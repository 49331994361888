import { LoginServices } from "@login";

import { ofAction } from "ngrx-actions";
import { of, Observable } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { FlockAuthService } from "src/providers";

import { Injectable } from "@angular/core";
import { Actions, createEffect } from "@ngrx/effects";
import { ActionRestrictStores, ActionSetStore } from "../../store/actions";
import {
  ActionLoginFail,
  ActionLoginSuccess,
  ActionSetFlockLogin,
  ActionTryFlockLogin,
  ActionTryLogin,
} from "../login/login-actions";

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: LoginServices,
    private flockAuthService: FlockAuthService
  ) {}

  /**
   * Triggers the login server call
   * Triggers success or fail based on the status
   * type {Observable<any>}
   */
  authLogin: Observable<
    ActionLoginSuccess | ActionRestrictStores | ActionSetStore | ActionLoginFail
  > = createEffect(() =>
    this.actions$.pipe(
      ofAction(ActionTryLogin),
      switchMap((authdata) => {
        return this.authService.getData(authdata.payload).pipe(
          // TODO
          
          switchMap((user) => {
            const actions: (
              | ActionLoginSuccess
              | ActionRestrictStores
              | ActionSetStore
            )[] = [new ActionLoginSuccess(user)];
            if (user.storeId.length > 0) {
              actions.push(new ActionRestrictStores(user.storeId));
            }

            if (user.storeId.length === 1) {
              actions.push(new ActionSetStore(user.storeId));
            }

            return actions;
          }),
          catchError((err) => {
            return of(new ActionLoginFail(""));
          })
        );
      })
    )
  );

  authFlockLogin: Observable<
    | (
        | ActionSetFlockLogin
        | ActionRestrictStores
        | ActionSetStore
        | ActionLoginSuccess
      )
    | ActionLoginFail
  > = createEffect(() =>
    this.actions$.pipe(
      ofAction(ActionTryFlockLogin),
      switchMap((authdata) => {
        return this.flockAuthService.getData(authdata.payload).pipe(
          switchMap((user) => {
            const actions: (
              | ActionSetFlockLogin
              | ActionRestrictStores
              | ActionSetStore
              | ActionLoginSuccess
            )[] = [new ActionSetFlockLogin()];

            if (user.storeId.length > 0) {
              actions.push(new ActionRestrictStores(user.storeId));
            }

            if (user.storeId.length === 1) {
              actions.push(new ActionSetStore(user.storeId));
            }

            actions.push(new ActionLoginSuccess(user));

            return actions;
          }),
          catchError((err) => {
            return of(new ActionLoginFail(""));
          })
        );
      })
    )
  );
}

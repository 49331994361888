import { HttpParams } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { CustomerEliteSubscription, User } from "@tendercuts/models";
import { EliteHistoryService } from "@tendercuts/providers";
import { FilterModel } from "src/models";
import { BasePage } from "../../utils/pages/base/base.component";
import { EliteHistoryDataSource } from "../data-source";
import { ColumnAction, DateFormatAction } from "../table-actions";

@Component({
  selector: "app-elite-history",
  templateUrl: "./elite-history.component.html",
  styleUrls: ["./elite-history.component.scss"],
})
export class EliteHistoryComponent extends BasePage implements OnInit {
  eliteHistory: CustomerEliteSubscription[];
  @Input() customerObj: User = null;

  columnsToDisplay: string[] = [
    "isActive",
    "eliteDaysLeft",
    "amountSaved",
    "freeOrderCount",
  ];

  titleToDisplay: string[] = [
    "Is Active",
    "Days Left",
    "Amount Saved",
    "Free Order Count",
  ];

  @Input() actions: ColumnAction[] = [
    new DateFormatAction(
      "Purchased At",
      "createdAt",
      this.getDateFormat.bind(this),
    ),
    new DateFormatAction(
      "Start Time",
      "startedDate",
      this.getDateFormat.bind(this),
    ),
    new DateFormatAction("End Time", "endDate", this.getDateFormat.bind(this)),
  ];

  modelFilter: FilterModel = new FilterModel([]);

  @Input()
  eliteHistoryDatasource: EliteHistoryDataSource = new EliteHistoryDataSource(
    this.modelFilter,
    [],
  );

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public eliteHistoryService: EliteHistoryService,
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    this.getEliteHistoryData();
  }

  /**
   *  userId is passed to load customer's  elite subscription records
   */
  getEliteHistoryData(): void {
    const params: HttpParams = this.eliteHistoryService.getParams(this.customerObj.userId);
    this.eliteHistoryService.getData(params).subscribe(
      (eliteData: CustomerEliteSubscription[]) => {
        this.eliteHistory = eliteData;
        this.eliteHistoryDatasource.data = eliteData;
      },
      (err) => this.somethingWentWrong(),
    );
  }

  getDateFormat(event: any, elite: CustomerEliteSubscription): string {
    return "MMM d, y h:mm a";
  }
}

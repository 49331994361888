import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { UserState } from "@store/user";
import { ActionTrySignup } from "@store/user/signup";
import { skipWhile, take } from "rxjs/operators";
import { BasePage } from "../../utils/pages/base/base.component";

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.scss"],
})
export class SignUpComponent extends BasePage implements OnInit {
  name: string = "";

  constructor(
    public dialogRef: MatDialogRef<SignUpComponent>,
    @Inject(MAT_DIALOG_DATA) public cusDetails: any,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {}

  createCustomer(): void {
    const user: {
      firstname: string;
      email: string;
      mobilenumber: string;
      password: string;
  } = {
      firstname: this.name,
      email: this.cusDetails.mail,
      mobilenumber: this.cusDetails.phone,
      password: "tendercuts123",
    };
    this.store.dispatch(new ActionTrySignup(user));
    this.store
      .select((state) => state.userState)
      .pipe(
        skipWhile((userState: UserState) => userState.loading == true),
        take(1),
      )
      .subscribe((userState: UserState) => {
        if (userState.error) {
          this.dialogRef.close(false);
        } else {
          this.dialogRef.close(true);
        }
      });
  }
}

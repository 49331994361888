// TODO
/* eslint-disable */
import { Component, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "error-404-page",
  templateUrl: "./error-404-page.component.html",
  styleUrls: [
    "./styles/error-404-page.styles.scss",
    "./styles/error-404-page.theme.scss",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class Error404PageComponent {}

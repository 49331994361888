import { AgmMap } from "@agm/core";
import {
  forwardRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { DriverOrder, OptimalTrip, SaleOrder, Trips } from "@tendercuts/models";
import { UpdateTripService } from "@tendercuts/providers";
import { take } from "rxjs/operators";
import { BasePage } from "../../utils/pages/base/base.component";
import { TripDirectionComponent } from "../trip-direction";

@Component({
  selector: "app-plan-trip-order-sequence",
  templateUrl: "./plan-trip-order-sequence.html",
  styleUrls: ["./plan-trip-order-sequence.scss"],
})
export class PlanTripOrderSequenceComponent extends BasePage implements OnInit {
  tripObject: Trips;
  orderMap: {} = {};
  sequencedOrder: DriverOrder[] = [];
  sequence: number[] = [];
  showInfoWindow: boolean = false;
  nextNearestOrder: DriverOrder;
  clickedOrder: SaleOrder;
  NEIGHBOUR_ORDER_RADIUS: number = 0.1;

  @Input()
  set trip(trip: Trips) {
    this.tripObject = trip;
    if (!this.tripObject) {
      return;
    }
    this.tripObject.driverOrder = trip.driverOrder.filter(
      (order) => order.isActiveOrder,
    );
    this.orderState.pipe(take(1)).subscribe((orderState) => {
      this.tripObject.driverOrder.forEach((order) => {
        this.orderMap[order.incrementId] =
          orderState.orderMap[order.incrementId];
      });
    });
  }

  @Input() isCloseable: boolean = false;

  @Output()
  dialogClose: EventEmitter<boolean> = new EventEmitter();

  @ViewChild("map") map: AgmMap;
  @ViewChild(forwardRef(() => TripDirectionComponent))
  tripDirectionComponent: TripDirectionComponent;
  selectedTrip: OptimalTrip;
  mapRawRef: any;
  zoom: number = 13;
  ngOnInit(): void {
    const routeParam: string = this.route.snapshot.queryParams[
      "d24230a0c7bde1dc38cdd4c7e9642e8e"
    ];
    if (routeParam) {
      for (const trip of this.tripObject.driverOrder) {
        this.addOrderToSequenceList(trip);
      }
    }
  }
  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public updateTripService: UpdateTripService,
    public router: Router,
    public route: ActivatedRoute,
  ) {
    super(dialog, snackBar, store);
  }

  renderSegment(mapRef: any): void {
    this.mapRawRef = mapRef;
  }

  /**
   * @func addOrderToSequenceList
   * @param order - DriverOrder Object
   * push the order to the sequence order list
   * and set the same sequence number to the all
   * neighbour orders
   */
  addOrderToSequenceList(driverOrder: DriverOrder): void {
    if (this.sequencedOrder.indexOf(driverOrder) === -1) {
      const nearByOrders: DriverOrder[] = this.getNeighbourOrders(driverOrder);
      if (nearByOrders.length > 0) {
        const sequenceNumber: number = this.sequence.length + 1;
        this.sequence.push(sequenceNumber);
        nearByOrders.forEach((order, index) => {
          order.setSequence = sequenceNumber;
          order.internalSequenceNumber = index + 1;
          this.sequencedOrder.push(order);
        });
      }
    }
  }

  /**
   * @func getNearByOrders
   * @param order
   * @return the orders that are very close
   *  to the last order in the sequence
   *  The crow eye view distance bewtween the orders are calcuated
   *  haversine method
   */
  getNeighbourOrders(order: DriverOrder): DriverOrder[] {
    const nearByOrders: DriverOrder[] = [];
    const neighbouringOrders: DriverOrder[] = [];
    this.unSequencedOrder.forEach((availableOrder) => {
      const distance: number = this.getDistance(order, availableOrder);
      availableOrder.distance = distance;
      if (distance < this.NEIGHBOUR_ORDER_RADIUS) {
        neighbouringOrders.push(availableOrder);
      } else {
        nearByOrders.push(availableOrder);
      }
    });
    this.findNextNearestOrder(nearByOrders);

    return neighbouringOrders;
  }

  /**
   *
   * @param nearByOrders
   * sort the orders with the distance and
   * set the first element as the next nearest order
   */
  findNextNearestOrder(nearByOrders: DriverOrder[]): void {
    this.nextNearestOrder = undefined;
    nearByOrders.sort((orderA, orderB) => {
      if (orderA.distance < orderB.distance) {
        return -1;
      }

      return 1;
    });
    this.nextNearestOrder = nearByOrders[0];
  }

  /**
   *
   * @param orderA
   * @param orderB
   * @returns the crow flying distance between the two
   * given orders in kilometer.
   */
  getDistance(orderA: DriverOrder, orderB: DriverOrder): number {
    function toRad(Value: number): number {
      return (Value * Math.PI) / 180;
    }
    const R: 6371 = 6371; // km
    const dLat: number = toRad(orderB.lat - orderA.lat);
    const dLon: number = toRad(orderB.lng - orderA.lng);
    const lat1: number = toRad(orderA.lat);
    const lat2: number = toRad(orderB.lat);

    const a: number =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c: number = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d: number = R * c;

    return d;
  }

  /**
   * @func moveUp
   * @param sequenceNumber - orders sequence number
   *  set one number less than the given sequence number to the orders
   *  having the same sequence numbers.
   *  set the given sequence number to the to the orders having the
   *  less than one og given sequence number
   */
  moveUp(sequenceNumber: number): void {
    const orderSetA: DriverOrder[] = this.sequencedOrder.filter(
      (order) => order.sequence === sequenceNumber - 1,
    );
    const orderSetB: DriverOrder[] = this.sequencedOrder.filter(
      (order) => order.sequence === sequenceNumber,
    );
    orderSetA.forEach((order) => (order.setSequence = sequenceNumber));
    orderSetB.forEach((order) => (order.setSequence = sequenceNumber - 1));
  }

  /**
   * @func moveDown
   * @param sequenceNumber - orders sequence number
   *  set one number greater than the given sequence number to the orders
   *  having the same sequence numbers.
   *  set the given sequence number to the to the orders having the
   *  greater than one of given sequence number in sequenced array
   */
  moveDown(sequenceNumber: number): void {
    const orderSetA: DriverOrder[] = this.sequencedOrder.filter(
      (order) => order.sequence === sequenceNumber + 1,
    );
    const orderSetB: DriverOrder[] = this.sequencedOrder.filter(
      (order) => order.sequence === sequenceNumber,
    );
    orderSetA.forEach((order) => (order.setSequence = sequenceNumber));
    orderSetB.forEach((order) => (order.setSequence = sequenceNumber + 1));
  }

  /**
   * @func moveUpInGroup
   * @param sequence - sequence number of the order
   * @param internalSequenceNumber - internal sequence number of the group orders
   * Increase the internal sequence number for the give sequence number group by one
   * and swap the order with the index in sequenced order
   */
  moveUpInGroup(sequence: number, internalSequenceNumber: number): void {
    const orderSetA: DriverOrder = this.getOrdersInSequence(sequence).filter(
      (order) => order.internalSequenceNumber === internalSequenceNumber - 1,
    )[0];
    const orderSetB: DriverOrder = this.getOrdersInSequence(sequence).filter(
      (order) => order.internalSequenceNumber === internalSequenceNumber,
    )[0];
    orderSetA.internalSequenceNumber = internalSequenceNumber;
    orderSetB.internalSequenceNumber = internalSequenceNumber - 1;
    const swapIndex: number = this.sequencedOrder.indexOf(orderSetA);
    this.sequencedOrder[swapIndex] = orderSetB;
    this.sequencedOrder[swapIndex + 1] = orderSetA;
  }

  /**
   * @func moveDownInGroup
   * @param sequence - sequence number of the order
   * @param internalSequenceNumber - internal sequence number of the group orders
   * Decrease the internal sequence number for the give sequence number group by one
   * and swap the order with the index in sequenced order
   */
  moveDownInGroup(sequence: number, internalSequenceNumber: number): void {
    const orderSetA: DriverOrder = this.getOrdersInSequence(sequence).filter(
      (order) => order.internalSequenceNumber === internalSequenceNumber + 1,
    )[0];
    const orderSetB: DriverOrder = this.getOrdersInSequence(sequence).filter(
      (order) => order.internalSequenceNumber === internalSequenceNumber,
    )[0];
    orderSetA.internalSequenceNumber = internalSequenceNumber;
    orderSetB.internalSequenceNumber = internalSequenceNumber + 1;
    const swapIndex: number = this.sequencedOrder.indexOf(orderSetA);
    this.sequencedOrder[swapIndex] = orderSetB;
    this.sequencedOrder[swapIndex - 1] = orderSetA;
  }

  /**
   * @func reOrderSequence
   * After removing a single order from the sequence list
   * Need to update sequence number for all order in the
   * sequence list
   */
  reOrderSequence(sequenceNumber: number): void {
    this.sequence.forEach((reOrderNumber) => {
      if (sequenceNumber === this.sequence.length) {
        this.sequence.pop();
        this.nextNearestOrder = undefined;
      } else if (reOrderNumber >= sequenceNumber) {
        this.moveDown(sequenceNumber);
        sequenceNumber++;
      }
    });
  }

  /**
   * @func removeOrder
   * @param sequenceNumber - sequence numer  of the orders
   * to be removed from
   * sequenced array
   */
  removeOrder(sequenceNumber: number): void {
    this.sequencedOrder = this.sequencedOrder.filter(
      (order) => order.sequence !== sequenceNumber,
    );
    this.reOrderSequence(sequenceNumber);
  }

  /**
   * @func resetSequence
   * Remove all the orders from the sequenced array
   */
  resetSequence(): void {
    this.sequencedOrder = [];
    this.sequence = [];
    this.nextNearestOrder = undefined;
  }

  /**
   * @func getSequenceNumber
   * @param order - Driver Order Object
   * @returns the sequence number for the order in the sequencedOrder array
   */
  getSequenceNumber(order: DriverOrder): number | string {
    const index: number = this.sequencedOrder.indexOf(order);

    return index !== -1 ? this.sequencedOrder[index].sequence : "";
  }

  loadIcon(order: DriverOrder): string {
    if (this.sequencedOrder.indexOf(order) !== -1) {
      return "assets/marker/location.png";
    }
  }

  /**
   * @func getOrdersInSequence
   * @param number
   * @returns the array of orders that have the given number as sequence number
   */
  getOrdersInSequence(OrdersInSequenceNumber: number): DriverOrder[] {
    return this.sequencedOrder.filter(
      (order) => order.sequence === OrdersInSequenceNumber,
    );
  }

  /**
   * @return orders that are not in sequenced list
   */
  get unSequencedOrder(): DriverOrder[] {
    return this.tripObject.driverOrder.filter(
      (order) => this.sequencedOrder.indexOf(order) === -1,
    );
  }

  /**
   * validate whether all orders in the trip is sequenced
   * and update with service
   */
  updateSequence(): void {
    if (this.sequencedOrder.length != this.tripObject.driverOrder.length) {
      this.textAlert(
        "Orders Missing",
        "Please sequence all the orders in the trip",
      );

      return;
    }
    const onSuccess: (result: any) => void = (result) => {
      if (!result) {
        return;
      }
      this.updateTripOrderSequence();
    };

    this.optionsAlert(
      "Are you sure?",
      "This sequence will be assigned to the trip",
      onSuccess,
    );
  }

  /**
   * Call the update trip service with trip onject and new sequenced
   * driver order array .
   */
  updateTripOrderSequence(): void {
    this.updateTripService
      .getData(
        this.updateTripService.getParams(this.tripObject, this.sequencedOrder),
      )
      .subscribe((response) => {
        if (response[0].status) {
          this.showNotification(
            "top",
            "center",
            "success",
            "info-circle",
            response[0].message,
          );
          /**
           * Resetting component scope variables since the component
           * is available all the time and values could be retained and
           * lead to bugs.
           */
          this.resetSequence();
        } else {
          this.textAlert("failed", response[0].message);
        }
      });
    this.dialogClose.emit(true);
  }
}

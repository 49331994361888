import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { StoreState } from "@store/store/state";
import { DriverLanguage, DriverProfile, DriverProfileDetails, GenericServerResponse } from "@tendercuts/models";
import {
  CreateDriverService,
  DriverLanguagesService,
  UpdateDriverService,
} from "@tendercuts/providers";
import { Subscription } from "rxjs";
import { BasePage } from "../../utils";

@Component({
  selector: "app-driver-onboarding",
  templateUrl: "./driver-onboarding.component.html",
  styleUrls: ["./driver-onboarding.component.scss"],
})
export class DriverOnBoardingComponent
  extends BasePage
  implements OnInit, OnDestroy {
  nameRegex: string | RegExp;
  driverOnboardingForm: FormGroup;
  status: boolean;
  driverImage: File;
  driverProofImage: File;
  imageSize: number;
  availableStores: any;
  availableLanguage: DriverLanguage[] = [];

  @ViewChild("myInputProof")
  proofImage: ElementRef;
  @ViewChild("myInputProfile")
  profileImage: ElementRef;
  storeSub: Subscription;
  driver: DriverProfile;
  // flag to show either create or update rider details
  isUpdatableMode: boolean = false;
  availableSchemes: {
    value: string;
    label: string;
  }[] = [
    {
      value: "DAILY",
      label: "Daily Earnings",
    },
    {
      value: "WEEKLY",
      label: "Weekly Earnings",
    },
    {
      value: "HYBRID",
      label: "Hybrid Earnings",
    },
    {
      value: "BEST",
      label: "Best of daily & weekly Earnings",
    },
  ];

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public createDriverService: CreateDriverService,
    public updateRiderDetailService: UpdateDriverService,
    public driverLanguagesService: DriverLanguagesService,
    public dialogRef: MatDialogRef<DriverOnBoardingComponent>,
    private title: Title,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(dialog, snackBar, store);
    if (data) {
      this.driver = data.driver;
      this.isUpdatableMode = data.editMode;
    }
  }

  ngOnInit(): void {
    this.fetchStores();
    this.fetchDriverLanguages();
    this.driverOnboardingForm = new FormGroup({
      userName: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern(this.nameRegex),
      ]),

      phoneNumber: new FormControl("", [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
      ]),

      email: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"),
      ]),

      accountNumber: new FormControl("", [
        Validators.required,
        Validators.minLength(6),
      ]),
      ifscCode: new FormControl("", [
        Validators.required,
        Validators.minLength(6),
      ]),
      proofType: new FormControl("", [Validators.required]),
      proofId: new FormControl("", [Validators.required]),
      weekendRider: new FormControl(),
      workLocation: new FormControl("", [Validators.required]),
      pan: new FormControl("", [Validators.required, Validators.minLength(10)]),
      preferredLanguage: new FormControl("", [Validators.required]),
      earningScheme: new FormControl("", [Validators.required]),
    });
    if (this.isUpdatableMode) {
      this.updateFormAutoload();
    }
  }

  /**
   * Account number,Ifsc code and PAN values are autoload in update driver form
   */
  updateFormAutoload(): void {
    this.driverOnboardingForm.controls.accountNumber.setValue(
      this.driver.profile.accountNumber
    );
    this.driverOnboardingForm.controls.ifscCode.setValue(
      this.driver.profile.ifscCode
    );
    this.driverOnboardingForm.controls.pan.setValue(this.driver.profile.pan);
    this.driverOnboardingForm.controls.preferredLanguage.setValue(
      this.driver.profile.languageId
    );
    this.driverOnboardingForm.controls.earningScheme.setValue(
      this.driver.profile.earningScheme
    );
  }

  fetchStores(): void {
    this.storeSub = this.storeState.subscribe((storeState: StoreState) => {
      this.availableStores = storeState.availableStores;
    });
  }

  // Fn to fetch available driver languages from server
  fetchDriverLanguages(): void {
    this.driverLanguagesService.getData().subscribe(
      (language: DriverLanguage[]) => {
        this.availableLanguage = language;
      },
      (err) => {
        this.textAlert("Invalid Data", "No languages found");
      }
    );
  }

  onProofImageSelected(event: any, isDriverImage: boolean): void {
    this.imageSize = event.target.files[0].size;
    if (this.imageSize > 200000) {
      this.validationFailed(
        "Proof image size should be less than 200kb only.."
      );
      this.resetProof();

      return;
    }
    if (isDriverImage) {
      this.driverProofImage = event.target.files[0];

      return;
    }
  }

  onProfileImageSelected(event: any, isDriverImage: boolean): void {
    this.imageSize = event.target.files[0].size;
    if (this.imageSize > 200000) {
      this.validationFailed(
        "Profile image size should be less than 200kb only.."
      );
      this.resetProfile();

      return;
    }
    if (isDriverImage) {
      this.driverImage = event.target.files[0];

      return;
    }
  }

  resetProof(): void {
    this.proofImage.nativeElement.value = "";
  }

  resetProfile(): void {
    this.profileImage.nativeElement.value = "";
  }

  validateSignUp(): void {
    if (!this.driverOnboardingForm.controls["userName"].valid) {
      this.validationFailed(
        "Please enter a valid name , name should not contain any special characters.."
      );

      return;
    }

    if (!this.driverOnboardingForm.controls["phoneNumber"].valid) {
      this.validationFailed("Please enter a valid phone number..");

      return;
    }

    if (!this.driverOnboardingForm.controls["email"].valid) {
      this.validationFailed("Please enter a valid E-mail..");

      return;
    }

    if (!this.driverOnboardingForm.controls["accountNumber"].valid) {
      this.validationFailed("Please enter a valid account number ..");

      return;
    }

    if (!this.driverOnboardingForm.controls["ifscCode"].valid) {
      this.validationFailed("Please enter IFSC code ..");

      return;
    }

    if (!this.driverOnboardingForm.controls["pan"].valid) {
      this.validationFailed(
        "Please enter your PAN, PAN should have min 10 digits."
      );

      return;
    }

    if (!this.driverOnboardingForm.controls["proofType"].valid) {
      this.validationFailed("Please select proof-type name ..");

      return;
    }

    if (!this.driverOnboardingForm.controls["proofId"].valid) {
      this.validationFailed("Please enter proof-Id number ..");

      return;
    }

    if (!this.driverOnboardingForm.controls["earningScheme"].valid) {
      this.validationFailed("Please select earning scheme.");

      return;
    }

    if (!this.driverProofImage) {
      this.validationFailed("Please upload driver proof image ..");

      return;
    }

    if (!this.driverImage) {
      this.validationFailed("Please upload driver image ..");

      return;
    }

    if (!this.driverOnboardingForm.controls["workLocation"].valid) {
      this.validationFailed("Please select the Work Location..");

      return;
    }

    if (!this.driverOnboardingForm.controls["preferredLanguage"].valid) {
      this.validationFailed("Please select preferred language..");

      return;
    }

    this.createDriver();
  }

  validationFailed(message: string): void {
    this.showNotification("bottom", "center", "danger", "info-circle", message);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  createDriver(): void {
    const params: FormData = this.createDriverService.getParams(
      this.driverOnboardingForm.controls["userName"].value,
      this.driverOnboardingForm.controls["phoneNumber"].value,
      this.driverOnboardingForm.controls["accountNumber"].value,
      this.driverOnboardingForm.controls["ifscCode"].value,
      this.driverProofImage,
      this.driverImage,
      this.driverOnboardingForm.controls["proofId"].value,
      this.driverOnboardingForm.controls["proofType"].value,
      this.driverOnboardingForm.controls["weekendRider"].value ? true : false,
      this.driverOnboardingForm.controls["email"].value,
      this.driverOnboardingForm.controls["workLocation"].value,
      this.driverOnboardingForm.controls["pan"].value,
      this.driverOnboardingForm.controls["preferredLanguage"].value,
      this.driverOnboardingForm.controls["earningScheme"].value
    );
    this.createDriverService.getData(params).subscribe(
      (response) => {
        if (response[0].status == true) {
          this.driverOnboardingForm.reset();
          this.resetProof();
          this.resetProfile();
          this.dialogRef.close();
        }
        this.showNotification(
          "bottom",
          "center",
          "",
          "info-circle",
          response[0].message
        );
      },
      (error) => {
        this.somethingWentWrong();
      }
    );
  }

  ngOnDestroy(): void {
    this.storeSub?.unsubscribe();
  }

  // Fn to update profile details
  updateRiderDetail(): void {
    if (!this.driverOnboardingForm.controls["accountNumber"].valid) {
      this.validationFailed("Please enter a valid account number ..");

      return;
    }
    if (!this.driverOnboardingForm.controls["ifscCode"].valid) {
      this.validationFailed("Please enter a valid IFSC code");

      return;
    }

    if (!this.driverOnboardingForm.controls["pan"].valid) {
      this.validationFailed(
        "Please enter a valid PAN, PAN should have min 10 digits."
      );

      return;
    }

    if (!this.driverOnboardingForm.controls["earningScheme"].valid) {
      this.validationFailed("Please select earning scheme.");

      return;
    }

    if (!this.driverImage) {
      this.validationFailed("Please upload driver image ..");

      return;
    }

    if (!this.driverOnboardingForm.controls["preferredLanguage"].valid) {
      this.validationFailed("Please select preferred language..");

      return;
    }

    const params: FormData = this.updateRiderDetailService.getParams(
      this.driver.driverProfileId,
      this.driver.driverId,
      this.driverOnboardingForm.controls["accountNumber"].value,
      this.driverOnboardingForm.controls["ifscCode"].value,
      this.driverOnboardingForm.controls["pan"].value,
      this.driverImage,
      this.driverOnboardingForm.controls["preferredLanguage"].value,
      this.driverOnboardingForm.controls["earningScheme"].value
    );

    this.updateRiderDetailService.getData(params).subscribe(
      (response: GenericServerResponse[]) => {
        if (response[0].status == true) {
          this.dialogRef.close(true);
        }
        this.showNotification(
          "bottom",
          "center",
          "",
          "info-circle",
          response[0].message
        );
      },
      (error) => {
        this.somethingWentWrong();
      }
    );
  }
}

import { Category, LiveTrack, Product } from "@tendercuts/models";
import { createReducer, Action, Store } from "ngrx-actions";
import {
  ActionCatalogLoad,
  ActionCatalogLoadSuccess,
  ActionClearCatalog,
  ActionInventoryLoad,
  ActionLiveTrackLoad,
  ActionLiveTrackLoadSuccess,
  ActionLoadFailed,
  ActionSearchComplete,
  ActionSearchProducts,
} from "../catalog/catalog-actions";
import { CatalogState } from "./state";

@Store({
  catalog: [],
  filteredProducts: [],
  loading: false,
  errorMsg: null,
})
export class CatalogReducers {
  @Action(ActionCatalogLoad)
  load(
    state: CatalogState,
    action: ActionCatalogLoad
  ): {
    loading: boolean;
    errorMsg: any;
    catalog: Category[];
    filteredProducts: Product[];
    products: Product[];
    productMap: {
      [id: number]: Product;
    };
  } {
    return {
      ...state,
      loading: true,
      errorMsg: null,
    };
  }

  @Action(ActionLoadFailed)
  loadFailed(
    state: CatalogState,
    action: ActionLoadFailed
  ): {
    loading: boolean;
    errorMsg: any;
    catalog: Category[];
    filteredProducts: Product[];
    products: Product[];
    productMap: {
      [id: number]: Product;
    };
  } {
    return {
      ...state,
      loading: false,
      errorMsg: { ...action.payload },
    };
  }

  /**
   * Nuke the catalog
   * @param CatalogState state
   * @param ActionClearCatalog action
   * @returns catalog: Category[]; loading: boolean;
   * errorMsg: GenericError; filteredProducts: Product[]
   */
  @Action(ActionClearCatalog)
  clearCatalog(
    state: CatalogState,
    action: ActionClearCatalog
  ): {
    loading: boolean;
    errorMsg: any;
    catalog: Category[];
    filteredProducts: Product[];
    products: Product[];
    productMap: {
      [id: number]: Product;
    };
  } {
    return {
      ...state,
      catalog: [],
      filteredProducts: [],
      products: [],
    };
  }

  @Action(ActionCatalogLoadSuccess)
  loadSuccess(
    state: CatalogState,
    action: ActionCatalogLoadSuccess
  ): {
    loading: boolean;
    errorMsg: any;
    catalog: Category[];
    filteredProducts: Product[];
    products: Product[];
    productMap: {
      [id: number]: Product;
    };
  } {
    const products: Product[][] = action.payload.map(
      (category: Category) => category.products
    );
    const flatProducts: any = [].concat.apply([], products);

    const flatProductsUnique: any = {};
    flatProducts.forEach(
      (product) => (flatProductsUnique[product.id] = product)
    );

    return {
      ...state,
      catalog: [...action.payload],
      products: flatProducts,
      loading: false,
      errorMsg: null,
      productMap: flatProductsUnique,
    };
  }

  @Action(ActionInventoryLoad)
  loadInventory(
    state: CatalogState,
    action: ActionInventoryLoad
  ): {
    loading: boolean;
    errorMsg: any;
    catalog: Category[];
    filteredProducts: Product[];
    products: Product[];
    productMap: {
      [id: number]: Product;
    };
  } {
    return {
      ...state,
      loading: true,
    };
  }

  @Action(ActionSearchComplete)
  searchComplete(
    state: CatalogState,
    action: ActionSearchComplete
  ): {
    loading: boolean;
    errorMsg: any;
    catalog: Category[];
    filteredProducts: Product[];
    products: Product[];
    productMap: {
      [id: number]: Product;
    };
  } {
    return {
      ...state,
      filteredProducts: [...action.payload],
    };
  }

  @Action(ActionSearchProducts)
  searchProducts(
    state: CatalogState,
    action: ActionSearchProducts
  ): {
    loading: boolean;
    errorMsg: any;
    catalog: Category[];
    filteredProducts: Product[];
    products: Product[];
    productMap: {
      [id: number]: Product;
    };
  } {
    return {
      ...state,
      loading: false,
    };
  }

  @Action(ActionLiveTrackLoad)
  loadLiveTrack(
    state: CatalogState,
    action: ActionLiveTrackLoad
  ): {
    loading: boolean;
    errorMsg: any;
    catalog: Category[];
    filteredProducts: Product[];
    products: Product[];
    productMap: {
      [id: number]: Product;
    };
    liveTrack: LiveTrack[];
  } {
    return {
      ...state,
      loading: true,
      errorMsg: null,
    };
  }

  @Action(ActionLiveTrackLoadSuccess)
  liveTrackLoadSuccess(
    state: CatalogState,
    action: ActionLiveTrackLoadSuccess
  ): {
    loading: boolean;
    errorMsg: any;
    catalog: Category[];
    filteredProducts: Product[];
    products: Product[];
    productMap: {
      [id: number]: Product;
    };
    liveTrack: LiveTrack[];
  } {
    const hyperTrackDetails: LiveTrack[] = action.payload.map(
      (liveTrack: LiveTrack) => liveTrack
    );

    return {
      ...state,
      loading: false,
      errorMsg: null,
      liveTrack: hyperTrackDetails,
    };
  }
}

export const catalogReducer: (state: any, action: any) => any =
  createReducer(CatalogReducers);

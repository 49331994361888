import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { HttpParams } from "@angular/common/http";
import { HistoricOrdersService } from "@tendercuts/providers";
import { BehaviorSubject, Observable } from "rxjs";

export class BaseDataSource<T> implements DataSource<T> {
  protected connectionSubject: BehaviorSubject<T[]> = new BehaviorSubject<T[]>(
    [],
  );

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return this.connectionSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.connectionSubject.complete();
  }
}

export class SaleOrderServerDataSource<
  SaleOrder
> extends BaseDataSource<SaleOrder> {
  constructor(private service: HistoricOrdersService) {
    super();
  }

  load(page: number, orderId: string, storeId: number, clbk: any): void {
    let params: HttpParams = new HttpParams();
    params = params.append("store_id", storeId.toString());
    if (orderId) {
      params = params.append("increment_id", orderId);
    }
    params = params.append("page", page.toString());

    this.service.getData(params).subscribe((data) => {
      this.connectionSubject.next(data);
      // done callback
      if (clbk) {
        clbk();
      }
    });
  }
}

import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { Trips } from "@tendercuts/models";
import { TripCashService } from "@tendercuts/providers";
import { BasePage } from "../../utils/pages/base/base.component";

@Component({
  selector: "app-cash-collection-dialog",
  templateUrl: "./cash-collection-dialog.html",
  styleUrls: ["./cash-collection-dialog.scss"],
})
export class CashCollectionDialogComponent extends BasePage implements OnInit {
  cashInputControl: FormControl = new FormControl(0, [Validators.required]);
  reasonControl: FormControl = new FormControl("", [
    Validators.required,
    Validators.minLength(2),
    Validators.maxLength(200),
  ]);
  showReason: boolean = false;

  trip: Trips;

  // form group controller for all fields
  validationForm: FormGroup;

  // flag for show editable checkIn value input tag
  editCheckIn: boolean = false;

  // flag for show editable checkOut value input tag
  editCheckOut: boolean = false;
  isEnable: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Trips,
    public dialogRef: MatDialogRef<CashCollectionDialogComponent>,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public tripCashService: TripCashService
  ) {
    super(dialog, snackBar, store);
    if (data) {
      this.trip = data;
    }
  }

  ngOnInit(): void {
    this.validationForm = new FormGroup({
      correctedCheckIn: new FormControl(this.trip.checkInValue, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(5),
      ]),
      correctedCheckOut: new FormControl(this.trip.checkOutValue, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(5),
      ]),
    });
  }

  closePopup(): void {
    this.dialogRef.close(true);
  }

  /**
   * Validates whether the user entered cash is a number and
   * above 0.
   */
  validateCash(): void {
    const submittedCash: number = this.cashInputControl.value;
    if (typeof submittedCash === 'number' && submittedCash >= 0) {
      this.processCash(submittedCash);

      return;
    }
    this.textAlert("Invalid cash", "Please enter correct cash amount");
  }
  /**
   * 1. Validates reason if the reason field is aleady enabled.
   * 2. Asks for reason if there is cash shortage
   * 3. Submits the cash if there is no shortage
   * @param submittedCash number
   */
  processCash(submittedCash: number): void {
    if (this.showReason) {
      this.validateReason(submittedCash);

      return;
    }
    let actualAmount: number = this.trip.cash.actualAmount;
    if (this.trip.isCashShort) {
      actualAmount = this.trip.cash.shortageAmount;
    }
    const difference: number = actualAmount - submittedCash;
    if (difference > 5) {
      this.showReason = true;

      return;
    }
    this.submitCash(submittedCash);
  }

  /**
   * If the reason is valid, submits the shortage cash.
   * @param submittedCash number
   */
  validateReason(submittedCash: number): void {
    if (this.reasonControl.valid) {
      this.submitCash(submittedCash, this.reasonControl.value);

      return;
    }
    this.textAlert("Invalid Reason", "Please enter valid reason");
  }

  submitCash(submittedCash: number, reason: string = null): void {
    const params: {
      cash_id: number;
      trip: number;
      submitted_amount: number;
      check_in_value: number;
      check_out_value: number;
      reason: string;
    } = this.tripCashService.getParams(this.trip, submittedCash, reason);
    this.isEnable = false;
    this.presentLoader();
    this.tripCashService.getData(params).subscribe(
      (data) => {
        this.showNotification(
          "top",
          "center",
          data[0].status ? "success" : "danger",
          "info-circle",
          data[0].message
        );
        this.dismissLoader();
        this.isEnable = true;
        if (data[0].status) {
          this.closePopup();
        }
      },
      (err) => this.somethingWentWrong()
    );
  }
  /**
   * Resets the form controls
   */
  reset(): void {
    this.showReason = false;
    this.isEnable = true;
    this.reasonControl.setValue("");
    this.cashInputControl.setValue(0);
  }
}

import { Action } from "@ngrx/store";
import { User } from "@tendercuts/models";

/**
 * Loads the user from local storage
 */
export class ActionLoadUser implements Action {
  readonly type: string = "ACTION_LOAD_USER";

  constructor() {}
}

/**
 * Sets the user to the redux store.
 */
export class ActionSetUser implements Action {
  readonly type: string = "ACTION_SET_USER";

  constructor(public payload: User) {}
}

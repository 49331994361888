import { Serializable } from '../base';

export class PaymentTransaction implements Serializable<PaymentTransaction> {
    public url: string;
    public method: string;
    public params: any;
    public amount: number;

    private client_auth_token: string;
    private client_auth_token_expiry: string;
    private txn_id: string;
    private order_id: string;
    private customer_id;
    private customer_email;
    private customer_phone;

    get transactionId() { return this.txn_id }
    get orderId() { return this.order_id }

    get customerId() { return this.customer_id }
    get customerEmail() { return this.customer_email }
    get customerPhoneNumber() { return this.customer_phone }

    get clientAuthToken(): string {
      return this.client_auth_token;
    }

    get clientAuthTokenExpiry(): string {
      return this.client_auth_token_expiry;
    }

    constructor() { }

    deserialize(input) {

        this.url = input.url;
        this.method = input.method;
        this.params = input.params;
        this.amount = input.amount;

        this.txn_id = input.txn_id;
        this.order_id = input.order_id;

        this.customer_id = input.customer_id;
        this.customer_email = input.customer_email;
        this.customer_phone = input.customer_phone;
        this.client_auth_token_expiry = input.client_auth_token_expiry;
        this.client_auth_token = input.client_auth_token;

        return this;
    }
}

<div id="selector" class="page-break">
  <div class="container">
    <table>
      <thead>
        <tr>
          <td align="center" colspan="12">
            <span class="title">
              <b>{{ brandTitle }}</b>
            </span>
          </td>
        </tr>
        <tr>
          <td align="center" colspan="12">
            <span
              >{{ getStoreAddress }}
              <br />
            </span>
            <span *ngIf="cityPhoneNo">Ph: {{ cityPhoneNo }}</span>
          </td>
        </tr>
        <tr>
          <td align="center" colspan="12">
            <b> {{ posQuote?.orderId }} </b>
          </td>
        </tr>
        <tr>
          <td align="center" colspan="12">
            {{ posQuote?.createdAt | date : "EEEE, MMMM d, y, h:mm:ss a" }}
          </td>
        </tr>
        <tr>
          <td style="border-top: 1px dashed #000" colspan="12"></td>
        </tr>
      </thead>
      <tbody>
        <ng-container>
          <tr>
            <th colspan="12" align="left">ITEM NAME * QTY</th>
          </tr>
          <tr class="head">
            <th colspan="3" align="left">RATE</th>
            <th colspan="3" align="center">DISC</th>
            <th colspan="3" align="center">GST</th>
            <th colspan="3" align="right">SUBTOTAL</th>
          </tr>
          <tr>
            <td style="border-top: 1px dashed #000" colspan="12"></td>
          </tr>
        </ng-container>
        <ng-container *ngFor="let item of posQuote?.cartProducts">
          <tr>
            <td *ngIf="!item.parentItemId" colspan="12">
              <span class="bold">
                {{ item.name }}}<span *ngIf="!item.isComboItem"
                  >({{
                    item.price | currency : "INR" : "symbol" : "1.2-2"
                  }}/unit)</span
                >
                * {{ item.quantity | number : "1.3-3" }}</span
              ><span *ngIf="item.hsnCode" class="hsn bold"
                >| HSN:{{ item.hsnCode }}</span
              >
            </td>
          </tr>
          <tr *ngIf="!item.parentItemId">
            <td colspan="3" align="left">
              <span
                >{{ item.baseRowTotal | currency : "INR" : "symbol" : "1.2-2" }}
              </span>
            </td>

            <td colspan="3" align="center">
              <span
                >{{
                  itemdiscount(item) | currency : "INR" : "symbol" : "1.2-2"
                }}
              </span>
            </td>

            <td colspan="3" align="center">
              {{ item.taxAmount | currency : "INR" : "symbol" : "1.2-2" }}
            </td>
            <td colspan="3" align="right">
              <span>{{
                item.rowTotal | currency : "INR" : "symbol" : "1.2-2"
              }}</span>
            </td>
          </tr>
        </ng-container>
        <tr>
          <td style="border-top: 1px dashed #000" colspan="12"></td>
        </tr>
        <ng-container>
          <tr>
            <th colspan="3" align="left">
              {{ itemsBaseRowTotal | currency : "INR" : "symbol" : "1.2-2" }}
            </th>
            <th colspan="3" align="center">
              {{ promoDiscount | currency : "INR" : "symbol" : "1.2-2" }}
            </th>
            <th colspan="3" align="center">
              {{
                posQuote.totals.taxAmount
                  | currency : "INR" : "symbol" : "1.2-2"
              }}
            </th>
            <th colspan="3" id="sub-total" align="right">
              {{ itemSubTotal | currency : "INR" : "symbol" : "1.2-2" }}
            </th>
          </tr>
        </ng-container>
        <tr>
          <td style="border-top: 1px dashed #000" colspan="12"></td>
        </tr>
        <!-- compare both ITEM TOTAL and NET TOTAL values if both the values are same we hide the Item Total and show only NET TOTAL-->
        <tr *ngIf="itemSubTotal != posQuote.totals.grandTotal">
          <td colspan="8">ITEM TOTAL</td>
          <th colspan="4" id="sub-total" align="right">
            <span>
              <span>
                {{ itemSubTotal | currency : "INR" : "symbol" : "1.2-2" }}
              </span>
            </span>
          </th>
        </tr>

        <tr *ngIf="shouldShowComponent('taxAmount')">
          <td colspan="8">GST</td>
          <th colspan="2" id="gst" align="right">
            {{
              posQuote.totals.taxAmount | currency : "INR" : "symbol" : "1.2-2"
            }}
          </th>
        </tr>
        <!-- TODO -->
        <!-- <tr *ngIf="shouldShowComponent('walletSpend')">
          <td colspan="6">Wallet Spent</td>
          <td colspan="4"></td>
          <th colspan="2" align="right">
            -{{ posQuote.totals.discount | currency: "INR":"symbol":"1.2-2" }}
          </th>
        </tr> -->

        <!-- compare GROSS TOTAL with both ITEM TOTAL and NET TOTAL values if both the values are same we hide the GROSS TOTAL and show only NET TOTAL and item total-->
        <tr
          *ngIf="
            grossTotal != itemSubTotal &&
            grossTotal != posQuote.totals.grandTotal
          "
        >
          <td colspan="8">GROSS TOTAL</td>
          <th colspan="4" id="gross-total" align="right">
            {{ grossTotal | currency : "INR" : "symbol" : "1.2-2" }}
          </th>
        </tr>

        <tr *ngIf="shouldShowComponent('discount')">
          <td colspan="8">DISCOUNT({{ posQuote.couponCode }})</td>
          <th colspan="4" align="right">
            <span *ngIf="!isDetailMode">-</span>
            {{
              posQuote.totals.discount | currency : "INR" : "symbol" : "1.2-2"
            }}
          </th>
        </tr>
        <tr *ngIf="shouldShowComponent('rewardPointsSpend')">
          <td colspan="8">Rewards Spent</td>
          <th colspan="4" align="right">
            <span *ngIf="!isDetailMode">-</span>
            {{
              posQuote.totals.rewardPointsSpend
                | currency : "INR" : "symbol" : "1.2-2"
            }}
          </th>
        </tr>
        <tr *ngIf="shouldShowComponent('grandTotal')">
          <td colspan="8">
            <strong>NET TOTAL</strong>
          </td>
          <th colspan="4" id="net-total" align="right">
            {{
              posQuote.totals.grandTotal | currency : "INR" : "symbol" : "1.2-2"
            }}
          </th>
        </tr>
        <tr *ngIf="totalSavings > 0 && !order.isEliteCustomer">
          <td class="save" align="center" colspan="12">
            Your Total Savings :
            {{ totalSavings | currency : "INR" : "symbol" : "1.2-2" }}
          </td>
        </tr>
        <ng-container
          *ngIf="posQuote.paymentMethod === 'cashforpos' && !isDetailMode"
        >
          <tr>
            <td style="border-top: 1px dashed #000" colspan="12"></td>
          </tr>
          <tr>
            <td colspan="6">CASH</td>
            <td colspan="4"></td>
            <th colspan="2" id="cash" align="right">
              {{ posQuote.tenderCash | currency : "INR" : "symbol" : "1.2-2" }}
            </th>
          </tr>
          <tr>
            <td colspan="6">CHANGE CASH</td>
            <td colspan="4"></td>
            <th colspan="2" id="change-cash" align="right">
              {{ posQuote.changeCash | currency : "INR" : "symbol" : "1.2-2" }}
            </th>
          </tr>
        </ng-container>

        <tr>
          <td style="border-top: 1px dashed #000" colspan="12"></td>
        </tr>
        <tr>
          <td colspan="6">
            <span>Payment Mode:</span> <b>{{ displayPaymentMethod }}</b>
          </td>
          <td align="right" colspan="6">
            <span>Customer:</span>
            <span>
              {{ posQuote.user?.firstname }}
            </span>
          </td>
        </tr>
        <tr>
          <td
            class="text-left"
            *ngIf="posQuote.user?.rewards && !isDetailMode"
            colspan="6"
          >
            <span> Available Rewards:</span>
            {{ userRewards }}
          </td>
          <td align="right" *ngIf="isDetailMode" colspan="6"></td>
          <td align="right" colspan="6">
            <span *ngIf="posQuote.user?.entityId">ID : </span>
            {{ posQuote.user?.entityId }}
          </td>
        </tr>
      </tbody>
    </table>

    <table>
      <ng-container *ngIf="order.eliteSaving && order.isEliteCustomer">
        <tr>
          <td style="border-top: 1px dashed #000" colspan="12"></td>
        </tr>
        <tr>
          <td colspan="12" align="center">{{ order.eliteSavingsMessage }}</td>
        </tr>
        <tr>
          <td style="border-top: 1px dashed #000" colspan="12"></td>
        </tr>
      </ng-container>
      <!-- GST grouping-->
      <ng-container *ngIf="categoryTax.length > 0">
        <thead>
          <td align="center" colspan="12">
            <span>
              TAX Break Up:
              <span> (GST: {{ gstNumber }})</span>
            </span>
          </td>
        </thead>
        <tbody>
          <tr>
            <td style="border-top: 1px dashed #000" colspan="12"></td>
          </tr>
          <tr>
            <th colspan="2">Tax%</th>
            <th colspan="3">Amount</th>
            <th colspan="2">SGST</th>
            <th colspan="2">CGST</th>
            <th colspan="3">Total</th>
          </tr>

          <tr>
            <td style="border-top: 1px dashed #000" colspan="12"></td>
          </tr>
          <ng-container *ngFor="let category of categoryTax">
            <tr>
              <td colspan="2">{{ category.taxPercent }}</td>
              <td colspan="3">
                {{ category.Amount | currency : "INR" : "symbol" : "1.2-2" }}
              </td>
              <td colspan="2">
                {{
                  math.floor(category.gst) / 2
                    | currency : "INR" : "symbol" : "1.2-2"
                }}
              </td>
              <td colspan="2">
                {{
                  math.floor(category.gst) / 2
                    | currency : "INR" : "symbol" : "1.2-2"
                }}
              </td>
              <td colspan="3">
                {{
                  math.floor(category.gst)
                    | currency : "INR" : "symbol" : "1.2-2"
                }}
              </td>
            </tr>
          </ng-container>
        </tbody>
      </ng-container>
      <tfoot>
        <ng-container>
          <tr>
            <td colspan="12" align="center">FSSAI : {{ fssaiCode }}</td>
          </tr>
          <tr>
            <td colspan="12" align="center">Thank You for Choosing us</td>
          </tr>
        </ng-container>
      </tfoot>
    </table>
    <ngx-barcode
      [bc-value]="posQuote?.orderId"
      [bc-display-value]="true"
      [bc-height]="50"
      [bc-width]="1.5"
      [bc-font-size]="14"
    >
    </ngx-barcode>
  </div>
</div>

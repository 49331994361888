import { AfterViewInit, Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SaleOrder, Trips } from "@tendercuts/models";
import { StoreAddress } from "@tendercuts/models";
import {
  QuoteSerializerService,
  StoreAddressService,
} from "@tendercuts/providers";
import { PosQuote } from "../../../../models";
export class PrintDataConfig {
  printType: string;
  showTripDetails: boolean;
  firstCustomerCopy: boolean;
  printKot: boolean;
  secondCustomerCopy: boolean;
  trip: Trips;
  orders: any;
}

@Component({
  selector: "app-print-invoice-dialog",
  templateUrl: "./print-invoice-dialog.component.html",
  styleUrls: ["./print-invoice-dialog.component.scss"],
})
export class PrintInvoiceDialogComponent implements AfterViewInit {
  constructor(
    public dialogRef: MatDialogRef<PrintInvoiceDialogComponent>,
    public storeAddressService: StoreAddressService,
    @Inject(MAT_DIALOG_DATA) public data: PrintDataConfig,
  ) {}

  ngAfterViewInit(): void {
    this.print();
  }

  storeAddress(order: SaleOrder): void {
    this.storeAddressService
      .getData()
      .subscribe((availableStores: StoreAddress[]) => {
        const stores: StoreAddress[] = availableStores.filter(
          (store) => store.storeId === order.storeId,
        );

        return stores.length > 0 ? stores[0].address : "";
      });
  }

  print(): void {
    let printContents: string;
    let popupWin: Window;
    printContents = document.getElementById("print-container").innerHTML;
    popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=70mm");
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print Bills</title>
        </head>
        <style>
            @page{margin:0cm;}
            .pagebreak { page-break-after: always; }
            table { width: 70mm; font-family: Arial; font-size: 12px; }
            .title { font-size: 18px; }
            .justify { text-align: justify; }
            .center { text-align: center; }
            .right { text-align: right; }
            .bill { font-size: 12px; text-align: right; }
            .combo table { width: 100%; margin-left: 5px }
            .bold { font-weight: bold; }
            .pagenum {
              padding:5px;
              border-radius: 50px;
              border: 1px solid black;
             }
            hr { margin-bottom: 3px; margin-top: 3px; }
           </style>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
    popupWin.document.close();
  }
}

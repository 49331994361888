import { Injectable } from "@angular/core";

import { BaseGetProvider } from "@tendercuts/http";
import { User } from "@tendercuts/models";

import { HttpClient, HttpParams } from "@angular/common/http";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";

@Injectable()
export class CustomerSearchService extends BaseGetProvider<User> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof User {
    return User;
  }

  get endpoint(): string {
    return this.endpointService.searchCustomer();
  }

  get isCachable(): boolean {
    return false;
  }

  /**
   * If the params is string passing as phone
   * else passing as entity,phone depend upon the length
   * @param customerSearchParams
   */
  getParams(customerSearchParams: any): HttpParams {
    let params: HttpParams = new HttpParams();
    if (Number(customerSearchParams)) {
      params =
        customerSearchParams.toString().length < 10
          ? params.append("entity_id", customerSearchParams.toString())
          : params.append("phone", customerSearchParams.toString());
    } else {
      params = params.append("phone", customerSearchParams.toString());
    }

    return params;
  }
}

import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenavContainer } from "@angular/material/sidenav";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { StoreState } from "@store/store/state";
import {
  DriverLoginLogout,
  GenerateWeeklyTripSheetResponse,
  WeeklyTripSheet,
} from "@tendercuts/models";
import {
  FetchWeeklyStoreDriversService,
  GenerateWeeklyTripSheetService,
} from "@tendercuts/providers";
import { Subscription } from "rxjs";
import { skipWhile, take } from "rxjs/operators";
import {
  DriverDataSource,
  MatChipAction,
  TableRowAction,
} from "src/app/components";
import { BasePage } from "src/app/utils";
import { Filter, FilterGroup, FilterMatch, FilterModel } from "src/models";

/**
 * component to show the total week riders in the selected week.
 */
@Component({
  selector: "app-weekly-drivers-dashboard",
  templateUrl: "./weekly-drivers-dashboard.component.html",
  styleUrls: ["./weekly-drivers-dashboard.component.scss"],
})
export class WeeklyDriversDashboardComponent
  extends BasePage
  implements OnInit, OnDestroy {
  // selected store riders
  drivers: DriverLoginLogout[] = [];

  // selected week
  selectedWeek: string;

  weeklyTripSheet: WeeklyTripSheet;

  // selected Rider
  selectedDriver: DriverLoginLogout;

  @Input() columnsToDisplay: string[] = [
    "driverId",
    "firstName",
    "phoneNumber",
  ];

  @Input() titleToDisplay: string[] = [
    "Driver Id",
    "Driver Name",
    "Phone Number",
  ];

  // param to show column as mat chip
  @Input() chipAction: MatChipAction[] = [
    new MatChipAction("Status", "weeklySheetStatus", null),
  ];

  @Input()
  rowClickCallback: TableRowAction = new TableRowAction(this.showDrawer.bind(this));

  driverSearchFilterGroup: FilterGroup = new FilterGroup([
    new Filter(
      "Search",
      null,
      ["firstName", "phoneNumber", "driverId"],
      FilterMatch.CONTAINS,
    ),
  ]);

  // To filter trip sheet status
  tripStatusFilterGroup: FilterGroup = new FilterGroup([
    new Filter("Pending", "Pending", "weeklySheetStatus"),
    new Filter("To Approve", "To Approve", "weeklySheetStatus"),
    new Filter("Confirm", "Confirm", "weeklySheetStatus"),
  ]);

  modelFilter: FilterModel = new FilterModel([
    this.tripStatusFilterGroup,
    this.driverSearchFilterGroup,
  ]);

  driverDataSource: DriverDataSource = new DriverDataSource(
    this.modelFilter,
    [],
  );

  @ViewChild("drawer") drawer: MatSidenavContainer;
  routerEventSubcriber: Subscription;
  constructor(
    public title: Title,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    private fetchStoreDrivers: FetchWeeklyStoreDriversService,
    private weeklytripsheetService: GenerateWeeklyTripSheetService,
    public router: Router,
    public route: ActivatedRoute,
  ) {
    super(dialog, snackBar, store);
  }

  /**
   * listen the route change if the there is any change in the route
   * it will fetch the data according to the activated route value
   */
  ngOnInit(): void {
    this.routerEventSubcriber = this.router.events.subscribe((data) => {
      if (data instanceof NavigationEnd) {
        this.fetchData();

        return;
      }
    });
    this.fetchData();
  }

  ngOnDestroy(): void {
    if (this.routerEventSubcriber) {
      this.routerEventSubcriber.unsubscribe();
    }
  }

  /*
  * Fetching data according to the current router value
  */
  fetchData(): void {
    const routeParam: string = this.route.snapshot.params["selectedWeek"];
    this.title.setTitle("Weekly Trip Sheet");
    if (routeParam != undefined) {
      this.selectedWeek = routeParam;
      this.fetchStoreData();

      return;
    }
    const previousWeek: string | number =
      (+this.getNumberOfWeek - 1).toString().length == 1
        ? "0" + (+this.getNumberOfWeek - 1)
        : +this.getNumberOfWeek - 1;
    this.selectedWeek = new Date().getFullYear() + "-W" + previousWeek;
    this.fetchStoreData();
  }

  /**
   * fetch redux store data
   */
  async fetchStoreData(): Promise<void> {
    const storeState$: StoreState = await this.store
      .select((state) => state.storeState)
      .pipe(
        skipWhile((state) => state.loading == true),
        take(1),
      )
      .toPromise();

    this.fetchDrivers(storeState$.store);
  }

  /**
   * fetch the drivers data from api
   * and assigning to driverdata source
   * @param storeId
   */
  fetchDrivers(storeId: number): void {
    this.presentLoader();

    if (storeId == undefined) {
      this.textAlert("Please select store", "To load Driver's Data");
      this.dismissLoader();
    }

    const params: {
      store_id: string;
      week_no: string;
      year: string;
  } = this.fetchStoreDrivers.getParams(storeId, this.selectedWeek);
    this.fetchStoreDrivers
      .getData(params)
      .subscribe((data: DriverLoginLogout[]) => {
        this.dismissLoader();
        this.drivers = data;
        this.driverDataSource.selection.clear();
        this.driverDataSource.data = this.drivers;

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (error) => {
          this.dismissLoader();
          this.somethingWentWrong();
        };
      });
  }

  /**
   * search filter based on the search value
   * @param searchString
   */
  applyDriverSearchFilter(searchString: string): void  {
    if (searchString === "") {
      this.driverSearchFilterGroup.filters[0].selected = false;
      this.driverSearchFilterGroup.filters[0].value = null;
    } else {
      this.driverSearchFilterGroup.filters[0].selected = true;
      this.driverSearchFilterGroup.filters[0].value = searchString;
    }
    this.driverDataSource.filter = "true";
  }

  /**
   * Trigger's on week change.
   * If selected week same as current week throw an error message.
   * else it will pass that selected week value // eg:- 2021-W05
   * into the router param
   * @param $event
   * @param $event
   */
  onSelectWeek($event: any): void {
    const currentWeek: string = new Date().getFullYear() + "-W" + this.getNumberOfWeek;
    if ($event.target.value == currentWeek) {
      this.textAlert(
        "OOPS!",
        "You can't generate weekly trip sheet for current week. Please select previous week",
      );

      return;
    }
    this.router.navigate([
      "/dashboard/weekly-tripsheet/" + $event.target.value,
    ]);
  }

  /**
   * return the running week no ex: 2020-W36
   */
  get maxWeek(): string {
    return (
      new Date().getFullYear().toString() + "-" + "W" + this.getNumberOfWeek
    );
  }

  /**
   * used to show last 10 week's in week sheet.
   * if year change showing the remaining weeks in last year.
   */
  get minWeek(): string {
    return Math.sign(+this.getNumberOfWeek - 10) == 1
      ? new Date().getFullYear().toString() +
          "-" +
          "W" +
          (+this.getNumberOfWeek - 10)
      : new Date().getFullYear() -
          1 +
          "-" +
          "W" +
          (53 - +(+this.getNumberOfWeek - 10));
  }

  /**
   * getter for get the current week number ex:36 week
   * if week no single digit "0" added in suffix.
   */
  get getNumberOfWeek(): string | number {
    const today: any = new Date();
    const firstDayOfYear: any = new Date(today.getFullYear(), 0, 1);
    const pastDaysOfYear: number = Math.floor(
      (today - firstDayOfYear) / (24 * 60 * 60 * 1000),
    );

    const weekNo: number = Math.ceil( pastDaysOfYear / 7);

    return weekNo.toString().length == 1 ? "0" + weekNo : weekNo;
  }

  showDrawer(event: any, driver: DriverLoginLogout): void  {
    this.selectedDriver = driver;
    this.generateWeeklyTripSheet();
  }

  /**
   * create's the week trip sheet
   */
  generateWeeklyTripSheet(): void {
    this.presentLoader();
    const params: {
      driver_id: number;
      week_no: string;
      year: string;
  } = this.weeklytripsheetService.getParams(
      this.selectedDriver.driverId,
      this.selectedWeek,
    );
    this.weeklytripsheetService.getData(params).subscribe(
      (data: GenerateWeeklyTripSheetResponse[]) => {
        this.dismissLoader();
        if (data.length) {
          this.weeklyTripSheet = data[0].result;
          this.drawer.open();
        }
      },
      (errorResponse: any) => {
        this.dismissLoader();
        if (errorResponse.status != 400) {
          this.somethingWentWrong();

          return;
        }

        this.showNotification(
          "top",
          "center",
          "danger",
          "info-circle",
          errorResponse.error.message ? errorResponse.error.message : "",
        );
      },
    );
  }

  closeDrawer(status: boolean): void {
    if (status) {
      this.fetchStoreData();

      return;
    }

    this.drawer.close();
  }

  /**
   * filter the data source using chips
   * @param filter
   */
  getFilteredData(filter: Filter): void {
    filter.selected = !filter.selected;
    this.driverDataSource.filter = "true";
  }
}

import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

/**
 * show the given image in the popup
 */
@Component({
  selector: "app-speedo-meter-image",
  templateUrl: "./speedo-meter-image.component.html",
  styleUrls: ["./speedo-meter-image.component.scss"],
})
export class SpeedoMeterImageComponent {
  imagePath: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    public dialogRef: MatDialogRef<SpeedoMeterImageComponent>,
  ) {
    if (data) {
      this.imagePath = data;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

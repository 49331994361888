import { Component, Input, OnInit } from "@angular/core";
import { LocationPing } from "@tendercuts/models";

@Component({
  selector: "app-location-ping-marker",
  templateUrl: "./location-ping-marker.component.html",
  styleUrls: ["./location-ping-marker.component.scss"],
})
export class LocationPingMarkerComponent implements OnInit {
  @Input() ping: LocationPing;
  @Input() label: string;
  public displayWindow: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}

import { Component, Inject, Input, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ChangeLogs } from "@tendercuts/models";

/**
 * component to show the trip earning and trip sheet logs
 */
@Component({
  selector: "app-change-logs",
  templateUrl: "./change-logs.component.html",
  styleUrls: ["./change-logs.component.scss"],
})
export class ChangeLogsComponent {
  // change logs og trip earning and trip sheets
  @Input()
  changeLogs: ChangeLogs[] = [];

  /**
   * component using in both selector type and entry component so used optional
   */
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ChangeLogs[],
    @Optional() public dialogRef: MatDialogRef<ChangeLogsComponent>,
  ) {
    if (data) {
      this.changeLogs = data;
    }
  }
}

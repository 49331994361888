<div class="fixed-header">
  <mat-toolbar class="title">
    <mat-toolbar-row>
      <span>Inventory Update</span>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div *ngIf="checkForAccessTime(); else timeout">
  <div class="container d-flex justify-content-center">
    <mat-card class="card m-2">
      <mat-card-header>
        <mat-card-title class="title"> UPLOAD INVENTORY SHEET </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p class="para-text">1. Only CSV file allowed to upload</p>
        <p class="para-text">2. Headers - sku | qty | store_name</p>
        <input
          type="file"
          #file
          style="display: none"
          (change)="onFilesAdded()"
        />
        <div *ngIf="fileToUpload">
          <h5>{{ fileToUpload?.name }} file ready to upload</h5>
          <mat-progress-bar
            *ngIf="fileUploading"
            mode="indeterminate"
          ></mat-progress-bar>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button
          *ngIf="!fileToUpload; else upload"
          [disabled]="uploading || uploadSuccessful"
          mat-raised-button
          color="primary"
          class="add-files-btn"
          (click)="addFiles()"
        >
          Add CSV File
        </button>
        <ng-template #upload>
          <button
            mat-raised-button
            color="primary"
            class="add-files-btn"
            (click)="removeFile()"
          >
            Remove CSV File
          </button>
        </ng-template>
        <button
          mat-raised-button
          color="primary"
          [disabled]="!fileToUpload"
          (click)="uploadFileToServer()"
        >
          Upload
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

<ng-template #timeout>
  <div style="margin: 10%">
    <h2 style="text-align: center">
      Stock Update is available only between 8:00 Am - 8:00 PM
    </h2>
  </div>
</ng-template>

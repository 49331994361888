import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Address, User } from "@tendercuts/models";

import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { FilterModel } from "src/models";
import { BasePage } from "../../utils/pages/base/base.component";
import { CustomerAddressDataSource } from "../data-source";
import { ColumnAction } from "../table-actions";

@Component({
  selector: "app-crm-customer-address",
  templateUrl: "./crm-customer-address.component.html",
  styleUrls: ["./crm-customer-address.component.scss"],
})
export class CrmCustomerAddressComponent extends BasePage implements OnInit {
  address: Address[] = [];
  @Input() customerObj: User = null;

  columnsToDisplay: string[] = ["fullAddress", "designatedStore"];
  titleToDisplay: string[] = ["Address", "Store"];
  actions: ColumnAction[] = [];
  modelFilter: FilterModel = new FilterModel([]);

  @Input()
  customerAddressDataSource: CustomerAddressDataSource = new CustomerAddressDataSource(
    this.modelFilter,
    [],
  );

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    this.address = this.customerObj.address;
    this.customerAddressDataSource.data = this.address;
  }
}

import { DefaultSerializationMixin, Serializable } from "./base";
import { Store } from "./store";
import { Address } from "./address";
import { DriverOnlineStatus } from "./driver-online-status";

export class User
  extends DefaultSerializationMixin
  implements Serializable<User>
{
  public entity_id: string;
  public email: string;
  public mobilenumber: string;
  public firstname: string;
  public lastname: string;
  public message: string;
  public password: string;
  public address: Address[];
  public token: string;
  public default_billing: any;
  public default_shipping: any;
  public dnd: number;
  private group_id: number;
  public groups = [];
  private fs_token: string;
  private new_user: boolean;
  private email_updated: boolean;
  private order_count: number;
  private is_account_delete_scheduled: boolean;
  private account_delete_scheduled_time: string;

  public reward_points: number;
  public store_credit: number;

  get wallet() {
    return this.store_credit;
  }

  // Pumped in variables
  // DEPRECATED, use store_id instead.
  private _store: Store;

  //driver-app
  public phone: string;
  private driverOnlineStatus: DriverOnlineStatus;
  public store_id: string;
  get storeId() {
    if (!this.store_id) {
      return [];
    }
    return this.store_id.split(",").map(parseInt);
  }

  constructor() {
    super();
  }

  deserialize(input) {
    if (input == null || input == undefined) {
      return this;
    }

    this.entity_id = input.entity_id;
    this.email = input.email;
    this.email_updated = input.email_updated;
    this.mobilenumber = input.mobilenumber;
    this.reward_points = input.reward_points;
    this.store_credit = input.store_credit;
    this.firstname = input.firstname;
    this.lastname = input.lastname;
    this.message = input.message;
    this.token = input.token;
    this.default_shipping = input.default_shipping;
    this.default_billing = input.default_billing;
    this.dnd = input.dnd;
    this.fs_token = input.fs_token;
    this.group_id = +input.group_id;
    this.new_user = input.new_user;
    this.order_count = input.order_count;
    this.is_account_delete_scheduled = input.is_account_delete_scheduled;
    this.account_delete_scheduled_time = input.account_delete_scheduled_time;

    // backend apps
    this.store_id = input.store_id;

    //driver-app
    this.phone = input.mobilenumber || input.phone;
    //fetched from local storage
    if (input.driverOnlineStatus)
      this.driverOnlineStatus = new DriverOnlineStatus().deserialize(
        input.driverOnlineStatus
      );
    // fetched from local storage
    if (input._store) this._store = new Store().deserialize(input._store);

    this.address = [];
    if (input.address != null) {
      for (var address of input.address) {
        this.address.push(new Address().deserialize(address));
      }
    }

    // backend groups
    if (input.groups) {
      this.groups = input.groups;
    }

    return this;
  }

  get isAccountDeleteScheduled(): boolean {
    return this.is_account_delete_scheduled;
  }

  get accountDeleteScheduledTime(): string {
    return this.account_delete_scheduled_time;
  }

  get fsToken() {
    return this.fs_token;
  }

  get defaultAddress() {
    return this.default_shipping;
  }

  get isNewUser(): boolean {
    return this.new_user;
  }

  get fullName() {
    return this.firstname + " " + this.lastname;
  }

  get isEliteCustomer() {
    return this.group_id === 4;
  }

  get groupId() {
    return this.group_id;
  }

  get isEmailUpdated(): boolean {
    return this.email_updated;
  }

  /**
   *
   * Override it to handle address
   */
  eavDeserialize(input) {
    // special handling for address
    var addressEav = input.filter((element) => element.code == "address");
    if (addressEav.length == 1) {
      // since we are replacing the whole object blob it
      // is better to deserialize if instead of eavSerializing it
      // as eav is only meant if it is for some attributs
      // each contains code and value
      this.address = addressEav[0].value.map((address) =>
        new Address().deserialize(address)
      );

      // now remove the addressEav
      input = input.filter((element) => element.code != "address");
    }

    return super.eavDeserialize(input);
  }

  serialize() {
    return {
      customer_id: this.entity_id,
      store_id: "7",
    };
  }

  set store(store: Store) {
    this._store = store;
  }

  get store() {
    return this._store;
  }

  get username() {
    return this.email || this.mobilenumber;
  }

  get isValid() {
    return !(this.email == undefined);
  }
  get userId() {
    return this.entity_id;
  }
  //driver-app
  get usernameDriver() {
    return this.email || this.phone;
  }
  get isValidDriver() {
    return !(this.phone == undefined);
  }

  get onlineStatus() {
    return this.driverOnlineStatus;
  }
  set onlineStatus(onlineStatus: DriverOnlineStatus) {
    this.driverOnlineStatus = onlineStatus;
  }

  get isPosUser() {
    return this.groups.some((group) => group == "POS User");
  }

  get isSm() {
    return this.groups.some((group) => group == "Store Manager");
  }

  get isOrderHistoryAccessor() {
    return this.groups.some((group) => group == "City Manager");
  }

  get isDeliveryManager() {
    return this.groups.some((group) => group == "Delivery Manager");
  }

  get isGtgUser() {
    return this.groups.some((group) => group == "GTG User");
  }

  get isCcAgent() {
    return this.groups.some((group) => group == "Call Center");
  }

  get isEnveeUser() {
    return this.groups.some((group) => group == "Envee POS User");
  }

  get isTechOps() {
    return this.groups.some(
      (group) => group == "Tech Ops" || group == "Tech Support"
    );
  }

  get isInventoryManager() {
    return this.groups.some((group) => group == "Inventory Manager");
  }

  get isCcManager() {
    return this.groups.some((group) => group == "Call Center Manager");
  }

  get isClusterManager(): boolean {
    return this.groups.some((group) => group == "Cluster Manager");
  }

  get orderCount(): number {
    return this.order_count;
  }
}

<div
  class="empty"
  id="empty-message"
  *ngIf="!drivers.length; else showActiveRiders"
>
  No Active Riders Found, Please refresh the page and try.
</div>

<ng-template #showActiveRiders>
  <h6 class="header" id="content">Drivers list</h6>
  <app-driver-status-grid
    [dataSource]="driverDataSource"
    [actions]="buttonAction"
    [columnsToDisplay]="columnsToDisplay"
    [titleToDisplay]="titleToDisplay"
  >
  </app-driver-status-grid>
</ng-template>

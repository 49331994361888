import { Injectable } from "@angular/core";
import { Store } from "@tendercuts/models";
import { of, Observable } from "rxjs";

/*
  Generated class for the Store provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/
@Injectable()
export class MockStoreService {
  get cache(): Store[] {
    const jsonDump: any = {
      count: 7,
      next: null,
      previous: null,
      results: [
        {
          name: "Admin",
          code: "admin",
          store_id: 0,
          location: null,
          website_id: 0,
        },
        {
          name: "thoraipakkam",
          code: "thoraipakkam",
          store_id: 1,
          location: {
            longandlatis: { latitude: "12.932999", longitude: "80.232793" },
          },
          website_id: 1,
        },
        {
          name: "adayar",
          code: "adayar",
          store_id: 4,
          location: {
            longandlatis: { latitude: "13.006194", longitude: "80.248289" },
          },
          website_id: 2,
        },
        {
          name: "porur",
          code: "porur",
          store_id: 5,
          location: {
            longandlatis: { latitude: "13.017100", longitude: "80.138710" },
          },
          website_id: 3,
        },
        {
          name: "tambaram",
          code: "tambaram",
          store_id: 7,
          location: {
            longandlatis: { latitude: "12.932701", longitude: "80.128456" },
          },
          website_id: 4,
        },
        {
          name: "arumbakkam",
          code: "arumbakkam",
          store_id: 8,
          location: {
            longandlatis: { latitude: "13.066978", longitude: "80.210991" },
          },
          website_id: 5,
        },
        {
          name: "tpos",
          code: "tpos",
          store_id: 9,
          location: null,
          website_id: 6,
        },
      ],
    };

    return jsonDump.results.map((value) => new Store().deserialize(value));
  }
  getData(): Observable<{
    name: string;
    store_id: number;
    website_id: number;
}> {
    return of({
      name: "Thoraiakkam",
      store_id: 1,
      website_id: 1,
    });
  }
}

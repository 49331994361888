
export enum SignupStepStatus {
    SUCCESS = 0,
    FAILED,
    PENDING,
    PROCESSING
  }
  
  export enum SignUpStepEnum {
    OTPVerified = 0,
    CreatingAccount,
    LogIn,
    ClearingCart,
    SignUpSuccessfull
  }

  export class SignUpSteps{
    nextStepIndex: number = 0;
    currentState: SignUpStepEnum;
    data: any;

    state = {};
    stateNames = [
      "OTP Verified",
      "Creating account",
      "Loggin-In",
      "Creating Cart",
      "SignUp-Sucessfull",
    ]

    constructor() { 
    this.state[SignUpStepEnum.SignUpSuccessfull] = SignupStepStatus.PENDING;
    this.state[SignUpStepEnum.OTPVerified] = SignupStepStatus.PENDING;
    this.state[SignUpStepEnum.CreatingAccount] = SignupStepStatus.PENDING;
    this.state[SignUpStepEnum.LogIn] = SignupStepStatus.PENDING;
    this.state[SignUpStepEnum.ClearingCart] = SignupStepStatus.PENDING;
  }
  
  updateState(step: SignUpStepEnum, state: SignupStepStatus, data = undefined) {
      this.currentState = step;
      this.nextStepIndex = step + 1;
      this.state[step] = state;
      this.data = data;
  }

  signUpStepEnum(){
    return SignUpStepEnum;
  }

   /**
   * @func isStepDone
   * @param stepName
   * @return true if the sate of stepName is success
   */
  isStepDone(stepName: string) {
    var index = this.stateNames.findIndex((val) => { return stepName == val });
    return this.state[index] == SignupStepStatus.SUCCESS;
  }

  /**
   * @func isStepFailed
   * @param stepName
   * @return true if the sate of stepName is falied
   */
  isStepFailed(stepName: string) {
    var index = this.stateNames.findIndex((val) => { return stepName == val });
    return this.state[index] == SignupStepStatus.FAILED;
  }

  /**
   * @func isStepPending
   * @param stepName
   * @return true if the sate of stepName remains pending
   */
  isStepPending(stepName: string) {
    var index = this.stateNames.findIndex((val) => { return stepName == val });
    return this.state[index] == SignupStepStatus.PENDING;
  }

  /**
   * @func isStepProcessing
   * @param stepName
   * @return true if the sate of stepName remains Processing
   */
  isStepProcessing(stepName: string) {
    var index = this.stateNames.findIndex((val) => { return stepName == val });
    return this.state[index] == SignupStepStatus.PROCESSING;
  }

  }
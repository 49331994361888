import { Component, Input, OnInit } from "@angular/core";
import { Trips } from "@tendercuts/models";
import { TripsStatusHistory } from "tendercuts/models/trips";

@Component({
  selector: "app-trip-status",
  templateUrl: "./trip-status.component.html",
  styleUrls: ["./trip-status.component.scss"],
})
export class TripStatusComponent implements OnInit {
  @Input() trip: Trips;

  tripStatusHistory: TripsStatusHistory[] = [];

  constructor() {}

  /**
   * Sort the trip progress by created date and time
   */
  ngOnInit(): void {
    if (!this.trip || this.trip.progress.length == 0) {
      return;
    }
    this.tripStatusHistory = this.trip.progress.sort((a, b) => {
      if (a.createdAt > b.createdAt) {
        return -1;
      }

      return 1;
    });
  }
}

import { Observable } from "rxjs";
import { map, share } from "rxjs/operators";
import { DataProcessingProtocol } from "./processor";

/**
 * Interface that needs to be implemented by a class if it
 * needs to transform the data that it receives from the
 * a HTTP link
 */
// TODO
// eslint-disable-next-line @typescript-eslint/naming-convention
export interface DataTransformationProtocol {
  preProcessResponse?(response: any): any;
  postProcessResponse?(response: any): any;
}

/**
 * DataTransformer is a class that hooks into a base observable
 * wraps a transformation interface around it, such as preprocess
 * and post process
 *
 * 1. Provides caching for the wrapped observable/data
 */
export abstract class BaseDataTransformer<T> {
  // wrapped observale
  protected observable: Observable<any>;

  public delegate: DataTransformationProtocol;
  public processingDelegate: DataProcessingProtocol;

  constructor() {}

  /**
   * params:
   *   Reponse(json object): Raw json response from the base observable
   *
   * Returns:
   *   Processed json object that gets pushed down the pipeline
   */
  protected preProcess(response: any): Array<any> {
    if (
      this.delegate == undefined ||
      this.delegate.preProcessResponse == null
    ) {
      return response;
    }

    return this.delegate.preProcessResponse(response);
  }

  /**
   * params:
   *   Reponse(Array of Models<Serialize>):Response from the process data obserble
   *
   * Returns:
   *   Processed json object that gets pushed down the pipeline
   */
  protected postProcess(response: any): Array<any> {
    if (
      this.delegate == undefined ||
      this.delegate.postProcessResponse == null
    ) {
      return response;
    }

    return this.delegate.postProcessResponse(response);
  }

  /**
   * getData public endpoint
   *
   * TODO: Move to an interface.
   *
   */
  public getData(...args: any[]): Observable<any> {
    const process: any = this.processingDelegate.processResponse.bind(this);
    const postProcess: any = this.postProcess.bind(this);
    const preProcess: any = this.preProcess.bind(this);
    // not in cache lets generate the observable
    const baseObservable: Observable<any> = this.generateBaseObservable(...args);

    // wrap it with hooks
    this.observable = baseObservable.pipe(
      map(preProcess),
      map(process),
      map(postProcess),
      share(),
    );

    return this.observable;
  }

  /**
   * Sub class should extend it and return an observable
   * @param args Any number of args
   */
  protected abstract generateBaseObservable(...args: any[]): Observable<any>;
  // TODO
  // we need to add processResponse as implementation/ interface
}

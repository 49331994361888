import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import * as selectors from "@store/state";
import { ActionLoadCities, ActionSetStore, StoreState } from "@store/store";
import { Store as TcStore, TcutsCity } from "@tendercuts/models";
import { Observable, Subscription } from "rxjs";
import { map, skipWhile, take } from "rxjs/operators";
import { BasePage } from "src/app/utils";

@Component({
  selector: "app-store-search",
  templateUrl: "./store-search.component.html",
  styleUrls: ["../content/styles/top-navbar-content.scss"],
})
export class StoreSearchComponent
  extends BasePage
  implements OnInit, OnDestroy {
  // list of stores from redux for store managers access
  @Input() accessibleStores: TcStore[];

  sortedCity: TcutsCity[] = [];

  // To store current store id
  selectedStoreId: number;

  // To store current store name
  selectedStoreName: string;

  storeSub$: Subscription;

  cities$: Subscription;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    this.fetchStoreDetail();
    this.fetchCityBasedStores();
  }

  fetchStoreDetail(): void {
    this.storeSub$ = this.stores.subscribe((state: StoreState) => {
      this.selectedStoreId = state.store;

      state.availableStores.filter((data) => {
        if (data.storeId == this.selectedStoreId) {
          this.selectedStoreName = data.storeAttribute.displayName;
        }
      });
    });
  }

  get stores(): Observable<StoreState> {
    return this.store.select(selectors.getStoreState);
  }

  /*
   * we fetch total stores from city api response from redux,
   * with Based on the input accessibleStores we filter with city and push resp stores,
   * if accessibleStores is empty, then all stores will be accessible
   */
  async fetchCityBasedStores(): Promise<void> {
    await this.store.dispatch(new ActionLoadCities());

    const cityData: Observable<TcutsCity[]> = await this.storeState.pipe(
      skipWhile((state) => state.cities.length == 0),
      map((state) => state.cities),
      take(1)
    );

    this.cities$ = cityData.subscribe((cities: TcutsCity[]) => {
      if (cities.length && !this.accessibleStores.length) {
        this.sortedCity = cities;

        return;
      }

      const cityDataResponse: TcutsCity[] = cities.filter(
        (city) =>
          (city.filteredStores = this.accessibleStores.filter(
            (store) =>
              store.storeAttribute.city.toLocaleLowerCase() ==
              city.name.toLocaleLowerCase()
          ))
      );
      this.sortedCity = cityDataResponse.filter((city) => city.filteredStores.length);
    });
  }

  /** To make autofocus over the store searchBar */
  focusOnSearchBar(): void {
    const storeSearch: any = document.getElementById("searchbar-store");
    setTimeout(() => {
      storeSearch.focus();
    }, 500);
  }

  /**
   * search for store
   * @param searchString, with it we filter out data from available stores list
   * returns filtered output
   * filteredStoresData => setter to store filtered stores
   */
  applyStoreSearchFilter(searchString: string): void {
    // Filters wrt accessibleStores
    if (this.accessibleStores.length) {
      this.filterAccessibleStores(searchString);

      return;
    }

    // Filters wrt city access
    this.sortedCity.forEach(
      (city: TcutsCity) =>
        (city.filteredStoresData = city.storesList.filter((store: TcStore) => {
          return this.filterStores(store, searchString);
        }))
    );
  }

  /**
   *
   * @param searchString ie entered text value,
   * filteredStoresData => setter to store the array of filtered stores,
   * which matches the same city name
   */

  filterAccessibleStores(searchString: string): void {
    this.sortedCity.filter(
      (city: TcutsCity) =>
        (city.filteredStoresData = this.accessibleStores.filter(
          (store: TcStore) => {
            if (
              store.storeAttribute.city.toLocaleLowerCase() ==
              city.name.toLocaleLowerCase()
            ) {
              return this.filterStores(store, searchString);
            }
          }
        ))
    );
  }

  /**
   * @param store store data
   * @param searchString search string from entered input
   * @returns true if search string matches store name, else returns false
   */
  filterStores(store: TcStore, searchString: string): boolean {
    return store.storeAttribute.displayName
      ? store.storeAttribute.displayName
          .toLowerCase()
          .includes(searchString.toLowerCase())
      : false;
  }

  /**
   * @param selectedStoreId is passed to load particular store details
   */
  changeStore(): void {
    this.store.dispatch(new ActionSetStore(this.selectedStoreId));
    this.clearStores();
    window.location.reload();
  }

  ngOnDestroy(): void {
    this.storeSub$?.unsubscribe();
    this.cities$?.unsubscribe();
  }
}

/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { Serializable } from "../base";
import { Store } from "../store/store";
import { Category } from "./category";
import { Inventory } from "./inventory";
import { ProductPrice } from "./product-price";

export enum ProductDisplayMode {
  DETAIL = 0,
  COMPACT,
  ULTRACOMPACT,
  SUGGESTION,
  RECOMMENDED,
  CHECKOUT,
  FREEDELIVERYPUSHMODE,
}

enum Visibility {
  CATALOG = 2,
  SEARCH,
  CATALOG_SEARCH,
  PARENT_CATALOG,
}

export enum IsScannable {
  NOT_SCANNABLE = 0,
  SCANNABLE = 1,
  SCANNABLE_WITH_WEIGHT_AND_DATE = 2,
}

/** Source type is sent to subscribers to differentiate in which mode the product was added */
export enum ProductSourceType {
  catalog = "CATALOG",
  cart = "CART",
  addOn = "ADD_ON",
  suggestion = "SUGGESTION",
  freePush = "FREE_PUSH",
  topPicksForYou = "TOP_PICKS_FOR_YOU",
  youMayAlsoLike = "YOU_MAY_ALSO_LIKE",
}

export class Product implements Serializable<Product> {
  public name: string;
  public gross: string;
  public net: string;
  public description: string;
  public short_description: string;
  public image: string;
  public price: ProductPrice;
  public thumb: string;
  public sku: string;
  public uom: number;
  public id: number;
  public spicyLevel: number;
  public gramsPerUnit: number;
  public weight_from: number;
  public weight_to: number;
  public relatedProducts: number[];
  public serves: number;
  public pieces: string;
  public categoryId: number;
  public parentId: number;
  public category_url_key: string;
  public inventory: Inventory;
  public sort_order: number;
  public url_key: string;
  public uom_value: string;
  public tax_percent: number;
  // after browser refresh getters not work,so changed this into public
  public type_id: string;

  private status: number;
  private visibility: number;
  private bar_code: string;
  private parent_sku: string;
  private plu_code: number;
  private video_url: string = "";
  private video_label: string = "";
  private deeplink: string = "";
  private category_name: string = "";
  private qty_inc: number;
  private upsell_products: number[] = [];
  private link_inventory_products: number[] = [];
  private tag: string[] = [];
  private tag_label: string = "";
  private weight_label: string;
  private _store: Store;
  private feed_id: string;
  private hsn_code: string;
  private is_scannable: number;
  // Flag for show gross and net in ui
  private show_gross_net: boolean;

  get store(): Store {
    return this._store;
  }

  set store(store: Store) {
    this._store = store;
  }

  get isVisibleInCatalog(): boolean {
    return (
      [Visibility.CATALOG, Visibility.CATALOG_SEARCH].indexOf(this.visibility) >
      -1
    );
  }

  get upsellProducts(): number[] {
    return this.upsell_products;
  }

  get linkInventoryProducts(): number[] {
    return this.link_inventory_products;
  }

  get videoLink(): string {
    return this.video_url;
  }

  get videoLabel(): string {
    return this.video_label ? this.video_label : "Cooking instructions";
  }

  get deepLink(): string {
    return this.deeplink;
  }

  get productTags(): string[] {
    return this.tag;
  }

  get productLabel(): string {
    return this.tag_label;
  }

  get feedId(): string {
    return this.feed_id;
  }

  // not sure if it needs to be a weak reference
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public category: Category;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  deserialize(input: any): this {
    this.status = input.status;
    this.video_label = input.video_label;
    this.video_url = input.video_url;
    this.deeplink = input.deeplink;
    this.tag = input.tag;
    this.tag_label = input.tag_label;
    this.gross = input.gross_weight_description;
    this.thumb = input.thumb;
    this.visibility = input.visibility;
    this.short_description = input.short_description;
    this.net = input.weight_description;
    // not being used, need to update!!
    this.image = input.image;
    this.sku = input.sku;
    this.serves = input.serves;
    this.pieces = input.pieces;
    this.name = input.name;
    this.description = input.description;
    this.uom = input.uom;
    this.id = input.entity_id;
    this.spicyLevel = input.spicy;
    this.gramsPerUnit = input.gramsperunit;
    this.weight_from = input.weight_from;
    this.weight_to = input.weight_to;
    this.weight_label = input.weight_label;
    this.sort_order = input.sort_order;
    this.url_key = input.url_key;
    this.categoryId = input.category_id;
    this.category_name = input.category_name;
    this.uom_value = input.uom_value;
    this.type_id = input.type_id;
    this.tax_percent = input.tax_percent ? input.tax_percent : 0;
    this.parentId = input.parent_sku;
    this.parent_sku = input.parent_sku;
    this.upsell_products = input.upsell_products;
    this.link_inventory_products = input.link_inventory_products;
    this.category_url_key = input.category_url_key;
    this.feed_id = input.feed_id;
    this.price = new ProductPrice().deserialize({
      price: input.price,
      special_price: input.special_price,
      elite_price: input.elite_price,
      offer_price: input.offer_price,
      per_month_subs_price: input.per_month_subs_price,
    });
    this.plu_code = input.plu_code;
    this.bar_code = input.bar_code;
    this.qty_inc = input.qty_inc;
    this.hsn_code = input.hsn_code;
    this.is_scannable = input.is_scannable;
    this.show_gross_net = input.show_gross_net;

    return this;
  }

  ogTagSerializer(
    prodMetaTitle: string,
    prodMetaDescription: string,
    ogType?: string // For website 'ogType' = website, For m-site 'ogType' = mobile-site.
  ): {
    metaTitle: string;
    metaDesc: string;
    name: string;
    type: string;
    image: string;
  } {
    return {
      metaTitle: prodMetaTitle,
      metaDesc: prodMetaDescription,
      name: this.categoryName,
      type: ogType,
      image: this.thumb,
    };
  }

  twitterTagSerializer(
    prodMetaTitle: string,
    prodMetaDescription: string
  ): {
    metaTitle: string;
    metaDesc: string;
    name: string;
    card: string;
    image: string;
  } {
    return {
      metaTitle: prodMetaTitle,
      metaDesc: prodMetaDescription,
      name: this.categoryName,
      card: "summary",
      image: this.thumb,
    };
  }

  get barcode(): string {
    return this.bar_code;
  }

  get isScannable(): number {
    return this.is_scannable;
  }

  // uom label
  get weightLabel(): string {
    return this.weight_label;
  }

  get categoryName(): string {
    return this.category_name;
  }

  /**
   * Flag to show Antibiotic tag in UI for chicken category
   */
  get isChicken(): boolean {
    return this.categoryId && this.categoryId === 4;
  }

  get parentSku(): string {
    return this.parent_sku;
  }

  get productType(): string {
    return this.parent_sku;
  }

  get showGrossNet(): boolean {
    return this.show_gross_net;
  }

  get isActive(): boolean {
    return this.status === 1;
  }

  get uomValue(): string {
    return this.uom_value;
  }

  // Returns true if product can only be visible in catalog(i.e Parent product)
  get isCustomisable(): boolean {
    return this.visibility === Visibility.CATALOG;
  }

  // Returns true if product can only searched in catalog(i.e child products)
  get isSearchOnlyVisible(): boolean {
    return this.visibility === Visibility.SEARCH;
  }

  // Returns true if product can be present anywhere(catalog,catalog search etc)
  get isOmniPresent(): boolean {
    return this.visibility === Visibility.CATALOG_SEARCH;
  }

  // Returns true if the product is a Parent product which is not shown in the frontend
  get isParentCatalog(): boolean {
    return this.visibility === Visibility.PARENT_CATALOG;
  }

  get isPackItem(): boolean {
    return this.uom_value == "Pack";
  }

  get isSimple(): boolean {
    return this.type_id == "simple";
  }

  get isPromoProduct(): boolean {
    return this.type_id == "promo";
  }

  get type(): string {
    return this.type_id;
  }

  get isSubscription(): boolean {
    return this.type_id == "virtual";
  }

  get qtyIncrement(): number {
    return this.qty_inc;
  }

  /**
   * Return the gross weight of the product.
   */
  get grossWeight(): string {
    return `Gross : ${this.weight_from} - ${this.weight_to} ${this.weightLabel}`;
  }

  get hcGrossWeight(): string {
    return +this.weight_from === +this.weight_to
      ? `${this.weight_from || this.weight_to || 0} ${
          this.weightLabel || "Gms"
        }`
      : `${this.weight_from} - ${this.weight_to} ${this.weightLabel || "Gms"}`;
  }

  /**
   * Return the net weight of the product.
   */
  get netWeight(): string {
    const grossWeight: string = this.net ? this.net : "NA";
    const netWeight: string = this.gross ? this.gross : "NA";

    return `Net : ${grossWeight} - ${netWeight} ${this.weightLabel}`;
  }

  get pluCode(): number {
    return this.plu_code;
  }

  get hsnCode(): string {
    return this.hsn_code;
  }

  get displayWeight(): string {
    return this.weight_from == this.weight_to
      ? `${this.weight_from} ${this.weightLabel}`
      : `${this.weight_from} - ${this.weight_to} ${this.weightLabel}`;
  }

  // Returns true for if the product is a seafood
  get isSeafood(): boolean {
    return this.sku.includes("SF_");
  }

  // // Override JSON.stringify to avoid the circular reference
  // toJSON() {
  //     function replacer(key, value) {
  //         // Make sure we skip category
  //         if (key == 'category')
  //             return this.category.categoryId;
  //         else
  //             return value
  //     }
  //
  //     return JSON.stringify(this, replacer)
  // }

  // if gross and net both will not null it will return true else return false
  get canShowNet(): boolean {
    return this.gross && this.net ? true : false;
  }
}

<mat-card class="image">
  <mat-card-title class="row no-gutters image__title">
    <div class="col-6">Speedo Meter Image</div>
    <div class="col-6 image__title__close-btn">
      <button (click)="closeDialog()" mat-stroked-button color="primary">
        cancel
      </button>
    </div>
  </mat-card-title>
  <mat-card-content class="image__content">
    <img [src]="imagePath" alt="" />
  </mat-card-content>
</mat-card>

import { Serializable } from './base';

export class NewAddressResponse implements Serializable<NewAddressResponse> {
    public result: boolean;
    public addressId: string;
    constructor() { }

    deserialize(input) {
        this.result = input.result;
        this.addressId = input.addressId;
        return this;
    }
}

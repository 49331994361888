<mat-toolbar class="title">
  <mat-toolbar-row>
    <span>Historic Sales Dashboard</span>
  </mat-toolbar-row>
</mat-toolbar>

<br />
<div class="row filters">
  <mat-card class="col-12">
    <mat-card-title>
      <mat-form-field class="col-8">
        <input
          matInput
          placeholder="Search order Id/Driver Name/Driver Number"
          #search
        />
      </mat-form-field>
      <button
        mat-raised-button
        class="col-3"
        color="primary"
        (click)="filterOrders(search)"
      >
        Search
      </button>
    </mat-card-title>
    <mat-card-content> </mat-card-content>
  </mat-card>
</div>
<br />

<div class="row table-side">
  <!-- When user just logged in, so there will be no selected store -->
  <div *ngIf="!(storeState | async).store">
    <i class="no-driver-icon fa fa-home"></i>
    <p>Please Select A Store</p>
  </div>

  <app-sale-order-grid
    [orders]="orders"
    [rowColoring]="false"
    [columnsToDisplay]="columnsToDisplay"
    (onPageChange)="onPageChange($event)"
    (onOrderSelected)="onOrderSelected($event)"
  ></app-sale-order-grid>
</div>

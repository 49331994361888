<ng-container *ngIf="direction">
  <agm-direction
    #directionDisplay
    [origin]="direction.origin"
    [destination]="direction.destination"
    [waypoints]="direction.waypoints"
    [markerOptions]="{ waypoints: direction.waypoints }"
    [visible]="direction.visible"
    (afterRender)="onAfterRender($event)"
  ></agm-direction>
</ng-container>

import { Component, Input, OnInit } from "@angular/core";
import { DriverEarningSlab, WeeklyTripSheet } from "@tendercuts/models";

/**
 * component to show driver slab progress
 */
@Component({
  selector: "app-slab-progress-bar",
  templateUrl: "./slab-progress-bar.component.html",
  styleUrls: ["./slab-progress-bar.component.scss"],
})
export class SlabProgressBarComponent {
  @Input()
  weeklyTripSheet: WeeklyTripSheet;

  availableSlabs: DriverEarningSlab[] = [];

  // generate the slabmap with slab name as id
  slabMap: {} = {};

  @Input() set slabs(inputSlabs: DriverEarningSlab[]) {
    this.availableSlabs = inputSlabs;
    this.availableSlabs.forEach(
      (slab) => (this.slabMap[slab.slab_name] = slab),
    );
  }

  constructor() {}

  /**
   * Returns true if driver has achieved the slab`s target
   * @param slab
   */
  isCurrentSlab(slab: DriverEarningSlab): boolean {
    return this.weeklyTripSheet.incentiveEarning >= slab.target;
  }

  get currentSlabTarget(): number {
    const currentSlab: DriverEarningSlab = this.slabMap[this.weeklyTripSheet.slabName];

    return currentSlab ? currentSlab.target : 0;
  }
}

<ng-container
  *ngIf="direction.origin && direction.destination; else noLiveTrack"
>
  <div class="row">
    <mat-chip-list class="col-9">
      <ng-container *ngFor="let order of pendingOrder; let last = last">
        <mat-chip color="primary">
          {{ order.incrementId }}
        </mat-chip>
        <span *ngIf="!last">-----</span>
      </ng-container>
    </mat-chip-list>

    <p class="col-3">
      <span> <b>ETA</b> {{ time }} Mins </span>
      <span> <b>Distance</b> {{ distance }} Km </span>
    </p>
  </div>

  <div class="row map">
    <agm-map
      [zoom]="13"
      [zoomControlOptions]="{ position: 3 }"
      [latitude]="direction.origin.lat"
      [longitude]="direction.origin.lng"
      [disableDefaultUI]="false"
      [zoomControl]="true"
    >
      <agm-marker
        [latitude]="direction.origin.lat"
        [longitude]="direction.origin.lng"
        [label]="'Driver'"
        [iconUrl]="'assets/marker/bike.png'"
      ></agm-marker>

      <agm-marker
        [latitude]="direction.destination.lat"
        [longitude]="direction.destination.lng"
        [label]="'Order'"
        [iconUrl]="'assets/marker/green.png'"
      ></agm-marker>

      <agm-direction
        [origin]="direction.origin"
        [destination]="direction.destination"
        [waypoints]="direction.waypoints"
        [markerOptions]="{ waypoints: direction.waypoints }"
        (afterRender)="onAfterRender($event)"
      ></agm-direction>
    </agm-map>
  </div>
</ng-container>

<ng-template #noLiveTrack>
  <p>Live track not available</p>
</ng-template>

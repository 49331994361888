import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { InventoryUpdateService } from "@tendercuts/providers";
import { BasePage } from "../../utils";

@Component({
  selector: "app-inventory-update",
  templateUrl: "./inventory-update.component.html",
  styleUrls: ["./inventory-update.component.scss"],
})
export class InventoryUpdateComponent
  extends BasePage
  implements AfterViewInit {
  @ViewChild("file") file: any;
  public fileToUpload: File;
  // TO display mat progressbar during upload
  fileUploading: boolean = false;

  constructor(
    public inventoryUpdateService: InventoryUpdateService,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public dialog: MatDialog,
  ) {
    super(dialog, snackBar, store);
  }

  ngAfterViewInit(): void {
    this.file.nativeElement.value = null;
    this.fileToUpload = null;
  }

  addFiles(): void {
    this.file.nativeElement.click();
  }

  checkForAccessTime(): boolean {
    const currentHour: number = new Date().getHours();
    const currentMinutes: number = new Date().getMinutes();
    if (8 <= currentHour && currentHour <= 20) {
      return true;
    }

    return false;
  }

  /**
   * Rest the file by removing the file input element value
   */
  resetFile(): void {
    this.file.nativeElement.value = null;
    this.fileUploading = false;
  }

  /**
   * Check the files type is csv or not
   * If csv file then ready to upload
   * if not a csv file throw error notification
   */
  onFilesAdded(): void {
    const files: { [key: string]: File } = this.file.nativeElement.files;
    this.fileToUpload = files[0];
  }

  /*
   * removes the files after it added
   */
  removeFile(): void {
    this.fileToUpload = null;
    this.file.nativeElement.value = null;
  }
  /**
   * uploads the file to server
   * and response Generic server response
   */
  uploadFileToServer(): void {
    this.fileUploading = true;
    const param: FormData = this.inventoryUpdateService.getParams(
      this.fileToUpload,
    );
    this.inventoryUpdateService.getData(param).subscribe(
      (res) => {
        if (res[0].status) {
          this.showNotification(
            "bottom",
            "center",
            "success",
            "info-circle",
            this.fileToUpload.name + " Uploaded Successfully",
          );
          this.fileToUpload = null;
        } else {
          this.showNotification(
            "bottom",
            "center",
            "danger",
            "info-circle",
            this.fileToUpload.name + " not Uploaded, please check the file",
          );
        }
        this.resetFile();
      },
      (err) => {
        this.somethingWentWrong();
        this.resetFile();
      },
    );
  }
}

import { Serializable } from "../base";

export class NotifyProducts implements Serializable<NotifyProducts> {
  private product_id: string;
  private store_id: number;
  private is_serviced: boolean;
  private user: number;
  private created_time: Date;

  public date: Date;
  public id: number;

  constructor() {}

  deserialize(input) {

    this.product_id = input.product_id;
    this.id = input.id;
    this.store_id = input.store_id;
    this.is_serviced = input.is_serviced;
    this.user = input.user;
    this.created_time = input.created_time;
    this.date = input.date;

    return this;
  }

  get productId() {
    return this.product_id;
  }

  get isServiced(){
    return this.is_serviced;
  }

  get userId() {
    return this.user;
  }
}



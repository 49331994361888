import { Injectable } from "@angular/core";

import { BaseGetProvider } from "@tendercuts/http";
import { CustomerProfile } from "@tendercuts/models";

import { HttpClient, HttpParams } from '@angular/common/http';
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";

@Injectable()
export class CustomerProfileService extends BaseGetProvider<CustomerProfile> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof CustomerProfile {
    return CustomerProfile;
  }

  get endpoint(): string {
    return this.endpointService.customerProfile();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(customerId: string): HttpParams {
    let params: HttpParams = new HttpParams();
    params = params.append("customer_id", customerId);

    return params;
  }
}

/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/naming-convention */
import { Product } from "../catalog";
import { Serializable } from "../base";
import { ProductPrice } from "@tendercuts/models";

export class SaleItem implements Serializable<SaleItem> {
  public itemId: number;
  public name: string;
  public quantity: number;
  // 1 quantity of item selling price
  public price: number;
  public SKU: string;
  // set from product
  public _product: Product;
  // driver-app
  public weight: number;
  public product_id: number;
  public deeplink: string = "";
  // 1 quantity of item original price
  private base_price: number;
  // item selling price
  private row_total: number;
  // item original price
  private base_row_total: number;
  // item tax_amount
  private tax_amount;
  // category tax percentage
  private tax_percent: number;
  // item wise discount amount
  private discount_amount = 0;
  // for weight tracking
  private is_scanned = false;
  private locked_do_ship: number;
  private display_weight: any;
  private product_type: string;
  private parent_item_id: number;
  private plu_code: number;
  private hsn_code: string;
  // shipping amoutn with tax
  private shipping_amount: number;
  // tax for that shipping amount
  private hidden_tax_amount: number;

  // total discount including rewards,promo & disc
  private base_discount_invoiced: number;

  // net value of the item with gst and without discount
  private base_row_invoiced: number;
  private s_base_price: number;
  private s_row_total: number; // sale item row total from server
  private s_base_row_total: number; // sale item base row total from server
  private elite_savings: number = 0;
  private feed_id: string;
  private productImageLink: string;

  constructor() {}

  deserialize(input) {
    this.itemId = input.item_id;
    this.name = input.name;
    this.quantity = input.qty_ordered;
    this.SKU = input.sku;
    this.base_row_total = input.base_row_total;
    this.product_id = input.product_id;
    this.locked_do_ship = input.locked_do_ship;
    this.row_total = input.row_total;
    this.base_price = input.base_price;
    // backend
    this.price = input.price;
    // combo
    this.product_type = input.product_type;
    this.parent_item_id = input.parent_item_id;

    this.tax_amount = +input.tax_amount;
    this.tax_percent = +input.tax_percent;
    this.discount_amount = +input.discount_amount;

    //backend
    this.display_weight = input.weight;
    this.plu_code = input.plu_code;

    // driver-app
    // for front-end weight tracking purpose in driver
    this.weight = 0;
    this.is_scanned = input.is_scanned;
    this.shipping_amount = input.shipping_amount;
    this.hidden_tax_amount = input.hidden_tax_amount;
    this.base_discount_invoiced = input.base_discount_invoiced;
    this.base_row_invoiced = input.base_row_invoiced;

    this.feed_id = input.feed_id;
    this.hsn_code = input.hsn_code;
    this.deeplink = input.deeplink;
    this.productImageLink = input.productImageLink;

    return this;
  }

  serialize() {
    return {
      product_id: this._product.id,
      qty: this.quantity,
    };
  }

  //driver-app
  serializeDriver() {
    return {
      item_id: this.itemId,
      product_id: this.product_id,
      qty_ordered: this.quantity,
      name: this.name,
      weight: this.weight,
      row_total: this.row_total,
    };
  }

  static buildFromProduct(
    product: Product,
    quantity = 0,
    eliteComputation: boolean = false
  ): SaleItem {
    let item = new SaleItem();
    item._product = product;
    item.name = product.name;
    item.quantity = quantity;
    item.base_price = product.price.original_price;
    item.base_row_total = product.price.original_price * quantity;
    item.product_type = product.type_id;
    item.hsn_code = product.hsnCode;
    item.itemId = product.id;
    if (eliteComputation && product.price.offerPrice) {
      item.price = product.price.offerPrice;
      item.row_total = quantity * product.price.offerPrice;
      item.elite_savings = quantity * product.price.eliteSavingsPerUnit;

      return item;
    }
    if (
      product.isSubscription &&
      product.price.special_price < product.price.original_price
    ) {
      item.price = product.price.special_price;
      item.row_total = quantity * product.price.special_price;

      return item;
    }
    // For non elite customers we cant use offer price [POS]
    if (product.price.isSpecialPriceApplicable) {
      item.price = product.price.special_price;
      item.row_total = quantity * product.price.special_price;

      return item;
    }
    item.price = product.price.original_price;
    item.row_total = quantity * product.price.original_price;

    return item;
  }

  // used for item wise apply discount
  set discountAmount(amount) {
    this.discount_amount = amount;
  }
  set serverBasePrice(price: number) {
    this.s_base_price = price;
  }
  set serverRowTotal(price: number) {
    this.s_row_total = price;
  }
  set serverBaseRowTotal(price: number) {
    this.s_base_row_total = price;
  }

  fromProduct(product: Product, quantity = 0) {
    this._product = product;
    this.name = this.product.name;
    this.quantity = quantity;

    this.row_total = this.isItemSpecialPriceAvailable
      ? this.quantity * this.product.price.special_price
      : this.quantity * this.product.price.original_price;
    return this;
  }

  get feedId(): string {
    return this.feed_id;
  }

  /**
   * If tax percent available in sale item model return the value
   * else return from product model
   * In pos tax_percent will come in product model
   */
  get taxPercent() {
    if (this.tax_percent != undefined) {
      return this.tax_percent;
    }

    return this.product.tax_percent;
  }

  get pluCode() {
    return this.plu_code;
  }

  get hsnCode(): string {
    return this.product ? this.product.hsnCode : this.hsn_code;
  }

  /**
   * tax_amount is available only in sale order api.
   * for tax calculation in pos we calculate tax_amount
   */
  get taxAmount() {
    return this.tax_amount;
  }

  get productType() {
    return this.product_type;
  }

  get isComboItem() {
    return this.product_type
      ? this.product_type == "grouped"
      : this._product.type_id == "grouped";
  }

  get isSimple() {
    return this.product_type == "simple";
  }

  get isSubscription() {
    return this.product_type == "virtual";
  }

  get isPromoItem() {
    return this.product_type == "promo";
  }

  get parentItemId() {
    return this.parent_item_id;
  }

  /**
   * set the tax amount for pos
   */
  set taxAmount(amount) {
    this.tax_amount = amount;
  }

  /**items shipping amount total with gst*/
  get shippingAmount() {
    return this.shipping_amount;
  }

  /**shipping hiddentax amount*/
  // 10 = 9 + 1 (1 is hidden tax here,9 is the shipping amount)
  get shippingHiddenTaxAmount() {
    return this.hidden_tax_amount;
  }

  /**shipping amount without gst */
  get shippingAmountWithoutGst() {
    return this.shippingAmount - this.shippingHiddenTaxAmount;
  }

  /**Total discount including rewards,promo & disc*/
  get baseDiscountInvoiced() {
    return this.base_discount_invoiced;
  }

  /**base row total with gst and discount
   * price - 10
   * special price - 5
   * qty - 2
   * base_row_total - 20
   * row_total - 10
   * discount - 10
   * gst - 1
   * base_row_invoiced - 11
   */
  get baseRowInvoiced() {
    return this.base_row_invoiced;
  }

  /** total tax amount item tax amount + shippingamounttax */
  get totalGst() {
    return this.taxAmount + this.shippingHiddenTaxAmount;
  }

  /**
   * row_total is available only in sale order api
   * for tax calculation in pos we calculate rowTotalWithDiscount
   */
  get rowTotalWithDiscount() {
    let rowtotal_discount = this.row_total - this.discount_amount;
    return +rowtotal_discount.toFixed(2);
  }

  get isOutOfStock() {
    return this.locked_do_ship === 1;
  }

  public incrementQuantity() {
    this.quantity += 1;
    if (this.isItemSpecialPriceAvailable) {
      this.row_total += this.product.price.original_price;
    } else {
      this.row_total += this.product.price.special_price;
    }
  }

  public decrementQuantity() {
    this.quantity -= 1;
    if (this.isItemSpecialPriceAvailable) {
      this.row_total -= this.product.price.original_price;
    } else {
      this.row_total -= this.product.price.special_price;
    }
  }

  get canRemove() {
    return this.quantity == 0;
  }

  /**
   * checking product level special price.
   */
  get isItemSpecialPriceAvailable() {
    return this._product.price.special_price != 0 &&
      this._product.price.special_price < this._product.price.original_price
      ? true
      : false;
  }

  get product() {
    return this._product;
  }

  get isStockAvailable(): boolean {
    return this.quantity <= this.product.inventory.total;
  }

  /**
   * A helper to identify if we can serve this quantity.
   * The requested qty should be present in either express or scheduled
   *
   * @return
   *  boolean if we can add or not
   */
  get canAdd(): boolean {
    var maxAvailable = this.product.inventory.total;
    return this.quantity + 1 <= maxAvailable;
  }

  /**
   * If qty is available today for 90 mins delivery
   * @return
   *  boolean
   */
  get isAvailableToday() {
    return (
      this.product.inventory.today != 0 &&
      this.quantity <= this.product.inventory.today
    );
  }

  /**
   * If qty is available for later (D+1) delivery
   * @return
   *  boolean
   */
  get isAvailableLater() {
    return (
      this.product.inventory.future != 0 &&
      this.quantity <= this.product.inventory.future
    );
  }

  /**
   * Convenience getter!
   */
  get totalAvailability() {
    return this.product.inventory.total;
  }

  get todayAvailability() {
    return this.product.inventory.today;
  }

  get futureAvailability() {
    return this.product.inventory.future;
  }

  get thumb() {
    return this.product.thumb;
  }

  get isActive() {
    return true;
  }

  get category() {
    return this.product.category;
  }

  get sku() {
    if (this.product) {
      return this.product.sku;
    }
    return this.SKU;
  }

  get displayWeight() {
    return this.display_weight;
  }

  /**
   * @returns the base row toal price of the product
   * in pos we dont have base row total calculating here
   */
  get baseRowTotal() {
    return this.base_row_total;
  }

  /**
   * Returns the amount saved beacuse of elite
   */
  get eliteSavings(): number {
    return this.elite_savings;
  }
  //driver-app
  /**
   * this.product_id used in backend
   * this.product.id used in pos
   */
  get productId() {
    return this.product_id ? this.product_id : this.product.id;
  }

  get isScanned() {
    return this.is_scanned;
  }

  get uom() {
    if (this.product.uom_value == "Pack") {
      return "Pack";
    }
    return "Kg";
  }
  // backend
  get rowTotal() {
    return this.row_total;
  }

  get basePrice() {
    return this.base_price;
  }

  /**calculate the dicount of the item,the difference between base row total and row total */
  get discountPrice() {
    return this.baseRowTotal - this.rowTotal;
  }

  /**Total Discount (spcial price + coupon discount)*/
  get itemTotalDiscount() {
    return this.discountPrice + this.discount_amount;
  }

  /**Item subtotal without discount and with Gst*/
  get itemSubtotal() {
    return this.rowTotalWithDiscount + this.taxAmount;
  }

  // Returns true for if the product is a seafood
  get isSeafood() {
    return this.sku.includes("SF_");
  }

  get showGrossNet(): boolean {
    return this.product.showGrossNet;
  }

  get serverBasePrice(): number {
    return this.s_base_price;
  }
  get serverRowTotal(): number {
    return this.s_row_total;
  }
  get serverBaseRowTotal(): number {
    return this.s_base_row_total;
  }

  set thumbImage(imageUrl: string) {
    this.productImageLink = imageUrl;
  }

  get thumbImage(): string {
    return this.productImageLink;
  }
}

import { Serializable } from "../base";

export class FaqActions implements Serializable<FaqActions> {
  private action_value: string;
  public action: string;
  private bot_id: string;
  
  constructor() {}

  get actionValue(): string {
    return this.action_value;
  }

  get botId() {
    return this.bot_id;
  }

  get showChatBtn(): boolean {
    return this.action === 'CHAT';
  }

  get showEmailBtn(): boolean {
    return this.action === 'EMAIL';
  }

  get showCallBtn(): boolean {
    return this.action === 'CALL';
  }

  deserialize(input) {
    this.action = input.action;
    this.action_value = input.action_value;
    this.bot_id = input.bot_id

    return this;
  }
}

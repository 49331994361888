import { Component, Input, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { Trips } from "@tendercuts/models";
import { GridRowDetailComponent } from "src/app/components/base-grid/grid-row-detail.component";
import { BasePage } from "src/app/utils";
import { DummyTripEditComponent } from "../dummy-trip-edit";
import { TripsActionButtonsComponent } from "../trips-action-buttons";

/**
 * component to show trip order and trip label im tabel grid
 */
@Component({
  selector: "app-trip-render",
  templateUrl: "./trip-render.component.html",
  styleUrls: ["./trip-render.component.scss"],
  providers: [TripsActionButtonsComponent]
})
export class TripRenderComponent
  extends BasePage
  implements GridRowDetailComponent, OnInit {
  @Input()
  data: any;

  constructor(
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public dialog: MatDialog,
    public tripActionButtonsComponent: TripsActionButtonsComponent
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {}

  /**
   * Fn to open dummy trip name edit component
   * @param trip
   */
  editDummyTripName(trip: Trips): void {
    const editNameDialogRef: MatDialogRef<DummyTripEditComponent, any> =
      this.dialog.open(DummyTripEditComponent, {
        panelClass: "dummy-name-edit",
        data: trip,
        disableClose: true,
        width: "25vw"
      });

    editNameDialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.refreshOrdersAndTrips();
      }
    });
  }

  scanOrders = (trip: Trips): void => {
    this.tripActionButtonsComponent.scanOrders(trip.id.toString());
  }

  assignDriver = (trip: Trips): void => {
    this.tripActionButtonsComponent.assignDriver(trip);
  }
}

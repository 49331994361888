import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-status-filter",
  templateUrl: "./status-filter.component.html",
  styleUrls: ["./status-filter.component.scss"],
})
export class StatusFilterComponent implements OnInit {
  @Output() triggerChange: EventEmitter<any> = new EventEmitter();
  statusOptions = [
    {
      label: "All Orders",
      value: "all",
      icon: "status-all",
    },
    {
      label: "Pending orders",
      value: "pending",
      icon: "status-pending",
    },
    {
      label: "In-Processing",
      value: "processing",
      icon: "status-progress",
    },
    {
      label: "Out for Delivery",
      value: "out_delivery",
      icon: "status-delivery",
    },
    {
      label: "Holded orders",
      value: "hold",
      icon: "status-hold",
    },
    {
      label: "Completed orders",
      value: "complete",
      icon: "status-completed",
    },
    {
      label: "Cancelled orders",
      value: "canceled",
      icon: "status-cancel",
    },
  ];
  statusFilterSelectedOption: {
    value: string;
    label: string;
    icon: string;
  } = this.statusOptions[0];

  onStatusFilterChange(selectedFilter: {
    value: string;
    label: string;
    icon: string;
  }) {
    this.statusFilterSelectedOption = selectedFilter;
    this.triggerChange.emit(this.statusFilterSelectedOption);
  }
  constructor() {}

  ngOnInit(): void {}
}

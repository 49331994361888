import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import {
  GenerateTripSheetResponse,
  GenericServerResponse,
  TripSheet,
} from "@tendercuts/models";
import {
  TripSheetApproveService,
  TripSheetPayService,
  TripSheetPenaltyService,
  TripSheetUpdateService,
} from "@tendercuts/providers";
import { BasePage } from "src/app/utils";
import { TripSheetApproveComponent } from "../trip-sheet-approve";
import { TripSheetConfirmComponent } from "../trip-sheet-confirm";
import { TripSheetPayComponent } from "../trip-sheet-pay";
import { TripSheetPenaltyComponent } from "../trip-sheet-penalty";

@Component({
  selector: "app-trip-sheet-action",
  templateUrl: "./trip-sheet-action.component.html",
  styleUrls: ["./trip-sheet-action.component.scss"],
})
export class TripSheetActionComponent extends BasePage {
  @Input()
  tripSheet: TripSheet;

  // mode to diff actions
  @Input()
  isDeliveryManagerMode: boolean = false;

  // close the dialog and reload the parent
  @Output() public reloadParent: EventEmitter<boolean> = new EventEmitter();

  // update parent`s tripsheet (used only for trip approval & rejections)
  @Output() public updateParent: EventEmitter<TripSheet> = new EventEmitter();
  isEnable: boolean = true;

  constructor(
    private tripSheetUpdateService: TripSheetUpdateService,
    private tripSheetPayService: TripSheetPayService,
    private tripSheetApproveService: TripSheetApproveService,
    private tripSheetPenaltyService: TripSheetPenaltyService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
  ) {
    super(dialog, snackBar, store);
  }

  /**
   * show the popup with data to be change
   * @param status
   */
  approveRequest(): void {
    const dialogRef: MatDialogRef<
      TripSheetConfirmComponent,
      any
    > = this.dialog.open(TripSheetConfirmComponent, {
      width: "350px",
      panelClass: "remove-padding",
      data: this.tripSheet,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data && data.status) {
        this.presentLoader();
        const params: {
          id: number;
          status: number;
          comment: string;
      } = this.tripSheetUpdateService.getApproveRequest(
          this.tripSheet.id,
          1,
          data.requestValue,
          data.comment,
        );
        this.tripSheetUpdate(params);
      }
    });
  }

  /**
   * show the confirmation popup if success update the status
   * @param status
   */
  confirmRequest(): void {
    // Confirm
    const onSuccess: (result: any) => void = (result) => {
      if (!result) {
        return;
      }

      const status: number =
        this.tripSheet.checkInValue == this.tripSheet.correctedCheckInValue &&
        this.tripSheet.checkOutValue == this.tripSheet.correctedCheckOutValue
          ? 2
          : 1;

      this.presentLoader();
      const params: {
         id: number;
         status: number;
        } = this.tripSheetUpdateService.getConfirmRequest(
        this.tripSheet.id,
        status,
      );
      this.tripSheetUpdate(params);
    };

    this.optionsAlert(
      "Are you sure?",
      "Once you confirm this trip sheet. you can't change.",
      onSuccess,
    );
  }

  /**
   * update trip sheet(confirm and raise issue)
   * @param status
   */
  tripSheetUpdate(params: {
    id: number;
    status: number;
}): void {
    this.tripSheetUpdateService.getData(params).subscribe(
      (response: TripSheet) => {
        this.dismissLoader();
        if (response) {
          this.reloadParent.emit(true);
          this.updateParent.emit(response[0]);
          this.dialog.closeAll();
          this.textAlert("Trip Sheet Updated", "");
        }
      },
      (error) => {
        this.dismissLoader();
        this.somethingWentWrong();
      },
    );
  }

  /**
   * trip sheet approve passing tripsheet object and mode of the action
   */
  tripSheetApprove(): void {
    const data: {
      tripSheet: TripSheet;
      isRejectMode: boolean;
    } = { tripSheet: this.tripSheet, isRejectMode: false };
    const dialogRef: MatDialogRef<
      TripSheetApproveComponent,
      any
    > = this.dialog.open(TripSheetApproveComponent, {
      width: "350px",
      panelClass: "remove-padding",
      data,
    });

    dialogRef.afterClosed().subscribe((tripData) => {
      if (tripData && tripData.status) {
        this.presentLoader();
        const params: {
          sheet_id: number;
          action: string;
          amount: string;
          comment: string;
      } = this.tripSheetApproveService.getApproveRequest(
          this.tripSheet.id,
          tripData.amount,
          tripData.comment,
        );
        this.ontripSheetApproved(params);
      }
    });
  }

  /**
   * trip sheet pay amount to rider
   */
  tripSheetPayAmount(): void {
    this.isEnable = false;
    const data: {
      tripSheet: TripSheet;
    } = { tripSheet: this.tripSheet };
    const dialogRef: MatDialogRef<
      TripSheetPayComponent,
      any
    > = this.dialog.open(TripSheetPayComponent, {
      width: "35%",
      panelClass: "remove-padding",
      data,
    });
    dialogRef.afterClosed().subscribe((tripData) => {
      if (!tripData) {
        this.isEnable = true;

        return;
      }
      this.isEnable = true;
      this.reloadParent.emit(true);
    });
  }

  /**
   * trip sheet approve passing tripsheet object and mode of the action
   */
  approveWithOutMbgInc(): void {
    const data: {
      tripSheet: TripSheet;
      isRejectMode: boolean;
      header: string;
    } = {
      tripSheet: this.tripSheet,
      isRejectMode: true,
      header: "Approve with out Mbg/Inc",
    };
    const dialogRef: MatDialogRef<
      TripSheetApproveComponent,
      any
    > = this.dialog.open(TripSheetApproveComponent, {
      width: "350px",
      panelClass: "remove-padding",
      data,
    });

    dialogRef.afterClosed().subscribe((tripData) => {
      if (tripData && tripData.status) {
        this.presentLoader();
        const params: {
          sheet_id: number;
          action: string;
          comment: string;
      } = this.tripSheetApproveService.getApproveWithOutMbgIncRequest(
          this.tripSheet.id,
          tripData.comment,
        );
        this.ontripSheetApproved(params);
      }
    });
  }

  /**
   * trip sheet reject passing tripsheet object and mode of the action
   */
  tripSheetReject(): void {
    const data: {
      tripSheet: TripSheet;
      isRejectMode: boolean;
      header: string;
    } = {
      tripSheet: this.tripSheet,
      isRejectMode: true,
      header: "Reject",
    };
    const dialogRef: MatDialogRef<
      TripSheetApproveComponent,
      any
    > = this.dialog.open(TripSheetApproveComponent, {
      width: "350px",
      panelClass: "remove-padding",
      data,
    });

    dialogRef.afterClosed().subscribe((tripData) => {
      if (tripData && tripData.status) {
        this.presentLoader();
        const params: {
          sheet_id: number;
          action: string;
          comment: string;
      } = this.tripSheetApproveService.getReject(
          this.tripSheet.id,
          tripData.comment,
        );
        this.ontripSheetApproved(params);
      }
    });
  }

  /**
   * adding penalty for the trip
   */
  addPenalty(): void {
    const data: {
      tripSheet: TripSheet;
    } = { tripSheet: this.tripSheet };
    const dialogRef: MatDialogRef<
      TripSheetPenaltyComponent,
      any
    > = this.dialog.open(TripSheetPenaltyComponent, {
      width: "350px",
      panelClass: "remove-padding",
      data,
    });

    dialogRef.afterClosed().subscribe((tripData) => {
      if (tripData && tripData.status) {
        this.presentLoader();
        const params: {
          sheet_id: number;
          penalty_amount: number;
          comment: string;
      } = this.tripSheetPenaltyService.getParams(
          this.tripSheet.id,
          tripData.amount,
          tripData.comment,
        );

        this.tripSheetPenaltyService.getData(params).subscribe(
          (response: GenerateTripSheetResponse[]) => {
            this.dismissLoader();
            this.textAlert(response[0].message, "");
            if (response[0].status) {
              this.updateParent.emit(response[0].result);
            }
          },
          (error) => {
            this.dismissLoader();
            this.somethingWentWrong();
          },
        );
      }
    });
  }

  /**
   * hit the trip sheet approve service with related params
   * @param params
   */
  ontripSheetApproved(params: {
    sheet_id: number;
    action: string;
    amount?: string;
    comment: string;
}): void {
    this.tripSheetApproveService.getData(params).subscribe(
      (response: GenerateTripSheetResponse[]) => {
        this.dismissLoader();
        if (response[0].status) {
          this.updateParent.emit(response[0].result);
          this.textAlert(response[0].message, "");
        }
      },
      (error) => {
        this.dismissLoader();
        this.somethingWentWrong();
      },
    );
  }

  /**
   * check raise issue and confirm actions
   */
  get canShowSMActions(): boolean {
    return this.tripSheet.status == 0 && !this.isDeliveryManagerMode;
  }

  /**
   * check raise issue and confirm actions
   */
  get canShowDMActions(): boolean {
    return this.tripSheet.status == 1 && this.isDeliveryManagerMode;
  }
}

<h4>{{ productDataSource.key | uppercase }} INVENTORY UPDATE</h4>
<app-product-display-tab
  [actions]="buttonActions"
  [dataSource]="productDataSource"
  [columnsToDisplay]="columnsToDisplay"
>
</app-product-display-tab>

<span class="row d-flex justify-content-center align-items-center">
  <i class="fa fa-info-circle mr-2" aria-hidden="true"></i>
  All fields must be entered , if there is no stock enter zero
</span>
<div
  class="bottom-component row d-flex justify-content-center align-items-center"
>
  <button
    class="col-5"
    color="primary"
    [disabled]="isRequestTriggered"
    mat-raised-button
    (click)="dismiss(false)"
  >
    Cancel
  </button>
  <button
    class="col-5 ml-2"
    mat-raised-button
    [disabled]="
      isRequestTriggered || requests.length != productDataSource.data.length
    "
    id="submit-button"
    color="primary"
    (click)="verifyRequests()"
  >
    Submit Request ({{ requests.length }})
  </button>
</div>

<div *ngIf="canShowSMActions">
  <button
    (click)="toApproveWeeklyTripSheet()"
    mat-raised-button
    color="primary"
    id="confirm-btn"
  >
    Raise Issue
  </button>
  <button
    (click)="updateWeeklyTripSheet()"
    mat-raised-button
    color="primary"
    id="confirm-btn"
  >
    Confirm
  </button>
</div>
<div *ngIf="canShowDMActions">
  <button
    (click)="approveWeeklyTripSheet()"
    mat-raised-button
    color="primary"
    id="approve-btn"
  >
    Approve
  </button>
  <button
    (click)="rejectWeeklyTripSheet()"
    mat-raised-button
    color="primary"
    id="reject-btn"
  >
    Reject
  </button>
</div>

<section class="bg-white">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6 px-0">
        <img
          src="../../../assets/login.svg"
          alt="Login image"
          class="w-100-p h-100-v"
          style="object-fit: cover; object-position: left"
        />
      </div>
      <div
        class="col-lg-6 px-0 mt-12 pb-10 d-flex align-items-center justify-content-center"
      >
        <div class="col-md-7">
          <header>
            <div class="text-center">
              <img src="../../../assets/logo/login-logo.svg" />
              <h2 class="display-1 black-1 m-8">Sign in</h2>
              <p class="body-1 grey-3">
                Welcome Back ! Enter your User Id and <br />
                Password below to sign in
              </p>
            </div>
          </header>
          <form class="form-horizontal form-stacked" [formGroup]="formLogin">
            <div class="form-group">
              <label
                class="header-2"
                [attr.for]="'email'"
                [ngClass]="error ? 'primary' : 'grey-1'"
                >Username</label
              >
              <input
                type="text"
                class="w-100-p textbox"
                formControlName="email"
                id="email"
                placeholder="Enter Username"
              />
              <div
                *ngIf="error"
                class="d-flex flex-wrap primary body-2 mt-10 align-items-center"
              >
                <div class="w-16 h-16 bdr-rds-20 bg-primary"></div>
                &nbsp;Couldn’d find the account with this username again
              </div>
            </div>
            <div class="form-group">
              <label
                class="header-2 mt-10"
                [attr.for]="'password'"
                [ngClass]="error ? 'primary' : 'grey-1'"
                >Password
              </label>
              <input
                type="password"
                class="w-100-p textbox"
                formControlName="password"
                id="password"
                placeholder="Enter Password"
              />
              <div
                *ngIf="error"
                class="d-flex flex-wrap primary body-2 mt-10 align-items-center"
              >
                <div class="w-16 h-16 bdr-rds-20 bg-primary"></div>
                &nbsp;Wrong Password . Please enter a valid password and try
                again
              </div>
            </div>
            <div class="mt-30">
              <button
                id="login-btn"
                class="button-primary-lg w-100-p"
                [disabled]="formLogin.invalid"
                (click)="login()"
              >
                <span>Login</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { NgxBarcodeModule } from "ngx-barcode";
import { ComboItemsListComponent } from "../../components";
import { SharedModule } from "../../shared/shared.module";
import { UtilsModule } from "../../utils/utils.module";
import { InvoiceComponent } from "./invoice";
import { PosInvoiceComponent } from "./pos-invoice";
import { PrintInvoiceDialogComponent } from "./print-invoice-dialog";
import { PrintKotInvoiceComponent } from "./print-kot-invoice";
import { TripDetailInvoiceComponent } from "./trip-detail-invoice";

@NgModule({
    declarations: [
        PrintKotInvoiceComponent,
        PrintInvoiceDialogComponent,
        InvoiceComponent,
        TripDetailInvoiceComponent,
        PosInvoiceComponent,
        ComboItemsListComponent,
    ],
    imports: [SharedModule, UtilsModule, CommonModule, NgxBarcodeModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [PrintInvoiceDialogComponent, ComboItemsListComponent]
})
export class PrintModule {}

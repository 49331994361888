<!--Filters-->
<p *ngIf="!noTitle">Select Areas to select orders from.</p>
<mat-chip-list [multiple]="true" [selectable]="true" *ngIf="areaFilterGroup">
  <!-- Area filter -->
  <div class="border">
    <ng-container *ngFor="let filter of areaFilterGroup.filters">
      <span *ngIf="filter.segment.orderCount > 0">
        <mat-chip
          color="primary"
          id="area-seg-{{ filter.segment.segmentId }}"
          (click)="onFilterApplied.emit(filter)"
          [selected]="filter.selected"
        >
          {{ filter.displayName }}

          <ng-container *ngIf="filter.segment.urgentCount > 0">
            (<i class="fa fa-exclamation-circle red" aria-hidden="true"></i>
            {{ filter.segment.urgentCount }} Orders)
          </ng-container>
        </mat-chip>
      </span>
    </ng-container>
  </div>
</mat-chip-list>

<p *ngIf="!noTitle">
  Select Slots (not selecting will lead to picking up all orders).
</p>
<mat-chip-list [multiple]="true" [selectable]="true" *ngIf="slotFilterGroup">
  <!-- slot filter -->
  <div class="border">
    <ng-container *ngFor="let filter of slotFilterGroup.filters; let i = index">
      <mat-chip
        id="slot-seg-{{ i }}"
        color="primary"
        *ngIf="noCount || slotCountMap[filter.displayName] > 0"
        (click)="onFilterApplied.emit(filter)"
        [selected]="filter.selected"
      >
        {{ filter.displayName }} ({{ slotCountMap[filter.displayName] }})
      </mat-chip>
    </ng-container>
  </div>
</mat-chip-list>

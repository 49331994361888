import { Serializable, DefaultSerializationMixin } from "./base";
import { DriverOrder } from './trips';

export class DriverViolation
  extends DefaultSerializationMixin
  implements Serializable<DriverViolation> {

  private corrected_at: Date;
  private corrected_by: string;
  private corrected_lat: string;
  private corrected_lng: string;
  private created_at: Date;
  private customer_lat: string;
  private customer_lng: string;
  private driver_lat: string;
  private driver_lng: string;
  private expected_lat: number;
  private expected_lng: number;
  private driver_order: DriverOrder;
  private force_complete: boolean;
  private is_verified: boolean;
  private store_id: number;
  public status: number;
  public trip: number;
  public distance: number;
  public id: number;
  public ticketStatus: string;

  get correctedBy(): string {
    return this.corrected_by;
  }

  get correctedAt(): Date {
    return this.corrected_at;
  }

  get correctedLat(): string {
    return this.corrected_lat;
  }

  get correctedLng(): string {
    return this.corrected_lng;
  }

  get createdAt(): Date {
    return this.created_at;
  }

  get customerLat(): string {
    return this.customer_lat;
  }

  get customerLng(): string {
    return this.customer_lng;
  }

  get driverLAT(): string {
    return this.driver_lat;
  }

  get driverLNG(): string {
    return this.driver_lng;
  }

  get forceComplete(): boolean {
    return this.force_complete;
  }

  get isVerified(): boolean {
    return this.is_verified;
  }

  get storeId(): number {
    return this.store_id;
  }

  get incrementId(): string {
    return this.driver_order ? this.driver_order.incrementId : "";
  }

  get driverId(): number {
    return this.driver_order ? this.driver_order.driverUser : null;
  }

  get expectedLng(): number {
    return this.expected_lng;
  }

  get expectedLat(): number {
    return this.expected_lat;
  }

  deserialize(input) {
    this.corrected_at = new Date(input.corrected_at);
    this.corrected_by = input.corrected_by;
    this.corrected_lat = input.corrected_lat;
    this.corrected_lng = input.corrected_lng;
    this.created_at = new Date(input.created_at);
    this.customer_lat = input.customer_lat;
    this.customer_lng = input.customer_lng;
    this.driver_lat = input.driver_lat;
    this.driver_lng = input.driver_lng;
    this.force_complete = input.force_complete;
    this.is_verified = input.is_verified;
    this.store_id = input.store_id;
    this.expected_lat = input.expected_lat;
    this.expected_lng = input.expected_lng;
    this.status = input.status;
    this.trip = input.trip;
    this.distance = Math.trunc(input.distance);
    this.id = input.id;

    if (input.driver_order) {
      this.driver_order = new DriverOrder().deserialize(input.driver_order);
    }

    switch (this.status.toString()) {
      case "0":
        this.ticketStatus = "PENDING";
        break;
      case "1":
        this.ticketStatus = "VERIFIED";
        break;
      case "2":
        this.ticketStatus = "VIOLATED";
        break;
      default:
        this.ticketStatus = "";
    }

    return this;
  }
}

<responsive-breakpoints></responsive-breakpoints>
<mat-progress-bar *ngIf="loading" mode="indeterminate"> </mat-progress-bar>
<div class="main-content-outer">
  <ng-container *ngIf="requests.data.length > 0; else noRequests">
    <mat-form-field>
      <input
        matInput
        (keyup)="applySearchFilter($event.target.value)"
        placeholder="Search requests"
      />
    </mat-form-field>

    <app-inventory-request-grid
      [actions]="buttonActions"
      [dataSource]="requests"
    >
    </app-inventory-request-grid>
  </ng-container>

  <ng-template #noRequests>
    <p>No active requests</p>
  </ng-template>
</div>

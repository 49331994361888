<mat-sidenav-container class="trip-dash">
  <div class="fixed-header">
    <div class="row filters">
      <mat-card class="col-12">
        <mat-card-title>
          <mat-form-field class="row">
            <input
              matInput
              id="searchbar"
              (keyup)="applyTripSearchFilter($event.target.value)"
              placeholder="Search Trip-Id/Driver-Number/Order-Id"
              #searchbar
            />
          </mat-form-field>
        </mat-card-title>

        <mat-card-content>
          <mat-chip-list [multiple]="true" [selectable]="true">
            <div class="border">
              <mat-chip
                (click)="clearAllFilter()"
              >
                Clear
              </mat-chip>
            </div>

            <div
              class="border"
              *ngFor="let filterGroup of combinedFilterGroups"
            >
              <mat-chip
                *ngFor="let filter of filterGroup.filters"
                (click)="applyTripStatusFilter(filter)"
                [selected]="filter.selected"
              >
                {{ filter.displayName }}
              </mat-chip>
            </div>

            <button
              class="trip-cash-btn"
              id="cashCollected"
              mat-raised-button
              color="primary"
              (click)="openTodaysTripCash()"
            >
              View Trip Cash
            </button>

            <button
              mat-raised-button
              color="primary"
              id="active-riders"
              class="active-riders"
              (click)="showActiveRiders()"
            >
              Active Drivers
            </button>
          </mat-chip-list>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="space"></div>

  <mat-tab-group
    class="trip-dash__mat-tab-group"
    (selectedTabChange)="loadSelectedStatusData(selectedStatusTabIndex)"
    [(selectedIndex)]="selectedStatusTabIndex"
  >
    <mat-tab *ngFor="let status of statusTabsFilterGroup.filters" [label]="status.displayName">
      <div class="row no-gutters">
        <div class="grid" *ngIf="trips.length > 0">
          <app-drivers-trips-grid
            [tripOrdersDataSource]="tripOrdersDataSource"
            (showTrip)="showTrip($event)"
            class="trips-grid"
          ></app-drivers-trips-grid>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  <mat-sidenav
    disableClose="true"
    [hasBackdrop]="false"
    class="drawer"
    [ngClass]="{ 'active-rider-drawer': showData, drawer: !showData }"
    mode="over"
    position="end"
    #drawer
  >
    <i
      class="menu-item-icon fa fa-window-close-o"
      (click)="closeDrawer(false)"
    ></i>

    <div class="trip-detail" *ngIf="selectedTrip">
      <app-trip-detail
        #Matsort
        (refreshParentEmiter)="closeDrawer($event)"
        [trip]="selectedTrip"
      ></app-trip-detail>
    </div>

    <div class="rider-detail" *ngIf="showData">
      <app-driver-status></app-driver-status>
    </div>
  </mat-sidenav>
</mat-sidenav-container>

<div class="something-went-wrong">
  <span class="alert-modal-icon fa fa-bug"></span>
  <h1 class="alert-modal-title">Oops!</h1>
  <span class="alert-modal-text"
    >Sorry, Something Went Wrong. Contact Tech Support</span
  >
  <div class="alert-modal-buttons">
    <button
      type="button"
      class="btn alert-modal-button"
      [mat-dialog-close]="false"
    >
      OK
    </button>
  </div>
</div>

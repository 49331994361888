<form [formGroup]="driverSuspendForm">
  <div class="driver_suspend">
    <p><strong>Select the option to Archive Rider</strong></p>
    <mat-radio-group formControlName="type" aria-label="Select an option">
      <ion-row>
        <div class="col-12">
          <mat-radio-button value="inactive">Archive</mat-radio-button>
          <p class="driver_suspend__subtitle">
            Use this option if you want to de-activate the rider for not
            accepting booking for a few days
          </p>
        </div>
        <div class="col-12">
          <mat-radio-button value="blocked">Block</mat-radio-button>
          <p class="driver_suspend__subtitle">
            Use this option to blacklist a rider from the platform for
            disciplinary reasons
          </p>
        </div>
      </ion-row>
    </mat-radio-group>
  </div>
  <p><strong>Reason to Archive the Rider</strong></p>
  <mat-form-field class="suspendOrder_form">
    <mat-select placeholder="Choose Reason" formControlName="reason">
      <mat-option *ngFor="let option of options" [value]="option.value">
        {{ option.viewValue }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
<div class="driver_confirmation">
  <h6 class="driver_confirmation__message">
    Are you sure you want archive the Rider ?
  </h6>
  <div class="row no-gutters driver_confirmation__actions">
    <div class="driver_confirmation__cancel">
      <button color="primary" mat-stroked-button mat-dialog-close>
        Cancel
      </button>
    </div>
    <div class="driver_confirmation__confirm">
      <button
        [disabled]="!driverSuspendForm.valid"
        color="primary"
        id="submit"
        mat-raised-button
        (click)="suspendRider()"
      >
        Confirm
      </button>
    </div>
  </div>
</div>

import { InjectionToken } from "@angular/core";
import { Action, ActionReducerMap } from "@ngrx/store";

import { catalogReducer } from "./catalog";
import { driverReducer } from "./driver";
import { orderReducer } from "./orders";
import { AppState } from "./state";
import { storeReducer } from "./store";
import { userReducer } from "./user";

export const reducers: {
  userState: any;
  storeState: any;
  orderState: (state: any, action: any) => any;
  driverState: (state: any, action: any) => any;
  catalog: (state: any, action: any) => any;
} = {
  userState: userReducer,
  storeState: storeReducer,
  orderState: orderReducer,
  driverState: driverReducer,
  catalog: catalogReducer,
};

export const reducerToken: InjectionToken<
  ActionReducerMap<AppState, Action>
> = new InjectionToken<ActionReducerMap<AppState>>("appState");

export function getReducers(): {
  userState: any;
  storeState: any;
  orderState: (state: any, action: any) => any;
  driverState: (state: any, action: any) => any;
  catalog: (state: any, action: any) => any;
} {
  return reducers;
}

export const reducerProvider: {
  provide: InjectionToken<ActionReducerMap<AppState, Action>>;
  useFactory: () => {
    userState: any;
    storeState: any;
    orderState: (state: any, action: any) => any;
    driverState: (state: any, action: any) => any;
    catalog: (state: any, action: any) => any;
  };
}[] = [{ provide: reducerToken, useFactory: getReducers }];

<mat-card>
  <mat-card-title>
    <div>
      Raise Issue for the trip:
      <span (click)="showTripDetails()" class="trip-id">
        {{ tripEarning.tripId }}
      </span>
    </div>
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="tripEarningConfirmForm">
      <mat-label>Click on field to change value</mat-label>
      <label class="row no-gutters">
        <mat-checkbox
          class="checkbox"
          formControlName="showKm"
          id="km-checkbox"
        >
          KM
        </mat-checkbox>
      </label>
      <div class="show-fields" *ngIf="tripEarningConfirmForm.value['showKm']">
        <div class="row no-gutters">Requesting change from</div>

        <div class="row no-gutters align-center">
          {{ tripEarning.finalKms }} ->
          <mat-form-field class="km-change-input">
            <input
              matInput
              formControlName="kmValue"
              placeholder="Enter the actual KM"
              autofocus
              autocomplete="off"
            />
          </mat-form-field>
        </div>
        <mat-form-field>
          <textarea
            matInput
            type="text"
            class="row"
            placeholder="Enter reason for change"
            formControlName="comment"
          ></textarea>
        </mat-form-field>
      </div>
      <app-change-logs [changeLogs]="tripEarning.changes"></app-change-logs>

      <div class="action-btns no-gutters row">
        <button
          (click)="closeDialog()"
          class="close-btn"
          color="primary"
          mat-raised-button
        >
          close
        </button>
        <button
          (click)="tripEarningUpdate()"
          id="submit"
          [disabled]="!tripEarningConfirmForm.valid"
          color="primary"
          mat-raised-button
        >
          Submit
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>

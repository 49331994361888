/* eslint-disable @typescript-eslint/naming-convention */
export class DeliveryDate {
  public name: string;
  public slots: DeliverySlot[] = [];
  public isAvailable: boolean = true;
  public formattedDate: string = "";

  public expressSlot: DeliverySlot;
  public pickupSlots: DeliverySlot[];
  public morningSlots: DeliverySlot[];
  public eveningSlots: DeliverySlot[];
  public morningSlotsWithoutExpress: DeliverySlot[];
  public eveningSlotsWithoutExpress: DeliverySlot[];

  public availableExpressSlots: number;
  public availablePickupSlots: number;
  public availableMorningSlots: number;
  public availableEveningSlots: number;

  public expressToggleFlag: boolean = false;
  public pickupToggleFlag: boolean = false;
  public morningToggleFlag: boolean = false;
  public eveningToggleFlag: boolean = false;

  private is_today: boolean = false;

  /*
   * Makes a copy of delivery date model
   * @param input
   * P.S: Used to take copies of todays slot.
   */
  static makeCopy(input: DeliveryDate): DeliveryDate {
    const copy: DeliveryDate = new DeliveryDate().deserialize({
      date: input.name,
      is_today: input.is_today,
    });
    input.slots.forEach((slot: DeliverySlot) => {
      const slotCopy: DeliverySlot = new DeliverySlot().deserialize({
        ...slot,
        ddate_id: slot.slotId,
      });
      copy.slots.push(slotCopy);
    });
    copy.populateSlots();

    return copy;
  }

  deserialize(input: any): this {
    this.name = input.date;
    this.is_today = input.is_today;
    if (input.times) {
      input.times.forEach((slot) =>
        this.slots.push(new DeliverySlot().deserialize(slot))
      );
    }
    this.populateSlots();

    if (this.name) {
      /**
       * converts i.e 20/11/2020 to Nov 20 - Friday and
       * stores in formattedDate.
       */
      const newDate: Date = new Date(this.name);
      const dateString: string = newDate.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      });
      const dayString: string = newDate.toLocaleDateString("en-US", {
        weekday: "short",
      });
      this.formattedDate =  `${dateString} - ${dayString}`;
    }

    return this;
  }

  get isToday(): boolean {
    return this.is_today;
  }

  populateSlots(): void {
    this.expressSlot = this.slots.find((slot) => slot.isExpress);
    this.pickupSlots = this.slots.filter((slot) => slot.isPickup);
    this.morningSlots = this.slots.filter((slot) => slot.isMorningSlot);
    this.eveningSlots= this.slots.filter((slot) => slot.isEveningSlot);
    this.morningSlotsWithoutExpress = this.morningSlots.filter((slot) => !slot.isExpress);
    this.eveningSlotsWithoutExpress = this.eveningSlots.filter((slot) => !slot.isExpress);

    this.availablePickupSlots = this.pickupSlots.filter(
      (count) => count && !count.isSlotFilled).length;
    this.availableExpressSlots = [this.expressSlot].filter(
      (count) => count && !count.isSlotFilled).length;
    this.availableMorningSlots = this.morningSlotsWithoutExpress.filter(
      (count) => count && !count.isSlotFilled).length;
    this.availableEveningSlots = this.eveningSlotsWithoutExpress.filter(
      (count) => count && !count.isSlotFilled).length;

    if(this.availablePickupSlots != 0){
      this.pickupToggleFlag = true;
    }
    if(this.availableExpressSlots != 0){
      this.expressToggleFlag = true;
    }
    if(this.availableMorningSlots != 0){
      this.morningToggleFlag = true;
    }
    if(this.availableEveningSlots!= 0){
      this.eveningToggleFlag = true;
    }
  }
}

export class DeliverySlot {
  EXPRESS_SLOT_ID: number = -1;
  PICKUP_DELIVERY_TYPE: number = 4;

  public interval: string;
  public cost: string;
  public message: string;
  public original_cost: string;
  private slot_id: number;
  private delivery_type: number;
  private is_full: boolean = false;
  private bc: number;
  private duration: number;

  get slotId(): number {
    return this.slot_id;
  }

  get isMorningSlot(): boolean {
    return this.slot_id < 55;
  }

  get isEveningSlot(): boolean {
    return this.isPickup ? false : this.slot_id >= 55;
  }

  get isExpress(): boolean {
    return this.slot_id == this.EXPRESS_SLOT_ID;
  }

  get originalCost(): string {
    return this.original_cost;
  }

  get isCostDiff(): boolean {
    if (this.cost !== this.original_cost) {
      return true;
    }

    return false;
  }

  get isPickup(): boolean {
    return this.delivery_type == this.PICKUP_DELIVERY_TYPE;
  }

  get deliveryType(): number {
    return this.delivery_type;
  }

  get isSlotFilled(): boolean {
    return this.is_full;
  }

  getSlotContent(): string {
    if (this.isExpress) {
      return "Express Slot";
    }
    if (this.isMorningSlot) {
      return "Morning Slot";
    }
    if (this.isEveningSlot) {
      return "Evening Slot";
    }
    if (this.isPickup) {
      return "Self Pickup";
    }
  }

  /**
   * getter used in backend,
   * to load cut off time for delivery slots in sales dash
   */
  get backendSlotCutOffTime(): number {
    const cutOff: number = this.bc / 60;

    return cutOff;
  }

  /**
   * getter used in backend,
   * to get slot duration ,obtained as seconds,converted to minutes
   */
  get backendSlotCutOffDuration(): number {
    const slotDuration: number = this.duration / 60;

    return slotDuration;
  }

  deserialize(input: any): this {
    this.slot_id = input.ddate_id;
    this.delivery_type = input.delivery_type;
    this.message = input.message;
    this.interval = input.interval;
    this.cost = input.cost;
    this.original_cost = input.original_cost;
    if (input.is_full) {
      this.is_full = input.is_full;
    }
    this.bc = input.bc;
    this.duration = input.duration;

    return this;
  }
}

<ng-container class="tripsheet-wizard">
  <div class="row tripsheet-wizard__close no-gutters">
    <i
      class="tripsheet-wizard__close__icon fa fa-window-close-o"
      (click)="closeDialog(false)"
    ></i>
  </div>
  <mat-horizontal-stepper [selectedIndex]="stepperIndex" #stepper>
    <mat-step>
      <app-speedo-meter-validation
        [driverProfile]="selectedRider"
        [selectedDate]="selectedDate"
        (moveStepper)="moveNextStepper($event)"
      >
      </app-speedo-meter-validation>
    </mat-step>
    <mat-step>
      <app-verify-trip-sheet
        [driverProfile]="selectedRider"
        *ngIf="tripSheet"
        (reloadParent)="closeDialog($event)"
        [tripSheet]="tripSheet"
      ></app-verify-trip-sheet>
    </mat-step>
  </mat-horizontal-stepper>
</ng-container>

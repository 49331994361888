/* eslint-disable @typescript-eslint/naming-convention */
import { Serializable } from "../base";

export class StoreAttribute implements Serializable<StoreAttribute> {

  public city: string;
  public mapUrl: string;
  public mapConstant: string =
    "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=";
  public phone: string;
  public logo: string;
  public area: string;
  private display_name: string;
  private enable_driver_scan: boolean;
  private gst_number: string;
  private enable_live_track: boolean;
  private place_id: string;
  private fssai_no: string;
  private store_type: number;
  private enable_chat: boolean;
  private enable_chat_v2: boolean;
  public radius: number;

  get displayName(): string {
    return this.display_name;
  }

  get storeType(): number {
    return this.store_type;
  }

  get placeId(): string {
    return this.place_id;
  }

  // To check whether order is scanned
  get isDriverScanEnabled(): boolean {
    return this.enable_driver_scan;
  }

  get gstNumber(): string {
    return this.gst_number;
  }

  get isLiveTrackEnabled(): boolean {
    return this.enable_live_track;
  }

  get fssaiCode(): string {
    return this.fssai_no;
  }

  get isChatEnabled(): boolean {
    return this.enable_chat_v2;
  }

  deserialize(input: any): this {
    if (input == null || input == undefined) {
      return this;
    }

    this.city = input.city;
    this.display_name = input.display_name;
    this.place_id = input.place_id;
    this.mapUrl = this.mapConstant + input.place_id;
    this.store_type = input.typeofstore;
    this.enable_driver_scan = input.enable_driver_scan;
    this.gst_number = input.gst_number;
    this.enable_live_track = input.enable_live_track;
    this.phone = input.phone;
    this.fssai_no = input.fssai_no;
    this.enable_chat = input.enable_chat;
    this.enable_chat_v2 = input.enable_chat_v2;
    this.radius = input.radius * 1000;
    this.logo = input.logo;
    this.area = input.area;

    return this;
  }
}

<div class="store-credit">
  <form [formGroup]="storeCreditForm">
    <mat-label class="row label no-gutters"
      >Enter Store Credit Amount</mat-label
    >
    <mat-form-field>
      <input
        matInput
        type="number"
        class="row"
        placeholder="Enter amount"
        autocomplete="off"
        max="1000"
        formControlName="creditControl"
        autofocus
      />
    </mat-form-field>
    <mat-label class="row label no-gutters">Select The Order</mat-label>
    <mat-form-field>
      <mat-select placeholder="Order Id" formControlName="orderId">
        <mat-option *ngFor="let orderId of customerOrdersIdList" [value]="orderId">
          {{ orderId }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-label class="row label no-gutters">Comments</mat-label>
    <mat-form-field>
      <textarea
        matInput
        type="text"
        class="row"
        placeholder="Enter Comment"
        formControlName="commentControl"
      ></textarea>
    </mat-form-field>
    <mat-label class="row label no-gutters">Select Reason</mat-label>
    <mat-form-field>
      <mat-select
        placeholder="choose option"
        formControlName="selectedReasonFormControl"
      >
        <mat-option *ngFor="let option of constants.reasons" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="row comment_btn">
      <button
        [disabled]="
          !this.storeCreditForm.value.creditControl ||
          !this.storeCreditForm.value.orderId
        "
        id="submit_btn"
        mat-button
        class="col-10"
        (click)="submitStoreCredit()"
      >
        Submit
      </button>
    </div>
  </form>
</div>

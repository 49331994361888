<ng-container *ngIf="dailyTripSheet">
  <app-trip-sheet-title
    *ngIf="!checkDeliveryManagerMode"
    [tripSheetDetailMode]="tripSheetDetailMode"
    [tripSheet]="dailyTripSheet"
    [dialogMode]="dialogMode"
    >
  </app-trip-sheet-title>
  <div class="row no-gutters division">
    <div class="col-4">
      <mat-card class="trip-sheet-detail">
        <mat-card-title>Details</mat-card-title>
        <mat-card-content class="trip-sheet-detail__content card-overflow">
          <div class="row">
            <div class="header col-6">ID</div>
            <div class="content col-6">
              {{ dailyTripSheet.id }}
            </div>
          </div>
          <div class="row">
            <div class="header col-6">Shift</div>
            <div class="content col-6">
              {{ dailyTripSheet.shift }}
            </div>
          </div>
          <div class="row">
            <div class="header col-6">Generated At</div>
            <div class="content col-6">
              {{ dailyTripSheet.updatedAt | date: 'MMM d, y, h:mm:ss a' }}
            </div>
          </div>
          <div class="row">
            <div class="header col-6">Driver</div>
            <div class="content col-6">
              <a class="content-tag" (click)="redirectToRiderCrm()">
                {{ dailyTripSheet.driverId }}</a
              >
            </div>
          </div>
          <div class="row">
            <div class="header col-6">Store Id</div>
            <div class="content col-6">
              {{ dailyTripSheet.storeId }}
            </div>
          </div>
          <div class="row">
            <div class="header col-6">Status</div>
            <div class="content col-6">
              {{ dailyTripSheet.tripSheetStatus }}
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-4">
      <mat-card class="earning-breakup">
        <mat-card-title>Earnings - Breakup</mat-card-title>
        <mat-card-content class="earning-breakup__content card-overflow">
          <div class="row">
            <div class="header col-8">Trip Count</div>
            <div id="trip-count" class="content col-4">
              {{ dailyTripSheet.tripEarnings?.length }}
            </div>
          </div>
          <div class="row">
            <div class="header col-8">Order Count</div>
            <div id="order-count" class="content col-4">
              {{ dailyTripSheet.totalOrderCount }}
            </div>
          </div>
          <div class="row">
            <div class="header col-8">km Earning</div>
            <div class="content col-4">
              {{ dailyTripSheet.kmEarning | currency: "INR" }}
            </div>
          </div>
          <div class="row">
            <div class="header col-8">Order Earn</div>
            <div class="content col-4">
              {{ dailyTripSheet.orderEarning | currency: "INR" }}
            </div>
          </div>
          <div class="row">
            <div class="header col-8">Incentive</div>
            <div class="content col-4">
              {{ dailyTripSheet.incentiveEarning | currency: "INR" }}
            </div>
          </div>
          <div class="row">
            <div class="header col-8">Manual Incentive</div>
            <div class="content col-4">
              {{ dailyTripSheet.manualIncentive | currency: "INR" }}
            </div>
          </div>
          <div *ngIf="dailyTripSheet.mbgEarning" class="row">
            <div class="header col-8">Mbg Earning</div>
            <div class="content col-4">
              {{ dailyTripSheet.mbgEarning | currency: "INR" }}
            </div>
          </div>
          <div *ngIf="dailyTripSheet.penaltyAmount" class="row">
            <div class="header col-8">Penalty Amount</div>
            <div class="content col-4">
              - {{ dailyTripSheet.penaltyAmount | currency: "INR" }}
            </div>
          </div>
          <div class="row">
            <div class="header col-8">Total Earnings</div>
            <div class="content col-4">
              {{ dailyTripSheet.totalEarning | currency: "INR" }}
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-4" *ngIf="dailyTripSheet.changes?.length">
      <mat-card class="change-logs">
        <mat-card-title> Change Logs </mat-card-title>
        <mat-card-content class="change-logs__content card-overflow">
          <app-change-logs
            [changeLogs]="dailyTripSheet.changes"
          ></app-change-logs>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="row no-gutters division">
    <div class="col-3">
      <mat-card class="no-gutters time">
        <mat-card-title class="time__title">Trip Times</mat-card-title>
        <mat-card-content class="time__content card-overflow">
          <div class="row" *ngIf="dailyTripSheet.checkInTime">
            <div class="header col-6">Login Time</div>
            <div class="content col-6">
              {{ dailyTripSheet.checkInTime }}
            </div>
          </div>
          <div class="row" *ngIf="dailyTripSheet.checkOutTime">
            <div class="header col-6">Logout Time</div>
            <div class="content col-6">
              {{ dailyTripSheet.checkOutTime }}
            </div>
          </div>
          <div class="row">
            <div class="header col-6">Ride Time</div>
            <div class="content col-6">
              {{ dailyTripSheet.ridingTime }}
            </div>
          </div>
          <div class="row">
            <div class="header col-6">In Store Time</div>
            <div class="content col-6">
              {{ dailyTripSheet.onStoreTime }}
            </div>
          </div>
          <div class="row">
            <div class="header col-6">Login Hours</div>
            <div class="content col-6">
              {{ dailyTripSheet.loginHours }}
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-3">
      <mat-card class="no-gutters incentives">
        <mat-card-title class="incentives__title"
          >Incentive Flags</mat-card-title
        >
        <mat-card-content class="incentives__content card-overflow">
          <mat-chip-list class="incentives__chips">
            <mat-chip
              ><i
                [ngClass]="{ green: dailyTripSheet.isOnTime }"
                class="material-icons"
                >{{ checkEligiblity(dailyTripSheet.isOnTime) }}</i
              >
              On Time</mat-chip
            >
            <mat-chip
              ><i
                [ngClass]="{ green: dailyTripSheet.isTripCountEligible }"
                class="material-icons"
                >{{ checkEligiblity(dailyTripSheet.isTripCountEligible) }}</i
              >Trip Count</mat-chip
            >
            <mat-chip
              ><i
                [ngClass]="{ green: dailyTripSheet.isOrderCountEligible }"
                class="material-icons"
                >{{ checkEligiblity(dailyTripSheet.isOrderCountEligible) }}</i
              >Order Count Eligible</mat-chip
            >
            <mat-chip
              ><i
                [ngClass]="{ green: dailyTripSheet.mbgApplied }"
                class="material-icons"
                >{{ checkEligiblity(dailyTripSheet.mbgApplied) }}</i
              >Mbg Eligible</mat-chip
            >
            <mat-chip
              class="incentives__kmViolation"
              *ngIf="dailyTripSheet.isKmViolation"
              ><i class="material-icons incentives__icon">warning</i>Km
              Violation</mat-chip
            >
          </mat-chip-list>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-3">
      <mat-card class="no-gutters incentives">
        <mat-card-title class="incentives__title"
          >Incentive Tags</mat-card-title
        >
        <mat-card-content class="incentives__content card-overflow">
          <mat-chip-list class="incentives__chips">
            <mat-chip
              ><i
                [ngClass]="{ green: dailyTripSheet.requestIncentive }"
                class="material-icons"
                >{{ checkEligiblity(dailyTripSheet.requestIncentive) }}</i
              >
              Request Incentive</mat-chip
            >
            <mat-chip
              ><i
                [ngClass]="{ green: dailyTripSheet.requestMbg }"
                class="material-icons"
                >{{ checkEligiblity(dailyTripSheet.requestMbg) }}</i
              >Request MBG</mat-chip
            >
          </mat-chip-list>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-3">
      <mat-card class="no-gutters checkIn-checkOut">
        <mat-card-title class="checkIn-checkOut__title">
          Trip Login Logout (km-travelled:
          <span
            [ngClass]="{
              'checkIn-checkOut__title-strike':
                dailyTripSheet.odoMeterKM != dailyTripSheet.correctedOdoMeterKm
            }"
            >{{ dailyTripSheet.odoMeterKM }}</span
          ><span
            *ngIf="
              dailyTripSheet.odoMeterKM != dailyTripSheet.correctedOdoMeterKm
            "
            >{{ dailyTripSheet.correctedOdoMeterKm }}</span
          >
          kms)
        </mat-card-title>
        <mat-card-content
          class="row no-gutters checkIn-checkOut__content card-overflow"
        >
          <div
            *ngIf="dailyTripSheet.checkInUrl"
            class="col-3 checkIn-checkOut__content__checkIn"
          >
            <img
              class="checkIn-checkOut__content__checkIn__img"
              (click)="showSpeedoMeterImage(dailyTripSheet.checkInUrl)"
              [src]="dailyTripSheet.checkInUrl"
              alt=""
            />
          </div>
          <div class="col-6 checkIn-checkOut__content__km">
            <span
              class="row no-gutters checkIn-checkOut__content__km__checkInTtile"
            >
              Check In Value</span
            >
            <span
              class="row no-gutters checkIn-checkOut__content__km__checkInValue"
            >
              <span
                [ngClass]="{
                  'checkIn-checkOut__content__km__checkInValue-strike':
                    dailyTripSheet.correctedCheckInValue !=
                    dailyTripSheet.checkInValue
                }"
                >{{ dailyTripSheet.checkInValue }}
              </span>
              <span
                class="row no-gutters checkIn-checkOut__content__km__checkInValue__correct"
                *ngIf="
                  dailyTripSheet.correctedCheckInValue !=
                  dailyTripSheet.checkInValue
                "
                >{{ dailyTripSheet.correctedCheckInValue }}</span
              >
            </span>
            <span
              class="row no-gutters checkIn-checkOut__content__km__checkOutTitle"
            >
              Check Out Value</span
            >
            <span
              class="row no-gutters checkIn-checkOut__content__km__checkOutValue"
            >
              <span
                [ngClass]="{
                  'checkIn-checkOut__content__km__checkOutValue-strike':
                    dailyTripSheet.correctedCheckOutValue !=
                    dailyTripSheet.checkOutValue
                }"
                >{{ dailyTripSheet.checkOutValue }}</span
              >
              <span
                class="row no-gutters checkIn-checkOut__content__km__checkOutValue__correct"
                *ngIf="
                  dailyTripSheet.correctedCheckOutValue !=
                  dailyTripSheet.checkOutValue
                "
                >{{ dailyTripSheet.correctedCheckOutValue }}</span
              >
            </span>
          </div>
          <div
            *ngIf="dailyTripSheet.checkOutUrl"
            class="col-3 checkIn-checkOut__content__checkOut"
          >
            <img
              class="checkIn-checkOut__content__checkOut__img"
              (click)="showSpeedoMeterImage(dailyTripSheet.checkOutUrl)"
              [src]="dailyTripSheet.checkOutUrl"
              alt=""
            />
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="row no-gutters">
    <div class="col-12">
      <mat-card class="trip-table">
        <mat-card-title
          >Trip Earnings -
          {{ dailyTripSheet.tripEarnings?.length }} trips</mat-card-title
        >
        <mat-card-content>
          <div class="row no-gutters driver card-overflow">
            <app-trip-sheet-grid
              [dataSource]="tripEarningDataSource"
              [columnsToDisplay]="columnsToDisplay"
              [titleToDisplay]="titleToDisplay"
              [actions]="buttonActions"
              [detailComponent]="componentToRender"
            >
            </app-trip-sheet-grid>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="footer row no-gutters" *ngIf="!dialogMode; else showDialogMode">
    <div class="total-earnings col-4">
      <b>Total Earnings:</b> {{ dailyTripSheet.totalEarning }}
    </div>
    <div class="action-buttons col-8">
      <app-trip-sheet-action
        [isDeliveryManagerMode]="checkDeliveryManagerMode"
        [tripSheet]="dailyTripSheet"
        (reloadParent)="closeDialog($event)"
        (updateParent)="updateParent($event)"
        *ngIf="!tripSheetDetailMode"
      ></app-trip-sheet-action>
    </div>
  </div>

  <ng-template #showDialogMode>
    <div class="dialog-footer row no-gutters">
      <div class="dialog-footer__total-earnings col-4">
        <b>Total Earnings:</b> {{ dailyTripSheet.totalEarning }}
      </div>
      <div class="dialog-footer__action-buttons col-8">
        <app-trip-sheet-action
          [isDeliveryManagerMode]="checkDeliveryManagerMode"
          [tripSheet]="dailyTripSheet"
          (reloadParent)="closeDialog($event)"
          (updateParent)="updateParent($event)"
          *ngIf="!tripSheetDetailMode"
        ></app-trip-sheet-action>
      </div>
    </div>
  </ng-template>
</ng-container>

<ng-container *ngIf="saleOrders?.length > 0; else noOrders">
  <div class="order" *ngFor="let order of saleOrders; let i = index">
    <h6 class="header" (click)="onOrderClicked.emit(order)">
      <app-order-id-display
        [order]="order"
        [showTime]="true"
        [showIndex]="!availableOrdersMode"
        [index]="i + 1"
      ></app-order-id-display>
      <span *ngIf="!order.shippingAddress?.canNavigate">
        <i class="fa fa-eye-slash"></i>
      </span>
    </h6>

    <p
      (click)="onOrderClicked.emit(order)"
      style="border-bottom: 1px solid black"
      class="address"
    >
      {{ order.shippingAddress?.fax }}, {{ order.shippingAddress?.apartment }},
      {{ order.shippingAddress?.landmark }}
    </p>
    <button
      mat-raised-button
      style="background: green"
      *ngIf="availableOrdersMode; else remove"
      (click)="addOrder(order)"
      id="add-{{ order.incrementId }}"
    >
      Add To Trip
    </button>
    <ng-template #remove>
      <button
        mat-raised-button
        color="warn"
        id="remove-{{ order.incrementId }}"
        (click)="removeOrder(order)"
      >
        Remove From Trip
      </button>
    </ng-template>
  </div>
</ng-container>
<ng-template #noOrders>
  <p>
    <span class="col-6">No Orders To Display</span>
  </p>
</ng-template>

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BasePutProvider } from "@tendercuts/http";
import { BookingRequest, GenericServerResponse } from "@tendercuts/models";

@Injectable({
  providedIn: "root",
})
export class BookingEditService extends BasePutProvider<GenericServerResponse> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
  }

  get key(): string {
    return "id";
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof GenericServerResponse {
    return GenericServerResponse;
  }

  get endpoint(): string {
    return this.endpointService.bookingEditRequest();
  }

  get isCachable(): boolean {
    return false;
  }
}

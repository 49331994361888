<div class="row">
  <div class="col-sm-6 col-md-4">
    <div class="card card-modals">
      <span class="card-modals-text">
        Register
      </span>
      <button class="btn card-modals-button" type="button" (click)="registerModal()">
        TRY ME
      </button>
    </div>
  </div>
  <div class="col-sm-6 col-md-4">
    <div class="card card-modals">
      <span class="card-modals-text">
        Login
      </span>
      <button class="btn card-modals-button" type="button" (click)="loginModal()">
        TRY ME
      </button>
    </div>
  </div>
</div>

import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { of, Observable } from "rxjs";
import { RewardPointsTransactionService } from "./reward-points-transaction";

import { HttpClient } from "@angular/common/http";
import { LoginServices } from "@login";
import { RewardPointsTransaction } from "@tendercuts/models";

@Injectable()
export class MockRewardPointsTransactionService extends RewardPointsTransactionService {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http, loginService, endpointService);
  }

  getData(): Observable<RewardPointsTransaction[]> {
    return of([
      new RewardPointsTransaction().deserialize({
        amount: "100",
        amount_used: "25",
        created_at: "Jun 13, 2017",
      }),
    ]);
  }
}

import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenavContainer } from "@angular/material/sidenav";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { StoreState } from "@store/store/state";
import { TripSheet } from "@tendercuts/models";
import { FetchTripSheetService } from "@tendercuts/providers";
import { Subscription } from "rxjs";
import { skipWhile, take } from "rxjs/operators";
import { TripSheetDataSource } from "src/app/components";
import { BasePage } from "src/app/utils";
import { Filter, FilterGroup, FilterMatch, FilterModel } from "src/models";
import { TableRowAction } from "../../../components";

/**
 * component to show list of non approved trip sheets
 */
@Component({
  selector: "app-trip-sheet-update",
  templateUrl: "./trip-sheet-update.component.html",
  styleUrls: ["./trip-sheet-update.component.scss"],
})
export class TripSheetUpdateComponent
  extends BasePage
  implements OnInit, OnDestroy {
  // today's trip sheets(not approved)
  tripSheets: TripSheet[] = [];
  // sheet to show the all details
  selectedTripSheet: TripSheet;
  @Input() columnsToDisplay: string[] = [
    "driverId",
    "id",
    "earningDate",
    "kmEarning",
    "orderEarning",
    "totalEarning",
    "tripSheetStatus",
  ];

  @Input() titleToDisplay: string[] = [
    "Driver-Id",
    "Trip Sheet Id",
    "Created Date",
    "Km Earning",
    "Order Earning",
    "Total Earning",
    "Status",
  ];

  /**
   * handle for next and prev buttons
   */
  tripSheetIndex: number = 0;

  // date for show the drivers. initially today's date
  selectedDate: Date = new Date();

  // date for max check in date picker
  todayDate: Date = new Date();

  tripSheetSearchFilterGroup: FilterGroup = new FilterGroup([
    new Filter("Search", null, ["driverId", "id"], FilterMatch.CONTAINS),
  ]);

  // To filter trip sheet status
  tripStatusFilterGroup: FilterGroup = new FilterGroup([
    new Filter("Pending", "Pending", "tripSheetStatus"),
    new Filter("To Approve", "To Approve", "tripSheetStatus"),
    new Filter("Confirmed", "Confirmed", "tripSheetStatus"),
  ]);

  @Input()
  rowClickCallback: TableRowAction = new TableRowAction(
    this.showDrawer.bind(this),
  );

  @ViewChild("drawer") drawer: MatSidenavContainer;

  modelFilter: FilterModel = new FilterModel([
    this.tripSheetSearchFilterGroup,
    this.tripStatusFilterGroup,
  ]);

  tripSheetDataSource: TripSheetDataSource = new TripSheetDataSource(
    this.modelFilter,
    [],
  );
  routerEventSubcriber: Subscription;

  constructor(
    public title: Title,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public fetchTripSheet: FetchTripSheetService,
    public router: Router,
    public route: ActivatedRoute,
  ) {
    super(dialog, snackBar, store);
  }

  /**
   * listen the route change if the there is any change in the route
   * it will fetch the data according to the activated route value
   */
  ngOnInit(): void {
    this.routerEventSubcriber = this.router.events.subscribe((data) => {
      if (data instanceof NavigationEnd) {
        this.fetchData();

        return;
      }
    });
    this.fetchData();
  }

  /*
   * Fetching data according to the current router value
   */
  fetchData(): void {
    const routeParam: string | number | Date = this.route.snapshot.params["selectedDate"];
    this.title.setTitle("Trip Sheets");
    if (routeParam) {
      this.selectedDate = new Date(routeParam);
    } else {
      this.selectedDate = new Date();
    }
    this.selectedDate.setTime(
      this.selectedDate.getTime() + 5.5 * 60 * 60 * 1000,
    );
    this.fetchTripSheets();
  }

  /**
   * send the selected date to the router
   * it will redirect the page to the current dashboard
   * and load the data according to the selected date
   * @param $event
   */
  onDateSelect($event: MatDatepickerInputEvent<Date> | any): void {
    const formatDate: string = $event.value
      ? this.dateFormat($event.value)
      : this.dateFormat($event);
    this.router.navigate(["/dashboard/trip-sheet-approve/" + formatDate]);
  }

  /**
   * To send the format date to the route
   * MM/DD/YYYY
   */
  dateFormat(date: Date): string {
    return (
      date.getMonth() + 1 + "-" + date.getDate() + "-" + date.getFullYear()
    );
  }

  showDrawer(event: any, tripSheet: TripSheet): void {
    this.tripSheetIndex = this.tripSheets.indexOf(tripSheet);
    this.selectedTripSheet = tripSheet;
    this.drawer.open();
  }

  /**
   * moved to the next trip sheet in the list of trip sheets
   */
  moveToNext(): void {
    this.tripSheetIndex++;
    this.selectedTripSheet = this.tripSheets[this.tripSheetIndex];
  }

  /**
   * moved to the prev trip sheet in the list of trip sheets
   */
  moveToPrev(): void {
    this.tripSheetIndex--;
    this.selectedTripSheet = this.tripSheets[this.tripSheetIndex];
  }

  /**
   * in first trip sheet hiding prev button
   */
  get canShowPrevButton(): boolean {
    return this.tripSheetIndex != 0;
  }

  /**
   * In last trip sheet hiding next button
   */
  get canShowNextButton(): boolean {
    return this.tripSheetIndex != this.tripSheets.length - 1;
  }

  /**
   * fetch the today's non approved trip sheets
   */
  async fetchTripSheets(): Promise<void> {
    this.presentLoader();

    const storeState$: StoreState = await this.store
      .select((state) => state.storeState)
      .pipe(
        skipWhile((state) => state.loading == true),
        take(1),
      )
      .toPromise();

    // Empty store check
    if (storeState$.store == undefined) {
      this.textAlert("Please Select Store", "To Approve Driver's Trip Sheet");
      this.dismissLoader();
    }

    const params: {
      earning_date: string;
      store_id: number;
  } = await this.fetchTripSheet.getParams(
      this.selectedDate,
      storeState$.store,
    );

    this.tripSheets = await this.fetchTripSheet
      .getData(params)
      .pipe(take(1))
      .toPromise();
    await this.dismissLoader();
    this.tripSheetDataSource.selection.clear();
    this.tripSheetDataSource.data = this.tripSheets;
  }

  /**
   * search filter based on the search value
   * @param searchString
   */
  applyDriverSearchFilter(searchString: string): void {
    if (searchString === "") {
      this.tripSheetSearchFilterGroup.filters[0].selected = false;
      this.tripSheetSearchFilterGroup.filters[0].value = null;
    } else {
      this.tripSheetSearchFilterGroup.filters[0].selected = true;
      this.tripSheetSearchFilterGroup.filters[0].value = searchString;
    }
    this.tripSheetDataSource.filter = "true";
  }

  /**
   * if status flag is true reload data once.
   * @param $event
   */
  closeDrawer($event: any): void {
    this.drawer.close();
    if ($event) {
      this.fetchTripSheets();
    }
  }

  // Onclicking over matchip data is filtered
  getFilteredData(filter: Filter): void {
    filter.selected = !filter.selected;
    this.tripSheetDataSource.filter = "true";
  }

  ngOnDestroy(): void {
    if (this.routerEventSubcriber) {
      this.routerEventSubcriber.unsubscribe();
    }
  }
}

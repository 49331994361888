<ng-container>
  <p>Inventory released successfully with the following products :</p>

  <mat-card>
    <mat-card-title>Products list</mat-card-title>
    <mat-table #matTable [dataSource]="productData" matSort>
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
        <mat-cell *matCellDef="let productData">
          <span class="label">{{ productData.id }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="productName">
        <mat-header-cell *matHeaderCellDef> Product-Name </mat-header-cell>
        <mat-cell *matCellDef="let productData">
          <span class="label">{{ productData.productName }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="productQuantity">
        <mat-header-cell *matHeaderCellDef> Quantity </mat-header-cell>
        <mat-cell *matCellDef="let productData">
          <span class="label">{{ productData.qty }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="storeName">
        <mat-header-cell *matHeaderCellDef> Store-Name </mat-header-cell>
        <mat-cell *matCellDef="let productData">
          <span class="label">{{ productData.storeName | uppercase }}</span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row *matRowDef="let row; columns: columnsToDisplay"></mat-row>
    </mat-table>
  </mat-card>
  <div class="dismiss-button">
    <button mat-raised-button color="primary" (click)="dialogClose()">
      Ok
    </button>
  </div>
</ng-container>

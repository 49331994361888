import { Serializable } from "../base";

/**
 * Trip Configuration
 */
export class TripConfig implements Serializable<TripConfig> {
  private order_per_trip: number;
  private day_of_week: string;
  private store_id: number;
  public id: number;
  public city: string;
  public type: string;

  constructor() {}

  get ordersPerTrip() {
    return this.order_per_trip;
  }

  deserialize(input) {
    this.order_per_trip = input.order_per_trip;
    this.day_of_week = input.day_of_week;
    this.store_id = input.store_id;
    this.id = input.id;
    this.city = input.city;
    this.type = input.type;

    return this;
  }
}

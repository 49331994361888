import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { map, skipWhile, take } from "rxjs/operators";

import * as selectors from "@store/state";
import { AppState } from "@store/state";
import {
  GenericServerResponse,
  OptimalTrip,
  SaleOrder,
  StoreSegment,
  Trips,
} from "@tendercuts/models";

import { Title } from "@angular/platform-browser";
import {
  ActionDisableTripEditMode,
  ActionFetchTripConfig,
  ActionGetDrivers,
} from "@store/driver";
import { ActionRefreshOrders } from "@store/orders";
import { ActionLoadStoreSegments, StoreState } from "@store/store";
import { AutoRoutesService, PubSubService } from "@tendercuts/providers";
import { Subscription } from "rxjs";
import {
  Filter,
  FilterGroup,
  FilterMatch,
  FilterModel,
  StoreAreaFilter,
} from "src/models";
import { FilterModelDataSource } from "../../components";
import { PlanTripWizardComponent } from "../../components/plan-trip-wizard/plan-trip-wizard.component";
import { BasePage } from "../../utils/pages/base/base.component";
import { FirebaseAnalyticsService } from "src/providers/firebase-analytics/firebase-analytics";

class SaleOrderDataSource extends FilterModelDataSource<SaleOrder> {
  constructor(filterModel: FilterModel, initialData?: SaleOrder[]) {
    super(filterModel, initialData);
  }
}

@Component({
  selector: "app-routing-dashboard",
  templateUrl: "./routing-dashboard.component.html",
  styleUrls: ["./routing-dashboard.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RoutingDashboardComponent
  extends BasePage
  implements AfterViewInit, OnInit, OnDestroy {
  // zoom level for map
  zoom: number = 13;
  showOptimalTrip: boolean = true;
  mapRawRef: any;

  // filters array
  areaFilterGroup: FilterGroup = new FilterGroup([]);
  // filters empty arry for delivery slots
  slotFilterGroup: FilterGroup = new FilterGroup([]);
  statusFilterGroup: FilterGroup = new FilterGroup([
    new Filter(
      "Out For Delivery",
      true,
      "isUnAssignedOrder",
      FilterMatch.EXACT,
      true
    ),
  ]);

  modelFilter: FilterModel = new FilterModel([
    this.statusFilterGroup,
    this.areaFilterGroup,
    // Delivery slots filter group
    this.slotFilterGroup,
  ]);
  orders: SaleOrderDataSource = new SaleOrderDataSource(this.modelFilter, []);
  segments: StoreSegment[] = [];

  optimalTrips: OptimalTrip[] = [];
  selectedTrip: OptimalTrip;
  plannedOrders: string[] = [];

  orderSubscriber: Subscription;
  dummyTripSubscriber: Subscription;
  storeId: string;

  ordersPerTrip: number;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    private title: Title,
    private autoRoutesService: AutoRoutesService,
    public firebaseAnalyticsService: FirebaseAnalyticsService,
    public events: PubSubService
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    this.fetchOrdersPerTrip();
    this.fetchData();
    this.title.setTitle("Plan Trip");
    this.checkTripState();
    this.dummyTripSubscriber = this.events.$sub(
      "dummy-trip:created",
      (dummyTrip: Trips) => this.removeDummyTripOrders(dummyTrip)
    );
  }

  /**
   * fetch selected slot orders per trip
   */
  async fetchOrdersPerTrip(): Promise<void> {
    const state: AppState = await this.store
      .select((appState) => appState)
      .pipe(
        skipWhile(
          (appState) =>
            appState.storeState.loading || appState.orderState.loading
        ),
        take(1)
      )
      .toPromise();
    if (state && state.driverState.tripConfig) {
      this.ordersPerTrip = state.driverState.tripConfig.ordersPerTrip;
    }
  }

  /**
   * Whenever a dummy trip is created, it removes all those orders of that
   * trip from existing optimal trips. And then filters out any optimal trips
   * which are empty.
   */
  removeDummyTripOrders(dummyTrip: Trips): void {
    if (!this.optimalTrips.length) {
      return;
    }

    this.optimalTrips.forEach((eachTrip: OptimalTrip) => {
      dummyTrip.orders.forEach((order) => {
        eachTrip.orders.delete(order);
        this.plannedOrders.push(order);
      });
    });

    // refresh data source
    this.orders.data = this.orders.data.filter(
      (order) => !this.plannedOrders.includes(order.incrementId)
    );

    this.optimalTrips = this.optimalTrips.filter((trip) => trip.orders.size);
  }

  /**
   * Fetches the trip obj to be edited. And creates an optimal trip for it.
   * And then opens the plan trip dialog with that optimal trip.
   */
  async checkTripState(): Promise<void> {
    const editableTrip: Trips = await this.driverState
      .pipe(
        take(1),
        map((driveState) => {
          const editTripObj: Trips = driveState.trips.find(
            (trip) => trip.id == driveState.editTripId
          );

          return editTripObj;
        })
      )
      .toPromise();
    if (editableTrip) {
      const tripOrders: string[] = editableTrip.driverOrder.map((order) => {
        if (order.isActiveOrder) {
          return order.incrementId;
        }
      });
      const optimalTrip: OptimalTrip = new OptimalTrip().deserialize({
        orders: Array.from(tripOrders),
        trip_id: editableTrip.tripId,
      });
      this.openTripDrawer(optimalTrip, true);
      this.store.dispatch(new ActionDisableTripEditMode());
    }
  }

  /**
   * Gets segments and generates area filters for customer orders
   * with available segments and then dismisses the loader
   */
  async getSegments(): Promise<void> {
    const segments: StoreSegment[] = await this.store
      .select(selectors.getStoreState)
      .pipe(
        skipWhile((state) => state.segmentLoaded == false),
        take(1),
        map((storeState) => storeState.segments)
      )
      .toPromise();
    this.buildFilter(segments);
    this.dismissLoader();
  }

  /**
   * Fetches available drivers and segments from server
   */
  async fetchDriversAndSegments(): Promise<void> {
    this.presentLoader();
    const storeState: StoreState = await this.store
      .select(selectors.getStoreState)
      .pipe(
        skipWhile(
          (state) =>
            state.availableStores.length == 0 ||
            typeof state.store == "undefined"
        ),
        take(1)
      )
      .toPromise();
    const storeId: string = storeState.store.toString();
    this.store.dispatch(new ActionGetDrivers(storeId));
    this.store.dispatch(new ActionLoadStoreSegments(parseInt(storeId)));
    // this.store.dispatch(new ActionFetchSavedTrips(parseInt(storeId)));
    this.getSegments();
  }

  /* reloads orders,drivers and recalculates segments
   */
  async fetchData(): Promise<void> {
    const storeState: StoreState = await this.store
      .select(selectors.getStoreState)
      .pipe(take(1))
      .toPromise();
    this.storeId = storeState.store.toString();
    this.store.dispatch(new ActionRefreshOrders(storeState.store.toString()));
    this.store.dispatch(new ActionFetchTripConfig(storeState.store.toString()));
    const isOrdersLoaded: boolean = await this.store
      .select((state) => state.orderState.loading)
      .pipe(
        skipWhile((isLoading) => isLoading),
        take(1)
      )
      .toPromise();
    this.fetchDriversAndSegments();
  }

  ngAfterViewInit(): void {
    this.orderSubscriber = this.store
      .select((state) => state)
      .subscribe((state: AppState) => {
        this.slotFilterGroup.filters = state.storeState.deliverySlotFilters;

        this.orders.data = state.orderState.orders.filter(
          (order) => order.isElgibleForTrip
        );
        this.orders.filter = "true";
      });
  }

  ngOnDestroy(): void {
    if (this.orderSubscriber) {
      this.orderSubscriber.unsubscribe();
    }
    if (this.dummyTripSubscriber) {
      this.dummyTripSubscriber.unsubscribe();
    }
  }

  buildFilter(segments: StoreSegment[]): void {
    // flush old filters
    this.orders.filter = "true";
    this.segments = segments;
    this.areaFilterGroup.filters = [];
    segments.forEach((segment) => {
      const regionFilter: StoreAreaFilter = new StoreAreaFilter(
        segment,
        segment.name,
        segment.segmentId, // segid
        "segmentId",
        FilterMatch.EXACT,
        false
      );
      this.areaFilterGroup.filters.push(regionFilter);
    });
  }

  showTripWizard(): void {
    const dialogRef: MatDialogRef<PlanTripWizardComponent, any> =
      this.dialog.open(PlanTripWizardComponent, {
        data: {
          areaFilterGroup: this.areaFilterGroup,
          slotFilterGroup: this.slotFilterGroup,
          orders: this.orders,
        },
      });
    dialogRef.afterClosed().subscribe((trips: OptimalTrip[]) => {
      if (trips == undefined || trips.length == 0) {
        this.buildFilter(this.segments);
        this.optimalTrips = [];

        return;
      }
      this.optimalTrips = trips;
    });
  }

  openTripDrawer(trip: OptimalTrip, editMode: boolean = false): void {
    this.events.$pub("plan-trip:open", {
      selectedTrip: trip,
      dataSource: this.orders,
      segments: this.segments,
      editMode,
    });
  }

  addTrip(): void {
    if (this.selectedTrip == undefined) {
      const trip: OptimalTrip = new OptimalTrip();
      this.optimalTrips.push(trip);
      this.selectedTrip = trip;
    } else {
      this.textAlert(
        "Multiple Trips not allowed",
        "Cant create multiple trips at a time"
      );
    }
  }

  /**
   * fetch the api response with storeId and slotId,
   * @param storeId,
   * @param ordersPerTrip
   */
  loadAutoRoutes(): void {
    this.firebaseAnalyticsService.autoRouteButtonClicked();
    this.presentLoader();
    const params: {
      store_id: string;
      order_per_trip: number;
    } = this.autoRoutesService.getParams(this.storeId, this.ordersPerTrip);

    this.autoRoutesService.getData(params).subscribe(
      (data: GenericServerResponse[]) => {
        if (data[0].status) {
          this.dismissLoader();
          this.refreshOrdersAndTrips();
          this.firebaseAnalyticsService.autoRouteSuccess(data[0].message);
        } else  {
          this.firebaseAnalyticsService.autoRouteFailure(data[0].message);
        }

        this.showNotification(
          "top",
          "center",
          "success",
          "info-circle",
          data[0].message
        );
        this.dismissLoader();
      },

      (err) => this.somethingWentWrong()
    );
  }
}

<div class="before-release">
  <div class="row d-flex justify-content-center align-items-center">
    <h5>SELECT ITEMS TO RELEASE</h5>
  </div>

  <ng-container>
    <div class="row">
      <table class="table">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Name</th>
            <th scope="col">Quantity</th>
            <th scope="col">*Acceptable Weight</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let product of productData; let ind = index">
            <td>
              <mat-checkbox
                id="check-{{ ind }}"
                (change)="onSelectingSku($event, product)"
              >
              </mat-checkbox>
            </td>
            <td>{{ product.productName }}</td>
            <td>{{ product.qty }} kg</td>
            <td>( {{ product.toleranceRange }} ) kg</td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <div
    class="bottom-component row d-flex justify-content-center align-items-center"
  >
    <button
      mat-raised-button
      color="primary"
      (click)="release.emit(false)"
      class="m-2"
    >
      Back
    </button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="selectedProducts.length != productData.length"
      (click)="releaseProducts()"
      class="m-2"
    >
      Release
    </button>
  </div>
</div>

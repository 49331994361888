import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { DriverLoginLogout, TripEarning, TripSheet } from "@tendercuts/models";
import { TripEarningUpdateService } from "@tendercuts/providers";
import {
  ButtonAction,
  ColumnAction,
  DateFormatAction,
  HyperLinkAction,
  TripEarningDataSource,
} from "src/app/components";
import { BasePage } from "src/app/utils";
import { FilterGroup, FilterModel } from "src/models/filters";
import { SpeedoMeterImageComponent } from "../speedo-meter-image";
import { TripEarningConfirmComponent } from "../trip-earning-confirm";
import { TripEarningSegmentDisplay } from "../trip-segments";

/**
 * component to validate the generated trip sheet
 */
@Component({
  selector: "app-verify-trip-sheet",
  templateUrl: "./verify-trip-sheet.component.html",
  styleUrls: ["./verify-trip-sheet.component.scss"],
})
export class VerifyTripSheetComponent extends BasePage {

  /**
   * setter to set the trip sheet object
   */
  @Input() set tripSheet(tripSheet: TripSheet) {
    this.dailyTripSheet = tripSheet;
    this.tripEarningDataSource.data = tripSheet.tripEarnings;
  }

  // setter to set delivery manager flag
  @Input() set isDeliveryManagerMode(status: boolean) {
    this.checkDeliveryManagerMode = status;
  }

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private tripEarningUpdateService: TripEarningUpdateService,
    public store: Store<AppState>,
    public router: Router,
  ) {
    super(dialog, snackBar, store);
  }

  /**
   * fetch button actions based on the condition
   */
  get buttonActions(): ColumnAction[] {
    return this.checkDeliveryManagerMode && this.dailyTripSheet.status != 2
      ? [
          new HyperLinkAction(
            "Trip Id",
            "tripId",
            this.redirectToTripDetail.bind(this),
          ),
          new ButtonAction(
            "Change Km",
            "raiseIssue",
            this.approveRequest.bind(this),
          ),
        ]
      : [
          new HyperLinkAction(
            "Trip Id",
            "tripId",
            this.redirectToTripDetail.bind(this),
          ),
          new DateFormatAction(
            "Created Date",
            "created_date",
            this.getDateFormat.bind(this),
          ),
        ];
  }
  dailyTripSheet: TripSheet;

  // selected store rider
  @Input()
  driverProfile: DriverLoginLogout;

  // to show only trip detail no action perform
  @Input() tripSheetDetailMode: boolean = false;

  // for show the delivery manager options
  checkDeliveryManagerMode: boolean = false;

  // selector input to show total earnings inside dialog
  @Input() dialogMode: boolean;

  @Input() columnsToDisplay: string[] = [
    "kmEarning",
    "finalKms",
    "originalKm",
    "orderCount",
    "orderEarning",
    "totalEarning",
    "tripEarningStatus",
  ];

  @Input() titleToDisplay: string[] = [
    "km Earning",
    "Final-km",
    "Original-km",
    "#Orders",
    "Order Earning",
    "Total Earning",
    "Status",
  ];

  // close the dialog and reload the parent
  @Output() public reloadParent: EventEmitter<any> = new EventEmitter();

  // reload the trip sheets in trip sheet update component
  @Output() public reloadTripSheets: EventEmitter<boolean> = new EventEmitter();

  tripEarningFilterGroup: FilterGroup = new FilterGroup([]);

  modelFilter: FilterModel = new FilterModel([this.tripEarningFilterGroup]);

  tripEarningDataSource: TripEarningDataSource = new TripEarningDataSource(
    this.modelFilter,
    [],
  );

  // update the trip sheet
  @Output()
  public updateTripSheet: EventEmitter<TripSheet> = new EventEmitter();

  componentToRender: typeof TripEarningSegmentDisplay = TripEarningSegmentDisplay;

  getDateFormat(event: any, tripEarning: TripEarning): string {
    return "mediumDate";
  }

  /**
   * incentive flags based on the status.
   * @param status
   */
  checkEligiblity(status: boolean): string {
    return status ? "done" : "clear";
  }

  /**
   * redirect to trip-detail with params
   * @param event
   * @param trip
   */
  redirectToTripDetail(event: any, trip: TripEarning): void {
    window.open(
      window.location.origin + "/dashboard/trip-detail/" + trip.tripId,
      "_blank",
    );
  }

  /**
   * show the approval popup and get the kms to change
   * pass it to trip earning update service
   * @param event
   * @param trip
   */
  approveRequest(event: any, trip: TripEarning): void {
    const data: {
      trip: TripEarning;
      tripSheet: TripSheet;
      isDeliveryManagerMode: boolean;
  } = {
      trip,
      tripSheet: this.tripSheet,
      isDeliveryManagerMode: true,
    };
    const dialogRef: MatDialogRef<
    TripEarningConfirmComponent, any
    > = this.dialog.open(TripEarningConfirmComponent, {
      width: "380px",
      panelClass: "remove-padding",
      data,
    });

    dialogRef.afterClosed().subscribe((tripData) => {
      if (tripData && tripData.status) {
        const status: number = trip.status;
        const params: {
          id: number;
          status: number;
          final_km: number;
          comment: string;
      } = this.tripEarningUpdateService.getApproveRequest(
          status,
          trip.id,
          tripData.km,
          tripData.comment,
        );
        this.finakKmUpdate(params, trip);
      }
    });
  }

  showSpeedoMeterImage(source: string): void {
    const dialogRef: MatDialogRef<
    SpeedoMeterImageComponent, any
    > = this.dialog.open(SpeedoMeterImageComponent, {
      panelClass: "remove-padding",
      data: source,
    });
  }

  /**
   * updating trip earning km field
   * @param trip
   * @param status
   */
  finakKmUpdate(params: {
    id: number;
    status: number;
    final_km: number;
    comment: string;
},              trip: TripEarning): void {
    this.tripEarningUpdateService.getData(params).subscribe(
      (response: TripEarning) => {
        if (response) {
          this.presentLoader();
          this.dailyTripSheet.tripEarnings.splice(
            this.dailyTripSheet.tripEarnings.indexOf(trip),
            1,
            response[0],
          );
          this.tripEarningDataSource.data = this.dailyTripSheet.tripEarnings;
          this.showNotification(
            "top",
            "center",
            "success",
            "info-circle",
            "Trip Earning Final Km Updated",
          );
          this.dismissLoader();
        }
      },
      (error) => this.somethingWentWrong(),
    );
  }

  closeDialog(status: boolean): void  {
    this.reloadParent.emit(status);
  }

  updateParent(tripSheet: TripSheet): void {
    this.reloadTripSheets.emit(true);
    if (this.checkDeliveryManagerMode) {
      this.dailyTripSheet = tripSheet;
    } else {
      this.updateTripSheet.emit(tripSheet);
    }
  }

  /**
   * Fn to redirect to driver crm
   * @param driverId ie rider number is passed
   */

  redirectToRiderCrm(): void {
    this.dialog.closeAll();
    this.router.navigate([
      "dashboard/driver-crm/" + this.dailyTripSheet.driverId,
    ]);
  }
}

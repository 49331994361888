/**
 * Any filter component should implement this.
 */
interface IFilter {
  filter(input: any[]): any[];
}

enum FilterOperator {
  AND,
  OR,
}

export enum FilterMatch {
  EXACT,
  CONTAINS,
  LESS_THAN,
  GREATER_THAN,
}

export class Filter implements IFilter {
  /**
   * Filter
   * @param string _displayName: string to display
   * @param string| string[]} value value to compare with
   * @param string searchField model attribute to search in
   * @param matchType
   * @param selected
   */
  constructor(
    public nameDisplayed: string,
    public value: any,
    public searchField: string | string[],
    public matchType: FilterMatch = FilterMatch.EXACT,
    public selected: boolean = false,
  ) {}

  /**
   * Getter to check is the slot is store pickup
   */
  get isPickup(): boolean {
    return this.value === 998;
  }

  /**
   * Using getter for display name, this allows the filter to provide
   * a dynamic names
   */
  get displayName(): string {
    return this.nameDisplayed;
  }

  /*
   * Function to extract the value(primitives only) from the entity based on the search field.
   */
  matchEntityField(searchField: string | string[], entity: any): boolean {
    const entityFields: string[] = this.extractEntityFields(entity, searchField);

    const isObj: boolean = entityFields.some(
      (entityField) => entityField !== null && typeof entityField === "object",
    );

    if (isObj) {
      throw new Error("Filter framework supports only primitive data type. \
       Use Nested / NestedCounter Filter for object types");
    }

    const results: boolean[] = entityFields.map((entityField) =>
      this.applyRules(entityField),
    );

    return results.some((status) => status === true);
  }

  /**
   * Extracts the searchField value. Supports 2 levels of object nesting.
   */
  extractEntityFields(entity: any, searchField: any): any[] {
    const comps: any = searchField.split(".");

    if (comps.length > 2) {
      throw new Error("Only 2 levels of look-up is allowed");
    }

    let values: any[] = [entity];

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let index: number = 0; index < comps.length; index++) {
      const key: any = comps[index];
      values = values.map((val) => val[key]);
      values = [].concat.apply([], values);
    }

    return values;
  }

  applyRules(valueToCheck: string): boolean {
    // if the type is exact we do a index query
    if (this.matchType == FilterMatch.EXACT) {
      return this.value.indexOf(valueToCheck) >= 0;
    }

    if (this.matchType == FilterMatch.LESS_THAN) {
      return this.value
        .map((val) => valueToCheck < val)
        .some((status) => status == true);
    }

    if (this.matchType == FilterMatch.GREATER_THAN) {
      return this.value
        .map((val) => valueToCheck > val)
        .some((status) => status == true);
    }

    // if the type is contains
    if (this.matchType == FilterMatch.CONTAINS) {
      let fieldValue: any = valueToCheck;
      // counter null values
      if (!fieldValue) {
        fieldValue = "";
      }
      if (typeof fieldValue === "number") {
        fieldValue = fieldValue.toString();
      }

      // run thro' array and check with includes
      return (
        // for every value, check if the search matches (case insensitive)
        this.value
          .map((val) => fieldValue.toLowerCase().includes(val.toLowerCase()))
          .some((status) => status == true)
      );
    }
  }

  /**
   * Searches the model's search field with the value.
   * @param any[] input
   * @returns any[]
   */
  filter(input: any[]): any[] {
    return input.filter((entity) => {
      // Null value indicates remove filter
      if (!this.value) {
        return true;
      }

      if (typeof this.searchField === "string") {
        this.searchField = [this.searchField];
      }

      if (
        typeof this.value === "string" ||
        typeof this.value === "number" ||
        typeof this.value == "boolean"
      ) {
        this.value = [this.value];
      }

      // for every searchField check if the value is in one of the mentioned array
      const searchFieldResults: boolean[] = this.searchField.map((searchField) =>
        this.matchEntityField(searchField, entity),
      );

      return searchFieldResults.some((status) => status === true);
    });
  }
}

export class NestedFilter extends Filter {
  constructor(
    public nameDisplayed: string,
    public value: any,
    public searchField: string | string[],
    public matchType: FilterMatch = FilterMatch.EXACT,
    public selected: boolean = false,
    public nestedMatchField: string,
  ) {
    super(nameDisplayed, value, searchField, matchType, selected);
  }

  /*
   * Function to extract the value(objects only) from the entity based on the search field.
   */
  matchEntityField(searchField: string | string[], entity: any): boolean {
    // e.g entity will be a list of sale items.
    const entities: any[] = this.extractEntityFields(entity, searchField);

    const isPrimitive: boolean = entities.some(
      (entityField) => typeof entityField !== "object",
    );

    if (isPrimitive) {
      throw new Error("Nested Filter framework  supports only object data type. \
       Use Filter Framework for primitive types");
    }

    const results: boolean[] = entities.map((entityField) =>
      this.applyRules(entityField[this.nestedMatchField]),
    );

    return results.some((status) => status === true);
  }
}

/**
 * Nested Counter Filter is an extension of nested filter.
 * P.S: Specifically designed for product filtering by their quantity.
 */
export class NestedCounterFilter extends NestedFilter {
  counterAvailable: number; // copy of counter to preserve the user given counter value
  constructor(
    public nameDisplayed: string,
    public value: any,
    public searchField: string | string[],
    public matchType: FilterMatch = FilterMatch.EXACT,
    public selected: boolean = false,
    public nestedMatchField: string,
    public counterField: string,
    public counter: number,
  ) {
    super(
      nameDisplayed,
      value,
      searchField,
      matchType,
      selected,
      nestedMatchField,
    );
  }

  /**
   * Plucks out nested obj of each entity and applies counter against it.
   * When counter value becomes negative entities will not be filtered.
   * @param searchField
   * @param entity
   */
  matchEntityField(searchField: string | string[], entity: any): boolean {
    // e.g entity will be a list of sale items.
    const entities: any[] = this.extractEntityFields(entity, searchField);

    const isPrimitive: boolean = entities.some(
      (entityField) => typeof entityField !== "object",
    );

    if (isPrimitive) {
      throw new Error("Nested Counter Filter framework supports only object data type. \
       Use Filter Framework for primitive types");
    }

    const results: boolean[] = entities.map((entityMatchField) => {
      const entityMatched: boolean = this.applyRules(entityMatchField[this.nestedMatchField]);
      if (!entityMatched) { return entityMatched; }

      this.counterAvailable =
        this.counterAvailable - +entityMatchField[this.counterField];

      return this.counterAvailable >= 0;
    });

    return results.some((status) => status === true);
  }

  /**
   * Build the counterAvailable everytime before filtering to
   * ensure we don`t use the already computed counterAvailable value
   * @param input
   */
  filter(input: any): any[] {
    this.counterAvailable = Number(this.counter);

    return super.filter(input);
  }
}

/**
 * A filter group consist of a list of filters which will be
 * merged based on OR filters.
 */
export class FilterGroup implements IFilter {
  constructor(public filters: (Filter | NestedCounterFilter)[]) {}

  filter(input: any[]): any[] {
    const filters: (NestedCounterFilter | Filter)[] = this.filters.filter(
      (f: Filter | NestedCounterFilter) => f.selected,
    );

    // to handle cases where no filters are selected
    if (filters.length == 0) {
      return input;
    }

    const output: any[] = [];
    filters.forEach((f: Filter | NestedCounterFilter) =>
      output.push(f.filter(input)),
    );

    const flattenedArray: any = [].concat.apply([], output);

    return Array.from(new Set(flattenedArray));
  }
}

/**
 * A filter model will consistof a list of filtergrup which will be
 * applied on a model
 */
export class FilterModel implements IFilter {
  constructor(public filterGroups: FilterGroup[]) {}

  filter(input: any[]): any[] {
    this.filterGroups.forEach((f: FilterGroup) => (input = f.filter(input)));

    return input;
  }

  clearAll(): void {
    this.filterGroups.forEach((f: FilterGroup) => {
      f.filters.forEach(
        (filter: Filter | NestedCounterFilter) => (filter.selected = false),
      );
    });
  }
}

<mat-card *ngIf="isRejectMode; else approve">
  <mat-card-title>
    {{ header }} - Trip Sheet({{ tripSheet.driverId }})
  </mat-card-title>

  <mat-card-content>
    <mat-form-field>
      <textarea
        matInput
        type="text"
        id="reject-comment"
        class="row"
        placeholder="Enter reason for rejection"
        [formControl]="commentControl"
      ></textarea>
    </mat-form-field>

    <div class="action-btns no-gutters row">
      <button
        (click)="closeDialog()"
        class="close-btn"
        color="primary"
        mat-raised-button
      >
        close
      </button>
      <button
        [disabled]="!commentControl.value"
        (click)="tripSheetReject()"
        color="primary"
        id="submit-reject"
        mat-raised-button
      >
        Submit
      </button>
    </div>
  </mat-card-content>
</mat-card>
<ng-template #approve>
  <mat-card>
    <mat-card-title>
      Approve - Trip Sheet({{ tripSheet.driverId }})
    </mat-card-title>

    <mat-card-content>
      <mat-form-field>
        <mat-label>Remarks</mat-label>
        <textarea
          matInput
          type="text"
          id="approve-remarks"
          class="row"
          placeholder="Enter remarks"
          [formControl]="commentControl"
        ></textarea>
      </mat-form-field>

      <mat-form-field 
        *ngIf="tripSheet.requestIncentive && tripSheet.isSchemeDaily"
      >
        <mat-label>Enter Incentive amount</mat-label>
        <input
          matInput
          id="approve-incentive"
          type="number"
          [formControl]="inputController"
          placeholder="Enter incentive"
        />
      </mat-form-field>

      <div class="action-btns no-gutters row">
        <button
          (click)="closeDialog()"
          class="close-btn"
          color="primary"
          mat-raised-button
        >
          close
        </button>
        <button
          [disabled]="!canActiveSubmit"
          (click)="tripSheetApprove()"
          color="primary"
          id="submit-approve"
          mat-raised-button
        >
          Submit
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>

<mat-card class="payment_otp">
  <mat-card-title class="payment_otp__title">
    <div class="row">
      <div class="col-10">
        <strong> OTP send to - {{ tripSheet.driverId }} </strong>
      </div>
      <div class="col-2">
        <i
          class="fa fa-window-close-o"
          (click)="closeDialog()"
          aria-hidden="true"
        ></i>
      </div>
    </div>
  </mat-card-title>

  <mat-card-content>
    <div class="container">
      <div class="row d-flex justify-content-center m-2">
        <p class="text-center">
          Enter the otp send to the phone
          <strong>{{ tripSheet.driverId }} </strong> for the payment amount
          <strong>{{ amount }} Rs </strong>
        </p>
      </div>
      <mat-form-field class="row d-flex justify-content-center">
        <mat-label>Enter Payment OTP</mat-label>
        <input
          matInput
          class="payment_otp__input"
          id="otp"
          [formControl]="inputController"
          placeholder="----"
          maxlength="4"
          autofocus
        />
      </mat-form-field>
    </div>
    <div class="row d-flex justify-content-center payment_otp__button">
      <button
        [disabled]="showResend"
        (click)="resendOtp()"
        class="close-btn"
        color="primary"
        mat-raised-button
      >
        <span *ngIf="showResend; else resend">
          Re-Send OTP ({{ timer }} sec)
        </span>
        <ng-template #resend> Resend OTP </ng-template>
      </button>
      <button
        [disabled]="inputController.value?.length < 4"
        id="submit-otp"
        (click)="submitOtp()"
        class="close-btn"
        color="primary"
        mat-raised-button
      >
        Submit
      </button>
    </div>
  </mat-card-content>
</mat-card>

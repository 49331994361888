import { createReducer, Action, Store } from "ngrx-actions";
import { Observable } from "rxjs";
import {
  ActionAllStoreLoaded,
  ActionDeliverySlotsLoaded,
  ActionDeliverySlotsLoadFailed,
  ActionErrorLoadCities,
  ActionGetStores,
  ActionLoadAllStores,
  ActionLoadCities,
  ActionLoadDeliverySlots,
  ActionLoadStore,
  ActionLoadStoreSegments,
  ActionRestrictStores,
  ActionSaveCities,
  ActionSetStore,
  ActionSetStoreSegments,
  ActionStoresLoadSuccess,
  ActionStoreError,
} from "../store/actions";
import { storeInitialState, StoreState } from "./state";

@Store({ ...storeInitialState })
export class StoreReducers {
  /**
   * Set store to redux state
   * @param StoreState state
   * @param ActionSetStore action
   * @returns store: Store; loading: boolean; error: boolean; errorMessage: string
   */
  @Action(ActionSetStore)
  actionSetStore(state: StoreState, action: ActionSetStore): any {
    return {
      ...state,
      storageLoading: false,
      store: action.payload,
    };
  }

  /**
   * Set store segments to loading state, and set loading state
   * @param StoreState state
   * @param ActionLoadStoreSegments action
   * @returns store: Store; loading: boolean; error: boolean; errorMessage: string
   */
  @Action(ActionLoadStoreSegments)
  actionLoadStoreSegments(
    state: StoreState,
    action: ActionLoadStoreSegments
  ): any {
    return {
      ...state,
      segmentLoaded: false,
      loading: true,
      segments: [],
    };
  }
  /**
   * Set store segments to redux state
   * @param StoreState} state
   * @param ActionSetStore action
   * @returns store: Store; loading: boolean; error: boolean; errorMessage: string
   */
  @Action(ActionSetStoreSegments)
  actionSetStoreSegments(
    state: StoreState,
    action: ActionSetStoreSegments
  ): any {
    return {
      ...state,
      segmentLoaded: true,
      loading: false,
      segments: action.segments,
    };
  }

  /**
   * set laoding flag to true
   * @param StoreState state
   * @returns store: Store; loading: boolean; error: boolean; errorMessage: string
   */
  @Action(ActionGetStores)
  actionGetStore(state: StoreState): any {
    return {
      ...state,
      loading: true,
    };
  }

  /**
   * set laoding flag to true
   * @param StoreState state
   * @returns store: Store; loading: boolean; error: boolean; errorMessage: string
   */
  @Action(ActionLoadStore)
  actionLoadStoreFromStorage(state: StoreState): any {
    return {
      ...state,
      storageLoading: true,
    };
  }

  /**
   * Set all loaded stores to redux cart
   * @param StoreState state
   * @param ActionAllStoreLoaded action
   * @returns store: Store; loading: boolean; error: boolean;
   * errorMessage: string; availableStores: []
   */
  @Action(ActionAllStoreLoaded)
  actionAllStoreLoaded(state: StoreState, action: ActionAllStoreLoaded): any {
    return {
      ...state,
      availableStores: action.payload.sort((a, b) => {
        if (a.storeAttribute.displayName > b.storeAttribute.displayName) {
          return 1;
        }

        return -1;
      }),
      loading: false,
    };
  }

  @Action(ActionRestrictStores)
  actionRestrictStore(state: StoreState, action: ActionRestrictStores): any {
    const stores: any = state.availableStores.filter(
      (store) => action.allowedStores.indexOf(store.storeId) != -1
    );

    return {
      ...state,
      availableStores: stores,
      loading: false,
    };
  }

  /**
   * Clear the store state.
   * @param StoreState state
   * @param ActionStoreError action
   * @returns store: Store; loading: boolean; error: boolean; errorMessage: string; errorMsg:
   */
  @Action(ActionStoreError)
  actionStoreError(state: StoreState, action: ActionStoreError): any {
    return {
      ...state,
      loading: false,
      segmentLoaded: false,
      error: true,
      errorMsg: action.payload,
      segments: [],
    };
  }

  /**
   * To load delivery slots details,Set store segments to loading state,
   * @param StoreState state
   * @param ActionLoadDeliverySlots action
   * @returns store:Store; loading: boolean; error: boolean
   */
  @Action(ActionLoadDeliverySlots)
  actionLoadDeliverySlots(
    state: StoreState,
    action: ActionLoadDeliverySlots
  ): any {
    return {
      ...state,
      loading: true,
      error: false,
    };
  }

  /**
   * Action to store delivery slots loaded,
   * @param StoreState state
   * @param  action
   * @returns store : Store; loading: boolean ; error: boolean; deliverySlots:[]
   */
  @Action(ActionDeliverySlotsLoaded)
  actionDeliverySlotsLoaded(
    state: StoreState,
    action: ActionDeliverySlotsLoaded
  ): any {
    return {
      ...state,
      loading: false,
      error: false,
      deliverySlotFilters: action.payload.slotFilterGroup,
      slotMap: action.payload.slotMap,
    };
  }

  /**
   * Action to be called when delivary slots are not loaded,
   * @param StoreState store
   * @param ActionDeliverySlotsLoadFailed action
   * @returns store: store; loading: boolean;error : boolean; errorMessage:string
   */
  @Action(ActionDeliverySlotsLoadFailed)
  actionDeliverySlotsLoadFailed(
    state: StoreState,
    action: ActionDeliverySlotsLoadFailed
  ): any {
    return {
      ...state,
      loading: false,
      error: true,
      errorMessage: action.payload,
    };
  }

  /**
   * set laoding flag to true
   * @param StoreState state
   * @returns store: Store; loading: boolean; error: boolean; errorMessage: string
   */
  @Action(ActionLoadAllStores)
  actionLoadAllStore(state: StoreState): any {
    return {
      ...state,
      loading: true,
    };
  }

  /**
   * Set all loaded stores to redux cart
   * @param StoreState state
   * @param ActionStoresLoadSuccess action
   * @returns store: Store; loading: boolean; error: boolean; availableStores: []
   */
  @Action(ActionStoresLoadSuccess)
  actionStoresLoadSuccess(
    state: StoreState,
    action: ActionStoresLoadSuccess
  ): any {
    return {
      ...state,
      allStores: action.stores,
      loading: false,
    };
  }

  /**
   * set loading flag to true
   * @param StoreState state
   * @returns store: Store; loading: boolean; error: boolean;
   */
  @Action(ActionLoadCities)
  actionLoadCities(state: StoreState): StoreState {
    return {
      ...state,
      loading: true,
      error: false,
    };
  }

  /**
   * Set all loaded cities to redux cart
   * @param StoreState state
   * @param ActionSaveCities action
   * @returns store: Store; loading: boolean; error: boolean;
   * errorMessage: string; cities: []
   */
  @Action(ActionSaveCities)
  actionSaveCities(state: StoreState, action: ActionSaveCities): StoreState {
    return {
      ...state,
      cities: action.payload,
      loading: false,
      error: false,
    };
  }

  /**
   * Clear the store state.
   * @param StoreState state
   * @param ActionErrorLoadCities action
   * @returns store: Store; loading: boolean; error: boolean;
   */

  @Action(ActionErrorLoadCities)
  actionErrorLoadCities(
    state: StoreState,
    action: ActionErrorLoadCities
  ): StoreState {
    return {
      ...state,
      loading: false,
      error: true,
    };
  }
}

export const storeReducer: (state: any, action: any) => any =
  createReducer(StoreReducers);

import {
  Directive,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import * as selectors from "@store/state";
import { StoreState } from "@store/store";
import { Subscription } from "rxjs";
import { skipWhile, take } from "rxjs/operators";

export interface ElementControl {
  hide: boolean;
  stores: string[];
}
@Directive({
  selector: "[appFirebaseControlledElement]",
})
export class FirebaseControlledElementDirective implements OnInit {
  elementById$: Subscription;
  elementId: string;
  storeId: string;
  elementControl: ElementControl;

  constructor(
    private afs: AngularFirestore,
    private elementRef: ElementRef,
    public store: Store<AppState>
  ) {}

  /**
   * check the element have id and start the firebase value changes subscription
   */
  ngOnInit(): void {
    if (this.elementRef.nativeElement && this.elementRef.nativeElement.id) {
      this.elementId = this.elementRef.nativeElement.id;
      this.startElementByIdSubscription();
    }
  }

  /**
   * Return the current store Id from the redux
   */
  async getStoreId(): Promise<string> {
    const storeState: StoreState = await this.store
      .select(selectors.getStoreState)
      .pipe(
        skipWhile(
          (state) =>
            state.availableStores.length == 0 ||
            typeof state.store == "undefined"
        ),
        take(1)
      )
      .toPromise();

    return storeState.store.toString();
  }

  /**
   *  Start subscribing to the leaf elementById/<--id--> in firebase
   *  It will hide or show the element based on the hide & stores field.
   *  if hide field is undefined or null, element will be shown to all stores.
   *  if hide field is true, element will be hided in listed elementControl stores.
   *  if hide field is false, element will be shown in listed elementControl stores.
   */
  private startElementByIdSubscription(): void {
    const elementStatusDb: any = this.afs.doc(
      "elementById/" + this.elementId.toString()
    );
    this.elementById$ = elementStatusDb
      .valueChanges()
      .subscribe(async (data: ElementControl) => {
        if (!data || data.hide == undefined || data.hide == null) {
          this.elementRef.nativeElement.style.display = "block";

          return;
        }
        this.elementControl = data;
        this.storeId = await this.getStoreId();
        this.elementControl.hide == true ? this.hideElement() : this.showElement();
      });
  }

  /**
   * Hide the element if the current store id is listed in elementControl stores
   * if there is no stores list, blindly it will hide for all stores
   */
  hideElement(): void {
    if (!this.elementControl.stores?.length) {
      this.elementRef.nativeElement.style.display = "none";

      return;
    }
    const displayType: string = this.elementControl.stores.includes(
      this.storeId
    )
      ? "none"
      : "block";
    this.elementRef.nativeElement.style.display = displayType;
  }

  /**
   * show the element if the current store id is listed in elementControl stores
   * if there is no stores list, blindly it will show for all stores
   */
  showElement(): void {
    if (!this.elementControl.stores?.length) {
      this.elementRef.nativeElement.style.display = "block";

      return;
    }
    const displayType: string = this.elementControl.stores.includes(
      this.storeId
    )
      ? "block"
      : "none";
    this.elementRef.nativeElement.style.display = displayType;
  }

  /**
   * unsubscribe the value changes subscrption
   */
  ngOnDestory(): void {
    this.elementById$.unsubscribe();
  }
}

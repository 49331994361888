import {
  createFeatureSelector,
  createSelector,
  DefaultProjectorFn,
  MemoizedSelector,
} from "@ngrx/store";
import { Store, StoreSegment, User } from "@tendercuts/models";
import { catalogInitialState, CatalogState } from "./catalog/state";
import { driverInitialState, DriverState } from "./driver/state";
import { orderInitialState, OrderState } from "./orders/state";
import { storeInitialState, StoreState } from "./store/state";
import { userInitialState, UserState } from "./user/state";
// TODO
// eslint-disable-next-line @typescript-eslint/naming-convention
export interface AppState {
  userState: UserState;
  storeState: StoreState;
  orderState: OrderState;
  driverState: DriverState;
  catalog: CatalogState;
}

export const getAppState: MemoizedSelector<
  object,
  AppState,
  DefaultProjectorFn<AppState>
> = createFeatureSelector<AppState>("appState");
export const getStoreState: MemoizedSelector<
  object,
  StoreState,
  DefaultProjectorFn<StoreState>
> = createFeatureSelector<StoreState>("storeState");
export const getUserState: MemoizedSelector<
  object,
  UserState,
  DefaultProjectorFn<UserState>
> = createFeatureSelector<UserState>("userState");
export const getOrderState: MemoizedSelector<
  object,
  OrderState,
  DefaultProjectorFn<OrderState>
> = createFeatureSelector<OrderState>("orderState");
export const getDriverState: MemoizedSelector<
  object,
  DriverState,
  DefaultProjectorFn<DriverState>
> = createFeatureSelector<DriverState>("driverState");
export const getCatalogState: MemoizedSelector<
  object,
  CatalogState,
  DefaultProjectorFn<CatalogState>
> = createFeatureSelector<CatalogState>("catalog");

export const getUser: MemoizedSelector<
  object,
  User,
  DefaultProjectorFn<User>
> = createSelector(getAppState, (store: AppState) => store.userState.user);

export const getLastOrderTime: MemoizedSelector<
  object,
  string,
  DefaultProjectorFn<string>
> = createSelector(getOrderState, (store: OrderState) => store.lastOrderTime);

export const getSelectedStore: MemoizedSelector<
  object,
  Store,
  DefaultProjectorFn<Store>
> = createSelector(getStoreState, (state: StoreState) => {
  const store: Store[] = state.availableStores.filter(
    (storeData) => storeData.storeId == state.store,
  );

  return store[0];
});

export const getStoreSegments: MemoizedSelector<
object,
StoreSegment[],
DefaultProjectorFn<StoreSegment[]>
> = createSelector(
  getStoreState,
  (state: StoreState) => {
    return state.segments;
  },
);

export const appInitialState: AppState = {
  userState: userInitialState,
  storeState: storeInitialState,
  orderState: orderInitialState,
  driverState: driverInitialState,
  catalog: catalogInitialState,
};

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BasePutProvider } from "@tendercuts/http";
import { GenericServerResponse } from "@tendercuts/models";

@Injectable({ providedIn: "root" })
export class UpdateDriverService extends BasePutProvider<GenericServerResponse> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices
  ) {
    super(http);
  }

  get key(): string {
    return "id";
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get endpoint(): string {
    return this.endpointService.updateRiderDetails;
  }

  get model(): typeof GenericServerResponse {
    return GenericServerResponse;
  }

  get isCachable(): boolean {
    return false;
  }

  // As its an form data we pass content type as undefined
  get contentType(): any {
    return undefined;
  }

  /**
   * To update driver profile
   * @param driverId
   * @param driverProfileId
   * @param accountNo
   * @param ifscCode
   * @param profile_image
   * @param pan
   *
   */
  getParams(
    driverProfileId: number,
    driverId: number,
    accountNo: number,
    ifscCode: number,
    pan: any,
    profileImage: File,
    preferredLanguage: string[],
    earningScheme: string
  ): FormData {
    const params: FormData = new FormData();
    params.append("id", driverProfileId.toString());
    params.append("user", driverId.toString());
    params.append("account_no", accountNo.toString());
    params.append("ifsc_code", ifscCode.toString());
    params.append("pan", pan.toString());
    params.append("profile_image", profileImage, profileImage.name);
    params.append("preferred_language", preferredLanguage.toString());
    params.append("earning_scheme", earningScheme);


    return params;
  }
}

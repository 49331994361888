import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BaseGetProvider } from "@tendercuts/http";
import { TripPayment } from "@tendercuts/models";

@Injectable({
  providedIn: "root",
})
export class TripPaymentPaidService extends BaseGetProvider<TripPayment> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
    this.delegate = this;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  // override this
  preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof TripPayment {
    return TripPayment;
  }

  get isCachable(): boolean {
    return false;
  }

  get endpoint(): string {
    return this.endpointService.tripSheetPay();
  }

  /**
   *
   * @param id
   * sheet id for the trip
   */
  getParams(id: number): {
    sheet_id: number;
} {
    const params: {
      sheet_id: number;
  } = {
      sheet_id: id,
    };

    return params;
  }
}

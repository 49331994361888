import { AfterContentChecked, Component, Input } from "@angular/core";
import { StoreSegment } from "@tendercuts/models";

@Component({
  selector: "app-store-segment",
  templateUrl: "./store-segment.component.html",
  styleUrls: ["./store-segment.component.scss"],
})
export class StoreSegmentComponent implements AfterContentChecked {
  storeSegments: StoreSegment;
  polygon: google.maps.Polygon;

  @Input() map: any;

  /**
   * Hover flag - If set we show the name of the segment on mouse
   * hover
   */
  @Input() noHover: boolean = false;

  /**
   * Fill opacity for the polygon
   */
  @Input() fillOpacity: number = 0;

  /**
   * Fill color for the polygon
   */
  @Input() fillColor: string = null;

  /**
   * Position to show the tooltip at, if not specified we display it at
   * the first point
   */
  @Input() position: number = null;

  /**
   * Flag - if set we highlight the segment path.
   * @param status
   */
  @Input() set highlight(status: any) {
    if (!this.polygon) {
      return;
    }

    if (status) {
      this.polygon.setOptions({
        fillColor: "#c11c2d",
      });
    } else {
      this.polygon.setOptions({
        fillColor: "#cdcdcd",
      });
    }
  }

  @Input()
  set storeSegment(segment: StoreSegment) {
    this.storeSegments = segment;

    const points: any[] = [];
    const components: string[] = this.storeSegments.pathString.split(",");
    while (components.length > 0) {
      const lat: number = parseFloat(components.shift());
      const lng: number = parseFloat(components.shift());
      points.push(new google.maps.LatLng(lat, lng));
    }

    const randomColor: string = Math.floor(Math.random() * 16777215).toString(
      16
    );
    const fillColor: string = this.fillColor
      ? this.fillColor
      : `#${randomColor}`;
    this.polygon = new google.maps.Polygon({
      paths: points,
      fillColor,
      strokeColor: segment.strokeColor,
      strokeOpacity: 1,
      strokeWeight: segment.strokeWeight,
      fillOpacity: this.fillOpacity,
      clickable: true,
      zIndex: segment.zIndex,
    });

    if (!this.map) {
      return;
    }

    this.polygon.setMap(this.map);
    // No hover
    if (this.noHover) {
      return;
    }

    // opens segment info in center of the polygon or in the position passed
    const position: any = this.position
      ? this.position
      : this.storeSegments.center;
    const infoWindow: google.maps.InfoWindow = new google.maps.InfoWindow({
      content: this.storeSegments.name,
      position,
    });

    const openInfo: () => void = () => infoWindow.open(this.map);
    google.maps.event.addListener(this.polygon, "mouseover", openInfo);
    const closeInfo: () => void = () => infoWindow.close();
    google.maps.event.addListener(this.polygon, "mouseout", closeInfo);
  }

  constructor() {}

  ngAfterContentChecked(): void {
    // if (this.map) {
    //   this._polygon.setMap(this.map);
    // }
  }
}

// TODO
/* eslint-disable */
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { NavigationEnd, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { ActionDriverStoreClear } from "@store/driver";
import { ActionOrderStoreClear } from "@store/orders";
import { AppState } from "@store/state";
import { ActionLogoutUser } from "@store/user";
import { BaseComponent, ModalComponent } from "../../../utils";

@Component({
  selector: "side-menu-content",
  styleUrls: ["./styles/side-menu-content.scss"],
  templateUrl: "./side-menu-content.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class SideMenuContentComponent extends BaseComponent implements OnInit {
  liveTrackTabRef: Window;
  constructor(
    public route: Router,
    public dialog: MatDialog,
    public store: Store<AppState>,
  ) {
    super(store);
  }

  @Output()
  autoClose: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.autoClose.emit(true);
      }
    });
  }

  logout(): void {
    const dialogRef: MatDialogRef<ModalComponent, any> = this.dialog.open(ModalComponent, {
      data: {
        icon: "exclamation-circle",
        iconColor: "alert",
        title: "Do you want to logout?",
        text: "Are you sure?",
        options: true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.loginService.logout();
        this.store.dispatch(new ActionOrderStoreClear());
        this.store.dispatch(new ActionDriverStoreClear());
        this.store.dispatch(new ActionLogoutUser());

        this.route.navigate(["login"]);
        this.dialog.open(ModalComponent, {
          data: {
            icon: "check-circle",
            iconColor: "success",
            title: "Logged out successfully",
            text: "See you soon!",
            button: "OK",
          },
        });
      }
    });
  }
  /**
   * Opens live track in new tab if live track tab does not
   * exist already. If it already exists then reuses that tab and
   * brings it to focus.
   */
  openLiveTrack(): void {
    if (!this.liveTrackTabRef || this.liveTrackTabRef.closed) {
      this.liveTrackTabRef = window.open("/live-track", "live-track");
    } else {
      this.liveTrackTabRef.focus();
    }
  }
}

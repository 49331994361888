import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTabGroup } from "@angular/material/tabs";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

import * as selectors from "@store/state";
import { AppState } from "@store/state";
import { ActionGetStores, StoreState } from "@store/store";
import { InventoryRequest } from "@tendercuts/models";
import {
  FetchParentProductService,
  InventoryRequestService,
} from "@tendercuts/providers";

import { HttpParams } from "@angular/common/http";
import { Constants } from "@app/env";
import { Filter, FilterGroup, FilterMatch, FilterModel } from "src/models";
import { ProductDataSource } from "../../components";
import { BasePage } from "../../utils";
import {
  InventoryAdjustmentDialogComponent,
} from "../inventory-adjustment-dialog/inventory-adjustment-dialog.component";

@Component({
  selector: "app-inventory-adjustment",
  templateUrl: "./inventory-adjustment.component.html",
  styleUrls: ["./inventory-adjustment.component.scss"],
})
export class InventoryAdjustmentComponent
  extends BasePage
  implements OnInit, AfterViewInit {
  private _ACCESSIBLE_STORES: number[] = [1];
  private INV_REQUEST_TYPE: number = 2;
  public today: Date = new Date();
  public isRequestTriggered: boolean = false;
  searchFilterGroup: FilterGroup = new FilterGroup([
    new Filter("Search", null, ["name", "sku"], FilterMatch.CONTAINS),
  ]);
  modelFilter: FilterModel = new FilterModel([this.searchFilterGroup]);
  productDataSources: ProductDataSource[] = [];
  selectedStoreId: any;
  requests: InventoryRequest[] = [];

  @ViewChild("tab") tabView: MatTabGroup;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public fetchParentProductService: FetchParentProductService,
    public inventoryRequestService: InventoryRequestService,
    public constants: Constants,
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    this.store.dispatch(new ActionGetStores());
    this.stores.subscribe((state: StoreState) => {
      if (this.selectedStoreId != state.store) {
        this.selectedStoreId = state.store;
        this.loadData();
      }
    });
  }

  checkForAccessTime(): boolean {
    if (this.constants.IS_DEVELOPMENT) {
      return true;
    }
    const currentHour: number = new Date().getHours();
    const currentMinutes: number = new Date().getMinutes();
    if (19 <= currentHour && currentHour <= 24) {
      return true;
    }

    return false;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.selectedStoreId) {
        this.loadData();
      }
    }, 1);
  }

  get stores(): Observable<StoreState> {
    return this.store.select(selectors.getStoreState);
  }

  /**
   * fetch the parent products by hiting
   *  FetchParentProductService and trigger refreshData
   */
  loadData(): void {
    this.productDataSources = [];
    this.requests = [];
    // 1 - Thoraipakkam store ID. parent will fetch only for Thoraipakkam
    if (this._ACCESSIBLE_STORES.indexOf(+this.selectedStoreId) !== -1) {
      const param: HttpParams = this.fetchParentProductService.getParams(
        this.selectedStoreId,
      );
      this.fetchParentProductService.getData(param).subscribe(
        (categories) => {
          this.refreshData(categories);
        },
        (err) => {
          this.somethingWentWrong();
        },
      );
    }
  }

  /**
   *
   * @param categories - Catagory fetched from api
   * Create a product data source and push to products array
   */
  refreshData(categories: any): void {
    this.productDataSources = [];
    categories.forEach((category) => {
      if (category.name === "Hot Deals") {
        return;
      }
      // try to find existing datasource, or create one
      const sources: ProductDataSource[] = this.productDataSources.filter(
        (source) => source.key === category.name,
      );

      let dataSource: ProductDataSource;
      if (!sources || sources.length === 0) {
        dataSource = new ProductDataSource(
          this.modelFilter,
          category.products,
          category.name,
        );
      } else {
        dataSource = sources[0];
      }
      this.productDataSources.push(dataSource);
    });
  }

  openInventoryUpdateDialog(productDataSource: any): void {
    const dialogData: {
      productData: any;
    } = {
      productData: productDataSource,
    };
    const invAdjustmentDialog: MatDialogRef<
      InventoryAdjustmentDialogComponent,
      any
    > = this.dialog.open(InventoryAdjustmentDialogComponent, {
      data: dialogData,
      disableClose: true,
      width: "100vw",
      height: "80%",
    });

    invAdjustmentDialog.afterClosed().subscribe((status) => {
      if (status) {
        this.loadData();
      }
    });
  }
}

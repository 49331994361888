export class OrderRated {
  public order_id: string;
  public isOrderRated: boolean;

  deserialize(input) {
    this.order_id = input.increment_id;
    this.isOrderRated = input.status;
    return this;
  }
}

<div class="hypertracktrip" *ngIf="hyperTrackTrip">
  <div class="hypertracktrip__header row">
    <h6 class="col-6">Trip ID : {{ trip.tripId }}</h6>
    <span class="hypertracktrip__header__close col-6"
      ><i
        class="menu-item-icon fa fa-window-close-o"
        (click)="dialogClose()"
      ></i
    ></span>
  </div>
  <div class="hypertracktrip__details" *ngIf="hyperTrackTrip">
    <p *ngIf="showStoreETA">
      <mat-chip class="hypertracktrip__details__chip"
        >ETA to store: {{ hyperTrackTrip.remainingDuration }} min</mat-chip
      >
    </p>
    <p *ngIf="showNextOrderETA">
      <mat-chip class="hypertracktrip__details__chip"
        >ETA for next order:
        {{ nextOrderETA }}
        min</mat-chip
      >
    </p>
    <iframe class="hypertracktrip__iframe" [src]="embedUrl"> </iframe>
  </div>
</div>

<div>
  <app-gtg-stock-request
    *ngIf="isGtg; else tendercuts"
    [newInventoryMode]="newInventoryMode"
  ></app-gtg-stock-request>
  <ng-template #tendercuts>
    <app-stock-request
      [newInventoryMode]="newInventoryMode"
    ></app-stock-request>
  </ng-template>
</div>

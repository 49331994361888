<div class="adcash-wrapper">
  <div class="row no-gutters adcash-wrapper__header">
    <div class="col-6">
      {{ isRemoveCash ? "Remove Advance Cash from Trip" : "Add Advance Cash to \
      Trip" }}
    </div>
    <div class="col-6 adcash-wrapper__header__close-btn">
      <i
        class="menu-item-icon fa fa-window-close-o"
        (click)="dialogRef.close()"
      ></i>
    </div>
  </div>
  <form class="adcash-wrapper__form" (submit)="validateInputs()">
    <mat-form-field class="adcash-wrapper__form__input-field">
      <input
        type="number"
        id="cash"
        placeholder="Enter amount"
        matInput
        [formControl]="cashInputControl"
      />
      <mat-hint
        *ngIf="!isRemoveCash; else removeCashHint"
        class="adcash-wrapper__form__hint"
        >The cash will be added to the trip as Advance cash and to be collected
        once the trip is completed.
      </mat-hint>
      <ng-template #removeCashHint>
        <mat-hint class="adcash-wrapper__form__hint"
          >Only the advance cash added previously for this trip can be removed.
        </mat-hint>
      </ng-template>
    </mat-form-field>
  </form>

  <div class="adcash-wrapper__btn-container">
    <button
      mat-raised-button
      color="primary"
      class="adcash-wrapper__btn-reset"
      id="reset-cash"
      (click)="reset()"
    >
      <i class="fa fa-refresh" aria-hidden="true"></i>
      Reset
    </button>
    <button
      mat-raised-button
      color="primary"
      id="submit-cash"
      (click)="validateInputs()"
    >
      <i class="fa fa-money" aria-hidden="true"></i>
      {{ isRemoveCash ? 'Remove Cash' : 'Submit Cash' }}
    </button>
  </div>
</div>

export interface Serializable<T> {
  deserialize(input: Object): T;

  // serialize?(): any;
}

export interface Eav {
  code: string;
  value: string | Eav[];
}

export interface EavSerializable {
  eavDeserialize(input: Eav[] | Eav);
}

export class DefaultSerializationMixin implements EavSerializable {
  /**
   *
   * CAVEAT: Does not handle an array of serializable
   */
  eavDeserialize(input) {
    if (!(input instanceof Array))
      input = [input];

    for (let eav of input) {
      let attrName = eav.code;
      let attrValue = eav.value;
      let attrTitle = eav.title;
      let attr = this[attrName];

      if (typeof attr === 'object' && 'eavDeserialize' in attr) {
                attr.eavDeserialize(attrValue);
      }
      else {
        this[attrName] = attrValue;
        // Display title for attribut
        attrTitle = attrTitle != null ? attrTitle : attrName;
        this[`${attrName}__display`] = attrTitle;
      }

    }

    return this;
  }
}

import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { GridRowDetailComponent } from "./grid-row-detail.component";
import { GridRowHost } from "./grid-row-host.directive";

@Component({
  selector: "app-grid-row-renderer",
  template: ` <ng-template detail></ng-template> `,
})
export class GridRowRendererComponent implements OnInit {
  @Input() model: any;
  @Input() component: any;

  @ViewChild(GridRowHost, { static: true })
  detailHost: GridRowHost;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit(): void {
    const componentFactory: ComponentFactory<
    unknown
    > = this.componentFactoryResolver.resolveComponentFactory(
      this.component,
    );

    const viewContainerRef: ViewContainerRef = this.detailHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef: ComponentRef<unknown> = viewContainerRef.createComponent(componentFactory);
    (componentRef.instance as GridRowDetailComponent).data = this.model;
  }
}

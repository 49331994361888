<mat-card class="plan-trip-wizard">
  <mat-card-header>
    <mat-card-title
      >Assisted Trip Wizard ({{ orders.filteredData.length }}
      Orders)
    </mat-card-title>
    <button
      id="generateroute"
      mat-raised-button
      color="primary"
      [disabled]="orders.filteredData.length == 0"
      (click)="generateTrips()"
    >
      <i class="material-icons">commute</i>
      Generate Routes
    </button>
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <div class="form col-12">
        <mat-form-field class="full-width">
          <input
            matInput
            placeholder="Enter Number of orders"
            [(ngModel)]="orderCount"
            type="number"
            value="Enter Number of orders"
          />
        </mat-form-field>
        <app-store-segment-filter
          [dataSource]="orders"
          (onFilterApplied)="applyFilters($event)"
        ></app-store-segment-filter>
      </div>
    </div>
  </mat-card-content>
</mat-card>

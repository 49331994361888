<ng-container class="slab" *ngIf="weeklyTripSheet && availableSlabs.length">
  <p class="slab__header">
    {{ weeklyTripSheet?.incentiveEarning }} / {{ currentSlabTarget }}
  </p>
  <div class="row no-gutters slab-text_center">
    <ng-container
      *ngFor="let slab of availableSlabs; let last = last; let i = index"
    >
      <div class="slab-text_center slab__col">
        <div
          id="{{ slab.slab_name }}"
          *ngIf="isCurrentSlab(slab); else showGray"
          class="slab__green fa fa-check-circle"
        ></div>
        <ng-template #showGray>
          <i id="{{ slab.slab_name }}" class="slab__gray fa fa-check-circle">
          </i>
        </ng-template>
        <div class="slab__name">
          <div>{{ slab.target }}</div>
        </div>
      </div>
      <div class="slab__col slab__pipe" *ngIf="!last" center>
        <hr
          [ngClass]="{
            'rm-margin': slab.target > weeklyTripSheet.incentiveEarning
          }"
          class="slab__track_line"
        />
      </div>
    </ng-container>
  </div>
</ng-container>

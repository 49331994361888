/* eslint-disable @typescript-eslint/naming-convention */
import { DefaultSerializationMixin } from "../base";

/**
 * Response data of create quote api.
 */
export class ResponseTotal extends DefaultSerializationMixin {
  private rewardpoints_earn: number = 0;
  private rewards_spend: number = 0;
  private packingcharges: number = 0;
  private elite_savings_pos: number = 0;
  private base_subtotal: number = 0;
  private delivery_savings_amount: number;
  private total_savings_amount: number;
  private elite_savings: number;
  private convenience_fee: number;

  public subtotal: number = 0;
  public credit: number = 0;
  public discount: number = 0;
  public shipping: number = 0;
  public actual_shipping: number = 0;
  public packingcharges_total: number = 0;
  public grand_total: number = 0;
  public tax_amount: number = 0;

  constructor() {
    super();
  }

  get grandTotal() {
    return this.grand_total;
  }

  get rewardsEarn() {
    return this.rewardpoints_earn;
  }

  get totalSavings(): number {
    return this.total_savings_amount;
  }

  get deliverySavings(): number {
    return this.delivery_savings_amount;
  }

  get eliteSavings(): number {
    return this.elite_savings;
  }

  get actualShipping(): number{
    return this.actual_shipping;
  }

  get convenienceFee(): number{
    return this.convenience_fee;
  }

  /**
   * Used in pos
   * Updated the earned rewards
   * @param rewards
   */
  addRewardsEarned(rewards: number) {
    this.rewardpoints_earn += rewards;
  }

  get rewardsSpend() {
    return this.rewards_spend;
  }

  set rewardsSpend(rewards) {
    this.rewards_spend = rewards;
  }

  get packingCharges() {
    return this.packingcharges;
  }

  get gstAmount() {
    return this.tax_amount;
  }

  get eliteSavingsPos(): number {
    return this.elite_savings_pos;
  }

  set eliteSavingsPos(value) {
    this.elite_savings_pos = value;
  }

  get baseSubtotal(): number {
    return this.base_subtotal;
  }
}

export class ResponseRewardPoints extends DefaultSerializationMixin {
  private spend_min_points: number;
  private spend_max_points: number = 0;
  private points_spend: number;

  constructor() {
    super();
    this.points_spend = 0;
    this.spend_max_points = 0;
    this.spend_min_points = 0;
  }

  get pointsSpend() {
    return this.points_spend;
  }

  set pointsSpend(points) {
    this.points_spend = points;
  }

  get maxPoints() {
    return this.spend_max_points;
  }

  get minPoints() {
    return this.spend_min_points;
  }

  serialize() {
    return {
      points_spend: this.points_spend,
    };
  }

  eavDeserialize(input) {
    return super.eavDeserialize(input);
  }
}

export class ResponseQuoteAddress extends DefaultSerializationMixin {
  private quote_address_id: number;

  get quoteAddressId() {
    return this.quote_address_id;
  }
}

export class QuoteAlerts extends DefaultSerializationMixin {
  public message: string;
  private cart_summary_message: string;
  private delivery_summary_message: string;
  private elite_savings: number;
  private elite_savings_message: string;
  private delivery_savings_message: string;
  private total_savings_message: string;
  private elite_summary_message: string;

  get deliverySavingsMessage(): string {
    return this.delivery_savings_message;
  }

  get totalSavingsMessage(): string{
    return this.total_savings_message;
  }

  get eliteSummaryMessage(): string{
    return this.elite_summary_message;
  }

  get cartSummaryMessage(): string {
    return this.cart_summary_message;
  }

  get deliverySummaryMessage(): string {
    return this.delivery_summary_message;
  }

  get eliteSavings(): number {
    return this.elite_savings;
  }

  get eliteSavingsMessage(): string {
    return this.elite_savings_message;
  }
}

export class QuoteConditions extends DefaultSerializationMixin {
  private mov_slider: boolean;
  private elite_benefit_bottom: boolean;
  private elite_benefit_top_banner: boolean;
  private addon_product_added: boolean;
  private you_may_also_like: boolean;
  private free_delivery_popup: boolean;
  private renew_elite_banner: boolean;


  get isMovEnabled(): boolean {
    return this.mov_slider;
  }

  get isEliteBannerTopEnabled(): boolean{
    return this.elite_benefit_top_banner;
  }

  get isEliteBenefitBottomEnabled(): boolean {
    return this.elite_benefit_bottom;
  }

  get isAddOnProductEnabled(): boolean {
    return this.addon_product_added;
  }

  get isRecProductEnabled(): boolean{
    return this.you_may_also_like;
  }

  get isRenewEliteBannerEnabled(): boolean{
    return this.renew_elite_banner;
  }

  get isFreeDeliveryPopupEnabled(): boolean{
    return this.free_delivery_popup;
  }

  constructor(){
    super();
  }

  eavDeserialize(input: any): any {
    this.mov_slider = input.mov_slider;
    this.elite_benefit_bottom = input.elite_benefit_bottom;
    this.elite_benefit_top_banner = input.elite_benefit_top_banner;
    this.addon_product_added = input.addon_product_added;
    this.you_may_also_like = input.you_may_also_like;
    this.free_delivery_popup = input.free_delivery_popup;
    this.renew_elite_banner = input.renew_elite_banner;

    return this;
  }
}

export class ItemTotal extends DefaultSerializationMixin {
  public row_total: number;
  public base_row_total: number;

  constructor() {
    super();
  }
  get rowTotal(): number {
    return this.row_total;
  }

  get baseRowTotal(): number{
    return this.base_row_total;
  }

  eavDeserialize(input) {
    this.base_row_total = input.base_row_total;
    this.row_total = input.row_total;
    return this;
  }

}
/**
 * Every attribute should match with the response generated
 * by server.
 */
export class QuoteResponse extends DefaultSerializationMixin {
  min_cart_value = 0;
  public coupon_message;
  public message;

  public total: ResponseTotal = new ResponseTotal();
  public address: ResponseQuoteAddress = new ResponseQuoteAddress();
  private reward_points: ResponseRewardPoints = new ResponseRewardPoints();
  public alerts: QuoteAlerts = new QuoteAlerts();
  public ui_conditions: QuoteConditions = new QuoteConditions();
  public item_total: ItemTotal = new ItemTotal();


  /**
   * Holds the cart items row total and base row total.
   * NOTE: We can't come with a type as the product key will always be dynamic.
   */
  public items;

  get minCartValue() {
    return this.min_cart_value;
  }

  get rewardPoints() {
    return this.reward_points;
  }

  get itemTotal() {
    return this.item_total;
  }

  private quote_id;

  get quoteId() {
    return this.quote_id;
  }

  constructor() {
    super();
  }

  eavDeserialize(input) {
    return super.eavDeserialize(input);
  }
}

import { Category, ComboSpec, GenericError, LiveTrack, Product } from "@tendercuts/models";
// TODO
// eslint-disable-next-line @typescript-eslint/naming-convention
export interface CatalogState {
  catalog: Category[];
  loading: boolean;
  errorMsg: GenericError;
  filteredProducts: Product[];
  products: Product[];
  productMap: { [id: number]: Product };
  liveTrack: LiveTrack[];
}

export const catalogInitialState: {
  catalog: any[];
  loading: boolean;
  errorMsg: any;
  filteredProducts: any[];
  products: any[];
  productMap: {};
  liveTrack: any[];
} = {
  catalog: [],
  loading: false,
  errorMsg: null,
  filteredProducts: [],
  products: [],
  productMap: {},
  liveTrack: [],
};

import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { SaleOrder, Trips, TripCash } from "@tendercuts/models";
import { CanHoldService, UnHoldOrderService } from "@tendercuts/providers";
import { BasePage } from "../../utils/pages/base/base.component";

@Component({
  selector: "app-can-hold",
  templateUrl: "./can-hold.component.html",
  styleUrls: ["./can-hold.component.scss"],
})
export class CanHoldComponent extends BasePage {
  order: SaleOrder;
  constructor(
    public dialogRef: MatDialogRef<CanHoldComponent>,
    private unHoldOrderService: UnHoldOrderService,
    public canHoldService: CanHoldService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialog, snackBar, store);
    if (data) {
      this.order = data.order;
    }
  }

  /**
   * unhold the order
   * return the order into the store
   */
  returnToStore(): void {
    const params: {
      increment_id: string;
      can_add: boolean;
    } = this.unHoldOrderService.getparams(this.order.incrementId, false);
    this.presentLoader();
    this.dialogRef.close(params);
    this.unHoldOrderService.getData(params).subscribe(
      (response) => {
        this.dismissLoader();
        this.textAlert(response[0].message, "Order Return to the store");
      },
      (err) => this.somethingWentWrong(),
    );
  }

  /**
   * unhold the order
   * return the order into the trip
   */
  returnToTrip(): void {
    const params: {
      increment_id: string;
      can_add: boolean;
    } = this.unHoldOrderService.getparams(this.order.incrementId, true);
    this.presentLoader();
    this.dialogRef.close(params);
    this.unHoldOrderService.getData(params).subscribe(
      (response) => {
        this.dismissLoader();
        this.textAlert(response[0].message, "Order Return to the trip");
      },
      (err) => this.somethingWentWrong(),
    );
  }
}

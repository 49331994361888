import { Injectable } from "@angular/core";
import { SaleOrder } from "@tendercuts/models";
import { PosQuote } from "../../models";

@Injectable({ providedIn: "root" })
export class QuoteSerializerService {
  constructor() {}

  /**
   * serialized quote for save order in firebase
   * @param quote
   * @param storeId
   */
  quoteToSaleOrderSerializer(
    quote: PosQuote,
    storeId: any,
  ): {
    x: number;
    s: any;
    tc: number;
    cc: number;
    ti: any;
    cn: any;
    id: string;
    u: {
      id: number;
      p: string;
      n: string;
    };
    c: string;
    p: {
      id: number;
      q: number;
    }[];
    pay: string;
    rp: number;
    d: string;
    gt: string;
    dt: string;
  } {
    const products: {
      id: number;
      q: number;
    }[] = quote.cartProducts.map((product) => {
      return { id: product.productId, q: product.quantity };
    });

    return {
      x: 0,
      s: storeId,
      tc: quote.tenderCash,
      cc: quote.changeCash,
      ti: quote.transactionId,
      cn: quote.cardNumber,
      id: quote.orderId,
      u: quote.user.fireBaseSerialize(),
      c: quote.couponCode ? quote.couponCode : null,
      p: products,
      pay: quote.paymentMethod,
      rp: quote.totals.rewardPointsSpend,
      d: quote.totals.discount.toFixed(2),
      gt: quote.totals.grandTotal.toFixed(2),
      dt: new Date().toLocaleString(),
    };
  }

  /**
   * serialized quote for save quote in firebase
   * @param quote
   * @param storeId
   */
  quoteToVoidQuoteSerializer(
    quote: PosQuote,
    storeId: any,
    cancelReason: string,
  ): {
    x: number;
    s: any;
    p: {
      id: number;
      q: number;
    }[];
    cr: string;
    dt: string;
  } {
    const products: {
      id: number;
      q: number;
    }[] = quote.cartProducts.map((product) => {
      return { id: product.productId, q: product.quantity };
    });

    return {
      x: 0,
      s: storeId,
      p: products,
      cr: cancelReason,
      dt: new Date().toLocaleString(),
    };
  }

  /**
   * converting saleorder to posquote
   * @param order
   */
  public posQuoteSerializer(order: SaleOrder): PosQuote {
    const quote: any = {
      status: order.status,
      cartProducts: order.items,
      user: {
        entity_id: order.customerId,
        firstname: order.firstname,
      },
      reward_points_sepnd: order.rewardAmount,
      payment_method: order.paymentMethod,
      created_at: order.createdAt,
      order_id: order.incrementId,
      medium: order.medium,
      totals: {
        subtotal: order.subtotal,
        discount: order.discount,
        taxAmount: order.taxAmount,
        rewardPointsSpend: order.rewardAmount,
        grandTotal: order.total,
      },
      coupon_code: order.couponCode,
    };

    return new PosQuote().deserialize(quote);
  }
}

import { ModuleWithProviders } from "@angular/core";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";

export class Constants {
  CDN: any = "https://cdn1.tendercuts.in";
  API_URL: any = "https://api1.tendercuts.in/";
  TRACK_URL: any = "https://track.tendercuts.in/";
  MAGE_URL: any = "https://tendercuts.in/";
  LOGO_URL: any = "https://tendercuts.in/media/logo.png";
  DEPLOY_CHANNEL: any = "production";
  WEB_URL: any = "https://tendercuts.in/";
  GTG_WEB_URL: any = "https://www.goodtogostore.com/";
  MESSAGES: string[] = [
    "RNR_1",
    "RNR_2",
    "RNR_3",
    "Quality_complaint_RNR_1",
    "Quality_complaint_RNR_2",
    "Quality_complaint_RNR_3",
    "Order_cancellation_RNR_2",
    "Order_cancellation_RNR_1",
    "Cancellation_Message_1",
    "Delivery_Complaint_RNR_1",
    "Delivery_Complaint_RNR_2",
    "Delivery_Complaint_RNR_3",
    "Pickup_reminder",
    "Store_pick_up_cancellation",
    "Online_delivery_cancellation",
  ];
  IS_DEVELOPMENT: boolean = false;
  IS_PROD: boolean = true;

  // Choose the reason for StoreCredits
  reasons: string[] = [
    "IPL Contest",
    "Celebrity Bonus",
    "Payment Received Twice ",
    "Change Not Given",
    "Broken Eggs",
    "Monthly Top Up",
    "Out of Stock",
    "Customer Requested Cancellation",
    "Store Pick Up Cancellation",
    "Mistakenly Added",
    "Wrong Product",
    "Missed product",
    "Delivery Guarantee",
    "Quality Guarantee",
    "Coupon Code Issue",
    "SMS Received Not Delivered",
    "Refund To Bank",
    "Weight Issue",
    "Retention offer",
  ];

  HYPERTRACKURL: string = "https://embed.hypertrack.com/tracking?view=trips&publishable_key=Cax06O56zH1RH-CqhofSzbnxarKqqnFmCIgspyrHzEFT7lEgBNDV2NtXoxwtpgOn222zgbbadFV6ogX2SvZgeg&hide_selector=true&hide_entity_selector=true&trip_complete=false&hide_sharing=true&hide_nav=true&trip_metadata_filter=";

}

export const devTools: ModuleWithProviders<StoreDevtoolsModule>[] = [
  StoreDevtoolsModule.instrument({
    logOnly: false,
    maxAge: 25,
  }),
];

export const firebaseConfig: any = {
  apiKey: "AIzaSyB_VQQkbkRgGLnmsO0K6Eg7_IJwHahFGzY",
  authDomain: "backend-prod-33ebc.firebaseapp.com",
  databaseURL: "https://backend-prod-33ebc.firebaseio.com",
  projectId: "backend-prod-33ebc",
  storageBucket: "backend-prod-33ebc.appspot.com",
  messagingSenderId: "17951133817",
  appId: "1:17951133817:web:d0686debff0a204d1584a8",
  measurementId: "G-E447VKTYB7"
};

export const secondaryAppConfig: any = {
  apiKey: "AIzaSyAlti5LKm-r42dkfLGLA1Hnh9IKt9VmkeU",
  authDomain: "live-track-staging.firebaseapp.com",
  projectId: "live-track-staging",
  storageBucket: "live-track-staging.appspot.com",
  messagingSenderId: "1000499814104",
  appId: "1:1000499814104:web:405db01b5dbc17f9a801ad",
};

export const FIREBASE_ANALYTICS_DEBUG_MODE = false;

// export class Constants {
//   CDN = "https://cdn1.tendercuts.in";
//   API_URL = "https://staging.tendercuts.in:82/";
//   MAGE_URL = "http://staging.tendercuts.in/v3/website";
//   LOGO_URL = 'https://tendercuts.in/media/logo.png';
//   DEPLOY_CHANNEL = "production";
// }

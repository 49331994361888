<div class="dummy-rider">
  <mat-label>Label your trip</mat-label>
  <mat-form-field>
    <input
      matInput
      type="text"
      placeholder="Enter label"
      maxlength="100"
      [formControl]="driverLabelControl"
    />
  </mat-form-field>
</div>

import { Serializable } from "../base";

export class BotActiveChat implements Serializable<BotActiveChat> {
  private conversation_id: string;
  private flow_name: string;
  private external_conversation_id: string;
  private order_id: string;
  private customer_id: number;
  private is_active: boolean;
  private created_at: Date;
  private updated_at: Date;
  public id: number;

  get conversationId(): string {
    return this.conversation_id;
  }

  get flowName(): string {
    return this.flow_name;
  }

  get externalConversationId(): string {
    return this.external_conversation_id;
  }

  get orderId(): string {
    return this.order_id;
  }

  get customerId(): number {
      return this.customer_id;
  }

  get isActive(): boolean {
    return this.is_active;
  }

  get updatedAt(): Date {
    return this.updated_at;
  }

  get createddAt(): Date {
    return this.created_at;
  }

  constructor() {}

  deserialize(input) {
    this.id = input.id;
    this.conversation_id = input.conversation_id;
    this.flow_name = input.flow_name;
    this.external_conversation_id = input.external_conversation_id;
    this.order_id = input.order_id;
    this.customer_id = input.custoemr_id;
    this.is_active = input.is_active;
    this.created_at = input.created_at;
    this.updated_at = input.updated_at;

    return this;
  }
}

import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { DriverOrder, SaleOrder, Trips } from "@tendercuts/models";
import { FetchOrdersDetailsService } from "../../../providers";
import { BasePage } from "../../utils/pages/base/base.component";
import { OrderDisplayDialogComponent,
} from "../order-display-dialog/order-display-dialog.component";

@Component({
  selector: "app-order-id-sequence",
  templateUrl: "./order-id-sequence.component.html",
  styleUrls: ["./order-id-sequence.component.scss"],
})
export class OrderIdSequenceComponent extends BasePage {
  @Input() trip: Trips;

  constructor(
    public store: Store<AppState>,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public router: Router,
    public orderService: FetchOrdersDetailsService
  ) {
    super(dialog, snackBar, store);
  }

  /** If sale order is there opens order display and returns
   *  If sale order is undefined fetch orderDetails from api
   */
  selectOrder(order: DriverOrder): void {
    if (order.saleOrder == undefined) {
      this.fetchOrderDetail(order);

      return;
    }

    this.openOrderDisplay(order.saleOrder);
  }

  /** Fn to fetch order detail
   * @param order_id ie orderId is passed
   */
  fetchOrderDetail(order: DriverOrder): void {
    const params: {
      order_id: string;
    } = this.orderService.getParams(order.incrementId);
    this.presentLoader();
    this.orderService.getData(params).subscribe((orders: SaleOrder[]) => {
      this.dismissLoader();
      this.openOrderDisplay(orders[0]);
    });
  }

  /** Fn to open orderDisplay component
   * @param order ie saleOrder
   */
  openOrderDisplay(order: SaleOrder): void {
    const dialogRef: MatDialogRef<OrderDisplayDialogComponent, any> =
      this.dialog.open(OrderDisplayDialogComponent, {
        data: {
          order,
          tripDetailsMode: true,
        },
      });
  }
}

import {
  CartTotal,
  DefaultSerializationMixin,
  SaleItem,
  Serializable,
} from "@tendercuts/models";
import { PosUser } from "../pos-user";

export class PosQuote
  extends DefaultSerializationMixin
  implements Serializable<PosQuote> {
  public status: string;
  public couponTotalDiscount: number = 0;
  private rewardPointsExpend: number = 0;
  private paymentProcedure: string;
  private couponKey: string = null;
  private ordersId: string;
  public medium: string;
  public tenderCash: number = 0;
  public changeCash: number = 0;
  public transactionId: any = null;
  public cardNumber: any = null;
  public cartProducts: SaleItem[] = [];
  private generatedAt: Date;
  public user: PosUser;
  public totals: CartTotal = new CartTotal();
  public cartBarcodes: string[] = [];

  constructor() {
    super();
  }

  get rewardPointsSpend(): number {
    return this.rewardPointsExpend;
  }

  set rewardPointsSpend(points: number) {
    this.rewardPointsExpend = points;
  }

  get paymentMethod(): string {
    return this.paymentProcedure;
  }

  set paymentMethod(method: string) {
    this.paymentProcedure = method;
  }

  get couponCode(): string {
    return this.couponKey;
  }

  set couponCode(code: string) {
    this.couponKey = code;
  }

  get createdAt(): Date {
    return this.generatedAt;
  }

  set orderId(id: string) {
    this.ordersId = id;
  }

  get orderId(): string {
    return this.ordersId;
  }

  get availableRewardPoints(): number {
    return this.user.rewards - this.totals.rewardPointsSpend;
  }

  deserialize(input: any): this {
    if (!input) {
      return this;
    }
    this.status = input.status;
    if (input.user) {
      this.user = new PosUser().deserialize(input.user);
    }
    this.rewardPointsExpend = input.reward_points_spend;
    this.paymentProcedure = input.payment_method;
    this.couponKey = input.coupon_code;
    this.generatedAt = input.created_at;
    this.ordersId = input.order_id;
    input.cartProducts.forEach((product) => {
      if (product._product) {
        this.cartProducts.push(
          SaleItem.buildFromProduct(product._product, product.quantity),
        );
      } else {
        this.cartProducts = input.cartProducts;
      }
    });
    this.medium = input.medium;
    this.totals = input.totals;
    this.tenderCash = input.tenderCash;
    this.changeCash = input.changeCash;
    this.cartBarcodes = input.cartBarcodes;

    return this;
  }

  eavDeserialize(input: any): this {
    super.eavDeserialize(input);

    return this;
  }
}

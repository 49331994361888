import { User } from "@tendercuts/models";
// TODO
// eslint-disable-next-line @typescript-eslint/naming-convention
export interface UserState {
  user: User;
  loading: boolean;
  error: boolean;
  errorMessage: string;
  loginViaFlock: boolean;
}

export const userInitialState: UserState = {
  user: null,
  loading: false,
  error: false,
  errorMessage: null,
  loginViaFlock: false,
};

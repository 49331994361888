import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import {
  DriverLoginLogout,
  DriverProfile,
  GenerateTripSheetResponse,
  TripEarning,
  TripSheet,
} from "@tendercuts/models";
import { BasePage } from "src/app/utils";

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatStepper } from "@angular/material/stepper";

/**
 * component to verify trip earnings and trip sheet
 */
@Component({
  selector: "app-trip-sheet-wizard",
  templateUrl: "./trip-sheet-wizard.component.html",
  styleUrls: ["./trip-sheet-wizard.component.scss"],
})
export class TripSheetWizardComponent extends BasePage {
  tripSheet: TripSheet;
  // Fetch selected rider all trip earnings
  tripEarnings: TripEarning[] = [];
  // selected driver profile
  selectedRider: DriverLoginLogout;
  // user selected date
  selectedDate: Date = null;

  stepperIndex: number = 0;

  @ViewChild("stepper") matStepper: MatStepper;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<TripSheetWizardComponent>,
    public store: Store<AppState>,
  ) {
    super(dialog, snackBar, store);
    this.selectedRider = this.data.selectedRider;
    this.selectedDate = this.data.selectedDate;
    this.stepperIndex = this.data.stepperIndex;
    this.tripSheet = this.data.tripSheet;
  }

  /**
   * once generate trip sheet step to move
   * trip sheet wizard
   * @param $event
   */
  moveNextStepper($event: GenerateTripSheetResponse): void {
    this.tripSheet = $event.result;
    this.matStepper.next();
  }

  /**
   * close the dialog
   */
  closeDialog($event: any): void {
    this.dialogRef.close($event);
  }
}

import { HttpParams } from "@angular/common/http";
import { Component, Inject, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { DeliverySlot, Product, SaleItem, SaleOrder } from "@tendercuts/models";
import { DeliveryDate } from "@tendercuts/models";
import { ReplaceOrderService } from "@tendercuts/providers";
import { FetchDeliverySlotService } from "@tendercuts/providers";
import { Filter, FilterGroup, FilterMatch, FilterModel } from "src/models";
import { NumberInputAction, SaleItemDataSource } from "../../components";
import { BasePage } from "../../utils/pages/base/base.component";

/**
 * This component for call center to replace the order
 * by giving input data of quantity and slot type and delivery date
 * Getting slot details from fetchDeliverySlotService from model Delivery
 */
@Component({
  selector: "app-order-replace",
  templateUrl: "./order-replace.component.html",
  styleUrls: ["./order-replace.component.scss"],
})
export class ReplaceOrderComponent extends BasePage implements OnInit {
  @Input() order: SaleOrder;
  @Input() slotDates: DeliveryDate[];
  saleItemDataSource: SaleItemDataSource;
  replacementItems: any[] = [];
  selectedDateSlots: DeliverySlot[];
  replaceForm: FormGroup = new FormGroup({
    slotDay: new FormControl("", [Validators.required]),
    slotTime: new FormControl("", [Validators.required]),
  });
  buttonActions: NumberInputAction[] = [
    new NumberInputAction(
      "Enter Quantity",
      "packs",
      this.onItemSelect.bind(this),
    ),
  ];

  searchFilterGroup: FilterGroup = new FilterGroup([
    new Filter("Search", null, ["name"], FilterMatch.CONTAINS),
  ]);
  modelFilter: FilterModel = new FilterModel([this.searchFilterGroup]);

  constructor(
    public dialogRef: MatDialogRef<ReplaceOrderComponent>,
    public dialog: MatDialog,
    public fetchDeliverySlotService: FetchDeliverySlotService,
    public replaceorderService: ReplaceOrderService,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: SaleOrder,
  ) {
    super(dialog, snackBar, store);
    if (data) {
      this.order = data;
      const items: SaleItem[] = this.order.items.filter(
        (item: SaleItem) => item.isSimple,
      );
      this.saleItemDataSource = new SaleItemDataSource(this.modelFilter, items);
    }
  }

  ngOnInit(): void {
    const params: HttpParams = this.fetchDeliverySlotService.getParams(
      this.order.quoteId,
    );
    this.fetchDeliverySlotService
      .getData(params)
      .subscribe((data: DeliveryDate[]) => {
        if (data) {
          this.slotDates = data;
        }
      });
  }
  /**
   * According to the selection of datethe slot loads
   * @param event is on selection change of value
   */
  selectedDay(event: DeliveryDate): void {
    this.selectedDateSlots = event.slots;
    this.replaceForm.controls["slotDay"].setValue(event.name);
  }

  /**
   * process to replace the order
   */
  proceedToReplace(): void {
    const params: {
      order_id: string;
      product_ids: any;
      delivery_date: string;
      slot_id: number;
    } = this.replaceorderService.getParams(
      this.order.incrementId,
      this.replacementItems,
      this.replaceForm.controls["slotDay"].value,
      this.replaceForm.controls["slotTime"].value,
    );
    this.dialogRef.close(params);
  }

  /**
   * process to get the productId and quantity to replace
   * @param event for trigger the function
   * @param item is product id
   * @param quantityInputControl is customer requiement to replace
   */
  onItemSelect(event: any, item: SaleItem): number | any[] {
    const pid: number = +item.productId;
    const qty: number = +event.target.value;
    if (qty < 0) {
      this.textAlert(`Qty Negative or Zero`, `Enter above zero quantity`);
      event.target.value = "";

      return;
    }

    if (qty > +item.quantity) {
      this.textAlert(
        `Qty exceeded`,
        `You connot replace more than the original quantity ${+item.quantity}`,
      );
      event.target.value = "";

      return;
    }

    const obj: {
      pid: number;
      qty: number;
    } = { pid, qty };
    const index: number = this.replacementItems.findIndex(
      (product) => product.pid === item.productId,
    );

    if (index == -1 && qty > 0) {
      return this.replacementItems.push(obj);
    }
    if (qty == 0) {
      return this.replacementItems.splice(index, 1);
    }

    this.replacementItems[index].qty = qty;
  }

  get columnsToDisplay(): string[] {
    return ["name"];
  }
}

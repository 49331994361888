<button
  class="date-picker__date-filter-btn"
  mat-button
  [matMenuTriggerFor]="menu"
  #menuTrigger="matMenuTrigger"
>
  <div class="date-picker__filter-marker"></div>
  Date Filter <mat-icon class="ml-2">date_range</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button
    *ngFor="let option of dateOptions"
    [ngClass]="{
      'item-active': dateFilterSelectedOption.value === option.value
    }"
    (click)="onDatePickerChange(option)"
    mat-menu-item
  >
    {{ option.label }}
  </button>
  <button
    [ngClass]="{
      'item-active': dateFilterSelectedOption.value === 'custom'
    }"
    mat-menu-item
    (click)="$event.stopPropagation(); picker.open()"
  >
    Custom Date
    <mat-form-field class="date-picker" style="visibility: hidden">
      <mat-date-range-input
        [max]="today"
        [formGroup]="range"
        [rangePicker]="picker"
      >
        <input
          matStartDate
          placeholder="Start date"
          formControlName="start"
          readonly
        />
        <input
          readonly
          matEndDate
          placeholder="End date"
          formControlName="end"
        />
      </mat-date-range-input>

      <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
      <mat-date-range-picker #picker>
        <mat-datepicker-actions>
          <button mat-button matDatepickerCancel>Cancel</button>
          <button
            (click)="onDatePickerChange(); menuTrigger.closeMenu()"
            mat-raised-button
            color="primary"
            matDatepickerApply
          >
            Apply
          </button>
        </mat-datepicker-actions>
      </mat-date-range-picker>
    </mat-form-field>
  </button>
</mat-menu>

import { HttpParams } from "@angular/common/http";
import { Component, Inject, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { DeliveryDate, DeliverySlot, SaleOrder } from "@tendercuts/models";
import {
  FetchDeliverySlotService,
  SlotChangeService,
} from "@tendercuts/providers";
import { BasePage } from "../../utils/pages/base/base.component";

/**
 * display the pop up for slot-change
 */
@Component({
  selector: "app-slot-change",
  templateUrl: "./slot-change.component.html",
  styleUrls: ["./slot-change.component.scss"],
})
export class SlotChangeComponent extends BasePage implements OnInit {
  order: SaleOrder;
  slotDates: DeliveryDate[];
  selectedDateSlots: DeliverySlot[];
  slotForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<SlotChangeComponent>,
    public dialog: MatDialog,
    public fetchDeliverySlotService: FetchDeliverySlotService,
    public slotChangeService: SlotChangeService,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: SaleOrder,
  ) {
    super(dialog, snackBar, store);
    if (data) {
      this.order = data;
    }
  }

  /**
   * when the component is opened fetch delivery slot service will be hit,
   * and fetch the avaliable delivery slots
   */
  ngOnInit(): void {
    this.slotForm = new FormGroup({
      slotDay: new FormControl("", [Validators.required]),
      slotTime: new FormControl("", [Validators.required]),
    });

    const params: HttpParams = this.fetchDeliverySlotService.getParams(this.order.quoteId);
    this.fetchDeliverySlotService.getData(params).subscribe(
      (data: DeliveryDate[]) => {
        if (data) {
          this.slotDates = data;
          this.selectedDateSlots = this.slotDates[0].slots;
        }
      },
      (err) => this.somethingWentWrong(),
    );
  }

  /**
   *
   * @param event is on selection change of value
   */
  selectedDay(event: MatSelectChange): void  {
    this.slotForm.controls["slotDay"].setValue(event.value);
  }

  changeSlot(): void {
    const params: string = this.slotChangeService.getParams(
      this.order.incrementId,
      this.slotForm.controls["slotDay"].value,
      this.slotForm.controls["slotTime"].value,
    );
    this.slotChangeService.getData().subscribe(
      (response) => {
        this.dialogRef.close(true);
        if (response.status) {
          this.textAlert(response.message, "");
        }
      },
      (err) => this.somethingWentWrong(),
    );
  }
}

import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { Trips, TripCashLine } from "@tendercuts/models";
import { BasePage } from "../../../utils";

@Component({
  selector: "app-trip-cash-splitup",
  templateUrl: "./trip-cash-splitup.component.html",
  styleUrls: ["./trip-cash-splitup.component.scss"],
})
export class TripCashSplitupComponent extends BasePage implements OnInit {
  trip: Trips;

  @Input() set tripData(data: any) {
    this.trip = data;
  }

  cashLines: TripCashLine[];
  cashLineDataSource: MatTableDataSource<TripCashLine>;

  columnsToDisplay: string[] = [
    "cashId",
    "cashType",
    "cashCollected",
    "createdAt",
  ];

  constructor(
    public dialog: MatDialog,
    public snacker: MatSnackBar,
    public store: Store<AppState>,
  ) {
    super(dialog, snacker, store);
  }

  ngOnInit(): void {
    if (this.trip.cash) {
      this.cashLines = this.trip.cash.cashLines;
      this.cashLineDataSource = new MatTableDataSource(this.cashLines);
    }
  }
}

import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { SaleItem, SaleOrder, User } from "@tendercuts/models";
import { map, skipWhile, take } from "rxjs/operators";

/**
 * component to show list comboItem related items
 */
@Component({
  selector: "app-combo-items-list",
  templateUrl: "./combo-items-list.component.html",
  styleUrls: ["./combo-items-list.component.scss"],
})
export class ComboItemsListComponent implements OnInit {
  @Input() selectedItem: SaleItem;
  @Input() order: SaleOrder;
  // hiding table headers in invoice
  @Input() invoiceMode: boolean = false;
  // assign selected comboItem related child items
  comboChildItems: SaleItem[] = [];

  isTechOps: boolean = false;

  constructor(public store: Store<AppState>) {}

  ngOnInit(): void {
    this.getTechOps();
    this.comboChildItems = this.order.items.filter(
      (item: SaleItem) => item.parentItemId == this.selectedItem.itemId,
    );
  }

  async getTechOps(): Promise<void> {
    this.isTechOps = await this.store
      .select((state) => state.userState.user)
      .pipe(
        skipWhile((user) => !user),
        take(1),
        map((user: User) => user.isTechOps),
      )
      .toPromise();
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { GenericServerResponse } from "@tendercuts/models";
import { BasePostProvider } from "../http/core";
import { LoginServices } from "../login/service";

@Injectable({
  providedIn: "root",
})
export class DeliveryInstructionsService extends BasePostProvider<GenericServerResponse> {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): any {
    return this.loginService;
  }

  get endpoint(): string {
    return this.endpointService.addDeliveryInstructions();
  }

  get model(): typeof GenericServerResponse {
    return GenericServerResponse;
  }

  getParams(orderId: string, deliveryInstruction: string): string {
    const params: string = JSON.stringify({
      order_id: orderId,
      comment: deliveryInstruction,
    });

    return params;
  }
}

import { Component, Inject, Input } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { GenericServerResponse } from "@tendercuts/models";
import { SaleOrder } from "@tendercuts/models";
import { SaveAlternateNumberService } from "@tendercuts/providers";
import { BasePage } from "../../utils";

/**
 * To add Alternate phone number
 */
@Component({

  // TODO
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "add-alternate-number",
  templateUrl: "./add-alternate-number.html",
  styleUrls: ["./add-alternate-number.scss"],
})
export class AlternateNumberComponent extends BasePage {
  alternateNumberControl: FormControl = new FormControl("", [
    Validators.required,
    Validators.minLength(10),
    Validators.maxLength(10),
    Validators.pattern(/^-?(0|[1-9]\d*)?$/),
  ]);
  @Input() order: SaleOrder;
  constructor(
    public alternateNumberService: SaveAlternateNumberService,
    public dialogRef: MatDialogRef<AlternateNumberComponent>,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialog, snackBar, store);
    if (data.order) {
      this.order = data.order;
    }
  }

  /**
   * To send order id with phone number to service
   */
  validateNumber(): void {
    if (
      isNaN(this.alternateNumberControl.value) ||
      this.alternateNumberControl.value.length != 10
    ) {
      this.showNotification(
        "top",
        "center",
        "danger",
        "info-circle",
        "Enter valid number",
      );

      return;
    }
    if (
      this.order.shippingAddress.telephone ===
        this.alternateNumberControl.value ||
      this.order.shippingBillingAddress.telephone ===
        this.alternateNumberControl.value
    ) {
      this.showNotification(
        "top",
        "center",
        "danger",
        "info-circle",
        "Already Exist! Please Enter New Number",
      );

      return;
    }
    const params: {
      order_id: string;
      alternate_number: number;
    } = this.alternateNumberService.getParams(
      this.order.incrementId,
      this.alternateNumberControl.value,
    );
    this.presentLoader();
    this.alternateNumberService.getData(params).subscribe(
      (data: GenericServerResponse[]) => {
        if (data) {
          this.showNotification(
            "top",
            "center",
            "success",
            "info-circle",
            data[0].message,
          );
          this.dismissLoader();

          return;
        }
      },
      (err) => this.somethingWentWrong(),
    );
    this.dialogRef.close(true);
  }
}

<app-store-credit-filter
  (storeCreditFilter)="filterStoreCredit($event)"
></app-store-credit-filter>
<div
  class="no-data-container"
  *ngIf="!transactionRecords || transactionRecords.length == 0; else walletGrid"
>
  No Data found
</div>
<ng-template #walletGrid>
  <app-customer-profile-grid
    [dataSource]="walletTransactionDataSource"
    [columnsToDisplay]="columnsToDisplay"
    [titleToDisplay]="titleToDisplay"
    [actions]="actions"
  ></app-customer-profile-grid>
</ng-template>

import { Component, Inject, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { Trips } from "@tendercuts/models";
import { BasePage } from "../../utils";

@Component({
  selector: "app-order-sequence-dialog",
  templateUrl: "./order-sequence-dialog.component.html",
  styleUrls: ["./order-sequence-dialog.component.scss"],
})
export class OrderSequenceDialogComponent extends BasePage {
  currentTrip: Trips;
  isDialogCloseable: boolean = false;

  @ViewChild("stepper") matStepper: any;

  constructor(
    public dialogRef: MatDialogRef<OrderSequenceDialogComponent>,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialog, snackBar, store);
    this.dialogRef.disableClose = true;
    this.currentTrip = data.trip;
    this.isDialogCloseable = data.isCloseable;
  }

  closeDialog(status: boolean = false): void {
    this.dialogRef.close(status);
  }
}

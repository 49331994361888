import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BasePostProvider } from "@tendercuts/http";
import { GenericServerResponse } from "@tendercuts/models";

@Injectable()
export class OrderCloseService extends BasePostProvider<GenericServerResponse> {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof GenericServerResponse {
    return GenericServerResponse;
  }

  get endpoint(): string {
    return this.endpointService.closeOrder();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(incrementId: string): {
    order_id: string;
} {
    const params: {
      order_id: string;
  } = {
      order_id: incrementId,
    };

    return params;
  }
}

<mat-sidenav-container>
  <mat-sidenav id="drawer" mode="over" position="end" #drawer>
    <i class="menu-item-icon fa fa-window-close-o" (click)="closeDrawer()"></i>
    <div class="trip-detail" *ngIf="selectedTrip">
      <app-trip-detail [trip]="selectedTrip" (refreshParentEmiter)="closeDrawer($event)"></app-trip-detail>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="top-nav-bar-wrap">
      <mat-form-field class="col-10">
        <input
          matInput
          type="number"
          maxlength="10"
          placeholder="Enter Driver Number/Trip Id"
          aria-label="number"
          [(ngModel)]="searchText"
          (keyup.enter)="searchDriverOrder()"
        />
      </mat-form-field>
      <button
        class="col-2"
        id="search-driver"
        mat-stroked-button
        (click)="searchDriverOrder()"
      >
        Search
      </button>
    </div>

    <div *ngIf="driverTrips">
      <app-drivers-trips-grid
        [tripOrdersDataSource]="driverTrips"
        class="trip-grid"
        (showTrip)="showTrip($event)"
      ></app-drivers-trips-grid>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<div class="all-orders-dashboard">
  <div style="position: sticky; top: 5.07rem; background-color: #ecedee">
    <div class="all-orders-dashboard__header">
      <div class="all-orders-dashboard__header__title">
        Order History -
        <span class="capitalize">{{ dateFilterSelectedOption?.label }}</span>
      </div>

      <div class="all-orders-dashboard__header__filter-container mb-4">
        <!-- Status Filter starts-->
        <app-status-filter
          (triggerChange)="onStatusFilterChange($event)"
        ></app-status-filter>
        <!-- Status Filter ends-->

        <!-- Custom Date Filter Starts -->
        <app-custom-date-picker
          (triggerChange)="onDateFilterChange($event)"
        ></app-custom-date-picker>
        <!-- Custom Date Filter ends -->
      </div>
    </div>

    <div class="totalOrders">
      Total Orders: <span>{{ totalOrders }}</span>
    </div>
  </div>

  <app-order-table
    *ngIf="orders?.length"
    [orders]="orders"
    [columnsToDisplay]="columnsToDisplay"
    [titleToDisplay]="titleToDisplay"
  >
  </app-order-table>
  <div #elementRef class="loader">
    <div class="spinner" *ngIf="isLoading"></div>
  </div>
</div>

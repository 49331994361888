import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { WeeklyTripSheet } from "@tendercuts/models";
import { BasePage } from "src/app/utils";

/**
 * component to update weekly trip sheet(Approve and Reject)
 */
@Component({
  selector: "app-approve-weekly-tripsheet",
  templateUrl: "./approve-weekly-tripsheet.component.html",
  styleUrls: ["./approve-weekly-tripsheet.component.scss"],
})
export class ApproveWeeklyTripsheetComponent
  extends BasePage
  implements OnInit {
  weeklyTripSheet: WeeklyTripSheet;

  // mode diff approve and reject
  isRejectMode: boolean = false;

  // form group controller for all fields
  weeklyTripSheetApprove: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ApproveWeeklyTripsheetComponent>,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
  ) {
    super(dialog, snackBar, store);
    this.weeklyTripSheet = data.tripSheet;
    this.isRejectMode = data.isRejectMode;
  }

  ngOnInit(): void {
    this.weeklyTripSheetApprove = new FormGroup({
      inputController: new FormControl("", [Validators.required]),
      commentControl: new FormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(300),
      ]),
    });
  }

  /**
   * pass the params to trip earning update
   */
  approveTripSheet(): void {
    this.dialogRef.close({
      status: true,
      incentive: this.weeklyTripSheetApprove.value["inputController"],
      comment: this.weeklyTripSheetApprove.value["commentControl"],
    });
  }

  closeDialog(): void {
    this.dialogRef.close({ status: false });
  }
}

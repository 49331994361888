import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Store } from "@ngrx/store";
import { DriverState } from "@store/driver";
import { AppState } from "@store/state";
import { CashEntryTransaction, TripCashEntries } from "@tendercuts/models";
import { AngularCsv } from "angular-csv-ext/dist/Angular-csv";
import { skipWhile, take } from "rxjs/operators";

@Component({
  selector: "app-todays-cash-trips",
  templateUrl: "./todays-cash-trips.component.html",
  styleUrls: ["./todays-cash-trips.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class TodaysCashTripsComponent implements OnInit {
  selectedTrip: TripCashEntries;
  tripCash: TripCashEntries[];
  @ViewChild(MatSort) sort: MatSort;

  totalCashCollected: number = 0;
  todaysTrip: MatTableDataSource<TripCashEntries>;

  columnsToDisplay: string[] = [
    "driverName",
    "driver-number",
    "total-amount",
    "pending-amount",
    "actual-amount",
  ];

  tripData: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<TodaysCashTripsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.fetchData();
  }

  /**
   * Toggle rider's trip details
   */
  selectTrip(trip: TripCashEntries): void {
    this.selectedTrip = this.selectedTrip === trip ? null : trip;
  }

  /**
   * Groupby drivers with Trip cash entries and
   * calculate Total cash collected
   * @param { TripCashEntries[] }
   */
  getDriversCashEntries(tripCashEntries: TripCashEntries[]): void {
    const driversArray: any = tripCashEntries.
      reduce((driverDetails: any, tripCashEntry: TripCashEntries) => {
        const driverName: string = tripCashEntry.driverName;
        const driverNumber: number = tripCashEntry.driverNumber;
        const submittedAmount: number = tripCashEntry.submittedAmount;
        const remainingAmount: number = tripCashEntry.remainingAmount;
        const totalAmount: number = tripCashEntry.actualAmount;
        const cashEntries: CashEntryTransaction[] = tripCashEntry.
          cashEntries.map((cashEntry: CashEntryTransaction) => {
            cashEntry["tripId"] = tripCashEntry.tripId;
            cashEntry["totalAmount"] = tripCashEntry.actualAmount;
            cashEntry["time"] = new Date(cashEntry.createdAt).toLocaleString();

            return cashEntry;
          });

        if (!(driverNumber in driverDetails)) {
          driverDetails[driverNumber] = {
            driverName,
            driverNumber,
            cashEntries: [],
            remainingAmount: 0,
            submittedAmount: 0,
            totalAmount: 0,
          };
        }
        // Calculating total cash collected
        this.totalCashCollected += submittedAmount;
        const updatedCashEntries: CashEntryTransaction[] =
          [...driverDetails[driverNumber].cashEntries, ...cashEntries];
        const updatedSubmittedAmount: number =
          driverDetails[driverNumber].submittedAmount + submittedAmount;
        const updatedRemainingAmount: number =
          driverDetails[driverNumber].remainingAmount + remainingAmount;
        const updatedTotalAmount: number =
          driverDetails[driverNumber].totalAmount + totalAmount;

        driverDetails[driverNumber] = {
          ...driverDetails[driverNumber],
          cashEntries: updatedCashEntries,
          remainingAmount: updatedRemainingAmount,
          submittedAmount: updatedSubmittedAmount,
          totalAmount: updatedTotalAmount,
        };

        return driverDetails;
      }, {});

    this.tripData = Object.values(driversArray);
  }

  /**
   * Fetch the tripcash data from redux
   */
  async fetchData(): Promise<void> {
    const driverState: DriverState = await this.store
      // eslint-disable-next-line @typescript-eslint/no-shadow
      .select((state) => state.driverState)
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        skipWhile((state) => state.loading), take(1))
      .toPromise();
    this.tripCash = driverState.tripCashEntries;
    this.getDriversCashEntries(this.tripCash);
    this.todaysTrip = new MatTableDataSource(this.tripData);
    this.todaysTrip.sort = this.sort;
  }

  /** Button onclick => file downloaded in csv format,
   * created tableData,
   * @param data => tripCash data's pushed into tableData,
   * By AngularCsv tableData is displayed in csv format.
   */

  downloadCsv(): void {
    const tableData: {
      column1: string;
      column2: string;
      column3: string;
      column4: string;
      column5: string;
    }[] = [
        {
          column1: "Driver-Name",
          column2: "Driver-Number",
          column3: "Trip-Id",
          column4: "Actual-Amount",
          column5: "Created-At",
        },
      ];
    // Data's are pushed into the table
    this.tripData.forEach((data) => {
      data.cashEntries.forEach((cashEntry) => {
        tableData.push({
          column1: data.driverName,
          column2: data.driverNumber.toString(),
          column3: cashEntry.tripId.toString(),
          column4: cashEntry.amount.toString(),
          column5: cashEntry.time.toLocaleString(),
        });
      });
    });
    // Total cash collected shown in the table
    tableData.push({
      column1: "",
      column2: "",
      column3: "Total Cash Collected",
      column4: this.totalCashCollected.toString(),
      column5: "",
    });
    // Date and Time of downloading file
    const fileName: string = "Trip_cash_" + new Date().toLocaleString();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    new AngularCsv(tableData, fileName);
  }
}

import { Action } from "@ngrx/store";
import {
  Driver,
  GenericError,
  SaleOrder,
  Trips,
  TripCashEntries,
  TripConfig,
} from "@tendercuts/models";

/**
 * Action to trigger server request to load all drivers
 */
export class ActionGetDrivers implements Action {
  readonly type: string = "ACTION_GET_DRIVERS";

  constructor(public storeId: string) {}
}

/**
 * Action to trigger server request to load all drivers
 * including in-active ones.
 */
export class ActionGetEveryDrivers implements Action {
  readonly type: string = "ACTION_GET_EVERY_DRIVERS";

  constructor(public storeId: string) {}
}

/**
 * Action to trigger server request to load all trips
 */
export class ActionGetTrips implements Action {
  readonly type: string = "ACTION_GET_TRIPS";

  constructor(public storeId: string) {}
}

/**
 * Action to indicate that all drivers are loaded.
 */
export class ActionAllDriversLoaded implements Action {
  readonly type: string = "ACTION_ALL_DRIVERS_LOADED";

  constructor(public payload: Driver[]) {}
}

/**
 * Action to indicate that all drivers including in-active ones
 * are loaded.
 */
export class ActionEveryDriversLoaded implements Action {
  readonly type: string = "ACTION_EVERY_DRIVERS_LOADED";

  constructor(public payload: Driver[]) {}
}

/**
 * Action to indicate that all trips are loaded.
 */
export class ActionAllTripsLoaded implements Action {
  readonly type: string = "ACTION_ALL_TRIPS_LOADED";

  constructor(public payload: Trips[]) {}
}

/**
 * Error action
 */
export class ActionLoadFailed implements Action {
  readonly type: string = "ACTION_LOAD_FAILED";

  constructor(public payload: GenericError) {}
}

/**
 * Error action
 */
export class ActionDriverStoreClear implements Action {
  readonly type: string = "ACTION_DRIVER_STORE_CLEAR";

  constructor() {}
}

/**
 * stitches order & driver objects to trpip objects.
 */
export class ActionStitchTripOrders implements Action {
  readonly type: string = "ACTION_STITCH_TRIP_ORDERS";

  constructor(public orders: SaleOrder[]) {}
}

/**
 * Action to trigger server request to load all trips
 */
export class ActionFetchSavedTrips implements Action {
  readonly type: string = "ACTION_FETCH_SAVED_TRIPS";

  constructor(public storeId: number) {}
}

export class ActionStoreSavedTrips implements Action {
  readonly type: string = "ACTION_STORE_SAVED_TRIPS";

  constructor(public payload: Trips[]) {}
}

/**
 * Sets the id of trip to be edited
 */
export class ActionEnableTripEditMode implements Action {
  readonly type: string = "ACTION_ENABLE_TRIP_EDIT_MODE";

  constructor(public tripId: number) {}
}

/**
 * Disables the trip editing
 */

export class ActionDisableTripEditMode implements Action {
  readonly type: string = "ACTION_Disable_TRIP_EDIT_MODE";

  constructor() {}
}

/**
 * Action to trigger server request to load all trips cash entries
 */
export class ActionLoadTripCash implements Action {
  readonly type: string = "ACTION_LOAD_TRIP_CASH";

  constructor(public payload: string) {}
}

/**
 * Dispatch action for fetch trip configuratation
 */
export class ActionFetchTripConfig implements Action {
  readonly type: string = "ACTION_FETCH_TRIP_CONFIG";

  constructor(public storeId: string) {}
}

/**
 * Action for save trip configuration
 */
export class ActionSaveTripConfig implements Action {
  readonly type: string = "ACTION_SAVE_TRIP_CONFIG";

  constructor(public tripConfig: TripConfig) {}
}

/**
 * Action for trip configuration error
 */
export class ActionTripConfigError implements Action {
  readonly type: string = "ACTION_TRIP_CONFIG_ERROR";

  constructor(public payload: GenericError) {}
}

/**
 * Action to save all trips cash entries
 * [called by ActionLoadTripCash]
 */
export class ActionSaveTripCash implements Action {
  readonly type: string = "ACTION_SAVE_TRIP_CASH";

  constructor(public payload: TripCashEntries[]) {}
}

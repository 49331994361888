<agm-marker
  [latitude]="ping.lat"
  [longitude]="ping.lng"
  [label]="label"
  iconUrl="assets/marker/route.png"
  (mouseOver)="displayWindow = true"
  (mouseOut)="displayWindow = false"
>
  <agm-snazzy-info-window
    [isOpen]="displayWindow"
    [padding]="3"
    maxWidth="2000px"
    maxHeight="1000px"
  >
    <ng-template>
      <h6>Location Details</h6>
      <div class="location row no-gutters col-12">
        <div class="first col-6">
          <div class="row no-gutters">
            <span class="field col-4">Lat</span>
            <span class="value col-8">{{ ping.lat }}</span>
          </div>
          <div class="row no-gutters">
            <span class="field col-4">Lng</span>
            <span class="value col-8">{{ ping.lng }}</span>
          </div>
          <div class="row no-gutters">
            <span class="field col-6">Battery</span>
            <span class="value col-6">{{ ping.battery }}</span>
          </div>
        </div>
        <div class="second col-6">
          <div class="row no-gutters">
            <span class="field col-8">OdoMtr</span>
            <span class="value col-4">{{ ping.odometerValue }}</span>
          </div>
          <div class="row no-gutters">
            <span class="field col-8">Speed</span>
            <span class="value col-4">{{ ping.speed }}</span>
          </div>
          <div class="row no-gutters">
            <span class="field col-8">Trip-Id</span>
            <span class="value col-4">{{ ping.tripId }}</span>
          </div>
        </div>
      </div>
    </ng-template>
  </agm-snazzy-info-window>
</agm-marker>

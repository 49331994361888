import { Component, Input, OnInit } from "@angular/core";
import { SaleOrder } from "@tendercuts/models";
import { OrderDisplayDialogComponent } from "../order-display-dialog/order-display-dialog.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-order-table",
  templateUrl: "./order-table.component.html",
  styleUrls: ["./order-table.component.scss"],
})
export class OrderTableComponent implements OnInit {
  @Input() columnsToDisplay: string[] = [];

  @Input() titleToDisplay: string[] = [];

  @Input() orders: SaleOrder[] = [];

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  getStatusClass(event: any, order: SaleOrder): string {
    const status: string = order.status;
    switch (status) {
      case "pending":
        return "bg-red";
      case "out_delivery":
      case "complete":
        return "bg-green";
      case "processing":
        return "bg-yellow";
      case "hold":
        return "bg-orange";
      case "canceled":
      case "closed":
        return "bg-red2";
      default:
        return "";
    }
  }

  /**
   * Opens the order display dialog for the selected order.
   * Refetches the orders when the dialog gets closed after holding an order.
   * @param order
   */
  onOrderSelected(event: any, order: SaleOrder): void {
    const dialogRef: MatDialogRef<OrderDisplayDialogComponent, any> =
      this.dialog.open(OrderDisplayDialogComponent, {
        data: { order, isOrderHistoryMode: true },
        panelClass: "remove-padding",
      });
  }

  onRowClick(index: number): void {
    this.onOrderSelected(null, this.orders[index]);
  }
}

import {Component, Input} from '@angular/core';
import {BaseGridComponent} from "../base-grid/base-grid.component";

@Component({
  selector: 'app-product-display-tab',
  templateUrl: '../base-grid/base-grid.component.html',
  styleUrls: ['../base-grid/base-grid.component.scss'],
})
export class ProductDisplayTabComponent extends BaseGridComponent {
  @Input() columnsToDisplay: string[] = ['name', 'inventory.today'];
  @Input() titleToDisplay: string[] = ['Name', 'Current Inventory'];
}

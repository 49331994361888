import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { DriverProfile, DriverSnapshot, SaleOrder } from "@tendercuts/models";
import { FetchDriverSnapshotService } from "@tendercuts/providers";
import { BasePage } from "src/app/utils";

@Component({
  selector: "app-driver",
  templateUrl: "./driver.component.html",
  styleUrls: ["./driver.component.scss"],
})

/** Component to load drivers previous orders and trip details */
export class DriverComponent extends BasePage implements OnInit {
  @Input() order: SaleOrder;
  @Input() driver: DriverProfile;

  snapDetails: DriverSnapshot;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    private fetchDriverSnapshot: FetchDriverSnapshotService
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    this.loadDriverSnapshot();
  }

  /**
   * @param driverId of rider is passed, to load this performance details
   */
  loadDriverSnapshot(): void {
    const params: {
      driver: number;
    } = this.fetchDriverSnapshot.getParams(this.driver.driverId);
    this.fetchDriverSnapshot.getData(params).subscribe(
      (data: DriverSnapshot[]) => {
        this.snapDetails = data[0];
      },
      (error) => {
        this.somethingWentWrong();
      }
    );
  }
}

import { Injectable, NgZone } from "@angular/core";

import { Store } from "@tendercuts/models";
import { Observable } from "rxjs";
import { StoreService } from "../store";

@Injectable()
export class GoogleMapsService {
  AutocompleteService: google.maps.places.AutocompleteService;
  Geocoder: google.maps.Geocoder;
  PlacesService: google.maps.places.PlacesService;
  // Source:
  //    - https://developers.google.com/maps/documentation/javascript/directions
  //    - https://developers.google.com/maps/documentation/javascript/examples/directions-simple
  DirectionsService: google.maps.DirectionsService;
  //  Source
  //    - https://developers.google.com/maps/documentation/javascript/examples/distance-matrix
  DistanceMatrixService: google.maps.DistanceMatrixService;

  // There are some issues with async observers
  //  (https://gist.github.com/endash/1f961830d0c5b744598a)
  //    - That's why we need to use ngZones
  // Here's another post explaining the issue (http://stackoverflow.com/a/38100262/1116959)
  //    - Seems that google.maps API is not patched by Angular's zone
  constructor(public zone: NgZone, public storeService: StoreService) {
    this.AutocompleteService = new google.maps.places.AutocompleteService();
    this.Geocoder = new google.maps.Geocoder();
    // As we are already using a map, we don't need to pass the map element
    //  to the PlacesServices
    //  (https://groups.google.com/forum/#!topic/google-maps-js-api-v3/QJ67k-ATuFg)
    this.PlacesService = new google.maps.places.PlacesService(
      document.createElement("div"),
    );
    this.DirectionsService = new google.maps.DirectionsService();
    this.DistanceMatrixService = new google.maps.DistanceMatrixService();
  }

  // Caveat:  As we are using Observable.create don't forget a well-formed finite Observable
  // must attempt to call either the observer’s onCompleted method exactly once or its onError
  // method exactly once, and must not thereafter
  // attempt to call any of the observer’s other methods.
  //    - http://reactivex.io/documentation/operators/create.html
  //    - http://stackoverflow.com/a/38376519/1116959

  // https://developers.google.com/maps/documentation/javascript/reference#AutocompletePrediction
  getPlacePredictions(
    query: string,
    append: boolean = true,
    types: string[] = ["geohash"],
  ): Observable<Array<google.maps.places.AutocompletePrediction>> {
    // TODO
    
    return Observable.create((observer) => {
      this.AutocompleteService.getPlacePredictions(
        { input: query, types, componentRestrictions: { country: "IN" } },
        (placesPredictions, status) => {
          if (status != google.maps.places.PlacesServiceStatus.OK) {
            this.zone.run(() => {
              observer.next([]);
              observer.complete();
            });
          } else {
            this.zone.run(() => {
              observer.next(placesPredictions);
              observer.complete();
            });
          }
        },
      );
    });
  }

  /**
   * Converts latLng or string into address
   * @param location
   * @param isAddressMode
   *
   * 1. When isAddressMode is true,
   *      we set the location to request parameters` address
   * 2. When isAddressMode is false, and location is of type string,
   *     we set the location to request parameters` placeId
   * 3. When isAddressMode is false, and location is not of type string,
   *     we set the location to request parameters` location
   */

  geocodePlace(
    location: google.maps.LatLngLiteral | string,
    isAddressMode: boolean = false,
  ): Observable<google.maps.GeocoderResult> {
    // TODO
    
    return Observable.create((observer) => {
      const options: any = {};

      if (isAddressMode) {
        options["address"] = location;
      } else if (typeof location === "string") {
        options["placeId"] = location;
      } else {
        options["location"] = location;
      }

      this.Geocoder.geocode(options, (results, status) => {
        if (status.toString() === "OK") {
          if (results[0]) {
            this.zone.run(() => {
              observer.next(results[0]);
              observer.complete();
            });
          } else {
            this.zone.run(() => {
              observer.error(new Error("no results"));
            });
          }
        } else {
          this.zone.run(() => {
            observer.error(new Error("error"));
          });
        }
      });
    });
  }

  getStoreDistanceMatrix(origin: string): Observable<Store | null> {
    const destinations: any = this.storeService.cache.filter(
      (store) => store.location != null,
    );

    const distanceQuery: google.maps.DistanceMatrixRequest = {
      origins: [origin],

      destinations: destinations.map(
        (store, index) =>
          store.location.latitude + "," + store.location.longitude,
      ),

      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.METRIC,
    };

    // TODO
    
    return Observable.create((observer) => {
      this.DistanceMatrixService.getDistanceMatrix(
        distanceQuery,
        (distance, status) => {
          if (status.toString() === "OK") {
            // sort
            let rows: any = distance.rows[0].elements;

            rows = rows.filter((row) => row.status !== "ZERO_RESULTS");

            let selectedStore: any = null;
            if (rows.length != 0) {
              // zipping it!
              // destination[index] is the store object
              rows = rows.map((value, index) => [value, destinations[index]]);
              rows.sort((a, b) => a[0].distance.value - b[0].distance.value);

              // selectedStore = row, store
              let distanceData: any;
              [distanceData, selectedStore] = rows[0];

              if (distanceData.distance.value > 13 * 1000) {
                selectedStore = null;
              }
            }

            this.zone.run(() => {
              // Yield a single value and complete
              observer.next(selectedStore);
              observer.complete();
            });
          } else {
            this.zone.run(() => {
              observer.error(new Error("error due to " + status));
            });
          }
        },
      );
    });
  }

  /**
   * [geocodePlaceToPincode get the related pincode for latlng]
   * @param  any        location [latlng literal]
   * @return Observable         [Pincode/null]
   */
  geocodePlaceToPincode(location: any): Observable<string | undefined> {
    // TODO
    
    return Observable.create((observer) => {
      this.Geocoder.geocode({ location }, (results, status) => {
        if (status.toString() === "OK") {
          if (results) {
            let pincode: any;
            for (const result of results) {
              if (result.types[0] == "postal_code") {
                pincode = result.address_components[0].long_name;
              }
            }

            this.zone.run(() => {
              observer.next(pincode);
              observer.complete();
            });
          } else {
            this.zone.run(() => {
              observer.error(new Error("no results"));
            });
          }
        } else {
          this.zone.run(() => {
            observer.error(new Error("error"));
          });
        }
      });
    });
  }

  // https://developers.google.com/maps/documentation/javascript/reference#PlaceResult
  getPlacesNearby(
    location: google.maps.LatLng,
  ): Observable<Array<google.maps.places.PlaceResult>> {
    // TODO
    
    return Observable.create((observer) => {
      this.PlacesService.nearbySearch(
        {
          location,
          radius: 500,
          types: ["restaurant"],
        },
        (results, status) => {
          if (status != google.maps.places.PlacesServiceStatus.OK) {
            this.zone.run(() => {
              observer.next([]);
              observer.complete();
            });
          } else {
            this.zone.run(() => {
              observer.next(results);
              observer.complete();
            });
          }
        },
      );
    });
  }

  // https://developers.google.com/maps/documentation/javascript/reference#DirectionsResult
  getDirections(
    origin: google.maps.LatLng,
    destination: google.maps.LatLng,
  ): Observable<google.maps.DirectionsResult> {
    const loctionOrigin: google.maps.Place = {
        location: origin,
      };
    const locationDestination: google.maps.Place = {
        location: destination,
      };
    const routeQuery: google.maps.DirectionsRequest = {
        origin: loctionOrigin,
        destination: locationDestination,
        travelMode: google.maps.TravelMode.WALKING,
      };

    // TODO
    
    return Observable.create((observer) => {
      this.DirectionsService.route(routeQuery, (route, status) => {
        if (status.toString() === "OK") {
          this.zone.run(() => {
            // Yield a single value and complete
            observer.next(route);
            observer.complete();
          });
        } else {
          this.zone.run(() => {
            observer.error(new Error("error due to " + status));
          });
        }
      });
    });
  }
}

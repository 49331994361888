import { Directive, HostListener } from '@angular/core';

/**
 * Directive for block cut, copy and paste
 */
@Directive({
  selector: '[appBlockCopyPaste]'
})
export class BlockCopyPasteDirective {
  constructor() { }

  @HostListener('paste', ['$event']) onPaste(e: ClipboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) onCopy(e: ClipboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) onCut(e: ClipboardEvent) {
    e.preventDefault();
  }
}
import { createReducer, Action, Store } from "ngrx-actions";

import * as UserLoginActions from "../user/login/login-actions";
import * as UserActions from "../user/storage/storage-actions";

import { User } from "@tendercuts/models";
import * as UserSignUpActions from "../user/signup/signup-actions";
import { userInitialState, UserState } from "../user/state";

@Store({ ...userInitialState })
export class UserReducers {
  /**
   * Load the user from storage while setting the load flag
   * @param UserState state
   * @returns user: User; loading: boolean; error: boolean; errorMessage: string
   */
  @Action(UserActions.ActionLoadUser)
  actionLoadUser(state: UserState): {
    loading: boolean;
    user: User;
    error: boolean;
    errorMessage: string;
    loginViaFlock: boolean;
} {
    return {
      ...state,
      loading: true,
    };
  }

  /**
   * Set the user to redux store
   * @param UserState state
   * @param UserLoginActions.ActionLoginSuccess action
   * @returns user: User; loading: boolean; error: boolean; errorMessage: string
   */
  @Action(UserActions.ActionSetUser)
  actionSetUser(state: UserState, action: UserActions.ActionSetUser): {
    user: User;
    loading: boolean;
    error: boolean;
    errorMessage: string;
    loginViaFlock: boolean;
} {
    return {
      ...state,
      user: action.payload,
      loading: false,
    };
  }

  /**
   *
   * @param state UserState
   * Set the state as user initial state
   */
  @Action(UserLoginActions.ActionLogoutUser)
  actionLogoutuser(state: UserState): {
    user: User;
    loading: boolean;
    error: boolean;
    errorMessage: string;
    loginViaFlock: boolean;
} {
    state = userInitialState;

    return {
      ...state,
    };
  }

  /**
   * Clears the loading flag.
   * @param UserState state
   * @param ActionLoginFail action
   * @returns user: User; loading: boolean; error: boolean; errorMessage: string
   */
  @Action(UserLoginActions.ActionLoginFail)
  actionLoginFail(state: UserState, action: UserLoginActions.ActionLoginFail): {
    loading: boolean;
    error: boolean;
    errorMessage: string;
    user: User;
    loginViaFlock: boolean;
} {
    return {
      ...state,
      loading: false,
      error: true,
      errorMessage: "Invalid login details",
    };
  }

  @Action(UserLoginActions.ActionLoginSuccess)
  actionLoginSuccess(
    state: UserState,
    action: UserLoginActions.ActionLoginSuccess,
  ): any {
    return {
      ...state,
      loading: false,
      user: action.user,
    };
  }

  /**
   * Triggers the login and sets the loading flag.
   * @param UserState state
   * @param ActionLoginFail action
   * @returns user: User; loading: boolean; error: boolean; errorMessage: string
   */
  @Action(UserLoginActions.ActionTryLogin)
  actionLoginTry(state: UserState, action: UserLoginActions.ActionTryLogin): {
    loading: boolean;
    user: User;
    error: boolean;
    errorMessage: string;
    loginViaFlock: boolean;
} {
    return {
      ...state,
      loading: true,
    };
  }

  @Action(UserLoginActions.ActionTryFlockLogin)
  actionFlockLoginTry(
    state: UserState,
    action: UserLoginActions.ActionTryFlockLogin,
  ): {
    loading: boolean;
    user: User;
    error: boolean;
    errorMessage: string;
    loginViaFlock: boolean;
} {
    return {
      ...state,
      loading: true,
    };
  }

  @Action(UserSignUpActions.ActionTrySignup)
  actionTrySignUp(state: UserState): {
    loading: boolean;
    error: boolean;
    user: User;
    errorMessage: string;
    loginViaFlock: boolean;
} {
    return {
      ...state,
      loading: true,
      error: false,
    };
  }

  @Action(UserSignUpActions.ActionSignupSuccess)
  actionSignupSuccess(state: UserState): {
    loading: boolean;
    error: boolean;
    user: User;
    errorMessage: string;
    loginViaFlock: boolean;
} {
    return {
      ...state,
      loading: false,
      error: false,
    };
  }

  @Action(UserSignUpActions.ActionSignupFail)
  actionSignupFail(state: UserState): {
    loading: boolean;
    error: boolean;
    user: User;
    errorMessage: string;
    loginViaFlock: boolean;
} {
    return {
      ...state,
      loading: false,
      error: true,
    };
  }

  /**
   * Sets the loginViaFlock flag to true, otherwise it's false
   * @param UserState state
   * @param ActionSetFlockLogin action
   * @returns user: User; loading: boolean; error: boolean; errorMessage: string
   */
  @Action(UserLoginActions.ActionSetFlockLogin)
  actionSetFlockLogin(
    state: UserState,
    action: UserLoginActions.ActionSetFlockLogin,
  ): {
    loginViaFlock: boolean;
    user: User;
    loading: boolean;
    error: boolean;
    errorMessage: string;
} {
    return {
      ...state,
      loginViaFlock: true,
    };
  }

  @Action(UserLoginActions.ActionLoginClearError)
  actionClearError(
    state: UserState,
    action: UserLoginActions.ActionLoginClearError,
  ): {
    loading: boolean;
    error: boolean;
    errorMessage: any;
    user: User;
    loginViaFlock: boolean;
} {
    return {
      ...state,
      loading: false,
      error: false,
      errorMessage: null,
    };
  }
}

export const userReducer: any = createReducer(UserReducers);

<p>Please verify your requests before submitting</p>

<app-inventory-request-approval-grid
  [dataSource]="dataSource"
  [columnsToDisplay]="columnsToDisplay"
>
</app-inventory-request-approval-grid>

<div class="row">
  <div class="col-6">
    <button mat-raised-button color="primary" (click)="dialogRef.close(true)">
      Confirm
    </button>
  </div>

  <div class="col-6">
    <button mat-raised-button color="primary" (click)="dialogRef.close(false)">
      Cancel
    </button>
  </div>
</div>

import { Serializable } from "../base";

export class CouponCode implements Serializable<CouponCode> {
  public coupon_id: string;
  public rule: CouponCodeRule;
  public code: number;
  public type: any;
  private usage_limit: any;
  private usage_per_customer: any;
  private times_used: number;
  private expiration_date: string;
  private is_primary: any;
  private created_at: string;
  private is_popup: any;
  private user_ip: any;
  private popup_cookie_id: any;
  private user_id: any;
  private coupon_value: number;

  constructor() {}

  deserialize(input) {
    this.coupon_id = input.coupon_id;
    this.rule = input.rule;
    this.code = input.code;
    this.usage_limit = input.usage_limit;
    this.usage_per_customer = input.usage_per_customer;
    this.times_used = input.times_used;
    this.expiration_date = input.expiration_date;
    this.is_primary = input.is_primary;
    this.created_at = input.created_at;
    this.type = input.type;
    this.is_popup = input.is_popup;
    this.user_ip = input.user_ip;
    this.popup_cookie_id = input.popup_cookie_id;
    this.user_id = input.user_id;
    this.coupon_value = input.coupon_value;
    if(input.rule) {
      this.rule = new CouponCodeRule().deserialize(input.rule);
    }

    return this;
  }

  get couponValue(): number {
    return this.coupon_value;
  }

  get usageLimit() {
    return this.usage_limit;
  }

  get usagePerCustomer() {
    return this.usage_per_customer;
  }

  get noOfTimesUsed() {
    return this.times_used;
  }

  get expiryDate() {
    return this.expiration_date;
  }

  get isPrimary() {
    return this.is_primary;
  }

  get createdAt() {
    return this.created_at;
  }

  get isPopup() {
    return this.is_popup;
  }

  get userIp() {
    return this.user_ip;
  }

  get popupCookieId() {
    return this.popup_cookie_id;
  }

  get userId() {
    return this.user_id;
  }
}



export class CouponCodeRule implements Serializable<CouponCodeRule> {
  private actions_serialized: string;
  private conditions_serialized: string;
  private apply_to_shipping: number;
  private coupon_type: number;
  private discount_amount: string;
  private discount_qty: string;
  private discount_step: string;
  private from_date: Date;
  private is_active: number;
  private is_advanced: number;
  private is_rss: number;
  private max_discount_amount: number;
  private product_ids: string;
  private rule_id: number;
  private simple_action: string;
  private simple_free_shipping: number;
  private sort_order: number;
  private stop_rules_processing: number;
  private times_used: number;
  private to_date: Date;
  private use_auto_generation: number;
  private uses_per_coupon: number;
  private uses_per_customer: number;
  public description: string = "";
  public name: string;

  public descriptionList: string[] = [];

  constructor() {}

  deserialize(input) {
    this.actions_serialized = input.actions_serialized;
    this.apply_to_shipping = input.apply_to_shipping;
    this.conditions_serialized = input.conditions_serialized;
    this.coupon_type = input.coupon_type;
    this.description = input.description;
    this.discount_amount = input.discount_amount;
    this.discount_qty = input.discount_qty;
    this.discount_step = input.discount_step;
    this.from_date = input.from_date;
    this.is_active = input.is_active;
    this.is_advanced = input.is_advanced;
    this.is_rss = input.is_rss;
    this.max_discount_amount = input.max_discount_amount;
    this.name = input.name;
    this.product_ids = input.product_ids;
    this.rule_id = input.rule_id;
    this.simple_action = input.simple_action;
    this.simple_free_shipping = input.simple_free_shipping;
    this.sort_order = input.sort_order;
    this.stop_rules_processing = input.stop_rules_processing;
    this.times_used = input.times_used;
    this.to_date = input.to_date;
    this.use_auto_generation = input.use_auto_generation;
    this.uses_per_coupon = input.uses_per_coupon;
    this.uses_per_customer = input.uses_per_customer;

    // Split the description list(using "\n") into an array and save the data into a variable 
    if(this.description) {
      this.descriptionList = this.description.split("\n");
    }

    return this;
  }
}

<div class="header">
  <div class="row filters">
    <mat-card class="col-12">
      <mat-card-title>
        <mat-form-field class="date-picker">
          <mat-label>Choose a date</mat-label>
          <input
            matInput
            [max]="todayDate"
            [(ngModel)]="selectedDate"
            (dateInput)="onDateSelect($event)"
            [matDatepicker]="picker"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="search-bar">
          <input
            matInput
            id="searchbar"
            (keyup)="applyDriverSearchFilter($event.target.value)"
            placeholder="Search Driver-Id/ Name / Phone"
            #searchbar
          />
        </mat-form-field>
      </mat-card-title>

      <mat-card-content>
        <mat-chip-list [multiple]="true" [selectable]="true">
          <div class="chip-border">
            <mat-chip
              (click)="
                modelFilter.clearAll();
                driverDataSource.filter = '';
                searchbar.value = ''
              "
            >
              Clear
            </mat-chip>
          </div>

          <div
            class="chip-border"
            *ngFor="let filterGroup of modelFilter.filterGroups"
          >
            <mat-chip
              *ngFor="let filter of filterGroup.filters"
              (click)="getFilteredData(filter)"
              [selected]="filter.selected"
            >
              {{ filter.displayName }}
            </mat-chip>
          </div>
        </mat-chip-list>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="space"></div>

<div class="row no-gutters driver">
  <app-trip-sheet-grid
    *ngIf="drivers.length; else noDrivers"
    [dataSource]="driverDataSource"
    [columnsToDisplay]="columnsToDisplay"
    [titleToDisplay]="titleToDisplay"
    [actions]="actions"
    [rowClickCallback]="rowClickCallback"
  >
  </app-trip-sheet-grid>
  <ng-template #noDrivers>
    <div class="no-date">
      No Drivers found in this date. Please try some other date
    </div>
  </ng-template>
</div>

import { Serializable } from "./base";

export class Banner implements Serializable<Banner> {
  public url: string;
  public status: boolean;
  public type: string;
  private banner_image: string;
  private web_banner_image: string;
  private sort_order: number;
  private detail_image: string;
  public title: string;
  private alt_text: string = "";
  private web_link: string;
  private app_link: string;
  private external_redirection: boolean;

  constructor() {}

  get bannerImage() {
    return this.banner_image;
  }

  get isExternalRedirection(): boolean {
    return this.external_redirection;
  }

  get webBannerImage() {
    return this.web_banner_image;
  }

  get sortId() {
    return this.sort_order;
  }

  get detailedImage() {
    return this.detail_image;
  }

  get webLink(): string {
    return this.web_link;
  }

  get appLink(): string {
    return this.app_link;
  }

  get altText(): string {
    return this.alt_text;
  }

  deserialize(input) {
    this.title = input.title;
    this.url = input.url;
    this.banner_image = input.banner_image;
    this.status = input.status;
    this.type = input.type;
    this.web_banner_image = input.web_banner_image;
    this.sort_order = input.sort_order;
    this.detail_image = input.detail_image;
    this.web_link = input.web_link;
    this.app_link = input.app_link;
    this.external_redirection = input.external_redirection;
    this.alt_text = input.alt_text;

    return this;
  }
}

import { Serializable } from '../base';
export class DriverEarningSlab implements Serializable<DriverEarningSlab> {
  slab_id: number;
  store_id: number;
  slab_name: string;
  rank: string;
  target_mon: number;
  target_tue: number;
  target_wed: number;
  target_thu: number;
  target_fri: number;
  target_sat: number;
  target_sun: number;
  target_week: number;
  incentive_wd_ontime: number;
  incentive_we_ontime: number;
  incentive_att: number;
  constructor() {}
  deserialize(input) {
    this.slab_id = input.slab_id;
    this.store_id = input.store_id;
    this.slab_name = input.slab_name;
    this.rank = input.rank;
    this.target_mon = input.target_mon;
    this.target_tue = input.target_tue;
    this.target_wed = input.target_wed;
    this.target_thu = input.target_thu;
    this.target_fri = input.target_fri;
    this.target_sat = input.target_sat;
    this.target_sun = input.target_sun;
    this.target_week = input.target_week;
    this.incentive_wd_ontime = input.incentive_wd_ontime;
    this.incentive_we_ontime = input.incentive_we_ontime;
    this.incentive_att = input.incentive_att;
    return this;
  }

  get target() {
    return (
      this.incentive_att + this.incentive_wd_ontime + this.incentive_we_ontime
    );
  }
}

// useful constants
export const WEEKEND = ['sat', 'sun'];
export const WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri'];
export const ALLDAYS = [...WEEKDAYS, ...WEEKEND];

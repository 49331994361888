import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { SaleOrder } from "@tendercuts/models";
import { take } from "rxjs/operators";
import { PrintInvoiceDialogComponent } from "../../modules/print-module";

@Component({
  selector: "app-order-detail",
  templateUrl: "./order-detail.component.html",
  styleUrls: ["./order-detail.component.scss"],
})
export class OrderDetailComponent implements OnInit {
  order: SaleOrder;
  storeName: string;
  ratingTags: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<OrderDetailComponent>,
    public dialog: MatDialog,
    public store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) private data: SaleOrder,
  ) {
    this.order = data;
  }
  ngOnInit(): void {
    this.store
      .select((state) => state)
      .pipe(take(1))
      .subscribe((state) => {
        if (
          state.orderState.priorityOrdertags[this.order.incrementId] !=
          undefined
        ) {
          this.ratingTags =
            state.orderState.priorityOrdertags[this.order.incrementId];
        }
      });
  }

  printOrder(): void {
    const dialogData: {
      orders: SaleOrder[];
      printType: string;
      printKot: boolean;
    } = {
      orders: [this.order],
      printType: "customer",
      printKot: false,
    };
    this.dialog.open(PrintInvoiceDialogComponent, {
      width: "80mm",
      data: dialogData,
    });
  }
}

/* eslint-disable @typescript-eslint/naming-convention */
import { Serializable } from "../base";

export class StoreConfig implements Serializable<StoreConfig> {
  public scope: string;
  public path: string;
  public value: string;
  private scope_id: string;
  private config_id: string;

  get configId(): string {
    return this.config_id;
  }

  get scopeId(): string {
    return this.scope_id;
  }

  get isScanConfig(): boolean {
    return this.path === "general/store_information/store_is_scannable";
  }

  get isEdcMachineIntegrated(): boolean {
    return this.path === "general/store_information/is_edc_machine_integrated";
  }

  get isMetaTitle(): boolean {
    return this.path === "general/store_information/meta_title";
  }

  get isMetaDescription(): boolean {
    return this.path === "general/store_information/meta_description";
  }

  get isMetaKeyword(): boolean {
    return this.path === "general/store_information/meta_keyword";
  }

  deserialize(input: any): this {
    if (input == null || input == undefined) {
      return this;
    }

    this.config_id = input.config_id;
    this.scope = input.scope;
    this.scope_id = input.scope_id;
    this.path = input.path;
    this.value = input.value;

    return this;
  }
}

import {
  Address,
  BarcodeSpec,
  BookingRequest,
  Complaint,
  CustomerEliteSubscription,
  Driver,
  DriverLoginLogout,
  DriverStatusHistory,
  DriverViolation,
  FreshdeskTicket,
  InventoryLog,
  InventoryRequest,
  Product,
  RewardPointsTransaction,
  SaleItem,
  SaleOrder,
  Store,
  Trips,
  TripEarning,
  TripPayment,
  TripSheet,
  WalletTransaction,
  WeeklyTripSheet,
} from "@tendercuts/models";

import { SelectionModel } from "@angular/cdk/collections";
import { MatTableDataSource } from "@angular/material/table";
import { FilterModel } from "src/models";

/**
 * Client Data source
 */
export class FilterModelDataSource<T> extends MatTableDataSource<T> {
  public filterModel: FilterModel;

  initialSelection: T[] = [];
  allowMultiSelect: boolean = true;
  public selection: SelectionModel<T> = new SelectionModel<T>(
    this.allowMultiSelect,
    this.initialSelection
  );

  constructor(filterModel: FilterModel, initialData?: T[]) {
    super(initialData);
    this.filterModel = filterModel;
  }

  _filterData(data: T[]): T[] {
    this.filteredData = !this.filter ? data : this.filterModel.filter(data);

    return this.filteredData;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected: number = this.selection.selected.length;
    const numRows: number = this.data.length;

    return numSelected == numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.filteredData.forEach((row) => this.selection.select(row));
  }
}

export class SaleOrderDataSource extends FilterModelDataSource<SaleOrder> {
  constructor(filterModel: FilterModel, initialData?: SaleOrder[]) {
    super(filterModel, initialData);
  }
}

export class DriverStatusDataSource extends FilterModelDataSource<Driver> {
  constructor(filterModel: FilterModel, initialData?: Driver[]) {
    super(filterModel, initialData);
  }
}

/*
 *Filter the data from the saleitem data source
 */
export class SaleItemDataSource extends FilterModelDataSource<SaleItem> {
  constructor(filterModel: FilterModel, initialData?: SaleItem[]) {
    super(filterModel, initialData);
  }
}

export class ProductDataSource extends FilterModelDataSource<Product> {
  public key: string;
  constructor(
    filterModel: FilterModel,
    initialData?: Product[],
    key: string = null
  ) {
    super(filterModel, initialData);
    this.key = key;
  }
}

export class InventoryRequestDataSource extends FilterModelDataSource<InventoryRequest> {
  constructor(filterModel: FilterModel, initialData?: InventoryRequest[]) {
    super(filterModel, initialData);
  }
}

/*
 *DataSource for TripObject
 */
export class TripDataSource extends FilterModelDataSource<Trips> {
  constructor(filterModel: FilterModel, initialData?: Trips[]) {
    super(filterModel, initialData);
  }
}

export class DriverDataSource extends FilterModelDataSource<DriverLoginLogout> {
  constructor(filterModel: FilterModel, initialData?: DriverLoginLogout[]) {
    super(filterModel, initialData);
  }
}

export class TripEarningDataSource extends FilterModelDataSource<TripEarning> {
  constructor(filterModel: FilterModel, initialData?: TripEarning[]) {
    super(filterModel, initialData);
  }
}

export class TripSheetDataSource extends FilterModelDataSource<TripSheet> {
  constructor(filterModel: FilterModel, initialData?: TripSheet[]) {
    super(filterModel, initialData);
  }
}
export class BookingStatusDataSource extends FilterModelDataSource<BookingRequest> {
  constructor(filterModel: FilterModel, initialData?: BookingRequest[]) {
    super(filterModel, initialData);
  }
}
export class BookingDetailsDataSource extends FilterModelDataSource<BookingRequest> {
  constructor(filterModel: FilterModel, initialData?: BookingRequest[]) {
    super(filterModel, initialData);
  }
}
export class BarcodeSpecDatasource extends FilterModelDataSource<BarcodeSpec> {
  constructor(filterModel: FilterModel, initialData?: BarcodeSpec[]) {
    super(filterModel, initialData);
  }
}
export class DriverAttendanceDataSource extends FilterModelDataSource<DriverLoginLogout> {
  constructor(filterModel: FilterModel, initialData?: DriverLoginLogout[]) {
    super(filterModel, initialData);
  }
}

export class WeeklyTripSheetDataSource extends FilterModelDataSource<WeeklyTripSheet> {
  constructor(filterModel: FilterModel, initialData?: WeeklyTripSheet[]) {
    super(filterModel, initialData);
  }
}

export class InventoryDebugDataSource extends FilterModelDataSource<InventoryLog> {
  constructor(filterModel: FilterModel, initialData?: InventoryLog[]) {
    super(filterModel, initialData);
  }
}

export class TripPaymentDataSource extends FilterModelDataSource<TripPayment> {
  constructor(filterModel: FilterModel, initialData?: TripPayment[]) {
    super(filterModel, initialData);
  }
}

export class ComplaintDataSource extends FilterModelDataSource<Complaint> {
  constructor(filterModel: FilterModel, initialData?: Complaint[]) {
    super(filterModel, initialData);
  }
}

export class FreshDeskTicketDataSource extends FilterModelDataSource<FreshdeskTicket> {
  constructor(filterModel: FilterModel, initialData?: FreshdeskTicket[]) {
    super(filterModel, initialData);
  }
}

export class RewardsDataSource extends FilterModelDataSource<RewardPointsTransaction> {
  constructor(
    filterModel: FilterModel,
    initialData?: RewardPointsTransaction[]
  ) {
    super(filterModel, initialData);
  }
}

export class WalletTransactionDataSource extends FilterModelDataSource<WalletTransaction> {
  constructor(filterModel: FilterModel, initialData?: WalletTransaction[]) {
    super(filterModel, initialData);
  }
}

export class EliteHistoryDataSource extends FilterModelDataSource<CustomerEliteSubscription> {
  constructor(
    filterModel: FilterModel,
    initialData?: CustomerEliteSubscription[]
  ) {
    super(filterModel, initialData);
  }
}

export class CustomerAddressDataSource extends FilterModelDataSource<Address> {
  constructor(filterModel: FilterModel, initialData?: Address[]) {
    super(filterModel, initialData);
  }
}
export class DriverStatusHistoryDataSource extends FilterModelDataSource<DriverStatusHistory> {
  constructor(filterModel: FilterModel, initialData?: DriverStatusHistory[]) {
    super(filterModel, initialData);
  }
}
export class DriverViolationDataSource extends FilterModelDataSource<DriverViolation> {
  constructor(filterModel: FilterModel, initialData?: DriverViolation[]) {
    super(filterModel, initialData);
  }
}


import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenavContainer } from "@angular/material/sidenav";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { ActionLoadTripCash } from "@store/driver";
import { AppState } from "@store/state";
import { StoreState } from "@store/store";
import { Trips, TripCashEntries } from "@tendercuts/models";
import { Subscription } from "rxjs";
import { delay, skipWhile, take } from "rxjs/operators";
import { Filter, FilterGroup, FilterMatch, FilterModel } from "src/models";
import { FirebaseAnalyticsService } from "src/providers/firebase-analytics/firebase-analytics";
import { TodaysCashTripsComponent, TripDataSource } from "../../../components";
import { BasePage } from "../../../utils";

@Component({
  selector: "app-trip-dashboard",
  templateUrl: "./trip-dashboard.component.html",
  styleUrls: ["./trip-dashboard.component.scss"],
})
export class TripDashboardComponent
  extends BasePage
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('searchbar') searchbar: ElementRef;
  @ViewChild("drawer") drawer: MatSidenavContainer;
  trips: Trips[] = [];
  selectedTrip: Trips;

  totalCashCollected: number = 0;
  tripCash: TripCashEntries[] = [];
  selectedStatusTabIndex: number = 0;

  statusTabsFilterGroup: FilterGroup = new FilterGroup([
    new Filter("All", null, null),
    new Filter("Pickup", true, "isStorePickupTrip"),
    new Filter("Dummy", "Saved Trips", "state"),
    new Filter("Startable", "Scanned", "state"),
    new Filter("Started", "Started", "state"),
    new Filter("Pending Cash", "Pending Cash Collection", "state"),
  ]);

  // Filters Array for trip status in mat-chips
  tripStatusFilterGroup: FilterGroup = new FilterGroup([
    new Filter("Pending", "Pending", "state"),
    new Filter("Returning Back", "Returning Back", "state"),
    new Filter("Cash Shortage", "Cash Shortage", "state"),
  ]);

  // Filters for search field
  tripSearchFilterGroup: FilterGroup = new FilterGroup([
    new Filter(
      "Search",
      null,
      ["tripId", "tripDriverPhoneNumber", "tripOrderString"],
      FilterMatch.CONTAINS
    ),
  ]);

  // Filter for trip time intervals
  tripTimeFilterGroup: FilterGroup = new FilterGroup([
    new Filter("<15 Mins", 15, "tripTimeInMinutes", FilterMatch.LESS_THAN),
    new Filter("<30 Mins", 30, "tripTimeInMinutes", FilterMatch.LESS_THAN),
    new Filter("<60 Mins", 60, "tripTimeInMinutes", FilterMatch.LESS_THAN),
    new Filter(">60 Mins", 60, "tripTimeInMinutes", FilterMatch.GREATER_THAN),
  ]);

  combinedFilterGroups: FilterGroup[] = [
    this.tripStatusFilterGroup,
    this.tripSearchFilterGroup,
    this.tripTimeFilterGroup,
  ];

  modelFilter: FilterModel = new FilterModel(
    [...this.combinedFilterGroups, this.statusTabsFilterGroup]
  );

  tripOrdersDataSource: TripDataSource = new TripDataSource(
    this.modelFilter,
    []
  );
  driverStateSubscription$: Subscription;
  showData: boolean = false;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public title: Title,
    public route: ActivatedRoute,
    public firebaseAnalyticsService: FirebaseAnalyticsService
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    this.title.setTitle("Live Trips");
  }

  /**
   * @param tripCashEntries
   * total amount collected by the drivers is calculated.
   */
  processTripCash(tripCashEntries: TripCashEntries[]): void {
    this.tripCash = tripCashEntries;
    this.totalCashCollected = 0;
    this.tripCash.forEach((cash) => {
      cash.cashEntries.forEach((cashEntry) => {
        this.totalCashCollected += cashEntry.amount;
      });
    });
  }

  ngAfterViewInit(): void {
    this.driverStateSubscription$ = this.driverState
      .pipe(
        skipWhile((state) => state.stichDone === false),
        delay(0)
      )
      .subscribe((driverState) => {
        this.trips = driverState.trips;
        this.tripOrdersDataSource.selection.clear();
        this.tripOrdersDataSource.data = this.trips;
        this.processTripCash(driverState.tripCashEntries);
      });
  }

  ngOnDestroy(): void {
    if (this.driverStateSubscription$) {
      this.driverStateSubscription$.unsubscribe();
    }
  }

  /**
   * search for tripId,driver name and driver number
   * @param searchString
   */
  applyTripSearchFilter(searchString: string): void {
    if (searchString === "") {
      this.tripSearchFilterGroup.filters[0].selected = false;
      this.tripSearchFilterGroup.filters[0].value = null;
    } else {
      this.tripSearchFilterGroup.filters[0].selected = true;
      this.tripSearchFilterGroup.filters[0].value = searchString;
    }
    this.tripOrdersDataSource.filter = "true";
  }

  /**
   * @param tabIndex
   * Function to display the trips based on the
   * Tab is selected
   */
  loadSelectedStatusData(tabIndex: number): void {
    this.statusTabsFilterGroup.filters.forEach((filter: Filter, idx: number) => {
      filter.selected = idx === tabIndex;
    });
    this.tripOrdersDataSource.filter = "true";
  }

  /**
   * Applies model filters for mat chips
   */
  applyTripStatusFilter(filter: Filter): void {
    filter.selected = !filter.selected;
    this.tripOrdersDataSource.filter = "true";
  }

  /**
   * Onclicking Today's Trip Cash button,
   * dialog with tables containing cash collected by drivers is opened.
   */
  async openTodaysTripCash(): Promise<void> {
    const storeState: StoreState = await this.store
      .select((state) => state.storeState)
      .pipe(take(1))
      .toPromise();
    const storeId: string = storeState.store.toString();
    this.store.dispatch(new ActionLoadTripCash(storeId));
    this.dialog.open(TodaysCashTripsComponent, {
      width: "75vw",
    });
  }

  /**
   * @param trips data is passesd as event to selectedTrip.
   */
  showTrip(trips: Trips): void {
    this.selectedTrip = trips;
    this.firebaseAnalyticsService.openTrip(this.selectedTrip)
    this.drawer.open();
  }

  // To empty trip data on clossing dialog
  closeDrawer(status: boolean): void {
    if (status) {
      this.refreshOrdersAndTrips();
      this.dismissLoader();
    }

    this.selectedTrip = null;
    this.showData = false;
    this.drawer.close();
  }
  /**
   * Function to clear the filters
   */
  clearAllFilter(): void {
    this.combinedFilterGroups.forEach((filterGroup: FilterGroup) => {
      filterGroup.filters.forEach((filter: Filter) => filter.selected = false);
    });
    this.tripOrdersDataSource.filter = 'true';
    this.searchbar.nativeElement.value = '';
  }

  showActiveRiders(): void {
    this.showData = true;
    this.drawer.open();
  }
}

import { Injectable } from "@angular/core";
import { BaseGetProvider, DataTransformationProtocol } from "@tendercuts/http";
import { Location } from "@tendercuts/models";

import { HttpClient, HttpParams } from "@angular/common/http";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";

@Injectable()
export class LocateDriverService
  extends BaseGetProvider<Location>
  implements DataTransformationProtocol {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http);
    this.delegate = this;
  }

  // override this
  preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof Location {
    return Location;
  }

  get endpoint(): string {
    return this.endpointService.driverLocation();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(dId: number): HttpParams {
    let params: HttpParams = new HttpParams();
    params = params.append("driver_id", dId.toString());

    return params;
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { Store as AppStore } from "@ngrx/store";
import { AppState } from "@store/state";
import { BaseGetProvider } from "@tendercuts/http";
import { SaleItem } from "@tendercuts/models";

@Injectable({
  providedIn: "root",
})
export class FetchInventorySaleService extends BaseGetProvider<SaleItem> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public store: AppStore<AppState>,
    public loginService: LoginServices,
  ) {
    super(http);
    this.delegate = this;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof SaleItem {
    return SaleItem;
  }

  get endpoint(): string {
    return this.endpointService.inventorySale();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(storeId: number, sku: string, date: Date): {
    store_id: number;
    sku: string;
    date: string;
} {
    date.setTime(date.getTime() + 5.5 * 60 * 60 * 1000);

    const params: {
      store_id: number;
      sku: string;
      date: string;
  } = {
      store_id: storeId,
      sku,
      date: date.toISOString().split("T")[0],
    };

    return params;
  }
}

<div class="trip">
  <div class="trip__orders">
    <div class="trip__order__label" *ngIf="!data.driver && data.state === 'Saved Trips'">
      <mat-chip *ngIf="data.driverTag" class="trip__tag" (click)="editDummyTripName(data)">
        {{ data.driverTag }}
        <mat-icon class="trip__tag__edit">create</mat-icon>
      </mat-chip>
    </div>
    <app-order-id-sequence [trip]="data"></app-order-id-sequence>
  </div>
  <div
    class="trip__trip-btns"
    *ngIf="data.state === 'Saved Trips'"
  >
    <button
      mat-raised-button
      class="trip__trip-btns trip__trip-btns--red scan-orders-btn"
      color="primary"
      (click)="scanOrders(data)"
      *ngIf="data.unScannedOrdersLength"
    >
      <i class="search-bar-icon fa fa-search"></i>
      Scan Orders
    </button>
    <button
      mat-raised-button
      class="trip__trip-btns trip__trip-btns--white assign-rider-btn"
      color="primary"
      (click)="assignDriver(data)"
      *ngIf="!data.driverUserId && !data.unScannedOrdersLength && data.canAssignManual"
    >
      <i class="material-icons">motorcycle</i>
      Assign Rider
    </button>
  </div>
</div>
<div class="trip__progress" *ngIf="data">
  <mat-progress-bar mode="determinat" class="trip__progress__bar" [value]="data.progressPercentage"></mat-progress-bar>
</div>
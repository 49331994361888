import { Serializable } from "./base";
import { ChangeLogs } from './change-logs';

export class TripEarning implements Serializable<TripEarning> {
  private created_at: string;
  private created_date: string;
  private final_km: number;
  private fr_driver_earning: number;
  private km_earning: number;
  private order_count: number;
  private order_earning: number;
  private store_id: number;
  private total_earning: number;
  private update_date: Date;
  private original_km: number;
  public driver: number;
  public id: number;
  public status: number;
  public trip: number;
  public changes: ChangeLogs[] = [];
  public segments: string;

  get tripId() {
    return this.trip;
  }

  get createdAt(): string {
    return this.created_at;
  }

  get createdDate() {
    return this.created_date;
  }

  get finalKms() {
    return this.final_km;
  }

  /**
   * separate segment names by using ","
   */
  get segmentNames() {
    const areas: string[] = this.segments.split(",");
    return areas.length ? areas : [];
  }

  get driverEarning() {
    return this.fr_driver_earning;
  }

  get kmEarning() {
    return "₹" + this.km_earning;
  }

  get orderCount() {
    return this.order_count;
  }

  get orderEarning() {
    return "₹" + this.order_earning;
  }

  get totalEarning() {
    return "₹" + this.total_earning;
  }

  get storeId() {
    return this.store_id;
  }

  get updatedDate() {
    return this.update_date;
  }

  get originalKm() {
    return this.original_km ? this.original_km : 0;
  }

  get tripEarningStatus() {
    switch (this.status) {
      case 0:
        return "Pending";
      case 1:
        return "Confirmed";
      default:
        return "";
    }
  }

  deserialize(input) {
    this.created_at = input.created_at;
    this.created_date = input.created_date;
    this.driver = input.driver;
    this.final_km = input.final_km;
    this.fr_driver_earning = input.fr_driver_earning;
    this.id = input.id;
    this.km_earning = input.km_earning;
    this.order_count = input.order_count;
    this.order_earning = input.order_earning;
    this.status = input.status;
    this.store_id = input.store_id;
    this.total_earning = input.total_earning;
    this.segments = input.segments;
    this.trip = input.trip;
    this.update_date = input.updated_at;
    this.original_km = input.original_km;

    if (input.changes.length) {
      input.changes.forEach((log) => {
        const logs = new ChangeLogs().deserialize(log);
        this.changes.push(logs);
      });
    }

    return this;
  }
}

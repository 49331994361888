import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { InventoryRequest } from "@tendercuts/models";
import { InventoryRequestDataSource } from "../data-source";

@Component({
  selector: "app-inventory-req-review",
  templateUrl: "./inventory-req-review-dialog.component.html",
  styleUrls: ["./inventory-req-review-dialog.component.scss"],
})
// TODO
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class InventoryReqReviewDialog implements OnInit {
  dataSource: InventoryRequestDataSource = new InventoryRequestDataSource(
    null,
    [],
  );
  columnsToDisplay: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<InventoryReqReviewDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.dataSource.data = data.requests;
    this.columnsToDisplay = data.columns;
  }

  ngOnInit(): void {}
}

import { Serializable } from "./base";
import { DriverLanguage } from "./driver-language";

/**
 * Model for providing driver profile details..
 */

export class DriverProfile implements Serializable<DriverProfile> {
  private username: number;
  private first_name: string;
  private trip_earning_count: number;
  private trip_sheet_status: number;
  private last_name: string;
  private is_active: boolean;
  private profile_image: string;

  public email: string;
  public id: number;
  public ratings: number;
  public trips: number;
  public orders: number;
  public profile: DriverProfileDetails;

  get phoneNumber() {
    return this.username;
  }

  get firstName() {
    return this.first_name;
  }

  get lastName() {
    return this.last_name;
  }

  get driverId() {
    return this.profile ? this.profile.user : 0;
  }

  get tripEarningCount() {
    return this.trip_earning_count;
  }

  get isActive() {
    return this.is_active;
  }

  get isWeekendRider() {
    return this.profile.isWeekendRider;
  }

  get isInActive() {
    return this.profile.subStatus == "INACTIVE";
  }

  get isFreelancer() {
    return this.profile.isFreelancer;
  }

  get driverProfileId() {
    return this.profile.driverProfileId;
  }

  get profileImage() {
    return this.profile_image;
  }

  get tripSheetStatus() {
    switch (this.trip_sheet_status) {
      case -1:
        return "Not generated";

      case 0:
        return "Pending";

      case 1:
        return "To Approve";

      case 2:
        return "Confirm";

      default:
        return "Not generated";
    }
  }

  get isSilver(): boolean {
    return this.profile.driverGrade === "Silver";
  }

  get isPlatinum(): boolean {
    return this.profile.driverGrade === "Platinum";
  }

  get isGold(): boolean {
    return this.profile.driverGrade === "Gold";
  }

  deserialize(input) {
    if (!input) {
      return;
    }

    this.username = input.username;
    this.is_active = input.is_active;
    this.first_name = input.first_name;
    this.last_name = input.last_name;
    this.trip_sheet_status = input.trip_sheet_status;
    this.trip_earning_count = input.trip_earning_count;
    this.email = input.email;
    this.id = input.id;
    this.ratings = input.ratings;
    this.orders = input.orders;
    this.trips = input.trips;
    this.profile_image = input.profile_image;

    if (input.driver) {
      this.profile = new DriverProfileDetails().deserialize(input.driver);
    }

    return this;
  }
}

/**
 * Model for getting driver profile data,obtained through create driver from ..
 */
export class DriverProfileDetails implements Serializable<DriverProfileDetails> {
  private proof_type: string;
  private proof_id: number;
  private account_no: string;
  private profile_image: string;
  private proof_image: string;
  private ifsc_code: string;
  private is_weekend_user: boolean;
  private is_freelancer: boolean;
  private work_location: number;
  private sub_status: string;
  private language_known: DriverLanguage[] = [];
  public user: number;
  public id: number;
  public level: number;
  public pan: any;
  private earning_scheme: string;

  get workLocation() {
    return this.work_location;
  }

  get thumb() {
    return this.profile_image;
  }

  get subStatus() {
    return this.sub_status.toLocaleUpperCase();
  }

  get proofType() {
    return this.proof_type;
  }

  get proofId() {
    return this.proof_id;
  }

  get accountNumber() {
    return this.account_no;
  }

  get proofImage() {
    return this.proof_image;
  }

  get isWeekendRider() {
    return this.is_weekend_user;
  }

  get isFreelancer() {
    return this.is_freelancer;
  }

  get ifscCode() {
    return this.ifsc_code;
  }

  get driverProfileId() {
    return this.id;
  }

  get driverGrade() {
    switch (this.level) {
      case 0:
        return "Silver";

      case 1:
        return "Gold";

      case 2:
        return "Platinum";

      default:
        return "No grade";
    }
  }

  get languageId(): number[] {
    return this.language_known.map(
      (language: DriverLanguage) => language.id
    );
  }

  get languageName(): string[] {
    return this.language_known.map(
      (language: DriverLanguage) => language.language
    );
  }

  get earningScheme(): string {
    return this.earning_scheme;
  }

  get schemeDescription(): string {
    switch(this.earningScheme) {
      case "DAILY":
        return "Daily Earnings";
      case "WEEKLY":
        return "Weekly Earnings";
      case "HYBRID":
        return "Hybrid Earnings"
      default:
        return "Best of daily & weekly Earnings";
    }
  }

  constructor() {}

  deserialize(input) {
    this.user = input.user;
    this.id = input.id;
    this.is_weekend_user = input.is_weekend_user;
    this.is_freelancer = input.is_freelancer;
    this.profile_image = input.profile_image;
    this.proof_image = input.proof_image;
    this.proof_type = input.proof_type;
    this.proof_id = input.proof_id;
    this.account_no = input.account_no;
    this.ifsc_code = input.ifsc_code;
    this.sub_status = input.sub_status;
    this.level = input.level;
    this.work_location = input.work_location;
    this.pan = input.pan;
    this.earning_scheme = input.earning_scheme;
    if (input.language_known) {
      input.language_known.forEach((languageKnown) => {
        this.language_known.push(
          new DriverLanguage().deserialize(languageKnown)
        );
      });
    } else {
      this.language_known = [];
    }

    return this;
  }
}

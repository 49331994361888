import { Serializable } from './base';

// https://public-api.wordpress.com/wp/v2/sites/tendercutsblog.wordpress.com/posts?per_page=5&order=asc&_embed
export class WordpressPost implements Serializable<WordpressPost> {
  public link: string;
  public title: string;
  public excerpt: string;
  public featuredImage: string;

  constructor() { }

  deserialize(input) {

    this.title = input.title.rendered;
    this.link = input.link;
    this.excerpt = input.excerpt.rendered;
    this.featuredImage = input.jetpack_featured_media_url;

    return this;
  }
}

import {
  DeliverySlot,
  Store,
  StoreSegment,
  TcutsCity,
} from "@tendercuts/models";
import { Filter } from "src/models";
// TODO
// eslint-disable-next-line @typescript-eslint/naming-convention
export interface StoreState {
  store: number;
  loading: boolean;
  storageLoading: boolean;
  // Flag to check if all segments have been loaded.
  segmentLoaded: boolean;
  error: boolean;
  errorMessage: string;
  availableStores: Store[];
  allStores: Store[];
  segments: StoreSegment[];
  deliverySlotFilters: Filter[];
  slotMap: { [id: number]: DeliverySlot };
  cities: TcutsCity[];
}

export const storeInitialState: StoreState = {
  store: null,
  availableStores: [],
  loading: false,
  storageLoading: false,
  segmentLoaded: false,
  error: false,
  errorMessage: null,
  segments: [],
  deliverySlotFilters: [],
  allStores: [],
  slotMap: null,
  cities: [],
};

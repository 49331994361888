import { Component, Input } from "@angular/core";
import { Butcher, SaleOrder } from "@tendercuts/models";

@Component({
  selector: "app-butcher",
  templateUrl: "./butcher.component.html",
  styleUrls: ["./butcher.component.scss"],
})
export class ButcherComponent {
  @Input() butcher: Butcher;
  @Input() order: SaleOrder;
}

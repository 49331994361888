<br />
<table style="margin: 3px">
  <thead>
    <tr>
      <td align="center" colspan="12">
        <span> <b> Trip Details</b></span>
      </td>
    </tr>
    <tr>
      <td align="center" colspan="12">
        <span>Trip Id : {{ trip.tripId }}</span>
      </td>
    </tr>
    <tr *ngIf="trip.driverTag">
      <td align="center" colspan="12">
        <span>Trip tag : {{ trip.driverTag }}</span>
      </td>
    </tr>
  </thead>
  <tbody style="margin: 3px">
    <tr
      *ngIf="trip.subAreas?.length > 0"
      style="display: flex; max-width: 100px; height: auto"
    >
      <td *ngFor="let subArea of trip.subAreas">
        <span>{{ subArea }}</span>
      </td>
    </tr>
  </tbody>
</table>

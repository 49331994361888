import { Component, Input, OnInit } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import {
  CustomerEavAttribute,
  CustomerEliteSubscription,
  CustomerProfile,
  User,
} from "@tendercuts/models";
import { CustomerDndService } from "@tendercuts/providers";
import { CustomerDashboardComponent } from "../../pages/customer-dashboard";
import { BasePage } from "../../utils";

@Component({
  selector: "app-customer-profile-core",
  templateUrl: "./customer-profile-core.component.html",
  styleUrls: ["./customer-profile-core.component.scss"],
})
export class CustomerProfileCoreComponent extends BasePage {
  @Input() profile: CustomerProfile;
  @Input() customer: User;

  columnsToDisplay: string[] = ["productName", "orderCount", "orderValue"];

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public customerDndService: CustomerDndService,
  ) {
    super(dialog, snackBar, store);
  }


  popUpMessage(message: string): void {
    this.showNotification("bottom", "center", "", "info-circle", message);
  }

  /*
  * update the customer in CRM as Do Not Disturb
  */
  customerDnd(event: MatCheckboxChange): void {
    const value: number = event.checked ? 1 : 0;
    this.customerDndService
      .getData({ customer_id: this.customer.entity_id, value })
      .subscribe(
        (response: CustomerEavAttribute[]) => {
          const status: number = response[0].value;

          if (status == 1) {
            this.popUpMessage("Marked Customer As DND");

            return;
          }
          this.popUpMessage("Unmarked Customer From DND");
        },
        (err) => {
          this.somethingWentWrong();
        },
      );
  }
}

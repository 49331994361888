import { Component, Input } from "@angular/core";
import { BaseGridComponent } from "../base-grid/base-grid.component";

@Component({
  selector: "app-driver-attendance-display-tab",
  templateUrl: "../base-grid/base-grid.component.html",
  styleUrls: ["../base-grid/base-grid.component.scss"],
})
export class DriverAttendanceTabComponent extends BaseGridComponent {
  @Input() columnsToDisplay: string[] = ["date", "checkIn", "checkOut"];

  @Input() titleToDisplay: string[] = [
    "Date",
    "Check In Time",
    "Check Out Time",
  ];
}

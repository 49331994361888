import { Serializable } from "../base";

export class HyperTrackOrder implements Serializable<HyperTrackOrder> {
  private share_url: string;
  private device_id: string;
  private external_trip_id: string;
  private external_order_id: string;
  private remaining_duration: number;
  private remaining_distance: number;
  private arrive_at: string;
  private created_at: string;
  private updated_at: string;
  private driver_order: number;
  private is_delayed: boolean;
  private customer_map_view: string;
  public distance: number;
  public duration: number;
  public status: string;
  public id: number;
  public deviation: number;

  constructor() {}

  deserialize(input) {
    if (input == undefined) {
      return this;
    }
    this.share_url = input.share_url;
    this.device_id = input.device_id;
    this.external_trip_id = input.external_trip_id;
    this.external_order_id = input.external_order_id;
    this.remaining_duration = input.remaining_duration;
    this.remaining_distance = input.remaining_distance;
    this.arrive_at = input.arrive_at;
    this.created_at = input.created_at;
    this.updated_at = input.updated_at;
    this.driver_order = input.driver_order;
    this.is_delayed = input.is_delayed;
    this.distance = input.distance;
    this.duration = input.duration;
    this.status = input.status;
    this.id = input.id;
    this.deviation = input.deviation;
    this.customer_map_view = input.customer_map_view;

    return this;
  }

  get shareUrl(): string {
    return this.share_url ? this.share_url : "";
  }

  get customerMapView(): string {
    if(this.customer_map_view) {
      return this.customer_map_view;
    }

    // eslint-disable-next-line max-len
    return this.share_url ? `${this.share_url}?map_only=true&icon=bike&hide_controls=true&hide_sharing=true&hide_nav=true` : null;
  }

  get deviceId(): string {
    return this.device_id;
  }

  get externalTripId(): string {
    return this.external_trip_id;
  }

  get externalOrderId(): string {
    return this.external_order_id;
  }

  get remainingDuration(): number {
    return this.remaining_duration ? this.remaining_duration : 0;
  }

  get remainingDistance(): number {
    return this.remaining_distance ? this.remaining_distance : 0;
  }

  get arriveAt(): string {
    return this.arrive_at;
  }

  get createdAt(): string {
    return this.created_at;
  }

  get updatedAt(): string {
    return this.updated_at;
  }

  get driverOrder(): number {
    return this.driver_order;
  }

  get isDelayed(): boolean {
    return this.is_delayed;
  }

  get isCompleted(): boolean {
    return this.status === "completed" || this.status === "cancelled";
  }
}

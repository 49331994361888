<mat-progress-bar *ngIf="(catalogState | async).loading" mode="indeterminate">
</mat-progress-bar>
<responsive-breakpoints></responsive-breakpoints>
<div
  *ngIf="
    '06:00' < (today | date: 'HH:mm') && (today | date: 'HH:mm') < '22:00';
    else timeout
  "
>
  <mat-card
    class="inventory-picker"
    *ngIf="inventoryType == null; else showTabs"
  >
    <mat-card-title>Select Inventory to update</mat-card-title>
    <mart-card-content>
      <div class="row">
        <button
          *ngIf="(today | date: 'HH:mm') < '19:50'"
          mat-raised-button
          color="primary"
          (click)="changeInventoryType(0)"
        >
          Today Inventory
        </button>
      </div>
      <div class="row">
        <button
          class="row"
          mat-raised-button
          color="primary"
          (click)="changeInventoryType(1)"
        >
          Tomorrow Inventory
        </button>
      </div>
    </mart-card-content>
  </mat-card>

  <ng-template #showTabs>
    <!--Title Bar-->
    <div class="top-nav-bar-wrap" *ngIf="!newInventoryMode">
      <div class="actions-wrapper">
        <select
          class="store-select"
          [(ngModel)]="selectedStoreId"
          (change)="changeStore()"
        >
          <option
            *ngFor="let store of (stores | async)?.availableStores"
            [value]="store.store_id"
          >
            {{ store.storeAttribute?.displayName }}
          </option>
        </select>
      </div>
    </div>

    <div class="main-content-outer" *ngIf="products.length > 0">
      <!--Filter-->
      <div class="row">
        <mat-form-field class="row">
          <input
            matInput
            (keyup)="applySearchFilter($event.target.value)"
            placeholder="Search product"
          />
        </mat-form-field>
      </div>

      <mat-tab-group #tab>
        <mat-tab *ngFor="let category of products" label="{{ category.key }}">
          <app-product-display-tab
            [actions]="buttonActions"
            [dataSource]="category"
            [columnsToDisplay]="columnsToDisplay"
          >
          </app-product-display-tab>
        </mat-tab>
      </mat-tab-group>

      <div class="bottom-component">
        <button
          mat-raised-button
          [disabled]="isRequestTriggered"
          class="bottom-button"
          color="primary"
          (click)="verifyRequests()"
        >
          Submit Request ({{ requests.length }})
        </button>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #timeout>
  <div style="margin: 10%">
    <h2 style="text-align: center">
      Stock Requesting is available only between 6am - 10pm
    </h2>
  </div>
</ng-template>

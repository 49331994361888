import { Serializable } from '../base';
import { ALLDAYS } from './driver-earning-slab';

export class DriverEarnings implements Serializable<DriverEarnings> {
  entity_id: number;
  week_no: number;
  is_earning_computed: boolean;
  private slab_name: string;
  incentive_wd_ontime: number;
  incentive_we_ontime: number;
  incentive_att: number;
  mon_att: boolean;
  tue_att: boolean;
  wed_att: boolean;
  thu_att: boolean;
  fri_att: boolean;
  sat_att: boolean;
  sun_att: boolean;
  mon_ot: boolean;
  tue_ot: boolean;
  wed_ot: boolean;
  thu_ot: boolean;
  fri_ot: boolean;
  sat_ot: boolean;
  sun_ot: boolean;
  mon_ord: number;
  tue_ord: number;
  wed_ord: number;
  thu_ord: number;
  fri_ord: number;
  sat_ord: number;
  sun_ord: number;
  mon_ci: string;
  tue_ci: string;
  wed_ci: string;
  thu_ci: string;
  fri_ci: string;
  sat_ci: string;
  sun_ci: string;
  mon_shift: number;
  tue_shift: number;
  wed_shift: number;
  thu_shift: number;
  fri_shift: number;
  sat_shift: number;
  sun_shift: number;
  user: number;

  constructor() {}

  deserialize(input) {
    this.entity_id = input.entity_id;
    this.week_no = input.week_no;
    this.is_earning_computed = input.is_earning_computed;
    this.slab_name = input.slab_name;
    this.incentive_wd_ontime = input.incentive_wd_ontime;
    this.incentive_we_ontime = input.incentive_we_ontime;
    this.incentive_att = input.incentive_att;
    this.mon_att = input.mon_att;
    this.tue_att = input.tue_att;
    this.wed_att = input.wed_att;
    this.thu_att = input.thu_att;
    this.fri_att = input.fri_att;
    this.sat_att = input.sat_att;
    this.sun_att = input.sun_att;
    this.mon_ot = input.mon_ot;
    this.tue_ot = input.tue_ot;
    this.wed_ot = input.wed_ot;
    this.thu_ot = input.thu_ot;
    this.fri_ot = input.fri_ot;
    this.sat_ot = input.sat_ot;
    this.sun_ot = input.sun_ot;
    this.mon_ord = input.mon_ord;
    this.tue_ord = input.tue_ord;
    this.wed_ord = input.wed_ord;
    this.thu_ord = input.thu_ord;
    this.fri_ord = input.fri_ord;
    this.sat_ord = input.sat_ord;
    this.sun_ord = input.sun_ord;
    this.mon_ci = input.mon_ci;
    this.tue_ci = input.tue_ci;
    this.wed_ci = input.wed_ci;
    this.thu_ci = input.thu_ci;
    this.fri_ci = input.fri_ci;
    this.sat_ci = input.sat_ci;
    this.sun_ci = input.sun_ci;
    this.mon_shift = input.mon_shift;
    this.tue_shift = input.tue_shift;
    this.wed_shift = input.wed_shift;
    this.thu_shift = input.thu_shift;
    this.fri_shift = input.fri_shift;
    this.sat_shift = input.sat_shift;
    this.sun_shift = input.sun_shift;
    this.user = input.user;
    return this;
  }

  /**
   * Weekly orders done
   */
  get week_ord() {
    let ord_count = 0;
    ALLDAYS.forEach(day => {
      let dayOrder = this[`${day}_ord`];
      if (dayOrder && dayOrder > 0) {
        ord_count += dayOrder;
      }
    });

    return ord_count;
  }

  get slabName() {
    return this.slab_name ? this.slab_name : 'NO_SLAB';
  }


  get incentiveProgress() {
    return (
      this.incentive_we_ontime + this.incentive_wd_ontime + this.incentive_att
    );
  }
}

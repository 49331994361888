import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect } from "@ngrx/effects";
import { LiteSaleOrder, SaleOrder } from "@tendercuts/models";
import {
  FetchLiteSaleOrder,
  FetchNewOrdersService,
  FetchOrdersService,
  FetchPriorityOrderService,
  FetchSaleOrdersService,
  SaleOrderProcessor,
} from "@tendercuts/providers";
import { ofAction } from "ngrx-actions";
import { of, pipe, Observable } from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
} from "rxjs/operators";

import {
  ActionAllOrdersLoaded,
  ActionCheckNewOrders,
  ActionClearOrders,
  ActionFetchNewOrders,
  ActionGetOrders,
  ActionGetPriorityOrders,
  ActionLoadSuccess,
  ActionOrderError,
  ActionPriorityOrdersLoaded,
  ActionRefreshOrders,
  ActionSaveOrders,
  ActionSetNewOrders,
  ActionUpdateActiveOrders,
} from "./actions";

@Injectable()
export class OrderEffects {
  constructor(
    private actions$: Actions,
    private orderService: FetchOrdersService,
    private fetchLiteSaleOrder: FetchLiteSaleOrder,
    private newOrderService: FetchNewOrdersService,
    private priorityOrderService: FetchPriorityOrderService,
    public saleOrderProcessor: SaleOrderProcessor,
    public fetchSaleOrderService: FetchSaleOrdersService,
  ) {}

  /**
   * Fetch current order status
   * compare to current redux oder and fetch out
   * orders to be removed from redux and
   * update the current status of redux orders
   *
   * Since its async function we not able do multiple dispatch
   * so we trigger one action ActionUpdateAndFetchNewOrders which dispatches
   * ActionUpdateActiveOrders and ActionCheckNewOrders
   */
  
  refreshOrders: Observable<
    ActionUpdateActiveOrders | ActionOrderError
  > = createEffect(() => this.actions$.pipe(
    ofAction(ActionRefreshOrders),
    switchMap((action: ActionRefreshOrders) => {
      const params: HttpParams = this.fetchLiteSaleOrder.getParams(
        action.storeId,
      );

      return this.fetchLiteSaleOrder.getData(params).pipe(
        switchMap(async (liteSaleOrders: LiteSaleOrder[]) => {
          const activeOrders: string[] = liteSaleOrders.map(
            (order) => order.orderId,
          );
          const removeOrders: string[] = await this.saleOrderProcessor.findOrdersToDelete(
            activeOrders,
          );
          const newOrders: string[] = await this.saleOrderProcessor.findNewOrders(
            activeOrders,
          );

          return new ActionUpdateActiveOrders(
            removeOrders,
            liteSaleOrders,
            action.storeId,
            newOrders,
          );
        }),
        catchError((err) => of(new ActionOrderError(err))),
      );
    }),
  ));

  /**
   * This effect will be trigger after completion of
   * ActionUpdateActiveOrders Reducer function
   * This helps in sequential flow 1) remove old order from redux
   * 2)Then append new orders to the purely active redux orders
   *
   * If there is no new orders -> returns ActionLoadSuccess
   * which will set loading as false
   */
  
  actionUpdateAndFetchNewOrders: Observable<
    ActionLoadSuccess | ActionFetchNewOrders
  > = createEffect(() => this.actions$.pipe(
    ofAction(ActionUpdateActiveOrders),
    switchMap((action: ActionUpdateActiveOrders) => {
      if (action.newOrders.length === 0) {
        return [new ActionLoadSuccess()];
      }

      return [new ActionFetchNewOrders(action.storeId, action.newOrders)];
    }),
  ));

  /**
   * fetch the orders details of the give orders id
   * and the new orders in redux
   */
  
  actionFetchNewOrders: Observable<
    ActionOrderError | ActionSetNewOrders
  > = createEffect(() => this.actions$.pipe(
    ofAction(ActionFetchNewOrders),
    switchMap((action: ActionFetchNewOrders) => {
      const params: {
        store_id: string;
        order_ids: string[];
      } = this.fetchSaleOrderService.getParams(
        action.storeId,
        action.newOrders,
      );

      return this.fetchSaleOrderService.getData(params).pipe(
        mergeMap((res: SaleOrder[]) => {
          return [new ActionSetNewOrders(res)];
        }),
        catchError((err) => of(new ActionOrderError(err))),
      );
    }),
  ));

  /**
   * Trigger the server request to load all stores
   * type {Observable<any>}
   */
  
  getOrders: Observable<
    | ActionOrderError
    | ActionLoadSuccess
    | ActionAllOrdersLoaded
    | ActionGetPriorityOrders
  > = createEffect(() => this.actions$.pipe(
    ofAction(ActionGetOrders),
    switchMap((action: ActionGetOrders) => {
      const params: HttpParams = this.orderService.getParams(action.storeId);

      return this.orderService.getData(params).pipe(
        mergeMap((res: SaleOrder[]) => {
          return [
            new ActionAllOrdersLoaded(res),
            new ActionLoadSuccess(),
            new ActionGetPriorityOrders(action.storeId),
          ];
        }),
        catchError((err) => of(new ActionOrderError(err))),
      );
    }),
  ));

  /**
   * Set the orderSate orders to localStorage
   * under key 'liveOrders'
   */
  
  setOrders: Observable<ActionLoadSuccess> = createEffect(() => this.actions$.pipe(
    ofAction(ActionSaveOrders),
    map((action: ActionSaveOrders) => {
      const orders: any[] = action.orders.map((order) => order.rawInput);
      localStorage.setItem("liveOrders", JSON.stringify(orders));

      return new ActionLoadSuccess();
    }),
  ));

  /**
   * Removes the locally store live orders
   */
  
  removeOrders: Observable<ActionLoadSuccess> = createEffect(() => this.actions$.pipe(
    ofAction(ActionClearOrders),
    map((action) => {
      localStorage.removeItem("liveOrders");

      return new ActionLoadSuccess();
    }),
  ));

  /**
   * Trigger the server request to load new orders
   * type {Observable<any>}
   */
  
  getNewOrders: Observable<any> = createEffect(() => this.actions$.pipe(
    ofAction(ActionCheckNewOrders),
    switchMap((action: ActionCheckNewOrders) => {
      const lastOrderTime: any = action.lastOrderTime;

      const params: HttpParams = this.newOrderService.getParams(
        action.storeId + "",
        lastOrderTime,
      );

      return this.newOrderService.getData(params).pipe(
        mergeMap((res: SaleOrder[]) => {
          const resp: any[] = [];
          resp.push(new ActionSetNewOrders(res));
          if (res.length > 0) {
            resp.push(new ActionGetPriorityOrders(res[0].storeId));
          }

          return resp;
        }),
        catchError((err) => of(new ActionOrderError(err))),
      );
    }),
  ));

  /**
   * Trigger the server request to load all prority orders
   */
  
  getPriorityOrders: Observable<
    ActionOrderError | ActionPriorityOrdersLoaded
  > = createEffect(() => this.actions$.pipe(
    ofAction(ActionGetPriorityOrders),
    switchMap((action: ActionGetPriorityOrders) => {
      const params: HttpParams = this.priorityOrderService.getParams(
        action.storeId,
      );

      return this.priorityOrderService.getData(params).pipe(
        map((res: any) => {
          return new ActionPriorityOrdersLoaded(res);
        }),
        catchError((err) => of(new ActionOrderError(err))),
      );
    }),
  ));
}

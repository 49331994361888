import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BasePostProvider } from "@tendercuts/http";
import { GenericServerResponse } from "@tendercuts/models";

@Injectable()
export class ItemWeightService extends BasePostProvider<GenericServerResponse> {
  constructor(
    public ePoint: EndPoint,
    public http: HttpClient,
    public loginService: LoginServices,
  ) {
    super(http);
  }
  get isAuthenticatedEndpoint(): boolean {
    return true;
  }
  get authProtocol(): LoginServices {
    return this.loginService;
  }
  get model(): typeof GenericServerResponse {
    return GenericServerResponse;
  }
  get endpoint(): string {
    return this.ePoint.itemWeight();
  }

  getParams(incrementId: any, items: any): string {
    const ordersDetails: string = JSON.stringify({
      items,
      increment_id: incrementId,
      version: 100,
    });

    return ordersDetails;
  }
}

<div class="app-map-trip">
  <div *ngIf="mapTrip">
    <!--Order locations-->
    <ng-container *ngFor="let order of mapTrip.driverOrder">
      <app-address-marker [driverOrder]="order" [order]="order.saleOrder" [showCircle]="false"></app-address-marker>
    </ng-container>

    <!--Driver Location: TODO move to a sep component.-->
    <ng-container *ngFor="let ping of pings; let i = index">
      <app-location-ping-marker [label]="i + ''" [ping]="ping"></app-location-ping-marker>
    </ng-container>

    <!--Point details-->
    <!--<app-trip-direction [trip]="trip"></app-trip-direction>-->
  </div>
</div>
import { Injectable } from "@angular/core";
import { Store } from "@tendercuts/models";
import { of, Observable } from "rxjs";

@Injectable()
export class MockGoogleMapsService {
  getPlacePredictions(query: string): Observable<Array<any>> {
    return of([
      { description: "Porur", structured_formatting: { main_text: "Porur" } },
      { description: "Powai", structured_formatting: { main_text: "Porur" } },
    ]);
  }
  geocodePlace(placeId: string): Observable<any> {
    return of({
      geometry: {
        location: {
          lat: () => "10.000",
          lng: () => "19.8234",
        },
      },
    });
  }

  getStoreDistanceMatrix(origin: any): Observable<Store | null> {
    return of(
      new Store().deserialize({
        name: "thoraipakkam",
        code: "thoraipakkam",
        store_id: 1,
        location: {
          longandlatis: { latitude: "12.932999", longitude: "80.232793" },
        },
        website_id: 1,
      }));
  }
}

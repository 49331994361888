import { Driver, Trips, TripCashEntries, TripConfig } from "@tendercuts/models";
// TODO
// eslint-disable-next-line @typescript-eslint/naming-convention
export interface DriverState {
  drivers: Driver[];
  allDrivers: Driver[];
  trips: Trips[];
  savedTrips: Trips[];
  driverMap: any;
  loading: boolean;
  trip_loader: boolean;
  driver_loader: boolean;
  error: boolean;
  errorMessage: null;
  editTripId: number;
  tripCashEntries: TripCashEntries[];
  tripConfig: TripConfig;
  stichDone: boolean;
}

export const driverInitialState: DriverState = {
  drivers: [],
  allDrivers: [],
  trips: [],
  savedTrips: [],
  loading: false,
  trip_loader: false,
  driver_loader: false,
  driverMap: {},
  error: false,
  errorMessage: null,
  editTripId: null,
  tripCashEntries: [],
  tripConfig: null,
  stichDone: false
};

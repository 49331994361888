import { Params } from "@angular/router";
import { Action } from "@ngrx/store";

import { User } from "@tendercuts/models";
import { AppState } from "../../state";

/**
 * Action to trigger the login requests, outcome maybe success/failure.
 */
export class ActionTryLogin implements Action {
  readonly type: string = "ACTION_LOGIN_TRY";

  constructor(public payload: User) {}
}

/**
 * Action to trigger the login requests via flock, outcome maybe success/failure.
 */
export class ActionTryFlockLogin implements Action {
  readonly type: string = "ACTION_TRY_FLOCK_LOGIN";

  constructor(public payload: Params) {}
}

/**
 * Action to set the error flag set during login failures
 */
export class ActionLoginFail implements Action {
  readonly type: string = "ACTION_LOGIN_FAIL";

  constructor(public errorMessage?: string) {}

}

/**
 * Action triggered when login is successfull
 */
export class ActionLoginSuccess implements Action {
  readonly type: string = "ACTION_LOGIN_SUCCESS";

  constructor(public user: User) {}
}

/**
 * Action triggered when login is successful via flock auth
 */
export class ActionSetFlockLogin implements Action {
  readonly type: string = "ACTION_SET_FLOCK_LOGIN";
  constructor() {}
}

/**
 * Action to clear the error flag set during failures
 */
export class ActionLoginClearError implements Action {
  readonly type: string = "ACTION_LOGIN_CLEAR_ERROR";
}

export class ActionLogoutUser implements Action {
  readonly type: string = "ACTION_LOGOUT_USER";

  constructor() {}
}

export class ActionLogoutSuccess implements Action {
  readonly type: string = "ACTION_LOGOUT_SUCCESS";
}

<!-- <mat-table #table [dataSource]="tickets">
  <ng-container matColumnDef="date">
    <mat-header-cell *matHeaderCellDef>
      Date
    </mat-header-cell>
    <mat-cell *matCellDef="let ticket">
      {{ ticket.created_at | date: mediumDate }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="subject">
    <mat-header-cell *matHeaderCellDef>
      Subject
    </mat-header-cell>
    <mat-cell *matCellDef="let ticket">
      {{ ticket.subject }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="voc">
    <mat-header-cell *matHeaderCellDef>
      VOC
    </mat-header-cell>
    <mat-cell *matCellDef="let ticket">
      {{ ticket.voc }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="disposition">
    <mat-header-cell *matHeaderCellDef>
      Disposition
    </mat-header-cell>
    <mat-cell *matCellDef="let ticket">
      {{ ticket.disposition }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="description">
    <mat-header-cell *matHeaderCellDef>
      description
    </mat-header-cell>
    <mat-cell *matCellDef="let ticket">
      {{ ticket.description }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef>
      Status
    </mat-header-cell>
    <mat-cell *matCellDef="let ticket">
      {{ ticket.status }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="detail view">
    <mat-header-cell *matHeaderCellDef>
      Detail
    </mat-header-cell>
    <mat-cell *matCellDef="let ticket">
      <a href="{{ ticket.url }}" target="_blank">
        view
      </a>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
  <mat-row *matRowDef="let ticket; columns: columnsToDisplay"></mat-row>
</mat-table> -->
<app-customer-profile-grid
  [dataSource]="freshDeskTicketDataSource"
  [columnsToDisplay]="columnsToDisplay"
  [titleToDisplay]="titleToDisplay"
  [actions]="actions"
></app-customer-profile-grid>

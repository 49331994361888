<div class="top-nav-bar-wrap">
  <div class="actions-wrapper">Live Track</div>
</div>
<br />
<breadcrumb class="separate-page-breadcrumb-styles"></breadcrumb>
<div class="row">
  <!-- Search actions -->
  <div class="col-6">
    <!--Search bar-->
    <div class="search-bar row">
      <mat-form-field class="col-9">
        <input
          type="text"
          placeholder="Enter Order Number"
          aria-label="Number"
          matInput
          [(ngModel)]="orderNumber"
          (keyup.enter)="resolveOrder()"
        />
      </mat-form-field>
      <button class="col-2" mat-stroked-button (click)="resolveOrder()">
        Search Order
      </button>
    </div>
  </div>

  <!-- card display -->
  <div class="col-6">
    <mat-card *ngIf="order">
      <mat-card-title>{{ order.incrementId }}</mat-card-title>
      <mat-card-subtitle *ngIf="time"
        >ETA: ({{ time }} Mins, Distance: {{ distance }} Km)</mat-card-subtitle
      >
      <mat-card-content>
        <p><b>Pincode:</b> {{ order.shippingAddress.postcode }}</p>
        <p><b>Apartment/Door:</b> {{ order.shippingAddress.fax }}</p>
        <p><b>Street:</b> {{ order.shippingAddress.apartment }}</p>
        <p><b>Google Address:</b> {{ order.shippingAddress.googleAddress }}</p>
        <p><b>Landmark:</b> {{ order.shippingAddress.landmark }}</p>
        <p><b>Status:</b> {{ order.status }}</p>
        <p *ngIf="origin"><b>Status:</b> {{ order.status }}</p>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="latlong">
  <mat-form-field class="full-width">
    <textarea
      matInput
      placeholder="lat,lng"
      [(ngModel)]="latLngs"
      #pointsData
    ></textarea>
  </mat-form-field>
  <button class="col-2" mat-stroked-button (click)="generateRouteLine()">
    Plot Path
  </button>
</div>

<agm-map
  #map
  [zoom]="zoom"
  [latitude]="center[0]"
  [longitude]="center[1]"
  [disableDefaultUI]="false"
  [zoomControl]="true"
  (mapReady)="resolveUrl()"
>
  <!--Driver Location: TODO move to a sep component.-->
  <ng-container *ngFor="let ping of pings; let i = index">
    <app-location-ping-marker
      [label]="i + ''"
      [ping]="ping"
    ></app-location-ping-marker>
  </ng-container>

  <ng-container *ngIf="order">
    <!--searched order-->
    <app-address-marker [order]="order"></app-address-marker>

    <agm-marker
      *ngIf="oLat"
      [latitude]="oLat"
      [longitude]="oLng"
      [label]="Driver"
      [iconUrl]="'assets/marker/bike.png'"
    >
    </agm-marker>
  </ng-container>
</agm-map>

import {Serializable} from '../base';

export class StoreAddress implements Serializable<StoreAddress> {
  private store_id: number;
  public name:string;

  get storeId() {
    return this.store_id
  }

  public address: string;

  constructor() {
  }

  deserialize(input) {
    if (input == null || input == undefined) {
      return this;
    }

    this.store_id = input.scope_id;
    this.address = input.value.replace('\r\n', '');

    return this;
  }

}

import { Component, Input } from "@angular/core";
import { BaseGridComponent } from "../base-grid/base-grid.component";

@Component({
  selector: "app-inventory-request-approval-grid",
  templateUrl: "../base-grid/base-grid.component.html",
  styleUrls: ["../base-grid/base-grid.component.scss"],
})
export class InventoryRequestApprovalGridComponent extends BaseGridComponent {
  @Input() columnsToDisplay: string[] = ["productName", "qty"];
  @Input() titleToDisplay: string[] = ["Name", "Quantity"];
}

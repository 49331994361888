<div class="row no-gutters tripsheet-close">
  <h6 *ngIf="tripSheet" class="header">
    Trip Sheet for
    <span
      *ngIf="tripSheet.driverId"
      class="driver-phone"
      (click)="redirectToDriverProfile()"
      >{{ tripSheet.driverId }}
    </span>
    <span *ngIf="tripSheet.driverName">( {{ tripSheet.driverName }} )</span>
    <mat-chip>{{ tripSheet.tripSheetStatus }}</mat-chip>
  </h6>
  <button
    mat-raised-button
    color="primary"
    class="tripsheet-close__back-btn"
    *ngIf="(showBackButton && !tripSheetDetailMode) || dialogMode"
    (click)="closeTripSheet()"
  >
    Back
  </button>
</div>

import { Serializable } from "./base";

export class DriverLanguage implements Serializable<DriverLanguage> {
  private created_at: Date;
  private updated_at: Date;
  public id: number;
  public language: string;

  get createdAt(): Date {
    return this.created_at;
  }

  get updatedAt(): Date {
    return this.updated_at;
  }

  constructor() {}

  deserialize(input) {
    this.language = input.language;
    this.created_at = input.created_at;
    this.updated_at = input.updated_at;
    this.id = input.id;

    return this;
  }
}

import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";

@Injectable({
  providedIn: "root",
})
export class DeviceGuardService implements CanActivate {
  constructor(private router: Router, private store: Store<AppState>) {}

  /**
   * return false for mobile devices
   * @param next
   * @param state
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    return window.screen.width > 425;
  }
}

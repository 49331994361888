<mat-sidenav-container class="sales-dash">
  <div class="fixed-header">
    <mat-toolbar class="title">
      <mat-toolbar-row>
        <!-- <span>Sales Dashboard</span> -->
        <button
          mat-raised-button
          color="primary"
          id="bulk-kot"
          (click)="printBulkKot()"
        >
          <i class="material-icons">print</i>
          Print Bulk KOT
        </button>

        <button
          mat-raised-button
          color="primary"
          id="move-processing"
          (click)="processing()"
        >
          <i class="fa fa-arrow-right" aria-hidden="true"></i>
          Move To Processing
        </button>
        <div
          mat-raised-button
          color="primary"
          id="active-riders"
          class="active-riders"
          (click)="showActiveRiders()"
        >
          <span class="btn"> Active Drivers </span>
        </div>
        <button
          hidden
          mat-raised-button
          color="primary"
          id="move-to-hold"
          (click)="holdOrders()"
        >
          <i class="fa fa-hand-paper-o" aria-hidden="true"></i>
          Move To Hold
        </button>
        <span class="title__order_count"
          >Orders Per Trip:<strong>
            {{ (driverState | async).tripConfig?.ordersPerTrip }}</strong
          ></span
        >
        <span class="example-spacer"></span>

        <app-symbol-legend class="col-4"></app-symbol-legend>
      </mat-toolbar-row>
    </mat-toolbar>

    <div class="row filters">
      <mat-card class="col-12">
        <mat-card-title>
          <mat-form-field class="row">
            <input
              matInput
              id="searchbar"
              [(ngModel)]="searchTerm"
              (keyup)="applySearchFilter()"
              placeholder="Search order Id/Driver Name/Driver Number/Customer Name"
              #searchbar
            />
          </mat-form-field>
        </mat-card-title>
        <mat-card-content>
          <!--Ideally we should move this to a separate component!-->
          <mat-chip-list [multiple]="true" [selectable]="true">
            <div class="border">
              <mat-chip (click)="clearAllFilter()"> Clear </mat-chip>
            </div>

            <div
              class="border"
              *ngFor="
                let filterGroup of combinedFilterGroups;
                let productFilterGroup = last
              "
            >
              <ng-container *ngIf="productFilterGroup; else normalFilter">
                <mat-chip
                  [ngClass]="{ highlight: filterGroup.filters.length }"
                  (click)="openProductSelectionWizard()"
                >
                  <ng-container
                    *ngIf="filterGroup.filters.length; else showFilterByProduct"
                  >
                    {{ filterGroup.filters[0].displayName }} -
                    {{ filterGroup.filters[0].counter }} Packs
                  </ng-container>
                  <ng-template #showFilterByProduct>
                    Filter By Product
                  </ng-template>
                </mat-chip>
              </ng-container>
              <ng-template #normalFilter>
                <mat-chip
                  *ngFor="let filter of filterGroup.filters"
                  (click)="applyFilters(filter)"
                  [selected]="filter.selected"
                >
                  {{ filter.displayName }}
                </mat-chip>
              </ng-template>
            </div>
          </mat-chip-list>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="spacer"></div>

  <br />

  <!-- When user just logged in, so there will be no selected store -->
  <div *ngIf="!(storeState | async).store">
    <i class="no-driver-icon fa fa-home"></i>
    <p>Please Select A Store</p>
  </div>

  <mat-tab-group
    class="sales-dash__mat-tab-group"
    (selectedTabChange)="loadSelectedStatusData(selectedStatusTabIndex)"
    [(selectedIndex)]="selectedStatusTabIndex"
  >
    <mat-tab
      *ngFor="let status of statusTabsFilterGroup.filters; let i = index;"
      [label]="status.nameDisplayed"
    >
      <ng-container *ngIf="selectedStatusTabIndex === i && status.selected">
        <!-- When there is no active drivers in selected store -->
        <div
          *ngIf="
            !(storeState | async).store &&
            (orderState | async).orders.length == 0
          "
        >
          <i class="fa fa-user-times"></i>
          <p>No Active orders</p>
        </div>
        <div class="sales-dash__mat-tab-group__table-container" >
          <app-sale-order-grid
          [dataSource]="orders"
          [rowClickCallback]="rowClickCallback"
          [showPagination]="true"
        >
        </app-sale-order-grid>
        </div>
      </ng-container>
    </mat-tab>
  </mat-tab-group>

  <div class="row table-side">
    <div
      mat-raised-button
      color="primary"
      id="active-riders"
      class="active-riders"
      (click)="showActiveRiders()"
    >
      <span class="btn"> Active Drivers </span>
    </div>
  </div>

  <mat-sidenav class="side-nav" mode="over" position="end" #drawer>
    <i
      class="menu-item-icon fa fa-window-close-o side-nav__close"
      (click)="closeDrawer(true)"
    ></i>
    <div class="side-nav__rider-detail" *ngIf="showData">
      <app-driver-status></app-driver-status>
    </div>
  </mat-sidenav>
</mat-sidenav-container>

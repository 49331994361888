<button
  type="button"
  class="btn-notifications"
  mat-icon-button
  [matMenuTriggerFor]="menu"
  (click)="closeNotificationsMenu()"
>
  <i *ngIf="!messages.length" class="fa fa-envelope-o"></i>
  <i *ngIf="messages.length" class="fa fa-envelope"></i>
  <span *ngIf="messages.length" class="notifications-count">{{
    messages.length
  }}</span>
</button>
<mat-menu #menu="matMenu" class="messages">
  <header>
    <h5>You have {{ messages.length }} messages</h5>
  </header>
  <div class="container-fluid">
    <ng-container *ngFor="let message of messages">
      <div
        class="row message"
        [ngClass]="{ 'message-new': message.time === 'JUST NOW' }"
      >
        <div class="col-xs-12 message-wrapper">
          <img class="message-image" [src]="message.image" alt="PIC" />
          <div class="message-content">
            <h3 class="message-user">{{ message.username }}</h3>
            <h4 class="message-title">{{ message.title }}</h4>
            <span class="message-text">{{ message.text }}</span>
          </div>
          <span
            class="message-time"
            [ngClass]="{ 'message-time-now': message.time === 'JUST NOW' }"
            >{{ message.time }}</span
          >
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <hr class="messages-divider" />
        </div>
      </div>
    </ng-container>
  </div>
  <footer class="text-center">
    <a href="javascript:void(0)">VIEW ALL MESSAGES</a>
  </footer>
</mat-menu>

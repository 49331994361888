import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BasePostProvider } from "@tendercuts/http";
import { GenerateWeeklyTripSheetResponse } from "@tendercuts/models";

@Injectable({
  providedIn: "root",
})
export class GenerateWeeklyTripSheetService extends BasePostProvider<
GenerateWeeklyTripSheetResponse
> {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof GenerateWeeklyTripSheetResponse {
    return GenerateWeeklyTripSheetResponse;
  }

  get endpoint(): string {
    return this.endpointService.generateWeeklyTripsheet();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(
    driverId: number,
    selectedWeek: string,
  ): { driver_id: number; week_no: string; year: string } {
    const weekNo: string = selectedWeek.split("W")[1];
    const year: string = selectedWeek.split("-")[0];

    const params: { driver_id: number; week_no: string; year: string } = {
      driver_id: driverId,
      week_no: weekNo,
      year,
    };

    return params;
  }
}

<div class="row inventory">
  <mat-card
    class="col-5"
    *ngIf="productsToRelease.length == 0; else showProducts"
  >
    <mat-card-title> Categories </mat-card-title>

    <div class="search-scroll col-12">
      Stock release for -
      <span *ngFor="let store of (stores | async)?.availableStores">
        <span class="store-name" *ngIf="store.store_id == selectedStoreId">
          {{ store.storeAttribute?.displayName | uppercase }} Store
        </span>
      </span>
    </div>
    <br />
    <mat-card-content *ngIf="selectedStoreId">
      <mat-list *ngIf="(releasePoMap | json) === '{}'; else showPoButtons">
        <mat-list-item
          class="row"
          *ngFor="let category of categories; let i = index"
        >
          <div class="col-5 {{ category.name }}">
            {{ category.name }}
          </div>
          <button
            (click)="inventoryRelease(category, selectedStoreId)"
            id="category{{ i }}"
            mat-raised-button
            color="primary"
            class="col-5"
          >
            Release
          </button>
        </mat-list-item>
      </mat-list>
      <ng-template #showPoButtons>
        <mat-list>
          <h5>
            Select the PO for release -
            <button
              color="primary"
              mat-button
              (click)="reSetSelectedCateogry()"
            >
              Back
            </button>
          </h5>
          <mat-list-item *ngFor="let poObject of releasePoMap | keyvalue">
            <button
              (click)="onPoSelected(poObject.key)"
              mat-raised-button
              class="w-100"
              color="primary"
            >
              {{ poObject.key === "null" ? "PO0001" : poObject.key }}
            </button>
          </mat-list-item>
        </mat-list>
      </ng-template>
    </mat-card-content>
  </mat-card>

  <ng-template #showProducts>
    <mat-card>
      <app-inventory-before-release
        [productData]="productsToRelease"
        (release)="releaseInventory($event)"
      >
      </app-inventory-before-release>
    </mat-card>
  </ng-template>
</div>

import { OrderTags } from "./order-tags";

export class RatingCategory {
    private category_name: string;
    private _ratingStar: number;
    private _selectedTags: OrderTags[] = [];
    private sub_title: string;
    private linked_field: string;
    private _availableTags: OrderTags[] = [];

    public id: number;
    public title: string;
  
    deserialize(input) {
      this.id = input.id;
      this.category_name = input.category_name;
      this.title = input.title;
      this.sub_title = input.sub_title;
      this.linked_field = input.linked_field;

      return this;
    }

    get linkedField() {
        return this.linked_field;
    }

    get subTitle() {
        return this.sub_title;
    }

    get categoryName() {
        return this.category_name;
    }

    /** 
     * sets the tag selected by the user
     */
    set selectedTags(tags: OrderTags[]) {
        this._selectedTags = tags
    }   
    
    /**
     * Set the rating selected by the user 
     */
    set ratingStar(star: number) {
        this._ratingStar = star;
    }

    /** 
     * Set the available stars with respect 
     * to the selected rating number
     */
    set availableTags(tags: OrderTags[]){
        this._availableTags = tags;
    }

    /** 
     * @returns the available tags for 
     * the category
     */
    get availableTags(): OrderTags[] {
       return this._availableTags
    }
    
    get selectedTags(): OrderTags[]{
        return this._selectedTags;
    }   
    
    get ratingStar(): number {
        return this._ratingStar
    }

    /**
     * @returns boolean
     * based on rating star and selected tag
     * True - when rating is greater than 3 or if rating less than 4 , 
     *         then tags must be selected
     */
    get isValid(): boolean{
        return this.ratingStar >= 4 ? true : this.selectedTags.length > 0;
    }

    /** 
     * Return true if product category rating star less than 5
     * only hack with hardcoded id 2 - product rating category
     * */
    get showItem(): boolean{
        return this.id == 2 && this.ratingStar && this.ratingStar < 5;
    }
  }
  
import { Serializable } from "../base";

export class EdcMachine implements Serializable<EdcMachine> {
  private serial_number: string;
  private pos_code: string;
  public imei: string;
  public status: boolean;

  get serialNo(): string {
    return this.serial_number;
  }

  get posCode(): string {
    return this.pos_code;
  }

  deserialize(input) {
    if (input == null || input == undefined) {
      return this;
    }

    this.serial_number = input.serial_number;
    this.pos_code = input.pos_code;
    this.imei = input.imei;
    this.status = input.status;

    return this;
  }
}

import { Component, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TripSheet, WeeklyTripSheet } from "@tendercuts/models";

/**
 * component to get trip earning and trip sheet approval information
 */
@Component({
  selector: "app-trip-sheet-confirm",
  templateUrl: "./trip-sheet-confirm.component.html",
  styleUrls: ["./trip-sheet-confirm.component.scss"],
})
export class TripSheetConfirmComponent {
  tripSheet: TripSheet;

  weeklyTripSheet: WeeklyTripSheet;

  // check for submit button enable
  isRadioButtonSelected: boolean = false;

  // controller for comment box
  commentControl: FormControl = new FormControl();

  // controller for radio buttons
  radioButtonController: FormControl = new FormControl([Validators.required]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TripSheet | WeeklyTripSheet,
    public dialogRef: MatDialogRef<TripSheetConfirmComponent>,
  ) {
    if (data instanceof WeeklyTripSheet) {
     this.weeklyTripSheet = data;
     this.isRadioButtonSelected = true;
    } else {
      this.tripSheet = data;
    }
  }

  /**
   * pass the params to trip sheet update
   */
  tripSheetUpdate(): void {
    this.dialogRef.close({
      status: true,
      requestValue: this.radioButtonController.value,
      comment: this.commentControl.value,
    });
  }

  /**
   * check for enable submit button
   */
  get canActiveSubmit(): boolean {
    return (
      this.commentControl.value && this.isRadioButtonSelected
    );
  }

  closeDialog(): void {
    this.dialogRef.close({ status: false});
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BasePostProvider } from "@tendercuts/http";
import { DriverViolation } from "@tendercuts/models";

@Injectable()
export class ChangeAddressService extends BasePostProvider<DriverViolation> {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint
  ) {
    super(http);
  }

  get key(): string {
    return "id";
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof DriverViolation {
    return DriverViolation;
  }

  get endpoint(): string {
    return this.endpointService.changeAddress();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(
    correctedLat: number,
    correctedLng: number,
    incrementId: string,
    violatedId: number,
    isDriverViolated: boolean
  ): {
    correctedLat: number;
    correctedLng: number;
    incrementId: string;
    violatedId: number;
    isDriverViolated: boolean;
  } {
    return {
      correctedLat,
      correctedLng,
      incrementId,
      violatedId,
      isDriverViolated,
    };
  }
}

import { Injectable } from "@angular/core";
import { ProductPrice } from "@tendercuts/models";
import { of, Observable } from "rxjs";
import { ProductPriceService } from "./service";

@Injectable()
export class MockProductPriceService extends ProductPriceService {
  public data: any;
  private prodPriceData: {
    price: number;
    entity_id: number;
    special_price: number;
}[] = [
    {
      price: 115,
      entity_id: 192,
      special_price: 100,
    },
    {
      price: 129,
      entity_id: 193,
      special_price: 110,
    },
    {
      price: 149,
      entity_id: 196,
      special_price: 125,
    },
  ];

  public get cache(): any {
    return this.data;
  }

  public getData(url: any): Observable<ProductPrice[]> {
    const productPrice: ProductPrice[] = this.prodPriceData.map((data) =>
      new ProductPrice().deserialize(data),
    );

    this.data = productPrice;

    return of(productPrice);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndPoint } from '@endpoint';
import { LoginServices } from '@login';
import { BasePostProvider } from '@tendercuts/http';
import { GenericServerResponse } from '@tendercuts/models';

@Injectable()
export class AddCommentService extends BasePostProvider<GenericServerResponse> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof GenericServerResponse {
    return GenericServerResponse;
  }

  get endpoint(): string {
    return this.endpointService.addComment();
  }

  getParams(orderId: string, comment: string): string {
    const params: string = JSON.stringify({
      order_id: orderId,
      comment,
    });

    return params;
  }
}

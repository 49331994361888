<mat-table
  class="today-trip-list mat-elevation-z1"
  #matTable
  [dataSource]="todaysTrip"
  matSort
>
  <ng-container matColumnDef="trip-id">
    <mat-header-cell *matHeaderCellDef> Trip Id </mat-header-cell>
    <mat-cell *matCellDef="let trip">
      <span class="label">{{ trip.tripId }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="created-at">
    <mat-header-cell *matHeaderCellDef> Created at </mat-header-cell>
    <mat-cell *matCellDef="let trip">
      <span class="label">{{ trip.time }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="total-amount">
    <mat-header-cell *matHeaderCellDef> Cash to be Collected </mat-header-cell>
    <mat-cell *matCellDef="let trip">
      <span class="label">{{ trip.totalAmount }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actual-amount">
    <mat-header-cell *matHeaderCellDef> Collected Cash </mat-header-cell>
    <mat-cell *matCellDef="let trip">
      <span class="label">{{ trip.amount }}</span>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
  <mat-row *matRowDef="let row; columns: columnsToDisplay"></mat-row>
</mat-table>

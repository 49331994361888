import { Serializable } from "../base";

export class QuoteItem implements Serializable<QuoteItem> {
  product: number;
  qty: string;
  
  deserialize(input) {
    this.product = input.product;
    this.qty = input.qty;
    return this;
  }
}
export class QuoteItemResponse implements Serializable<QuoteItemResponse> {
  items: QuoteItem[] = [];
  entity_id: number;

  deserialize(input) {
    if (input.quote_item && input.quote_item.length) {
      input.quote_item.forEach((item) => {
        this.items.push(new QuoteItem().deserialize(item));
      });
    }
    this.entity_id = input.entity_id;
    return this;
  }

  quoteId() {
    return this.entity_id;
  }
}

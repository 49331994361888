import { Serializable } from "./base";

export class TopPicks implements Serializable<TopPicks> {

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private top_picks: number[];

  deserialize(input: any): TopPicks {
    this.top_picks = input.top_picks;

    return this;
  }

  get productIDs(): number[] {
    return this.top_picks;
  }

}

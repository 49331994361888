import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import {
  GenerateWeeklyTripSheetResponse,
  WeeklyTripSheet,
} from "@tendercuts/models";
import {
  WeeklyTripSheetConfirmService,
  WeeklyTripSheetUpdateService,
} from "@tendercuts/providers";
import { skipWhile, take } from "rxjs/operators";
import { BasePage } from "src/app/utils";
import { ApproveWeeklyTripsheetComponent } from "../approve-weekly-tripsheet";
import { TripSheetConfirmComponent } from "../trip-sheet-confirm";

/**
 * component to show weekly trip sheet action
 */
@Component({
  selector: "app-weekly-trip-sheet-actions",
  templateUrl: "./weekly-trip-sheet-actions.component.html",
  styleUrls: ["./weekly-trip-sheet-actions.component.scss"],
})
export class WeeklyTripSheetActionsComponent extends BasePage {
  @Input()
  weeklyTripSheet: WeeklyTripSheet;

  // mode to diff actions
  @Input()
  isDeliveryManagerMode: boolean = false;

  // close the dialog and reload the parent
  @Output()
  public reloadParent: EventEmitter<WeeklyTripSheet> = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    private weeklyTripSheetService: WeeklyTripSheetUpdateService,
    private weeklyTripSheetConfirm: WeeklyTripSheetConfirmService,
  ) {
    super(dialog, snackBar, store);
  }

  /**
   * weekly trip sheet update in sm level(Confirm button)
   */
  updateWeeklyTripSheet(): void {
    const onSuccess: (result: any) => void = (result) => {
      if (!result) {
        return;
      }

      this.presentLoader();
      const params: {
        status: number;
        id: number;
      } = this.weeklyTripSheetConfirm.getConfirmParams(this.weeklyTripSheet.id);
      this.weeklyTripsheetUpdate(params);
    };

    this.optionsAlert(
      "Are you sure?",
      "you want to confirm this weekly tripsheet",
      onSuccess,
    );
  }

  /**
   * hit the service with params
   * @param params
   */
  weeklyTripsheetUpdate(params: {
    status: number;
    id: number;
}): void {
    this.weeklyTripSheetConfirm.getData(params).subscribe(
      (responseData: WeeklyTripSheet[]) => {
        this.dismissLoader();
        if (!responseData.length) {
          return;
        }

        this.reloadParent.emit(responseData[0]);
        this.showNotification(
          "bottom",
          "center",
          "success",
          "info-circle",
          "Weekly Trip Sheet Updated",
        );
      },
      (error) => {
        this.dismissLoader();
        this.somethingWentWrong();
      },
    );
  }

  /**
   * update the weekly trip sheet in sm level(To Approve button)
   */
  toApproveWeeklyTripSheet(): void {
    const dialogRef: MatDialogRef<
      TripSheetConfirmComponent,
      any
    > = this.dialog.open(TripSheetConfirmComponent, {
      width: "350px",
      panelClass: "remove-padding",
      data: this.weeklyTripSheet,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data && data.status) {
        this.presentLoader();
        const params: {
          status: number;
          request_incentive: boolean;
          id: number;
          comment: string;
        } = this.weeklyTripSheetConfirm.getApproveParams(
          this.weeklyTripSheet.id,
          data.comment,
        );
        this.weeklyTripsheetUpdate(params);
      }
    });
  }

  /**
   * check raise issue and confirm actions
   */
  get canShowSMActions(): boolean {
    return this.weeklyTripSheet.status == 0;
  }

  /**
   * check raise issue and confirm actions
   */
  get canShowDMActions(): boolean {
    return this.weeklyTripSheet.status == 1 && this.isDeliveryManagerMode;
  }

  /**
   * Approve the trip sheet in DM level(Approve button)
   */
  approveWeeklyTripSheet(): void {
    const data: {
      isRejectMode: boolean;
      weeklyTripSheet: WeeklyTripSheet;
    } = { isRejectMode: false, weeklyTripSheet: this.weeklyTripSheet };
    const dialogRef: MatDialogRef<
      ApproveWeeklyTripsheetComponent,
      any
    > = this.dialog.open(ApproveWeeklyTripsheetComponent, {
      width: "350px",
      panelClass: "remove-padding",
      data,
    });

    dialogRef.afterClosed().subscribe((weeklytTripSheetData) => {
      if (weeklytTripSheetData && weeklytTripSheetData.status) {
        this.presentLoader();
        const params: {
          sheet_id: number;
          action: string;
          amount: number;
          comment: string;
        } = this.weeklyTripSheetService.getApproveRequest(
          this.weeklyTripSheet.id,
          weeklytTripSheetData.incentive,
          weeklytTripSheetData.comment,
        );
        this.weeklyTripsheetConfirm(params);
      }
    });
  }

  /**
   * Reject the trip sheet in DM level(Reject button)
   */
  rejectWeeklyTripSheet(): void {
    const data: {
      isRejectMode: boolean;
      weeklyTripSheet: WeeklyTripSheet;
    } = { isRejectMode: true, weeklyTripSheet: this.weeklyTripSheet };
    const dialogRef: MatDialogRef<
      ApproveWeeklyTripsheetComponent,
      any
    > = this.dialog.open(ApproveWeeklyTripsheetComponent, {
      width: "350px",
      panelClass: "remove-padding",
      data,
    });

    dialogRef.afterClosed().subscribe((weeklytTripSheetData) => {
      if (weeklytTripSheetData && weeklytTripSheetData.status) {
        this.presentLoader();
        const params: {
          sheet_id: number;
          action: string;
          comment: string;
        } = this.weeklyTripSheetService.getRejectRequest(
          this.weeklyTripSheet.id,
          weeklytTripSheetData.comment,
        );
        this.weeklyTripsheetConfirm(params);
      }
    });
  }

  /**
   * hit the service with the params
   * @param params
   */
  weeklyTripsheetConfirm(params: {
    sheet_id: number;
    action: string;
    amount?: number;
    comment: string;
}): void {
    this.weeklyTripSheetService.getData(params).subscribe(
      (responseData: GenerateWeeklyTripSheetResponse[]) => {
        this.dismissLoader();
        if (responseData[0].status) {
          this.reloadParent.emit(responseData[0].result);

          return;
        }

        this.showNotification(
          "bottom",
          "center",
          "danger",
          "info-circle",
          responseData[0].message,
        );
      },
      (error) => {
        this.dismissLoader();
        this.somethingWentWrong();
      },
    );
  }
}

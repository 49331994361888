<div *ngIf="tripStatusHistory.length" class="row progress-comments">
  <div class="col-12">
    <mat-card class="row separator no-gutters">
      <mat-card-title><h5>Trip Progress</h5></mat-card-title>
      <mat-card-content>
        <ng-container
          *ngFor="let history of tripStatusHistory; let last = last"
        >
          <div class="d-flex flex-row py-1 align-items-center status">
            <p class="status-number">
              <b> {{ history.tripStatus }}</b>
            </p>
            <p class="m-0">
              <b> {{ history.statusCode }} </b>({{
                history.createdAt | date: "MMM d,h:mm a"
              }}
              )<br />
            </p>
          </div>
          <div class="line" *ngIf="!last"></div>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </div>
</div>

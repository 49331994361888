import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { Trips } from "@tendercuts/models";

@Component({
  selector: "app-trip-detail-invoice",
  templateUrl: "./trip-detail-invoice.html",
  styleUrls: ["./trip-detail-invoice.scss"],
})
export class TripDetailInvoiceComponent {
  @Input() trip: Trips;
  constructor() {}
}

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";

import { BaseComponent } from "../../utils";

@Component({
  selector: "app-store-marker",
  templateUrl: "./store-marker.component.html",
  styleUrls: ["./store-marker.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoreMarkerComponent extends BaseComponent implements OnInit {
  @Input() storeObj: any;
  @Input() showBoundary: boolean = true;
  @Input() showMarker: boolean = true;

  constructor(public store: Store<AppState>) {
    super(store);
  }

  ngOnInit(): void {}

  parse(str: string): number  {
    return parseFloat(str);
  }

  get polygonPath(): any[] {
    const pathString: string[] = this.storeObj.pathString.split(",");
    const latLngs: any[] = [];
    for (let i: number = 0; i < pathString.length; i = i + 2) {
      latLngs.push({
        lat: this.parse(pathString[i]),
        lng: this.parse(pathString[i + 1]),
      });
    }

    return latLngs;
  }

  formatLabel(): {
    color: string;
    text: any;
} {
    return {
      color: "#fff",
      text: this.storeObj?.storeAttribute?.displayName || this.storeObj?.name,
    };
  }
}

import { Injectable } from "@angular/core";

import { BaseGetProvider, DataTransformationProtocol } from "@tendercuts/http";
import { WalletTransaction } from "@tendercuts/models";

import { HttpClient, HttpParams } from "@angular/common/http";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";

@Injectable()
export class WalletTransactionService
  extends BaseGetProvider<WalletTransaction>
  implements DataTransformationProtocol {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endPointService: EndPoint
  ) {
    super(http);
    this.delegate = this;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }
  get authProtocol(): LoginServices {
    return this.loginService;
  }

  /**
   * disable caching
   */
  get isCachable(): boolean {
    return false;
  }

  /**
   * Hook to process response at the beginning of the pipeline
   */
  preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  /**
   * Model to render data as in process step.
   */
  get model(): typeof WalletTransaction {
    return WalletTransaction;
  }

  /**
   * Endpoint to hit the service
   */
  get endpoint(): string {
    return this.endPointService.walletTransaction();
  }

  getParams(customerId: string): HttpParams {
    let params: HttpParams = new HttpParams();
    params = params.append("customer_id", customerId.toString());

    return params;
  }
}

<mat-progress-bar *ngIf="loading" mode="indeterminate"> </mat-progress-bar>

<div class="selection" *ngIf="!selectedDriver; else showConfirmation">
  <div class="row header">
    <div class="col-6">
      <span><b> Assign Driver </b></span>
    </div>
    <div *ngIf="!loading" class="col-6 assign-btn">
      <button
        mat-stroked-button
        color="primary"
        id="trip-assign"
        (click)="assignTrip()"
      >
        Assign Trip
      </button>
    </div>
  </div>
  <div class="drivers-content">
    <div class="rider-search">
      <mat-form-field>
        <input
          type="text"
          id="rider-detail"
          placeholder="Search Rider Name / No"
          matInput
          [formControl]="driverControl"
          autocomplete="off"
        />
      </mat-form-field>
    </div>
    <div class="available-riders">
      <p *ngIf="filteredOptions.length; else showEmptyMessage">Store Riders</p>
      <ng-template #showEmptyMessage>
        <p class="available-riders__empty-text">Currently no active riders are available in store.</p>
      </ng-template>
      <div class="drivers">
        <mat-radio-group
          [formControl]="driverSelectionControl"
          labelPosition="before"
        >
          <mat-radio-button
            class="row radio-btn"
            *ngFor="let driver of filteredOptions"
            [color]="primary"
            [disabled]="driver.isDriverOnTrip"
            [value]="driver"
          >
            <app-store-rider [driver]="driver"></app-store-rider>
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
</div>

<ng-template #showConfirmation>
  <div class="trip-assign">
    <p *ngIf="selectedDriver">
      <b> Are you sure to assign this trip to</b>
      <app-store-rider [driver]="selectedDriver"></app-store-rider>
    </p>
    <div>
      <!-- Show only for dummy rider -->
      <app-driver-tag
        id="trip-tag"
        *ngIf="selectedDriver.id === -1"
      ></app-driver-tag>
      <div class="actions">
        <button
          mat-raised-button
          color="primary"
          id="no"
          (click)="closeDialog()"
        >
          NO
        </button>
        <button
          mat-raised-button
          color="primary"
          class="assign-btn"
          id="yes"
          [disabled]="loading"
          (click)="assignToRider()"
        >
          YES
        </button>
      </div>
    </div>
  </div>
</ng-template>

<table mat-table [dataSource]="orders" class="mat-elevation-z8">
  <ng-container *ngFor="let column of columnsToDisplay" [matColumnDef]="column">
    <th mat-header-cell *matHeaderCellDef>
      {{ titleToDisplay[columnsToDisplay.indexOf(column)] }}
    </th>
    <td mat-cell *matCellDef="let order" onclick="">
      <ng-container
        *ngIf="column !== 'status' && column !== 'total'; else template"
      >
        {{ order[column] }}
      </ng-container>
      <ng-template #template>
        <ng-container *ngIf="column === 'status'; else totalTemplate">
          <span class="status" [ngClass]="getStatusClass('', order)">
            {{ order[column] }}
          </span>
        </ng-container>
        <ng-template #totalTemplate>
          <span class="total-amount">
            {{ order.total | currency : "INR" : "symbol" : "1.2" }}
          </span>
        </ng-template>
      </ng-template>
    </td>
  </ng-container>

  <tr
    mat-header-row
    *matHeaderRowDef="columnsToDisplay"
    class="mat-header-sticky"
  ></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: columnsToDisplay; let index = index"
    (click)="onRowClick(index)"
  ></tr>
</table>

import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  ProductSelectionComponent,
  QuantityDialogComponent,
} from "./components";
import { CustomCommonModule } from "./customCommon.module";
import { SharedModule } from "./shared/shared.module";
import { UtilsModule } from "./utils/utils.module";

@NgModule({
    declarations: [ProductSelectionComponent, QuantityDialogComponent],
    imports: [
        SharedModule,
        CommonModule,
        UtilsModule,
        CustomCommonModule,
        MatDialogModule,
        BrowserModule,
        MatFormFieldModule,
        MatInputModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: []
})
export class ProductSelectionModule {}

import { AgmMap } from "@agm/core";
import { HttpParams } from "@angular/common/http";
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { LocationPing, SaleOrder } from "@tendercuts/models";
import {
  FetchOrdersDetailsService,
  RealtimeTrackService,
} from "@tendercuts/providers";
import { defer, MonoTypeOperatorFunction, Observable, Subscription } from "rxjs";
import { delay, map, repeatWhen } from "rxjs/operators";
import { BasePage } from "../../utils";

@Component({
  selector: "app-live-trac",
  templateUrl: "./live-track-debug.component.html",
  styleUrls: ["./live-track-debug.component.scss"],
})
export class LiveTrackDebugComponent
  extends BasePage
  implements AfterViewInit, OnDestroy, OnInit {
  // components for the lat lng routing display.
  mapRawRef: any;
  latLngs: string;
  polyline: any;
  pings: LocationPing[] = [];
  // end

  @ViewChild("map") map: AgmMap;

  orderNumber: string;
  order: SaleOrder;
  zoom: number = 12;
  center: number[] = [13.0827, 80.2707];

  origin: any = null;
  destination: any = null;
  distance: any = null;
  time: any = null;

  retryInterval$: MonoTypeOperatorFunction<unknown> = repeatWhen((e) =>
    e.pipe(delay(1000 * 20)),
  ); // 20 secs
  fetchOrderSubscription$: Subscription;
  oLat: number;
  oLng: number;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public orderService: FetchOrdersDetailsService,
    public route: ActivatedRoute,
    public liveTrack: RealtimeTrackService,
    private title: Title,
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    this.title.setTitle("Live Track");
  }

  ngAfterViewInit(): void {
    const styles: ({
      featureType: string;
      elementType: string;
      stylers: {
          saturation: number;
      }[];
  } | {
      featureType: string;
      elementType: string;
      stylers: {
          visibility: string;
      }[];
  } | {
      featureType: string;
      elementType: string;
      stylers: {
        color: string;
      }[];
  })[] = [
      {
        featureType: "landscape.man_made",
        elementType: "geometry.fill",
        stylers: [{ saturation: -100 }],
      },
      {
        featureType: "landscape.man_made",
        elementType: "geometry.stroke",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi",
        elementType: "labels.icon",
        stylers: [{ color: "#89919e" }],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#89919e" }],
      },
      {
        featureType: "poi",
        elementType: "labels.text.stroke",
        stylers: [{ visibility: "off" }],
      },
    ];

    this.map.mapReady.subscribe((liveTrackMap) => {
      liveTrackMap.setOptions({ styles });
      this.mapRawRef = liveTrackMap;
    });
  }

  ngOnDestroy(): void {
    if (this.fetchOrderSubscription$) {
      this.fetchOrderSubscription$.unsubscribe();
    }
  }

  /**
   * Triggers a server request to find out if we have any new orders.
   * if we have - we just play a sound and display the new orders received.
   * private
   */
  private async _refreshOrder(orderId: string): Promise<any> {
    const params: HttpParams = this.liveTrack.getParams(orderId);

    return await this.liveTrack
      .getData(params)
      .pipe(
        map((location) => {
          this.oLat = +location[0].latitude;
          this.oLng = +location[0].longitude;

          return location;
        }),
      )
      .toPromise();
  }

  resolveUrl(): void {
    this.route.fragment.subscribe((fragment: string) => {
      if (!fragment) {
        return;
      }

      const components: string[] = fragment.split("=");
      if (components.length != 2) {
        return;
      }

      this.orderNumber = components[1];
      this.resolveOrder();
    });
  }

  /**
   * Resolves the the order to SaleOrder object.
   * @param increment_id
   */
  resolveOrder(): void {
    this.origin = this.destination = null;

    const params: {
      order_id: string;
  } = this.orderService.getParams(this.orderNumber);
    this.presentLoader();
    this.orderService.getData(params).subscribe((orders: SaleOrder[]) => {
      this.order = orders[0];
      this.center = [
        this.order.shippingAddress.Olatitude,
        this.order.shippingAddress.Olongitude,
      ];
      this.destination = {
        lat: this.center[0],
        lng: this.center[1],
      };
      this.dismissLoader();
    });

    // clean up older pings
    if (this.fetchOrderSubscription$) {
      this.fetchOrderSubscription$.unsubscribe();
    }

    const refresh$: Observable<unknown> = defer(() =>
      this._refreshOrder(this.orderNumber),
    ).pipe(this.retryInterval$);

    this.fetchOrderSubscription$ = refresh$.subscribe(
      () => {},
      (err) => {
        // TODO
        // eslint-disable-next-line no-console
        console.log(err);
      },
    );
  }

  generateRouteLine(): void {
    const lineSymbol: {
      path: google.maps.SymbolPath;
    } = {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    };
    // Extract points in google format.
    const points: any[] = [];
    const latLngs: string[] = this.latLngs.split(",");

    const N: 2 = 2;
    for (let i: number = 0; i < latLngs.length; i += N) {
      // Do your work with array[i], array[i+1]...array[i+N-1]
      const location: {
        lat: number;
        lng: number;
      } = {
        lat: parseFloat(latLngs[i]),
        lng: parseFloat(latLngs[i + 1]),
      };
      points.push(location);
      this.pings.push(new LocationPing().deserialize(location));
    }

    // draw the direction line
    this.polyline = new google.maps.Polyline({
      path: points,
      strokeColor: "red",
      strokeWeight: 2,
      strokeOpacity: 0.5,
      geodesic: true,
      icons: [
        {
          icon: lineSymbol,
          // offset: '100%',
          repeat: "50px",
        },
      ],
    });
    this.polyline.setMap(this.mapRawRef);
  }
}

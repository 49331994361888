import { Injectable } from '@angular/core';

import { BaseGetProvider, DataTransformationProtocol } from '@tendercuts/http';
import { Driver } from '@tendercuts/models';

import { HttpClient, HttpParams } from '@angular/common/http';
import { EndPoint } from '@endpoint';
import { LoginServices } from '@login';

@Injectable()
export class FetchDriversService extends BaseGetProvider<Driver>
  implements DataTransformationProtocol {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
    this.delegate = this;
  }

  // override this
  preProcessResponse(response: any): Array<any> {
    return response;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof Driver {
    return Driver;
  }

  get endpoint(): string {
    return this.endpointService.drivers();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(storeId: string): HttpParams {
    let params: HttpParams = new HttpParams();
    params = params.append('store_id', storeId);

    return params;
  }
}

@Injectable()
export class FetchEveryDriversService extends BaseGetProvider<Driver>
  implements DataTransformationProtocol {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
    this.delegate = this;
  }

  // override this
  preProcessResponse(response: any): Array<any> {
    return response;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof Driver {
    return Driver;
  }

  get endpoint(): string {
    return this.endpointService.everyDrivers();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(storeId: string): HttpParams {
    let params: HttpParams = new HttpParams();
    params = params.append('store_id', storeId);

    return params;
  }
}

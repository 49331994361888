import { Serializable } from './base';
export class Certificate implements Serializable<Certificate> {
    public category_name: string;
    public image_url: string;
    public name: string;
    public id: number;
    constructor() { }

    deserialize(input) {
        this.category_name = input.category_name;
        this.image_url = input.image_url;
        this.name = input.name;
        this.id = input.id;
        return this;
    }
}

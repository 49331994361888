<div class="row elite" *ngIf="eliteSubscription?.isActive">
  <mat-card class="elite__card col-12">
    <mat-card-content class="elite__card__content">
      <div class="row elite__card__content__row no-gutters">
        <div class="elite__card__content__row__status col-1">Elite</div>
        <div class="col-3">
          <span class="elite__card__content__row__field col-1"
            >Start Date
          </span>
          <span class="elite__card__content__row__value col-2">{{
            eliteSubscription.startedDate | date: "medium"
          }}</span>
        </div>
        <div class="col-3">
          <span class="elite__card__content__row__field col-1">End Date </span>
          <span class="elite__card__content__row__value col-2">{{
            eliteSubscription.endDate | date: "medium"
          }}</span>
        </div>
        <div class="col-3">
          <span class="elite__card__content__row__field col-1"
            >Free Orders
          </span>
          <span class="elite__card__content__row__value col-2">{{
            eliteSubscription.freeOrderCount
          }}</span>
        </div>
        <div class="col-2">
          <span class="elite__card__content__row__field col-1">Savings </span>
          <span class="elite__card__content__row__value col-1">{{
            eliteSubscription.amountSaved
          }}</span>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

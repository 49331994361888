<mat-paginator
  #paginator
  [length]="1000"
  [pageSizeOptions]="[30, 50, 100]"
  *ngIf="showPagination"
></mat-paginator>

<table
  mat-table
  [dataSource]="filterModelDataSource"
  matSort
  multiTemplateDataRows
>
  <ng-container
    *ngFor="let col of modelColumns; let i = index"
    matColumnDef="{{ col }}"
  >
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span *ngIf="!isSelectionColumn(col); else showSelection">
        {{ getTitle(col, i) }}
      </span>
      <ng-template #showSelection>
        <mat-checkbox
          id="parent-chk-box"
          (change)="$event ? filterModelDataSource.masterToggle() : null"
          [checked]="
            filterModelDataSource.selection.hasValue() &&
            filterModelDataSource.isAllSelected()
          "
          [indeterminate]="
            filterModelDataSource.selection.hasValue() &&
            !filterModelDataSource.isAllSelected()
          "
        >
        </mat-checkbox>
      </ng-template>
    </th>

    <td mat-cell *matCellDef="let model">
      <span *ngIf="!isSelectionColumn(col); else showSelection">{{
        getValue(col, model)
      }}</span>
      <ng-template #showSelection>
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="
            $event ? filterModelDataSource.selection.toggle(model) : null
          "
          [checked]="filterModelDataSource.selection.isSelected(model)"
          [disabled]="isCheckBoxDisable(col, model)"
        >
        </mat-checkbox>
      </ng-template>
    </td>
    <td mat-footer-cell *matFooterCellDef>{{ getColumnTotal(col) }}</td>
  </ng-container>

  <!-- action col -->
  <ng-container *ngFor="let action of actions" matColumnDef="{{ action.key }}">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ action.name }}
    </th>

    <td mat-cell *matCellDef="let model">
      <ng-container [ngSwitch]="action.type">
        <ng-container *ngSwitchCase="'timer'">
          <div>{{ showTimeValue(action.key, model) | async | timerPipe }}</div>
        </ng-container>
        <ng-container *ngSwitchCase="'currency'">
          <div>
            {{ getValue(action.key, model) | currency: "INR":symbol:"1.0-0" }}
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'date'">
          <div>
            {{
              getValue(action.key, model)
                | date: triggerCallback("", action, model)
            }}
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'button'">
          <button
            mat-stroked-button
            color="primary"
            (click)="triggerCallback($event, action, model)"
          >
            {{ action.name }}
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="'number'">
          <input
            matInput
            maxlength="5"
            placeholder="{{ action.name }}"
            type="number"
            (change)="triggerCallback($event, action, model)"
          />
        </ng-container>
        <ng-container *ngSwitchCase="'link'">
          <a
            class="hyper-link"
            (click)="triggerCallback($event, action, model)"
            >{{ getValue(action.key, model) }}</a
          >
        </ng-container>
        <ng-container *ngSwitchCase="'chip'">
          <mat-chip class="mat-chip" [ngClass]="triggerCallback('', action, model)">{{
            getValue(action.key, model)
          }}</mat-chip>
        </ng-container>
        <ng-container *ngSwitchCase="'icon'">
          <i [class]="triggerCallback('', action, model)"></i>
          <span *ngIf="!canShowIconText(action.key, model)">{{
            getValue(action.key, model)
          }}</span>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <input
            matInput
            maxlength="1"
            placeholder="{{ action.name }}"
            type="tel"
            (change)="triggerCallback($event, action, model)"
          />
        </ng-container>
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef>{{ getColumnTotal(action.key) }}</td>
  </ng-container>

  <!--  Sub row 1 -->
  <ng-container matColumnDef="detailRow">
    <td
      mat-cell
      *matCellDef="let model; let i = index"
      [attr.colspan]="columns.length"
    >
      <app-grid-row-renderer
        [model]="model"
        [component]="baseGridDetailComponent"
      ></app-grid-row-renderer>
    </td>
  </ng-container>

  <tr mat-header-row class="row-header" *matHeaderRowDef="columns"></tr>
  <tr
    mat-row
    [style.backgroundColor]="triggerRowColorRenderer(model)"
    (click)="triggerRowCallback($event, model)"
    [ngClass]="{ 'hide-border': isMultiRowEnabled }"
    *matRowDef="let model; columns: columns"
  ></tr>
  <ng-container *ngIf="isMultiRowEnabled">
    <tr
      mat-row
      [style.backgroundColor]="triggerRowColorRenderer(model)"
      *matRowDef="let model; columns: ['detailRow']"
      class="example-detail-row"
    ></tr>
  </ng-container>
  <ng-container *ngIf="footerColumnsToDisplay.length">
    <tr mat-footer-row *matFooterRowDef="columns"></tr>
  </ng-container>
</table>

<div class="booking_status">
  <div class="row booking_status__header">
    <mat-card align="left" class="booking_status__header__search_card col-7">
      <mat-card-title>
        <mat-form-field class="booking_status__header__search_card__search_bar">
          <input
            matInput
            id="searchbar"
            (keyup)="applyDriverSearchFilter($event.target.value)"
            placeholder="Search Driver Name / Phone"
            #searchbar
          />
        </mat-form-field>
      </mat-card-title>

      <mat-card-content>
        <mat-chip-list [multiple]="true" [selectable]="true">
          <div class="booking_status__header__search_card__chip_border">
            <mat-chip
              class="booking_status__header__search_card__chip_border__chip"
              (click)="
                modelFilter.clearAll();
                bookingDataSource.filter = '';
                searchbar.value = ''
              "
            >
              Clear
            </mat-chip>
          </div>

          <div
            class="booking_status__header__search_card__chip_border"
            *ngFor="let filterGroup of modelFilter.filterGroups"
          >
            <mat-chip
              class="booking_status__header__search_card__chip_border__mat_chip"
              *ngFor="let filter of filterGroup.filters"
              (click)="getFilteredData(filter)"
              [selected]="filter.selected"
            >
              {{ filter.displayName }}
            </mat-chip>
          </div>
        </mat-chip-list>
      </mat-card-content>
    </mat-card>

    <mat-card align="left" class="booking_status__header__card_slot col-2">
      <mat-card-title class="booking_status__header__card_slot__slot_bar">
        <div>Slot Availability</div>
      </mat-card-title>
      <mat-card-content>
        <div
          class="booking_status__header__card_slot__count_display"
          *ngFor="let count of acceptedCount"
        >
          <div
            class="booking_status__header__card_slot__count_display__shift row"
          >
            <div
              class="booking_status__header__card_slot__count_display__shift__first col-6"
            >
              <b>First Shift</b>
              <div class="row">
                <div
                  class="booking_status__header__card_slot__count_display__shift__first__data col-12"
                  id="first_shift"
                >
                  <b>{{ firstShiftCount }}/{{ count.firstShiftCount }}</b>
                </div>
              </div>
            </div>
            <div
              class="booking_status__header__card_slot__count_display__shift__second col-6"
              *ngIf="!count.enableDirectFullShift"
            >
              <b>Second Shift</b>
              <div class="row">
                <div
                  class="booking_status__header__card_slot__count_display__shift__second__data col-12"
                  id="second_shift"
                >
                  <b>{{ secondShiftCount }}/{{ count.secondShiftCount }}</b>
                </div>
              </div>
            </div>

            <div
              class="booking_status__header__card_slot__count_display__shift__full col-6"
              *ngIf="count.enableDirectFullShift"
            >
              <b>Full Shift</b>
              <div class="row">
                <div
                  class="booking_status__header__card_slot__count_display__shift__full__data col-12"
                  id="full_shift"
                >
                  <b>{{ fullShiftCount }}/{{ count.fullShiftCount }}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card
      align="right"
      class="booking_status__header__driver_availability col-2"
    >
      <mat-card-title
        class="booking_status__header__driver_availability__slot_bar"
      >
        <div>Driver Attendance</div>
      </mat-card-title>
      <mat-card-content>
        <div class="booking_status__header__driver_availability__count_display">
          <p>
            <b>{{ driverPresentCount }}/{{ confirmDriverCount }}</b>
          </p>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="row no-gutters driver">
  <app-booking-request-wrapper
    [dataSource]="bookingDataSource"
    [isRedirectToCrm]="isRedirectToCrm"
    class="driver__booking_display"
    (refreshParent)="refreshParent($event)"
  >
  </app-booking-request-wrapper>
</div>

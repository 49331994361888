import { Component, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatRadioChange } from "@angular/material/radio";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { DriverProfile } from "@tendercuts/models";
import { WeekendDriverService } from "@tendercuts/providers";
import { BasePage } from "src/app/utils";

/*
 * dialog is used to the change work location
 */
@Component({
  // TODO
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "driver-profile-update",
  templateUrl: "./driver-profile-updates.html",
  styleUrls: ["./driver-profile-updates.scss"],
})
export class DriverProfileUpdateComponent extends BasePage {
  driver: DriverProfile;
  availableStores: string;
  isWeekEndRider: boolean;
  isFreeLancer: boolean;

  constructor(
    public dialogRef: MatDialogRef<DriverProfileUpdateComponent>,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public weekendDriverService: WeekendDriverService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialog, snackBar, store);
    if (data) {
      this.driver = data.driver;
    }
  }

  /**
   * assign the selected value to weekend rider
   */
  markAsWeekendRider(event: MatRadioChange): void {
    if (event.value != null) {
      this.isWeekEndRider = event.value;
    }
  }

  /**
   * * assign the selected value to external rider
   */
  markAsExternalRider(event: MatRadioChange): void {
    if (event.value != null) {
      this.isFreeLancer = event.value;
    }
  }

  /**
   * update the rider profile in driver crm
   * if the values selected it will assign that values
   * else it will take the previous state of the rider
   */
  updateRiderProfile(): void {
    this.isWeekEndRider =
      typeof this.isWeekEndRider !== "undefined"
        ? this.isWeekEndRider
        : this.driver.profile.isWeekendRider;
    this.isFreeLancer =
      typeof this.isFreeLancer !== "undefined"
        ? this.isFreeLancer
        : this.driver.profile.isFreelancer;
    const params: FormData = this.weekendDriverService.getDriverUpdateProfileParams(
      this.driver.driverId,
      this.driver.driverProfileId,
      this.isFreeLancer,
      this.isWeekEndRider,
    );
    this.dialogRef.close(params);
    this.weekendDriverService.getData(params).subscribe(
      (responseData) => {
        this.showNotification(
          responseData[0].status ? "top" : "bottom",
          "center",
          responseData[0].status ? "success" : "danger",
          "info-circle",
          responseData[0].message,
        );
      },
      (error) => this.somethingWentWrong(),
    );
  }
}

import { HttpErrorResponse } from "@angular/common/http";
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { DriverState } from "@store/driver";
import { AppState } from "@store/state";
import { Driver } from "@tendercuts/models";
import { AssignDriverService, CreateOptimalTrip } from "@tendercuts/providers";
import { Subscription } from "rxjs";
import { debounceTime, map, take } from "rxjs/operators";
import { DriverTagComponent } from "../../components/driver-tag/driver-tag.component";
import { BaseComponent } from "../../utils";
import { BasePage } from "../../utils/pages/base/base.component";

@Component({
  selector: "app-driver-selection",
  templateUrl: "./driver-selection.component.html",
  styleUrls: ["./driver-selection.component.scss"],
})
export class DriverSelectionComponent
  extends BasePage
  implements OnInit, OnDestroy {
  driverControl: FormControl = new FormControl();
  driverSelectionControl: FormControl = new FormControl();
  options: Driver[] = [];
  selectedDriver: Driver;
  loading: boolean = false;
  filteredOptions: Driver[] = [];
  orders: string[];
  savedTripsMode: boolean = false;
  oldDriverId: number = null;
  tripLabel: string = null;
  tripId: number;
  driverFilterSubscrption: Subscription;
  @ViewChild(DriverTagComponent) driverTag: any;

  constructor(
    public dialogRef: MatDialogRef<DriverSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public store: Store<AppState>,
    public createOptimalTrip: CreateOptimalTrip,
    public asssignDriverService: AssignDriverService,
    public renderer: Renderer2,
    public changeDetectorRef: ChangeDetectorRef,
    public matDialog: MatDialog,
    public snackBar: MatSnackBar
  ) {
    super(matDialog, snackBar, store);
    this.orders = data.orders;
    this.savedTripsMode = data.savedTripsMode;
    this.tripId = data.tripId;
    this.oldDriverId = data.oldDriverId;
    this.selectedDriver = data.selectedDriver;
  }

  /**
   * 0. While creating a trip, it does not fiter any driver and shows all available drivers
   * 1. While re-assigning a trip, it ignores the dummy driver and old driver. And shows
   *   all other available drivers.
   * @param drivers
   */

  filterDrivers(drivers: Driver[]): void {
    this.options = drivers;
    const ignoreAbleDrivers: number[] = [];
    if (this.oldDriverId) {
      ignoreAbleDrivers.push(this.oldDriverId);
    }
    this.options = this.options.filter(
      (driver: Driver) =>
        ignoreAbleDrivers.indexOf(driver.id) === -1
    );
    this.filteredOptions = this.options;
  }

  /**
   * Fetch the drivers from the driverState
   * filter the drivers list based on input given
   */
  ngOnInit(): void {
    this.driverState.pipe(take(1)).subscribe((state: DriverState) => {
      this.filterDrivers(state.drivers);
    });
    this.driverFilterSubscrption = this.driverControl.valueChanges
      .pipe(debounceTime(100))
      .subscribe((value) => {
        this.filteredOptions = this.driverFilter(value);
      });
    this.setFocus("#rider-detail");
  }

  /**
   * based on filterValue filter the driver list
   * @param filterValue
   */
  private driverFilter(filterValue: string): Driver[] {
    return this.options.filter(
      (driver) =>
        driver.name.toLowerCase().includes(filterValue) ||
        driver.phone.includes(filterValue)
    );
  }

  /**
   * set the mouse pointer on the item
   * @param itemToFocus
   */
  setFocus(itemToFocus: string): void {
    this.changeDetectorRef.detectChanges();
    const element: any = this.renderer.selectRootElement(itemToFocus);
    setTimeout(() => element.focus(), 300);
  }

  /**
   * driver is not valid throw an error meassege
   * driver is valid get the driver into selectedDriver
   * hide the driver details and showing the
   * confirmation popup
   */
  assignTrip(): void {
    if (this._isValidDriver === false) {
      alert("Select a valid driver");

      return;
    }

    this.selectedDriver = this.driverSelectionControl.value;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  assignDriver(driverUser: number): void {
    const canReassignDriver: boolean = this.oldDriverId ? true : false;
    const params: {
      driver_user: number;
      trip_id: number;
      can_reassign_driver: boolean;
    } = this.asssignDriverService.getParams(
      driverUser,
      this.tripId,
      canReassignDriver
    );
    this.asssignDriverService.getData(params).subscribe(
      (data) => {
        this.dialogRef.close(data[0]);
      },
      (err) => this.somethingWentWrong()
    );
  }

  assignToRider(): void {
    if (this.driverTag) {
      this.tripLabel = this.driverTag.driverLabelControl.value;
    }

    if (this.savedTripsMode || this.oldDriverId) {
      this.assignDriver(this.selectedDriver.id);

      return;
    }

    const data: {
      driver_user: number;
      auto_assigned: boolean;
      driver_order: string[];
      driver_tag: string;
    } = {
      driver_user:
        this.selectedDriver.id === -1 ? null : this.selectedDriver.id,
      auto_assigned: true,
      driver_order: this.orders,
      driver_tag: this.selectedDriver.id === -1 ? this.tripLabel : null,
    };
    this.loading = true;

    this.createOptimalTrip.getData(data).subscribe(
      (result) => {
        this.dialogRef.close({ status: true, trip: result[0] });
      },
      (errorRes: HttpErrorResponse) => {
        this.loading = false;
        this.showNotification(
          "top",
          "center",
          "danger",
          "info-circle",
          errorRes.error.message
        );
        this.closeDialog();
      }
    );
  }

  get _isValidDriver(): boolean {
    if (this.driverSelectionControl.value instanceof Driver === true) {
      return true;
    }

    return false;
  }

  ngOnDestroy(): void {
    this.driverFilterSubscrption.unsubscribe();
  }
}

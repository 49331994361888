import {
  forwardRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { map, take } from "rxjs/operators";

import { AgmMap } from "@agm/core";
import { AppState } from "@store/state";
import {
  Driver,
  GenericServerResponse,
  OptimalTrip,
  SaleOrder,
  StoreSegment,
  Trips,
} from "@tendercuts/models";
import { EditTripService, PubSubService } from "@tendercuts/providers";
import * as $ from "jquery";
import { Observable } from "rxjs";
import { Filter } from "src/models";
import { SaleOrderDataSource } from "../../components";
import { DriverSelectionComponent } from "../../components/driver-selection";
import { TripDirectionComponent } from "../../components/trip-direction";
import { BasePage } from "../../utils/pages/base/base.component";
import { FirebaseAnalyticsService } from "src/providers/firebase-analytics/firebase-analytics";

@Component({
  selector: "app-plan-trip",
  templateUrl: "./plan-trip.html",
  styleUrls: ["./plan-trip.scss"],
})
export class PlanTripComponent extends BasePage implements OnInit {
  clickedOrder: SaleOrder;
  hoveredSegment: StoreSegment;
  zoom: number = 13;
  waypointMarkerIcon: string = "assets/marker/location.png";

  // Flag to indicate if driver selection is in progress.
  isSelectionInProgress: boolean = false;

  editTripMode: boolean = false;
  orders: SaleOrderDataSource | any;

  selectedTrip: OptimalTrip;

  segments: StoreSegment[] = [];

  @Output()
  dialogClose: EventEmitter<boolean> = new EventEmitter();

  // TODO
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onTripPlanned: EventEmitter<any> = new EventEmitter();

  @ViewChild("map") map: AgmMap;

  @ViewChild(forwardRef(() => TripDirectionComponent))
  tripDirectionComponent: TripDirectionComponent;
  mapRawRef: any;

  oldTripOrders: SaleOrder[] = [];

  orderMapCopy: any;

  dummyDriver: Driver;

  constructor(
    public editTripService: EditTripService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public events: PubSubService,
    public firebaseAnalyticsService: FirebaseAnalyticsService
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    this.dummyDriver = new Driver().deserialize({
      name: "Dummy Driver",
      id: -1,
      phone: "0000",
    });
    // subscribes to all the properties published at routing dashboard
    this.events.$sub("plan-trip:open").subscribe((data) => {
      this.oldTripOrders = [];
      this.getOrderMap();
      this.editTripMode = data.editMode;
      this.segments = data.segments;
      this.orders = data.dataSource;
      this.selectedTrip = data.selectedTrip;
    });
  }

  async getOrderMap(): Promise<void> {
    this.orderMapCopy = await this.orderState
      .pipe(
        take(1),
        map((orderState) => orderState.orderMap)
      )
      .toPromise();
    if (this.editTripMode) {
      this.saveOldTripOrders();
    }
  }

  saveOldTripOrders(): void {
    this.selectedTrip.orders.forEach((order) => {
      const tripOrder: SaleOrder = this.orderMapCopy[order];
      if (tripOrder !== undefined) {
        this.oldTripOrders.push(tripOrder);
      }
    });
  }

  renderSegment(mapRef: any): void {
    this.mapRawRef = mapRef;
  }

  applyFilters(filter: Filter): void {
    filter.selected = !filter.selected;
    this.orders.filter = "true";
  }

  /**
   * Returns oldTripOrders(only for edit mode) + unassigned orders in processing state
   */
  get availableOrders(): SaleOrder[] {
    let orders: SaleOrder[] = this.oldTripOrders;
    if (this.orders && this.orders.filteredData.length > 0) {
      orders = orders.concat(this.orders.filteredData);
    }
    const nonTripOrders: SaleOrder[] = orders.filter(
      (order) => !this.isTripOrder(order)
    );

    return nonTripOrders;
  }

  /**
   * Trip edits
   */
  showDriverSelection(trip: OptimalTrip, isDummyTrip?: boolean): void {
    let selectedDriver: Driver = null;
    if (isDummyTrip) {
      selectedDriver = this.dummyDriver;
    }
    this.isSelectionInProgress = true;
    const dialogRef: MatDialogRef<DriverSelectionComponent, any> =
      this.dialog.open(DriverSelectionComponent, {
        data: { orders: Array.from(trip.orders), selectedDriver },
      });

    dialogRef.afterClosed().subscribe((resultObj: any) => {
      this.handleDriverSelectionDialogClose(resultObj);
    });
  }

  /**
   * 0. Does nothing when it is manually closed.
   * 1. When closed after creating a dummy trip, closes
   *  the plan trip dialog and publishes the dummy trip
   * 2. When a trip is created with an actual driver then
   *  moves to trip sequencing step
   */
  handleDriverSelectionDialogClose(resultObj: any): void {
    this.isSelectionInProgress = false;
    // closing the drawer if driver is assigned via drawer
    if (!resultObj) {
      // this means that dialog this manually closed
      // no need to perform any action
      return;
    }

    if (!resultObj.status) {
      this.somethingWentWrong();
      this.dialogClose.emit();

      return;
    }

    // dummy trip created
    if (!resultObj.trip.driver_user) {
      this.dialogClose.emit();
      this.events.$pub("dummy-trip:created", resultObj.trip);
      this.showNotification(
        "top",
        "center",
        "success",
        "info-circle",
        "Dummy Trip created"
      );

      return;
    }

    // trip created with an actual driver
    const trips: {
      deltaOrders: SaleOrder[];
      trip: Trips;
    } = { deltaOrders: this.tripOrders, trip: resultObj.trip };
    this.onTripPlanned.emit(trips);
    this.showNotification(
      "top",
      "center",
      "success",
      "info-circle",
      "Trip created, Please Sequence the order"
    );
  }

  /**
   * Updates the trip with km, time received from google api
   * and arranges the orders according to the waypoint order
   * from google direction service
   */
  updateTrip(data: any): void {
    this.selectedTrip.km = data[0];
    this.selectedTrip.time = data[1];
    const spareTrip: string[] = Array.from(this.selectedTrip.orders);
    this.selectedTrip.orders.clear();
    // data[2] is waypoint order response from gmaps
    data[2].forEach((order) => {
      this.selectedTrip.orders.add(spareTrip[order]);
    });
  }

  selectOrders(selectedOrder: SaleOrder): void {
    if (this.selectedTrip.orders.has(selectedOrder.incrementId)) {
      this.removeOrderFromTrip(selectedOrder.incrementId);

      return;
    }
    this.availableOrders.forEach((order: SaleOrder) => {
      // also select same location orders
      if (
        order.shippingAddress.Olatitude ==
          selectedOrder.shippingAddress.Olatitude &&
        order.shippingAddress.Olongitude ==
          selectedOrder.shippingAddress.Olongitude
      ) {
        this.selectedTrip.orders.add(order.incrementId);
      }
    });
  }

  /**
   * Adds order to the trip and renders new route
   * @param orderId [id of the order to be added]
   */
  addOrderToTrip(orderId: string): void {
    let order: SaleOrder = this.orderMapCopy[orderId];
    this.firebaseAnalyticsService.orderAddedToTrip(
      this.selectedTrip.tripId,
      order
    );
    this.selectedTrip.addOrder(orderId);
  }

  /**
   * Removes order from trip and renders new route
   * @param orderId [id of the order to be removed]
   */
  removeOrderFromTrip(orderId: string): void {
    let order: SaleOrder = this.orderMapCopy[orderId];
    this.firebaseAnalyticsService.orderRemovedFromTrip(
      this.selectedTrip.tripId,
      order
    );
    this.selectedTrip.removeOrder(orderId);
  }

  /**
   * Returns all the processing orders that are included in selected trip
   */
  get tripOrders(): SaleOrder[] {
    if (
      this.selectedTrip != null &&
      this.selectedTrip.orders.size > 0 &&
      this.orderMapCopy
    ) {
      const filteredOrders: SaleOrder[] = [];
      this.selectedTrip.orders.forEach((order) => {
        const tripOrder: SaleOrder = this.orderMapCopy[order];
        if (tripOrder !== undefined) {
          filteredOrders.push(tripOrder);
        }
      });

      return filteredOrders;
    }

    return [];
  }

  /**
   * Returns true if order is present in selectedTrip,
   * returns false if not
   * @param order
   */
  isTripOrder(order: SaleOrder): boolean {
    if (
      this.selectedTrip != null &&
      this.selectedTrip.orders.size > 0 &&
      this.selectedTrip.orders.has(order.incrementId)
    ) {
      return true;
    }

    return false;
  }

  /**
   * Map related
   */

  /**
   * Since the marker label can only render text, the styling is quite
   * limited, so we tag a css class to every div and then style it.
   *
   * This method searches the div with content containing Mins and
   * tags it.
   */
  tagLabels(): void {
    // Hack to add class to markers.
    $(document).ready(() => {
      const textNodes: any = $(document)
        .find("div:contains('Mins')")
        .contents()
        .filter(function (): boolean {
          // get only text nodes (3)
          return this.nodeType == 3 && this.textContent.indexOf("Mins") > -1;
        });
      textNodes.parent().addClass("markerLabel");
    });
  }

  askConfirmation(): void {
    const onSuccess: (result: any) => void = (result) => {
      if (!result) {
        return;
      }
      this.firebaseAnalyticsService.editTripServerSync(this.selectedTrip.tripId);
      this.editTrip();
    };

    this.optionsAlert("Are you sure?", "Do you want to continue?", onSuccess);
  }

  /**
   * 0. Closes the plan trip dialog if all orders are removed from trip
   * 1. Goes to next step(sequencing) if there are orders in the edited trip
   */
  editTrip(): void {
    const params: {
      order_ids: string[];
      trip_id: any;
    } = this.editTripService.getParams(this.selectedTrip);
    this.editTripService.getData(params).subscribe(
      (res: GenericServerResponse) => {
        this.showNotification(
          "top",
          "center",
          res[0].status ? "success" : "danger",
          "info-circle",
          res[0].message
        );
        if (!res[0].status) {
          this.firebaseAnalyticsService.editTripFailure(
            this.selectedTrip.tripId, 
            res[0].errMessage
          );
          this.dialogClose.emit();
        } else {
          this.firebaseAnalyticsService.editTripSuccess(
            this.selectedTrip.tripId, 
          );
          const editedTrip: Trips = new Trips().deserialize(res[0].result);
          if (editedTrip.driverOrder.length === 0) {
            this.dialogClose.emit();

            return;
          }
          const trips: {
            deltaOrders: SaleOrder[];
            trip: Trips;
          } = { deltaOrders: this.tripOrders, trip: editedTrip };
          this.onTripPlanned.emit(trips);
        }
      },
      (errorResponse: any) => {
        this.firebaseAnalyticsService.editTripFailure(
          this.selectedTrip.tripId, 
          errorResponse.error.message
        );
        this.showNotification(
          "top",
          "center",
          "danger",
          "info-circle",
          errorResponse.error.message
        );
      }
    );
  }

  /**
   * current store is fetched from base getter selectedStore,
   * if isDriverScanEnabled ie scanMode is true, we show assign & seq rider btn,
   * if isDriverScanEnabled ie scanMode is false, we don't show assign rider & seq btn.
   */
  get canShowAssignRider(): Observable<any> {
    return this.selectedStore.pipe(
      take(1),
      map((store) => {
        return store.storeAttribute.isDriverScanEnabled;
      })
    );
  }
}

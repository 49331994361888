import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TripSheet } from "@tendercuts/models";

/**
 * component to show error for km violation
 */
@Component({
  selector: "app-km-violation-popup",
  templateUrl: "./km-violation-popup.component.html",
  styleUrls: ["./km-violation-popup.component.scss"],
})
export class KmViolationPopupComponent {
  // tripsheet details
  tripSheet: TripSheet;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TripSheet,
    public dialogRef: MatDialogRef<KmViolationPopupComponent>,
  ) {
    if (data) {
      this.tripSheet = data;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { Trips } from "@tendercuts/models";
import { BasePage } from "src/app/utils";
import { CashCollectionDialogComponent } from "../cash-collection-dialog";

@Component({
  selector: "app-trip-validation",
  templateUrl: "./trip-validation.component.html",
  styleUrls: ["./trip-validation.component.scss"],
})
export class TripValidationComponent extends BasePage implements OnInit {
  trip: Trips;

  // form group controller for all fields
  validationForm: FormGroup;

  // flag for show editable checkIn value input tag
  editCheckIn: boolean = false;

  // flag for show editable checkOut value input tag
  editCheckOut: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Trips,
    public dialogRef: MatDialogRef<TripValidationComponent>,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
  ) {
    super(dialog, snackBar, store);
    if (data) {
      this.trip = data;
    }
  }

  ngOnInit(): void {
    this.validationForm = new FormGroup({
      correctedCheckIn: new FormControl(this.trip.checkInValue, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(5),
      ]),
      correctedCheckOut: new FormControl(this.trip.checkOutValue, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(5),
      ]),
    });
    this.trip.updateCheckInValue(this.validationForm.value["correctedCheckIn"]);
    this.trip.updateCheckOutValue(
      this.validationForm.value["correctedCheckOut"],
    );
  }

  /**
   * If editCheckIn is false return true.
   * else true show the confirm popup
   */
  validateCheckIn(): void {
    if (!this.editCheckIn) {
      this.editCheckIn = true;

      return;
    }

    const onSuccess: (result: any) => void = (result) => {
      if (!result) {
        return;
      }

      this.trip.updateCheckInValue(
        this.validationForm.value["correctedCheckIn"],
      );
      this.editCheckIn = false;
    };

    this.optionsAlert(
      "Are you sure?",
      "you want to update the checkIn value",
      onSuccess,
    );
  }

  /**
   * If editCheckOut is false return true.
   * else true show the confirm popup
   */
  validateCheckOut(): void {
    if (!this.editCheckOut) {
      this.editCheckOut = true;

      return;
    }

    const onSuccess: (result: any) => void = (result) => {
      if (!result) {
        return;
      }

      this.trip.updateCheckOutValue(
        this.validationForm.value["correctedCheckOut"],
      );
      this.editCheckOut = false;
    };

    this.optionsAlert(
      "Are you sure?",
      "you want to update the checkout value",
      onSuccess,
    );
  }

  /**
   * checks for speedo meter values. once validated hit the generate trip
   * sheet api
   */
  confirmSpeedoMeter(): void {
    if (
      this.validationForm.value["correctedCheckIn"].toString().length != 5 ||
      this.validationForm.value["correctedCheckOut"].toString().length != 5
    ) {
      this.errorMessage("checkIn/checkOut values should be 5 digits");

      return;
    }

    if (
      this.validationForm.value["correctedCheckIn"] >
        this.validationForm.value["correctedCheckOut"] ||
      this.validationForm.value["correctedCheckOut"] -
        this.validationForm.value["correctedCheckIn"] >
        60
    ) {
      this.errorMessage(
        "checkout value should be less than checkIn value / km travelled not more than 60",
      );

      return;
    }

    const dialogRef: MatDialogRef<
      CashCollectionDialogComponent,
      any
    > = this.dialog.open(CashCollectionDialogComponent, {
      data: this.trip,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((status) => {
      if (status) {
        this.dialogRef.close(true);
      }
    });
  }

  /**
   * error message display
   * @param error
   */
  errorMessage(error: string): void {
    this.showNotification("bottom", "center", "danger", "info-circle", error);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

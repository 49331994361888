import { Component, Inject, Input } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Constants } from "@app/env";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { SaleOrder } from "@tendercuts/models";
import { OrderCloseService } from "@tendercuts/providers";
import { BasePage } from "../../utils/pages/base/base.component";

@Component({
  // TODO
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "app-closeOrder",
  templateUrl: "./order-close.component.html",
  styleUrls: ["./order-close.component.scss"],
})
export class CloseOrderComponent extends BasePage {
  @Input() order: SaleOrder;

  constructor(
    public dialogRef: MatDialogRef<CloseOrderComponent>,
    public closeOrderService: OrderCloseService,
    public dialog: MatDialog,
    public constants: Constants,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: SaleOrder,
  ) {
    super(dialog, snackBar, store);
    if (data) {
      this.order = data;
    }
  }
  /**
   * process to close the order
   */
  proceedToClose(): void {
    const params: {
      order_id: string;
  } = this.closeOrderService.getParams(this.order.incrementId);
    this.dialogRef.close(params);
  }
}

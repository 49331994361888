import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Input } from "@angular/core";
import { Constants } from "@app/env";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { ActionLoadAllStores } from "@store/store/actions";
import { UserState } from "@store/user";
import {
  SaleItem,
  SaleOrder,
  StoreAddress,
  TcutsCity,
  User,
} from "@tendercuts/models";
import {
  QuoteSerializerService,
  StoreAddressService,
} from "@tendercuts/providers";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { PosQuote } from "../../../../models";

@Component({
  selector: "app-pos-invoice",
  templateUrl: "./pos-invoice.component.html",
  styleUrls: ["./pos-invoice.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PosInvoiceComponent implements OnInit {
  // Diff Envee pos and tendercuts pos
  isEnveePos: boolean = false;
  order: SaleOrder;
  // In test cases mode hide the print popup
  isTestCaseMode: boolean = false;
  @Input() set saleOrder(order: SaleOrder) {
    this.order = order;
    this.posQuote = this.quoteSerializer.posQuoteSerializer(order);
  }
  @Input() posQuote: PosQuote;
  // tendercash amount not showing in order display page print
  @Input() isDetailMode: boolean = false;
  storeAddress: StoreAddress;
  user: User;
  math: Math = Math;
  cityPhoneNo: string;
  isGtgStore: boolean;
  // showing tax category wise
  categoryTax: any[] = [];
  getStoreAddress: string;
  gstNumber: string;
  fssaiCode: string;

  constructor(
    public storeAddressService: StoreAddressService,
    public quoteSerializer: QuoteSerializerService,
    private store: Store<AppState>,
    public constants: Constants
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new ActionLoadAllStores());
    this.store
      .select((state) => state)
      .pipe(take(1))
      .subscribe((state: AppState) => {
        const userStore: any = state.storeState.allStores.filter(
          (appStore) => appStore.storeId == this.order.storeId
        )[0];
        this.getStoreAddress = userStore.address;
        this.gstNumber = userStore.storeAttribute.gstNumber;
        this.fssaiCode = userStore.storeAttribute.fssaiCode;
        this.isGtgStore = userStore.isGtg;
        const selectedCity: TcutsCity = state.storeState.cities.find(
          (city) => city.code == userStore.storeAttribute.city
        );
        this.cityPhoneNo = selectedCity.phone;
      });
    this.fetchTaxAmount();
  }

  /**
   * showing gst for quote items in category wise.
   */
  fetchTaxAmount(): void {
    const category: {} = {};
    this.posQuote.cartProducts.forEach((item: SaleItem) => {
      if (!category[item.taxPercent]) {
        category[item.taxPercent] = {
          taxPercent: item.taxPercent,
          gst: 0,
          Amount: 0,
        };
        if (category[item.taxPercent].taxPercent <= 0) {
          return;
        }

        this.categoryTax.push(category[item.taxPercent]);
      }

      category[item.taxPercent].gst += item.taxAmount;
      category[item.taxPercent].Amount += item.rowTotalWithDiscount;

      return category;
    });
  }

  get displayPaymentMethod(): string {
    switch (this.posQuote.paymentMethod) {
      case "cashforpos":
        return "CASH";
      case "ccforpos":
        return "CARD";
      case "cp1forpos":
        return "VENDOR PAYMENT";
      default:
        return "";
    }
  }

  /**
   * user available reward points
   */
  get userRewards(): number {
    const rewards: number =
      this.posQuote.user.rewards - this.posQuote.totals.rewardPointsSpend;

    return rewards;
  }

  print(): void {
    let printContents: string;
    let popupWin: Window;
    printContents = document.getElementById("selector").innerHTML;
    popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=70mm");
    popupWin.document.open();
    popupWin.document.write(`
    <html>
    <head>
      <title>Print Bills</title>
    </head>
    <style>
      body {
        height: 100%;
      }
      .container {
        text-align: center;
        width: 70mm;
        padding:0 3%;
        page-break-before: always;
      }
      p {
        margin: 1%;
        font-size: 0.9rem;
        text-align: center;
      }
      .heading h4 {
        font-size: 1.8rem;
      }
      .order-id {
        text-align: center;
      }
      .heading h4 , .order-id h4 {
        font-weight: bolder;
        margin: 1%;
      }
      .vendor-payment {
        text-align: left;
      }
      table {
        margin: 4% 0;
        font-size: 15px;
        text-align: left;
        width: 70mm;
        border-collapse: collapse;
      }

      th {
        height: 25px;
      }

      td {
        height: 20px;
      }

      .text-right {
        text-align: right;
      }
      .tax-data {
        text-align: left;
        font-weight: bold;
        margin: 4% 0;
      }
      .bold {
        font-weight: bold;
        text-align:center;
      }
      .child{
        margin-left: 2rem;
        padding-top: 10px;
      }
      .hsn {
        padding-left: 0.3rem;
      }
    </style>

    <body >${printContents}</body>
    </html>
    `);
    popupWin.document.close();
    popupWin.focus();
    popupWin.print();
    popupWin.close();
  }

  shouldShowComponent(fieldName: string): boolean {
    if (
      this.posQuote.totals[fieldName] &&
      this.posQuote.totals[fieldName] != 0
    ) {
      return true;
    }

    return false;
  }

  get userState(): Observable<UserState> {
    return this.store.select((state) => state.userState);
  }

  /**
   * calculating the total itemsubtotal for actual price
   * return sum of saleitem actualPrice (RATE)
   */
  get itemSubTotal(): number {
    return this.posQuote.cartProducts.reduce(
      (total: number, item: SaleItem) => total + Math.abs(item.rowTotal),
      0
    );
  }

  /**
   * calculating the total original price for cartproducts item (RATE TOTAL)
   */
  get itemsBaseRowTotal(): number {
    return this.posQuote.cartProducts.reduce(
      (total: number, item: SaleItem) => total + Math.abs(item.baseRowTotal),
      0
    );
  }
  /**
   * (ITEM Discount TOTAL)
   * calculating promoDiscount with the diffeence between baseRowTotal and row Total
   */
  get promoDiscount(): number {
    return this.posQuote.cartProducts.reduce(
      (total: number, item: SaleItem) =>
        total + Math.abs(item.baseRowTotal - item.rowTotal),
      0
    );
  }

  /**
   * @param item promo discount for each item
   * ( item DISCOUNT )
   * calculating promoDiscount with the diffeence between
   * baseRowTotal and row Total for the item
   */

  itemdiscount(item: SaleItem): number {
    return item.baseRowTotal - item.rowTotal;
  }

  /**
   * grossTotal for the order adding itemSubTotal and gstamount
   */
  get grossTotal(): number {
    return this.itemSubTotal + this.posQuote.totals.taxAmount;
  }

  /**
   * calculation for savings promodiscount + coupon code (discount+rewardpointsspend)
   */
  get totalSavings(): number {
    const save: number =
      this.promoDiscount +
      Math.abs(this.posQuote.totals.discount) +
      Math.abs(this.posQuote.totals.rewardPointsSpend);

    return +save;
  }

  get eliteSavingsMessage(): string {
    if (!this.order?.eliteSaving) {
      return ``;
    }

    return `You saved ₹${this.order?.eliteSaving} by using your Elite Subscription.`;
  }

  get brandTitle(): string {
    return this.isGtgStore ? "GoodToGo" : "Tendercuts";
  }
}

// TODO
/* eslint-disable */
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "somethign-went-wrong",
  templateUrl: "./something-went-wrong.component.html",
  styleUrls: ["./something-went-wrong.component.scss"],
})
export class SomethingWentWrongComponent {
  constructor(public dialogRef: MatDialogRef<SomethingWentWrongComponent>) {}
}

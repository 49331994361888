<mat-card class="pay_amount">
  <mat-card-title class="pay_amount__title">
    Pay Amount to Rider {{tripSheet.driverName}}({{ tripSheet.driverId }})
  </mat-card-title>

  <mat-card-content>
    <mat-form-field>
      <mat-label>Enter Amount to Pay</mat-label>
      <input
        matInput
        id="pay"
        [formControl]="inputController"
        placeholder="Enter Amount"
        autofocus
      />
    </mat-form-field>

    <div class="row">
      <div class="col-4">
        <label><strong>Total Earnings</strong></label>
        <div class="row">
          <label class="pay_amount__amount">{{
            tripSheet.totalEarnings
          }}</label>
        </div>
      </div>
      <div class="col-4">
        <label><strong>Paid Amount</strong></label>
        <div class="row">
          <label class="pay_amount__amount">{{
            tripSheet.totalPaidAmount
          }}</label>
        </div>
      </div>
      <div class="col-4">
        <label><strong>Balance to Pay</strong></label>
        <div class="row">
          <label class="pay_amount__amount">{{
            tripSheet.balanceAmount
          }}</label>
        </div>
      </div>
    </div>

    <div class="pay_amount__details" *ngIf="tripSheet.payment.length">
      <app-trip-sheet-grid
        [dataSource]="tripPaymentDataSource"
        [columnsToDisplay]="columnsToDisplay"
        [titleToDisplay]="titleToDisplay"
      >
      </app-trip-sheet-grid>
    </div>

    <div class="pay_amount__button">
      <button
        (click)="closeDialog()"
        class="close-btn"
        color="primary"
        mat-raised-button
      >
        Close
      </button>
      <button
        [disabled]="!canActiveSubmit"
        (click)="tripSheetPay()"
        color="primary"
        id="submit-approve"
        mat-raised-button
      >
        Submit
      </button>
    </div>
  </mat-card-content>
</mat-card>

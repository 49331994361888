import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { Store as AppStore } from "@ngrx/store";
import * as fromStore from "@store/state";
import { AppState } from "@store/state";
import { BaseGetProvider } from "@tendercuts/http";
import { InventoryRequest } from "@tendercuts/models";

@Injectable({
  providedIn: "root",
})
export class FetchInventoryPurchaseService extends BaseGetProvider<InventoryRequest> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public store: AppStore<AppState>,
    public loginService: LoginServices,
  ) {
    super(http);
    this.delegate = this;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof InventoryRequest {
    return InventoryRequest;
  }

  get isCachable(): boolean {
    return false;
  }

  get endpoint(): string {
    return this.endpointService.inventoryPurchase();
  }

  getParams(
    storeId: number,
    productId: number,
    createdTime: Date,
  ): {
    store_id: number;
    product_id: number;
    created_time: string;
  } {
    createdTime.setTime(createdTime.getTime() + 5.5 * 60 * 60 * 1000);

    const params: {
      store_id: number;
      product_id: number;
      created_time: string;
    } = {
      store_id: storeId,
      product_id: productId,
      created_time: createdTime.toISOString().split("T")[0],
    };

    return params;
  }
}

<div class="change-work-location">
  <h5 class="change-work-location__header5">Change rider location</h5>
  <div class="row">
    <mat-label class="change-work-location__header6 col-5">
      <b class="change-work-location__header6--bold"
        >{{ storeName | uppercase }}
      </b></mat-label
    >
    <div class="change-work-location__header6 col-2">-></div>
    <form class="col-5">
      <mat-form-field>
        <mat-select
          placeholder="select store"
          [(value)]="selected"
          [formControl]="storeSelectionControl"
        >
          <mat-option
            *ngFor="let store of availableStores"
            [value]="store.storeId"
          >
            {{ store.storeAttribute?.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <hr />
  <div class="change-work-location__buttons">
    <h6 class="change-work-location__paragraph" *ngIf="selected != null">
      Are you sure you want to change the rider location?
    </h6>
    <div class="row">
      <div class="col-2">
        <button color="primary" mat-raised-button mat-dialog-close>
          Cancel
        </button>
      </div>
      <div class="col-2">
        <button
          paragraph
          class="change-work-location__buttons__switch"
          color="primary"
          [disabled]="selected == null"
          mat-raised-button
          (click)="changeWorkLocation()"
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="!isSequencedMode; else changeSequence">
  <mat-horizontal-stepper #stepper>
    <mat-step>
      <i
        class="menu-item-icon fa fa-window-close-o col-1"
        (click)="closeDialog()"
      ></i>
      <h5 class="text-center" *ngIf="!editMode; else editTrip">Plan Trip</h5>
      <ng-template #editTrip>
        <h5 id="edit-trip-title" class="text-center">Edit Trip</h5>
      </ng-template>
      <app-plan-trip
        (onTripPlanned)="movetToNextStep($event)"
        (dialogClose)="closeDialog()"
      >
      </app-plan-trip>
    </mat-step>
    <mat-step>
      <div>
        <app-plan-trip-order-sequence
          [trip]="currentTrip"
          (dialogClose)="closeDialog($event)"
        ></app-plan-trip-order-sequence>
      </div>
      <ng-container *ngIf="!currentTrip">
        <h4>Please assign the driver for the trip</h4>
      </ng-container>
    </mat-step>
  </mat-horizontal-stepper>
</ng-container>
<ng-template #changeSequence>
  <div>
    <app-plan-trip-order-sequence
      [trip]="sequenceChangeTrip"
      (dialogClose)="closeSequenceDialog($event)"
      [isCloseable]="true"
    ></app-plan-trip-order-sequence>
  </div>
</ng-template>

import { Injectable } from "@angular/core";
import { User } from "@tendercuts/models";
import { of, Observable } from "rxjs";

@Injectable()
export class SMStorageService {
  date: Date;

  constructor() {}

  storeData(key: string, value: any, date?: Date): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getData(key: string): Observable<any> {
    const item: string = localStorage.getItem(key);

    return of(JSON.parse(item));
  }

  getUser(key: string): Observable<User> {
    const user: string = localStorage.getItem(key);

    return of(new User().deserialize(JSON.parse(user)));
  }

  removeData(key: string): void {
    return localStorage.removeItem(key);
  }
}

export class OrderRatingResponse {
  private sub_title: string;
  
  public result: string;
  public message: string;
  public status: boolean;
  public link: string;
  public title: string;

  get subTitle(): string {
    return this.sub_title;
  }

  deserialize(input) {
    this.result = input.result;
    this.message = input.message;
    this.status = input.status;
    this.link = input.link;
    this.title = input.title;
    this.sub_title = input.sub_title;

    return this;
  }
}

<section class="controls">
    <div class="controls__switch">
        <mat-label>Date and Slots</mat-label>
        <mat-slide-toggle 
            class="controls__switch__toggle"
            [(ngModel)]="canSearchWithPathString"
            (change)="searchModeChanged()"
            id="switch"
        >
        </mat-slide-toggle>
        <mat-label>Path strings</mat-label>
    </div>
    <div class="row">
        <div *ngIf="canSearchWithPathString;else dateAndSlot" class="col-md-6">
            <mat-form-field
                class="controls__path-strings col-md-12"
            >
                <textarea
                    [rows]="5"
                    matInput
                    type="text"
                    placeholder="Enter the Trips Path string"
                    [formControl]="pathStringsControl"
                    id="path-strings-text-area"
                >
                </textarea>
            </mat-form-field>
        </div>
        <ng-template #dateAndSlot>
            <div class="controls__date col-md-2">
                <mat-form-field
                    class="col-md-12 controls__date__picker"
                    color="accent"
                    appearance="fill"
                >
                    <mat-label>Date</mat-label>
                    <input
                        matInput
                        [matDatepicker]="datePicker"
                        [max]="previousDate"
                        [formControl]="dateControl"
                    />
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="datePicker"
                        id="date"
                    >
                    </mat-datepicker-toggle>
                    <mat-datepicker #datePicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-9">
                <mat-chip-list 
                    [multiple]="true" 
                    [selectable]="true"
                >
                    <mat-chip 
                        *ngFor="let slot of slotFilterGroup.filters"
                        class="controls__slots"
                        (click)="updateSelectedSlots(slot)"
                        [selected]="slot.selected"
                    >
                        {{ slot.displayName }}
                    </mat-chip>
                </mat-chip-list>
            </div>
        </ng-template>
        <div class="col-md-1">
            <button
                id="search-btn"
                class="controls__search-btn"
                mat-button
                [disabled]="disableSearchBtn"
                (click)="updateMap(true)"
            >
                Search
            </button>
        </div>
    </div>
</section>
<section class="map-container row">
    <div *ngIf="tripsList.length" class="col-sm-2 map-container__trips">
        <table 
            mat-table 
            [dataSource]="tripsList" 
            class="map-container__trips__table"
            id="trips-table"
        >
            <ng-container matColumnDef="tripId">
              <th mat-header-cell *matHeaderCellDef>Trips</th>
              <td 
                mat-cell 
                *matCellDef="let element" 
                class="header map-container__trips__table__cell"
                [ngClass] = "canHighLightRowInTable(element) 
                    ? 'map-container__trips__table__cell-highlighted' 
                    : ''"
                [ngStyle]="{'border-right': '5px solid' + colors[element.tripNumber]}"
              > 
                <div class="map-container__trips__table__cell__label">Trip {{element.tripNumber + 1}} :</div>
                <div>{{element.pathString}}</div> 
              </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr 
              mat-row 
              *matRowDef="let row; columns: displayedColumns;" 
              (click)="highlightTripInMap(row)"
              class="map-container__trips__table__row"
            >
            </tr>
        </table>
    </div>
    <div [ngClass]="tripsList.length ? 'col-sm-10' : 'col-sm-12'">
        <agm-map 
            [zoom]="zoom" 
            [latitude]="initialLatitude" 
            [longitude]="initialLongitude" 
            class="map-container__map"
            id="map"
        >
            <ng-container *ngFor="let coordinates of coordinatesArray; let i = index">
                <agm-polyline [visible]="true" [strokeWeight]="5" [strokeColor]="[color ? color : colors[i]]">
                    <agm-polyline-point
                        *ngFor="let coordinate of coordinates"
                        [latitude]="coordinate[0]"
                        [longitude]="coordinate[1]"
                    >
                    <agm-marker
                        [latitude]="coordinate[0]"
                        [longitude]="coordinate[1]"
                        [iconUrl]="'assets/marker/red.png'"
                        (mouseOver)="onMouseOverMapMarker(infoWindow)" 
                        (mouseOut)="onMouseOutMapMarker(infoWindow)"
                    >
                      <agm-info-window #infoWindow [disableAutoPan]="true">
                        <div>
                            Latitude : <strong>{{coordinate[0]}}</strong>
                        </div>
                        <div>
                            Longitude : <strong>{{coordinate[1]}}</strong>
                        </div>
                      </agm-info-window>
                    </agm-marker>
                    </agm-polyline-point>
                </agm-polyline>
            </ng-container>
        </agm-map>
    </div>
    
</section>


import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseGetProvider } from "@tendercuts/http";
import { Butcher } from "@tendercuts/models";
import { EndPoint } from "../base";
import { LoginServices } from "../login";

@Injectable({
  providedIn: "root",
})
export class LiveTrackButcherService extends BaseGetProvider<Butcher> {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof Butcher {
    return Butcher;
  }

  get isCachable(): boolean {
    return false;
  }

  get endpoint(): string {
    return this.endpointService.liveTrackButcher();
  }

  getParams(storeId: number, orderId: string): HttpParams {
    let params: HttpParams = new HttpParams();
    params = params.append("store_id", storeId.toString());
    params = params.append("order_id", orderId);

    return params;
  }
}

import { Component, Inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { GenericServerResponse, Trips } from "@tendercuts/models";
import { BasePage } from "src/app/utils";
import { EditDummyTripService } from "src/providers";

@Component({
  selector: "app-dummy-trip-edit",
  templateUrl: "./dummy-trip-edit.component.html",
  styleUrls: ["./dummy-trip-edit.component.scss"],
})
export class DummyTripEditComponent extends BasePage {
  // form controller
  alternateNameControl: FormControl = new FormControl();

  // selected trip data
  trip: Trips;

  constructor(
    private editDummyTripService: EditDummyTripService,
    public dialogRef: MatDialogRef<DummyTripEditComponent>,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: Trips
  ) {
    super(dialog, snackBar, store);

    if (data) {
      this.trip = data;
    }
  }

  /**
   * @returns error message,
   * If the old driver tag name and entered name are same
   */
  validateName(): void {
    const submittedName: string = this.alternateNameControl.value;
    if (!submittedName) {

      return;
    }
    if (this.trip.driverTag === submittedName) {
      this.showNotification(
        "bottom",
        "center",
        "danger",
        "info-circle",
        "Already Exist! Please Enter New Name"
      );

      return;
    }

    this.submitAlternateName(submittedName);
  }

  /**
   * @param alternateName ie submitted name is passed to server
   */
  async submitAlternateName(alternateName: string): Promise<void> {
    const params: {
      trip_id: string;
      driver_tag: string;
    } = this.editDummyTripService.getParams(this.trip.tripId, alternateName);

    this.presentLoader();

    await this.editDummyTripService.getData(params).subscribe(
      (data: GenericServerResponse[]) => {
        if (data) {
          this.showNotification(
            "bottom",
            "center",
            "success",
            "info-circle",
            data[0].message
          );
          this.dismissLoader();

          return;
        }
      },
      (err) => this.somethingWentWrong()
    );
    this.dialogRef.close(true);
  }

  /**
   * Resets the form controls
   */
  resetData(): void {
    this.alternateNameControl.setValue("");
  }

  dialogClose(): void {
    this.dialogRef.close();
  }
}

<p>
  <b><h4>Select Item for Replacement</h4></b>
</p>
<div class="row">
  <div class="col-8" id="grid">
    <app-product-display-tab
      id="quantity"
      [actions]="buttonActions"
      [dataSource]="saleItemDataSource"
      [columnsToDisplay]="columnsToDisplay"
    >
    </app-product-display-tab>
  </div>
  <form name="replaceform" class="col-4" id="replace" [formGroup]="replaceForm">
    <div id="item-checks">
      <ng-container>
        <mat-label>
          <h6><b> Select Day and Slot:</b></h6>
        </mat-label>
        <mat-form-field>
          <mat-label>Select Day</mat-label>
          <mat-select id="day">
            <mat-option
              *ngFor="let date of slotDates; let i = index"
              value="{{ date.name }}"
              (click)="selectedDay(date)"
              >{{ date.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <br />
        <mat-form-field>
          <mat-label>Select Slot</mat-label>
          <mat-select id="time" formControlName="slotTime">
            <mat-option
              id="slot"
              *ngFor="let slot of selectedDateSlots"
              [disabled]="slot.isSlotFilled"
              [value]="slot.slot_id"
              >{{ slot.interval }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </ng-container>
    </div>
  </form>
  <hr />
  <button
    id="replace-cancel"
    color="primary"
    mat-raised-button
    mat-dialog-close
  >
    Cancel
  </button>
  <button
    *ngIf="replacementItems.length != 0"
    id="replace-btn"
    color="primary"
    [disabled]="!replaceForm.valid"
    mat-raised-button
    (click)="proceedToReplace()"
  >
    Replace
  </button>
</div>

import { Serializable } from "./base";

export class ChangeLogs implements Serializable<ChangeLogs> {
    private field_name: string;
    private new_value: string;
    private old_value: string;
    private user_name: string;
    private user: string;
    public comment: string;
    public date: Date;
  
    get fieldName() {
      return this.field_name;
    }
  
    get newValue() {
      return this.new_value;
    }
  
    get oldValue() {
      return this.old_value;
    }

    get userName() {
        return this.user_name;
    }

    get userId() {
        return this.user;
    }
  
    deserialize(input) {
      this.field_name = input.field_name;
      this.user = input.user;
      this.user_name = input.user_name;
      this.new_value = input.new_value;
      this.old_value = input.old_value;
      this.comment = input.comment;
      this.date = input.date;
  
      return this;
    }
  }
  
import { DefaultSerializationMixin, Serializable } from "../base";

/**
 * Model OrderLocation  
 * Used to return deserialized 
 * data from fireStore on order live track
 */
export class OrderLocation extends DefaultSerializationMixin 
    implements Serializable<OrderLocation> {

    public orderId: number;
    public latitude: number;
    public longitude: number;
    
    deserialize(input){
        this.orderId = input.orderId;
        this.latitude = input.l;
        this.longitude = input.o;
        return this;
    }
}
export class TripsStatusHistory {
  private status: number;
  private created_at: Date;
  private status_code: string;

  constructor() {}

  deserialize(input) {
    this.status = input.status;
    this.created_at = input.created_at;
    this.status_code = input.status_code;

    return this;
  }

  get createdAt() {
    return this.created_at;
  }

  get statusCode() {
    return this.status_code;
  }

  get tripStatus() {
    return this.status;
  }
}

import { Injectable } from "@angular/core";
import { Store as AppStore } from "@ngrx/store";

import * as fromStore from "@store/state";
import { AppState } from "@store/state";
import {
  AuthProtocol,
  BaseGetProvider,
  DataTransformationProtocol,
} from "@tendercuts/http";
import { Location, Trips, User } from "@tendercuts/models";

import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { EndPoint } from "./base";

@Injectable()
export class SnapToRoadService
  extends BaseGetProvider<Location>
  implements AuthProtocol, DataTransformationProtocol {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public store: AppStore<AppState>,
  ) {
    super(http);
    this.delegate = this;
  }

  getToken(): Observable<User> {
    return this.store.select(fromStore.getUser);
  }

  get isAuthenticatedEndpoint(): boolean {
    return false;
  }

  get authProtocol(): any {
    return null;
  }

  get model(): typeof Location {
    return Location;
  }

  get endpoint(): string {
    return this.endpointService.login;
  }

  postProcessResponse(data: any): any {
    // https://developers.google.com/maps/documentation/roads/snap
    return this.data.snappedPoints.map((entry) => entry.location);
  }

  getParams(trip: Trips): void {
    let params: HttpParams = new HttpParams();
    params = params.append("key", "AIzaSyBuNS48fU5fhgNWSRMlHgw9R88dh-m9yUg");
    let path: string = "";
    trip.locations.forEach((location) => (path += location.toString()));
    params = params.append("path", path);
  }
}

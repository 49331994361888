export class RatingTag{
    public id: number;
    public tag_name: string;

    deserialize(input){
        this.id = input.id
        this.tag_name = input.tag_name;
        return this;
    }

    get tagName(){
        return this.tag_name;
    }
}
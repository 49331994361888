import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BaseGetProvider } from "@tendercuts/http";
import { BookingRequest } from "@tendercuts/models";

@Injectable({
  providedIn: "root",
})
export class DriverBookingDetailsService extends BaseGetProvider<BookingRequest> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
    this.delegate = this;
  }

  preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof BookingRequest {
    return BookingRequest;
  }

  get endpoint(): string {
    return this.endpointService.bookingDetails();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(
    driverId: number,
  ): {
    user_id: string;
  } {
    const params: {
      user_id: string;
    } = {
      user_id: driverId.toString(),
    };

    return params;
  }
}

import { Injectable } from "@angular/core";
import { Actions, createEffect } from "@ngrx/effects";
import { GenericError } from "@tendercuts/models";
import { SignUpService } from "@tendercuts/providers";
import { ofAction } from "ngrx-actions";
import { of, Observable } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import {
  ActionSignupFail,
  ActionSignupSuccess,
  ActionTrySignup,
} from "../signup/signup-actions";

@Injectable()
export class SignUpEffects {
  constructor(
    private actions$: Actions,
    private signupService: SignUpService,
  ) {}

   signUp: Observable<
    ActionSignupFail | ActionSignupSuccess
  > = createEffect(() => this.actions$.pipe(
    ofAction(ActionTrySignup),
    switchMap((data) => {
      return this.signupService.getData(data.payload).pipe(
        map((response) => {
          if (!response[0].result) {
            return new ActionSignupFail(new GenericError(response));
          }

          return new ActionSignupSuccess(response);
        }),
        catchError((err) => {
          return of(new ActionSignupFail(new GenericError(err)));
        }),
      );
    }),
  ));
}

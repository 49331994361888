<div class="row top-nav-bar-wrap" *ngIf="!order; else orderdetail">
  <mat-form-field class="col-lg-7">
    <input
      type="text"
      id="scan-order-ip"
      placeholder="Trip id / Order id"
      aria-label="Number"
      matInput
      [(ngModel)]="searchText"
      autocomplete="off"
      autofocus="!order"
      (keyup.enter)="loadScanDashboard()"
    />
  </mat-form-field>
  <div class="col-md-4">
    <button
      class="btn col-lg"
      id="submit"
      mat-stroked-button
      (click)="loadScanDashboard()"
    >
      Search
    </button>
  </div>
</div>

<ng-template #orderdetail>
  <div class="row">
    <div 
      *ngIf="tripOrders.length" 
      id="order-list" 
      class="col-sm-3 order-list"
    >
      <div>
        <h4 class="order-list__trip-id">Trip #{{tripId}}</h4>
        <div 
          *ngFor="let tripOrder of tripOrders; let index = index"
          class="order-list__order"
          [ngClass]="tripOrder.isScanDone ? 'order-list__order--scanned' : ''"
        >
          <div 
            class="order-list__order__header"
            [ngClass]="tripOrder.isScanDone ? 'order-list__order__header--scanned' : ''"
          >
            <div>
              <div 
                class="order-list__order__header__number"
                [ngClass]="tripOrder.isScanDone ? 'order-list__order__header__number--scanned' : ''"
              >
                {{ index + 1 }}
              </div>
              <span class="order-list__order__header__label">
                Order Id : 
              </span>
              #{{ tripOrder.incrementId }}
            </div>
            <div>
              <div 
                class="order-list__order__header__label"
                *ngIf="tripOrder.isScanDone"  
              >
                Scanned
              </div>
            </div>
          </div>
          <div class="order-list__order__items">
            <div *ngFor="let item of tripOrder.items">
              <div *ngIf="item.isSimple">
                {{item.name}} &times; {{changeToInteger(item.quantity)}}
              </div>
            </div>
          </div>
          <button 
            mat-raised-button
            color="primary"
            class="order-list__order__button"
            (click)="setOrderToScan(tripOrder.incrementId)"
            *ngIf="!tripOrder.isScanDone"
          >
            Scan
          </button>
        </div>
      </div>
    </div>
    <div 
      *ngIf="!order.isScanDone"
      [ngClass]="tripOrders.length ? 'col-sm-9' : 'col-sm-12'"
    >
      <div class="separator scan-wrapper">
        <div class="scan-wrapper__order-header">
          <mat-card class="row">
            <div class="row scan-wrapper__order-header__order-detail">
              <div class="col-sm d-none d-lg-block">
                <div class="scan-wrapper__order-header__order-detail__order-label">Order ID</div>
                <div class="scan-wrapper__order-header__order-detail__order-id">#{{ order.incrementId }}</div>
              </div>
              <!-- for mobile devices-->
              <div class="w-100 order-detail-mb d-lg-none d-sm-block">
                <div class="row no-gutters">
                  <div class="col-6">Order</div>
                  <div class="col-6" id="order-pack">
                    Total Items: {{ orderPacksCount }}
                  </div>
                </div>
                <div class="row no-gutters">
                  <div class="col-6">#{{ order.incrementId }}</div>
                  <div class="col-6">
                    Amount: {{ order.total | currency: "INR" }}
                  </div>
                </div>
              </div>
              <div class="col-4 d-none d-lg-block">
                <div
                  class="scan-wrapper__order-header__order-detail__payment"
                  >{{ order.total | currency: "INR" }} |
                  {{ order.payment_method }}
              </div>
              </div>
            </div>
          </mat-card>
        </div>
        <div class="scan-wrapper__item-detail">
          <mat-card class="row card">
            <div class="scan-wrapper__item-detail__scanner row">
              <div class="col-lg-6">
                Items ({{scanItems.length}})
              </div>
              <div class="col-lg-6 scan-input">
                <mat-form-field 
                  class="col-sm"
                >
                  <input
                    type="text"
                    id="scan-item-ip"
                    placeholder="Scan Item Barcode"
                    [(ngModel)]="barcodeText"
                    pattern="[0-9]{8}"
                    matInput
                    (keypress)="monitorBarcodeInput()"
                    (keyup.enter)="processBarcode()"
                    [required]="true"
                    appBlockCopyPaste
                  />
                </mat-form-field>
                <h6 class="color_primary col-sm">
                  *Only barcode scans are allowed
                </h6>
              </div>
            </div>
            <mat-card-content>
              <table class="d-none d-lg-block order-items-table scan-wrapper__item-detail__table">
                <tr class="row w-100 scan-wrapper__item-detail__table__head">
                  <th class="col-3">Name</th>
                  <th class="col-3">Qty</th>
                  <th class="col-3">Status</th>
                  <th class="col-3">Yet to be scanned</th>
                </tr>
                <ng-container *ngFor="let scanItem of scanItems">
                  <tr class="row w-100" *ngIf="scanItem.item.isSimple">
                    <td class="col-3">{{ scanItem.item.name }}</td>
                    <td class="col-3">{{changeToInteger(scanItem.noOfTimesToBeScanned)}}</td>
                    <td class="col-3">
                      <span
                        *ngFor="
                          let scan of changeArrayType(
                            changeToInteger(scanItem.noOfTimesToBeScanned)
                          );
                          let ind = index
                        "
                      >
                        <i
                          class="fa fa-check-circle no-scan"
                          [ngClass]="{ scan: scanItem.noOfTimesScanned > ind }"
                        ></i>
                      </span>
                    </td>
                    <td class="col-3 scan">
                      {{
                        changeToInteger(scanItem.noOfTimesToBeScanned) -
                          changeToInteger(scanItem.noOfTimesScanned)
                      }}
                    </td>
                  </tr>
                </ng-container>
              </table>
    
              <!-- for mobile devices -->
              <table class="w-100 d-lg-none d-m-block order-items-table">
                <tr class="row">
                  <th class="col-6">Name</th>
                  <th class="col-6">Status</th>
                </tr>
                <tr class="row" *ngFor="let scanItem of scanItems">
                  <td class="col-6">{{ scanItem.item.name }}</td>
                  <td class="col-6">
                    <span
                      *ngFor="
                        let scan of changeArrayType(
                          changeToInteger(scanItem.noOfTimesToBeScanned)
                        );
                        let ind = index
                      "
                    >
                      <i
                        class="fa fa-check-circle no-scan"
                        [ngClass]="{ scan: scanItem.noOfTimesScanned > ind }"
                      ></i>
                    </span>
                  </td>
                </tr>
              </table>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="orderweight">
          <mat-card class="row d-none d-lg-block card">
            <div class="row">
              <div class="cancel-order col-6">
                <button 
                  mat-button
                  class="cancel-order-btn"
                  id="cancel-order-btn"
                  (click)="resetOrder()"
                >
                  Cancel
                </button>
              </div>
              <div class="complete-scan col-6">
                <button
                  mat-button
                  id="complete-scan-btn"
                  [disabled]="!checkIfAllItemsAreScanned()"
                  (click)="sendOrderWeights()"
                >
                  Complete Scan
                </button>
              </div>
            </div>
            <div class="row barcodes">
              <div class="cancel-barcode col-6">
                <ngx-barcode
                  [bc-value]="CANCELBARCODE"
                  [bc-display-value]="false"
                  [bc-height]="40"
                  [bc-width]="2"
                  [bc-font-size]="12"
                >
                </ngx-barcode>
              </div>
              <div class="complete-scan-barcode col-6">
                <ngx-barcode
                  [bc-value]="COMPLETESCANBARCODE"
                  [bc-display-value]="false"
                  [bc-height]="40"
                  [bc-width]="2"
                  [bc-font-size]="12"
                >
                </ngx-barcode>
              </div>
            </div>
          </mat-card>
          <!-- for mobile devices-->
    
          <mat-card class="row d-lg-none d-sm-block">
            <div class="row">
              <div class="col-sm">
                <button
                  mat-button
                  class="col-sm-5"
                  id="complete-scan-btn"
                  [disabled]="!checkIfAllItemsAreScanned()"
                  (click)="sendOrderWeights()"
                >
                  Complete
                </button>
              </div>
            </div>
            <div class="row">
              <div class="cancel-order-mb col-sm">
                <button
                  mat-button
                  class="col-sm-5 cancel-order-btn"
                  id="cancel-order-btn"
                  (click)="resetOrder()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>

</ng-template>

import {Serializable} from './base';

export enum InventoryRequestStatus {
  CREATED = 0,
  APPROVED,
  REJECTED
}
export class InventoryRequest implements Serializable<InventoryRequest> {

  get storeName(): string {
    return this.store_name;
  }
  get productName(): string {
    return this.product_name;
  }
  get createTime(): Date {
    return this.created_time;
  }

  get productId(): number {
    return this.product_id;
  }

  get storeId(): number {
    return this.store_id;
  }

  get isDone(): boolean {
    return this.is_done;
  }

  get inventoryCreatedTime() {
    return new Date(this.created_time).toLocaleTimeString();
  }

  get inventoryType(): string {
    if (this.type == 0) {
      return "Today";
    } else {
      return "Tomorrow";
    }
  }

  private created_time: Date;
  private product_id: number;
  private store_id: number;
  private is_done: boolean;
  private store_name: string;
  private product_name: string;
  private approved_by_id: string;

  public id: number;
  public sku: string;
  public qty: number;
  public status: number;
  public type: number;
  public gpu: number;
  private expiring_tomorrow: number;
  private stock_on_hand: number;

  constructor() {
  }

  set stockExpiresTomorrow(stock) {
    this.expiring_tomorrow = stock;
  }

  set stockOnHand(stock) {
    this.qty = stock;
  }

  get stockOnHand() {
    return this.qty;
  }

  get stockExpiresTomorrow() {
    return this.expiring_tomorrow;
  }

  deserialize(input) {
    this.id = input.id;
    this.created_time = input.created_time;
    this.product_id = input.product_id;
    this.store_id = input.store_id;
    this.qty = input.qty;
    this.is_done = input.is_done;
    this.store_name = input.store_name;
    this.product_name = input.product_name;
    this.sku = input.sku;
    this.status = input.status;
    this.type = input.type;
    this.gpu = input.gpu;
    this.stock_on_hand = input.stock_on_hand;
    this.expiring_tomorrow = input.expiring_tomorrow;

    return this;
  }
}

import { Injectable } from '@angular/core';

import { BaseGetProvider, DataTransformationProtocol } from '@tendercuts/http';
import { SaleOrder } from '@tendercuts/models';

import { HttpClient, HttpParams } from '@angular/common/http';
import { EndPoint } from '@endpoint';
import { LoginServices } from '@login';

@Injectable()
export class CustomerOrdersService extends BaseGetProvider<SaleOrder>
  implements DataTransformationProtocol {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
    this.delegate = this;
  }

  // override this
  preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof SaleOrder {
    return SaleOrder;
  }

  get endpoint(): string {
    return this.endpointService.customerOrders();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(userId: string): HttpParams {
    let params: HttpParams = new HttpParams();
    params = params.append('user_id', userId.toString());

    return params;
  }
}

/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { Serializable } from "../base";
import { Store } from "../store/store";
import { Inventory } from "./inventory";
import { Product } from "./product";
import { ProductPrice } from "./product-price";

enum InventoryCode {
  OOS,
  AVAILABLE_TODAY,
  AVAILABLE_TOMORROW,
}

export class Category implements Serializable<Category> {
  public name: string;
  public thumb: string;
  public image: string;
  public products: Product[];
  public url_key: string;
  public is_active: string;
  private entity_id: number;
  private meta_title: string;
  private meta_description: string;
  private meta_keywords: string;
  private display_products: Product[] = [];
  private icon: string;
  private fill_icon: string;
  private _store: Store;
  private deeplink: string = "";
  private include_in_menu: number;

  get categoryId(): number {
    return this.entity_id;
  }

  get isVisible(): boolean {
    /*
      0 = not visible
      1 = visible
    */

    if(this.include_in_menu === 0) {
      return false;
    }

    return true;
  }

  get displayProducts(): Product[] {
    return this.display_products;
  }

  get isSeaFood(): boolean {
    return this.entity_id == 14;
  }

  get metaTitle(): string {
    return this.meta_title ? this.meta_title : "";
  }

  get metaDescription(): string {
    return this.meta_description ? this.meta_description : "";
  }

  get metaKeyword(): string {
    return this.meta_keywords ? this.meta_keywords : "";
  }

  get deepLink(): string {
    return this.deeplink;
  }

  get catBarIcon(): string {
    return this.icon;
  }

  get catBarCloudIcon(): string {
    return this.fill_icon;
  }

  get store(): Store {
    return this._store;
  }

  set store(store: Store) {
    this._store = store;
  }

  constructor() {}

  deserialize(input: any): this {
    this.products = [];
    this.entity_id = input.category.entity_id;
    this.meta_description = input.category.meta_description;
    this.meta_title = input.category.meta_title;
    this.meta_keywords = input.category.meta_keywords;
    this.name = input.category.name;
    this.thumb = input.category.thumb;
    this.image = input.category.image;
    this.deeplink = input.category.deeplink;
    this.url_key = input.category.url_key;
    this.is_active = input.category.is_active;
    this.include_in_menu = input.category.include_in_menu;
    this.icon = input.category.icon;
    this.fill_icon = input.category.fill_icon;
    this.store = input.category._store;

    for (const product of input.products) {
      product.category_url_key = this.url_key;
      const newProduct: Product = new Product().deserialize(product);
      newProduct.store = this.store;
      this.products.push(newProduct);
    }
    this.display_products = this.products.filter(
      (product) => product.isVisibleInCatalog,
    );

    // TODO: FIX ME, causes Redux dev tools to die.
    // associate the category object
    // this.products.forEach((product) => product.category = this);

    return this;
  }

  get urlKey(): string {
    return this.url_key;
  }
/**
 * param ogType
 *    For website 'ogType' is 'website'
 *    For m-site 'ogType' is mobile-site
 */
  ogTagSerializer(ogType: string): {
    metaTitle: string;
    name: string;
    metaDesc: string;
    type: string;
    image: string;
  } {
    return {
      metaTitle: this.metaTitle,
      name: this.name,
      metaDesc: this.metaDescription,
      type: ogType,
      image: this.image,
    };
  }

  twitterTagSerializer(): {
    metaTitle: string;
    name: string;
    metaDesc: string;
    card: string;
    image: string;
  } {
    return {
      metaTitle: this.metaTitle,
      name: this.name,
      metaDesc: this.metaDescription,
      card: "summary",
      image: this.image,
    };
  }

  public populateInventoryForProducts(inventory: any): void {
    for (const product of this.products) {
      if (product.id in inventory) {
        product.inventory = inventory[product.id];
      } else {
        product.inventory = new Inventory();
      }
    }

    // sort the products
    this.products = this.products.sort((a, b) => {
      const cleanUp: (value: any) => any = (value) => (value > 0 ? value : 0);

      // express gets more weightage
      const computeWeight: (item: any) => any = (item) => {
        return cleanUp(item.inventory.today) * 1000 + item.inventory.future;
      };

      const aWeight: any = computeWeight(a);
      const bWeight: any = computeWeight(b);

      // less than
      // check inv first
      if (aWeight < bWeight) {
        return 1;
      } else {
        if (aWeight > bWeight) {
          return -1;
        }
      }

      return 0;
    });
    this.sortProductsByPriority();
  }

  public populatePriceForProducts(price: any): void {
    for (const product of this.products) {
      if (product.id in price) {
        product.price = price[product.id];
      } else {
        product.price = new ProductPrice();
      }
    }
  }

  public populateCrossSellProducts(crossSell: any): void {
    for (const product of this.products) {
      if (product.id in crossSell.related_products) {
        product.relatedProducts = crossSell.related_products[product.id];
      } else {
        product.relatedProducts = [];
      }
    }
  }

  /**
   * Separates todayProducts, tomorrowProducts and OOSProducts from
   * available products. Sorts each group of products by their priority.
   * Then pushes todayProducts,tomorrowProucts, OOSproducts into products array in
   * that order respectively.
   */
  sortProductsByPriority(): void {
    const oosProducts: any[] = [];
    let tomorrowProducts: any[] = [];
    let todayProducts: any[] = [];

    for (const product of this.products) {
      if (!product.isVisibleInCatalog) {
        continue;
      }
      switch (this.getInventoryStatus(product)) {
        case InventoryCode.OOS: {
          oosProducts.push(product);
          break;
        }
        case InventoryCode.AVAILABLE_TODAY: {
          todayProducts.push(product);
          break;
        }
        case InventoryCode.AVAILABLE_TOMORROW: {
          tomorrowProducts.push(product);
          break;
        }
      }
    }

    // sorting today and tomorrow products by their priority
    todayProducts = this.sortByPriorityNo(todayProducts);
    tomorrowProducts = this.sortByPriorityNo(tomorrowProducts);

    // now joining today, tomorrow products and oos products at the end.
    this.display_products = []
      .concat(todayProducts)
      .concat(tomorrowProducts)
      .concat(oosProducts);
  }

  getInventoryStatus(product: Product): InventoryCode {
    if (product.inventory.today > 0) {
      return InventoryCode.AVAILABLE_TODAY;
    }

    if (product.inventory.today <= 0 && product.inventory.future > 0) {
      return InventoryCode.AVAILABLE_TOMORROW;
    }

    return InventoryCode.OOS;
  }

  sortByPriorityNo(prodcuts: Product[]): Product[] {
    return prodcuts.sort((a, b) => {
      if (a.sort_order > b.sort_order) {
        return 1;
      } else {
        if (a.sort_order < b.sort_order) {
          return -1;
        }
      }

      return 0;
    });
  }
}

import { Serializable } from './base';

export class OTP implements Serializable<OTP> {
    public mobile: string;
    public otp: string;

    constructor() {}

    deserialize(input) {
        this.mobile = input.mobile;
        this.otp = input.otp;

        return this;
    }
}

import { GoogleMapsAPIWrapper } from "@agm/core";
import {
  GoogleMap,
  InfoWindow,
  LatLng,
  Marker,
} from "@agm/core/services/google-maps-types";
import {
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import DirectionsResult = google.maps.DirectionsResult;

declare var google: any;
@Directive({
  // TODO
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "agm-direction",
})
// TODO
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class AgmDirection implements OnChanges, OnInit, OnDestroy {
  /**
   * LatLng | String | google.maps.Place
   */
  @Input() origin: LatLng | string | google.maps.Place;

  /**
   * LatLng | String | google.maps.Place
   */
  @Input() destination: LatLng | string | google.maps.Place;

  @Input() travelMode: string = "DRIVING";
  @Input() transitOptions: any = undefined;
  @Input() drivingOptions: any = undefined;
  @Input() waypoints: any = [];
  @Input() optimizeWaypoints: boolean = true;
  @Input() provideRouteAlternatives: boolean = false;
  @Input() avoidHighways: boolean = false;
  @Input() avoidTolls: boolean = false;
  @Input() renderOptions: any;
  @Input() visible: boolean = true;
  @Input() panel: object | undefined;
  @Input() markerOptions: { waypoints: any };
  @Input() infoWindow: InfoWindow = undefined;

  // TODO
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  sendInfoWindow: EventEmitter<InfoWindow> = new EventEmitter<InfoWindow>();
  @Output()
  afterRender: EventEmitter<DirectionsResult> = new EventEmitter<DirectionsResult>();

  public directionsService: any = undefined;
  public directionsDisplay: any = undefined;

  private isFirstChange: boolean = true;

  private originMarker: Marker = undefined;
  private destinationMarker: Marker = undefined;
  private waypointsMarker: Marker[] = [];

  constructor(private gmapsApi: GoogleMapsAPIWrapper) {}

  ngOnInit(): void {
    if (this.visible === true) {
      this.directionDraw();
    }
  }

  ngOnChanges(obj: any): void {
    /**
     * When visible is false then remove the direction layer
     */
    if (!this.visible) {
      try {
        this.removeMarkers();
        this.removeDirections();
      } catch (e) {}
    } else {
      if (this.isFirstChange) {
        /**
         * When visible is false at the first time
         */
        if (typeof this.directionsDisplay === "undefined") {
          this.directionDraw();
        }
        this.isFirstChange = false;

        return;
      }

      /**
       * When renderOptions are not first change then reset the display
       */
      if (typeof obj.renderOptions !== "undefined") {
        if (obj.renderOptions.firstChange === false) {
          this.removeMarkers();
          this.removeDirections();
        }
      }
      this.directionDraw();
    }
  }

  onAfterRender(response: DirectionsResult): void {
    this.afterRender.emit(response);
  }

  ngOnDestroy(): void {
    this.destroyMarkers();
    this.removeDirections();
  }

  /**
   * This event is fired when the user creating or updating this direction
   */
  private directionDraw(): void {
    this.gmapsApi.getNativeMap().then((map: GoogleMap) => {
      if (typeof this.directionsDisplay === "undefined") {
        this.directionsDisplay = new google.maps.DirectionsRenderer({
          suppressMarkers: true,
        });
        this.directionsDisplay.setMap(map);
        this.directionsDisplay.addListener("directions_changed", () => {
          this.onChange.emit(this.directionsDisplay.getDirections());
        });
      }

      if (typeof this.directionsService === "undefined") {
        this.directionsService = new google.maps.DirectionsService();
      }

      if (typeof this.panel === "undefined") {
        this.directionsDisplay.setPanel(null);
      } else {
        this.directionsDisplay.setPanel(this.panel);
      }

      this.directionsService.route(
        {
          origin: this.origin,
          destination: this.destination,
          travelMode: this.travelMode,
          transitOptions: this.transitOptions,
          drivingOptions: this.drivingOptions,
          waypoints: this.waypoints,
          optimizeWaypoints: this.optimizeWaypoints,
          provideRouteAlternatives: this.provideRouteAlternatives,
          avoidHighways: this.avoidHighways,
          avoidTolls: this.avoidTolls,
        },
        (response: DirectionsResult, status: string) => {
          /**
           * DirectionsStatus
           * https://developers.google.com/maps/documentation/javascript/directions#DirectionsStatus
           */
          switch (status) {
            case "OK":
              this.directionsDisplay.setDirections(response);

              /**
               * Emit The DirectionsResult Object
               * https://developers.google.com/maps/documentation/
               * javascript/directions?hl=en#DirectionsResults
               */
              // Custom Markers
              if (typeof this.markerOptions !== "undefined") {
                this.destroyMarkers();

                // Set custom markers
                try {
                  // Waypoints Marker
                  if (typeof this.markerOptions.waypoints !== "undefined") {
                    this.waypoints.forEach((waypoint: any, index: number) => {
                      this.markerOptions.waypoints[index].map = map;
                      this.markerOptions.waypoints[index].label = {
                        text: "" + (index + 1),
                        color: "yellow",
                      };
                      this.markerOptions.waypoints[index].zIndex = 0;
                      this.markerOptions.waypoints[index].position =
                        waypoint.location;
                      this.waypointsMarker.push(
                        this.setMarker(
                          map,
                          waypoint,
                          this.markerOptions.waypoints[index],
                          "",
                        ),
                      );
                    });
                    this.onAfterRender(response);
                  }
                } catch (err) {
                  // TODO
                  // eslint-disable-next-line no-console
                  console.error("MarkerOptions error.", err);
                }
              }

              break;

            default:
              break;
          } // End switch
        },
      );
    });
  }

  /**
   * Custom Origin and Destination Icon
   * @param map map
   * @param marker marker
   * @param markerOpts properties
   * @param content marker's infowindow content
   * @returns new marker
   * memberof AgmDirection
   */
  private setMarker(
    map: GoogleMap,
    marker: any,
    markerOpts: any,
    content: string,
  ): Marker {
    if (typeof this.infoWindow === "undefined") {
      this.infoWindow = new google.maps.InfoWindow({});
    }
    marker = new google.maps.Marker(markerOpts);
    marker.addListener("click", () => {
      const infowindoContent: string =
        typeof markerOpts.infoWindow === "undefined"
          ? content
          : markerOpts.infoWindow;
      this.infoWindow.setContent(infowindoContent);
      this.infoWindow.open(map, marker);
    });

    return marker;
  }

  /**
   * This event is fired when remove markers
   */
  private removeMarkers(): void {
    this.waypointsMarker.forEach((w: any) => {
      if (typeof w !== "undefined") {
        w.setMap(null);
      }
    });
  }

  /**
   * This event is fired when remove directions
   */
  private removeDirections(): void {
    if (this.directionsDisplay !== undefined) {
      this.directionsDisplay.setPanel(null);
      this.directionsDisplay.setMap(null);
      this.directionsDisplay = undefined;
    }
  }

  /**
   * This event is fired when destroy markers
   */
  private destroyMarkers(): void {
    try {
      this.waypointsMarker.forEach((w: any) => {
        if (typeof w !== "undefined") {
          google.maps.event.clearListeners(w, "click");
        }
      });
      this.removeMarkers();
    } catch (err) {
      // TODO
      // eslint-disable-next-line no-console
      console.error("Can not reset custom marker.", err);
    }
  }
}

import { Serializable } from "../base";
import { HyperTrackOrder } from "./hypertrack-order";

export class HyperTrackTrip implements Serializable<HyperTrackTrip> {
  private share_url: string;
  private device_id: string;
  private external_trip_id: string;
  private embed_url: string;
  private remaining_duration: number;
  private remaining_distance: number;
  private arrive_at: string;
  private created_at: string;
  private updated_at: string;
  private driver_order: number;
  private is_delayed: boolean;
  public id: number;
  public order: HyperTrackOrder[];
  public distance: number;
  public duration: number;
  public status: string;
  public trip: number;

  constructor() {}

  get shareUrl(): string {
    return this.share_url;
  }

  get deviceId(): string {
    return this.device_id;
  }

  get externalTripId(): string {
    return this.external_trip_id;
  }

  get embedUrl(): string {
    return this.embed_url;
  }

  get remainingDuration(): number {
    return this.remaining_duration;
  }

  get remainingDistance(): number {
    return this.remaining_distance;
  }

  get arriveAt(): string {
    return this.arrive_at;
  }

  get createdAt(): string {
    return this.created_at;
  }

  get updatedAt(): string {
    return this.updated_at;
  }

  get driverOrder(): number {
    return this.driver_order;
  }

  get isDelayed(): boolean {
    return this.is_delayed;
  }

  get isOrdersCompleted(): boolean {
    return this.order.every(
      (hyperTrackOrder: HyperTrackOrder) => hyperTrackOrder.isCompleted
    );
  }

  get isCompleted(): boolean {
    return this.status === "completed";
  }

  deserialize(input) {
    if (input == undefined) {
      return this;
    }
    this.share_url = input.string;
    this.device_id = input.device_id;
    this.external_trip_id = input.external_trip_id;
    this.embed_url = input.embed_url;
    this.remaining_duration = input.remaining_duration;
    this.remaining_distance = input.remaining_distance;
    this.arrive_at = input.arrive_at;
    this.created_at = input.created_at;
    this.updated_at = input.updated_at;
    this.driver_order = input.driver_order;
    this.is_delayed = input.is_delayed;
    this.distance = input.distance;
    this.duration = input.duration;
    this.status = input.status;
    this.trip = input.trip;
    this.order = input.order;
    this.id = input.id;

    return this;
  }
}

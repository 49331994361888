import { Serializable } from "../base";
import { CampaignWinners } from "./campaign-winners";

/**
 * Model of referral campaigns
 */
export class ReferralCampaign implements Serializable<ReferralCampaign> {
  private start_time: string;
  private end_time: string;
  private days_left: number;
  private participants_count: number;
  private short_description: string;
  public name: string;
  private campaign_banner: string;
  public winners: CampaignWinners[];

  constructor() {}

  deserialize(input) {
    this.start_time = input.start_date;
    this.end_time = input.end_date;
    this.name = input.campaign_name;
    this.days_left = input.days_left;
    this.participants_count = input.participants_count;
    this.short_description = input.short_description;
    this.campaign_banner = input.campaign_banner;
    this.winners = [];

    if (input.winners && input.winners.length) {
      input.winners.forEach((winner) => {
        this.winners.push(new CampaignWinners().deserialize(winner));
      });
    }

    return this;
  }

  get startedDate() {
    return this.start_time;
  }

  get endDate() {
    return this.end_time;
  }

  get shortDescription() {
    return this.short_description;
  }

  get daysLeft() {
    return this.days_left > 0 ? this.days_left : 0;
  }

  get participants() {
    return this.participants_count;
  }

  // to check  for active campaign
  get isActive() {
    return this.daysLeft > 0 ? true : false;
  }

  //to get a campaign image
  get campaignBanner() {
    return this.campaign_banner;
  }
}

<div class="tc-reward">
  <mat-label class="row label no-gutters">Enter TC Rewards Amount</mat-label>
  <mat-form-field>
    <input
      matInput
      type="number"
      class="row"
      placeholder="Enter amount"
      autocomplete="off"
      max="1000"
      [formControl]="rewardPointsControl"
      autofocus
    />
  </mat-form-field>
  <mat-label class="row label no-gutters">Select The Order</mat-label>
  <mat-form-field>
    <mat-select placeholder="Order Id" [formControl]="orderId">
      <mat-option *ngIf="isCcManager" [value]="mockOrderId">
        {{ mockOrderId }}
      </mat-option>
      <mat-option *ngFor="let order of orders" [value]="order.incrementId">
        {{ order.incrementId }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-label class="row label no-gutters">Comments</mat-label>
  <mat-form-field>
    <textarea
      matInput
      type="text"
      class="row"
      placeholder="Enter Comment"
      [formControl]="commentControl"
    ></textarea>
  </mat-form-field>
  <div class="row comment_btn">
    <button
      [disabled]="!rewardPointsControl.value"
      id="submitbtn"
      mat-button
      class="col-10"
      (click)="submitTcRewards()"
    >
      Submit
    </button>
  </div>
</div>

import { Injectable } from "@angular/core";

import { BaseGetProvider, DataTransformationProtocol } from "@tendercuts/http";
import { SaleOrder } from "@tendercuts/models";

import { HttpClient, HttpParams } from "@angular/common/http";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";

@Injectable()
export class FetchNewOrdersService
  extends BaseGetProvider<SaleOrder>
  implements DataTransformationProtocol {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
    this.delegate = this;
  }

  // override this
  preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof SaleOrder {
    return SaleOrder;
  }

  get endpoint(): string {
    return this.endpointService.getNewOrders();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(storeId: string, createdAt: any): HttpParams {
    if (createdAt) {
      createdAt = createdAt.toISOString();
    } else {
      createdAt = "";
    }

    let params: HttpParams = new HttpParams();
    params = params.append("store_id", storeId);
    params = params.append("created_at", createdAt);

    return params;
  }
}

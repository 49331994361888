<h5>Select SMS Template</h5>
<form>
  <mat-form-field>
    <mat-select
      placeholder="Select the content"
      [formControl]="smsControl"
      [(value)]="selected"
      required
    >
      <mat-option>--</mat-option>
      <mat-option *ngFor="let message of messages" [value]="message">
        {{ message }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
<div id="buttons" *ngIf="selected != null">
  <button color="primary" mat-raised-button mat-dialog-close>Cancel</button>
  <button id="send-btn" color="primary" mat-raised-button (click)="sendSms()">
    Send
  </button>
</div>

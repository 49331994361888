
import {ResponseTotal} from './sale-quote';



/**
 * cartTotal is used in redux cart state for displaying totals.
 */
export class CartTotal {
  public subtotal: number = 0;
  public credit: number = 0;
  public discount: number = 0;
  public shipping: number = 0;
  public actualShipping: number = 0;
  public grandTotal: number = 0;
  public taxAmount: number = 0;
  public gstAmount: number = 0;
  public packingCharges: number = 0;
  public baseSubtotal: number = 0;
  public eliteSavings: number = 0;
  public deliverySavings: number = 0;
  public totalSavings: number = 0;


  public rewardPointsEarn: number = 0;
  public rewardPointsSpend: number = 0;
  public packingcharges_total: number = 0;
  public eliteSavingsPos: number = 0;
  public convenienceFee: number = 0;

  static fromTotals(total: ResponseTotal) {
    const cartTotal = new CartTotal();
    cartTotal.subtotal = total.subtotal;
    cartTotal.credit = total.credit;
    cartTotal.discount = total.discount;
    cartTotal.shipping = total.shipping;
    cartTotal.actualShipping = total.actual_shipping;
    cartTotal.grandTotal = total.grandTotal;
    cartTotal.taxAmount = total.tax_amount;
    cartTotal.gstAmount = total.gstAmount;
    cartTotal.rewardPointsEarn = total.rewardsEarn;
    cartTotal.rewardPointsSpend = total.rewardsSpend;
    cartTotal.packingCharges = total.packingCharges;
    cartTotal.eliteSavingsPos = total.eliteSavingsPos;
    cartTotal.baseSubtotal = total.baseSubtotal;
    cartTotal.eliteSavings = total.eliteSavings;
    cartTotal.deliverySavings = total.deliverySavings;
    cartTotal.totalSavings = total.totalSavings;
    cartTotal.convenienceFee = total.convenienceFee;
    cartTotal.packingcharges_total = total.packingcharges_total;

    return cartTotal;
  }

}

<div class="top-nav-bar-wrap">
  <mat-form-field class="col-9">
    <input
      type="text"
      id="order-id"
      placeholder="Enter Order Number"
      aria-label="Number"
      matInput
      [(ngModel)]="orderNumber"
      (keyup.enter)="resolveOrder()"
    />
  </mat-form-field>
  <button
    class="col-2"
    id="search-order"
    mat-stroked-button
    (click)="resolveOrder()"
  >
    Search Order
  </button>
</div>

<div *ngIf="order">
  <app-order-display
    (refreshParent)="getOrder()"
    [order]="order"
  ></app-order-display>
</div>

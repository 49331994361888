// TODO
/* eslint-disable */
import { Component, Input } from "@angular/core";
import { NotificationsMenuService } from "../notifications-menu.service";

@Component({
  selector: "notifications-menu-button",
  styleUrls: ["./styles/notifications-menu-button.scss"],
  templateUrl: "./notifications-menu-button.component.html",
})
export class NotificationsMenuButtonComponent {
  @Input("notifications") notifications: any[] = [];

  constructor(private notificationsMenuService: NotificationsMenuService) {}

  toggleNotificationsMenu(): void {
    this.notificationsMenuService.sidenav.toggle();
  }
}

import { RatingTag } from "./rating-tag";

export class PriorityOrder {
    public orderId : string;
    public customerID : string;
    public ratingtags : RatingTag[] = [];
    public tagCateogry : string[] = [];


    deserialize(input){
        this.orderId = input.increment_id;
        this.customerID = input.csutomer;
        var tagCateogryArray: string[] = [];
        input.complaint_tag.forEach(tag => {
            this.ratingtags.push( new RatingTag().deserialize(tag) )
            tagCateogryArray.push(tag.tag_category);
        });
        const set = new Set(tagCateogryArray)
        this.tagCateogry = Array.from(set);
        return this;
    }
}
import { Serializable } from "../base";

export class RiderProjection implements Serializable<RiderProjection> {
  private count: number;
  private day_of_week: number;
  private enable_direct_full_shift: boolean;
  private enable_first_shift: boolean;
  private enable_second_shift: boolean;
  private id: number;
  private second_count: number;
  private store_id: number;
  private first_booked_count: number;
  private second_booked_count: number;

  constructor() {}

  deserialize(input) {
    this.count = input.count;
    this.day_of_week = input.day_of_week;
    this.enable_direct_full_shift = input.enable_direct_full_shift;
    this.enable_first_shift = input.enable_first_shift;
    this.enable_second_shift = input.enable_second_shift;
    this.id = input.id;
    this.second_count = input.second_count;
    this.store_id = input.store_id;
    this.first_booked_count = input.first_booked_count;
    this.second_booked_count = input.second_booked_count;

    return this;
  }

  get bookingCount() {
    return this.count;
  }

  get bookedDay() {
    return this.day_of_week;
  }

  get projectionId() {
    return this.id;
  }

  get storeId() {
    return this.store_id;
  }

  get firstShiftCount() {
    return this.count;
  }

  get secondShiftCount() {
    return this.second_count;
  }

  get enableFirstShift() {
    return this.enable_first_shift;
  }

  get enableSecondShift() {
    return this.enable_second_shift;
  }

  get enableDirectFullShift() {
    return this.enable_direct_full_shift;
  }

  get firstShiftBookedCount() {
    return this.first_booked_count;
  }

  get secondShiftBookedCount() {
    return this.second_booked_count;
  }

  /**
   * If enable direct full shift is true get second shift count else return 0
   */
  get fullShiftCount() {
    return this.enableDirectFullShift ? this.second_count : 0;
  }

  //  If enable direct full shift is true get second shift booked count else return 0
  get fullShiftBookedCount() {
    return this.enableDirectFullShift ? this.second_booked_count : 0;
  }
}

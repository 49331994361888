import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";

import { AppState } from "@store/state";
import { InventoryRequest, InventoryRequestStatus } from "@tendercuts/models";
import {
  FetchInventoryRequestService,
  StockApprovalService,
} from "@tendercuts/providers";

import { Filter, FilterGroup, FilterMatch, FilterModel } from "src/models";
import { ButtonAction, InventoryRequestDataSource } from "../../components";
import { BasePage } from "../../utils";

@Component({
  selector: "app-stock-approval",
  templateUrl: "./stock-approval.component.html",
  styleUrls: ["./stock-approval.component.scss"],
})
export class StockApprovalComponent extends BasePage implements OnInit {
  buttonActions: ButtonAction[] = [
    new ButtonAction("Approve", "approve", this.approveRequest.bind(this)),
    new ButtonAction("Reject", "reject", this.rejectRequest.bind(this)),
  ];

  searchFilterGroup: FilterGroup = new FilterGroup([
    new Filter("Search", null, ["name", "sku"], FilterMatch.CONTAINS),
  ]);

  modelFilter: FilterModel = new FilterModel([this.searchFilterGroup]);
  requests: InventoryRequestDataSource = new InventoryRequestDataSource(
    this.modelFilter,
    [],
  );
  loading: boolean = false;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public inventoryRequestService: FetchInventoryRequestService,
    public approveService: StockApprovalService,
  ) {
    super(dialog, snackBar, store);
  }
  ngOnInit(): void {
    this.refreshData();
  }

  refreshData(): void {
    this.loading = true;
    this.inventoryRequestService.getData().subscribe((data) => {
      this.requests.data = data;
      this.loading = false;
    });
  }

  /**
   * Searches for orderId
   * @param orderId
   */
  applySearchFilter(searchString: string): void {
    if (searchString === "") {
      this.searchFilterGroup.filters[0].selected = false;
      this.searchFilterGroup.filters[0].value = null;
    } else {
      this.searchFilterGroup.filters[0].selected = true;
      this.searchFilterGroup.filters[0].value = searchString;
    }

    this.requests.filter = "true";
  }

  approveRequest(event: any, request: InventoryRequest): void {
    // Approved
    request.status = InventoryRequestStatus.APPROVED;
    this.submitRequest(request);
  }

  rejectRequest(event: any, request: InventoryRequest): void {
    // rejected
    request.status = InventoryRequestStatus.REJECTED;
    this.submitRequest(request);
  }

  /**
   * Attached as a callback to button action.
   * Create a new inventory request model and commit it to server.
   * @param Product product
   */
  submitRequest(request: InventoryRequest): void {
    this.presentLoader();
    this.approveService.getData(request).subscribe(
      (data) => {
        this.dismissLoader();
        this.showNotification(
          "top",
          "center",
          "success",
          "info-circle",
          "Request Successful",
        );
        this.refreshData();
      },
      (error) => {
        this.showNotification(
          "top",
          "center",
          "danger",
          "info-circle",
          "Request Failed",
        );

        this.dismissLoader();
      },
    );
  }
}

<mat-card class="card">
  <mat-card-title class="card__title"> Incentive Earning Info </mat-card-title>
  <mat-card-content class="card__content">
    <ol type="1">
      <div *ngFor="let comment of comments; let ind = index">
        <li class="card__content__list" *ngIf="comment">
          {{ comment }}
        </li>
      </div>
    </ol>
    <div class="card__actions">
      <button
        (click)="dismissPage()"
        class="card__actions__btn"
        mat-stroked-button
      >
        Done
      </button>
    </div>
  </mat-card-content>
</mat-card>

<ng-container *ngIf="products.data.length > 0">
  <div class="row">
    <mat-form-field class="row">
      <input
        matInput
        (keyup)="applySearchFilter($event.target.value)"
        placeholder="Search product"
      />
    </mat-form-field>
  </div>
  <app-inventory-request-grid [actions]="buttonActions" [dataSource]="requests">
  </app-inventory-request-grid>
</ng-container>

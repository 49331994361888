import { Serializable } from "../base";

export class BookingRiderDetail implements Serializable<BookingRiderDetail> {
  private phone_number: string;
  private first_name: string;
  private id: number;

  get phoneNumber() {
    return this.phone_number;
  }

  get firstName() {
    return this.first_name;
  }

  get driverId() {
    return this.id;
  }

  constructor() {}

  deserialize(input) {
    this.phone_number = input.username;
    this.first_name = input.first_name;
    this.id = input.id;

    return this;
  }
}

export class BookingRequest implements Serializable<BookingRequest> {
  public id: number;
  private created_at: string;
  private updated_at: string;
  private booking_date: Date;
  private store_id: number;
  private is_present: boolean;
  private status: string;
  private driver_user: number;
  private is_actionable: boolean;
  private driver_details: BookingRiderDetail;
  private expire_at: string;
  private shift: string;
  private enable_direct_full_shift: boolean;
  private enable_first_shift: boolean;
  private enable_second_shift: boolean;
  private request_full_shift: boolean;
  private store_code: string;
  private first_shift_deadline: string;
  private second_shift_deadline: string;
  public title: string;
  public subtitle: string;

  constructor() {}

  deserialize(input) {
    this.id = input.id;
    this.created_at = input.created_at;
    this.updated_at = input.updated_at;
    this.booking_date = input.booking_date;
    this.store_id = input.store_id;
    this.is_present = input.is_present;
    this.status = input.status;
    this.driver_user = input.driver_user;
    this.is_actionable = input.is_actionable;
    this.title = input.title;
    this.subtitle = input.subtitle;
    this.expire_at = input.expire_at;
    this.shift = input.shift;
    this.enable_direct_full_shift = input.enable_direct_full_shift;
    this.enable_first_shift = input.enable_first_shift;
    this.enable_second_shift = input.enable_second_shift;
    this.request_full_shift = input.request_full_shift ? true : false;
    this.store_code = input.store_code;
    this.first_shift_deadline = input.first_shift_deadline;
    this.second_shift_deadline = input.second_shift_deadline;

    if (input.driver_user.isNumber) {
      this.driver_user = input.driver_user;
    } else {
      this.driver_details = new BookingRiderDetail().deserialize(
        input.driver_user
      );
    }

    return this;
  }

  serialize() {
    return {
      id: this.id,
      created_at: this.created_at,
      updated_at: this.updated_at,
      booking_date: this.booking_date,
      store_id: this.store_id,
      is_present: this.is_present,
      status: this.status,
      is_actionable: this.is_actionable,
      title: this.title,
      subtitle: this.subtitle,
      expire_at: this.expire_at,
      shift: this.shift,
      enable_direct_full_shift: this.enable_direct_full_shift,
      enable_first_shift: this.enable_first_shift,
      enable_second_shift: this.enable_second_shift,
      request_full_shift: this.request_full_shift,
      store_code: this.store_code,
      first_shift_deadline: this.first_shift_deadline,
      second_shift_deadline: this.second_shift_deadline,
      driver_user: this.driverProfileId
    }
  }

  get shiftName() {
    return this.shift;
  }

  get shiftStatus() {
    switch (this.shift) {
      case "first":
        return "First Shift";

      case "second":
        return "Second Shift";

      case "full_shift":
        return "Full shift";

      case "request_full":
        return "Full Shift Requested";
    }
  }

  get createdAt() {
    return this.created_at;
  }

  get updatedAt() {
    return this.updated_at;
  }

  get serialNo() {
    return this.id;
  }

  /**
   * To display expire date of cancel request
   */
  get expireAt() {
    let dateTime = new Date(this.expire_at)
      .toString()
      .split(" ")
      .slice(0, 5)
      .join(" ");
    return dateTime;
  }

  get expireAtTime() {
    return this.expire_at;
  }

  get respondDateTime() {
    if (this.status == "pending") {
      return "No Response";
    }
    let dateTime = new Date(this.updated_at)
      .toString()
      .split(" ")
      .slice(0, 5)
      .join(" ");
    return dateTime;
  }

  get bookedAt() {
    return this.booking_date;
  }

  get bookedDate() {
    return new Date(this.booking_date).toDateString();
  }

  get driverBookedDate() {
    return this.booking_date;
  }

  get storeId() {
    return this.store_id;
  }

  // set the selected store
  setStore(id: number) {
    this.store_id = id;
  }

  // set the selected shift
  setShift(shift: string) {
    this.shift = shift;
  }

  // set the selected store code
  setStoreCode(name: string) {
    this.store_code = name;
  }

  get isPresent() {
    return this.is_present;
  }

  get driverAttendance() {
    return this.is_present == true ? "Present" : "Absent";
  }

  get bookingStatus() {
    return this.status;
  }

  get driverId() {
    return this.driver_user;
  }

  get isActionable() {
    return this.is_actionable;
  }

  get driverDetails() {
    return this.driver_details;
  }

  get driverProfileId() {
    return isNaN(this.driver_user) ? this.driverDetails.driverId : this.driver_user;
  }

  /**
   * status is confirm &
   * isPresent true, means returns true
   */
  get isShow() {
    return this.status == "confirm" && this.isPresent == true;
  }

  /**
   * status is confirm &
   * isPresent false , means returns true
   */
  get noShow() {
    return this.status == "confirm" && this.isPresent == false;
  }

  /**
   * status is pending || status is reject
   * isActionable false, means return true
   */
  get isPending() {
    return (
      (this.status == "pending" || this.status == "reject") &&
      this.isActionable == true
    );
  }

  // status is reject, returns true
  get requestRejected() {
    return this.status == "reject";
  }

  /**
   * status is pending &
   * isActionable is false, means returns true
   */
  get notResponded() {
    return this.status == "pending" && this.isActionable == false;
  }

  /**
   * status is confirm &
   * isActionable is true, means returns true
   */
  get canRequestCancellation() {
    return this.status == "confirm" && this.isActionable == true;
  }

  /**
   * isActionable is true,
   * status != confirm
   */
  get isBlinkable() {
    return this.isActionable == true && this.status != "confirm";
  }

  get enableFirstShift() {
    return this.enable_first_shift;
  }

  get enableSecondShift() {
    return this.enable_second_shift;
  }

  get enableDirectFullShift() {
    return this.enable_direct_full_shift;
  }

  get enableRequestForFullShift() {
    return this.request_full_shift;
  }

  get storeName() {
    return this.store_code;
  }

  get firstShiftDeadline() {
    let time = this.first_shift_deadline
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
      this.first_shift_deadline,
    ];
    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? "AM" : "PM";
      time[0] = (+time[0] % 12 || 12).toLocaleString();
    }
    return time.join("");
  }

  get secondShiftDeadline() {
    let time = this.second_shift_deadline
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
      this.second_shift_deadline,
    ];
    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? "AM" : "PM";
      time[0] = (+time[0] % 12 || 12).toLocaleString();
    }
    return time.join("");
  }

  /**
   * convert the today date into ISO string format YYYY-MM-DD
   * convert booking date also same ISO string format
   * if both equal returns true else false
   */
  get isTodayBooking() {
    return (
      new Date().toISOString().split("T")[0] ==
      new Date(this.booking_date).toISOString().split("T")[0]
    );
  }

   /**
   * convert the today date into ISO string format YYYY-MM-DD
   * convert booking date also same ISO string format
   * if both equal returns true else false
   */
  get isTomorrowBooking() {
    let tomorrowDate = new Date(Date.now() + 24 * 60 * 60 * 1000)
    return (
      tomorrowDate.toISOString().split("T")[0] ==
      new Date(this.booking_date).toISOString().split("T")[0]
    );
  }

  set requestFullShift(data: boolean) {
    this.request_full_shift = data;
  }

  set shiftSelected(data: string) {
    this.shift = data;
  }

  set bookingDate(date: Date) {
    this.booking_date = date;
  }

  set setShiftStatus(data: string) {
    this.status = data;
  }
}

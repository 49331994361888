import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { SaleOrder, User } from "@tendercuts/models";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AppState } from "@store/state";
import { Store } from "@ngrx/store";
import { BasePage } from "src/app/utils";
import { OrderHistoryResponse, OrderHistoryService } from "src/providers";
import { map, skipWhile, take } from "rxjs/operators";

interface StatusFilterSelectedOption {
  icon: string;
  label: string;
  value: string;
}
interface DateFilterSelectedOption {
  fromDate: string;
  label: string;
  toDate: string;
  value: string;
}

@Component({
  selector: "app-all-orders",
  templateUrl: "./all-orders.component.html",
  styleUrls: ["./all-orders.component.scss"],
})
export class AllOrdersComponent extends BasePage implements OnInit, OnDestroy {
  orders: SaleOrder[] = [];

  city: string = "delhi";

  totalOrders: number = 0;

  canFetch: boolean = true;

  page: number = 1;

  isLoading: boolean = true;

  statusFilterSelectedOption: StatusFilterSelectedOption = {
    icon: "status-all",
    label: "All Orders",
    value: "all",
  };

  dateFilterSelectedOption: DateFilterSelectedOption = {
    label: "Today",
    value: "today",
    fromDate: this.serverDateConverter(new Date()),
    toDate: this.serverDateConverter(new Date()),
  };

  @ViewChild("elementRef") elementRef: ElementRef;

  observer: IntersectionObserver;

  titleToDisplay: string[] = [
    "Order Id",
    "Cust Name",
    "Slot",
    "Order Amount",
    "Payment Name",
    "Store",
    "Status",
  ];

  columnsToDisplay: string[] = [
    "incrementId",
    "firstname",
    "shippingDescription",
    "total",
    "payment_method",
    "storeName",
    "status",
  ];

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public orderHistoryService: OrderHistoryService
  ) {
    super(dialog, snackBar, store);
  }

  async ngOnInit(): Promise<void> {
    this.city = await this.store
      .select((appState) => appState.userState.user)
      .pipe(
        skipWhile((user) => !user),
        take(1),
        map((user: User) => (user.isGtgUser ? "delhi" : "chennai"))
      )
      .toPromise();
    this.infiniteScrollSubscribe();
    this.fetchOrder();
  }

  /**
   * InfiniteScrollSubscribe sets up an IntersectionObserver to enable infinite scrolling.
   * When the observed element enters the viewport, it triggers a data fetch if allowed.
   * The observer is disconnected if further fetching is disabled.
   */
  infiniteScrollSubscribe() {
    const threshold = 0.2;
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && this.canFetch && this.page > 1) {
            this.fetchOrder();
          }
        });
      },
      { threshold }
    );

    this.observer.observe(this.elementRef.nativeElement);
  }

  /**
   * Fetches the order history data based on the selected filters and updates the state.
   * If an error occurs during the fetch, appropriate actions are taken based on the error type.
   */
  async fetchOrder(): Promise<void> {
    this.isLoading = true;
    try {
      const orderHistoryResponse: OrderHistoryResponse =
        await this.orderHistoryService
          .getData({
            from: this.dateFilterSelectedOption.fromDate,
            to: this.dateFilterSelectedOption.toDate,
            status: this.statusFilterSelectedOption.value,
            city: this.city,
            page: this.page,
          })
          .toPromise();

      this.totalOrders = orderHistoryResponse?.count;
      this.orders = [...this.orders, ...orderHistoryResponse.results];
      this.page += 1;
    } catch (err: any) {
      if (err.status === 404) {
        this.canFetch = false;
      } else {
        this.somethingWentWrong();
      }
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * Handles changes to the status filter. Resets the order list and fetches new data if the selected status changes.
   * @param {StatusFilterSelectedOption} selectedOption - The newly selected status filter option.
   */
  onStatusFilterChange(selectedOption: StatusFilterSelectedOption): void {
    if (this.statusFilterSelectedOption.value !== selectedOption.value) {
      this.statusFilterSelectedOption = selectedOption;
      this.resetAndFetchOrders();
    }
  }

  /**
   * Handles changes to the date filter. Resets the order list and fetches new data if the selected date changes.
   * @param {DateFilterSelectedOption} selectedOption - The newly selected date filter option.
   */
  onDateFilterChange(selectedOption: DateFilterSelectedOption): void {
    if (this.dateFilterSelectedOption.label !== selectedOption.label) {
      this.dateFilterSelectedOption = selectedOption;
      this.resetAndFetchOrders();
    }
  }

  /**
   * Resets the order list, total orders count, and page number. Then fetches new order data.
   */
  resetAndFetchOrders(): void {
    this.orders = [];
    this.totalOrders = 0;
    this.page = 1;
    this.canFetch = true;
    this.fetchOrder();
  }

  /**
   * Converts a given Date object to a string formatted as 'YYYY-MM-DD' suitable for server use.
   * @param {Date} date - The date to be converted.
   * @returns {string} - The formatted date string.
   */
  serverDateConverter(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    return date.toLocaleDateString("en-CA", options);
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}

import { GenericServerResponse, ScanItem } from "@tendercuts/models";

export class BarcodeValidator {
  /**
   * function validateBarcode
   * @param barcodeText
   * checks if each letter in the barcode is a number
   * check barcode length must be only 8, 13, 12 and 17 digits only
   */

  static validateBarcode(barcodeText: string | any[]): any {
    const response: any = {
      status: false,
      message: [],
    };
    if (barcodeText == undefined || barcodeText == null || barcodeText == "") {
      response.message = ["No barcode detected", "Please try again"];

      return response;
    }
    if (
      barcodeText.length !== 8 &&
      barcodeText.length !== 17 &&
      barcodeText.length !== 13 &&
      barcodeText.length !== 12
    ) {
      response.message = ["Invalid barcode", "Please check the barcode"];

      return response;
    }
    // TODO
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i: number = 0; i < barcodeText.length; i++) {
      if (isNaN(barcodeText[i])) {
        response.message = ["Invalid barcode", "Please check the barcode"];

        return response;
      }
    }
    response.status = true;

    return response;
  }

  /**
   * function validateWeight
   * @param barcodeText
   * @param saleOrder
   * 0.Splitting the barcode text to get product id and weight.
   * if the barcode is in 8 digit 0-3 is pid number and next digit as weight
   * if barcode is 17 digit 13-4 digit as pid number and 4-5 digit is weight
   * 1.check product scannable
   * 2.checking weight of the product limit
   * 3.Validating weight
   * 4.Adding weight
   */
  static validateItem(barcodeText: string, scanItems: ScanItem[]): void {
    let scannedId: number;
    let scannedWeight: number;
    const response: any = {
      status: false,
      message: [],
      item: {},
      weight: 0,
    };

    scannedId =
      barcodeText.length == 8
        ? Number.parseInt(barcodeText.substring(0, 3))
        : Number.parseInt(barcodeText.substring(17, 13));

    scannedWeight =
      barcodeText.length == 8
        ? Number.parseInt(barcodeText.substring(3))
        : Number.parseInt(barcodeText.substring(4, 9));
    const sameProducts: any = scanItems.filter(
      (item: ScanItem) => item.productId == scannedId
    );
    for (const itemspec of sameProducts) {
      if (
        itemspec.productId == scannedId &&
        itemspec.noOfTimesScanned != itemspec.noOfTimesToBeScanned
      ) {
        const weightResponse: any = this.validateWeight(
          itemspec,
          scannedWeight
        );
        if (!weightResponse.status) {
          response.message = [
            "Please check the weight of the product",
            "weight should be between " +
              itemspec.spec.weightFrom +
              " - " +
              itemspec.spec.weightTo +
              "grams",
          ];
        }
        response.status = weightResponse.status;
        response.item = weightResponse.item;
        response.weight = weightResponse.weight;

        return response;
      }
      response.status = true;
      response.item = itemspec;
      response.weight = scannedWeight;
    }
    response.message = [
      "Wrong Product Scanned",
      "Please ensure you scanned the right product",
    ];

    return response;
  }

  static validateWeight(itemspec: ScanItem, scannedWeight: number): any {
    const response: any = {
      status: false,
      item: {},
      weight: 0,
    };
    const weightFrom: number = itemspec.spec.shouldValidateWeight
      ? itemspec.spec.weightFrom
      : 100;
    const weightTo: number = itemspec.spec.shouldValidateWeight
      ? itemspec.spec.weightTo
      : 3500;
    if (weightFrom <= scannedWeight && scannedWeight <= weightTo) {
      response.status = true;
      response.item = itemspec;
      response.weight = scannedWeight;

      return response;
    }

    return response;
  }

  static isAleardyScannedItem(scanItems: ScanItem): any {
    const response: any = {
      status: false,
      message: [],
    };
    if (scanItems.isScanned) {
      response.message = [
        "Already scanned product detected",
        "Please check the product scanned",
      ];

      return response;
    }
    response.status = true;

    return response;
  }
}

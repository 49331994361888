// TODO
/* eslint-disable */
import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";

interface BreadCrumb {
  label: string;
  url: string;
}

@Component({
  selector: "breadcrumb",
  styleUrls: ["./styles/breadcrumb.scss"],
  templateUrl: "./breadcrumb.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  public breadcrumbs: BreadCrumb[] = [];
  public routerEventSubcriber: Subscription;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    setTimeout(() => {
      this.breadcrumbs = this.buildBreadCrumb(
        this.activatedRoute.root,
        "",
        this.breadcrumbs,
      );
    });
  }

  /**
   * listen the route params change if the there is any change in the route
   * parameter it will fetch the data according to the activated route value
   */
  ngOnInit(): void {
    this.routerEventSubcriber = this.router.events.subscribe((data) => {
      if (data instanceof NavigationEnd) {
        this.breadcrumbs = this.buildBreadCrumb(
          this.activatedRoute.root,
          "",
          this.breadcrumbs,
        );
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routerEventSubcriber) {
      this.routerEventSubcriber.unsubscribe();
    }
  }

  buildBreadCrumb(
    route: ActivatedRoute,
    url: string = "",
    breadcrumbs: BreadCrumb[] = [],
  ): BreadCrumb[] {
    // If no routeConfig is avalailable we are on the root path
    let label: string =
      route.routeConfig && route.routeConfig.data
        ? route.routeConfig.data.breadcrumb
        : "";
    let path: string =
      route.routeConfig && route.routeConfig.data ? route.routeConfig.path : "";
    // If the route is dynamic route such as ':id', remove it
    const lastRoutePart: string = path.split("/").pop();
    const isDynamicRoute: boolean = lastRoutePart.startsWith(":");
    /*
    *If the route is dynamic route such as ':id'
    split that id and append to label
    */
    if (isDynamicRoute) {
      // split the path into segments
      const segments: string[] = path.split("/");
      for (const segment of segments) {
      // segments dont start with ":" it will continues
      // else it will break and come out of loop
        if (!segment.startsWith(":")) {
          continue;
        }
        // here split the segment and replace the params value to segment
        // and update the label by params values
        const paramName: string = segment.split(":")[1];
        path = path.replace(segment, route.snapshot.params[paramName]);
        label += `[${route.snapshot.params[paramName]}]`;
      }
    }

    // In the routeConfig the complete path is not available,
    // so we rebuild it each time
    const nextUrl: string = path ? `${url}/${path}` : url;

    const breadcrumb: BreadCrumb = {
      label,
      url: nextUrl,
    };

    // Only adding route with non-empty label
    const newBreadcrumbs: BreadCrumb[] = breadcrumb.label
      ? [...breadcrumbs, breadcrumb]
      : [...breadcrumbs];

    /*
    * if the array length of the breadcrumbs is more than 5
    * remove the first element from array
    */
    if (newBreadcrumbs.length > 5) {
      newBreadcrumbs.shift();
    }
    if (route.firstChild) {
      // If we are not on our current path yet,
      // there will be more children to look after, to build our breadcumb

      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }

    return newBreadcrumbs;
  }
}

<div class="content">
  <mat-chip-list>
    <ng-container *ngFor="let order of trip.driverOrder; let last = last">
      <div class="chip-container">
        <div 
          class="chip-container__slot-data" 
          *ngIf="order.saleOrder?.shippingDescription"
        >
            <span>
              {{
                order.saleOrder?.deliveryType === 2
                  ? order.saleOrder?.shippingDescription
                  : (order.saleOrder?.slotDescription
                  + (order.saleOrder?.deliveryType === 1 ? " Order (120 mins)" : ""))
              }}
            </span>
        </div>
        <div class="chip-container__chip">
          <mat-chip [ngClass]="{
              'order-complete-chip': order.isComplete,
              'order-scanned-chip': order.saleOrder?.isScanned,
              'central-scanned-chip': order.saleOrder?.isCentralScanned,
              'is-active-chip': order.isActiveOrder == false
            }" (click)="selectOrder(order)">
            <div class="d-flex flex-column">
              <span>
                {{ order?.incrementId }}
                <ng-container *ngIf="order.saleOrder &&
                    order.saleOrder.status !== 'complete' && order.isActiveOrder
                  ">
                  - ({{ order.saleOrder?.timeRemaining }} min)
                </ng-container>
              </span>
            </div>
          </mat-chip>
        </div>
        <div 
          class="chip-container__sub-status"
        >
          {{ order.saleOrder?.subStatus }}
        </div>
        <div class="chip-container__dot">
          <span class="dot" *ngIf="!last">-----</span>
        </div>
      </div>
    </ng-container>
  </mat-chip-list>
</div>
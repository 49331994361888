import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BaseGetProvider } from "@tendercuts/http";
import { DeliveryDate } from "@tendercuts/models";

@Injectable({
  providedIn: "root",
})
export class FetchDeliverySlotService extends BaseGetProvider<DeliveryDate> {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http);
    this.delegate = this;
  }

  // override this
  public preProcessResponse(response: any): Array<any> {
    return response;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof DeliveryDate {
    return DeliveryDate;
  }

  get endpoint(): string {
    return this.endpointService.slotCc();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(quoteId: string): HttpParams {
    let params: HttpParams = new HttpParams();
    params = params.append("quote_id", quoteId.toString());

    return params;
  }

  // To load delivery slot details,storeId is passed
  getStoreParams(
    storeId: number = 0,
  ): {
    store_id: string;
  } {
    const params: {
      store_id: string;
    } = {
      store_id: storeId.toString(),
    };

    return params;
  }
}

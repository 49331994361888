<div class="trip-cash col-12">
  <h5>Today's TripCash Details</h5>
  <div class="trip-cash__header">
    <div>
      Total Collected Cash : <b id="total-cash-collected">{{ totalCashCollected }}</b>
    </div>
    <button
      *ngIf="tripData.length"
      mat-raised-button
      color="primary"
      id="download-csv-button"
      (click)="downloadCsv()"
    >
      Download
    </button>
  </div>
</div>

<div id="trip-scroll" *ngIf="todaysTrip">
  <mat-card>
    <mat-table
      #matTable
      [dataSource]="todaysTrip"
      matSort
      multiTemplateDataRows
    >
      <ng-container matColumnDef="driverName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Driver Name
        </mat-header-cell>
        <mat-cell *matCellDef="let trip">
          <span class="label">
            <i
              *ngIf="!selectedTrip || selectedTrip != trip; else selected"
              class="fa fa-chevron-right"
              aria-hidden="true"
            ></i>
            <ng-template #selected>
              <i class="fa fa-chevron-down" aria-hidden="true"></i>
            </ng-template>
            {{ trip.driverName }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="driver-number">
        <mat-header-cell *matHeaderCellDef>
          Driver Phone Number
        </mat-header-cell>
        <mat-cell *matCellDef="let trip">
          <span class="label">{{ trip.driverNumber }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="total-amount">
        <mat-header-cell *matHeaderCellDef
          >Total Cash to be collected</mat-header-cell
        >
        <mat-cell *matCellDef="let trip">
          <span class="label">{{ trip.totalAmount }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="pending-amount">
        <mat-header-cell *matHeaderCellDef
          >Total Pending Cash
        </mat-header-cell>
        <mat-cell *matCellDef="let trip">
          <span class="label">{{ trip.remainingAmount }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actual-amount">
        <mat-header-cell *matHeaderCellDef>
          Total Collected Cash
        </mat-header-cell>
        <mat-cell *matCellDef="let trip">
          <span class="label">{{ trip.submittedAmount }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <mat-cell
          *matCellDef="let trip"
          [attr.colspan]="columnsToDisplay.length"
        >
          <div
            class="trip-hide-detail"
            [@detailExpand]="trip == selectedTrip ? 'expanded' : 'collapsed'"
          >
            <app-todays-trip-list
              [todaysTrip]="trip.cashEntries"
            ></app-todays-trip-list>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: columnsToDisplay"
        (click)="selectTrip(row)"
      ></mat-row>
      <mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        [hidden]="row.parentItemId"
        class="trip-detail-row"
      ></mat-row>
    </mat-table>
  </mat-card>
</div>

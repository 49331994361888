<br />
<table style="margin: 3px" *ngIf="butcherMode">
  <tbody *ngIf="ratingTags?.length > 0">
    <tr>
      <td align="center" colspan="5">
        <span class="title"> <b>PRIORITY ORDER</b></span>
      </td>
    </tr>
    <tr>
      <td
        style="border: 1px solid #000"
        align="center"
        *ngFor="let tag of ratingTags; let i = index"
      >
        <span> {{ tag }} </span>
      </td>
    </tr>
  </tbody>
</table>

<!--Store data-->
<table>
  <thead>
    <tr *ngIf="order.isNewUser">
      <td align="center" colspan="12">
        <span id="new-user">New User</span>
      </td>
    </tr>
    <tr>
      <td align="center" colspan="12">
        <span class="title"
          ><b>{{ brandTitle }} - </b></span
        >
        <span *ngIf="'customer'">
          <b>{{ index + 1 }}</b>
        </span>
      </td>
    </tr>
    <tr>
      <td align="center" colspan="12">
        <div>Good To Go Foodworks Pvt. Ltd.</div>
      </td>
    </tr>
    <tr *ngIf="butcherMode">
      <td align="center" colspan="12">
        <span><b>Butcher's Copy</b> </span>
      </td>
    </tr>
    <tr>
      <td align="center" colspan="12">
        <span>
          {{ address }}
          <br />
        </span>
        <span *ngIf="cityPhoneNo">Ph: {{ cityPhoneNo }}</span>
      </td>
    </tr>
    <tr>
      <td style="border-top: 1px dashed #000" colspan="12"></td>
    </tr>
    <!--Customer-->
    <tr>
      <td class="invoice" align="center" colspan="12"><b>Tax Invoice</b></td>
    </tr>
    <tr>
      <td align="center" colspan="12">PROMISED DELIVERY TIME</td>
    </tr>
    <tr>
      <td align="center" colspan="12">
        {{ order.promisedDeliveryTime }}
      </td>
    </tr>

    <tr>
      <td align="center" colspan="12">
        <b> {{ order.incrementId }} ({{ order.paymentModeSimple }})</b>
      </td>
    </tr>

    <tr>
      <td align="center" colspan="12" *ngIf="!butcherMode">
        <strong
          >{{ order.firstname }}
          <mat-basic-chip class="elite-chip" *ngIf="order.isEliteCustomer"
            >Elite</mat-basic-chip
          ></strong
        >
      </td>
    </tr>

    <tr *ngIf="!butcherMode">
      <td align="left" colspan="12">
        DELIVER TO : {{ order.shippingAddress.fullAddress }}
      </td>
    </tr>
    <tr *ngIf="!butcherMode && order.shippingAddress?.locality && isGtgStore">
      <td align="left" colspan="12">
        Locality : {{ order.shippingAddress.locality }}
      </td>
    </tr>
    <tr>
      <td style="border-top: 1px dashed #000" colspan="12"></td>
    </tr>
  </thead>

  <!--Order Items-->
  <tbody>
    <ng-container>
      <tr>
        <th colspan="6" align="left">ITEM NAME</th>
        <th colspan="6" align="right">Subtotal</th>
      </tr>
    </ng-container>
    <tr>
      <td style="border-top: 1px dashed #000" colspan="12"></td>
    </tr>
    <ng-container *ngFor="let item of order.simpleItems">
      <tr>
        <td colspan="12" class="prod-name">
          <b
            >ID: {{ item.productId }} | {{ item.name }} | QTY :
            {{ item.quantity | number : "1.0-0" }}</b
          >
          <div *ngIf="productWeight(item)" style="font-size: 10px">
            {{ productWeight(item) }}
          </div>
        </td>
      </tr>
      <tr>
        <td class="itemName" colspan="8" align="left">
          <div class="itemName__gross-price">
            Gross Price :
            {{ item.baseRowTotal | currency : "INR" : "symbol" : "1.2-2" }}
            <span *ngIf="item.itemTotalDiscount > 0">
              | Disc
              {{
                item.itemTotalDiscount | currency : "INR" : "symbol" : "1.2-2"
              }}</span
            >
          </div>
          <div *ngIf="item.taxPercent > 0" style="font-size: 11px">
            GST ({{ item.taxPercent }}%) :
            {{ item.taxAmount | currency : "INR" : "symbol" : "1.2-2" }}
          </div>
          <div *ngIf="item.hsnCode" style="font-size: 11px">
            <span class="hsn">HSN : {{ item.hsnCode }}</span>
          </div>
        </td>
        <td colspan="4" align="right">
          {{ item.itemSubtotal | currency : "INR" : "symbol" : "1.2-2" }}
        </td>
      </tr>
      <tr *ngIf="item.shippingAmountWithoutGst > 0" class="charge">
        <td
          style="padding-left: 12px"
          class="deliveryFee"
          colspan="6"
          align="left"
        >
          <span> Delivery Fee </span>
        </td>
        <td colspan="6" align="right">
          {{ item.shippingAmount | currency : "INR" : "symbol" : "1.2-2" }}
        </td>
      </tr>
      <br />
    </ng-container>
    <tr *ngIf="order.packingCharges">
      <td colspan="6" align="left">
        <span> Packing charges </span>
      </td>
      <td colspan="6" align="right">
        {{ order.packingCharges | currency : "INR" : "symbol" : "1.2-2" }}
      </td>
    </tr>
    <tr *ngIf="order.rewardAmount < 0 || order.walletAmount > 0">
      <td style="border-top: 1px dashed #000" colspan="12"></td>
    </tr>
  </tbody>

  <!--TOTAL & SUBS-->
  <tfoot class="foot-totals">
    <ng-container *ngIf="!butcherMode">
      <tr *ngIf="order.rewardAmount < 0">
        <td colspan="8">Rewards</td>
        <td colspan="4" align="right">
          {{ order.rewardAmount | currency : "INR" : "symbol" : "1.2-2" }}
        </td>
      </tr>
      <tr *ngIf="order.walletAmount > 0">
        <td colspan="8">Wallet</td>
        <td colspan="4" align="right">
          -{{ order.walletAmount | currency : "INR" : "symbol" : "1.2-2" }}
        </td>
      </tr>

      <tr>
        <td style="border-top: 1px dashed #000" colspan="12"></td>
      </tr>
      <tr>
        <td colspan="6"><b>Grand Total</b></td>
        <td colspan="6" align="right">
          <b>{{ order.total | currency : "INR" : "symbol" : "1.2-2" }}</b>
        </td>
      </tr>
      <tr *ngIf="order.rewardPointsEarned > 0">
        <td colspan="8"><b>Earned Rewards</b></td>
        <td colspan="4" align="right">
          {{ order.rewardPointsEarned | currency : "INR" : "symbol" : "1.2-2" }}
        </td>
      </tr>
      <br />
      <tr>
        <td align="center" colspan="12">
          <div
            class="delivery-charge-free row"
            *ngIf="order.isShippingFree && order.isEliteCustomer"
          >
            <small>Free Delivery because you're an Elite!</small>
          </div>
        </td>
      </tr>
      <tr class="save" *ngIf="order.savings > 0 && !order.isEliteCustomer">
        <td style="border: 1px dashed #000" colspan="12" align="center">
          <b
            >Your Total Savings :
            {{ order.savings | currency : "INR" : "symbol" : "1.2-2" }}</b
          >
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="order.eliteSaving && order.isEliteCustomer">
      <tr>
        <td style="border-top: 1px dashed #000" colspan="12"></td>
      </tr>
      <tr>
        <td colspan="12" align="center">{{ eliteSavingsMessage }}</td>
      </tr>
      <tr>
        <td style="border-top: 1px dashed #000" colspan="12"></td>
      </tr>
    </ng-container>
    <ng-container>
      <tr *ngIf="!butcherMode">
        <td class="taxbreakup" colspan="12" align="center">
          TAX Break Up : (GSTIN: {{ gstNumber }})
        </td>
      </tr>
      <tr>
        <td style="border-top: 1px dashed #000" colspan="12"></td>
      </tr>
      <tr
        align="center"
        colspan="12"
        *ngIf="gstTable.length > 0"
        class="taxtable"
      >
        <th colspan="1"></th>
        <th colspan="2">GST%</th>
        <th colspan="2">Amount</th>
        <th colspan="2">SGST</th>
        <th colspan="2">CGST</th>
        <th colspan="2">Total</th>
        <th colspan="1"></th>
      </tr>
      <tr>
        <td style="border-top: 1px dashed #000" colspan="12"></td>
      </tr>
      <tr align="center" colspan="12" *ngFor="let table of gstTable">
        <td colspan="1"></td>
        <td colspan="2">{{ table.taxpercent }}%</td>
        <td colspan="2">
          {{
            table.rowTotalWithDiscount | currency : "INR" : "symbol" : "1.2-2"
          }}
        </td>
        <td colspan="2">
          {{ table.taxamount / 2 | currency : "INR" : "symbol" : "1.2-2" }}
        </td>
        <td colspan="2">
          {{ table.taxamount / 2 | currency : "INR" : "symbol" : "1.2-2" }}
        </td>
        <td colspan="2">
          {{ table.taxamount | currency : "INR" : "symbol" : "1.2-2" }}
        </td>
        <td colspan="1"></td>
      </tr>
      <tr *ngIf="!butcherMode">
        <td colspan="12" align="center">FSSAI : {{ fssaiCode }}</td>
      </tr>
      <tr>
        <td colspan="12" align="center">Thank You for Choosing us</td>
      </tr>
      <tr>
        <td colspan="12" align="center">
          <ngx-barcode
            [bc-value]="order.incrementId"
            [bc-display-value]="true"
            [bc-height]="50"
            [bc-width]="2"
            [bc-font-size]="14"
          >
          </ngx-barcode>
        </td>
      </tr>
    </ng-container>
  </tfoot>
</table>

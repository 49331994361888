import { Component, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { GenerateTripSheetResponse, TripSheet } from "@tendercuts/models";
import {
  TripPaymentPhoneNumberService,
  TripSheetPayService,
} from "@tendercuts/providers";
import { BasePage } from "src/app/utils";

/**
 * component to show otp dialog
 */
@Component({
  selector: "app-trip-sheet-pay-otp-dialog",
  templateUrl: "./trip-sheet-payment-otp.component.html",
  styleUrls: ["./trip-sheet-payment-otp.component.scss"],
})
export class TripSheetPaymentOtpComponent extends BasePage implements OnInit {
  tripSheet: TripSheet;
  amount: number;
  showResend: boolean;
  // resend otp timer in seconds
  timer: number = 15;

  // controller for pay input amount
  inputController: FormControl = new FormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TripSheetPaymentOtpComponent>,
    private tripSheetPayService: TripSheetPayService,
    private tripPaymentPhoneNumberService: TripPaymentPhoneNumberService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>
  ) {
    super(dialog, snackBar, store);
    this.tripSheet = data.tripSheet;
    this.amount = data.amount;
  }

  ngOnInit(): void {
    this.triggerOtpRider();
  }

  /**
   * send a phone number of rider to service
   * to get otp
   */
  triggerOtpRider(): void {
    const paramsData: {
      mobile: number;
      otp_mode: string;
    } = this.tripPaymentPhoneNumberService.getParams(this.tripSheet.driverId);
    this.tripPaymentPhoneNumberService.getData(paramsData).subscribe(
      (response: GenerateTripSheetResponse[]) => {
        this.dismissLoader();
        if (response) {
          this.showNotification(
            "top",
            "center",
            "success",
            "info-circle",
            "OTP Successfully Sent"
          );
        }
        this.enableReSendOtp();
      },
      (error) => {
        this.dismissLoader();
        this.somethingWentWrong();
      }
    );
  }

  /**
   * Control the time gap between
   * sending opts multiple times
   */
  enableReSendOtp(): void {
    this.showResend = true;
    this.timer = 15;
    const timer: NodeJS.Timer = setInterval(() => {
      this.timer--;
    }, 1000);
    setTimeout(() => {
      this.showResend = false;
      clearInterval(timer);
    }, this.timer * 1000);
  }

  /**
   * To close the dialogbox
   */
  closeDialog(): void {
    this.dialogRef.close();
  }

  /**
   * send an otp to service
   * close the dialog if return true else
   * throw error messsage
   */
  submitOtp(): void {
    this.presentLoader();
    const paramsData: {
      sheet_id: number;
      payment_amount: number;
      otp: number;
    } = this.tripSheetPayService.getParams(
      this.tripSheet.id,
      this.amount,
      this.inputController.value
    );
    this.tripSheetPayService.getData(paramsData).subscribe(
      (response: GenerateTripSheetResponse[]) => {
        this.dismissLoader();
        this.textAlert(response[0].message, "");
        if (response[0].status) {
          this.dialogRef.close({
            status: response[0].status,
          });

          return;
        }
        this.inputController.setValue("");
      },
      (err) => {
        this.somethingWentWrong(), this.dismissLoader();
      }
    );
  }

  /**
   * resend an otp to rider
   */
  resendOtp(): void {
    this.triggerOtpRider();
    this.inputController.setValue("");
  }
}

import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BaseGetProvider } from "@tendercuts/http";
import { GenericServerResponse } from "@tendercuts/models";
import { of, Observable } from "rxjs";

@Injectable()
export class GetCategoryService extends BaseGetProvider<GenericServerResponse> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof GenericServerResponse {
    return GenericServerResponse;
  }

  get endpoint(): string {
    return this.endpointService.getCategory();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(categoryId: string): HttpParams {
    let params: HttpParams = new HttpParams();
    params = params.append("customer_id", categoryId);

    return params;
  }

  getData(): Observable<
    {
      name: string;
      categoryId: number;
    }[]
  > {
    return of([
      {
        name: "Chicken",
        categoryId: 4,
      },
      {
        name: "Mutton",
        categoryId: 5,
      },

      {
        name: "Seafood",
        categoryId: 14,
      },

      {
        name: "Marinades",
        categoryId: 15,
      },
      {
        name: "Cold Cuts",
        categoryId: 24,
      },
      {
        name: "Eggs",
        categoryId: 18,
      },
      {
        name: "Pickles",
        categoryId: 22,
      },
      {
        name: "Spices",
        categoryId: 23,
      },
    ]);
  }
}

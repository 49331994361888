import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { ActionEnableTripEditMode } from "@store/driver";
import { AppState } from "@store/state";
import {
  Driver,
  GenericServerResponse,
  HyperTrackTrip,
  SaleOrder,
  Trips,
  TripStatus,
  User,
} from "@tendercuts/models";
import {
  CloseTripService,
  HyperTrackTripService,
  PubSubService,
} from "@tendercuts/providers";
import { Observable } from "rxjs";
import { map, skipWhile, take } from "rxjs/operators";
import { HyperTrackTripComponent } from "src/app/components/hyper-track-trip";
import { TripValidationComponent } from "src/app/components/trip-validation";
import { FirebaseAnalyticsService } from "src/providers/firebase-analytics/firebase-analytics";
import { AdvanceCashDialogComponent } from "../../../components/advance-cash-dialog";
import { DriverSelectionComponent } from "../../../components/driver-selection";
import { RemoveTripOrdersComponent } from "../../../components/remove-trip-orders/remove-trip-orders.component";
import { BasePage } from "../../../utils";
import { PrintInvoiceDialogComponent } from "../../print-module";
import { ViewTripBtnDialogueComponent } from "../view-trip-btn-dialogue";

export enum DialogCloseStatus {
  CLOSED = 1,
  OPEN,
}

/**
 * Component to display trips which have been created.
 * These are trips that are not in memory like optimal trip
 */
@Component({
  selector: "app-trips-action-buttons",
  templateUrl: "./trips-action-buttons.component.html",
  styleUrls: ["./trips-action-buttons.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TripsActionButtonsComponent extends BasePage implements OnInit {
  @Input() trip: Trips;

  @Output() showTrip: EventEmitter<any> = new EventEmitter<any>();
  @Output() public reloadParent: EventEmitter<any> = new EventEmitter();

  printType: string;
  canShowAssignDriverBtn: boolean = false;
  isTechOps: boolean = false;
  tripCloseStatus: typeof TripStatus = TripStatus;
  hyperTrackTrip: HyperTrackTrip;
  embedUrl: SafeResourceUrl;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public router: Router,
    private closeTripService: CloseTripService,
    private hyperTrackTripService: HyperTrackTripService,
    public events: PubSubService,
    protected sanitizer: DomSanitizer,
    private firebaseAnalyticsService: FirebaseAnalyticsService
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    this.getTechOps();
    if (
      this.trip.canAssignManual &&
      (this.trip.status === TripStatus.PENDING ||
        this.trip.status === TripStatus.SCANNED) &&
      !this.trip.driverUserId
    ) {
      this.canShowAssignDriverBtn = true;
    }
  }

  /**
   * current store is fetched from base getter selectedStore,
   * if isDriverScanEnabled ie scanMode is true,we will check if all orders are scanned or not.
   * returns true if all orders are either order_scanned or item_scanned
   */
  get canShowAssignRider(): Observable<boolean> {
    return this.selectedStore.pipe(
      take(1),
      map((store) => {
        return store.storeAttribute.isDriverScanEnabled
          ? true
          : this.trip.driverOrder.every((data) => {
              if (data.saleOrder) {
                return (
                  data.saleOrder.isCentralScanned || data.saleOrder.isScanned
                );
              }
            });
      })
    );
  }

  async getTechOps(): Promise<void> {
    this.isTechOps = await this.store
      .select((state) => state.userState.user)
      .pipe(
        skipWhile((user) => !user),
        take(1),
        map((user: User) => user.isTechOps)
      )
      .toPromise();
  }

  // funtion to open hyper track map for trip
  async viewTrip(): Promise<void> {
    const params: { trip_id: number } =
      await this.hyperTrackTripService.getParams(this.trip.tripId);
    const response: HyperTrackTrip[] = await this.hyperTrackTripService
      .getData(params)
      .toPromise();
    if (response[0].embedUrl && response[0].embedUrl != "") {
      this.hyperTrackTrip = response[0];
      // here the query params is to hide the complete button,back arrow,and export data
      this.embedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        response[0].embedUrl +
          "&trip_complete=false&back=false&export=false&hide_sharing=true"
      );
      const dialogRef: MatDialogRef<HyperTrackTripComponent, any> =
        this.dialog.open(HyperTrackTripComponent, {
          data: {
            trip: this.trip,
            hyperTrackTrip: this.hyperTrackTrip,
            embedUrl: this.embedUrl,
          },
        });
      dialogRef.afterClosed().subscribe((data) => {
        if (data) {
          this.reloadParent.emit(1);
        }
      });

      return;
    }
    const viewTripDialog: MatDialogRef<ViewTripBtnDialogueComponent, any> =
      this.dialog.open(ViewTripBtnDialogueComponent, {
        data: this.trip,
        width: "80vw",
      });
    viewTripDialog.afterClosed().subscribe((data) => {
      if (data) {
        this.reloadParent.emit(1);
      }
    });
  }

  // Pending Cash Collection
  collectCash(): void {
    const dialogRef: MatDialogRef<TripValidationComponent, any> =
      this.dialog.open(TripValidationComponent, {
        data: this.trip,
        width: "1024px",
        height: "610px",
        panelClass: "earning",
        disableClose: true,
      });

    dialogRef.afterClosed().subscribe((status) => {
      if (status) {
        this.reloadParent.emit(1);
      }
    });
  }

  // Removing Order's from trip
  removeOrdersConfirm(): void {
    const dialogRef: MatDialogRef<RemoveTripOrdersComponent, any> =
      this.dialog.open(RemoveTripOrdersComponent, {
        data: this.trip,
      });

    dialogRef.afterClosed().subscribe((status) => {
      if (status === DialogCloseStatus.CLOSED) {
        if (this.trip.sequencedOrders.length > 0) {
          this.printOrders(
            this.trip.sequencedOrders,
            "kot",
            false,
            this.trip.driver.displayName
          );
        }
        this.reloadParent.emit(1);
      }

      if (status === DialogCloseStatus.OPEN) {
        this.textAlert("Failed", "Order un-assign failed.");
      }
    });
  }

  printOrders(
    tripOrders: SaleOrder[],
    printType: string = "",
    printKot: boolean = false,
    driver: any = null,
    twoCustomerCopy: boolean = false
  ): void {
    const dialogData: {
      orders: SaleOrder[];
      printType: string;
      printKot: boolean;
      driverMode: boolean;
      tripId: number;
      trip: Trips;
      driverDisplayName: string;
      twoCustomerCopy: boolean;
    } = {
      orders: tripOrders,
      printType,
      printKot,
      driverMode: true,
      tripId: this.trip.tripId,
      trip: this.trip,
      driverDisplayName: driver ? driver.displayName : "NOT YET ASSIGNED",
      twoCustomerCopy,
    };
    this.dialog.open(PrintInvoiceDialogComponent, {
      width: "80mm",
      data: dialogData,
    });
  }

  printKotOrders(tripOrders: Set<string>, driver: Driver): void {
    const orders: any[] = Array.from(tripOrders);
    const saleOrder: SaleOrder[] = [];
    this.store
      .select((state) => state.orderState.orderMap)
      .pipe(take(1))
      .subscribe((orderMap) => {
        orders.forEach((orderId: number) => {
          saleOrder.push(orderMap[orderId]);
        });
        const dialogData: {
          orders: SaleOrder[];
          printType: string;
          printKot: boolean;
          driverDisplayName: string;
          twoCustomerCopy: boolean;
          trip: Trips;
        } = {
          orders: saleOrder,
          printType: "customer",
          printKot: true,
          driverDisplayName: driver ? driver.displayName : "NOT YET ASSIGNED",
          twoCustomerCopy: true,
          trip: this.trip,
        };
        this.dialog.open(PrintInvoiceDialogComponent, {
          width: "80mm",
          data: dialogData,
        });
      });
  }

  // Assigning Driver to a trip
  assignDriver(trip: Trips, reAssignDriver: boolean = false): void {
    const params: {
      orders: string[];
      savedTripsMode: boolean;
      tripId: number;
      oldDriverId: number;
    } = {
      orders: Array.from(trip.orders),
      savedTripsMode: true,
      tripId: trip.tripId,
      oldDriverId: null,
    };
    if (reAssignDriver) {
      params.savedTripsMode = false;
      params.oldDriverId = trip.driverUserId;
    }
    const dialogRef: MatDialogRef<DriverSelectionComponent, any> =
      this.dialog.open(DriverSelectionComponent, {
        data: params,
      });

    dialogRef.afterClosed().subscribe((data: GenericServerResponse) => {
      // closing the drawer if driver is assigned via drawer
      if (data == undefined) {
        // this means that dialog this manually closed
        // no need to perform any action
        return;
      }
      if (data.status) {
        this.showNotification(
          "top",
          "center",
          "success",
          "info-circle",
          data.message
        );
        this.printKotOrders(trip.orders, trip.driver);
        this.reloadParent.emit(1);
      } else {
        this.showNotification(
          "top",
          "center",
          "danger",
          "info-circle",
          data.message
        );
      }
      this.refreshOrdersAndTrips();
    });
  }

  // For Reassigning rider to a trip
  reAssignDriver(trip: Trips): void {
    const dialogRef: MatDialogRef<DriverSelectionComponent, any> =
      this.dialog.open(DriverSelectionComponent, {
        data: {
          orders: Array.from(trip.orders),
          savedTripsMode: false,
          oldDriverId: trip.driverUserId,
          tripId: trip.tripId,
        },
      });

    dialogRef.afterClosed().subscribe((data) => {
      // closing the drawer if driver is assigned via drawer
      if (data == undefined) {
        // this means that dialog this manually closed
        // no need to perform any action
        return;
      }
      if (data.status) {
        this.showNotification(
          "top",
          "center",
          "success",
          "info-circle",
          data.message
        );
        this.printKotOrders(trip.orders, data.driver);
        this.reloadParent.emit(1);
      } else {
        this.showNotification(
          "top",
          "center",
          "danger",
          "info-circle",
          data.message
        );
      }
    });
  }

  /**
   * We can only reassign the trip to a diff rider when the trip is not dummy and not started
   */
  get canReAssign(): boolean {
    if (
      this.trip.canAssignManual &&
      this.trip.driverUserId &&
      this.trip.status <= 1
    ) {
      return true;
    }

    return false;
  }

  /**
   * open the plan trip dialog in change sequence mode
   * @param tripObject
   */
  sequencedOrder(tripObject: Trips): void {
    this.events.$pub("plan-trip:open", {
      sequenceChangeTrip: tripObject,
      dataSource: [],
      segments: [],
      isSequencedMode: true,
      editMode: false,
    });
  }

  /**
   * Adding/Removing Advance Cash
   * 1. Open Advance Cash popup based on
   * the isRemoveCashClicked check (Add cash / Remove cash)
   * 2. Refreshes the parent on popup close
   */
  openAdvanceCashDialog(isRemoveCashClicked: boolean): void {
    const dialogRef: MatDialogRef<AdvanceCashDialogComponent, any> =
      this.dialog.open(AdvanceCashDialogComponent, {
        disableClose: true,
        data: { trip: this.trip, isRemoveCash: isRemoveCashClicked },
      });

    dialogRef.afterClosed().subscribe((status) => {
      if (status) {
        this.reloadParent.emit(1);
      }
    });
  }

  /**
   * Sets the trip id to be edited in redux
   * and navigates to routing dashboard
   */
  editTrip(): void {
    this.firebaseAnalyticsService.editButtonClicked(this.trip.tripId);
    this.store.dispatch(new ActionEnableTripEditMode(this.trip.tripId));
    this.router.navigate(["dashboard/routing"]);
  }

  // To close driver trips
  closeTrip(): void {
    const onClickingYes: (result: any) => void = (result) => {
      if (result) {
        this.closeTripConfirm();
      }
    };
    this.optionsAlert(
      "Confirm",
      "Are you sure to complete the trip",
      onClickingYes
    );
  }

  /**
   * @param trip_id is passed to service to get response
   */
  closeTripConfirm(): void {
    this.closeTripService.getData({ trip_id: this.trip.tripId }).subscribe(
      (response) => {
        if (response[0].status == true) {
          this.refreshStores();
        }
        this.textAlert(response[0].message, "");
      },
      (err) => this.somethingWentWrong()
    );
  }

  scanOrders = (tripId: string): void => {
    this.router.navigate([`/dashboard/scan/${tripId}`]);
  };
}

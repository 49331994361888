import { Component, Input } from "@angular/core";
import { BaseGridComponent } from "../base-grid/base-grid.component";
import { ColumnAction } from "../table-actions";

/**
 * To display booking details for an driver
 */
@Component({
  selector: "app-booking-request-display-tab",
  templateUrl: "../base-grid/base-grid.component.html",
  styleUrls: ["../base-grid/base-grid.component.scss"],
})
export class BookingDisplayTabComponent extends BaseGridComponent {
  // To display display driverId hyperlink
  @Input() isRedirectToCrm: boolean = false;
  // To add cancel request button in grid
  @Input() actions: ColumnAction[] = [];
  @Input() columnsToDisplay: string[] = [
    "storeId",
    "driverDetails.firstName",
    "bookedDate",
    "respondDateTime",
    "bookingStatus",
    "driverAttendance",
    "expireAt",
    "shiftStatus",
  ];
  @Input() titleToDisplay: string[] = [
    "Store Id",
    "Driver Name",
    "Request Date",
    "Respond Date & Time",
    "Request Status",
    "Present/Absent",
    "Request Expire At",
    "Shift Status",
  ];

  constructor() {
    super();
  }
}

// TODO
/* eslint-disable */
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class AutoCompleterService {
  options: any = undefined;

  constructor(private http: HttpClient) {}

  getData(filter: any): Promise<any> {
    if (this.options) {
      return new Promise((resolve, reject) => {
        resolve(this.filterOptions(filter));
      });
    } else {
      return new Promise((resolve, reject) => {
        this.http.get("../../../../assets/data/autocomplete.json").subscribe(
          (data) => {
            this.options = data;
            resolve(this.filterOptions(filter));
          },
          (err) => reject(),
        );
      });
    }
  }

  filterOptions(filter: any): Array<string> {
    return this.options.filter((option) =>
      this.fuzzysearch(filter, option.title),
    );
  }

  // Credit: https://github.com/bevacqua/fuzzysearch
  fuzzysearch(needle: any, haystack: any): boolean {
    const hlen: number = haystack.length;
    const nlen: number = needle.length;

    if (nlen > hlen) {
      return false;
    }

    needle = needle.toLowerCase();
    haystack = haystack.toLowerCase();

    let nIdx: number = 0;
    let hIdx: number = 0;
    while (nIdx < nlen) {
      if (hIdx >= hlen) {
        return false;
      }
      if (needle.charCodeAt(nIdx) === haystack.charCodeAt(hIdx++)) {
        nIdx++;
      }
    }

    return true;
  }
}

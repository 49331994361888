<form class="alternate_number">
  <mat-form-field>
    <input
      autofocus
      maxlength="10"
      class="alternate_number__input"
      placeholder="Enter Phone Number"
      matInput
      [formControl]="alternateNumberControl"
    />
    <mat-hint align="end"
      >{{alternateNumberControl.value?.length || 0}}/10</mat-hint
    >
  </mat-form-field>
  <div class="alternate_number__submit_btn">
    <button color="primary" mat-raised-button (click)="validateNumber()">
      Submit
    </button>
  </div>
</form>

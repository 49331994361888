import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { CatalogState } from "@store/catalog";
import { AppState } from "@store/state";
import { Driver, HyperTrackTrip, LiveTrack } from "@tendercuts/models";
import { HyperTrackTripService } from "@tendercuts/providers";
import { Subscription } from "rxjs";
import { skipWhile, take } from "rxjs/operators";
import { FilterModel } from "src/models";
import {
  DriverStatusDataSource,
  HyperTrackTripComponent,
} from "../../components";
import { BasePage } from "../../utils";
import { ButtonAction, ColumnAction } from "../table-actions";

@Component({
  selector: "app-driver-status",
  templateUrl: "./driver-status.component.html",
  styleUrls: ["./driver-status.component.scss"],
})

/** Component to display active riders in a store */
export class DriversStatusComponent
  extends BasePage
  implements OnInit {
  drivers: Driver[] = [];
  currentStoreId: number;
  liveTrackDetails: LiveTrack[];
  liveTrackSubscription$: Subscription;

  modelFilter: FilterModel = new FilterModel([]);
  driverDataSource: DriverStatusDataSource = new DriverStatusDataSource(
    this.modelFilter,
    []
  );
  hyperTrackTrip: HyperTrackTrip;
  embedUrl: SafeResourceUrl;

  @Input() columnsToDisplay: string[] = [
    "name",
    "phone",
    "trackStatus",
    "tripId",
  ];

  @Input() titleToDisplay: string[] = [
    "Name",
    "Phone Number",
    "Status",
    "Trip Id",
  ];

  constructor(
    private hyperTrackTripService: HyperTrackTripService,
    protected sanitizer: DomSanitizer,
    public matDialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>
  ) {
    super(matDialog, snackBar, store);
  }

  ngOnInit(): void {
    this.fetchAppState();
  }

  /**
   * Fetch the states from the appState
   * filter the drivers list based on input given
   */
  async fetchAppState(): Promise<void> {
    const state: AppState = await this.store
      .select((appState) => appState)
      .pipe(
        skipWhile(
          (appState) =>
            appState.storeState.loading == true ||
            appState.orderState.loading == true
        ),
        take(1)
      )
      .toPromise();

    if (state) {
      this.currentStoreId = state.storeState.store;
      // this.store.dispatch(
      //   new ActionLiveTrackLoad(this.currentStoreId.toString())
      // );

      // this.fetchLiveTrackDatabase();

      this.filterDrivers(state.driverState.allDrivers);
    }
  }

  /**
   * Fn to fetch hyper track details from redux
   */
  async fetchLiveTrackDatabase(): Promise<void> {
    const catalogState: CatalogState = await this.store
      .select((appState) => appState.catalog)
      .pipe(
        skipWhile((state) => state.loading == true),
        take(1)
      )
      .toPromise();

    if (catalogState) {
      this.liveTrackDetails = catalogState.liveTrack;
    }
  }

  /**
   * @param drivers
   */
  filterDrivers(drivers: Driver[]): void {
    this.drivers = drivers;
    this.driverDataSource.data = drivers;
  }

  /**
   * Fn to show button action
   */
  get buttonAction(): ColumnAction[] {
    return [
      new ButtonAction(
        "Track",
        "trip_id",
        this.openHyperTrackDialog.bind(this)
      ),
    ];
  }

  /**
   * Fn to open hyper track dialog,
   * @param driverDetail,
   * @param hyperTrackTrip ie hyper tack details,
   * @param embedUrl to load iframe is passed
   */
  async openHyperTrackDialog(event: any, driverDetail: Driver): Promise<void> {
    if (!driverDetail.tripId) {
      this.showErrorAlert();

      return;
    }
    const params: { trip_id: number } =
      await this.hyperTrackTripService.getParams(driverDetail.tripId);

    const response: HyperTrackTrip[] = await this.hyperTrackTripService
      .getData(params)
      .toPromise();

    if (!response[0].embedUrl) {
      this.showErrorAlert();

      return;
    }

    if (response[0].embedUrl.length > 0) {
      this.hyperTrackTrip = response[0];
      this.embedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        response[0].embedUrl +
          "&trip_complete=false&back=false&export=false&hide_sharing=true"
      );

      const hyperTrackDialog: MatDialogRef<HyperTrackTripComponent, any> =
        this.dialog.open(HyperTrackTripComponent, {
          data: {
            trip: driverDetail,
            hyperTrackTrip: this.hyperTrackTrip,
            embedUrl: this.embedUrl,
            hasBackdrop: false,
            width: "80vw",
          },
        });
    }
  }

  // fn to show error message
  showErrorAlert(): void {
    this.showNotification(
      "bottom",
      "center",
      "danger",
      "info-circle",
      "Tracking is available only for Ontrip riders"
    );
  }
}

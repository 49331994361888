import { Serializable } from "../base";

export class CustomerProfile implements Serializable<CustomerProfile> {
  public id: number;
  public customer_id: number;
  public scheduled: number;
  public express: number;
  public last_order_medium: number;
  public last_order_store: number;
  public first_order_medium: Date;
  public first_ordered_date: Date;
  public last_ordered_date: Date;
  public ats: number;

  public monday: number;
  public tuesday: number;
  public wednesday: number;
  public thursday: number;
  public friday: number;
  public saturday: number;
  public sunday: number;

  public sale_total: number;
  public sale_chicken: number;
  public sale_mutton: number;
  public sale_marinades: number;
  public sale_eggs: number;
  public sale_sea_food: number;
  public sale_pickles: number;

  public count_eggs: number;
  public count_pickles: number;
  public count_marinades: number;
  public count_sea_food: number;
  public count_total: number;
  public count_mutton: number;
  public count_chicken: number;

  public pos: number;
  public msite: number;
  public website: number;
  public callcenter: number;
  public mobile: number;

  public week_no: number;
  public type: number;
  public year: number;
  private rep_count: number;
  private order_per_month: number;

  constructor() {}

  get favoriteDays() {
    let data = [
      { name: "Mon", v: this.monday },
      { name: "Tue", v: this.tuesday },
      { name: "Wed", v: this.wednesday },
      { name: "Thu", v: this.thursday },
      { name: "Fri", v: this.friday },
      { name: "Sat", v: this.saturday },
      { name: "Sun", v: this.sunday }
    ];

    return data.sort((a, b) => b.v - a.v).slice(0, 2);
  }

  get favoriteCategory() {
    let data = [
      {
        name: "CHK",
        count: this.count_chicken,
        v: Math.ceil(this.sale_chicken / 1000)
      },
      {
        name: "MUT",
        count: this.count_mutton,
        v: Math.ceil(this.sale_mutton / 1000)
      },
      {
        name: "MAR",
        count: this.count_marinades,
        v: Math.ceil(this.sale_marinades / 1000)
      },
      {
        name: "SF",
        count: this.count_sea_food,
        v: Math.ceil(this.sale_sea_food / 1000)
      },
      {
        name: "EGGS",
        count: this.count_eggs,
        v: Math.ceil(this.sale_eggs / 1000)
      },
      {
        name: "PKL",
        count: this.count_pickles,
        v: Math.ceil(this.sale_pickles / 1000)
      }
    ];

    return data.sort((a, b) => b.v - a.v);
  }

  get favoriteMedium() {
    let data = [
      { name: "WEB", v: this.website },
      { name: "MOB", v: this.mobile },
      { name: "CC", v: this.callcenter },
      { name: "MWEB", v: this.msite },
      { name: "RETAIL", v: this.pos }
    ];

    return data.sort((a, b) => b.v - a.v).slice(0, 2);
  }

  get replacementOrderCount() {
    return this.rep_count;
  }
  
  get ordersPerMonth() {
    return this.order_per_month;
  }

  deserialize(input) {
    this.id = input.id;
    this.customer_id = input.customer_id;
    this.scheduled = input.scheduled;
    this.ats = input.ats;
    this.last_order_store = input.last_order_store;
    this.last_order_medium = input.last_order_medium;
    this.express = input.express;
    this.first_order_medium = input.first_order_medium;
    this.first_ordered_date = new Date(input.first_ordered_date);
    this.last_ordered_date = new Date(input.last_ordered_date);

    this.sale_marinades = input.sale_marinades;
    this.sale_chicken = input.sale_chicken;
    this.sale_eggs = input.sale_eggs;
    this.sale_total = input.sale_total;
    this.sale_mutton = input.sale_mutton;
    this.sale_sea_food = input.sale_sea_food;
    this.sale_pickles = input.sale_pickles;

    this.count_sea_food = input.count_sea_food;
    this.count_eggs = input.count_eggs;
    this.count_pickles = input.count_pickles;
    this.count_marinades = input.count_marinades;
    this.count_chicken = input.count_chicken;
    this.count_total = input.count_total;
    this.count_mutton = input.count_mutton;

    this.monday = input.monday;
    this.tuesday = input.tuesday;
    this.wednesday = input.wednesday;
    this.thursday = input.thursday;
    this.friday = input.friday;
    this.saturday = input.saturday;
    this.sunday = input.sunday;

    this.pos = input.pos;
    this.website = input.website;
    this.msite = input.msite;
    this.callcenter = input.callcenter;
    this.mobile = input.mobile;

    this.type = input.type;
    this.year = input.year;
    this.week_no = input.week_no;
    this.rep_count = input.rep_count;
    this.order_per_month = input.order_per_month;

    return this;
  }
}

<mat-sidenav-container>
  <div class="header">
    <div class="row filters">
      <mat-card class="col-12">
        <mat-card-title>
          <mat-form-field class="date-picker">
            <mat-label>Choose a date</mat-label>
            <input
              matInput
              [max]="todayDate"
              [(ngModel)]="selectedDate"
              (dateInput)="onDateSelect($event)"
              [matDatepicker]="picker"
            />

            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>

            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="search-bar">
            <input
              matInput
              id="searchbar"
              (keyup)="applyDriverSearchFilter($event.target.value)"
              placeholder="Search Driver-Id/ Trip Sheet Id"
              #searchbar
            />
          </mat-form-field>
        </mat-card-title>

        <mat-card-content>
          <mat-chip-list [multiple]="true" [selectable]="true">
            <div class="chip-border">
              <mat-chip
                (click)="
                  modelFilter.clearAll();
                  tripSheetDataSource.filter = '';
                  searchbar.value = ''
                "
              >
                Clear
              </mat-chip>
            </div>

            <div
              class="chip-border"
              *ngFor="let filterGroup of modelFilter.filterGroups"
            >
              <mat-chip
                *ngFor="let filter of filterGroup.filters"
                (click)="getFilteredData(filter)"
                [selected]="filter.selected"
              >
                {{ filter.displayName }}
              </mat-chip>
            </div>
          </mat-chip-list>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="space"></div>

  <div class="row no-gutters driver">
    <div
      *ngIf="tripSheets.length == 0; else showTripSheets"
      class="row no-gutters no-trip-sheets"
    >
      No trip Sheets found in this date. Please try some other date.
    </div>
    <ng-template #showTripSheets>
      <h6 class="row no-gutters header">Trip Sheets:</h6>
      <app-trip-sheet-grid
        [dataSource]="tripSheetDataSource"
        [columnsToDisplay]="columnsToDisplay"
        [titleToDisplay]="titleToDisplay"
        [rowClickCallback]="rowClickCallback"
      >
      </app-trip-sheet-grid>
    </ng-template>
  </div>
  <mat-sidenav id="drawer" mode="over" position="end" #drawer>
    <div class="trip-detail" *ngIf="selectedTripSheet">
      <div class="row no-gutters header">
        <div class="close-btn">
          <i
            class="close-btn__icon fa fa-window-close-o"
            (click)="closeDrawer(false)"
          ></i>
        </div>
        <div class="prev-btn col-2 text-left">
          <button
            mat-raised-button
            color="primary"
            id="prev"
            *ngIf="canShowPrevButton"
            (click)="moveToPrev()"
          >
            Prev
          </button>
        </div>

        <div class="col-6 title">
          <app-trip-sheet-title
            [tripSheet]="selectedTripSheet"
            [showBackButton]="false"
          ></app-trip-sheet-title>
        </div>

        <div class="col-3 next-btn text-right">
          <button
            mat-raised-button
            color="primary"
            id="next"
            class="mx-2"
            *ngIf="canShowNextButton"
            (click)="moveToNext()"
          >
            Next
          </button>
        </div>
      </div>
      <app-verify-trip-sheet
        *ngIf="selectedTripSheet"
        (reloadTripSheets)="fetchTripSheets()"
        [isDeliveryManagerMode]="true"
        [tripSheet]="selectedTripSheet"
      ></app-verify-trip-sheet>
    </div>
  </mat-sidenav>
</mat-sidenav-container>

import { Component, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { Trips } from "@tendercuts/models";
import { AddCashToTripService } from "@tendercuts/providers";
import { BasePage } from "../../utils/pages/base/base.component";

@Component({
  selector: "app-advance-cash-dialog",
  templateUrl: "./advance-cash-dialog.html",
  styleUrls: ["./advance-cash-dialog.scss"],
})
export class AdvanceCashDialogComponent extends BasePage {
  cashInputControl: FormControl = new FormControl(0, [Validators.required]);
  trip: Trips;
  isRemoveCash: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { trip: Trips, isRemoveCash: boolean },
    public dialogRef: MatDialogRef<AdvanceCashDialogComponent>,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public addCashToTripService: AddCashToTripService,
  ) {
    super(dialog, snackBar, store);
    this.trip = data.trip;
    this.isRemoveCash = data.isRemoveCash;
  }

  closePopup(): void {
    this.dialogRef.close(true);
  }

  /**
   * 1. Validates whether the user entered cash is a number and
   * greater than 0
   */
  validateInputs(): void {
    const submittedCash: number = this.cashInputControl.value;
    if (typeof submittedCash !== 'number' || submittedCash <= 0) {
      this.textAlert("Invalid cash", "Please enter correct cash amount");

      return;
    }

    this.submitCash(submittedCash);
  }

  /**
   * Submits the cash entered for trip to server.
   * Sends tripId and submitted Cash as params.
   * Shows message received from server after submitting the cash.
   */
  submitCash(submittedCash: number): void {
    const params: {
      trip: number;
      amount: number;
    } = this.addCashToTripService.getParams(
      this.trip.tripId,
      this.isRemoveCash ? -(submittedCash) : submittedCash,
    );
    this.presentLoader();
    this.addCashToTripService.getData(params).subscribe(
      (data) => {
        if (!data[0].status) {
          this.textAlert(data[0].message, "", "OK");
          this.dismissLoader();

          return;
        }
        this.showNotification(
          "top",
          "center",
          "success",
          "info-circle",
          data[0].message,
        );

        this.dismissLoader();
        this.closePopup();
      },
      (err) => this.somethingWentWrong(),
    );
  }
  /**
   * Resets the form controls
   */
  reset(): void {
    this.cashInputControl.setValue(0);
  }
}

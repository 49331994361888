import { Component, Input } from "@angular/core";
import { BaseGridComponent } from "../base-grid/base-grid.component";

@Component({
  selector: "app-inventory-request-grid",
  templateUrl: "../base-grid/base-grid.component.html",
  styleUrls: ["../base-grid/base-grid.component.scss"],
})
export class InventoryRequestGridComponent extends BaseGridComponent {
  @Input() columnsToDisplay: string[] = [
    "productName",
    "storeName",
    "qty",
    "inventoryType",
  ];
  @Input() titleToDisplay: string[] = ["Name", "Store Name", "qty", "Type"];
}

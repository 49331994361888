import { Serializable } from "../base";

export class ProductPrice implements Serializable<ProductPrice> {
  private product_id: number;
  private special_to_date: Date;
  private elite_price: number;
  private offer_price: number;
  private per_month_subs_price: number;
  private price_text: string;
  private offer_text: string;
  private is_elite: boolean;
  private per_unit_price: number;

  public original_price: number;
  public special_price: number;


  constructor() {
    this.original_price = 0;
    this.special_price = 0;
    this.elite_price = 0;
    this.offer_price = 0;
    this.per_month_subs_price = 0;
    this.per_unit_price = 0;
  }

  deserialize(input) {
    this.special_to_date = new Date(input.special_to_date);
    this.product_id = input.entity_id;
    if (input.special_price) {
      this.special_price = +input.special_price;
    }
    this.original_price = +input.price;
    // If special price is expired special price become 0
    if (
      this.special_price &&
      this.special_to_date instanceof Date &&
      this.special_to_date < new Date(new Date().toDateString())
    ) {
      this.special_price = 0;
    }

    if (input.elite_price) {
      this.elite_price = +input.elite_price;
    }

    if (input.offer_price) {
      this.offer_price = +input.offer_price;
    }

    this.price_text = input.price_text;
    this.offer_text = input.offer_text;
    this.is_elite = input.is_elite;
    this.per_month_subs_price = input.per_month_subs_price;
    this.per_unit_price = input.per_unit_price;

    return this;
  }

  get productId() {
    return this.product_id;
  }

  get computePrice() {
    if (this.isOfferApplicable) {
      return this.offerPrice;
    }

    return this.originalPrice;
  }

  get originalPrice() {
    return this.original_price;
  }

  get specialPrice() {
    return this.special_price;
  }

  get isSpecialPriceApplicable() {
    if (this.special_price && this.special_price != 0) {
      return this.special_price < this.original_price;
    }
    return false;
  }

  get offerPrice(): number {
    return this.offer_price;
  }

  /**
   * Returns true if offer price is not zero and less than original price
   */
  get isOfferApplicable(): boolean {
    return this.offerPrice !== 0 && this.offerPrice < this.originalPrice;
  }

  get priceText(): string {
    return this.price_text;
  }

  get offerText(): string {
    return this.offer_text;
  }

  get isElite(): boolean {
    return this.is_elite;
  }
  get elitePrice(): number {
    return this.elite_price;
  }

  get perMonthSubsPrice(): number {
    return this.per_month_subs_price;
  }

  get perUnitPrice(): number {
    return this.per_unit_price;
  }
  /**
   * Compute elite saving cost for per unit
   */
  get eliteSavingsPerUnit(): number {
    if(!this.elitePrice) return 0;
    if(this.elitePrice > this.originalPrice) return 0;

    return this.originalPrice - this.elitePrice;
  }
}


import { Injectable } from "@angular/core";

import { BasePostProvider } from "@tendercuts/http";
import { GenericServerResponse } from "@tendercuts/models";

import { HttpClient } from "@angular/common/http";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";

@Injectable()
export class AssignDriverService extends BasePostProvider<GenericServerResponse> {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get endpoint(): string {
    return this.endpointService.assignDriver();
  }

  get model(): typeof GenericServerResponse {
    return GenericServerResponse;
  }

  getParams(
    driverUser: any,
    tripId: any,
    canReassignDriver: boolean = false,
  ): {
    driver_user: any;
    trip_id: any;
    can_reassign_driver: boolean;
  } {
    const params: {
      driver_user: any;
      trip_id: any;
      can_reassign_driver: boolean;
    } = {
      driver_user: driverUser,
      trip_id: tripId,
      can_reassign_driver: canReassignDriver,
    };

    return params;
  }
}

import { Serializable } from "../base";

export class NextAvailableSlots implements Serializable<NextAvailableSlots> {
  express: string;
  pickup: string;
  scheduled: string;

  constructor() {}

  deserialize(input) {
    this.express = input.express;
    this.pickup = input.pickup;
    this.scheduled = input.scheduled;

    return this;
  }
}

import { Component, Input } from "@angular/core";
import { GridRowDetailComponent } from "src/app/components/base-grid/grid-row-detail.component";

/**
 * component to show the segments in the trip earnings
 */
@Component({
  selector: "app-trip-earning-segment-display",
  templateUrl: "./trip-segments.component.html",
  styleUrls: ["./trip-segments.component.scss"],
})
// TODO
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class TripEarningSegmentDisplay implements GridRowDetailComponent {
  @Input()
  data: any;

  constructor() {}
}

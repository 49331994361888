<!--Filters and search-->
<mat-sidenav-container>
  <!--Table view-->
  <mat-sidenav-content>
    <!--Toolbar-->
    <mat-toolbar class="title">
      <mat-toolbar-row>
        <!-- <span>Plan Routes</span> -->

        <button
          appFirebaseControlledElement
          mat-raised-button
          color="primary"
          id="assisted-plan"
          (click)="showTripWizard()"
        >
          <i class="material-icons">commute</i>
          Assisted Planning
        </button>

        <button
          appFirebaseControlledElement
          mat-raised-button
          color="primary"
          id="manual-plan"
          (click)="addTrip()"
        >
          <i class="material-icons">directions</i>
          Manual Planning
        </button>

        <button
          mat-raised-button
          appFirebaseControlledElement
          color="primary"
          id="auto-routes"
          (click)="loadAutoRoutes()"
        >
          <i class="material-icons">commute</i>
          Auto Routes
        </button>
        <span class="title__order_count"
          >Orders Per Trip:<strong>
            {{ (driverState | async).tripConfig?.ordersPerTrip }}</strong
          ></span
        >
        <span class="example-spacer"></span>
        <app-symbol-legend class="col-4"></app-symbol-legend>
      </mat-toolbar-row>
    </mat-toolbar>

    <div
      class="route-wrapper"
      *ngFor="let trip of optimalTrips; let index = index"
    >
      <ng-container *ngIf="showOptimalTrip">
        <app-optimal-trip-display
          [trip]="trip"
          (triggerAction)="openTripDrawer(trip)"
          [segments]="segments"
        ></app-optimal-trip-display>
      </ng-container>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>

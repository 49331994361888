/* eslint-disable @typescript-eslint/naming-convention */
import { Serializable } from './base';
export class CustomerEvents implements Serializable<CustomerEvents> {
  private elite_status: boolean;
  private elite_subscription_count: number;
  private elite_cohort: string;
  private elite_subscription_days: number;
  private elite_subscription_days_cohort: string;
  private total_elite_saving: number;
  private total_elite_saving_cohort: string;
  private total_elite_free_order: number;
  private total_elite_free_order_cohort: string;
  private last_elite_renewal_date: Date;
  private lifetime_order_count: number;
  private lifetime_order_count_cohort: string;
  private last_thirty_days_order_count: number;
  private last_thirty_days_order_count_cohort: string;
  private last_seven_days_order_count: number;
  private last_seven_days_order_count_cohort: string;
  private days_since_last_purchase: number;
  private last_order_coupon_status: boolean;

  deserialize(input: any): CustomerEvents{
    if (!input) {
      return;
    }

    this.elite_status = input.elite_status;
    this.elite_subscription_count = input.elite_subscription_count;
    this.elite_cohort = input.elite_cohort;
    this.elite_subscription_days = input.elite_subscription_days;
    this.elite_subscription_days_cohort = input.elite_subscription_days_cohort;
    this.total_elite_saving = input.total_elite_saving;
    this.total_elite_saving_cohort = input.total_elite_saving_cohort;
    this.total_elite_free_order = input.total_elite_free_order;
    this.total_elite_free_order_cohort = input.total_elite_free_order_cohort;

    this.last_elite_renewal_date = new Date(input.last_elite_renewal_date);
    this.lifetime_order_count = input.lifetime_order_count;
    this.lifetime_order_count_cohort = input.lifetime_order_count_cohort;
    this.last_thirty_days_order_count = input.last_thirty_days_order_count;
    this.last_thirty_days_order_count_cohort = input.last_thirty_days_order_count_cohort;
    this.last_seven_days_order_count = input.last_seven_days_order_count ;
    this.last_seven_days_order_count_cohort = input.last_seven_days_order_count_cohort;
    this.days_since_last_purchase = input.days_since_last_purchase;
    this.last_order_coupon_status = input.last_order_coupon_status;

    return this;
  }

  get eliteStatus(): boolean {
    return this.elite_status;
  }

  get eliteSubscriptionCount(): number {
    return this.elite_subscription_count;
  }

  get eliteCohot(): string {
    return this.elite_cohort;
  }

  get eliteSubscriptionDaya(): number {
    return this.elite_subscription_days;
  }

  get eliteSubscriptionDaysCohort(): string {
    return this.elite_subscription_days_cohort;
  }

  get totalEliteSaving(): number {
    return this.total_elite_saving;
  }

  get totalEliteSavingCohort(): string {
    return this.total_elite_saving_cohort;
  }

  get totalEliteFreeOrder(): number {
    return this.total_elite_free_order;
  }

  get totalEliteFreeOrderCohort(): string {
    return this.total_elite_free_order_cohort;
  }

  get lastEliteRenewalDate(): Date {
    return this.last_elite_renewal_date;
  }

  get lifetimeOrderCount(): number {
    return this.lifetime_order_count;
  }

  get lifetimeOrderCountCohort(): string {
    return this.lifetime_order_count_cohort;
  }

  get lastThirtyDaysOrderCount(): number {
    return this.last_thirty_days_order_count;
  }

  get lastThirtyDaysOrderCountCohort(): string {
    return this.last_thirty_days_order_count_cohort;
  }

  get lastSevenDaysOrderCount(): number {
    return this.last_seven_days_order_count;
  }

  get lastSevenDaysOrderCountCohort(): string {
    return this.last_seven_days_order_count_cohort;
  }

  get daysSinceLastPurchase(): number {
    return this.days_since_last_purchase;
  }

  get lastOrderCouponStatus(): boolean {
    return this.last_order_coupon_status;
  }
}

import { Component, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { StoreState } from "@store/store";
import { DeliverySlot, TripsHistory } from "@tendercuts/models";
import { map, skipWhile, take } from "rxjs/operators";
import { BasePage } from "src/app/utils";
import { Filter, FilterGroup } from "src/models";
import { FetchTripsHistoryService } from "src/providers";

interface TripsListDataSource {
  tripNumber: number;
  pathString: string;
  color: string;
  selected: boolean;
}

@Component({
  selector: "app-trips-history",
  templateUrl: "./trips-history.component.html",
  styleUrls: ["./trips-history.component.scss"],
})
export class TripsHistoryComponent extends BasePage implements OnInit {
  initialLatitude: number = 18.7679;
  initialLongitude: number = 78.8718;
  coordinatesArray: number[][][] = [];
  previousDate: Date = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);
  slots: DeliverySlot[] = [];
  canSearchWithPathString: boolean = false;
  pathStringsControl: FormControl = new FormControl("", [Validators.required]);
  dateControl: FormControl = new FormControl("", [Validators.required]);
  selectedSlots: number[] = [];
  zoom: number = 5;
  colors: string[] = [];
  slotFilterGroup: FilterGroup = new FilterGroup([]);
  displayedColumns: string[] = ["tripId"];
  selectedTrip: number = null;
  tripsList: TripsListDataSource[] = [];
  pathStrings: string;
  tempPathStringsData: string;
  color: string;
  tripsHistoryResponse: TripsHistory;

  constructor(
    public store: Store<AppState>,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public fetchTripsHistoryService: FetchTripsHistoryService,
    private title: Title,
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    this.title.setTitle("Trips history");
    this.fetchDeliverySlots();
  }

  /**
   * Function to fetch slots data
   */
  fetchDeliverySlots(): void {
    this.storeState
      .pipe(
        skipWhile((state) => state.loading === true),
        take(1)
      )
      .subscribe((storeState: StoreState) => {
        this.slotFilterGroup.filters = storeState.deliverySlotFilters.filter(
          (slot) => !slot.isPickup
        );
      });
  }

  /**
   * Function to update the selected slots in an array
   * @param slot
   */
  updateSelectedSlots(slot: Filter): void {
    slot.selected = !slot.selected;
    const index: number = this.selectedSlots.indexOf(slot.value);

    if (index > -1) {
      this.selectedSlots.splice(index, 1);

      return;
    }

    this.selectedSlots.push(slot.value);
  }

  /**
   * Function to empty the coordinates array
   * when the search mode is changed
   */
  searchModeChanged(): void {
    this.coordinatesArray = [];
    this.pathStrings = "";
    this.tripsList = [];
    this.color = null;
  }

  /**
   * zoom to the given coordinate
   */
  zoomToCoordinate(coordinates: number[]): void {
    this.initialLatitude = coordinates[0];
    this.initialLongitude = coordinates[1];
    this.zoom = 15;
  }

  /**
   * Prepare the data to display in map.
   * @param pathStringsArray
   * pathStringArray consist of lat and lng values for a trip.
   * The lat and lng coordinates are made as separate arrays
   * and pushed into tripPath array. 
   * The tripPath array is pushed into map's datasource.
   */
  prepareDataForMap(pathStringsArray: number[]): void {
    let latitudeAndLongitude: number[] = [];
    const tripPath: number[][] = [];
    pathStringsArray.forEach((coordinate) => {
      latitudeAndLongitude.push(coordinate);
      if (latitudeAndLongitude.length === 2) {
        tripPath.push(latitudeAndLongitude);
        latitudeAndLongitude = [];
      }
    });
    this.coordinatesArray.push(tripPath);
  }

  /**
   * Function to fetch store id
   */
  async fetchStoreId(): Promise<number> {
    const storeId: number = await this.storeState
      .pipe(
        skipWhile((state) => state.loading === true),
        take(1),
        map((state) => state.store)
      )
      .toPromise();

    return storeId;
  }

  getRandomColor(): string {
    const letters: string = "0123456789ABCDEF";
    let color: string = "#";
    for (let i: number = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }

    return color;
  }

  /**
   * Function to prepare the coordinates and draw polylines
   */
  drawPolyLines(pathString: string): void {
    this.coordinatesArray = [];
    const tripsPathArray: number[][] = [];
    const tripsRoute: string[] = pathString.trim().split("\n");

    tripsRoute.forEach((tripsPath, i) => {
      const randomColor: string = this.getRandomColor();
      this.colors.push(randomColor);
      this.tripsList.push(this.prepareTripsListData(i, tripsPath, randomColor));
      tripsPathArray.push(
        tripsPath.split(",").map((item) => {
          return Number(item.trim());
        })
      );
    });

    tripsPathArray.forEach((tripsPath) => {
      this.prepareDataForMap(tripsPath);
    });

    if (tripsPathArray[0].length >= 2) {
      this.zoomToCoordinate([tripsPathArray[0][0], tripsPathArray[0][1]]);
    }
  }

  /**
   * Prepare the coordinates and draw polylines
   * with the date and slots selected
   */
  async drawPolyLinesWithDateAndSlots(shouldFetchData: boolean = false): Promise<void> {
    const storeId: number = await this.fetchStoreId();

    const params: string = this.fetchTripsHistoryService.getParams(
      storeId,
      this.dateControl.value,
      this.selectedSlots
    );

    if (shouldFetchData) {
      this.presentLoader();
      this.tripsHistoryResponse = await this.fetchTripsHistoryService
        .getData(params)
        .toPromise()
        .catch((err) => {
          this.somethingWentWrong();
        });
      this.dismissLoader();
    }

    const pathString: string = this.tripsHistoryResponse[0].pathString;
    if (!pathString || pathString === "") {
      this.textAlert("No trips found", "");
      this.searchModeChanged();

      return;
    }
    this.drawPolyLines(pathString);
  }

  /**
   * Function to highlight the selected trip
   * @param trip
   * @returns
   */
  highlightTripInMap(trip: TripsListDataSource): void {
    if (this.selectedTrip == trip.tripNumber) {
      this.selectedTrip = null;
    } else {
      this.color = trip.color;
      this.selectedTrip = trip.tripNumber;
    }
    if (this.selectedTrip == null) {
      this.pathStrings = this.tempPathStringsData;
      this.color = null;
      this.updateMap();

      return;
    }
    this.drawPolyLines(trip.pathString);
  }

  /**
   * Function to highlight selected row
   * @param trip
   * @returns
   */
  canHighLightRowInTable(trip: TripsListDataSource): boolean {
    if (this.selectedTrip == trip.tripNumber) {
      return true;
    }

    return false;
  }

  /**
   * Function to update the map with new poly lines
   */
  updateMap(shouldFetchData: boolean = false): void {
    this.pathStrings = this.tempPathStringsData;
    if (shouldFetchData) {
      this.colors = [];
      this.color = null;
      this.tripsList = [];
    }
    this.selectedTrip = null;
    if (!this.canSearchWithPathString) {
      this.drawPolyLinesWithDateAndSlots(shouldFetchData);

      return;
    }

    this.drawPolyLines(this.pathStringsControl.value);
  }

  /**
   * Function used to create datasource for trips table
   * @param tripNumber
   * @param pathString
   * @param color
   * @param selected
   * @returns
   */
  prepareTripsListData(
    tripNumber: number,
    pathString: string,
    color: string,
    selected: boolean = false
  ): TripsListDataSource {
    return {
      tripNumber,
      pathString,
      color,
      selected,
    };
  }

  /**
   * Getter to disable the search btn
   * if path string is empty or data and slots are not selected
   */
  get disableSearchBtn(): boolean {
    return (
      (!this.pathStringsControl.value && this.canSearchWithPathString) ||
      ((!this.dateControl.value || !this.selectedSlots.length) &&
        !this.canSearchWithPathString)
    );
  }

  /**
   * Function to open tooltip on mouse over on map marker
   * @param infoWindow
   */
  onMouseOverMapMarker(infoWindow: google.maps.InfoWindow): void {
    infoWindow.open();
  }

  /**
   * Function to close tooltip on mouse out on map marker
   */
  onMouseOutMapMarker(infoWindow: google.maps.InfoWindow): void {
    infoWindow.close();
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { OptimalTrip, SaleOrder, StoreSegment } from "@tendercuts/models";
import { map, take } from "rxjs/operators";
import { BasePage } from "../../utils/pages/base/base.component";

@Component({
  selector: "app-optimal-trip-display",
  templateUrl: "./optimal-trip.component.html",
  styleUrls: ["./optimal-trip.component.scss"],
})
export class OptimalTripComponent extends BasePage implements OnInit {
  @Input()
  set trip(trip: OptimalTrip) {
    this.optimalTrip = trip;

    this.orders = [];
    this.orderState
      .pipe(
        take(1),
        map((state) => state.orderMap),
      )
      .subscribe((orderMap) => {
        this.optimalTrip.orders.forEach((orderId) =>
          this.orders.push(orderMap[orderId]),
        );
      });
  }

  @Input() segments: StoreSegment[];

  @Output() public triggerAction: EventEmitter<any> = new EventEmitter();

  optimalTrip: OptimalTrip;
  orders: SaleOrder[] = [];

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {}

  /**
   * @func removeOrder
   * @param order : increment_id
   * Removes the given order from the trip
   */
  removeOrder(orderId: string): void  {
    this.optimalTrip.orders.delete(orderId);
    this.orders = this.orders.filter((order) => order.incrementId !== orderId);
  }

  get verifiedCount(): number {
    return this.orders.filter((order) => order.isVerified).length;
  }

  get urgentCount(): number {
    return this.orders.filter((order) => order.timeRemaining < 75).length;
  }
}

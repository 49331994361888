import { HttpParams } from "@angular/common/http";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import {
  GramInventory,
  InventoryLog,
  InventoryRequest,
  SaleItem,
} from "@tendercuts/models";
import { Product } from "@tendercuts/models";
import {
  FetchInventoryDebugEntryService,
  FetchInventoryPurchaseService,
  FetchInventorySaleService,
  FetchParentProductService,
  InventoryDebugService,
} from "@tendercuts/providers";
import { Subscription } from "rxjs";
import {
  InventoryDebugDataSource,
  InventoryRequestDataSource,
  SaleItemDataSource,
} from "src/app/components/data-source";
import { FilterModel } from "src/models";
import { Category } from "tendercuts/models/catalog/category";
import { BasePage } from "../../utils";

/**
 * component to load inventory log details
 */
@Component({
  selector: "app-inventory-debug",
  templateUrl: "./inventory-debug.component.html",
  styleUrls: ["./inventory-debug.component.scss"],
})
export class InventoryLogComponent
  extends BasePage
  implements OnInit, OnDestroy {
  inventoryForm: FormGroup;

  storeSub: Subscription;

  // to store selected storeId
  selectedStoreId: number;

  // store the Product name
  storeProducts: any[] = [];

  // date for max check in date picker
  todayDate: Date = new Date();

  // Selected product
  searchedProducts: any[] = this.storeProducts;

  // Check the DropDown is open or not
  searchIsOpen: boolean = false;

  modelFilter: FilterModel = new FilterModel([]);

  inventoryDebugDataSource: InventoryDebugDataSource = new InventoryDebugDataSource(
    this.modelFilter,
    [],
  );

  inventoryPurchseDataSource: InventoryRequestDataSource = new InventoryRequestDataSource(
    this.modelFilter,
    [],
  );

  inventorySaleDataSource: SaleItemDataSource = new SaleItemDataSource(
    this.modelFilter,
    [],
  );

  // Inventory Purchase
  @Input() columnsToDisplay: string[] = [
    "sku",
    "qty",
    "gpu",
    "inventoryCreatedTime",
  ];
  @Input() titleToDisplay: string[] = ["SKU", "Quantity", "Weight", "Time"];
  @Input() footerColumnsToDisplay: string[] = ["qty", "gpu"];
  // Inventory Sales
  @Input() saleItmFooterColumnsToDisplay: string[] = [
    "quantity",
    "displayWeight",
  ];
  @Input() saleItemColumnsToDisplay: string[] = [
    "sku",
    "quantity",
    "displayWeight",
  ];
  @Input() saleItemTitleToDisplay: string[] = ["SKU", "Quantity", "Weight"];

  gramInventory: GramInventory;

  inventoryPurchase: InventoryRequest[] = [];

  inventorySale: SaleItem[] = [];

  constructor(
    public formBuilder: FormBuilder,
    public title: Title,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public fetchParentProductService: FetchParentProductService,
    public inventoryDebugService: InventoryDebugService,
    public fetchInventoryDebugEntryService: FetchInventoryDebugEntryService,
    public fetchInventoryPurchaseService: FetchInventoryPurchaseService,
    public fetchInventorySaleService: FetchInventorySaleService,
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    this.inventoryForm = this.formBuilder.group({
      selectedSKU: ["", Validators.required],
      selectedDate: ["", Validators.required],
      displayProduct: ["", Validators.required],
    });
    this.fetchStoreData();
  }

  fetchStoreData(): void {
    this.storeSub = this.storeState.subscribe((state) => {
      if (this.selectedStoreId == state.store) {
        return;
      }
      this.selectedStoreId = state.store;
      this.fetchParentSku(this.selectedStoreId);
      this.inventoryForm.setValue({
        selectedSKU: "",
        selectedDate: "",
        displayProduct: "",
      });
      this.inventoryDebugDataSource.data = [];
    });
  }

  /**
   * User enter a key to filter the sku products.
   */
  searchProduct(product: string): void {
    this.searchedProducts = this.storeProducts.filter((filter) =>
      filter.name.toLowerCase().includes(product),
    );
  }

  /**
   * fetch the Inventory Product Name and store the Array
   * @params storeId
   */
  fetchParentSku(storeId: number): void {
    this.selectedStoreId = storeId;
    this.presentLoader();
    if (storeId == undefined) {
      this.textAlert("Please select store", "To load Inventory Debug");
      this.dismissLoader();

      return;
    }
    this.dismissLoader();
    const params: HttpParams = this.fetchParentProductService.getParams(
      storeId.toString(),
    );
    this.fetchParentProductService
      .getData(params)
      .subscribe((response: Category[]) => {
        this.dismissLoader();
        response.forEach((category: Category) => {
          category.products.map((product: Product) => {
            this.storeProducts.push(product);
          });
        });
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (error) => {
          this.dismissLoader();
          this.somethingWentWrong();
        };
      });
  }

  /**
   * Fetch gram inventory from server
   */
  fetchGramInventory(): void {
    const product: Product = this.storeProducts.filter(
      (productData) => productData.sku == this.inventoryForm.value.selectedSKU,
    )[0];

    this.fetchInventoryPurchse(product);
    this.fetchInventorySale(product);
    this.fetchInventoryLogs();
    const params: {
      store_id: number;
      product_id: number;
      date: string;
    } = this.fetchInventoryDebugEntryService.getParams(
      this.selectedStoreId,
      product.id,
      this.inventoryForm.value.selectedDate,
    );

    this.fetchInventoryDebugEntryService.getData(params).subscribe(
      (data: GramInventory[]) => {
        if (!data.length) {
          return;
        }

        this.gramInventory = data[0];
      },
      (error) => this.somethingWentWrong(),
    );
  }

  /**
   * Fetch Inventory Purchase logs from server
   */
  fetchInventoryPurchse(product: Product): void {
    const params: {
      store_id: number;
      product_id: number;
      created_time: string;
    } = this.fetchInventoryPurchaseService.getParams(
      this.selectedStoreId,
      product.id,
      this.inventoryForm.value.selectedDate,
    );

    this.fetchInventoryPurchaseService.getData(params).subscribe(
      (data: InventoryRequest[]) => {
        this.inventoryPurchase = data;
        this.inventoryPurchseDataSource.data = data;
      },
      (error) => this.somethingWentWrong(),
    );
  }

  /**
   * ftch inventory sales logs from server
   * @param product
   */
  fetchInventorySale(product: Product): void {
    const params: {
      store_id: number;
      sku: string;
      date: string;
    } = this.fetchInventorySaleService.getParams(
      this.selectedStoreId,
      this.inventoryForm.value.selectedSKU,
      this.inventoryForm.value.selectedDate,
    );

    this.fetchInventorySaleService.getData(params).subscribe(
      (data: SaleItem[]) => {
        this.inventorySale = data;
        this.inventorySaleDataSource.data = data;
      },
      (error) => this.somethingWentWrong(),
    );
  }

  fetchInventoryLogs(): void {
    const formateDate: string = this.getFormattedDate(
      this.inventoryForm.value.selectedDate,
    );
    const params: HttpParams = this.inventoryDebugService.getParams(
      this.selectedStoreId,
      this.inventoryForm.value.selectedSKU,
      formateDate,
    );
    this.inventoryDebugService
      .getData(params)
      .subscribe((response: InventoryLog[]) => {
        this.inventoryDebugDataSource.selection.clear();
        this.inventoryDebugDataSource.data = response;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (error) => {
          this.dismissLoader();
          this.somethingWentWrong();
        };
      });
  }
  /**
   * Need to select product so setTimeOut
   */
  searchFieldBlur(): void {
    this.inventoryForm.controls["selectedSKU"].setValue(
      this.inventoryForm.controls["selectedSKU"].value,
    );
    setTimeout(() => {
      this.searchIsOpen = false;
      const clearSearch: string[] = this.storeProducts.filter(
        (filter) => filter.name == this.inventoryForm.value.displayProduct,
      );
      if (!clearSearch.length) {
        this.inventoryForm.patchValue({
          displayProduct: "",
        });
        this.searchedProducts = this.storeProducts;
      }
    }, 200);
  }

  /**
   * change the date format(YYYY-MM-DD)
   * @param date
   */
  getFormattedDate(date: any): string {
    const currDate: any = date.getDate();
    const currMonth: any = date.getMonth() + 1;
    const currYear: any = date.getFullYear();

    return currYear + "-" + currMonth + "-" + currDate;
  }

  /**
   * pass the values to get inventory data
   * @params storeId, sku, date
   */
  searchInventory(): void {
    if (
      this.inventoryForm.value.selectedSKU == "" ||
      this.inventoryForm.value.selectedDate == ""
    ) {
      this.showNotification(
        "top",
        "center",
        "danger",
        "info-circle",
        "Please select the Required field",
      );

      return;
    }

    this.fetchGramInventory();
  }

  /**
   * total gpu of the purchase inventory
   */
  get totalPurchaseWeight(): number {
    return this.inventoryPurchase.reduce(
      (total: number, inventory: InventoryRequest) => total + inventory.gpu,
      0,
    );
  }

  /**
   * total gpu of the sale inventory
   */
  get totalSaleWeight(): string {
    return this.inventorySale
      .reduce(
        (total: number, inventory: SaleItem) =>
          total + +inventory.displayWeight,
        0,
      )
      .toFixed(3);
  }

  /**
   * clear the search field and assign the value selectedSKU
   * @param product
   */
  onSelectProduct(product: Product): void {
    this.searchIsOpen = false;
    this.inventoryForm.controls["displayProduct"].setValue(product.name);
    this.inventoryForm.controls["selectedSKU"].setValue(product.sku);
  }

  //  unsubcribe the store data
  ngOnDestroy(): void {
    this.storeSub?.unsubscribe();
  }
}

import { Component, Inject, Input } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { SaleItem, SaleOrder } from "@tendercuts/models";
import { HoldOrderService } from "@tendercuts/providers";
import { BasePage } from "../../utils/pages/base/base.component";

@Component({
  // TODO
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "app-holdOrder",
  templateUrl: "./holdorder.component.html",
  styleUrls: ["./holdorder.component.scss"],
})
export class HoldOrderComponent extends BasePage {
  oosItems: number[] = [];

  @Input() order: SaleOrder;
  holdOrderControl: FormControl = new FormControl();
  options: {
    value: string;
    viewValue: string;
}[] = [
    { value: "hold_dp", viewValue: "Dp Mismatch / Out Of Service" },
    { value: "hold_blocked", viewValue: "Blocked Area" },
    { value: "hold_oos", viewValue: "Out of Stock" },
  ];
  selected: any;

  constructor(
    public dialogRef: MatDialogRef<HoldOrderComponent>,
    public holdOrderService: HoldOrderService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: SaleOrder,
  ) {
    super(dialog, snackBar, store);
    if (data) {
      this.order = data;
    }
  }
  /**
   * process to hold the order
   */
  proceedToHold(): void {
    const selectedOption: string = this.holdOrderControl.value;
    if (selectedOption == "hold_oos" && this.oosItems.length == 0) {
      this.textAlert("Alert", "Choose out of stock product to hold");

      return;
    }
    const params: {
      increment_id: string;
      reason: string;
      items: number[];
  } = this.holdOrderService.getparams(
      this.order.incrementId,
      selectedOption,
      this.oosItems,
    );
    this.dialogRef.close(params);
  }

  onItemSelect(event: MatCheckboxChange, item: SaleItem): void  {
    if (event.checked) {
      this.oosItems.push(item.productId);

      return;
    }
    const index: number = this.oosItems.findIndex((x) => x === item.productId);
    this.oosItems.splice(index, 1);
  }
}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NavigationEnd } from "@angular/router";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { GenerateTripSheetResponse } from "@tendercuts/models";
import { TripSheet } from "@tendercuts/models";
import { TripSheetGenerateService } from "@tendercuts/providers";
import { Subscription } from "rxjs";
import { BasePage } from "src/app/utils/pages/base/base.component";

@Component({
  selector: "app-tripsheet-detail",
  templateUrl: "./tripsheet-details.component.html",
  styleUrls: ["./tripsheet-details.component.scss"],
})
export class TripSheetDetailsComponent
  extends BasePage
  implements OnInit, OnDestroy {
  tripSheet: TripSheet;

  selectedDate: Date;

  driverId: number;

  routerEventSubcriber: Subscription;

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public generateTripsheetService: TripSheetGenerateService,
    public router: Router,
  ) {
    super(dialog, snackBar, store);
  }

  /**
   * listen the route params change if the there is any change in the route
   * parameter it will fetch the data according to the activated route value
   */
  ngOnInit(): void {
    this.routerEventSubcriber = this.router.events.subscribe((data) => {
      if (data instanceof NavigationEnd) {
        this.fetchData();

        return;
      }
    });
    this.fetchData();
  }

  /**
   * get the values from the router
   * And pass the values and fetchTripSheetData
   */
  fetchData(): void {
    this.selectedDate = new Date(this.route.snapshot.params["selectedDate"]);
    this.driverId = this.route.snapshot.params["selectedRider"];
    this.fetchTripSheetData();
  }

  /**
   * Get the Driver Trip Sheet data from service
   * @param driverId
   * @param selectedDate
   */
  fetchTripSheetData(): void {
    // TODO
    // eslint-disable-next-line use-isnan
    if (this.driverId == NaN) {
      this.router.navigate(["dashboard/generate-trip-sheet"]);

      return;
    }
    this.presentLoader();
    const params: {
      driver_id: string;
      earning_date: string;
  } = this.generateTripsheetService.getParams(
      this.driverId,
      this.selectedDate,
    );
    this.generateTripsheetService.getData(params).subscribe(
      (data: GenerateTripSheetResponse[]) => {
        this.dismissLoader();
        if (data[0].status) {
          this.tripSheet = data[0].result;
        } else {
          this.showNotification(
            "bottom",
            "center",
            "danger",
            "info-circle",
            data[0].message,
          );
        }
      },
      (errorResponse) => {
        if (errorResponse.status === 400) {
          this.showNotification(
            "top",
            "center",
            "danger",
            "info-circle",
            errorResponse.error.message,
          );

          return;
        }
        this.somethingWentWrong();
      },
    );
  }

  reloadTripSheet(tripSheet: TripSheet): void {
    this.fetchTripSheetData();
  }

  ngOnDestroy(): void {
    if (this.routerEventSubcriber) {
      this.routerEventSubcriber.unsubscribe();
    }
  }
}

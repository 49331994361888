import { Serializable } from "../base";

export class ComboSpec implements Serializable<ComboSpec> {
  private product_id: number;
  private linked_product_id: number;
  private qty: string;

  constructor() {}

  deserialize(input) {
    this.product_id = input.product_id;
    this.linked_product_id = input.linked_product_id;
    this.qty = input.qty;
    return this;

  }

  get productId() {
    return this.linked_product_id;
  }

  get weight() {
    return +this.qty;
  }
}

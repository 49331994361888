import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import {
  DriverLoginLogout,
  GenerateTripSheetResponse,
  TripSheet,
} from "@tendercuts/models";
import { BasePage } from "src/app/utils";
import {
  FetchSpeedoMeterSnapShot,
  TripSheetGenerateService,
} from "src/providers";

@Component({
  selector: "app-speedo-meter-validation",
  templateUrl: "./speedo-meter-validation.component.html",
  styleUrls: ["./speedo-meter-validation.component.scss"],
})
export class SpeedoMeterValidationComponent extends BasePage implements OnInit {
  tripSheet: TripSheet;

  // selected store rider
  @Input()
  driverProfile: DriverLoginLogout;

  // user selected date
  @Input() selectedDate: Date;

  // move to next step
  @Output() public moveStepper: EventEmitter<any> = new EventEmitter();

  // form group controller for all fields
  validationForm: FormGroup;

  // flag for show editable checkIn value input tag
  editCheckIn: boolean = false;

  // flag for show editable checkOut value input tag
  editCheckOut: boolean = false;

  constructor(
    public fetchSpeedoMeterSnapShotService: FetchSpeedoMeterSnapShot,
    public generateTripsheetService: TripSheetGenerateService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public router: Router,
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    this.validationForm = new FormGroup({});
    this.fetchSpeedoMeterDetails();
  }

  /**
   * fetch the driver speedo meter details
   */
  fetchSpeedoMeterDetails(): void {
    const params: {
      driver_id: string;
      date: string;
    } = this.fetchSpeedoMeterSnapShotService.getParams(
      this.driverProfile.driverId,
      this.selectedDate,
    );

    this.fetchSpeedoMeterSnapShotService
      .getData(params)
      .subscribe((data: TripSheet[]) => {
        this.tripSheet = data[0];
        this.validationForm = new FormGroup({
          correctedCheckIn: new FormControl(this.tripSheet.checkInValue, [
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(5),
          ]),
          correctedCheckOut: new FormControl(this.tripSheet.checkOutValue, [
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(5),
          ]),
        });

        if (!this.tripSheet.checkOutValue) {
          this.dialog.closeAll();
          this.showNotification(
            "top",
            "center",
            "danger",
            "info-circle",
            "Please collect cash for all trips & try again",
          );
        }
      });
  }

  /**
   * updated the corrected check out value
   */
  validateCheckIn(): void {
    if (!this.editCheckIn) {
      this.editCheckIn = true;

      return;
    }

    const onSuccess: (result: any) => void = (result) => {
      if (!result) {
        return;
      }

      this.tripSheet.updateCheckInValue(
        this.validationForm.value["correctedCheckIn"],
      );
      this.editCheckIn = false;
    };

    this.optionsAlert(
      "Are you sure?",
      "you want to update the checkIn value",
      onSuccess,
    );
  }

  /**
   * update the corrected check out value
   */
  validateCheckOut(): void {
    if (!this.editCheckOut) {
      this.editCheckOut = true;

      return;
    }

    const onSuccess: (result: any) => void = (result) => {
      if (!result) {
        return;
      }

      this.tripSheet.updateCheckOutValue(
        this.validationForm.value["correctedCheckOut"],
      );
      this.editCheckOut = false;
    };

    this.optionsAlert(
      "Are you sure?",
      "you want to update the checkout value",
      onSuccess,
    );
  }

  /**
   * checks for speedo meter values. once validated hit the generate trip
   * sheet api
   */
  confirmSpeedoMeter(): void {
    if (
      this.validationForm.value["correctedCheckIn"].toString().length != 5 ||
      this.validationForm.value["correctedCheckOut"].toString().length != 5
    ) {
      this.errorMessage("checkIn/checkOut values should be 5 digits");

      return;
    }

    if (
      this.validationForm.value["correctedCheckIn"] >
        this.validationForm.value["correctedCheckOut"] ||
      this.validationForm.value["correctedCheckOut"] -
        this.validationForm.value["correctedCheckIn"] >
        200
    ) {
      this.errorMessage(
        "checkout value should be less than checkIn value / km travelled not more than 200",
      );

      return;
    }

    this.generateTripSheet();
  }

  /**
   * error message display
   * @param error
   */
  errorMessage(error: string): void {
    this.showNotification("bottom", "center", "danger", "info-circle", error);
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  /**
   * date format MM/DD/YYYY
   * eg:- 02/23/2021
   * @param date
   */
  dateFormat(date: Date): string {
    return (
      date.getMonth() + 1 + "-" + date.getDate() + "-" + date.getFullYear()
    );
  }
  /**
   * fetch the generate trip sheet api. if success navigate the trip detail page
   */
  generateTripSheet(): void {
    const params: {
      driver_id: string;
      earning_date: string;
      corrected_check_in_value: number;
      corrected_check_out_value: number;
  } = this.generateTripsheetService.updateCorrectedValues(
      this.driverProfile.driverId,
      this.selectedDate,
      parseInt(this.validationForm.value["correctedCheckIn"]),
      parseInt(this.validationForm.value["correctedCheckOut"]),
    );
    this.generateTripsheetService.getData(params).subscribe(
      (data: GenerateTripSheetResponse) => {
        this.dismissLoader();
        if (data[0].status) {
          this.router.navigate([
            "dashboard/tripsheet-detail/" +
              this.dateFormat(this.selectedDate) +
              "/" +
              this.driverProfile.driverId,
          ]);
          this.dialog.closeAll();
        } else {
          this.showNotification(
            "bottom",
            "center",
            "danger",
            "info-circle",
            data[0].message,
          );
        }
      },
      (errorResponse: any) => {
        this.dismissLoader();
        if (errorResponse.status === 400) {
          this.showNotification(
            "top",
            "center",
            "danger",
            "info-circle",
            errorResponse.error.message,
          );

          return;
        }
        this.somethingWentWrong();
      },
    );
  }
}

<mat-sidenav-container>
  <mat-card class="driver_card">
    <mat-card-title class="row no-gutters driver_card__title">
      <mat-form-field class="driver_card__title__week">
        <mat-label>Choose a week</mat-label>
        <input
          matInput
          type="week"
          id="week-picker"
          [value]="selectedWeek"
          [max]="maxWeek"
          [min]="minWeek"
          (change)="onSelectWeek($event)"
          placeholder="Select a week"
        />
      </mat-form-field>

      <mat-form-field class="driver_card__title__search">
        <input
          matInput
          id="searchbar"
          (keyup)="applyDriverSearchFilter($event.target.value)"
          placeholder="Search Id/ Week No"
          #searchbar
        />
      </mat-form-field>
    </mat-card-title>

    <mat-card-content class="driver_card__content">
      <mat-chip-list [multiple]="true" [selectable]="true">
        <div class="driver_card__chip_layout">
          <mat-chip
            (click)="
              modelFilter.clearAll();
              weeklyTripSheetDataSource.filter = '';
              searchbar.value = ''
            "
            class="driver_card__chip_layout__chip"
          >
            Clear
          </mat-chip>
        </div>

        <div
          class="driver_card__chip_layout"
          *ngFor="let filterGroup of modelFilter.filterGroups"
        >
          <mat-chip
            *ngFor="let filter of filterGroup.filters"
            (click)="getFilteredData(filter)"
            [selected]="filter.selected"
            class="driver_card__chip_layout__chip"
          >
            {{ filter.displayName }}
          </mat-chip>
        </div>
      </mat-chip-list>
    </mat-card-content>
  </mat-card>
  <h6 class="row no-gutters">Weekly Trip Sheets:</h6>
  <div class="row no-gutters driver_list">
    <app-trip-sheet-grid
      class="driver_list__grid"
      *ngIf="weeklyTripSheetDataSource.data.length; else noSheets"
      [dataSource]="weeklyTripSheetDataSource"
      [rowClickCallback]="rowClickCallback"
      [columnsToDisplay]="columnsToDisplay"
      [titleToDisplay]="titleToDisplay"
    >
    </app-trip-sheet-grid>
    <ng-template #noSheets>
      <div class="driver_list__no_data">
        No Weekly Trip Sheets (To Approve) found in this week. Please try some other week
      </div>
    </ng-template>
  </div>
  <mat-sidenav class="drawer" id="drawer" mode="over" position="end" #drawer>
    <app-verify-weekly-trip-sheet
      [tripSheet]="selectedWeeklyTripSheet"
      *ngIf="selectedWeeklyTripSheet"
      [isDeliveryManagerMode]="true"
      (onClose)="closeDrawer($event)"
    ></app-verify-weekly-trip-sheet>
  </mat-sidenav>
</mat-sidenav-container>

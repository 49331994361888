import { Serializable } from "../base";
import { Driver } from "../driver";
import { SaleOrder } from "../sale-order";
import { TripsStatusHistory } from "./status-history";
import { Location } from "../location";

export enum TripStatus {
  PENDING = 0,
  SCANNED,
  STARTED,
  RETURNINGBACK,
  PENDINGCASH,
  CASHSHORTAGE,
  COMPLETED,
}

export class Trips implements Serializable<Trips> {
  private check_in_image: string;
  private check_out_image: string;
  private check_out_value: number;
  private check_in_value: number;
  private corrected_check_in_value: number;
  private corrected_check_out_value: number;
  public createdDate: Date;
  public assignTime: string;
  public tripAssignedDate: Date;
  public createdTime: string;
  public formatEndTripTime: string;
  public endDate: Date = null;
  public startTime: string;
  public tripStartTime: string;
  public endTime: string = null;
  public kilometersCovered: number;
  public orders: Set<string>;
  public orderSeq: any = [];
  public isCompleted: boolean;
  public day: string;
  public driverTag: string;
  public id: number;
  public auto_assigned: boolean;
  private driver_position: Location[];
  private _driver: Driver;
  private _sequencedOrders: SaleOrder[] = [];
  public cash: TripCash;
  public subAreas: string[];
  public progress: TripsStatusHistory[];
  private can_assign_manual: boolean = true;
  private is_store_pickup_trip: boolean;
  public can_sequence: boolean;

  /**
   * Legacy: set of strings
   */
  private driver_user: number;
  public driverOrder: DriverOrder[] = [];
  public meta: TripMeta;
  public status: number;
  private prepaid: number = 10;
  private cod: number = 10;
  private is_sequence = 0;
  public tripStatus = TripStatus;

  get locations() {
    return this.driver_position;
  }

  get driverOrderLength() {
    return this.driverOrder.length;
  }

  get sequencedOrdersLength() {
    return this._sequencedOrders.length;
  }

  get tripActualAmount() {
    return this.cash ? this.cash.actualAmount : "";
  }

  get tripSubmittedAmount() {
    return this.cash ? this.cash.submitted_amount : "";
  }

  get isTripCompleted() {
    return this.status >= 3;
  }

  get canRemoveOrders() {
    return this.status <= 1;
  }

  get canStart() {
    return this.status == 1;
  }

  get isFreshTrip() {
    return this.status == 0 && this.orders.size === 0;
  }

  get isSequence() {
    return this.is_sequence;
  }

  get canSequence(): boolean {
    return this.can_sequence;
  }

  get state(): string {
    let status;
    switch (this.status) {
      case 0: {
        status = !this.driver ? "Saved Trips" : "Pending";
        break;
      }
      case 1: {
        status = "Scanned";
        break;
      }

      case 2: {
        status = "Started";
        break;
      }

      case 3: {
        status = "Returning Back";
        break;
      }

      case 4: {
        status = "Pending Cash Collection";
        break;
      }

      case 5: {
        status = "Cash Shortage";
        break;
      }

      case 6: {
        status = "Completed";
        break;
      }

      default: {
        status = "In Progress";
        break;
      }
    }

    return status;
  }

  // Trip progress percentage values
  get progressPercentage() {
    const data = {
      "Saved Trips": 0,
      Pending: 20,
      Scanned: 40,
      Started: 50,
      "Returning Back": 60,
      "Pending Cash Collection": 80,
      "Cash Shortage": 90,
      Completed: 100,
    };
    return data[this.state];
  }

  get isCashShort() {
    if (this.status === 5) {
      return true;
    }
    return false;
  }

  get isPendingCash() {
    if (this.status === 4) {
      return true;
    }
    return false;
  }

  get driver(): Driver {
    return this._driver;
  }

  set driver(value: Driver) {
    this._driver = value;
  }

  get sequencedOrders(): SaleOrder[] {
    return this._sequencedOrders;
  }

  set sequencedOrders(value: SaleOrder[]) {
    this._sequencedOrders = value.sort((o1: SaleOrder, o2: SaleOrder) => {
      if (o1.sequence_number > o2.sequence_number) {
        return 1;
      }

      if (o1.sequence_number < o2.sequence_number) {
        return -1;
      }

      return 0;
    });
  }

  get unScannedOrdersLength() {
    return this.sequencedOrders.filter((order) => !order.isScanDone).length;
  }

  get scannedOrdersLength() {
    if (!this.driverOrder) {
      return 0;
    }

    const scannedOrders = this.driverOrder
      .map((order) => order.saleOrder)
      .filter((saleOrder) => {
        if (saleOrder) {
          return saleOrder.isScanned;
        }
      });

    return scannedOrders.length;
  }

  get driverUserId() {
    return this.driver_user;
  }

  get ordersFlat() {
    return Array.from(this.orders).join(",");
  }

  get tripId() {
    return this.id;
  }

  get isTripStarted() {
    if (this.status === 2) {
      return true;
    }
    return false;
  }

  get prepaidAmount() {
    return this.prepaid;
  }

  get CODAmount() {
    if (!this.sequencedOrders) {
      return 0;
    }

    let orders = this.sequencedOrders.filter((order) => !order.isPaidOnline);
    return orders.reduce(
      (total, order) => (total += parseFloat(order.total.toString())),
      0
    );
  }

  /**
   * Returns trip ending date if available
   * else return trip creating date
   */
  get tripDate() {
    if (this.endDate) {
      return this.endDate;
    }
    if (this.tripAssignedDate) {
      return this.tripAssignedDate;
    }
    return this.createdDate;
  }

  /**
   * Returns trip ending time if available
   * else returns trip creating time
   */
  get tripTime() {
    if (this.endTime != null) {
      return this.endTime;
    }
    return this.startTime;
  }

  get tripAssignedTime() {
    return this.assignTime;
  }

  get tripCreatedTime() {
    return this.createdTime;
  }

  /**
   * For Ongoing trips ,
   * Trip assigned time is substracted from the Current time from the server
   */
  get tripTimeInMinutes() {
    let totalMinutes: any =
      new Date().getTime() - new Date(this.tripAssignedDate).getTime();
    return Math.round(totalMinutes / 1000 / 60);
  }

  /**
   * For Completed trips,
   * Trip assigned time is substracted from the trip end date
   */
  get completedTripTimeInMinutes() {
    let totalMinutes: any =
      new Date(this.endDate).getTime() -
      new Date(this.tripAssignedDate).getTime();
    return Math.round(totalMinutes / 1000 / 60);
  }

  get isSequenced() {
    if (this.is_sequence === 2) {
      return true;
    }
    return false;
  }
  /**
   * returns trip starting time
   */
  get tripStartedTime() {
    return this.tripStartTime;
  }

  get tripDriverName() {
    if (this.driver) {
      return this.driver.displayName;
    }
    if (this.driverOrder.length > 0 && this.driverOrder[0].saleOrder) {
      return this.driverOrder[0].saleOrder.driver_name;
    }
    return "";
  }

  get tripDriverPhoneNumber() {
    if (this.driver) {
      return this.driver.phone;
    }
    if (this.driverOrder.length > 0 && this.driverOrder[0].saleOrder) {
      return this.driverOrder[0].saleOrder.driver_number;
    }
    return "";
  }

  get checkOutUrl() {
    return this.check_out_image;
  }

  get checkInUrl() {
    return this.check_in_image;
  }

  get checkOutValue() {
    return this.check_out_value;
  }

  get checkInValue() {
    return this.check_in_value;
  }

  get correctedCheckOutValue() {
    return this.corrected_check_out_value;
  }

  get correctedCheckInValue() {
    return this.corrected_check_in_value;
  }

  updateCheckOutValue(value: number) {
    this.corrected_check_out_value = value;
  }

  updateCheckInValue(value: number) {
    this.corrected_check_in_value = value;
  }

  // total travelled kms
  get odoMeterKM() {
    return this.checkOutValue && this.checkOutUrl
      ? this.checkOutValue - this.checkInValue
      : 0;
  }

  get canAssignManual(): boolean {
    return this.can_assign_manual;
  }

  get canSubmitAdvanceCash(): boolean {
    if (this.driverUserId && this.status <= 1) {
      return true;
    }

    return false;
  }

  get isBroadCasting(): boolean {
    return !this.canAssignManual && !this.driverUserId;
  }

  /**
   * Getter Used to check the trip is a
   * Store pickup trip
   */
  get isStorePickupTrip(): boolean {
    return this.is_store_pickup_trip;
  }

  /**
   * get the orderid from the tripObject using map
   * convert that orderid object to string
   */
  get tripOrderString() {
    const orders = this.driverOrder.map((order) => order.incrementId);
    const orderId = orders.toString();
    return orderId;
  }
  constructor() { }

  deserialize(input) {
    if (input == undefined) {
      return this;
    }
    this.driver_user = input.driver_user;
    this.subAreas = input.sub_area;
    // getting date from the trip ending time
    this.createdDate = new Date(input.trip_created_time);
    this.createdTime = new Date(input.trip_created_time).toLocaleTimeString();

    this.startTime = new Date(input.trip_created_time)
      .toTimeString()
      .split(" ")[0];
    if (input.trip_started_time) {
      this.tripStartTime = new Date(input.trip_started_time)
        .toTimeString()
        .split(" ")[0];
    }
    this.day = new Date(input.trip_created_time).toLocaleDateString("en-US", {
      weekday: "long",
    });

    if (input.trip_assigned_time) {
      this.assignTime = new Date(input.trip_assigned_time).toLocaleTimeString();
      this.tripAssignedDate = new Date(input.trip_assigned_time);
    }

    if (input.trip_ending_time) {
      this.endDate = new Date(input.trip_ending_time);
      this.formatEndTripTime = new Date(
        input.trip_ending_time
      ).toLocaleTimeString();
      this.endTime = new Date(input.trip_ending_time)
        .toTimeString()
        .split(" ")[0];
      // getting trip`s day
      this.day = new Date(input.trip_ending_time).toLocaleDateString("en-US", {
        weekday: "long",
      });
    }
    if (input.driver_position && input.driver_position.length) {
      this.driver_position = input.driver_position.map((position) =>
        new Location().deserialize(position)
      );
    }

    // converting meters to kilometers, and round it to contain only one decimal
    this.kilometersCovered = Math.round((input.km_travelled / 1000) * 10) / 10;
    this.id = input.id;
    this.driverTag = input.tag;
    this.auto_assigned = input.auto_assigned;
    this.status = input.status;
    this.is_sequence = input.is_sequence;

    this.isCompleted = input.trip_completed;
    this.orders = new Set<string>();
    for (let order of input.driver_order) {
      this.orders.add(order.increment_id);
      this.orderSeq.push({
        id: order.increment_id,
        seq: order.sequence_number,
      });
      this.driverOrder.push(new DriverOrder().deserialize(order));
    }
    this.driverOrder.sort((a, b) => a.sequence - b.sequence);

    if (input.trip_meta instanceof Array && input.trip_meta.length > 0) {
      this.meta = new TripMeta().deserialize(input.trip_meta[0]);
    }

    if (input.cash) {
      this.cash = new TripCash().deserialize(input.cash);
    }

    this.is_sequence = input.is_sequence;
    this.check_in_image = input.check_in_image;
    this.check_out_image = input.check_out_image;
    this.check_in_value = input.check_in_value;
    this.check_out_value = input.check_out_value;
    this.corrected_check_in_value = input.corrected_check_in_value;
    this.corrected_check_out_value = input.corrected_check_out_value;
    this.can_sequence = input.can_sequence;
    this.is_store_pickup_trip = input.is_store_pickup_trip;

    this.progress = [];
    if (input.status_history instanceof Array) {
      input.status_history.forEach((history) => {
        this.progress.push(new TripsStatusHistory().deserialize(history));
      });
    }
    if (input.can_assign_manual != undefined && input.can_assign_manual != null) {
      this.can_assign_manual = input.can_assign_manual;
    }
    return this;
  }
}

export class OptimalTrip implements Serializable<OptimalTrip> {
  public km: number = 0;
  public time: number = 0;
  private trip_id; // used when we create opt trip from normal trip obj
  public orders = new Set<string>();

  deserialize(input) {
    this.km = input.km;
    this.time = input.time;
    input.orders.forEach((order) => this.orders.add(order));
    this.trip_id = input.trip_id;

    return this;
  }

  get tripId() {
    return this.trip_id;
  }

  get title() {
    return `Route (Time: ${this.time} Mins, ${this.orders.size} Orders)`;
  }

  get titleWithoutKm() {
    return `Route (Time: ${this.time} Mins, ${this.orders.size} Orders)`;
  }

  removeOrder(orderId) {
    this.orders.delete(orderId);
  }

  addOrder(orderId) {
    this.orders.add(orderId);
  }

  toggle(orderId) {
    if (this.orders.has(orderId)) {
      this.removeOrder(orderId);
    } else {
      this.addOrder(orderId);
    }
  }
}

export class DriverOrder implements Serializable<DriverOrder> {
  public status: string;

  private increment_id: string;
  private latitude: string;
  private longitude: string;
  private sequence_number: string;
  private sale_order: SaleOrder;
  public distance: any;
  public internalSequenceNumber: number;
  public id: number;
  private is_active: boolean;
  private driver_user: number;

  get lat() {
    return parseFloat(this.latitude);
  }

  get lng() {
    return parseFloat(this.longitude);
  }

  get incrementId() {
    return this.increment_id;
  }

  get sequence() {
    return parseInt(this.sequence_number);
  }

  get isComplete() {
    return this.status == "complete";
  }

  set setSequence(num: any) {
    this.sequence_number = num;
  }

  get saleOrder() {
    return this.sale_order;
  }

  set saleOrder(value: SaleOrder) {
    this.sale_order = value;
  }

  //return false when order is in hold state
  get isActiveOrder() {
    return this.is_active;
  }

  get driverUser() {
    return this.driver_user;
  }

  deserialize(input) {
    this.id = input.id;
    this.increment_id = input.increment_id;
    this.status = input.status;
    this.latitude = input.latitude;
    this.longitude = input.longitude;
    this.sequence_number = input.sequence_number;
    this.internalSequenceNumber = input.internalSequenceNumber;
    this.is_active = input.is_active;
    this.driver_user = input.driver_user;
    this.sale_order = new SaleOrder().deserialize(input.sale_order);
    return this;
  }
}

export class TripMeta implements Serializable<TripMeta> {
  public id: string;

  private on_time_orders: number;
  private delayed_orders: number;
  private cod_value: number;
  private prepaid_value: number;

  get onTimeOrders(): number {
    return this.on_time_orders;
  }

  get delayedOrders(): number {
    return this.delayed_orders;
  }

  get codValue(): number {
    return this.cod_value;
  }

  get prepaidValue(): number {
    return this.prepaid_value;
  }

  deserialize(input) {
    this.on_time_orders = input.on_time_orders;
    this.delayed_orders = input.delayed_orders;
    this.cod_value = input.cod_value;
    this.prepaid_value = input.prepaid_value;

    return this;
  }
}

export class TripCash implements Serializable<TripCash> {
  private id: number;
  private actual_amount = 0;
  private remaining_amount = 0;
  private trip: number;

  public reason: string;
  public submitted_amount = 0;
  public cashEntries: CashTransaction[] = [];
  public cashLines: TripCashLine[] = [];

  get cashId() {
    return this.id;
  }

  get actualAmount() {
    return this.actual_amount;
  }

  get remainingAmount() {
    return this.remaining_amount;
  }

  get submittedAmount() {
    return this.submitted_amount;
  }

  get shortageAmount() {
    return this.actualAmount - this.submittedAmount;
  }

  get tripId() {
    return this.trip;
  }

  // To get cash on delivery amount total
  get codAmount() {
    let amount = this.cashLines
      .filter((line) => line.txn_type == "od")
      .reduce((sum, line) => sum + line.amount, 0);
    return amount;
  }

  // To get driver change amount total
  get changeAmount() {
    let amount = this.cashLines
      .filter((line) => line.txn_type == "ch")
      .reduce((sum, line) => sum + line.amount, 0);
    return amount;
  }

  // To get customer Wallet amount total
  get walletAmount() {
    let amount = this.cashLines
      .filter((lines) => lines.txn_type == "wl")
      .reduce((sum, line) => sum + line.amount, 0);
    return amount;
  }

  // sum of cod,wallet and change amount
  get totalAmount() {
    return this.changeAmount + this.walletAmount + this.codAmount;
  }

  deserialize(input) {
    this.id = input.id;
    this.actual_amount = input.actual_amount;
    this.reason = input.reason;
    this.remaining_amount = input.remaining_amount;
    this.submitted_amount = input.submitted_amount;
    this.trip = input.trip;

    for (let cashTransaction of input.cash_entries) {
      this.cashEntries.push(new CashTransaction().deserialize(cashTransaction));
    }

    for (let tripCashLine of input.lines) {
      this.cashLines.push(new TripCashLine().deserialize(tripCashLine));
    }
    return this;
  }
}
export class CashTransaction implements Serializable<CashTransaction> {
  private cash_entry: number;
  private id: number;

  public createdAt: string;
  public amount: number;
  public cashTransaction: string;

  constructor() { }

  get totalCash() {
    return this.cash_entry;
  }

  get cashId() {
    return this.id;
  }

  deserialize(input) {
    this.id = input.id;
    this.createdAt = new Date(input.created_at).toLocaleString();
    this.amount = input.amount;
    this.cash_entry = input.cash_entry;
    this.cashTransaction = input.cashEntries;

    return this;
  }
}

export class TripCashLine implements Serializable<TripCashLine> {
  private id: number;
  private res_id: number;
  private cash_entry: number;

  public tripCashLine: string;
  public txn_type: string;
  public amount: number;
  public createdAt: string;

  get orderId() {
    return this.res_id;
  }
  get totalCash() {
    return this.cash_entry;
  }
  get cashTypeId() {
    return this.id;
  }

  get cashType() {
    switch (this.txn_type) {
      case "od":
        return "Cash On Delivery";

      case "wl":
        return "Customer Wallet Amount";

      case "ch":
        return "Driver Advance Cash";
    }
  }

  constructor() { }

  deserialize(input) {
    this.id = input.id;
    this.txn_type = input.txn_type;
    this.amount = input.amount;
    this.createdAt = new Date(input.created_at).toLocaleString();
    this.res_id = input.res_id;
    this.cash_entry = input.cash_entry;
    this.tripCashLine = input.cashLines;

    return this;
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BaseGetProvider, DataTransformationProtocol } from "@tendercuts/http";
import { CurrentLocation, Store } from "@tendercuts/models";

@Injectable()
export class StoreService
  extends BaseGetProvider<Store>
  implements DataTransformationProtocol {
  private selectedPlace: CurrentLocation;

  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http);

    // the same class is a delegate
    this.delegate = this;
  }

  preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get isAuthenticatedEndpoint(): boolean {
    return false;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof Store {
    return Store;
  }

  get endpoint(): string {
    return this.endpointService.storeEndpoint();
  }

  get location(): CurrentLocation {
    return this.selectedPlace;
  }

  set location(googlePlace: CurrentLocation) {
    this.selectedPlace = googlePlace;
  }
}

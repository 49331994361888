import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable } from "rxjs";

@Injectable()
export class Error404PageResolver implements Resolve<any> {
  constructor() {}

  resolve(route: ActivatedRouteSnapshot): Promise<unknown> {
    return new Promise((resolve, reject) => {
      const breadcrumbs: {
        url: string;
        label: string;
      }[] = [{ url: "/", label: "404" }];

      return resolve({
        // breadcrumbs: breadcrumbs
      });
    });
  }
}

<div class="wrapper">
  <b *ngIf="showIndex">{{ index }}</b>
  <span>{{ order.incrementId }}</span
  ><i
    *ngIf="order?.shippingAddress.isVerifiedAddress"
    class="fa fa-check green tick"
  ></i>
  <i
    *ngIf="isPriorityOrder(order) | async"
    class="fa fa-product-hunt fa-2x priority"
  ></i>

  <i
    *ngIf="order?.timeRemaining < 75 && order?.status !== 'complete'"
    class="fa fa-exclamation-circle priority"
  ></i>

  <ng-container *ngIf="showTime">
    <span [ngClass]="{ priority: order.timeRemaining < 75 }">
      ({{ order.timeRemaining }} Mins)
    </span>
  </ng-container>
</div>

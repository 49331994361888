import { Serializable } from "./base";
import { TripSheet } from "./trip-sheets";

export class GenerateTripSheetResponse implements Serializable<GenerateTripSheetResponse> {
  public status: boolean;
  public message: string;
  public result: TripSheet;

  constructor() {}

  deserialize(input) {
    this.status = input.status;
    this.message = input.message;
    if (input.result) {
      this.result = new TripSheet().deserialize(input.result);
    }

    return this;
  }
}

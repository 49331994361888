import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MatDrawer, MatSidenav } from "@angular/material/sidenav";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { ActionGetStores, ActionLoadAllStores, ActionLoadCities } from "@store/store";
import { OptimalTrip, StoreSegment, Trips } from "@tendercuts/models";
import { PubSubService } from "@tendercuts/providers";
import { SaleOrderDataSource } from "../../components/data-source";
import {
  MessagesMenuService,
  NotificationsMenuService,
  SideMenuService,
} from "../../core";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DashBoardComponent implements AfterViewInit {
  @ViewChild("drawerContainer") drawerContainer: any;
  @ViewChild("sideMenu") sideMenu: MatSidenav;
  @ViewChild("sideNotifications") sideNotifications: any;
  @ViewChild("rightDrawer", { static: true }) rightDrawer: MatDrawer;
  planTrip: {
    selectedTrip: OptimalTrip;
    dataSource: SaleOrderDataSource;
    segments: StoreSegment[];
    editMode: boolean;
    sequenceChangeTrip: Trips;
    isSequencedMode: boolean;
  };

  notifications: any[] = [];
  messages: any[] = [];
  constructor(
    private sideMenuService: SideMenuService,
    private notificationsMenuService: NotificationsMenuService,
    private messagesMenuService: MessagesMenuService,
    public store: Store<AppState>,
    public events: PubSubService,
  ) {
    notificationsMenuService.getNotifications().then((notifications: any) => {
      this.notifications = notifications;
    });
    messagesMenuService.getMessages().then((messages: any) => {
      this.messages = messages;
    });
    // plan trip drawer subscribers
    this.events.$sub("plan-trip:open").subscribe((data) => {
      this.planTrip = data;
      this.rightDrawer.open();
    });
    this.events.$sub("plan-trip:close", (data) => {
      this.rightDrawer.close();
    });
  }

  ngAfterViewInit(): void {
    this.store.dispatch(new ActionGetStores());
    this.store.dispatch(new ActionLoadAllStores());
    this.store.dispatch(new ActionLoadCities());
    this.sideMenuService.sidenav = this.sideMenu;
    this.sideMenuService.drawerContainer = this.drawerContainer;
    this.notificationsMenuService.sidenav = this.sideNotifications;
  }

  collapseSidebar(): void {
    this.sideMenu.toggle(false);
  }
}

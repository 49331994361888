<div
  id="action-buttons"
  *ngIf="user && (user.isDeliveryManager || user.isTechOps)"
>
  <button mat-raised-button color="primary" id="get-otp" (click)="getOtp()">
    Get Otp
  </button>

  <button
    mat-raised-button
    id="logout"
    color="primary"
    (click)="driverLogout()"
  >
    Logout
  </button>

  <button
    mat-raised-button
    id="booking-request"
    color="primary"
    (click)="generateBookingRequest()"
  >
    Generate Request
  </button>
  <!-- <button
    mat-raised-button
    id="convert-full-shift"
    color="primary"
    (click)="convertFullShift()"
  >
    Convert Full Shift
  </button> -->
  <button
    mat-raised-button
    id="change-driver-store"
    color="primary"
    (click)="changeDriverWorkLocation()"
  >
    Switch Store
  </button>
  <button
    mat-raised-button
    id="change-driver-profile"
    color="primary"
    (click)="showDriverTagDialog()"
  >
    Driver Profile Update
  </button>
  <button
    *ngIf="driver.isActive"
    mat-raised-button
    id="driver-suspend"
    color="primary"
    (click)="suspendDriver()"
  >
    Block Driver
  </button>

  <button
    *ngIf="driver.isActive"
    mat-raised-button
    id="edit"
    color="primary"
    (click)="editDriverDetails()"
  >
    Edit
  </button>

  <button
    mat-raised-button
    *ngIf="driver.isInActive"
    id="driver-unblock"
    color="primary"
    (click)="unBlockDriver()"
  >
    Unblock Driver
  </button>
</div>

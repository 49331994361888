export class CompleteOrderResponse {
    result: string;
    message: string;
    status: boolean;
    is_verified: boolean;


    deserialize(input) {
        this.result = input.result;
        this.message = input.message;
        this.status = input.status;
        this.is_verified = input.is_verified;

        return this;
    }
}

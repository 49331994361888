import { Component, Input, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatStepper } from "@angular/material/stepper";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import {
  OptimalTrip,
  SaleOrder,
  StoreSegment,
  Trips,
} from "@tendercuts/models";
import { PubSubService } from "@tendercuts/providers";
import { take } from "rxjs/operators";
import { PrintInvoiceDialogComponent } from "../../modules/print-module";
import { BasePage } from "../../utils";
import { SaleOrderDataSource } from "../data-source";

@Component({
  selector: "app-plan-trip-dialog",
  templateUrl: "./plan-trip-dialog.component.html",
  styleUrls: ["./plan-trip-dialog.component.scss"],
})
export class PlanTripDialogComponent extends BasePage {
  @Input() orders: SaleOrderDataSource | any;
  @Input() segments: StoreSegment[] = [];
  @Input() selectedTrip: OptimalTrip;
  @Input() editMode: boolean;
  @Input() sequenceChangeTrip: Trips = null;
  // open the change sequence drawer
  @Input() isSequencedMode: boolean = false;
  currentTrip: Trips;
  deltaOrders: SaleOrder[] = [];
  isDummyTripPlanned: boolean = false;

  @ViewChild("stepper") matStepper: MatStepper;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public router: Router,
    public events: PubSubService,
  ) {
    super(dialog, snackBar, store);
  }

  closeDialog(status: boolean = false): void {
    this.events.$pub("plan-trip:close", status);
    if (status) {
      // resets mat stepper once trip sequencing is done
      this.matStepper.reset();
      this.printKotOrders();
      // refreshing trips after updating sequence
      this.refreshOrdersAndTrips();
      this.dismissLoader();
    }

    // closes the plan-trip drawer
    if (this.editMode || status) {
      this.router.navigate(["/dashboard/live-trips"]);
    }
  }

  movetToNextStep(event: any): void {
    this.deltaOrders = event.deltaOrders;
    this.currentTrip = event.trip;
    if (!event.trip.driverUserId) {
      this.isDummyTripPlanned = true;
    } else {
      this.isDummyTripPlanned = false;
    }
    this.matStepper.next();
  }

  /**
   * Print only the orders newly added to the
   * trip
   */
  printKotOrders(): void {
    if (this.isDummyTripPlanned) {
      return;
    }
    this.store
      .select((state) => state.orderState.orderMap)
      .pipe(take(1))
      .subscribe((orderMap) => {
        const dialogData: {
          orders: SaleOrder[];
          printType: string;
          printKot: boolean;
          twoCustomerCopy: boolean;
          trip: Trips;
        } = {
          orders: this.deltaOrders,
          printType: "customer",
          printKot: true,
          twoCustomerCopy: true,
          trip: this.currentTrip,
        };
        this.dialog.open(PrintInvoiceDialogComponent, {
          width: "80mm",
          data: dialogData,
        });
      });
  }

  /**
   * close dialog for trip sequence dialog.
   * @param status
   */
  closeSequenceDialog(status: boolean = false): void {
    this.events.$pub("plan-trip:close", status);
    this.router.navigate(["/dashboard/live-trips"]);
  }
}

import { Injectable } from '@angular/core';

import { BasePostProvider, DataTransformationProtocol } from '@tendercuts/http';
import { Trips } from '@tendercuts/models';

import { HttpClient } from '@angular/common/http';
import { EndPoint } from '@endpoint';
import { LoginServices } from '@login';

@Injectable()
export class CreateOptimalTrip extends BasePostProvider<Trips>
  implements DataTransformationProtocol {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http);
    this.delegate = this;
  }

  preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get endpoint(): string {
    return this.endpointService.trips();
  }

  get model(): typeof Trips {
    return Trips;
  }
}

import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { WeeklyTripSheet } from "@tendercuts/models";

/**
 * Component to show Incentive Earning Information in a popup.
 */
@Component({
  selector: "app-incentive-comment",
  templateUrl: "./incentive-comment.component.html",
  styleUrls: ["./incentive-comment.component.scss"],
})
export class IncentiveCommentComponent {
  comments: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WeeklyTripSheet,
    public dialogRef: MatDialogRef<IncentiveCommentComponent>,
  ) {
    if (data) {
      this.comments = data.incentiveComments
        ? data.incentiveComments.split(".")
        : null;
    }
  }

  /**
   * Dismiss the popup
   */
  dismissPage(): void {
    this.dialogRef.close();
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BasePostProvider } from "@tendercuts/http";
import { GenerateTripSheetResponse } from "@tendercuts/models";

@Injectable({
  providedIn: "root",
})
export class TripSheetApproveService extends BasePostProvider<GenerateTripSheetResponse> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
  }

  get key(): string {
    return "id";
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof GenerateTripSheetResponse {
    return GenerateTripSheetResponse;
  }

  get isCachable(): boolean {
    return false;
  }

  get endpoint(): string {
    return this.endpointService.tripSheetApprove();
  }

  /**
   * params to pass trip sheet confirm.
   * @param status
   * @param id
   */
  getApproveWithOutMbgIncRequest(id: number, comment: string): {
    sheet_id: number;
    action: string;
    comment: string;
} {
    return {
      sheet_id: id,
      action: "reject",
      comment,
    };
  }

  /**
   * params to pass trip sheet reject.
   * @param status
   * @param id
   */
  getReject(id: number, comment: string): {
    sheet_id: number;
    action: string;
    comment: string;
} {
    return {
      sheet_id: id,
      action: "reject_back",
      comment,
    };
  }

  /**
   * params to pass trip sheet approve
   * @param status
   * @param id
   * @param requestMbg
   * @param requestIncentive
   */
  getApproveRequest(id: number, amount: string, comment: string): {
    sheet_id: number;
    action: string;
    amount: string;
    comment: string;
} {
    return {
      sheet_id: id,
      action: "approve",
      amount,
      comment,
    };
  }
}

import { Component, Inject, OnInit } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatRadioChange } from "@angular/material/radio";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import {
  CancelOrderRequest,
  CancelOrderResponse,
  SaleItem,
  SaleOrder,
  User,
} from "@tendercuts/models";
import { OrderCancelService } from "@tendercuts/providers";
import { map, skipWhile, take } from "rxjs/operators";
import { BasePage } from "../../utils";

@Component({
  selector: "app-order-cancel",
  templateUrl: "./order-cancel.component.html",
  styleUrls: ["./order-cancel.component.scss"],
})
export class OrderCancelComponent extends BasePage implements OnInit {
  cancelOrderRequest: CancelOrderRequest;
  saleOrder: SaleOrder;
  cancelOrderResponse: CancelOrderResponse = null;
  isCcManager: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SaleOrder,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public cancelService: OrderCancelService,
    public dialogRef: MatDialogRef<OrderCancelComponent>,
    public store: Store<AppState>,
  ) {
    super(dialog, snackBar, store);
    this.saleOrder = this.data;
    this.cancelOrderRequest = new CancelOrderRequest(
      this.saleOrder.incrementId,
    );
  }
  ngOnInit(): void {
    this.getCCManager();
    this.cancelOrderRequest.refundMode = "0";
  }

  /**
   * check the login state is call center manager
   */
  async getCCManager(): Promise<void> {
    this.isCcManager = await this.store
      .select((state) => state.userState.user)
      .pipe(
        skipWhile((user) => !user),
        take(1),
        map((user: User) => user.isCcManager),
      )
      .toPromise();
  }

  /**
   * 1. When selected pushes the item to cancel req obj.
   * 2. When unselected remvoes the item from cancel req obj
   * @param event
   * @param item
   */
  onItemSelect(event: MatCheckboxChange, item: SaleItem): void {
    if (event.checked) {
      this.cancelOrderRequest.items.push(item.productId);

      return;
    }
    const index: number = this.cancelOrderRequest.items.findIndex(
      (x) => x === item.productId,
    );
    this.cancelOrderRequest.items.splice(index, 1);
  }

  /**
   * Cancels the order and shows the response.
   */
  cancelOrder(): void {
    this.presentLoader();
    this.cancelService.getData(this.cancelOrderRequest.serialize()).subscribe(
      (data) => {
        this.dismissLoader();
        this.cancelOrderResponse = data[0];
      },
      (err) => this.somethingWentWrong(),
    );
  }

  /**
   * on selected the radio button value
   * update the value in cancel request
   * @param event
   */
  onSelectionChange(event: MatRadioChange): void {
    this.cancelOrderRequest.refundMode = event.value;
  }

  closePopup(): void {
    this.refreshStores();
    this.dialogRef.close(true);
  }

  /**
   * Show the refund mode option if return true
   */
  get canAllowForRefund(): boolean {
    return this.saleOrder.paymentModeSimple == "Prepaid";
  }
}

import {
  AfterViewInit,
  Component,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { SaleOrder } from "@tendercuts/models";
import { HistoricOrdersService } from "@tendercuts/providers";
import { SaleOrderServerDataSource } from "../../components";
import { OrderDetailComponent } from "../../components/order-detail";
import { BasePage } from "../../utils";

@Component({
  selector: "app-historic-sales",
  templateUrl: "./historic-sales.component.html",
  styleUrls: ["./historic-sales.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HistoricSalesComponent extends BasePage implements AfterViewInit {
  orders: SaleOrderServerDataSource<SaleOrder> = null;

  columnsToDisplay: string[] = [
    "incrementId",
    "firstname",
    "slotDescription",
    "shippingAddress",
    "payment_method",
    "total",
    "status",
    "driver",
  ];

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public historicService: HistoricOrdersService,
  ) {
    super(dialog, snackBar, store);
    this.orders = new SaleOrderServerDataSource<SaleOrder>(historicService);
  }

  loadPage(pageIndex: number, orderId: string = null): void {
    this.selectedStore.subscribe((store) => {
      if (!store) {
        return;
      }
      this.presentLoader();
      this.orders.load(pageIndex, orderId, store.storeId, () => {
        this.dismissLoader();
      });
    });
  }

  ngAfterViewInit(): void {
    this.loadPage(1);
  }

  onPageChange(index: any): void {
    this.loadPage(index + 1);
  }

  filterOrders(search: any): void {
    this.loadPage(1, search.value);
  }

  onOrderSelected(order: SaleOrder): void {
    this.dialog.open(OrderDetailComponent, { data: order });
  }
}

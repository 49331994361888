import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTabGroup } from "@angular/material/tabs";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import {
  BarcodeSpec,
  Butcher,
  DeliverySlot,
  DriverProfile,
  SaleItem,
  SaleOrder,
  User,
} from "@tendercuts/models";
import {
  DriverProfileSearchService,
  FetchBarcodeService,
  FetchOrdersDetailsService,
  LiveTrackButcherService,
} from "@tendercuts/providers";
import { map, skipWhile, take } from "rxjs/operators";
import { BarcodeParser } from "tendercuts/barcode";
import { SalesFlatOrderItemBarcode } from "tendercuts/models/barcode-display";
import { SaleOrderStatusHistory } from "tendercuts/models/sale-order/status-history";
import { BasePage } from "../../utils/pages/base/base.component";
import { BarcodeSpecDatasource } from "../data-source";

@Component({
  selector: "app-order-display",
  templateUrl: "./order-display.component.html",
  styleUrls: ["./order-display.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class OrderDisplayComponent extends BasePage implements OnInit {
  columnsToDisplay: string[] = [
    "productId",
    "quantity",
    "sku",
    "base-price",
    "discount-price",
    "row-total",
    "display-weight",
    "tax",
  ];

  /**
   * handle for next and prev buttons
   */
  orderIndex: number = 0;
  barcodeSpecDatasource: BarcodeSpecDatasource = new BarcodeSpecDatasource(
    null,
    []
  );

  @Input() order: SaleOrder;
  splitOrder: SaleOrder;

  // OrderSource from the customer orders
  @Input() orderSource: SaleOrder[];

  isTechOps: boolean = false;
  isCrmPage: boolean = false;
  user: User;
  slotMap: { [id: number]: DeliverySlot } = {};
  isGtgOrder: any = false;

  // parsed the barcode and to store the values barcodeSpec.
  barcodeSpec: BarcodeSpec[] = [];
  sortedOrderProgress: SaleOrderStatusHistory[] = [];
  orderStatusHistory: SaleOrderStatusHistory[] = [];
  orderStatusHistoryIndex: number[] = [];
  userInteractionIndex: number[] = [];
  userInteractionsHistory: SaleOrderStatusHistory[] = [];
  @Input() isDialogOpen: boolean;
  @Input() isTripDetailsMode: boolean = false;
  @Input() isOrderHistoryMode: boolean = false;
  // item select to extract it's child
  selectedItem: SaleItem | null;

  liveTrackButchers: Butcher[] = [];
  liveTrackDriver: DriverProfile;
  @ViewChild("tabGroup") tabGroup: MatTabGroup;
  @Output()
  refreshParent: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public router: Router,
    public fetchBarcodeService: FetchBarcodeService,
    public orderService: FetchOrdersDetailsService,
    public liveTrackButcherService: LiveTrackButcherService,
    public liveTrackDriverService: DriverProfileSearchService
  ) {
    super(dialog, snackBar, store);
  }
  ngOnInit(): void {
    this.getTechOps();
    if (this.order.splittedFrom || this.order.splittedTo) {
      this.getSplitOrderData();
    }
    this.loadUser();
    this.checkGtgOrder();
    if (this.order && this.order.progress.length) {
      this.sortedOrderProgress = this.order.progress.sort((a, b) => {
        if (a.created_at > b.created_at) {
          return -1;
        }

        return 1;
      });
      this.sortedOrderProgress.forEach((status) => {
        if (status.isVisibleOnFront == 1) {
          this.userInteractionsHistory.push(status);
        } else {
          this.orderStatusHistory.push(status);
        }
      });
      this.orderStatusHistoryIndex = Array.from(
        Array(this.orderStatusHistory.length).keys()
      ).reverse();
      this.orderStatusHistoryIndex = this.orderStatusHistoryIndex.map(
        (index) => index + 1
      );
      this.userInteractionIndex = Array.from(
        Array(this.userInteractionsHistory.length).keys()
      ).reverse();
      this.userInteractionIndex = this.userInteractionIndex.map(
        (index) => index + 1
      );
    }
    if (this.orderSource) {
      this.isCrmPage = true;
    }
  }

  async getTechOps(): Promise<void> {
    this.isTechOps = await this.store
      // TODO
      // eslint-disable-next-line @typescript-eslint/no-shadow
      .select((state) => state.userState.user)
      .pipe(
        skipWhile((user) => !user),
        take(1),
        map((user: User) => user.isTechOps)
      )
      .toPromise();
  }

  /**
   * By passing the entity getting the sale order data
   * to display the split order
   */
  getSplitOrderData(): void {
    const params: {
      order_id: string;
    } = this.orderService.getParams(
      this.order.splittedFrom || this.order.splittedTo
    );
    this.presentLoader();
    this.orderService.getData(params).subscribe(
      (orders: SaleOrder[]) => {
        this.splitOrder = orders[0];
        this.dismissLoader();
      },
      (err) => this.somethingWentWrong()
    );
  }

  async loadUser(): Promise<void> {
    this.user = await this.store
      // TODO
      // eslint-disable-next-line @typescript-eslint/no-shadow
      .select((state) => state.userState.user)
      .pipe(
        skipWhile((user) => !user),
        take(1),
        map((user: User) => user)
      )
      .toPromise();
  }

  get totalItems(): number {
    return this.order.items.filter((item: SaleItem) => item.isSimple).length;
  }

  /**
   * show the selected combo items child's
   * @param item
   */
  selectItem(item: SaleItem): void {
    if (item.isSimple || item.isSubscription) {
      return;
    }

    this.selectedItem = this.selectedItem === item ? null : item;
  }

  copy(item: string): void {
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", item);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
  }

  handleOrderActions(): void {
    this.refreshParent.emit(1);
  }

  get highLight(): boolean {
    return this.order.isOutForDelivery || this.order.isProcessing;
  }

  redirectToCrm(): void {
    this.dialog.closeAll();
    this.router.navigate(["dashboard/crm/" + this.order.customerId]);
  }

  // Function to redirect to rider profile details
  redirectToRiderCrm(): void {
    this.dialog.closeAll();
    this.router.navigate(["dashboard/driver-crm/" + this.order.driver_number]);
  }

  // Function to load order detail
  redirectToOrderDetail(orderId: string): void {
    this.dialog.closeAll();
    this.router.navigate(["dashboard/order-detail/" + orderId]);
  }

  onTabChanged(index: number): void {
    this.tabGroup.selectedIndex = index;

    this.barcodeSpec = [];
    const params: {
      entity_id: string;
    } = this.fetchBarcodeService.getParams(this.order.orderId);
    if (index == 1) {
      /**
       * get the barcode from service and parse the barode to split
       */
      this.fetchBarcodeService
        .getData(params)
        .subscribe((data: SalesFlatOrderItemBarcode[]) => {
          data.forEach((barcodeObj) => {
            this.barcodeSpec.push(
              BarcodeParser.parse(barcodeObj.barcode.barcode)
            );
          });
          this.barcodeSpecDatasource.data = this.barcodeSpec;
        });
    }
    if (index == 2) {
      this.fetchLiveTrackDetails();
    }
  }

  /**
   * Move to previous order
   * @param order
   */
  movePreviousOrder(): void {
    this.orderIndex--;
    this.order = this.orderSource[this.orderIndex];
  }

  /**
   * Move to next order
   */
  moveNextOrder(): void {
    this.orderIndex++;
    this.order = this.orderSource[this.orderIndex];
  }

  /**
   * if order length is zero hiding prev button
   */
  canShowPrevButton(order: SaleOrder): boolean {
    this.orderIndex = this.orderSource.indexOf(order);

    return this.orderIndex != 0;
  }

  fetchLiveTrackDetails(): void {
    this.liveTrackButchers = [];
    this.liveTrackDriver = null;
    this.liveTrackButcherService
      .getData(
        this.liveTrackButcherService.getParams(
          this.order.storeId,
          this.order.incrementId
        )
      )
      .subscribe((data: Butcher[]) => {
        this.liveTrackButchers = data;
      });
    if (this.order.driverNumber) {
      this.loadProfileDetails();
    }
  }

  /** Fn to load driver live track status
   * @param mobile_number is used to fetch details
   */
  loadProfileDetails(): void {
    const params: {
      mobile_number: string;
    } = this.liveTrackDriverService.getParams(this.order.driverNumber);
    this.liveTrackDriverService
      .getData(params)
      .subscribe((response: DriverProfile[]) => {
        if (response.length > 0) {
          this.liveTrackDriver = response[0];
        }
      });
  }

  /**
   * hiding next button
   */
  get canShowNextButton(): boolean {
    return this.orderIndex != this.orderSource.length - 1;
  }

  async checkGtgOrder() {
    this.storeState
      .pipe(
        skipWhile((storeState) => storeState.loading),
        take(1)
      )
      .subscribe((state) => {
        this.isGtgOrder = state.allStores.filter(
          (appStore) => appStore.storeId == this.order.storeId
        )?.[0]?.isGtg;
      });
  }
}

import { Serializable } from "../base";

export interface InventoryFilterOptions {
  parent?: boolean;
  child?: boolean;
  today?: boolean;
  convert?: boolean;
}

/**
 * To update today inventory the type should be 0
 * To update tomorrow inventory the type should be 1
 */
export enum InventoryType {
  TODAY = 0,
  TOMORROW = 1
}

export class Inventory implements Serializable<Inventory> {
  private product: number;
  private scheduledqty: number;
  private qty: number;

  // Parent QTY
  private parent_qty: number;
  private parent_exp: number;
  private parent_fc: number;

  private gpu: number;
  private is_top: number;
  private is_unf: number;
  private is_clo: number;

  get isClosedForToday() {
    return this.is_clo == 1;
  }

  constructor() {
    this.scheduledqty = 0;
    this.qty = 0;

    this.parent_qty = 0;
    this.parent_exp = 0;
    this.parent_fc = 0;

    /**
     * For backwards compatibility we are setting some
     * smart defaults
     */
    this.gpu = 1;

    // by default we mark the product as non-unified inventory,
    // unless specified explicitly.
    this.is_unf = 0;

    // by default not closed for today
    this.is_clo = 0;
    // by default always open tomo
    this.is_top = 1;

  }

  get todayQty(): number {
    return this.qty;
  }

  get tomorrowQty(): number {
    return this.scheduledqty;
  }

  get isUnifiedInventory() {
    return this.is_unf == 1;
  }

  get isOpenForTomorrow() {
    return this.is_top == 1;
  }

  get parent_sch_qty() {
    if (!this.isUnifiedInventory) {
      return 0;
    }

    return this.parent_qty - this.parent_exp + this.parent_fc;
  }

  deserialize(input) {
    // version 1 attrs
    this.product = input.product;
    this.scheduledqty = +input.scheduledqty;
    this.qty = input.qty;

    // version 2 attrs - We are using gpu as an attr to
    // mark version 2
    if (input.gpu) {
      this.gpu = input.gpu;
      this.parent_qty = +input.parent_qty;
      this.parent_exp = +input.parent_exp;
      this.is_unf = input.is_unf;
      this.is_top = input.is_top;
      this.is_clo = input.is_clo;
    }

    if (input.parent_fc) {
      this.parent_fc = input.parent_fc;
    }

    return this;
  }

 /**
  * Convert kg to units
  * @param qty - number(kg) to be converted
  * @param methodType - type of conversion (floor or ceil)
  * Default conversion type floor
  */
  convertToUnits(qty, methodType = 'floor') {
    let packs = qty / this.gpu;
    return methodType == 'floor' ?
      Math.floor(+packs.toFixed(2)) : Math.ceil(+packs.toFixed(2));
  }

  /**
   * convert units to kg
   */
  convertToKg(qty) {
    return qty * this.gpu;
  }

  // TODO: Needs to be removed
  get productId() {
    return this.product;
  }

  /**
   *
   * @param today - today or tomorrow inventory
   * @param convert - convert to packs flag
   * @param parent - add parent inv
   * @param child - add child inv
   */
  avlQty(options: InventoryFilterOptions) {
    let qty = 0;

    let parent = options.parent !== undefined ? options.parent : true;
    if (parent) {
      qty += options.today ? this.parent_qty : this.parent_sch_qty;
    }

    let child = options.child !== undefined ? options.child : true;
    if (child) {
      qty += options.today ? this.qty : this.scheduledqty;
    }

    return options.convert ? this.convertToUnits(qty) : qty;
  }

  /**
   * Inventory avl to sell today - both parent + child
   */
  get today() {
    if (this.isClosedForToday) {
      return 0;
    }

    return this.convertToUnits(this.qty + this.parent_qty);
  }

  /**
   * Inventory avl to sell tomorrow - both parent + child
   */
  get future() {
    if (!this.isOpenForTomorrow) {
      return 0;
    }

    return this.convertToUnits(this.scheduledqty + this.parent_sch_qty);
  }

  // total will always be the greater of the two
  get total() {
    return this.today > this.future ? this.today : this.future;
  }
}

import { Serializable } from "./base";

export class TripPayment implements Serializable<TripPayment> {
  private created_at: Date;
  private payment_amount: number;
  private reference: string;
  public paymentDateTime;
  constructor() {}

  deserialize(input) {
    this.created_at = input.created_at;
    this.payment_amount = input.payment_amount;
    this.reference = input.reference;
    this.createdAt();
    
    return this;
  }

  /**
   * To display date and time of payment
   */
  createdAt() {
    this.paymentDateTime = new Date(this.created_at)
      .toString()
      .split(" ")
      .slice(0, 5)
      .join(" "); 
  }

  get amount() {
    return this.payment_amount;
  }

  get referenceNo() {
    return this.reference;
  }
}

import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { map, take, tap } from "rxjs/operators";

import { Store } from "@ngrx/store";
import { AppState } from "@store/state";

/**
 * If the user is logged in already, we push him to dashboard
 */
@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private route: Router, private store: Store<AppState>) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const sourceUrl: string = state.url;

    return this.store
      .select((appState) => appState)
      .pipe(
        take(1),
        map(
          (appState: AppState) =>
          appState.userState != undefined && appState.userState.user != undefined,
        ),
        tap((userExists) => {
          if (userExists == true) {
            return;
          }

          this.route.navigate(["/login"], {
            queryParams: { redirect: sourceUrl },
          });
        }),
      );
  }
}

<div class="view-trip-map">
  <div class="title">View Trip</div>
  <div>
    <app-order-id-sequence
      id="order"
      [trip]="selectedTrip"
    ></app-order-id-sequence>
  </div>

  <div class="map-side">
    <agm-map
      #map
      *ngIf="selectedTrip"
      [zoom]="zoom"
      [zoomControlOptions]="{ position: 3 }"
      [latitude]="(selectedStore | async)?.location?.lat"
      [longitude]="(selectedStore | async)?.location?.lng"
      [disableDefaultUI]="false"
      [zoomControl]="true"
      (idle)="tagLabels()"
    >
      <app-store-marker
        *ngIf="selectedStore | async"
        [showBoundary]="false"
        [storeObj]="selectedStore | async"
      ></app-store-marker>

      <div>
        <app-map-trip [mapRef]="mapRawRef" [trip]="selectedTrip"></app-map-trip>
      </div>
    </agm-map>
  </div>
</div>

import { Injectable } from '@angular/core';
import { EndPoint } from '@endpoint';
import { LoginServices } from '@login';
import { of, Observable } from 'rxjs';

import { WalletTransactionService } from './wallet-transaction';

import { HttpClient } from '@angular/common/http';
import { WalletTransaction } from '@tendercuts/models';

@Injectable()
export class MockWalletTransactionService extends WalletTransactionService {

    constructor(
        public http: HttpClient,
        public loginService: LoginServices,
        public endpointService: EndPoint,
    ) {
        super(http, loginService, endpointService);
    }

  getData(): Observable<WalletTransaction[]> {
      return of([new WalletTransaction().deserialize({
        amount: '100',
        created_at: 'Jun 13, 2017',
      })]);
  }

}

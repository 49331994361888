<div class="breadcrumb">
  <div *ngFor="let breadcrumb of breadcrumbs; let i = index">
    <span class="breadcrumb__span">
      <span class="breadcrumb__span__arrow" *ngIf="i != 0"> / </span
      ><a
        class="breadcrumb__span__arrow__label"
        [routerLink]="[breadcrumb.url]"
        routerLinkActive="router-link-active"
        >{{ breadcrumb.label }}</a
      >
    </span>
  </div>
</div>

// TODO
/* eslint-disable */
import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { Directive, ElementRef, Inject, Input } from "@angular/core";

@Directive({
  selector: "[responsiveBreakpoint]",
})
export class ResponsiveBreakpointDirective {
  @Input("responsiveBreakpoint") screen: string;

  constructor(public element: ElementRef) {}
}

<!-- <mat-progress-bar *ngIf="loading" mode="indeterminate">
</mat-progress-bar> -->

<mat-form-field class="example-form-field">
  <input matInput type="text" placeholder="Enter cus name" [(ngModel)]="name" />
  <button
    mat-button
    *ngIf="name"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="name = ''"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
<mat-form-field class="example-form-field">
  <input
    matInput
    type="text"
    placeholder="Enter cus mail"
    [(ngModel)]="cusDetails.mail"
  />
  <button
    mat-button
    *ngIf="cusDetails.mail"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="cusDetails.mail = ''"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
<!-- <input type="text" placeholder="Enter cus name" matInput [(ngModel)]="name">
<input type="text" placeholder="Enter cus mail" matInput [(ngModel)]="cus_details.mail"> -->

<!-- <p *ngIf="!loading">
    <button mat-raised-button color="primary" (click)="createCustomer()">
        <i class="fa fa-user"></i>
        Create Customer
    </button>
</p> -->

import { Serializable } from '../base';


export enum PaymentModeType {
    COD,
    PAYLATER,
    NB,
    CARD,
    WALLET,
    UPI
}

export enum PaymentGateWayType {
    PAYTM = "PAYTM",
    MOBIKWIK = "MOBIKWIK",
    PHONEPE = "PHONEPE",
    SIMPL = "SIMPL",
}

export class PaymentMode implements Serializable<PaymentMode> {
    private gateway_code: string;
    private gateway_code_level_1: string;
    private order_id: string;
    private app_bundle: string;
    // flag to indicate if the mode should be active
    // for the customer
    private is_active: boolean;
    
    private payment_id : string;
    // UI specific attributes:
    // since the mode is spread across field like gateway_code/method etc
    // we add another field (displayType)
    public displayId: number;
    public icon: string;
    public thumb: string;
    public displayType: PaymentModeType;
    public title: string;
    public method: string;
    public subtitle: string;
    public priority: number;
    public offers: string;

    // Card specific
    public pin: string;
    public expiry_month: string;
    public expiry_year: string;
    public brand: string;
    public persist: boolean = true;
    public nameOnCard: string;
    public token: string;

    // client side flag to identify new cards
    public isNewCard: boolean = false;


    get gatewayCode() { return this.gateway_code }
    get gatewayCodeLevel1() { return this.gateway_code_level_1 }
    get isActive() {return this.is_active}

    get expiryMonth() { return this.expiry_month }
    set expiryMonth(month) { this.expiry_month = month }

    get expiryYear() { return this.expiry_year }
    set expiryYear(year) { this.expiry_year = year }

    get orderId() { return this.order_id }
    set orderId(orderId: string) { this.order_id = orderId }

    constructor() { }

    deserialize(input) {
        this.title = input.title;
        this.method = input.method;
        this.subtitle = input.subtitle;
        this.gateway_code = input.gateway_code;
        this.gateway_code_level_1 = input.gateway_code_level_1;
        this.priority = input.priority;
        this.offers = input.offers;

        this.pin = input.pin
        this.expiry_month = input.expiry_month
        this.expiry_year = input.expiry_year
        this.brand = input.brand;
        this.persist = input.persist;

        this.displayId = input.displayId;
        this.icon = input.icon;
        this.thumb = input.thumb;
        this.displayType = input.displayType;
        this.is_active = input.is_active;
        this.nameOnCard = input.name_on_card;
        this.token = input.token;
        this.isNewCard = input.isNewCard;
        this.payment_id = input.payment_id;
        this.app_bundle = input.app_bundle;

        return this;
    }

    get paymentId(): string {
        return this.payment_id;
    }

    get appBundle(): string {
        return this.app_bundle;
    }

    get isValid(): boolean {
        if (this.gateway_code == "CARD") {
            // CVV should be 3/4
            if (this.pin && (this.pin.length == 3 || this.pin.length == 4))
                return true

            return false
        }

        // true for all other mode
        return true;
    }

    get isCod(): boolean {
        return this.method == "cashondelivery";
    }

    get isNb(): boolean {
        return this.gateway_code == "NB";
    }

    get isOnlineMode(): boolean {
        return this.gateway_code == "NB" || this.gateway_code == "CARD"
    }

    // Flag to check if the mode is dummy mode
    // for enabling the payment category.
    get isEnablementMode() {
      return this.gatewayCodeLevel1 === "NEW"
    }

}

export class PaymentGatewayResponse {
    constructor(public gatewayCode: string) { }
    public status: Boolean;
    public vendorId: string;
}

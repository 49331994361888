import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { DeliverySlot, SaleOrder } from "@tendercuts/models";
import { BaseGridComponent } from "../base-grid/base-grid.component";
import { SaleOrderDataSource } from "../data-source";
import {
  ColumnAction,
  CurrencyPipeAction,
  DateFormatAction,
  MatChipAction,
  TimerPipeAction,
} from "../table-actions";

@Component({
  selector: "app-sale-order-grid",
  templateUrl: "../base-grid/base-grid.component.html",
  styleUrls: ["../base-grid/base-grid.component.scss"],
})
export class SaleOrderGridComponent extends BaseGridComponent {
  @Input() columnsToDisplay: string[] = [
    "select",
    "incrementId",
    "firstname",
    "shippingDescription",
    "payment_method",
    "driverDetails",
  ];

  @Input() titleToDisplay: string[] = [
    "Select",
    "Order Id",
    "Cust Name",
    "Type",
    "Payment Name",
    "Driver",
  ];

  // check box should disable based on these column values
  @Input() checkBoxDisableColumns: string[] = ["isOutForDelivery"];

  @Input() actions: ColumnAction[] = [
    new TimerPipeAction(
      "Time Left",
      "orderTimeRemaining",
      this.getCurrencyPipeFormat.bind(this),
    ),
    new CurrencyPipeAction(
      "Total",
      "total",
      this.getCurrencyPipeFormat.bind(this),
    ),
    new DateFormatAction(
      "Sale Date",
      "saleDate",
      this.getDateFormat.bind(this),
    ),
    new MatChipAction(
      "Status",
      "subStatus",
      this.getStatusClass.bind(this),
    ),
  ];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() enableSelection: boolean = true;
  @Input() rowColoring: boolean = true;
  @Input() orders: SaleOrderDataSource;
  slotMap: { [id: number]: DeliverySlot };

  // TODO
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() private onPageChange: EventEmitter<any> = new EventEmitter();

  /**
   * get the format date
   * @param event
   * @param order
   */
  getDateFormat(event: any, order: SaleOrder): string {
    return "mediumDate";
  }

  /**
   * get the currency pipe
   * @param event
   * @param order
   */
  getCurrencyPipeFormat(event: any, order: SaleOrder): string {
    return '"INR":symbol:"1.0-0"';
  }

  /**
   * returns class names based on the order status
   * @param SaleOrder
   * @return className
   */
  getStatusClass(event: any, order: SaleOrder): string {
    const status: string = order.status;
    switch (status) {
      case "pending":
        return "bg-red";
      case "out_delivery":
        return "bg-green";
      case "processing":
        return "bg-yellow";
      case "hold":
        return "bg-orange";
      default:
        return "";
    }
  }

  loadPage(page: PageEvent): void {
    this.onPageChange.emit(page.pageIndex);
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-todays-trip-list',
  templateUrl: './todays-trip-list.component.html',
  styleUrls: ['./todays-trip-list.component.scss']
})
export class TodaysTripListComponent {
  @Input() todaysTrip: {
    tripId: number,
    time: string,
    totalAmount: number,
    amount: number,
  };
  columnsToDisplay: string[] = [
    "trip-id",
    "created-at",
    "total-amount",
    "actual-amount",
  ];
}

import { DefaultSerializationMixin } from '../base';

export class QuoteRewardPoints extends DefaultSerializationMixin {

    private spend_min_points: number;
    private spend_max_points: number = 0;
    private points_spend: number;

    constructor() {
        super();
        this.points_spend = 0;
    }

    get pointsSpend() { return this.points_spend }
    set pointsSpend(points) { this.points_spend = points }
    get maxPoints() { return this.spend_max_points }
    get minPoints() { return this.spend_min_points }

    serialize() {
        return {
            "points_spend": this.points_spend,
        };
    }

    eavDeserialize(input) {
        return super.eavDeserialize(input)
    }

}

<ng-container class="tag">
    <div class="tag__close-icon">
        <i class="fa fa-window-close-o " (click)="dialogClose()"></i>
    </div>
    <mat-card>
        <p>
            Current Name : <span class="tag__current-name"> {{ trip.driverTag }}</span>
        </p>
        <form class="alternate_name">
            <mat-form-field>
                <input autofocus class="alternate_name__input" placeholder="Enter Alternate Name" matInput
                    autocomplete="off" [formControl]="alternateNameControl" />

            </mat-form-field>
            <div>
                <button class="tag__reset-btn" mat-raised-button color="primary" (click)="resetData()">
                    Reset
                </button>

                <button class="tag__submit-btn" color="primary" mat-raised-button (click)="validateName()">
                    Submit
                </button>
            </div>
        </form>
    </mat-card>
</ng-container>
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BasePutProvider } from "@tendercuts/http";
import { GenericServerResponse, Trips, TripSheet } from "@tendercuts/models";

@Injectable()
export class TripCashService extends BasePutProvider<GenericServerResponse> {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http);
  }

  get key(): string {
    return "cash_id";
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get endpoint(): string {
    return this.endpointService.tripCashEndPoint;
  }

  get model(): typeof GenericServerResponse {
    return GenericServerResponse;
  }

  getParams(
    trip: Trips,
    submittedAmt: any,
    reason: any = null,
  ): {
    cash_id: number;
    trip: number;
    submitted_amount: any;
    check_in_value: number;
    check_out_value: number;
    reason: any;
  } {
    return {
      cash_id: trip.cash.cashId,
      trip: trip.tripId,
      submitted_amount: submittedAmt,
      check_in_value: trip.correctedCheckInValue,
      check_out_value: trip.correctedCheckOutValue,
      reason,
    };
  }
}

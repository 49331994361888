<div id="print-container">
  <div *ngIf="data.trip?.driverTag">
    <div class="pagebreak">
      <app-trip-detail-invoice [trip]="data.trip"></app-trip-detail-invoice>
    </div>
  </div>
  <div [ngSwitch]="data.printType">
    <div *ngSwitchCase="'butcher'">
      <ng-container *ngFor="let order of data.orders; last as last">
        <div class="pagebreak">
          <app-invoice
            [storeAddress]="storeAddress(order)"
            [butcherMode]="true"
            [setOrder]="order"
          ></app-invoice>
        </div>
      </ng-container>
    </div>

    <div *ngSwitchCase="'customer'">
      <ng-container
        *ngFor="let order of data.orders; last as last; let i = index"
      >
        <div class="pagebreak">
          <app-invoice
            *ngIf="!order.isPosOrder; else printPosBill"
            [storeAddress]="storeAddress(order)"
            [setOrder]="order"
            [index]="i"
          ></app-invoice>
          <ng-template #printPosBill>
            <app-pos-invoice [saleOrder]="order" [isDetailMode]="true">
            </app-pos-invoice>
          </ng-template>
        </div>
      </ng-container>
    </div>

    <div *ngSwitchCase="'kot'">
      <app-print-kot-invoice
        [data]="data.orders"
        [driverMode]="data.driverMode"
        [tripId]="data.tripId"
        [driverDisplayName]="data.driverDisplayName"
      ></app-print-kot-invoice>
    </div>

    <div *ngSwitchDefault>
      <ng-container *ngFor="let order of data.orders; last as last">
        <div class="pagebreak">
          <app-invoice
            [storeAddress]="storeAddress(order)"
            [butcherMode]="true"
            [setOrder]="order"
          ></app-invoice>
        </div>
        <div [ngClass]="{ pagebreak: !last }">
          <app-invoice
            [storeAddress]="storeAddress(order)"
            [setOrder]="order"
          ></app-invoice>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="pagebreak" *ngIf="data.printKot">
    <app-print-kot-invoice
      [data]="data.orders"
      [driverMode]="data.driverMode"
      [tripId]="data.tripId"
      [driverDisplayName]="data.driverDisplayName"
    ></app-print-kot-invoice>
  </div>
  <!-- for second customer copy-->
  <ng-container *ngFor="let order of data.orders; last as last; let i = index">
    <div *ngIf="data.twoCustomerCopy" class="pagebreak">
      <app-invoice
        [storeAddress]="storeAddress(order)"
        [setOrder]="order"
        [index]="i"
      ></app-invoice>
    </div>
  </ng-container>
</div>

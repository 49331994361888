import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { Trips } from "@tendercuts/models";
import { TableRowAction, TripDataSource } from "../../../components";
import { TripRenderComponent } from "../trip-render/trip-render.component";

@Component({
  selector: "app-drivers-trips-grid",
  templateUrl: "./drivers-trips-grid.component.html",
  styleUrls: ["./drivers-trips-grid.component.scss"],
})
export class DriversTripsGridComponent implements AfterViewInit {
  @Input() tripOrdersDataSource: TripDataSource;

  componentToRender: typeof TripRenderComponent = TripRenderComponent;

  // action call for row click
  @Input()
  rowClickCallback: TableRowAction = new TableRowAction(
    this.showTripDetail.bind(this)
  );

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Output() public showTrip: EventEmitter<any> = new EventEmitter();

  constructor(public store: Store<AppState>) {}

  ngAfterViewInit(): void {
    this.tripOrdersDataSource.sort = this.sort;
  }

  showTripDetail(event: any, trip: Trips): void {
    this.showTrip.emit(trip);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BaseGetProvider } from "@tendercuts/http";
import { GenericServerResponse } from "@tendercuts/models";

@Injectable({
    providedIn: "root",
})
export class SendPaymentLinkService extends BaseGetProvider<GenericServerResponse> {
    constructor(
        public http: HttpClient,
        public endpointService: EndPoint,
        public loginService: LoginServices,
    ) {
        super(http);
    }

    get isAuthenticatedEndpoint(): boolean {
        return true;
    }

    get authProtocol(): LoginServices {
        return this.loginService;
    }

    get model(): typeof GenericServerResponse {
        return GenericServerResponse;
    }

    get isCachable(): boolean {
        return false;
    }

    get endpoint(): string {
        return this.endpointService.sendPaymentLink();
    }

    getParams(orderId: string): {
        order_id: string;
    } {
        const params: {
            order_id: string;
        } = {
            order_id: orderId,
        };

        return params;
    }
}

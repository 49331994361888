import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { OrderState } from "@store/orders";
import { AppState } from "@store/state";
import { LiteSaleOrder, SaleOrder } from "@tendercuts/models";
import { take } from "rxjs/operators";
import { AppModule } from "../../app/app.module";

@Injectable()
export class SaleOrderProcessor {
  constructor(public store: Store<AppState>) {}

  /**
   *
   * @param activeOrders - current active orders from server
   * @returns list orders from redux orders that are not in active orders lsit
   */
  async findOrdersToDelete(activeOrders: string[]): Promise<string[]> {
    const orderState: OrderState = await this.store
      .select((state) => state.orderState)
      .pipe(take(1))
      .toPromise();
    const removeOrders: string[] = Object.keys(orderState.orderMap).filter(
      (orderId) => !activeOrders.includes(orderId),
    );

    return removeOrders;
  }

  /**
   *
   * @param activeOrders - current active orders from server
   * @returns list orders from active orders that are not in redux
   */
  async findNewOrders(activeOrders: string[]): Promise<string[]> {
    const orderState: OrderState = await this.store
      .select((state) => state.orderState)
      .pipe(take(1))
      .toPromise();
    const newOrders: string[] = activeOrders.filter(
      (orderId) => orderState.orderMap[orderId] == undefined,
    );

    return newOrders;
  }

  /**
   * fetch orders from redux and compute the last created order time
   * @returns recent orders created time
   */
  async getLastOrderTime(): Promise<Date> {
    const orderState: OrderState = await this.store
      .select((state) => state.orderState)
      .pipe(take(1))
      .toPromise();
    let lastCreateOrderTime: Date = null;
    orderState.orders.forEach((order: SaleOrder) => {
      lastCreateOrderTime =
        !lastCreateOrderTime || lastCreateOrderTime < order.createdAt
          ? order.createdAt
          : lastCreateOrderTime;
    });

    return lastCreateOrderTime;
  }
}

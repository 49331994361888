import { Injectable } from "@angular/core";

import {
  BaseGetProvider,
  BasePostProvider,
  DataTransformationProtocol,
} from "@tendercuts/http";
import { GenericServerResponse, StoreSegment } from "@tendercuts/models";

import { HttpClient, HttpParams } from "@angular/common/http";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";

@Injectable()
export class SaveSegmentService extends BasePostProvider<GenericServerResponse> {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get endpoint(): string {
    return this.endpointService.storeSegmentEndpoint();
  }

  get model(): typeof GenericServerResponse {
    return GenericServerResponse;
  }
}

@Injectable()
export class StoreSegmentService
  extends BaseGetProvider<StoreSegment>
  implements DataTransformationProtocol {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http);
    this.delegate = this;
  }

  // override this
  preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get endpoint(): string {
    return this.endpointService.storeSegmentEndpoint();
  }

  get model(): typeof StoreSegment {
    return StoreSegment;
  }

  getParams(storeId: number): HttpParams {
    let params: HttpParams = new HttpParams();
    params = params.append("store_id", storeId + "");

    return params;
  }
}

import { Component, Input } from "@angular/core";
import { DriverEarningSlab, WeeklyTripSheet } from "@tendercuts/models";

/**
 * component to show day of the order progress
 */
@Component({
  selector: "app-target-progress-bar",
  templateUrl: "./target-progress-bar.component.html",
  styleUrls: ["./target-progress-bar.component.scss"],
})
export class TargetProgressBarComponent {
  @Input()
  slabs: DriverEarningSlab[] = [];

  weeklyTripSheet: WeeklyTripSheet;

  @Input()
  day: string = "";

  // selected day order count
  selectedDayOrderCount: number = 0;

  @Input() set selectedWeekSheet(sheet: WeeklyTripSheet) {
    this.weeklyTripSheet = sheet;
    this.selectedDayOrderCount = this.weeklyTripSheet.dayOrderCount(this.day);
  }

  constructor() {}

  /**
   * Returns true if driver has achieved the slab`s target
   * @param slab
   */
  isCurrentSlab(slab: DriverEarningSlab): boolean {
    return this.weeklyTripSheet.incentiveEarning >= slab.target;
  }
}

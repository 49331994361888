import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BasePostProvider } from "@tendercuts/http";
import { GenericServerResponse } from "@tendercuts/models";

@Injectable({
  providedIn: "root",
})
export class EditDummyTripService extends BasePostProvider<GenericServerResponse> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get endpoint(): string {
    return this.endpointService.editDummyTrip();
  }

  get model(): typeof GenericServerResponse {
    return GenericServerResponse;
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(
    tripId: number,
    driverTag: string
  ): {
    trip_id: string;
    driver_tag: string;
  } {
    const params: {
      trip_id: string;
      driver_tag: string;
    } = {
      trip_id: tripId.toString(),
      driver_tag: driverTag,
    };

    return params;
  }
}

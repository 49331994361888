import { Component, Input } from "@angular/core";

import { BaseGridComponent } from "src/app/components/base-grid/base-grid.component";

/**
 * To display inventory details for an parent sku
 */
@Component({
  selector: "app-inventory-log-display-tab",
  templateUrl: "../../../components/base-grid/base-grid.component.html",
  styleUrls: ["../../../components/base-grid/base-grid.component.scss"],
})
export class InventoryLogGridComponent extends BaseGridComponent {
  @Input() columnsToDisplay: string[] = [
    "inventoryId",
    "createdTime",
    "skuName",
    "relatedTo",
    "stockUpdatedFrom",
    "stockUpdatedTo",
    "storeId",
    "quantityType",
  ];
  @Input() titleToDisplay: string[] = [
    "Inventory Id",
    "Time",
    "SKU",
    "Related To",
    "Stock Updated From",
    "Stock Updated To",
    "Store Id",
    "Quantity Type",
  ];
}

import { Injectable } from "@angular/core";

import { BaseGetProvider } from "@tendercuts/http";
import { DriverProfile } from "@tendercuts/models";

import { HttpClient } from "@angular/common/http";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LiveTrackDriverService extends BaseGetProvider<DriverProfile> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get isCachable(): boolean {
    return false;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get endpoint(): string {
    return this.endpointService.liveTrackDriver();
  }

  get model(): typeof DriverProfile {
    return DriverProfile;
  }

  // getParams(phone: string, orderId: string): HttpParams {
  //   let params = new HttpParams();
  //   params = params.append("mobile_number", phone);
  //   params = params.append("order_id", orderId);
  //   return params;
  // }

  getData(): any {
    return of([
      new DriverProfile().deserialize({
        email: "medavakkamstore@tendercuts.in",
        first_name: "Anbarasan.V",
        is_active: true,
        last_name: "",
        username: "9994569074",
        profile_image: "https://cdn2.tendercuts.in/driver/profiles/b0e4384e-2b0f-4d8b-97b2-87934c95e73c.jpg",
        ratings: "5",
        orders: "100",
        trips: "10"
      }),
    ]);
  }
}

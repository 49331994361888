import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { AutoCompleterPageComponent } from "./pages/auto-completer/auto-completer.component";
import { AutoCompleterService } from "./pages/auto-completer/services/auto-completer.service";
import { BasePage } from "./pages/base/base.component";
import { ModalComponent } from "./pages/base/modals/modal.component";
import {
  SomethingWentWrongComponent,
} from "./pages/base/something-went-wrong/something-went-wrong.component";
import { SpinnerComponent } from "./pages/base/spinner/spinner.component";
import { FileUploaderPageComponent } from "./pages/file-uploader/file-uploader.component";
import { FileUploaderDirective } from "./pages/file-uploader/file-uploader.directive";
import { ModalsPageComponent } from "./pages/modals/modals.component";
import { LoginModalComponent } from "./pages/modals/templates/login/login.component";
import { RegisterModalComponent } from "./pages/modals/templates/register/register.component";
import { NotificationComponent } from "./pages/notifications/notification/notification.component";
import { NotificationsPageComponent } from "./pages/notifications/notifications.component";

// export const utilsRoutes = [
//   { path: '', redirectTo: 'image-uploader' },
//   { path: 'file-uploader', component: FileUploaderPageComponent },
//   { path: 'auto-completer', component: AutoCompleterPageComponent },
//   { path: 'modals', component: ModalsPageComponent },
//   { path: 'notifications', component: NotificationsPageComponent },
//   { path: 'alerts', component: AlertsPageComponent }
// ];

@NgModule({
    declarations: [
        FileUploaderPageComponent,
        FileUploaderDirective,
        AutoCompleterPageComponent,
        ModalsPageComponent,
        NotificationsPageComponent,
        NotificationComponent,
        LoginModalComponent,
        RegisterModalComponent,
        ModalComponent,
        SpinnerComponent,
        SomethingWentWrongComponent,
        BasePage,
    ],
    providers: [AutoCompleterService],
    imports: [
        // RouterModule.forChild(utilsRoutes),
        SharedModule,
    ],
    exports: [NotificationComponent]
})
export class UtilsModule {}

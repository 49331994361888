import { Component, Inject, Input, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Constants } from "@app/env";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { SaleOrder } from "@tendercuts/models";
import { SendSmsService } from "@tendercuts/providers";

@Component({
  selector: "app-send-sms",
  templateUrl: "./send-sms.component.html",
  styleUrls: ["./send-sms.component.scss"],
})
export class SendSmsComponent {
  smsControl: FormControl = new FormControl("", [Validators.required]);
  @Input() order: SaleOrder;

  messages: string[];
  selected: string;
  constructor(
    public dialogRef: MatDialogRef<SendSmsComponent>,
    public sendsmsservice: SendSmsService,
    public constants: Constants,
    public store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: SaleOrder,
  ) {
    if (data) {
      this.order = data;
    }
    this.messages = constants.MESSAGES;
  }
  /**
   * send sms to the selected orderid
   */
  sendSms(): void {
    const selectedreason: string = this.smsControl.value;
    const params: {
      order_id: string;
      key: any;
    } = this.sendsmsservice.getParams(this.order.incrementId, selectedreason);
    this.dialogRef.close(params);
  }
}

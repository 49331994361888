import { Injectable } from "@angular/core";
import { Actions, createEffect } from "@ngrx/effects";
import {
  DeliveryDate,
  DeliverySlot,
  GenericError,
  Store as TenderCutsStore,
  StoreSegment,
  TcutsCity,
} from "@tendercuts/models";
import {
  FetchDeliverySlotService,
  StoreAddressService,
  StoreDetailService,
  StoreSegmentService,
  StoreService,
  SMStorageService,
  TcutsCityService,
} from "@tendercuts/providers";
import { ofAction } from "ngrx-actions";
import { of, Observable } from "rxjs";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { Filter } from "src/models";
import { ActionStitchSegment } from "../orders/actions";

import { HttpParams } from "@angular/common/http";
import { ActionCatalogLoad } from "@store/catalog";
import {
  ActionAllStoreLoaded,
  ActionDeliverySlotsLoaded,
  ActionDeliverySlotsLoadFailed,
  ActionErrorLoadCities,
  ActionGetStores,
  ActionLoadAllStores,
  ActionLoadCities,
  ActionLoadDeliverySlots,
  ActionLoadStore,
  ActionLoadStoreSegments,
  ActionSaveCities,
  ActionSetStore,
  ActionSetStoreSegments,
  ActionStoresLoadSuccess,
  ActionStoreError,
} from "../store/actions";

@Injectable()
export class StoreEffects {
  KEY: string = "store22";

  constructor(
    private actions$: Actions,
    private storeDetailService: StoreDetailService,
    private storageService: SMStorageService,
    private segmentService: StoreSegmentService,
    private fetchDeliverySlotService: FetchDeliverySlotService,
    private storeAddressService: StoreAddressService,
    private storeService: StoreService,
    private tcutsCityService: TcutsCityService
  ) {}

  /**
   * Trigger the server request to load all stores
   * type {Observable<any>}
   */
  
  getStore: Observable<any> = createEffect(() => this.actions$.pipe(
    ofAction(ActionGetStores),
    switchMap((data) => {
      return this.storeDetailService.getData().pipe(
        // pos formatting.
        map((res: TenderCutsStore[]) => {
          // filter online stores
          res = res.filter((store: TenderCutsStore) => store.isOnlineStore);

          return new ActionAllStoreLoaded(res);
        }),
        // if user store is set then filter out
        catchError((err) => of(new ActionStoreError(err)))
      );
    })
  ));

  /**
   * set the store
   */
  
  loadAllStore: Observable<ActionStoreError | ActionStoresLoadSuccess> = createEffect(() =>
    this.actions$.pipe(
      ofAction(ActionLoadAllStores),
      switchMap((data) => {
        return this.storeService.getData().pipe(
          map((res: TenderCutsStore[]) => {
            return new ActionStoresLoadSuccess(res);
          }),
          catchError((err) => of(new ActionStoreError(err)))
        );
      })
    ));

  
  saveStore: Observable<ActionLoadDeliverySlots | ActionCatalogLoad> = createEffect(() =>
    this.actions$.pipe(
      ofAction(ActionSetStore),
      switchMap((action) => {
        this.storageService.storeData("store22", action.payload);

        return of(
          new ActionLoadDeliverySlots(action.payload),
          new ActionCatalogLoad(
            action.payload ? action.payload.toString() : "1"
          )
        );
      })
    ));

  /**
   * Loads the user form local storage
   * type {Observable<any>}
   */
  
  loadStore: Observable<any> = createEffect(() => this.actions$.pipe(
    ofAction(ActionLoadStore),
    switchMap((data) => {
      return this.storageService.getData("store22").pipe(
        map((store) => {
          return new ActionSetStore(store);
        })
      );
    })
  ));

  /**
   * Loads the store segments from the server
   * type {Observable<any>}
   */
  
  loadStoreSegments: Observable<any> = createEffect(() => this.actions$.pipe(
    ofAction(ActionLoadStoreSegments),
    switchMap((action: ActionLoadStoreSegments) => {
      const params: HttpParams = this.segmentService.getParams(action.storeId);
      const segmentService$: Observable<any> =
        this.segmentService.getData(params);

      // Always inject a mock segment
      const otherSegment: StoreSegment = new StoreSegment().deserialize({
        name: "other",
        path_string: "0,0",
        store_id: action.storeId,
        segment_id: -1,
        id: -1,
      });

      return segmentService$.pipe(
        map((segments) => {
          return [...segments, otherSegment];
        }),
        mergeMap((segments) => [
          new ActionSetStoreSegments(segments),
          new ActionStitchSegment(segments),
        ])
      );
    })
  ));

  /**
   * Effect to load Delivery slots from the server
   */
  
  getDeliverSlots: Observable<
    ActionDeliverySlotsLoaded | ActionDeliverySlotsLoadFailed
  > = createEffect(() => this.actions$.pipe(
    ofAction(ActionLoadDeliverySlots),
    switchMap((action: ActionLoadDeliverySlots) => {
      const params: any = this.fetchDeliverySlotService.getStoreParams(
        action.payload
      );

      return this.fetchDeliverySlotService.getData(params).pipe(
        map((res: DeliveryDate[]) => {
          const todaySlot: DeliverySlot[] = res
            .filter((date) => date.isToday === true)
            .map((deliverySlot) => deliverySlot.slots)[0];

          const slotFilterGroup: Filter[] = [];

          if (todaySlot) {
            const slotMap: any = {};
            todaySlot.forEach((slot: DeliverySlot) => {
              slotMap[slot.slotId] = slot;
              slotFilterGroup.push(
                new Filter(
                  slot.interval,
                  slot.slotId,
                  slot.deliveryType.toLocaleString()
                )
              );
            });

            return new ActionDeliverySlotsLoaded({
              slotFilterGroup,
              slotMap,
            });
          }

          return new ActionDeliverySlotsLoadFailed(new GenericError(res));
        }),
        catchError((err) => of(new ActionDeliverySlotsLoadFailed(err)))
      );
    })
  ));

  /**
   * Trigger the server request to load all cities
   * type {Observable<any>}
   */
  
  getCityList: Observable<any> = createEffect(() => this.actions$.pipe(
    ofAction(ActionLoadCities),
    switchMap((data) => {
      return this.tcutsCityService.getData().pipe(
        map((res: TcutsCity[]) => {
          const responseData: TcutsCity[] = res.sort((a, b) => {
            const cityA: any = a.name.toUpperCase();
            const cityB: any = b.name.toUpperCase();

            return cityA < cityB ? -1 : cityA > cityB ? 1 : 0;
          });

          return new ActionSaveCities(responseData);
        }),
        catchError((err) => of(new ActionErrorLoadCities(err)))
      );
    })
  ));
}

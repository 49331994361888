import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import {
  BaseGetProvider,
  BasePostProvider,
  DataTransformationProtocol,
} from "@tendercuts/http";
import {
  LiteSaleOrder,
  PriorityOrder,
  SaleOrder,
  Trips,
} from "@tendercuts/models";
import { of } from "rxjs";

@Injectable()
export class FetchOrdersService
  extends BaseGetProvider<SaleOrder>
  implements DataTransformationProtocol {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices
  ) {
    super(http);
    this.delegate = this;
  }

  // override this
  preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof SaleOrder {
    return SaleOrder;
  }

  get endpoint(): string {
    return this.endpointService.getOrders();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(storeId: string): HttpParams {
    let params: HttpParams = new HttpParams();
    params = params.append("store_id", storeId);

    return params;
  }
}

@Injectable()
export class FetchOrdersDetailsService
  extends BaseGetProvider<SaleOrder>
  implements DataTransformationProtocol {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices
  ) {
    super(http);
    this.delegate = this;
  }

  // override this
  preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof SaleOrder {
    return SaleOrder;
  }

  get endpoint(): string {
    return this.endpointService.getOrderDetail();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(
    orderId: any,
    storeId?: number
  ): {
    order_id: string;
    store_id?: string;
  } {
    const params: {
      order_id: string;
      store_id?: string;
    } = {
      order_id: orderId,
    };
    if (storeId) {
      params['store_id'] = storeId.toString();
    }

    return params;
  }
}

@Injectable()
export class FetchOrderLocationService
  extends BaseGetProvider<Trips>
  implements DataTransformationProtocol {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices
  ) {
    super(http);
    this.delegate = this;
  }

  // override this
  preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof Trips {
    return Trips;
  }

  get endpoint(): string {
    return this.endpointService.getOrderLocation();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(orderId: string): HttpParams {
    let params: HttpParams = new HttpParams();
    params = params.append("order_id", orderId);

    return params;
  }
}

@Injectable()
export class FetchPriorityOrderService
  extends BaseGetProvider<PriorityOrder>
  implements DataTransformationProtocol {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices
  ) {
    super(http);
    this.delegate = this;
  }

  // override this
  preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof PriorityOrder {
    return PriorityOrder;
  }

  get endpoint(): string {
    return this.endpointService.getPriorityOrder();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(storeId: string): HttpParams {
    let params: HttpParams = new HttpParams();
    params = params.append("store_id", storeId);

    return params;
  }
}

@Injectable()
export class FetchLiteSaleOrder
  extends BaseGetProvider<LiteSaleOrder>
  implements DataTransformationProtocol {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices
  ) {
    super(http);
    this.delegate = this;
  }

  // override this
  preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof LiteSaleOrder {
    return LiteSaleOrder;
  }

  get endpoint(): string {
    return this.endpointService.liteSaleOrder();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(storeId: string): HttpParams {
    let params: HttpParams = new HttpParams();
    params = params.append("store_id", storeId);

    return params;
  }
}

/**
 * Fetch array of sale order details for given orders id in array.
 */
@Injectable({ providedIn: "root" })
export class FetchSaleOrdersService extends BasePostProvider<SaleOrder> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof SaleOrder {
    return SaleOrder;
  }

  get endpoint(): string {
    return this.endpointService.fetchOrders();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(
    storeId: string,
    orderIds: string[]
  ): {
    store_id: string;
    order_ids: string[];
  } {
    const params: {
      store_id: string;
      order_ids: string[];
    } = {
      store_id: storeId,
      order_ids: orderIds,
    };

    return params;
  }
}

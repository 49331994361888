import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer } from "@angular/platform-browser";
import { SafeResourceUrl } from "@angular/platform-browser";
import { Constants } from "@app/env";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { HyperTrack } from "@tendercuts/models";
import { skipWhile, take } from "rxjs/operators";
import { BasePage } from "../../utils";

@Component({
  selector: "app-hyper-track",
  templateUrl: "./hypertrack-component.html",
  styleUrls: ["./hypertrack-component.scss"],
})
export class HyperTrackComponent extends BasePage implements OnInit {
  hyperTrack: HyperTrack;
  trackPageUrl: SafeResourceUrl;
  storeId: number;

  constructor(
    protected sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public constants: Constants
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    this.fetchStoreData();
  }

  /**
   * fetch current store id
   * encode the json data with respect to the current storeid
   * and get the share url from constants
   * if the enviroment is staging get the staging url else get the prod url
   * then append the encoded value to the shared url
   */
  async fetchStoreData(): Promise<void> {
    this.storeId = await this.store
      .select((state) => state.storeState.store)
      .pipe(
        skipWhile((state) => !state),
        take(1)
      )
      .toPromise();
    if (!this.storeId) {
      this.textAlert("Please select store to find hypertrack", "");

      return;
    }
    const endocdedUrl: string = encodeURI(
      JSON.stringify({ store_id: this.storeId })
    );
    this.trackPageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.constants.HYPERTRACKURL + endocdedUrl
    );
  }
}

import { Serializable } from "../base";

export class FaqTopics implements Serializable<FaqTopics> {
  private sort_order: number;
  private is_active: boolean;
  private created_at: string;
  public name: string;
  public id: number;
  public description: string;

  constructor() {}

  get sortOrder(): number {
    return this.sort_order;
  }

  get isActive(): boolean {
    return this.is_active;
  }

  get createdAt(): string {
    return this.created_at;
  }

  deserialize(input) {
    this.name = input.name;
    this.id = input.id;
    this.created_at = input.created_at;
    this.is_active = input.is_active;
    this.sort_order = input.sort_order;
    this.description = input.description;

    return this;
  }
}

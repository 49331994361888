import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { StoreState } from "@store/store/state";
import { DriverProfile } from "@tendercuts/models";
import { WeekendDriverService } from "@tendercuts/providers";
import { Subscription } from "rxjs";
import { BasePage } from "src/app/utils";

/*
 * dialog is used to the change work location for the driver
 */
@Component({
  // TODO
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "switch-work-location",
  templateUrl: "./switch-work-location.component.html",
  styleUrls: ["./switch-work-location.component.scss"],
})
export class SwitchWorkLocationComponent extends BasePage implements OnInit, OnDestroy {
  driver: DriverProfile;
  availableStores: any;
  selected: string;
  storeSelectionControl: FormControl = new FormControl();
  storeSub: Subscription;
  constructor(
    public dialogRef: MatDialogRef<SwitchWorkLocationComponent>,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public switchWorkLocationService: WeekendDriverService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialog, snackBar, store);
    if (data) {
      this.driver = data.driver;
    }
  }

  /*
  * load the store avalable stores when the component is opened
  */
  ngOnInit(): void {
    this.storeSub = this.storeState.subscribe((state: StoreState) => {
      this.availableStores = state.availableStores;
    });
  }

  /**
   * get the store name
   */
  get storeName(): string {
    if (!this.driver.profile.workLocation) {
      return "No Store";
    }
    const store: any = this.availableStores.filter(
      (availablestores) =>
        availablestores.storeId == this.driver.profile.workLocation,
    );

    return store.length ? store[0].name : "";
  }

  /*
  * send the params to the service and hit the api
  */
  changeWorkLocation(): void {
    const selectedLocation: number = this.storeSelectionControl.value;
    if (this.driver.profile.workLocation == selectedLocation) {
      this.textAlert(
        "This Rider current work location is " + this.storeName,
        "Please select another location",
      );

      return;
    }
    const params: FormData = this.switchWorkLocationService.getWorkLocationParams(
      this.driver.driverId,
      this.driver.profile.id,
      selectedLocation,
    );
    this.dialogRef.close(params);
    this.switchWorkLocationService.getData(params).subscribe(
      (response) => {
        this.dialogRef.close(true);
        if (response[0]) {
          this.textAlert(response[0].message, "");
        }
      },
      (err) => this.somethingWentWrong(),
    );
  }

  ngOnDestroy(): void {
    this.storeSub.unsubscribe();
  }
}

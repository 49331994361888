import { Serializable } from "./base";

export class FreeDeliveryPushProducts implements Serializable<FreeDeliveryPushProducts> {

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private slider_recommender: number[];

  deserialize(input: any): FreeDeliveryPushProducts {
    this.slider_recommender = input.slider_recommender;

    return this;
  }

  get productIDs(): number[] {
    return this.slider_recommender;
  }

}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BasePostProvider } from "@tendercuts/http";
import { Location } from "@tendercuts/models";

/**
 * Used to fetch order locations for particular date of a store.
 */
@Injectable()
export class OrdersByDateService extends BasePostProvider<Location> {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http);
  }

  preProcess(response: any): Array<any> {
    return response.results;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof Location {
    return Location;
  }

  get endpoint(): string {
    return this.endpointService.ordersByDate();
  }

  getParams(
    fromDate: string,
    endDate: string,
    storeId: number,
    customStatus: string,
  ): {
    store_id: string;
    date: string;
    end_date: string;
    custom_status: string,
  } {
    const params: {
      store_id: string;
      date: string;
      end_date: string;
      custom_status: string;
    } = {
      store_id: storeId.toString(),
      date: fromDate,
      end_date: endDate,
      custom_status: customStatus,
    };

    return params;
  }
}

import { Serializable } from "../base";

export class CustomerEavAttribute implements Serializable<CustomerEavAttribute> {
  public value: number;
  public entity_type_id : number;
  public attribute_id : number;
  public entity_id: number;

  constructor(){}

  deserialize(input) {
    this.value = input.value;
    this.entity_type_id = input.entity_type_id;
    this.attribute_id = input.attribute_id;
    this.entity_id= input.entity_id;

    return this;
  }
}

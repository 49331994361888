import { Address } from "../address";
import { DefaultSerializationMixin, Serializable } from "../base";
import { FaqTopics } from "../faq/faq-topics";
import { PaymentDriver } from "../payment-driver";
import { User } from "../user";
import { DeliverySlot } from "./delivery";
import { SaleItem } from "./sale-item";
import { SaleOrderStatusHistory } from "./status-history";
import { Store } from "../store/store";

export enum SaleOrderStatus {
  PENDING = 0,
  PROCESSING,
  OUTFORDELIVERY,
  COMPLETE,
}

export enum HCSaleOrderStatus {
  PENDING = 0,
  PROCESSING,
  READY,
  OUTFORDELIVERY,
  COMPLETE,
}

export enum DeliveryType {
  EXPRESS = 1,
  SCHEDULED = 2,
  POS = 3,
  STOREPICKUP = 4,
}

function liveTrackStatusText(status: string): string {
  switch (status) {
    case "processing": {
      return "Meat Expert Assigned";
    }
    case "delivery_attempt": {
      return "Delivery Attempted";
    }
    case "delivery_nearby": {
      return "Order Picked Up";
    }
    case "order_shipped": {
      return "Order Ready";
    }
    case "out_delivery": {
      return this.isPickup ? "Order Ready For Pickup" : "Order Picked Up";
    }
    case "complete": {
      return this.isPickup ? "Order Picked Up" : "Order Delivered";
    }
    case "pending_payment": {
      return "Waiting For Payment";
    }
    default: {
      return "Order Received";
    }
  }
}

export class SaleOrder
  extends DefaultSerializationMixin
  implements Serializable<SaleOrder>
{
  private custom_status: string;
  private is_kot_printed: number;
  private otp: string;
  private quote_id: string;
  // Entity id for the split from order
  private splitted_from: number;
  // Entity id for the split to order
  private splitted_to: number;
  private shipping_description: string;
  private created_at: Date;
  private updated_at: Date;
  private sale_date: Date;
  private order_id: string;
  private store_id: number;
  private store_name: string;
  private customer_id: string;
  private increment_id: string;
  private scheduled_date: string;
  private scheduled_slot: string;
  private deliverytype: number;
  private payment_method: string;
  private ideal_issuer_title: string;
  private order_now: string;
  private promised_delivery_time: string;
  private can_retry: boolean;
  private status_header: string;
  private customer_note: string;
  private promised_delivery_time_dt: string;
  private tax_amount: number;
  private reward_currency_amount: number;
  private credit_amount: number;
  private customer_group_id: number;
  private rewardpoints_earn: number;
  private app_version: string;
  private os: string;
  private elite_saving: number;
  private total_savings: number;
  private city: string;
  private cut_off_difference: number;
  private is_new_user: boolean;
  private delivery_partner: string;
  private packing_charges: string;
  private convenience_fee: string;
  private convenience_tax: string;

  public firstname: string;
  public lastname: string;
  public total: number;
  public status: string;
  public medium: string;
  public subtotal: number;
  public discount: number;
  public shipping: string;
  public address: Address;
  public items: SaleItem[];
  public progress: SaleOrderStatusHistory[];
  public user: User;
  public rating: number;
  public shippingAddress: Address;
  public shippingBillingAddress: Address;
  public payment: PaymentDriver;
  public sequence_number: number;
  public driver_name: string;
  public driver_number: string;
  public packingcharges_total: number;
  public timeOfCut: Date;
  public couponCode: string;
  liveTrackStatusText = liveTrackStatusText.bind(this);
  public segment: string;
  public CCName: string;
  public rawInput: any;

  public store: Store;

  /**
   * To get Convenience Fee
   */
  get convenienceFee(): string {
    return this.convenience_fee;
  }

  /**
   * To get Convenience Tax
   */
  get convenienceTax(): string {
    return this.convenience_tax;
  }

  // return slot type
  get shippingDescription() {
    if (!this.shipping_description) {
      return "";
    }

    const slotName = this.shipping_description.substr(
      this.shipping_description.indexOf("("),
      this.shipping_description.indexOf(")")
    );
    return slotName ? slotName.substring(1, slotName.length - 1) : "";
  }

  get orderTimeRemaining() {
    return this.getTimeRemainingSeconds();
  }

  // transient property
  public segmentId: number;
  get scheduledDate() {
    return this.scheduled_date;
  }

  get scheduledSlot() {
    return this.scheduled_slot;
  }

  get statusHeader(): string {
    return this.status_header;
  }

  get deliveryType() {
    return this.deliverytype;
  }

  get incrementId() {
    return this.increment_id;
  }

  get customerId() {
    return this.customer_id;
  }

  get orderId() {
    return this.order_id;
  }

  get storeId() {
    return this.store_id;
  }

  get customStatus() {
    return this.custom_status;
  }

  get cityName() {
    return this.city;
  }

  get isDeliveryAttempted() {
    return this.custom_status && this.custom_status == "delivery_attempt";
  }

  get isOrderShipped() {
    return this.custom_status && this.custom_status == "order_shipped";
  }

  get isDriverNotFound() {
    return (
      this.custom_status && this.custom_status == "external_driver_not_found"
    );
  }

  get isPosOrder() {
    return +this.medium == 4;
  }

  get storeName() {
    const store = this.store_name.split("\n");
    if (store.length > 0) {
      return store[0];
    }
    return "";
  }

  get paymentMethod() {
    return this.payment_method;
  }

  /**
   * To get subpayment mode
   */
  get idealIssuerTitle() {
    return this.ideal_issuer_title;
  }

  set paymentMethod(paymentMethod) {
    this.payment_method = paymentMethod;
  }

  /**To get card orders for pos */
  get isPosCashPayment() {
    return this.paymentMethod == "cashforpos";
  }

  /**To get card orders for pos */
  get isPosCardPayment() {
    return this.paymentMethod == "ccforpos";
  }

  /**To get spcial customer orders */
  get isPosVendorPayment() {
    return this.paymentMethod == "cp1forpos" || this.paymentMethod == "up";
  }

  get updatedAt() {
    return this.updated_at;
  }

  get createdAt() {
    return this.created_at;
  }

  get promisedDeliveryTime() {
    return this.promised_delivery_time;
  }

  // driver-app
  get promisedDeliveryTimeDt() {
    return this.promised_delivery_time_dt;
  }

  get driverName() {
    return this.driver_name ? this.driver_name : "An expert driver";
  }

  get areItemsScanned() {
    if (!this.items || !this.items.length) {
      return false;
    }
    for (const item of this.items) {
      if (!item.isScanned) {
        return false;
      }
    }
    return true;
  }

  get rewardAmount() {
    return this.reward_currency_amount;
  }

  get walletAmount() {
    return this.credit_amount;
  }

  get isKotPrinted() {
    return this.is_kot_printed == 1;
  }

  get driverOtp() {
    return this.otp ? this.otp : "";
  }

  get subStatus() {
    return this.custom_status;
  }

  get isCod() {
    return this.paymentMethod === "cashondelivery";
  }

  get isEliteCustomer() {
    return this.customer_group_id == 4;
  }

  get groupId() {
    return this.customer_group_id;
  }

  get rewardPointsEarn() {
    return this.rewardpoints_earn;
  }

  get isShippingFree() {
    return parseInt(this.shipping) == 0;
  }

  get rewardPointsEarned() {
    return this.rewardpoints_earn;
  }

  get saleDate() {
    return this.sale_date;
  }

  get splittedFrom() {
    return this.splitted_from;
  }

  get splittedTo() {
    return this.splitted_to;
  }

  get appVersion(): string {
    return this.app_version;
  }

  get platform(): string {
    return this.os;
  }

  get eliteSaving(): number {
    return this.elite_saving;
  }

  // Return the elite total savings
  get totalSavings(): number {
    return this.total_savings;
  }

  get eliteSavingsMessage(): string {
    if (!this.elite_saving) {
      return ``;
    }

    return `You saved ₹${this.elite_saving} by using your Elite Subscription.`;
  }

  get cutOffDifference(): number {
    return this.cut_off_difference;
  }

  get chatTopic(): FaqTopics {
    if (this.isPickup) {
      return this.pickupOrderChatTopic;
    }

    return this.onlineOrderChatTopic;
  }

  get pickupOrderChatTopic(): FaqTopics {
    let topic: string;
    let topicDesc: string;
    if (this.isDone) {
      topic = "Help With Past Store Pickup Orders";
      topicDesc = "Help With Past Store Pickup Order";
    } else {
      topic = "Help With Live Store Pickup Orders";
      topicDesc = "Help With Current Store Pickup Order";
    }

    return new FaqTopics().deserialize({
      name: topic,
      description: topicDesc,
    });
  }

  get onlineOrderChatTopic(): FaqTopics {
    let topic: string;
    let topicDesc: string;
    if (this.isDone) {
      topic = "Help With Past Order";
      topicDesc = "Help With Past Order";
    } else {
      topic = "Help With Current Order";
      topicDesc = "Help With Current Order";
    }

    return new FaqTopics().deserialize({
      name: topic,
      description: topicDesc,
    });
  }

  get isNewUser(): boolean {
    return this.is_new_user;
  }

  get orderStatusLabel(): string {
    switch (this.status) {
      case "pending": {
        return "New";
      }
      case "processing": {
        return "Processing";
      }
      case "order_is_ready": {
        return "Ready";
      }
      case "hold": {
        return "Hold";
      }
      case "out_delivery": {
        return "Picked up";
      }
    }
  }

  set deliveryPartner(value: string) {
    this.delivery_partner = value;
  }

  get deliveryPartner(): string {
    return this.delivery_partner;
  }

  constructor() {
    super();
  }

  deserialize(input) {
    if (input == undefined) {
      return;
    }
    this.rawInput = input;
    this.customer_id = input.customer_id;
    this.status_header = input.status_header;
    this.increment_id = input.increment_id;
    this.order_id = input.entity_id;
    this.store_id = input.store_id;
    this.can_retry = input.can_retry;
    this.store_name = input.store_name;
    this.firstname = input.customer_firstname;
    this.lastname = input.customer_lastname;
    this.total = input.grand_total;
    this.medium = input.medium;
    this.status = input.status;
    this.address = new Address().deserialize(input.shipping_address);
    this.created_at = new Date(input.created_at);
    this.updated_at = new Date(input.updated_at);
    this.order_now = input.order_now;
    this.promised_delivery_time = input.promised_delivery_time;
    this.rating = input.rating;
    this.medium = input.medium;
    this.tax_amount = +input.tax_amount;
    this.couponCode = input.coupon_code;
    this.items = new Array();
    this.segment = input.segment;
    this.custom_status = input.custom_status;
    this.CCName = input.agent_mail;
    this.reward_currency_amount = +input.reward_currency_amount;
    this.credit_amount = input.credit_amount;
    this.otp = input.otp;
    this.quote_id = input.quote_id;
    this.customer_note = input.customer_note;
    this.customer_group_id = input.customer_group_id;
    this.rewardpoints_earn = input.rewardpoints_earn;
    this.sale_date = input.sale_date;
    this.splitted_to = input.splittedto;
    this.splitted_from = input.splittedfrom;
    this.app_version = input.app_version;
    this.os = input.os;
    this.elite_saving = Number(input.elite_saving);
    this.total_savings = Number(input.total_savings);
    this.city = input.city;
    this.cut_off_difference = input.cut_off_difference;
    this.is_new_user = input.is_new_user;
    this.delivery_partner = input.delivery_partner;
    this.packing_charges = input.packing_charges;
    this.convenience_fee = input.convenience_fee;
    this.convenience_tax = input.convenience_tax;

    if (input.items && input.items.length) {
      for (const item of input.items) {
        this.items.push(new SaleItem().deserialize(item));
      }
    }

    this.progress = [];
    if (input.status_history instanceof Array) {
      input.status_history.forEach((history) => {
        this.progress.push(new SaleOrderStatusHistory().deserialize(history));
      });
    }

    // driver-app
    let address = new Array();
    if (input.shipping_address instanceof Array) {
      address = input.shipping_address.filter(
        (eachAddress) => eachAddress.address_type == "shipping"
      );
    } else {
      address[0] = input.shipping_address;
    }
    this.shippingAddress = new Address().deserialize(address[0]);

    // driver app billing address
    if (input.shipping_address instanceof Array) {
      const billingAddress = input.shipping_address.find(
        (eachAddress) => eachAddress.address_type == "billing"
      );
      this.shippingBillingAddress = new Address().deserialize(billingAddress);
    } else {
      this.shippingBillingAddress = new Address().deserialize(
        input.shipping_address
      );
    }

    if (input.store) {
      this.store = new Store().deserialize(input.store);
    }

    if (input.payment != null && input.payment != undefined) {
      this.payment_method =
        input.payment[0] && input.payment[0].method
          ? input.payment[0].method
          : "";
      this.ideal_issuer_title =
        input.payment[0] && input.payment[0].ideal_issuer_title
          ? input.payment[0].ideal_issuer_title
          : "";
    }
    this.order_now = input.order_now;
    this.promised_delivery_time_dt =
      input.promised_delivery_time_dt != undefined
        ? input.promised_delivery_time_dt
        : "";
    this.shipping = input.shipping_amount;
    this.discount = +input.discount_amount;
    this.subtotal = input.subtotal;
    this.sequence_number = input.sequence_number;

    // slots data
    this.scheduled_slot = input.scheduled_slot;
    this.scheduled_date = input.scheduled_date;
    this.deliverytype = input.deliverytype;
    this.driver_name = input.driver_name;
    this.driver_number = input.driver_number;
    this.packingcharges_total = input.packingcharges_total;
    this.timeOfCut = input.time_of_cut;
    this.is_kot_printed = input.is_kot_printed;
    this.shipping_description = input.shipping_description;

    // Rating
    this.rating = input.rating;
    this.segmentId = -1;

    return this;
  }

  get showAlternateNumber() {
    if (
      this.shippingAddress.telephone != this.shippingBillingAddress.telephone
    ) {
      return true;
    }
  }

  /**
   * Filter out the processing history from the array of ale order history
   */
  get processingHistory() {
    if (this.progress && this.progress.length) {
      const processingHistory: SaleOrderStatusHistory = this.progress.filter(
        (history) => history.status === "processing"
      )[0];
      return processingHistory;
    }
    return null;
  }

  /** Set the status*/
  set orderStatus(value) {
    this.status = value;
  }

  /** Sets the cusotm status*/
  set customStatus(value) {
    this.custom_status = value;
  }

  /** Set the value of kot printed */
  set kotPrinted(value) {
    this.is_kot_printed = value;
  }

  /** set the vaue to driver_name */
  set driverName(value) {
    this.driver_name = value;
  }

  /** Set the value to driver_number */
  set driverNumber(value) {
    this.driver_number = value;
  }

  get driverNumber() {
    return this.driver_number;
  }

  get isNearBy() {
    return this.custom_status && this.custom_status == "delivery_nearby";
  }

  get isRated() {
    if (!this.rating) {
      return false;
    }
    if (this.rating > 0) {
      return true;
    }
  }

  get customerNote() {
    return this.customer_note;
  }

  get packingCharges(): string {
    return this.packing_charges;
  }

  get isComplete() {
    return this.status == "complete";
  }

  get canRetry() {
    return this.can_retry;
  }

  get searchingDriver() {
    return this.custom_status === "find_driver";
  }

  get isDriverAssigned() {
    return (
      this.custom_status === "driver_accepted" ||
      this.custom_status === "reached_for_pickup" ||
      this.custom_status === "pickup_complete" ||
      this.custom_status === "out_delivery" ||
      this.custom_status === "started_for_delivery" ||
      this.custom_status === "delivery_nearby" ||
      this.custom_status === "delivery_attempt"
    );
  }

  get isDeliveryPartnerCanceled() {
    return (
      this.custom_status == "external_driver_cancelled" ||
      this.custom_status == "external_partner_temporarily_not_available" ||
      this.custom_status == "external_partner_service_not_available" ||
      this.custom_status == "external_driver_not_found"
    );
  }

  get isClosed() {
    return this.status == "closed";
  }

  get isCanceled() {
    return this.status == "canceled" || this.isClosed;
  }

  get isDone() {
    return this.isCanceled || this.isClosed || this.isComplete;
  }

  get isReady(): boolean {
    return this.status == "order_is_ready";
  }

  get isPending() {
    return this.status == "pending" || this.status == "scheduled_order";
  }

  get isHolded(): boolean {
    return this.status == "hold";
  }

  get isProcessing() {
    return this.status == "processing";
  }

  get isAssignedOrder(): boolean {
    return this.custom_status == "order_assigned";
  }

  get isScanned() {
    return this.custom_status == "order_scanned";
  }

  get isPaymentPending() {
    return this.status == "pending_payment";
  }

  get isCentralScanned() {
    return this.custom_status == "item_scanned";
  }

  get isCompleteNotDelivered() {
    return this.custom_status == "complete_not_delivered";
  }

  get isOutForDelivery() {
    return this.status == "out_delivery";
  }

  get isPickup() {
    return this.deliveryType == 4;
  }

  get isCallCenterOrder() {
    return this.CCName;
  }

  get isHoldable() {
    return this.isPending || this.isProcessing || this.isOutForDelivery;
  }

  get isCancelable() {
    return this.isPending || this.isProcessing || this.isOutForDelivery;
  }

  get isSwitchable() {
    return this.isPending || this.isProcessing || this.isOutForDelivery;
  }

  get taxAmount() {
    return this.tax_amount;
  }

  get canSendSms() {
    return this.isPending || this.isProcessing || this.isOutForDelivery;
  }

  get quoteId() {
    return this.quote_id;
  }

  get orderStatusText() {
    if (this.isProcessing) {
      return "Your order is being processed.";
    }

    if (this.isOutForDelivery) {
      return "Your order is being processed.";
    }

    if (this.isComplete) {
      return "Your order has been delivered.";
    }

    return "We have received your order.";
  }

  /**
   * @returns the sum of all items
   *  original price ( base row total)
   */
  get actualSubtotal() {
    let originalSubtotal = 0;
    this.items.forEach((item) => {
      originalSubtotal += +item.baseRowTotal;
    });
    return originalSubtotal;
  }

  /**
   * @returns the sum of all items rowTotal
   */
  get itemSubTotal() {
    return this.items.reduce(
      (total: number, item: SaleItem) => total + Math.abs(item.rowTotal),
      0
    );
  }

  /**
   * @returns the sum of all items baserowTotal
   */
  get itemBaseRowSubTotal() {
    return this.items.reduce(
      (total: number, item: SaleItem) => total + Math.abs(item.baseRowTotal),
      0
    );
  }

  /**
   * @returns the sum of all items rowTotal
   */
  get itemRowSubTotal() {
    return this.items.reduce(
      (total: number, item: SaleItem) => total + Math.abs(item.rowTotal),
      0
    );
  }

  /**
   * @returns the sum of all items discount price
   */
  get specialPriceDiscount() {
    return this.items.reduce(
      (total: number, item: SaleItem) => total + Math.abs(item.discountPrice),
      0
    );
  }

  /**
   * @returns Remarks for Hold order
   */
  get holdOrdersRemarks(): string {
    switch (this.customStatus) {
      case "hold_rnr":
        return "Customer Not Picking Call";
      case "hold_crc":
        return "Customer requested Cancellation";
      case "hold_pin":
        return "Incorrect Address or Pin";
      case "hold_rd":
        return "Customer Rescheduled Delivery";
    }
  }

  /**
   * @returns Remarks for cancelled order
   */
  get cancelOrdersRemarks(): string {
    switch (this.customStatus) {
      case "cancel_oos":
        return "Out Of Stock Cancellation";
      case "cancel_oos_rp":
        return "Out Of Stock Repunch";
      case "cancel_dp":
        return "Dp Mismatch / Out Of Service Area";
      case "cancel_plan":
        return "Plan Change";
      case "cancel_pin":
        return "Incomplete Address";
      case "cancel_late":
        return "Late Delivery";
      case "cancel_duplicate":
        return "Duplicate Order";
      case "cancel_rnr":
        return "RNR";
      case "cancel_tech":
        return "Tech Issue";
      case "cancel_edit":
        return "Order Modifications";
    }
  }

  /**
   * @returns orders are in scan screen or not
   */
  get isScanDone(): boolean {
    return this.isScanned || this.isCentralScanned;
  }

  /**
   * To check the order is swiggy order
   */
  get isSwiggyOrder(): boolean {
    return +this.medium === 12;
  }

  /**
   * To check the order is only customer pickup and
   * not swiggy order
   */
  get isCustomerPickupOrder(): boolean {
    return this.isPickup && !this.isSwiggyOrder;
  }

  /**
   * @returns sum of itemSubTotal,delivery charge,total taxamount
   */
  get grossTotal() {
    let gross = 0;
    gross = this.itemSubTotal + parseInt(this.shipping) + this.taxAmount;
    return gross;
  }

  get totalQty() {
    let qty = 0;
    this.items.forEach((item) => {
      if (!item.isSimple) {
        return;
      }
      qty += +item.quantity;
    });
    return qty;
  }

  get totalWeight() {
    let weight = 0;
    this.items.forEach((item) => {
      weight += +item.displayWeight;
    });
    return weight.toFixed(3);
  }

  /**
   * Returns true if order is in processing and unassigned,
   * returns false if not
   * @param order
   */
  get isUnAssignedOrder() {
    return !this.isPending && this.driver_name == undefined;
  }

  /**
   * Returns true if order is elgible to assign to a trip
   * returns false if not
   * @param order
   */
  get isElgibleForTrip() {
    return this.isUnAssignedOrder && !this.isHolded && !this.isPickup;
  }

  /**
   * Return the Order complete status history
   */
  get completeHistory(): SaleOrderStatusHistory {
    if (this.progress && this.progress.length) {
      let completeHistory: SaleOrderStatusHistory = this.progress.find(
        (history) => history.status === "complete"
      );

      return completeHistory;
    }

    return null;
  }

  /**
   * return the total saving amount of
   * specialpricediscount,coupon discount,reward points
   */
  get savings() {
    let save = 0;
    save =
      this.specialPriceDiscount +
      Math.abs(this.discount) +
      Math.abs(this.rewardAmount);
    return +save.toFixed(2);
  }

  get slotDescription() {
    if (this.deliverytype == 1) {
      return "Express";
    }

    if (this.deliveryType == 3) {
      return "Pos Order";
    }

    if (this.deliveryType == 4) {
      return "Store Pickup";
    }

    const scheduled = {
      51: "10:30-12:30",
      52: "8:00-12:00",
      53: "9:30-11:30",
      54: "11:30-13:30",
      55: "17:30-19:30",
      56: "19:30-21:30",
      57: "18:30-20:30",
      58: "13:30-15:30",
      998: "Store Pickup",
    };
    return scheduled[this.scheduled_slot];
  }

  /**
   * orders time remaining is calculated from the last minute of the delivery slot
   * @function canProcess
   * for express and store pickup orders we directly return true,
   * here 1 resembles express order type and 4 for store pickup,for other slots
   * If sale Date time remaining less than or equal to scheduleCutOffTime
   * @return true
   * else
   * @return false
   */
  canProcess(deliverSlot: DeliverySlot) {
    if (
      this.deliveryType == DeliveryType.EXPRESS ||
      this.deliveryType == DeliveryType.STOREPICKUP
    ) {
      return true;
    }

    const scheduleCutOffTime = deliverSlot.backendSlotCutOffTime;

    return this.timeRemainingForSaleDate <= scheduleCutOffTime;
  }

  /**
   * fn to fetch time remaining from sale date,
   * SaleDate - contains the starting time of the slot (ex: 10-12 slot - sale date will be 21/12/2021 10:00)
   * current time - sale date time gives saleDateTimeRemaining
   */
  get timeRemainingForSaleDate(): number {
    const remainingMs: any =
      new Date(this.saleDate).getTime() - new Date().getTime();

    return Math.round(remainingMs / 1000 / 60);
  }

  /**toGet the simple items and vitual items */
  get simpleItems() {
    return this.items.filter((item) => item.isSimple || item.isSubscription);
  }

  get orderNow() {
    return this.order_now;
  }

  get orderStatusStage() {
    if (this.isProcessing) {
      return 1;
    }
    if (this.isOutForDelivery) {
      return 1;
    }
    if (this.isComplete) {
      return 2;
    }
    return 0;
  }

  // we are using this for  mobile app live-tracking.
  get statusForLiveTracking() {
    if (this.isProcessing) {
      return 1;
    }
    if (this.isOutForDelivery) {
      return 2;
    }
    if (this.isComplete) {
      return 3;
    }

    return 0;
  }

  get hCStatusForLiveTracking() {
    if (this.isProcessing) {
      return 1;
    }
    if (this.isReady) {
      return 2;
    }
    if (this.isOutForDelivery) {
      return 3;
    }
    if (this.isComplete) {
      return 4;
    }

    return 0;
  }

  get mode() {
    if (!this.medium) {
      return "NA";
    }
    switch (this.medium.toString()) {
      case "10":
        return "mobile Web";
      case "9":
        return "website";
      case "3":
        return "mobile";
      case "11":
        return "Urban Piper";
    }
  }

  public getTimeRemainingSeconds() {
    // Hack for the invalid date formats from server

    let promised_time =
      this.promised_delivery_time_dt != ""
        ? this.promised_delivery_time_dt
        : "1";
    if (promised_time.endsWith("Z")) {
      promised_time = promised_time.replace("Z", "+05:30");
    }

    const remainingMs: any =
      new Date(promised_time).getTime() - new Date().getTime();
    return Math.round(remainingMs / 1000);
  }

  get timeRemaining(): number {
    return Math.round(this.getTimeRemainingSeconds() / 60);
  }

  get bufferTime(): Number {
    return Number(this.timeRemaining) - Number(this.shippingAddress.eta);
  }

  get isVerified(): boolean {
    return this.shippingAddress.isVerifiedAddress;
  }

  get simpleItemsCount(): number {
    return this.items.filter(
      (item: SaleItem) => !item.isPromoItem && !item.isComboItem
    ).length;
  }

  get paymentModeSimple() {
    return this.payment_method == "cashondelivery" ? "COD" : "Prepaid";
  }

  get fullAddress() {
    return this.shippingAddress ? this.shippingAddress.fullAddress : "";
  }

  get travelTime() {
    return this.shippingAddress ? this.shippingAddress.eta : "";
  }

  get driverDetails() {
    return this.driver_name + "-" + this.driver_number;
  }

  /**
   * helper method to tell if the method is cod/prepaid order.
   */
  get isPaidOnline() {
    return this.payment_method !== "cashondelivery";
  }

  serialize() {
    function replacer(key, value) {
      // Make sure we skip the null/udefined values
      if (
        key != "" &&
        value != undefined &&
        typeof value === "object" &&
        "serialize" in value
      ) {
        return value.serialize();
      } else {
        return value;
      }
    }

    return JSON.stringify(this, replacer);
  }
}

export class LiveSaleOrder implements Serializable<LiveSaleOrder> {
  private increment_id: string;
  private state: string;
  private profile_image: string;
  private driver_number: number;
  private custom_status: string;
  private enable_live_track: boolean;
  public otp: string;
  public header: string;
  public body: string;
  public eta: number;

  constructor() {}

  deserialize(input) {
    this.increment_id = input.increment_id;
    this.state = input.status;
    this.otp = input.otp;
    this.header = input.header;
    this.body = input.body;
    this.profile_image = input.profile_image;
    this.driver_number = input.driver_number;
    this.custom_status = input.custom_status;
    this.enable_live_track = input.enable_live_track;
    this.eta = +input.eta;

    return this;
  }

  get incrementId() {
    return this.increment_id;
  }

  get isNearBy() {
    return this.custom_status && this.custom_status == "delivery_nearby";
  }

  get isDeliveryAttempted() {
    return this.custom_status && this.custom_status == "delivery_attempt";
  }

  get profileImage() {
    return this.profile_image;
  }

  get enableLiveTrack() {
    return this.enable_live_track;
  }

  get driverNumber() {
    return this.driver_number;
  }

  get customStatus() {
    return this.custom_status;
  }

  get driverOtp() {
    return this.otp ? this.otp : "";
  }

  get status() {
    return this.state;
  }

  get liveTrackStatusHeader() {
    return this.header;
  }

  get liveTrackStatusBody() {
    return this.body;
  }

  get statusCode() {
    switch (this.status) {
      case "pending": {
        return 0;
      }
      case "processing": {
        return 1;
      }
      case "out_delivery":
      case "delivery_attempt":
      case "delivery_nearby": {
        return 2;
      }
      case "complete": {
        return 3;
      }
      default: {
        return 4;
      }
    }
  }
}

export class LiteSaleOrder implements Serializable<LiteSaleOrder> {
  private increment_id: string;
  private custom_status: string;
  private is_kot_printed: boolean;
  private driver_name: string;
  private driver_number: string;
  private delivery_partner: string;

  public status: string;

  deserialize(input) {
    if (input == undefined) {
      return;
    }
    this.increment_id = input.increment_id;
    this.status = input.status;
    this.custom_status = input.custom_status;
    this.is_kot_printed = input.is_kot_printed;
    this.driver_name = input.driver_name;
    this.driver_number = input.driver_number;
    this.delivery_partner = input.delivery_partner;

    return this;
  }

  get orderId() {
    return this.increment_id;
  }

  get customStatus() {
    return this.custom_status;
  }

  get isKotPrinted() {
    return this.is_kot_printed;
  }

  get driverName() {
    return this.driver_name;
  }

  get driverNumber() {
    return this.driver_number;
  }

  get deliveryPartner(): string {
    return this.delivery_partner;
  }
}

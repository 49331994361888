import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import {
  GenericServerResponse,
  HyperTrackOrder,
  SaleOrder,
  User,
} from "@tendercuts/models";
import {
  AddCommentService,
  CanHoldService,
  ChangePaymentModeService,
  DeliveryInstructionsService,
  FetchOrderLocationService,
  HoldOrderService,
  HyperTrackOrderService,
  MarkAsNotDeliveredService,
  OrderCloseService,
  PickupOrderCompleteService,
  ReplaceOrderService,
  SendPaymentLinkService,
  SendSmsService,
  UnHoldOrderService,
} from "@tendercuts/providers";
import { skipWhile, take } from "rxjs/operators";
import { PrintInvoiceDialogComponent } from "../../modules/print-module";
import { BasePage } from "../../utils/pages/base/base.component";
import { AlternateNumberComponent } from "../add-alternate-number/add-alternate-number";
import { AddCommentComponent } from "../add-comment/add-comment.component";
import { CanHoldComponent } from "../can-hold/can-hold.component";
import { DeliveryInstructionsComponent } from "../delivery-instructions/delivery-instructions.component";
import { HoldOrderComponent } from "../holdorder/holdorder.component";
import { HyperTrackOrderComponent } from "../hyper-track-order/hyper-track-order.component";
import { LocateOrderPinComponent } from "../locate-order-pin";
import { OrderCancelComponent } from "../order-cancel";
import { CloseOrderComponent } from "../order-close/order-close.component";
import { OrderEtaComponent } from "../order-eta/order-eta.component";
import { ReplaceOrderComponent } from "../order-replace/order-replace.component";
import { ChangePaymentModeComponent } from "../payment-mode/payment-mode.component";
import { SendSmsComponent } from "../send-sms/send-sms.component";
import { SlotChangeComponent } from "../slot-change/slot-change.component";

@Component({
  selector: "app-order-actions",
  templateUrl: "./order-actions.component.html",
  styleUrls: ["./order-actions.component.scss"],
})
export class OrderActionsComponent extends BasePage implements OnInit {
  @Input() order: SaleOrder;
  user: User;

  // an event emitter to affect parent component on actions performed
  @Output() refreshParentEmitter: EventEmitter<any> = new EventEmitter<any>();
  hyperTrackOrder: HyperTrackOrder;
  shareUrl: SafeResourceUrl;

  constructor(
    private replaceOrderService: ReplaceOrderService,
    private holdOrderService: HoldOrderService,
    private closeOrderService: OrderCloseService,
    private changePaymentmodeService: ChangePaymentModeService,
    private unHoldOrderService: UnHoldOrderService,
    private sendsmsservice: SendSmsService,
    private sendPaymentLinkService: SendPaymentLinkService,
    private canHoldService: CanHoldService,
    private tripOrderService: FetchOrderLocationService,
    public commentService: AddCommentService,
    private storePickedOrderService: PickupOrderCompleteService,
    private hyperTrackOrderService: HyperTrackOrderService,
    private deliveryInstructionService: DeliveryInstructionsService,
    private markAsNotDeliveredService: MarkAsNotDeliveredService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public router: Router,
    public sanitizer: DomSanitizer
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    this.getUser();
  }

  /**
   * Show "Mark as not delivered" button only if
   * the user is a call centre agent or call centre manager and
   * the order is an online orders and
   * the order is in complete status and
   * the order sub status is not complete_not_delivered
   */
  get canShowMarkAsNotDeliveredBtn(): boolean {
    return (
      this.user &&
      (this.user.isCcAgent || this.user.isCcManager) &&
      this.order.deliveryType < 3 &&
      this.order.isComplete &&
      !this.order.isCompleteNotDelivered
    );
  }

  /**
   * Compare order created date with todays date and return
   * visible only for ccagent login and order in complete state
   */
  get canShowReplaceButton(): boolean {
    if (
      this.user &&
      this.user.isCcAgent &&
      this.order.isComplete &&
      this.order.totalQty > 0 &&
      !this.isComboORPromo
    ) {
      const todayDate: number = new Date().getDate();
      const orderDate: number = this.order.createdAt.getDate() + 2;

      return todayDate == orderDate || orderDate > todayDate;
    }

    return false;
  }

  get isComboORPromo(): boolean {
    const isValid: boolean = this.order.items.some(
      (item) => item.isComboItem || item.isPromoItem
    );

    return isValid ? true : false;
  }

  /**
   * Visible only for the user with ccmanager, cluster manager,
   * techops role or show payment link btn permission
   */
  get canShowActionBtn(): boolean {
    return this.user?.isTechOps ||
      this.user?.isClusterManager ||
      this.user?.isCcManager ||
      this.canShowPaymentLinkBtn
  }

  /**
   * Visible only for the user with ccagent, ccmanager
   * or techops role and order is COD
   */
  get canShowPaymentLinkBtn(): boolean {
    const isValidUserRole: boolean = this.user && (
      this.user.isCcAgent ||
      this.user.isCcManager ||
      this.user.isTechOps
    ) ? true : false;

    return isValidUserRole && this.order.isCod;
  }

  /**
   * fetch logged user details
   * By this we can get the logged user's role
   */
  async getUser(): Promise<void> {
    this.user = await this.store
      .select((state) => state.userState.user)
      .pipe(
        skipWhile((user) => !user),
        take(1)
      )
      .toPromise();
  }

  /**
   * To send the payment link to the customer
   * Order ID is passed as a param
   */
  async sendPaymentLink(): Promise<void> {
    const params: {
      order_id: string,
    } = this.sendPaymentLinkService.getParams(
      this.order.incrementId
    );

    const response: GenericServerResponse[] = await this.sendPaymentLinkService
      .getData(params)
      .toPromise()
      .catch((err) => this.somethingWentWrong());
    if (!response[0].status) {
      this.textAlert(response[0].message, "", "OK");

      return;
    }

    this.textAlert(response[0].message, "", "OK");
    this.refreshParentEmitter.emit(1);
  }

  /**
   * To track the out of delivery orders
   * if the order is having hypertrack shows the hyper track map
   * along with ETA and duration for not completed orders
   * else it is showing normal live track map
   */
  async openLiveTrack(): Promise<void> {
    this.presentLoader();
    const response: HyperTrackOrder[] = await this.hyperTrackOrderService
      .getData(this.hyperTrackOrderService.getParams(this.order.incrementId))
      .toPromise();
    if (response[0].shareUrl && response[0].shareUrl != "") {
      this.hyperTrackOrder = response[0];
      this.shareUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        response[0].shareUrl
      );
      this.dismissLoader();
      this.openHyperTrack();

      return;
    }
    this.openOldLiveTrack();
  }

  openHyperTrack(): void {
    const dialogRef: MatDialogRef<HyperTrackOrderComponent, any> =
      this.dialog.open(HyperTrackOrderComponent, {
        data: {
          order: this.order,
          hyperTrackOrder: this.hyperTrackOrder,
          shareUrl: this.shareUrl,
        },
        width: "80vw",
      });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.refreshParentEmitter.emit(1);
      }
    });
  }

  openOldLiveTrack(): void {
    this.tripOrderService
      .getData(this.tripOrderService.getParams(this.order.incrementId))
      .subscribe((tripObj) => {
        if (tripObj) {
          this.dismissLoader();
          const canholdOrderDialogRef: MatDialogRef<OrderEtaComponent, any> =
            this.dialog.open(OrderEtaComponent, {
              data: {
                trip: tripObj[0],
                order: this.order,
              },
            });
          canholdOrderDialogRef.afterClosed().subscribe((data) => {
            if (data) {
              this.refreshParentEmitter.emit(1);
            }
          });
        }
      });
  }
  // To locate Orders
  locateOrder(): void {
    const dialogRef: MatDialogRef<LocateOrderPinComponent, any> =
      this.dialog.open(LocateOrderPinComponent, {
        data: { order: this.order },
      });
  }

  scanOrder(): void {
    this.dialog.closeAll();
    this.router.navigate(["dashboard/scan/" + this.order.incrementId]);
  }

  /**
   * To take the printout of the order
   */
  printOrder(): void {
    const dialogData: {
      orders: SaleOrder[];
      printType: string;
      printKot: boolean;
    } = {
      orders: [this.order],
      printType: "customer",
      printKot: false,
    };
    this.dialog.open(PrintInvoiceDialogComponent, {
      width: "80mm",
      data: dialogData,
    });
  }

  /**
   * To hold the selcted order
   */
  holdOrder(): void {
    if (this.order.isOutForDelivery && this.order.driver_name) {
      this.textAlert(
        "Cannot hold orders which are in out for delivery state",
        "Unassign the order from driver trip and try again"
      );

      return;
    }
    const holdOrderDialogRef: MatDialogRef<HoldOrderComponent, any> =
      this.dialog.open(HoldOrderComponent, {
        data: this.order,
      });
    holdOrderDialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.presentLoader();
        this.holdOrderService.getData(data).subscribe(
          (response) => {
            this.dismissLoader();
            this.textAlert(response[0].message, "");
            this.refreshParentEmitter.emit(1);
          },
          (err) => this.somethingWentWrong()
        );
      }
    });
  }

  /**
   * unhold the holded order
   * check if trip object exist,order  will return to trip
   * else order will return into store
   */
  async unholdOrder(): Promise<void> {
    const param: {
      increment_id: string;
    } = this.canHoldService.getparams(this.order.incrementId);
    const response: any = await this.canHoldService.getData(param).toPromise();
    if (response[0].tripId != undefined) {
      const canholdOrderDialogRef: MatDialogRef<CanHoldComponent, any> =
        this.dialog.open(CanHoldComponent, {
          data: {
            order: this.order,
            trip: response[0],
          },
          width: "32vw",
          height: "70vh",
        });
      canholdOrderDialogRef.afterClosed().subscribe(() => {
        this.refreshParentEmitter.emit(1);
      });

      return;
    }
    const responses: any = await this.unHoldOrderService
      .getData(param)
      .toPromise()
      .catch((err) => this.somethingWentWrong());
    this.dismissLoader();
    this.textAlert(responses[0].message, "");
    this.refreshParentEmitter.emit(1);
  }

  /**
   * To cancel the selected order
   */
  openCancelOrderDialog(): void {
    const dialogData: MatDialogRef<OrderCancelComponent, any> =
      this.dialog.open(OrderCancelComponent, {
        data: this.order,
        width: "580px",
      });

    dialogData.afterClosed().subscribe((data) => {
      if (data) {
        this.refreshParentEmitter.emit(1);
      }
    });
  }

  /**
   * To Add alternate number
   */
  openAddAlternateDialog(): void {
    const dialogData: MatDialogRef<AlternateNumberComponent, any> =
      this.dialog.open(AlternateNumberComponent, {
        data: { order: this.order },
      });
    dialogData.afterClosed().subscribe((data) => {
      if (data) {
        this.refreshParentEmitter.emit(1);
      }
    });
  }

  /**
   * while dismiss of addCommentDialog  the sendComment function will trigger
   * and pass the param comment to that function
   */
  openAddCommentDialog(): void {
    const dialogData: MatDialogRef<AddCommentComponent, any> = this.dialog.open(
      AddCommentComponent,
      {
        data: this.order,
        width: "30vw",
      }
    );
    dialogData.afterClosed().subscribe((comment) => {
      if (comment) {
        this.sendComment(comment);
      }
    });
  }

  /**
   * used to add the comment for the particular order
   * param comment
   */
  sendComment(comment: string): void {
    const params: string = this.commentService.getParams(
      this.order.incrementId,
      comment
    );
    this.commentService.getData(params).subscribe(
      (data) => {
        this.dialog.closeAll();
        this.showNotification(
          "top",
          "center",
          data[0].status ? "success" : "danger",
          "info-circle",
          data[0].message
        );
        this.refreshParentEmitter.emit(1);
      },
      (err) => this.somethingWentWrong()
    );
  }

  /**
   * swith DP
   */
  goToGeoHash(): void {
    this.dialog.closeAll();
    this.router.navigate(["/dashboard/geohash/" + this.order.incrementId]);
  }

  /**
   * send sms to the customer for cancel request
   * while the sms dialog dismiss the sendComment function will trigger
   */
  openSmsDialog(): void {
    const dialogData: MatDialogRef<SendSmsComponent, any> = this.dialog.open(
      SendSmsComponent,
      {
        data: this.order,
        width: "23vw",
      }
    );
    dialogData.afterClosed().subscribe((data) => {
      if (data) {
        this.presentLoader();
        this.sendsmsservice.getData(data).subscribe(
          (response) => {
            this.dismissLoader();
            this.sendComment(response[0].message);
          },
          (err) => this.somethingWentWrong()
        );
      }
    });
  }
  /**
   * To close the order
   */
  closeOrder(): void {
    const dialogData: MatDialogRef<CloseOrderComponent, any> = this.dialog.open(
      CloseOrderComponent,
      {
        data: this.order,
        width: "23vw",
      }
    );
    dialogData.afterClosed().subscribe((data) => {
      if (!data) {
        return;
      }
      this.presentLoader();
      this.closeOrderService.getData(data).subscribe(
        (response) => {
          this.dismissLoader();
          this.refreshParentEmitter.emit(1);
          this.textAlert(response[0].message, " ");
        },
        (err) => {
          this.somethingWentWrong();
        }
      );
    });
  }

  /**
   * To replace the order
   */
  replaceOrder(): void {
    const replaceOrderDialogRef: MatDialogRef<ReplaceOrderComponent, any> =
      this.dialog.open(ReplaceOrderComponent, {
        data: this.order,
      });
    replaceOrderDialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.presentLoader();
        this.replaceOrderService.getData(data).subscribe(
          (response) => {
            this.dismissLoader();
            this.refreshParentEmitter.emit(1);
            this.textAlert(response[0].message, " ");
          },
          (err) => this.somethingWentWrong()
        );
      }
    });
  }

  /**
   * To change the payment mode retail
   */
  paymentMode(): void {
    if (!this.canShowChangePaymentButton) {
      this.textAlert("You can't perform this action", " ");

      return;
    }
    const dialogData: MatDialogRef<ChangePaymentModeComponent, any> =
      this.dialog.open(ChangePaymentModeComponent, {
        data: this.order,
        width: "23vw",
      });
    dialogData.afterClosed().subscribe((data) => {
      if (!data) {
        return;
      }
      this.presentLoader();
      this.changePaymentmodeService.getData(data).subscribe(
        (response) => {
          this.dismissLoader();
          this.refreshParentEmitter.emit(1);
          this.textAlert(response[0].message, " ");
        },
        (err) => {
          this.somethingWentWrong();
        }
      );
    });
  }

  /**
   * Show change payment button only on certain condition
   */
  get canShowChangePaymentButton(): boolean {
    return (
      this.user &&
      (this.user.isTechOps ||
        this.user.isClusterManager ||
        this.user.isCcManager) &&
      !this.order.isClosed
    );
  }

  // store pickup order check
  get canShowCompleteButton(): boolean {
    return (
      this.order.isPickup &&
      this.order.isOutForDelivery &&
      this.user &&
      this.user.isSm
    );
  }

  /**
   * param orderId is passed to get response
   */
  completeStorePickedOrder(): void {
    const params: string = this.storePickedOrderService.getparams(
      this.order.incrementId
    );
    this.storePickedOrderService.getData(params).subscribe(
      (responseData) => {
        if (responseData[0].status == true) {
          this.textAlert(responseData[0].message, "");
          this.refreshParentEmitter.emit(1);
        } else {
          this.textAlert(responseData[0].message, "");
        }
      },
      (err) => this.somethingWentWrong()
    );
  }

  /**
   * param order_id is passed to change the storepickup order slot
   */
  changeSlot(): void {
    const changedialogData: MatDialogRef<SlotChangeComponent, any> =
      this.dialog.open(SlotChangeComponent, {
        data: this.order,
        width: "23vw",
      });
    changedialogData.afterClosed().subscribe((data) => {
      if (data) {
        this.refreshParentEmitter.emit(1);
      }
    });
  }

  /**
   * Adds delivery instructions to an order
   */
  openDeliveryInstructionsDialog(): void {
    const dialogData: MatDialogRef<DeliveryInstructionsComponent, any> =
      this.dialog.open(DeliveryInstructionsComponent, {
        width: "30vw",
      });
    dialogData.afterClosed().subscribe((deliveryInstructions) => {
      if (deliveryInstructions) {
        this.presentLoader();
        const params: string = this.deliveryInstructionService.getParams(
          this.order.incrementId,
          deliveryInstructions
        );
        this.deliveryInstructionService.getData(params).subscribe(
          (response: GenericServerResponse) => {
            this.dismissLoader();
            this.sendComment(`Delivery instructions: ${deliveryInstructions}`);
          },
          (error) => this.somethingWentWrong()
        );
      }
    });
  }

  /**
   * When mark as not delivered btn is clicked
   * make an api call to update the substatus to complete_not_delivered
   */
  markAsNotDelivered(): void {
    const params: string = this.markAsNotDeliveredService.getparams(
      this.order.incrementId
    );
    this.presentLoader();
    this.markAsNotDeliveredService
      .getData(params)
      .toPromise()
      .then((response: GenericServerResponse) => {
        this.refreshParentEmitter.emit(1);
        this.dismissLoader();
        this.showNotification(
          "top",
          "center",
          response[0].status ? "success" : "failure",
          "info-circle",
          response[0].message
        );
      })
      .catch((error: Error) => {
        this.somethingWentWrong();
      });
  }
}

<div class="tripsheet" *ngIf="weeklyTripSheet">
  <div class="row no-gutters tripsheet__header">
    <span class="tripsheet__header__text col-8"
      >Generate Weekly Trip Sheet For
      <span (click)="redirectToCrm()" class="tripsheet__header__text__link">
        {{ weeklyTripSheet.driver?.id }}
      </span>
      ({{ weeklyTripSheet.driver?.firstName }})
    </span>
    <span class="col-4 tripsheet__header__closeBtn">
      <button
        (click)="viewtargets()"
        mat-raised-button
        color="primary"
        class="tripsheet__header__logs"
        id="confirm-btn"
      >
        View Targets
      </button>
      <button
        mat-raised-button
        class="tripsheet__header__close-btn"
        color="primary"
        id="close-btn"
        (click)="closeDialog()"
      >
        Close
      </button>
    </span>
  </div>
  <div class="row no-gutters tripsheet__cards">
    <div class="col-4">
      <mat-card class="tripsheet__cards__first">
        <mat-card-title class="tripsheet__cards__title">
          Details <mat-chip> {{ weeklyTripSheet.weeklySheetStatus }}</mat-chip>
        </mat-card-title>
        <mat-card-content
          class="tripsheet__cards__content tripsheet__cards__content--big"
        >
          <div class="row no-gutters">
            <div class="header col-6">ID</div>
            <div class="content col-6">
              {{ weeklyTripSheet.id }}
            </div>
          </div>
          <div class="row no-gutters">
            <div class="header col-6">Generated At</div>
            <div class="content col-6">
              {{ weeklyTripSheet.updatedAt| date: 'medium' }}
            </div>
          </div>
          <div class="row no-gutters">
            <div class="header col-6">Driver</div>
            <div class="content col-6">
              <a> {{ weeklyTripSheet.driver?.id }}</a>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="header col-6">Week</div>
            <div class="content col-6">
              {{ weeklyTripSheet.weekDays }}
            </div>
          </div>
          <div class="row no-gutters">
            <div class="header col-6">Trip Sheet Count</div>
            <div class="content col-6">
              {{ weeklyTripSheet.tripsheetCount }}
            </div>
          </div>
          <div class="row no-gutters">
            <div class="header col-6">Order Count</div>
            <div id="order-count" class="content col-6">
              {{ weeklyTripSheet.orderCount }}
            </div>
          </div>
          <div class="row no-gutters">
            <div class="header col-6">Scheme</div>
            <div id="scheme" class="content col-6">
              {{ weeklyTripSheet.schemeDescription }}
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-4">
      <mat-card class="tripsheet__cards__second">
        <mat-card-title class="tripsheet__cards__title">
          Earnings - Breakup
        </mat-card-title>
        <mat-card-content
          class="tripsheet__cards__content tripsheet__cards__content--big"
        >
          <table mat-table [dataSource]="earningsBreakupDatasource" class="earnings-table">

            <ng-container matColumnDef="label">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element" class="header"> {{element.label}} </td>
            </ng-container>

            <ng-container matColumnDef="columnA">
              <th mat-header-cell *matHeaderCellDef>Daily</th>
              <td mat-cell *matCellDef="let element" class="content"> 
                {{ element.columnA | currency: "INR" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="columnB">
              <th mat-header-cell *matHeaderCellDef>{{weeklyTripSheet.isSchemeHybrid ? 'Hybrid' : 'Weekly'}}</th>
              <td mat-cell *matCellDef="let element" class="content"> 
                {{ element.columnB | currency: "INR" }}
              </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="earnings-table__row"></tr>
            <tr 
              mat-row 
              *matRowDef="let row; columns: displayedColumns;" 
              [ngClass]="(row.label === 'Final Earnings' || row.label === 'Total Earnings') 
                ? 'earnings-table__final' 
                : ''" 
              class="earnings-table__row">
            </tr>
          </table>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-4" *ngIf="weeklyTripSheet.changes.length">
      <mat-card class="tripsheet__cards-overflow tripsheet__cards__second">
        <mat-card-title class="tripsheet__cards__title">
          Change Logs
        </mat-card-title>
        <mat-card-content
          class="tripsheet__cards__content tripsheet__cards__content--big"
        >
          <app-change-logs
            [changeLogs]="weeklyTripSheet.changes"
          ></app-change-logs>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="row no-gutters tripsheet__cards__incentive">
    <mat-card class="tripsheet__cards__flags">
      <mat-card-title class="tripsheet__cards__title">
        Incentive Flags
      </mat-card-title>
      <mat-card-content
        class="tripsheet__cards__content tripsheet__cards__flags-margin"
      >
        <mat-chip-list class="tripsheet__cards__flags__chips">
          <mat-chip class="tripsheet__cards__flags__matChip"
            ><i
              [ngClass]="{
                'tripsheet__cards__flags__matChip-green':
                  weeklyTripSheet.incentiveForAttendance
              }"
              class="material-icons"
              >{{ checkEligiblity(weeklyTripSheet.incentiveForAttendance) }}</i
            >
            Attendance
          </mat-chip>
          <mat-chip class="tripsheet__cards__flags__matChip"
            ><i
              [ngClass]="{
                'tripsheet__cards__flags__matChip-green':
                  weeklyTripSheet.incentiveForWeekdayOnTime
              }"
              class="material-icons"
              >{{
                checkEligiblity(weeklyTripSheet.incentiveForWeekdayOnTime)
              }}</i
            >
            Weekday On Time</mat-chip
          >
          <mat-chip class="tripsheet__cards__flags__matChip"
            ><i
              [ngClass]="{
                'tripsheet__cards__flags__matChip-green':
                  weeklyTripSheet.incentiveForWeekendOnTime
              }"
              class="material-icons"
              >{{
                checkEligiblity(weeklyTripSheet.incentiveForWeekendOnTime)
              }}</i
            >
            Weekend On Time</mat-chip
          >
        </mat-chip-list>
        <div
          *ngIf="weeklyTripSheet.incentiveComments"
          (click)="openCommentDialog()"
          class="tripsheet__cards__footer"
        >
          <i
            class="tripsheet__cards__footer__icon fa fa-info-circle"
            aria-hidden="true"
          ></i>
          <span class="tripsheet__cards__footer__content"
            >Click here to understand the incentive earnings</span
          >
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="tripsheet__cards__flags">
      <mat-card-title class="tripsheet__cards__title">
        Incentive Tags
      </mat-card-title>
      <mat-card-content
        class="tripsheet__cards__content tripsheet__cards__flags-margin"
      >
        <mat-chip-list class="tripsheet__cards__flags__chips">
          <mat-chip class="tripsheet__cards__flags__matChip"
            ><i
              [ngClass]="{
                'tripsheet__cards__flags__matChip-green':
                  weeklyTripSheet.requestIncentive
              }"
              class="material-icons"
              >{{ checkEligiblity(weeklyTripSheet.requestIncentive) }}</i
            >
            Request Incentive</mat-chip
          >
        </mat-chip-list>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="tripsheet__list">
    <mat-card class="tripsheet__list__card">
      <mat-card-title class="tripsheet__list__card__title">
        Trip Sheets
      </mat-card-title>
      <mat-card-content class="tripsheet__list__card__content">
        <app-trip-sheet-grid
          [dataSource]="tripSheetDataSource"
          [columnsToDisplay]="columnsToDisplay"
          [titleToDisplay]="titleToDisplay"
          [actions]="actions"
          [rowClickCallback]="rowClickCallback"
          [footerColumnsToDisplay]="footerColumnsToDisplay"
        >
        </app-trip-sheet-grid>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="tripsheet__footer row no-gutters">
    <div class="tripsheet__footer__total col-4">
      <b>Total Earnings:</b>
      {{ weeklyTripSheet.totalEarning | currency: "INR" }}
    </div>
    <div class="tripsheet__footer__btns col-8">
      <app-weekly-trip-sheet-actions
        (reloadParent)="reloadParent($event)"
        [isDeliveryManagerMode]="isDeliveryManagerMode"
        [weeklyTripSheet]="weeklyTripSheet"
      >
      </app-weekly-trip-sheet-actions>
    </div>
  </div>
</div>

import { Serializable } from '../base';
import { Product } from './product';


export class RelatedProducts implements Serializable<RelatedProducts> {
    
    public related_products: any;

    constructor() { }

    deserialize(input) {
        this.related_products = input;
        return this;
    }

}
import { Serializable } from "../base";

export class BarcodeSpec implements Serializable<BarcodeSpec> {
  private product_id: number;
  private is_new_format: boolean;
  public weight: number;
  public date: string;
  public time: string;

  get productId() {
    return this.product_id;
  }

  /**
   * get the data and time if date and time between (1 - 9)
   * add 0 in suffix.
   */
  get validateDate() {
    return this.is_new_format ? this.date == new Date().toDateString() : true;
  }

  constructor() {}

  deserialize(input) {
    this.product_id = input.product_id;
    this.is_new_format = input.is_new_format;
    this.weight = input.weight;
    this.date = input.date;
    this.time = input.time;

    return this;
  }
}

import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, Subscription } from "rxjs";

const ServiceName: any = "PubSub Service";

@Injectable()
// TODO
// eslint-disable-next-line @typescript-eslint/no-use-before-define
export class PubSubService implements IPubSubService {
  private events: any = {};

  constructor() {}

  public $sub(event: string): Observable<any>;
  public $sub(
    event?: string,
    callback?: (value: any) => void,
    error?: (error: any) => void,
    complete?: () => void,
  ): Subscription;
  public $sub(
    event: string,
    callback?: (value: any) => void,
    error?: (error: any) => void,
    complete?: () => void,
  ): any {
    if (!event) {
      throw new Error(
        `[${ServiceName}] => Subscription method must get event name.`,
      );
    }

    if (this.events[event] === undefined) {
      this.events[event] = new ReplaySubject<any>(1);
    }

    if (typeof callback !== "function") {
      return this.events[event].asObservable();
    }

    return this.events[event]
      .asObservable()
      .subscribe(callback, error, complete);
  }

  public $pub(event: string, eventObject?: any): void {
    if (!event) {
      throw new Error(
        `[${ServiceName}] => Publish method must get event name.`,
      );
    } else if (!this.events[event]) {
      return;
    }

    this.events[event].next(eventObject);
  }
}

export interface IPubSubService {
  $pub(event: string, eventObject?: any): void;
  $sub(event: string): Observable<any>;
  $sub(
    event?: string,
    callback?: (value: any) => void,
    error?: (error: any) => void,
    complete?: () => void,
  ): Subscription;
}

// interface I$sub{
//   (event: string): Observable<any>;
//   (event: string, callback: (value: any) => void): Subscription;
//   (event: string, callback: (value: any) => void, error: (error: any) => void): Subscription;
//   (event: string, callback: (value: any) =>
//     void, error: (error: any) => void, complete: () => void): Subscription;
// }

import { Serializable } from './base';

export class EmergencyMessage implements Serializable<EmergencyMessage> {
    public description: string;
    public city: string;
    public status: boolean;
    public type: string;
    public url: string;
    public title: string;
    
    constructor() { }

    deserialize(input) {
        this.description = input.content;
        this.city = input.city;
        this.status = input.status;
        this.type = input.type;
        this.url = input.url;
        this.title = input.title;

        return this;
    }
}
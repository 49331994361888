<p>
  <b>Order Location Pin</b>
</p>

<div class="row map">
  <agm-map [zoom]="13" [latitude]="lat" [longitude]="lng" [zoomControl]="true">
    <agm-marker
      [latitude]="lat"
      [longitude]="lng"
      [label]="'order-location'"
      [iconUrl]="'assets/marker/red.png'"
    ></agm-marker>
  </agm-map>
</div>

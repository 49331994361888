import * as selectors from "@store/state";

import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenavContainer } from "@angular/material/sidenav";
import { MatSnackBar } from "@angular/material/snack-bar";

import { HttpParams } from "@angular/common/http";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { ActionRefreshOrders } from "@store/orders";
import { AppState } from "@store/state";
import { StoreState } from "@store/store";
import { SaleOrder, Trips } from "@tendercuts/models";
import { DriverTripSearchService } from "@tendercuts/providers";
import { defer, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { BasePage } from "../../../utils";

@Component({
  selector: "app-trips-search",
  templateUrl: "./trips-search.component.html",
  styleUrls: ["./trips-search.component.scss"],
})
export class TripsSearchComponent
  extends BasePage
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("drawer") drawer: MatSidenavContainer;

  // Entire Trips array is obtained in drivertrip
  driverTrips: Trips[];

  // On selecting Particular trip drawer is open
  selectedTrip: Trips;
  searchText: string = "";
  phoneNumber: string = "";
  tripId: string = "";
  routerEventSubcriber: Subscription;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    private title: Title,
    public driverTripSearchService: DriverTripSearchService,
    private router: ActivatedRoute,
    private route: Router,
  ) {
    super(dialog, snackBar, store);
  }

  /**
   * listen the route params change if the there is any change in the route
   * parameter it will fetch the data according to the activated route value
   */
  ngOnInit(): void {
    this.routerEventSubcriber = this.route.events.subscribe((data) => {
      if (data instanceof NavigationEnd) {
        this.loadTripDetail();

        return;
      }
    });
    this.loadTripDetail();
    this.title.setTitle("Trip Detail");
    defer(() => this.loadData()).subscribe(() => {});
  }

  ngOnDestroy(): void {
    if (this.routerEventSubcriber) {
      this.routerEventSubcriber.unsubscribe();
    }
  }

  /**
   * if routeparam have a value set that value in search text
   * according to thisthe rider detail and trip details will load
   */
  loadTripDetail(): void {
    // tripId is passed as params to load data through url search
    const routeParam: string = this.router.snapshot.params["tripId"];
    if (routeParam) {
      this.searchText = routeParam;
      this.searchDriverOrder();
      this.fetchTripService();
    }
  }

  // To load live orders from ActionGetOrders
  async loadData(): Promise<void> {
    const storeState: StoreState = await this.store
      .select(selectors.getStoreState)
      .pipe(take(1))
      .toPromise();
    const storeId: string = storeState.store.toString();
    this.store.dispatch(new ActionRefreshOrders(storeId));
  }

  ngAfterViewInit(): void {}
  /**
   * If searchText length equal to 0,no data is loaded,
   * If searchtext length equal to 10, driver_id is passed to get trip deatil,
   * If searchtext length not equal to 10, trip_id is passed to get trip detail.
   */
  searchDriverOrder(): void {
    if (this.searchText.length == 0) {
      return;
    }
    if (this.searchText.length == 10) {
      this.phoneNumber = this.searchText;
      this.route.navigate(["/dashboard/trip-detail/" + this.phoneNumber]);
    } else {
      this.tripId = this.searchText;
      this.route.navigate(["/dashboard/trip-detail/" + this.tripId]);
    }
  }

  // Fetching trip details through service
  fetchTripService(): void {
    this.presentLoader();
    const params: HttpParams = this.driverTripSearchService.getParams(
      this.tripId,
      this.phoneNumber,
    );
    this.driverTripSearchService.getData(params).subscribe(
      (trips: Trips[]) => {
        this.dismissLoader();
        this.fetchDriverOrders(trips);
      },
      (err) => {
        this.dismissLoader();
        this.textAlert("Invalid Data", "No Trips found");
      },
    );
  }

  /**
   * @param trips getOrders function is called if response is obtained,
   * else error message in shown.
   */
  fetchDriverOrders(trips: Trips[]): void {
    if (trips.length > 0) {
      this.getOrders(trips);
    } else {
      this.dismissLoader();
      this.textAlert("Invalid Data", "Please Enter Valid Phone Number/Trip-Id");
    }
  }

  // From store select using orderstate stritching, sequencedOrders is obtained
  getOrders(trips: Trips[]): void {
    this.driverTrips = null;
    this.store
      .select((state) => state.orderState)
      .pipe(take(1))
      .subscribe((state) => {
        trips.forEach((trip) => {
          const orders: SaleOrder[] = trip.driverOrder.map((order) => order.saleOrder);
          trip.sequencedOrders = [];
          orders.forEach((saleOrder) => {
            if (saleOrder && saleOrder.status !== "complete") {
              trip.sequencedOrders.push(state.orderMap[saleOrder.incrementId]);
            }
          });
        });
      });
    this.dismissLoader();
    this.driverTrips = trips;
  }

  /**
   * @param trip data is passesd as event to selectedTrip
   */
  showTrip(trip: Trips): void {
    this.selectedTrip = trip;
    this.drawer.open();
  }

  // To empty trip data on clossing dialog
  closeDrawer(): void {
    this.selectedTrip = null;
    this.drawer.close();
  }
}

<div class="trip-wrap">
  <div class="row header">
    <p class="col-5 title">{{ optimalTrip.title }}</p>

    <p class="col-5 status">
      <mat-chip color="warn" class="order-status" *ngIf="verifiedCount > 0">
        <i class="fa fa-check green tick"></i>
        {{ verifiedCount }} Orders
      </mat-chip>

      <mat-chip color="warn" class="order-status" *ngIf="urgentCount > 0">
        <i class="fa fa-exclamation-circle priority"></i>
        {{ urgentCount }} Orders
      </mat-chip>
    </p>

    <p class="col-2">
      <button mat-stroked-button color="warn" (click)="triggerAction.emit()">
        Edit and Assign Trip
      </button>
    </p>
  </div>

  <mat-list-item class="trip-path">
    <mat-chip-list mat-line>
      <ng-container *ngFor="let order of orders; let last = last">
        <mat-chip class="order-chip">
          <app-order-id-display
            [order]="order"
            [showTime]="true"
          ></app-order-id-display>
          <mat-icon matChipRemove (click)="removeOrder(order.incrementId)">
            cancel
          </mat-icon>
        </mat-chip>
        <span *ngIf="!last">--</span>
      </ng-container>
    </mat-chip-list>
  </mat-list-item>

  <div class="area-chips" *ngIf="orders.length > 0">
    <mat-chip-list mat-line>
      <ng-container *ngFor="let segment of segments">
        <mat-chip *ngIf="segment.hasOrders(orders).size > 0">
          {{ segment.name }} - {{ segment.hasOrders(orders).size }} Orders
        </mat-chip>
      </ng-container>
    </mat-chip-list>
  </div>
</div>

<mat-card>
  <mat-card-title>Cash Transactions</mat-card-title>
  <mat-table #matTable [dataSource]="cashTransactionsDataSource" matSort>
    <ng-container matColumnDef="cashEntry">
      <mat-header-cell *matHeaderCellDef> Cash Entry </mat-header-cell>
      <mat-cell *matCellDef="let cashTransactions">
        <span class="label">{{ cashTransactions.totalCash }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cashId">
      <mat-header-cell *matHeaderCellDef> Cash Id </mat-header-cell>
      <mat-cell *matCellDef="let cashTransactions">
        <span class="label">{{ cashTransactions.cashId }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="amountCollected">
      <mat-header-cell *matHeaderCellDef> Amount Collected </mat-header-cell>
      <mat-cell *matCellDef="let cashTransactions">
        <span class="label">{{ cashTransactions.amount }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <mat-header-cell *matHeaderCellDef> Date & Time </mat-header-cell>
      <mat-cell *matCellDef="let cashTransactions">
        <span class="label">{{ cashTransactions.createdAt }}</span>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnsToDisplay"></mat-row>
  </mat-table>
</mat-card>

import { Driver, Trips, TripCashEntries, TripConfig } from "@tendercuts/models";
import { createReducer, Action, Store } from "ngrx-actions";
import {
  ActionDisableTripEditMode,
  ActionFetchTripConfig,
  ActionLoadTripCash,
  ActionSaveTripCash,
  ActionSaveTripConfig,
} from "../driver/actions";
import {
  ActionEnableTripEditMode,
  ActionEveryDriversLoaded,
  ActionFetchSavedTrips,
  ActionGetEveryDrivers,
  ActionLoadFailed,
  ActionStitchTripOrders,
  ActionStoreSavedTrips,
} from "../driver/actions";
import {
  ActionAllDriversLoaded,
  ActionAllTripsLoaded,
  ActionDriverStoreClear,
  ActionGetDrivers,
  ActionGetTrips,
} from "../driver/actions";
import { driverInitialState, DriverState } from "./state";

@Store({ ...driverInitialState })
export class DriverReducers {
  /**
   * Trigger server request and set loading to true
   * @param DriverState state
   * @param ActionGetDrivers action
   * @returns drivers: []; trips: []; loading: boolean; error: boolean; errorMessage: null
   */
  @Action(ActionGetDrivers)
  actionLoadDrivers(
    state: DriverState,
    action: ActionGetDrivers
  ): {
    drivers: Driver[];
    allDrivers: Driver[];
    trips: Trips[];
    savedTrips: Trips[];
    driverMap: any;
    loading: boolean;
    trip_loader: boolean;
    driver_loader: boolean;
    error: boolean;
    errorMessage: null;
    editTripId: number;
    tripCashEntries: TripCashEntries[];
    tripConfig: TripConfig;
  } {
    return {
      ...state,
      loading: true,
      error: false,
    };
  }

  /**
   * Trigger server request and set loading to true
   * @param DriverState state
   * @param ActionGetDrivers action
   * @returns drivers: []; trips: []; loading: boolean; error: boolean; errorMessage: null
   */
  @Action(ActionGetEveryDrivers)
  actionLoadEveryDrivers(
    state: DriverState,
    action: ActionGetEveryDrivers
  ): {
    drivers: Driver[];
    allDrivers: Driver[];
    trips: Trips[];
    savedTrips: Trips[];
    driverMap: any;
    loading: boolean;
    trip_loader: boolean;
    driver_loader: boolean;
    error: boolean;
    errorMessage: null;
    editTripId: number;
    tripCashEntries: TripCashEntries[];
    tripConfig: TripConfig;
  } {
    return {
      ...state,
      driver_loader: true,
      error: false,
    };
  }

  /**
   * Trigger server request and set loading to true
   * @param DriverState state
   * @param ActionGetDrivers action
   * @returns drivers: []; trips: []; loading: boolean; error: boolean; errorMessage: null
   */
  @Action(ActionGetTrips)
  actionLoadTrips(
    state: DriverState,
    action: ActionGetTrips
  ): {
    drivers: Driver[];
    allDrivers: Driver[];
    trips: Trips[];
    savedTrips: Trips[];
    driverMap: any;
    loading: boolean;
    trip_loader: boolean;
    driver_loader: boolean;
    error: boolean;
    errorMessage: null;
    editTripId: number;
    tripCashEntries: TripCashEntries[];
    tripConfig: TripConfig;
  } {
    return {
      ...state,
      trip_loader: true,
      error: false,
    };
  }

  /**
   * set laoding flag to false, and load all driver
   */
  @Action(ActionAllDriversLoaded)
  actionPublishDrivers(
    state: DriverState,
    action: ActionAllDriversLoaded
  ): {
    drivers: Driver[];
    allDrivers: Driver[];
    trips: Trips[];
    savedTrips: Trips[];
    driverMap: any;
    loading: boolean;
    trip_loader: boolean;
    driver_loader: boolean;
    error: boolean;
    errorMessage: null;
    editTripId: number;
    tripCashEntries: TripCashEntries[];
    tripConfig: TripConfig;
  } {
    return {
      ...state,
      loading: false,
      drivers: action.payload,
    };
  }

  /**
   * set laoding flag to false, and load all driver
   */
  @Action(ActionEveryDriversLoaded)
  actionPublishEveryDrivers(
    state: DriverState,
    action: ActionEveryDriversLoaded
  ): {
    drivers: Driver[];
    allDrivers: Driver[];
    trips: Trips[];
    savedTrips: Trips[];
    driverMap: any;
    loading: boolean;
    trip_loader: boolean;
    driver_loader: boolean;
    error: boolean;
    errorMessage: null;
    editTripId: number;
    tripCashEntries: TripCashEntries[];
    tripConfig: TripConfig;
  } {
    const driverMap: any = {};
    action.payload.forEach((driver: Driver) => (driverMap[driver.id] = driver));

    return {
      ...state,
      driver_loader: false,
      allDrivers: action.payload,
      driverMap,
    };
  }

  /**
   * set laoding flag to false, and load all driver
   */
  @Action(ActionAllTripsLoaded)
  actionPublishTrips(
    state: DriverState,
    action: ActionAllTripsLoaded
  ): {
    drivers: Driver[];
    allDrivers: Driver[];
    trips: Trips[];
    savedTrips: Trips[];
    driverMap: any;
    loading: boolean;
    trip_loader: boolean;
    driver_loader: boolean;
    error: boolean;
    errorMessage: null;
    editTripId: number;
    tripCashEntries: TripCashEntries[];
    tripConfig: TripConfig;
  } {
    return {
      ...state,
      trip_loader: false,
      trips: action.payload,
    };
  }

  @Action(ActionLoadFailed)
  ActionLoadFailed(state: DriverState): {
    drivers: Driver[];
    allDrivers: Driver[];
    trips: Trips[];
    savedTrips: Trips[];
    driverMap: any;
    loading: boolean;
    trip_loader: boolean;
    driver_loader: boolean;
    error: boolean;
    errorMessage: null;
    editTripId: number;
    tripCashEntries: TripCashEntries[];
    tripConfig: TripConfig;
  } {
    return {
      ...state,
      loading: false,
      error: true,
    };
  }

  /**
   * set laoding flag to false, and load all driver
   */
  @Action(ActionDriverStoreClear)
  actionclearDriverStore(
    state: DriverState,
    action: ActionDriverStoreClear
  ): {
    drivers: Driver[];
    allDrivers: Driver[];
    trips: Trips[];
    savedTrips: Trips[];
    driverMap: any;
    loading: boolean;
    trip_loader: boolean;
    driver_loader: boolean;
    error: boolean;
    errorMessage: null;
    editTripId: number;
    tripCashEntries: TripCashEntries[];
    tripConfig: TripConfig;
  } {
    return {
      ...state,
      trips: [],
      drivers: [],
      driverMap: {},
      allDrivers: [],
    };
  }

  /**
   * Action Stitch which will stitch the sale order objects into
   * trip objects.
   * @param state
   * @param action
   */
  @Action(ActionStitchTripOrders)
  actionStitchTripOrders(
    state: DriverState,
    action: ActionStitchTripOrders
  ): {
    drivers: Driver[];
    allDrivers: Driver[];
    trips: Trips[];
    savedTrips: Trips[];
    driverMap: any;
    loading: boolean;
    trip_loader: boolean;
    driver_loader: boolean;
    error: boolean;
    errorMessage: null;
    editTripId: number;
    tripCashEntries: TripCashEntries[];
    tripConfig: TripConfig;
    stichDone: boolean;
  } {
    const orderMap: any = {};
    action.orders.forEach((order) => (orderMap[order.incrementId] = order));
    state.trips.forEach((trip) => {
      trip.sequencedOrders = [];
      Array.from(trip.orders).forEach((orderId) =>
        orderMap[orderId] ? trip.sequencedOrders.push(orderMap[orderId]) : ""
      );

      trip.driverOrder.forEach(
        (driverData) =>
          (driverData.saleOrder = orderMap[driverData.incrementId])
      );

      trip.driver = state.driverMap[trip.driverUserId];
    });

    return {
      ...state,
      stichDone: true,
    };
  }

  @Action(ActionFetchSavedTrips)
  actionFetchSavedTrips(
    state: DriverState,
    action: ActionFetchSavedTrips
  ): {
    drivers: Driver[];
    allDrivers: Driver[];
    trips: Trips[];
    savedTrips: Trips[];
    driverMap: any;
    loading: boolean;
    trip_loader: boolean;
    driver_loader: boolean;
    error: boolean;
    errorMessage: null;
    editTripId: number;
    tripCashEntries: TripCashEntries[];
    tripConfig: TripConfig;
  } {
    return {
      ...state,
      loading: true,
      error: false,
    };
  }

  @Action(ActionStoreSavedTrips)
  actionStoreSavedTrips(
    state: DriverState,
    action: ActionStoreSavedTrips
  ): {
    drivers: Driver[];
    allDrivers: Driver[];
    trips: Trips[];
    savedTrips: Trips[];
    driverMap: any;
    loading: boolean;
    trip_loader: boolean;
    driver_loader: boolean;
    error: boolean;
    errorMessage: null;
    editTripId: number;
    tripCashEntries: TripCashEntries[];
    tripConfig: TripConfig;
  } {
    return {
      ...state,
      savedTrips: action.payload,
      loading: false,
      error: false,
    };
  }

  @Action(ActionEnableTripEditMode)
  actionEnableTripMode(
    state: DriverState,
    action: ActionEnableTripEditMode
  ): {
    drivers: Driver[];
    allDrivers: Driver[];
    trips: Trips[];
    savedTrips: Trips[];
    driverMap: any;
    loading: boolean;
    trip_loader: boolean;
    driver_loader: boolean;
    error: boolean;
    errorMessage: null;
    editTripId: number;
    tripCashEntries: TripCashEntries[];
    tripConfig: TripConfig;
  } {
    return {
      ...state,
      editTripId: action.tripId,
    };
  }

  @Action(ActionDisableTripEditMode)
  actionDisableTripEditMode(
    state: DriverState,
    action: ActionEnableTripEditMode
  ): {
    drivers: Driver[];
    allDrivers: Driver[];
    trips: Trips[];
    savedTrips: Trips[];
    driverMap: any;
    loading: boolean;
    trip_loader: boolean;
    driver_loader: boolean;
    error: boolean;
    errorMessage: null;
    editTripId: number;
    tripCashEntries: TripCashEntries[];
    tripConfig: TripConfig;
  } {
    return {
      ...state,
      editTripId: null,
    };
  }

  /**
   * Updates fetched trip cash entries in driverState
   * @param state
   * @param action
   */
  @Action(ActionSaveTripCash)
  actionSaveTripCash(
    state: DriverState,
    action: ActionSaveTripCash
  ): {
    drivers: Driver[];
    allDrivers: Driver[];
    trips: Trips[];
    savedTrips: Trips[];
    driverMap: any;
    loading: boolean;
    trip_loader: boolean;
    driver_loader: boolean;
    error: boolean;
    errorMessage: null;
    editTripId: number;
    tripCashEntries: TripCashEntries[];
    tripConfig: TripConfig;
  } {
    return {
      ...state,
      loading: false,
      tripCashEntries: action.payload,
    };
  }

  /**
   * Set loading true for fetch data
   * @param state
   * @param action
   */
  @Action(ActionFetchTripConfig)
  actionFetchTripConfig(
    state: DriverState,
    action: ActionFetchTripConfig
  ): {
    drivers: Driver[];
    allDrivers: Driver[];
    trips: Trips[];
    savedTrips: Trips[];
    driverMap: any;
    loading: boolean;
    trip_loader: boolean;
    driver_loader: boolean;
    error: boolean;
    errorMessage: null;
    editTripId: number;
    tripCashEntries: TripCashEntries[];
    tripConfig: TripConfig;
  } {
    return {
      ...state,
      loading: true,
    };
  }

  @Action(ActionLoadTripCash)
  actionLoadTripCash(
    state: DriverState,
    action: ActionLoadTripCash
  ): {
    drivers: Driver[];
    allDrivers: Driver[];
    trips: Trips[];
    savedTrips: Trips[];
    driverMap: any;
    loading: boolean;
    trip_loader: boolean;
    driver_loader: boolean;
    error: boolean;
    errorMessage: null;
    editTripId: number;
    tripCashEntries: TripCashEntries[];
    tripConfig: TripConfig;
  } {
    return {
      ...state,
      loading: true,
    };
  }

  /**
   * Set loading false after fetch data and save the configs
   * @param state
   * @param action
   */
  @Action(ActionSaveTripConfig)
  actionSaveTripConfig(
    state: DriverState,
    action: ActionSaveTripConfig
  ): {
    drivers: Driver[];
    allDrivers: Driver[];
    trips: Trips[];
    savedTrips: Trips[];
    driverMap: any;
    loading: boolean;
    trip_loader: boolean;
    driver_loader: boolean;
    error: boolean;
    errorMessage: null;
    editTripId: number;
    tripCashEntries: TripCashEntries[];
    tripConfig: TripConfig;
  } {
    return {
      ...state,
      loading: false,
      tripConfig: action.tripConfig,
    };
  }
}

export const driverReducer: (state: any, action: any) => any =
  createReducer(DriverReducers);

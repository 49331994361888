<div class="cc-wrapper">
  <div class="row no-gutters cc-wrapper__title">
    <span class="col-6"> Trip Cash Collection </span>
    <div class="col-6 cc-wrapper__title__closeBtn">
      <button
        mat-storked-button
        color="primary"
        id="close-btn"
        (click)="dialogRef.close()"
      >
        Close
      </button>
    </div>
  </div>
  <div class="cc-wrapper__content">
    <p>
      Amount to be collected: <i class="fa fa-rupee" aria-hidden="true"></i>
      {{ trip.cash?.actualAmount }}
      <br />
      <ng-container *ngIf="trip.isCashShort">
        Remaining amount to be collected:
        <i class="fa fa-rupee" aria-hidden="true"></i>
        {{ trip.cash?.shortageAmount }}
      </ng-container>
    </p>

    <form (submit)="validateCash()">
      <mat-form-field>
        <input
          type="number"
          id="cash"
          placeholder="Enter actual cash"
          matInput
          [readOnly]="showReason"
          [formControl]="cashInputControl"
        />
      </mat-form-field>
      <textarea
        *ngIf="showReason"
        id="cash-reason"
        maxlength="200"
        placeholder="Enter reason for cash shortage"
        [formControl]="reasonControl"
      ></textarea>
    </form>
  </div>

  <div class="footer">
    <button
      mat-raised-button
      color="primary"
      class="footer__btn"
      id="reset-cash"
      (click)="reset()"
    >
      <i class="fa fa-refresh" aria-hidden="true"></i>
      Reset
    </button>
    <button
      mat-raised-button
      color="primary"
      class="footer__btn"
      [disabled]="!isEnable"
      id="submit-cash"
      (click)="validateCash()"
    >
      <i class="fa fa-money" aria-hidden="true"></i>
      Submit
    </button>
  </div>
</div>

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BaseGetProvider } from "@tendercuts/http";
import { DriverStatusHistory } from "@tendercuts/models";

@Injectable({
  providedIn: "root",
})
export class DriverStatusHistoryService extends BaseGetProvider<DriverStatusHistory> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
    this.delegate = this;
  }

  preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof DriverStatusHistory {
    return DriverStatusHistory;
  }

  get endpoint(): string {
    return this.endpointService.driverStatusHistory();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(
    userId: number,
  ): {
    user_id: number;
  } {
    const params: {
      user_id: number;
    } = {
      user_id: userId,
    };

    return params;
  }
}

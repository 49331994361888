<form
  class="quantity"
  [formGroup]="quantityForm"
  (ngSubmit)="validateQuantityForm()"
>
  <mat-form-field>
    <mat-label>Enter Available Quantity</mat-label>
    <input
      matInput
      placeholder="Enter quantity to filter"
      type="number"
      formControlName="enteredQuantity"
      id="qty-available"
      required
    />
  </mat-form-field>
</form>
<div class="actions">
  <button mat-raised-button (click)="dialogRef.close()">Cancel</button>
  <button mat-raised-button color="primary" (click)="validateQuantityForm()">
    Ok
  </button>
</div>

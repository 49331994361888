import { Component, Input, OnInit } from "@angular/core";
import { GridRowDetailComponent } from "src/app/components/base-grid/grid-row-detail.component";

/**
 * component to show trip order and trip label im tabel grid
 */
@Component({
  selector: "app-customer-tag",
  templateUrl: "./customer-profile-complains.component.html",
  styleUrls: ["./customer-profile-complains.component.scss"],
})
export class CustomerComplaintTagComponent implements GridRowDetailComponent {
  @Input()
  data: any;

  constructor() {}

}

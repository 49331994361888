import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BaseGetProvider, DataTransformationProtocol } from "@tendercuts/http";
import { OptimalTrip } from "@tendercuts/models";

@Injectable()
export class OptimalTripService
  extends BaseGetProvider<OptimalTrip>
  implements DataTransformationProtocol {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
    this.delegate = this;
  }

  // override this
  preProcessResponse(response: any): Array<any> {
    return response;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof OptimalTrip {
    return OptimalTrip;
  }

  get endpoint(): string {
    return this.endpointService.optimalTrip();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(
    storeId: string,
    orderCount: number = -1,
    segment: any,
    slots: any,
  ): HttpParams {
    let params: HttpParams = new HttpParams();
    params = params.append("store_id", storeId);
    params = params.append("order_count", orderCount + "");
    if (segment) {
      params = params.append("segments", segment.join(","));
    }

    if (slots) {
      params = params.append("slots", slots.join(","));
    }

    return params;
  }
}

import { Injectable } from "@angular/core";
import { Inventory } from "@tendercuts/models";
import { of, Observable } from "rxjs";
import { InventoryService } from "./service";

@Injectable()
export class MockInventoryService extends InventoryService {
  public data: any;
  private inventoryData: {
    product: number;
    scheduledqty: string;
    qty: number;
}[] = [
    {
      product: 192,
      scheduledqty: "99",
      qty: 0,
    },
    {
      product: 193,
      scheduledqty: "0",
      qty: 3,
    },
    {
      product: 303,
      scheduledqty: "99",
      qty: 0,
    },
  ];

  public get cache(): any {
    return this.data;
  }

  public getData(): Observable<Inventory[]> {
    const category: Inventory[] = this.inventoryData.map((data) =>
      new Inventory().deserialize(data),
    );

    this.data = category;

    return of(category);
  }
}

import * as selectors from "@store/state";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { ActionGetStores, StoreState } from "@store/store";
import { Observable } from "rxjs";
import { BasePage } from "src/app/utils";

/**
 * component for New Inentory update
 */
@Component({
  selector: "app-new-inventory-release",
  templateUrl: "./new-inventory-release.component.html",
  styleUrls: ["./new-inventory-release.component.scss"],
})
export class NewInventoryReleaseComponent extends BasePage implements OnInit {
  newInventoryMode: boolean = true;
  isGtg: boolean = false;

  constructor(
    public store: Store<AppState>,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    this.store.dispatch(new ActionGetStores());
    this.stores.subscribe((state: StoreState) => {
      this.isGtg = state.allStores.filter(
        (store) => store.storeId === state.store
      )?.[0]?.isGtg;
    });
  }

  get stores(): Observable<StoreState> {
    return this.store.select(selectors.getStoreState);
  }
}

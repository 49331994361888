import { Serializable } from "../base";

export class CancelOrderRequest {
  reasons: { name: string; value: string }[] = [
    {
      name: "Out Of Stock Cancellation",
      value: "cancel_oos",
    },
    {
      name: "Out Of Stock Repunch",
      value: "cancel_oos_rp",
    },
    {
      name: "Dp Mismatch / Out Of Service Area",
      value: "cancel_dp",
    },
    {
      name: "Plan Change",
      value: "cancel_plan",
    },
    {
      name: "Incomplete Address",
      value: "cancel_pin",
    },
    {
      name: "Late Delivery",
      value: "cancel_late",
    },
    {
      name: "Duplicate Order",
      value: "cancel_duplicate",
    },
    {
      name: "RNR",
      value: "cancel_rnr",
    },
    {
      name: "Tech Issue",
      value: "cancel_tech",
    },
    {
      name: "Order Modifications",
      value: "cancel_edit",
    },
  ];

  items: number[] = [];
  selectedReason = "";
  increment_id = "";
  refundMode = "";
  constructor(increment_id: string) {
    this.increment_id = increment_id;
  }

  serialize() {
    return {
      items: this.items,
      reason: this.selectedReason,
      increment_id: this.increment_id,
      refund_to_bank: this.refundMode,
    };
  }
}

export class CancelOrderResponse implements Serializable<CancelOrderResponse> {
  private refund_details: RefundDetails;
  public message: string;
  public status: boolean;

  constructor() {}

  get refundDetails() {
    return this.refund_details;
  }

  deserialize(input) {
    this.message = input.message;
    this.status = input.status;
    if (input.refund_details) {
      this.refund_details = new RefundDetails().deserialize(
        input.refund_details
      );
    }
    return this;
  }
}

export class RefundDetails implements Serializable<RefundDetails> {
  private reward_amount: string;
  private credit_amount: string;
  private bank_amount: string;
  public products: string;

  get rewardAmount() {
    return this.reward_amount;
  }

  get creditAmount() {
    return this.credit_amount;
  }

  get bankAmount() {
    return this.bank_amount;
  }

  constructor() {}

  deserialize(input) {
    this.reward_amount = input.reward_amount;
    this.credit_amount = input.credit_amount;
    this.bank_amount = input.bank_amount;
    this.products = input.products;

    return this;
  }
}

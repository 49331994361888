import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Constants } from "@app/env";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { GenericServerResponse, SaleOrder, User, WalletTransaction } from "@tendercuts/models";
import { AddStoreCreditService } from "@tendercuts/providers";
import { skipWhile, take } from "rxjs/operators";
import { BasePage, ModalComponent } from "../../utils";

@Component({
  selector: "app-add-store-credit",
  templateUrl: "./add-store-credit.component.html",
  styleUrls: ["./add-store-credit.component.scss"],
})
export class AddStoreCreditComponent extends BasePage implements OnInit {
  // form group controller for all fields
  storeCreditForm: FormGroup = new FormGroup({
    commentControl: new FormControl("", [Validators.required]),
    orderId: new FormControl("", [Validators.required]),
    selectedReasonFormControl: new FormControl("", [Validators.required]),
    creditControl: new FormControl(""),
  });
  user: User;
  storeCredit: number;
  comment: string;
  customer: User;
  walletObj: WalletTransaction;
  minStoreCreditAmount: number;
  maxStoreCreditAmount: number;

  orders: SaleOrder[] = [];

  // List of customer order id's
  customerOrdersIdList: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public dialogRef: MatDialogRef<AddStoreCreditComponent>,
    public dialog: MatDialog,
    public storeCreditService: AddStoreCreditService,
    public constants: Constants
  ) {
    super(dialog, snackBar, store);
    if (data) {
      this.customer = data.customer;
      if (data.orders && data.orders.length) {
        this.orders = data.orders;
        this.orders.forEach((order: SaleOrder) =>
          this.customerOrdersIdList.push(order.incrementId)
        );
        this.customerOrdersIdList.unshift("IPL Offer");
      } else {
        this.customerOrdersIdList.unshift("IPL Offer");
      }
    }
    this.walletObj = new WalletTransaction();
  }

  ngOnInit(): void {
    this.getUser();
  }

  /**
   * Entered store credit is more than maxStoreCreditAmount
   * throw an error message
   * comment value is null throw an error message
   */
  submitStoreCredit(): void {
    if (!this.storeCreditForm.controls.creditControl.valid) {
      this.showNotification(
        "top",
        "center",
        "danger",
        "info-circle",
        `Amount credited should be between ${this.minStoreCreditAmount} to ${this.maxStoreCreditAmount}`,
      );

      return;
    }

    if (!this.storeCreditForm.value.commentControl) {
      this.showNotification(
        "top",
        "center",
        "danger",
        "info-circle",
        "Please enter the comment"
      );

      return;
    }

    if (!this.storeCreditForm.value.selectedReasonFormControl) {
      this.showNotification(
        "top",
        "center",
        "danger",
        "info-circle",
        "Please select a reason"
      );

      return;
    }

    this.sendStoreCredit();
  }

  /**
   * passing the customer_id, store credit and comment to a service
   * updated store credit is showing in textAlert popup
   */
  sendStoreCredit(): void {
    const comment: string =
      this.storeCreditForm.value["commentControl"] +
      " " +
      "Order #o|" +
      this.storeCreditForm.value["orderId"];

    this.walletObj.balance_delta = this.storeCreditForm.value.creditControl;
    this.walletObj.message = comment;
    this.walletObj.customer_id = this.customer.entity_id;
    this.walletObj.reason =
      this.storeCreditForm.value.selectedReasonFormControl;

    this.storeCreditService.getData(this.walletObj.serialize()).subscribe(
      (data: GenericServerResponse[]) => {
        const textAlert: MatDialogRef<ModalComponent, any> = this.textAlert(
          data[0].message,
          ""
        );
        textAlert.afterClosed().subscribe(() => {
          this.dialogRef.close(true);
        });
      },
      (err) => this.somethingWentWrong()
    );
  }

  /**
   * 1. Fetch logged user details
   * 2. Set Validators for Store Credit amount
   */
  async getUser(): Promise<void> {
    this.user = await this.store
      .select((state) => state.userState.user)
      .pipe(
        skipWhile((user) => !user),
        take(1)
      )
      .toPromise();

    this.setCreditControlValidator();
  }

  /**
   * 1. Set min and max store credit amount
   * based on the user role.
   * 2. Set Validators for Store Credit amount
   */
  setCreditControlValidator(): void {
    this.minStoreCreditAmount = -1000;
    this.maxStoreCreditAmount = this.user && this.user.isCcManager ? 1000 : 150;

    // Set validators
    this.storeCreditForm.get("creditControl").setValidators([
      Validators.required,
      Validators.max(this.maxStoreCreditAmount),
      Validators.min(this.minStoreCreditAmount)
    ]);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndPoint } from '@endpoint';
import { LoginServices } from '@login';
import { BaseGetProvider, DataTransformationProtocol } from '@tendercuts/http';
import { StoreAddress } from '@tendercuts/models';

@Injectable()
export class StoreAddressService extends BaseGetProvider<StoreAddress>
  implements DataTransformationProtocol {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http);
    this.delegate = this;
  }

  // override this
  preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get isCachable(): boolean {
    return true;
  }

  get isAuthenticatedEndpoint(): boolean {
    return false;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof StoreAddress {
    return StoreAddress;
  }

  get endpoint(): string {
    return this.endpointService.storeAddressEndpoint();
  }
}

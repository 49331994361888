import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { Category, Product } from "@tendercuts/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class SearchService {
  constructor(public store: Store<AppState>) {}

  /**
   * Extracts the list of all products from the catalog in the store.
   * @returns Observable
   */
  private extractProducts(): Observable<Product[]> {
    return this.store
      .select((state) => state.catalog.catalog)
      .pipe(
        map((cat: Category[]) => {
          return cat.map((category) => category.products);
        }),
        // flatten out array of arrays
        map((products: Product[][]) => [].concat(...products)),
      );
  }

  /**
   * Searches the catalog store based on the product name or Product object.
   * @param String | Product searchTerm
   * @returns Observable<Product[]>
   */
  public searchProducts(searchTerm: string): Observable<Product[]> {
    const products: Observable<Product[]> = this.extractProducts();

    const filterFn: (product: any) => boolean = (product) =>
      product.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;

    return products.pipe(
      map((product: Product[]) => product.filter(filterFn)),
    );
  }
}

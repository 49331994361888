import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Input } from "@angular/core";
import { Filter, FilterGroup } from "src/models";
import { SaleOrderDataSource } from "../data-source";

@Component({
  selector: "app-store-segment-filter",
  templateUrl: "./store-segment-filter.component.html",
  styleUrls: ["./store-segment-filter.component.scss"],
})
export class StoreSegmentFilterComponent implements OnInit {
  saleOrderDataSource: SaleOrderDataSource;
  areaFilterGroup: FilterGroup;
  slotFilterGroup: FilterGroup;

  slotCountMap: {} = {};

  /**
   * Flag -  if set will disable title display
   */
  @Input() noTitle: boolean = false;

  /**
   * Flag - if set we don't display the order count in the chips.
   */
  @Input() noCount: boolean = false;

  /**
   * Assumption - data source has the first filter as aread and second
   * as slots
   * @param source
   */
  @Input() set dataSource(source: SaleOrderDataSource) {
    if (!source) {
      return;
    }
    this.saleOrderDataSource = source;
    this.areaFilterGroup = source.filterModel.filterGroups[1];
    this.slotFilterGroup = source.filterModel.filterGroups[2];

    // TODO: Add assertions here.
    this.slotFilterGroup.filters.forEach((filter) => {
      this.slotCountMap[filter.displayName] = this.orderCount(filter);
    });
  }
  // TODO
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() private onFilterApplied: EventEmitter<any> = new EventEmitter();

  /**
   * Triggered when we right click on the segment
   */
  // TODO
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() private onSegmentHelp: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  /**
   * For the slots chips, we apply the filter dynamically into the
   * filter framework and extract the count and revert the filter back.
   *
   * @param filter
   */
  orderCount(filter: Filter): number | string {
    if (this.noCount) {
      return "-";
    }

    filter.selected = true;
    this.saleOrderDataSource.filter = "true";

    const count: number = this.saleOrderDataSource.filteredData.length;

    filter.selected = false;
    this.saleOrderDataSource.filter = "true";

    return count;
  }
}

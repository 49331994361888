/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/naming-convention */
import { Serializable } from "./base";

export class SocialMediaFeed implements Serializable<SocialMediaFeed> {
  public platform: string;
  public thumbnail: string;
  public id: number;
  private post_link: string;

constructor() {}

  deserialize(input: any): this {
    this.platform = input.platform;
    this.post_link = input.post_link;
    this.thumbnail = input.thumbnail;
    this.id = input.id;

    return this;
  }

  get postLink(): string {
    return this.post_link;
  }
}

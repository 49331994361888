<mat-progress-bar *ngIf="(catalogState | async).loading" mode="indeterminate">
</mat-progress-bar>
<responsive-breakpoints></responsive-breakpoints>
<div *ngIf="checkForAccessTime(); else timeout">
  <div
    class="row inventory d-flex m-2 justify-content-center align-items-center"
    *ngIf="productDataSources.length > 0; else noItems"
  >
    <mat-card class="col-5">
      <mat-card-title>
        Inventory Update -
        <span *ngFor="let store of (stores | async)?.availableStores">
          <span *ngIf="selectedStoreId == store.store_id">
            {{ store.storeAttribute?.displayName | uppercase }}
          </span>
        </span>
      </mat-card-title>
      <mat-card-content>
        <mat-list>
          <mat-list-item
            class="row"
            *ngFor="let category of productDataSources; let i = index"
          >
            <div class="col-5 {{ category.key }}">
              {{ category.key }}
            </div>
            <button
              (click)="openInventoryUpdateDialog(category)"
              id="category{{ i }}"
              mat-raised-button
              color="primary"
              class="ml-2"
            >
              Enter Stock on hand
              <b>AFTER DUMP</b>
            </button>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </div>

  <ng-template #noItems>
    <div style="margin: 10%">
      <h2 style="text-align: center">No products to display</h2>
    </div>
  </ng-template>
</div>
<ng-template #timeout>
  <div style="margin: 10%">
    <h2 style="text-align: center">
      Stock Adjustment is available only between 8:00 PM - 11:59 PM
    </h2>
  </div>
</ng-template>

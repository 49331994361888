import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { StoreState } from "@store/store/state";
import { DriverLoginLogout, TripSheet } from "@tendercuts/models";
import { TripSheetGenerateService } from "@tendercuts/providers";
import { Subscription } from "rxjs";
import { skipWhile, take } from "rxjs/operators";
import {
  DriverDataSource,
  MatChipAction,
  TableRowAction,
} from "src/app/components";
import { BasePage, ModalComponent } from "src/app/utils";
import { Filter, FilterGroup, FilterMatch, FilterModel } from "src/models";
import { FetchStoreDriversService } from "src/providers/fetch-store-drivers";
import { TripSheetWizardComponent } from "../trip-sheet-wizard";

/**
 * Component to show all srote riders datasource
 */
@Component({
  selector: "app-driver-dashboard",
  templateUrl: "./driver-dashboard.component.html",
  styleUrls: ["./driver-dashboard.component.scss"],
})
export class DriverDashboardComponent
  extends BasePage
  implements OnInit, OnDestroy {
  // selected store riders
  drivers: DriverLoginLogout[] = [];
  // selected Rider
  driver: DriverLoginLogout;
  @Input() columnsToDisplay: string[] = [
    "driverId",
    "firstName",
    "phoneNumber",
    "checkIn",
    "checkOut",
  ];

  @Input() titleToDisplay: string[] = [
    "Driver Id",
    "Driver Name",
    "Phone Number",
    "Login Time",
    "LogOut Time",
  ];

  tripSheet: TripSheet;

  // if tripsheet already confirmed wizard move to tripsheet page
  wizardIndex: number = 0;

  // action call for row click
  @Input()
  rowClickCallback: TableRowAction = new TableRowAction(
    this.showEarnings.bind(this),
  );

  // param to show column as mat chip
  @Input() actions: MatChipAction[] = [
    new MatChipAction("Status", "tripSheetStatus", null),
  ];

  // date for show the drivers. initially today's date
  selectedDate: Date = new Date();

  // date for max check in date picker
  todayDate: Date = new Date();

  driverSearchFilterGroup: FilterGroup = new FilterGroup([
    new Filter(
      "Search",
      null,
      ["firstName", "phoneNumber", "driverId"],
      FilterMatch.CONTAINS,
    ),
  ]);

  // To filter trip sheet status
  tripStatusFilterGroup: FilterGroup = new FilterGroup([
    new Filter("Pending", "Pending", "tripSheetStatus"),
    new Filter("To Approve", "To Approve", "tripSheetStatus"),
    new Filter("Confirm", "Confirm", "tripSheetStatus"),
  ]);

  modelFilter: FilterModel = new FilterModel([
    this.tripStatusFilterGroup,
    this.driverSearchFilterGroup,
  ]);

  driverDataSource: DriverDataSource = new DriverDataSource(
    this.modelFilter,
    [],
  );

  formatDate: string;
  routerEventSubcriber: Subscription;

  constructor(
    public router: Router,
    public title: Title,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public fetchStoreDrivers: FetchStoreDriversService,
    public generateTripsheetService: TripSheetGenerateService,
    public route: ActivatedRoute,
  ) {
    super(dialog, snackBar, store);
  }

  /**
   * listen the route params change if the there is any change in the route
   * parameter it will fetch the data according to the activated route value
   */
  ngOnInit(): void {
    this.routerEventSubcriber = this.router.events.subscribe((data) => {
      if (data instanceof NavigationEnd) {
        this.fetchData();

        return;
      }
    });
    this.fetchData();
  }

  ngOnDestroy(): void {
    if (this.routerEventSubcriber) {
      this.routerEventSubcriber.unsubscribe();
    }
  }

  /*
   * Fetching data according to the current router value
   */
  fetchData(): void {
    const routeParam: string | number | Date = this.route.snapshot.params["date"];
    this.title.setTitle("Trip Sheets");
    if (routeParam) {
      this.selectedDate = new Date(routeParam);
      this.fetchStoreData();

      return;
    }
    this.selectedDate = new Date();
    this.fetchStoreData();
  }

  /**
   * send the selected date to the router
   * it will redirect the page to the current dashboard
   * and load the data according to the selected date
   * @param $event
   */
  onDateSelect($event: MatDatepickerInputEvent<Date> | any): void {
    this.formatDate = $event.value
      ? this.dateFormat($event.value)
      : this.dateFormat($event);
    this.router.navigate(["/dashboard/generate-trip-sheet/" + this.formatDate]);
  }

  /**
   * To send the format date to the route
   * MM/DD/YYYY
   */
  dateFormat(date: Date): string {
    return (
      date.getMonth() + 1 + "-" + date.getDate() + "-" + date.getFullYear()
    );
  }

  /**
   * fetch redux store data
   */
  async fetchStoreData(): Promise<void> {
    const storeState$: StoreState = await this.store
      .select((state) => state.storeState)
      .pipe(
        skipWhile((state) => state.loading == true),
        take(1),
      )
      .toPromise();

    this.fetchDrivers(storeState$.store);
  }

  /**
   * fetch the drivers data from api
   * and assigning to driverdata source
   * @param storeId
   */
  fetchDrivers(storeId: number): MatDialogRef<ModalComponent, any> {
    if (storeId == undefined) {
      return this.textAlert(
        "Please select store",
        "To load Driver's Trip Sheet",
      );
    }

    this.presentLoader();
    const params: {
      store_id: string;
      date: string;
    } = this.fetchStoreDrivers.getParams(storeId, this.selectedDate);
    this.fetchStoreDrivers
      .getData(params)
      .subscribe((data: DriverLoginLogout[]) => {
        this.dismissLoader();
        this.drivers = data;
        this.driverDataSource.selection.clear();
        this.driverDataSource.data = this.drivers;

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (error: any) => {
          this.dismissLoader();
          this.somethingWentWrong();
        };
      });
  }

  /**
   * search filter based on the search value
   * @param searchString
   */
  applyDriverSearchFilter(searchString: string): void {
    if (searchString === "") {
      this.driverSearchFilterGroup.filters[0].selected = false;
      this.driverSearchFilterGroup.filters[0].value = null;
    } else {
      this.driverSearchFilterGroup.filters[0].selected = true;
      this.driverSearchFilterGroup.filters[0].value = searchString;
    }
    this.driverDataSource.filter = "true";
  }

  /**
   * showing the trip sheet component
   * @param driver
   */
  showEarnings(event: any, driver: DriverLoginLogout): void {
    if (!driver.isRiderLogout) {
      this.showNotification(
        "bottom",
        "center",
        "danger",
        "info-circle",
        "Rider Not Yet Logged Out",
      );

      return;
    }

    this.driver = driver;

    if (driver.isTripSheetConfirm) {
      this.showTripSheetDetails();

      return;
    }

    this.wizardIndex = 0;
    this.tripSheet = null;
    this.openTripSheetWizard();
  }

  // redirect to open trip sheet details
  showTripSheetDetails(): void {
    this.router.navigate([
      "dashboard/tripsheet-detail/" +
        this.dateFormat(this.selectedDate) +
        "/" +
        this.driver.driverId,
    ]);
  }

  /**
   * open the trip sheet wizard with the stepper index
   */
  openTripSheetWizard(): void {
    const data: {
      selectedRider: DriverLoginLogout;
      selectedDate: Date;
      tripSheet: TripSheet;
      stepperIndex: number;
    } = {
      selectedRider: this.driver,
      selectedDate: this.selectedDate,
      tripSheet: this.tripSheet,
      stepperIndex: this.wizardIndex,
    };
    const dialog: MatDialogRef<
      TripSheetWizardComponent,
      any
    > = this.dialog.open(TripSheetWizardComponent, {
      width: "1124px",
      height: "810px",
      panelClass: "earning",
      disableClose: true,
      data,
    });

    dialog.afterClosed().subscribe((status) => {
      if (status) {
        this.fetchStoreData();
      }
    });
  }

  // Onclicking over matchip data is filtered
  getFilteredData(filter: Filter): void {
    filter.selected = !filter.selected;
    this.driverDataSource.filter = "true";
  }
}

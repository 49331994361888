<ng-container class="slab" *ngIf="weeklyTripSheet">
  <p class="slab__header">Orders: {{ selectedDayOrderCount }}</p>
  <div class="row no-gutters slab-text_center">
    <ng-container *ngFor="let slab of slabs; let last = last; let i = index">
      <div class="slab-text_center slab__col">
        <i
          id="{{ slab.slab_name }}"
          class="fa fa-check-circle"
          [ngClass]="{
            slab__green: slab['target_' + day] <= selectedDayOrderCount,
            slab__gray: slab['target_' + day] > selectedDayOrderCount
          }"
        ></i>
        <div class="slab__name">
          <div>{{ slab["target_" + day] }}</div>
        </div>
      </div>
      <div class="slab__col slab__pipe" *ngIf="!last" center>
        <hr
          [ngClass]="{
            'rm-margin': slab['target_' + day] > selectedDayOrderCount
          }"
          class="slab__track_line"
        />
      </div>
    </ng-container>
  </div>
</ng-container>

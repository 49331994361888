import { Serializable } from "./base";

export class WalletTransaction implements Serializable<WalletTransaction> {
    public balance_amount: number;
    public balance_delta: number;
    public is_notified: string;
    public created_at: Date;
    public transaction_id: number;
    public message: string;
    public updated_at: Date;
    public action: string;
    public customer_id: string;
    public reason: string;
    private updated_by: string;
    private order_id: string;


    get createdAt() { return this.created_at; }
    get updatedAt() { return this.updated_at; }
    get updatedBy() { return this.updated_by; }

    get orderId(): string {
      return this.order_id;
    }

    constructor() { }

    serialize() {
        return {
          customer_id: this.customer_id,
          balance_delta: this.balance_delta,
          message: this.message,
          reason: this.reason,
        };
      }

    deserialize(input) {
        this.balance_amount = input.balance_amount;
        this.balance_delta = input.balance_delta;
        this.is_notified = input.is_notified;
        this.created_at = new Date(input.created_at);
        this.transaction_id = input.transaction_id;
        this.message = input.message;
        this.updated_at = new Date(input.updated_at);
        this.action = input.action;
        this.reason = input.reason;
        this.updated_by = input.updated_by;
        this.order_id = input.order_id;

        return this;
    }
}

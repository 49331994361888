<h5>Select Reason to Hold the Order</h5>
<form id="holdOrder-form">
  <mat-form-field>
    <mat-select
      placeholder="choose option"
      [(value)]="selected"
      [formControl]="holdOrderControl"
    >
      <mat-option *ngFor="let option of options" [value]="option.value">
        {{ option.viewValue }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
<div class="item-checks" *ngIf="holdOrderControl.value === 'hold_oos'">
  <mat-label class="row"><b>Select out of stock items:</b></mat-label>
  <ng-container *ngFor="let item of order.items">
    <mat-checkbox
      class="row"
      name="item"
      *ngIf="item.isSimple"
      value="{{ item.productId }}"
      (change)="onItemSelect($event, item)"
      >{{ item.name }}</mat-checkbox
    >
  </ng-container>
</div>

<div id="buttons" *ngIf="selected != null">
  <hr />
  <h6>Are you sure you want to hold the order</h6>
  <button color="primary" mat-raised-button mat-dialog-close>Cancel</button>
  <button
    id="hold-btn"
    color="primary"
    mat-raised-button
    (click)="proceedToHold()"
  >
    Hold
  </button>
</div>

import { Injectable } from "@angular/core";

import { BaseGetProvider } from "@tendercuts/http";
import { GenericServerResponse } from "@tendercuts/models";

import { HttpClient } from "@angular/common/http";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";

@Injectable({
  providedIn: "root",
})
export class DriverLogoutService extends BaseGetProvider<GenericServerResponse> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof GenericServerResponse {
    return GenericServerResponse;
  }

  get endpoint(): string {
    return this.endpointService.logoutDriver();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(
    driverId: number,
  ): {
    user_id: string;
  } {
    const params: {
      user_id: string;
    } = {
      user_id: driverId.toString(),
    };

    return params;
  }
}

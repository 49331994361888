import { Serializable } from "../base";
export class NewsArticle implements Serializable<NewsArticle> {
  private sort_order: number;
  private is_active: boolean;
  public id: number;
  public image: string;
  public title: string;
  public url: string;

  constructor() {}

  deserialize(input) {
    this.id = input.id;
    this.image = input.image;
    this.is_active = input.is_active;
    this.sort_order = input.sort_order;
    this.title = input.title;
    this.url = input.url;

    return this;
  }

  get sortOrder(): number {
    return this.sort_order;
  }

  get isActive(): boolean {
    return this.is_active;
  }
}

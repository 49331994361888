import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SaleOrder } from "@tendercuts/models";

@Component({
  selector: "app-trip-order-table",
  templateUrl: "./trip-order-table.component.html",
  styleUrls: ["./trip-order-table.component.scss"],
})
export class TripOrderTableComponent implements OnInit {
  saleOrders: SaleOrder[] = [];

  @Input() availableOrdersMode: boolean = false;

  @Input() set orders(orders: SaleOrder[]) {
    this.saleOrders = orders.filter((order: SaleOrder) => !order.isPickup);
    if (this.availableOrdersMode) {
      this.saleOrders.sort((orderA, orderB) => {
        if (orderA.timeRemaining < orderB.timeRemaining) {
          return -1;
        }

        return 1;
      });
    }
  }
  // TODO
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onOrderClicked: EventEmitter<SaleOrder> = new EventEmitter();
  @Output() addOrderToTrip: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeOrderFromTrip: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  addOrder(order: SaleOrder): void {
    this.addOrderToTrip.emit(order.incrementId);
  }

  removeOrder(order: SaleOrder): void {
    this.removeOrderFromTrip.emit(order.incrementId);
  }
}

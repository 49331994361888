
export class GenericServerResponse {
    result: string;
    message: string;
    status: boolean;


    deserialize(input) {
        this.result = input.result;
        this.message = input.message;
        this.status = input.status;
       
        return this;
    }
}

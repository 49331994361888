<div class="row header fixed-top no-gutters">
  <div>
    <h5><b> Driver Trip Details </b></h5>
  </div>
  <div class="trip-action-buttons" *ngIf="!isTripDetailsMode">
    <app-trips-action-buttons [trip]="trip" id="trip-action-btn" (reloadParent)="handleOrderActions()">
    </app-trips-action-buttons>
  </div>
</div>
<br />
<div class="trip-actions" *ngIf="trip">
  <div class="row col-12 trip-details">
    <div class="col-4">
      <mat-card>
        <mat-card-title>Driver Details</mat-card-title>
        <mat-card-content>
          <div class="row no-gutters">
            <span class="label col-6">Driver Number</span>
            <span class="value col-6">
              <a class="phone" (click)="redirectToRiderCrm()">{{ trip.tripDriverPhoneNumber }}
              </a></span>
          </div>

          <div class="row no-gutters">
            <span class="label col-6">Date</span>
            <span class="value col-6">{{
              trip.tripDate | date: "MMM d, h:mm a"
              }}</span>
          </div>

          <div class="row no-gutters">
            <span class="label col-6">Trip Assigned Time</span>
            <span class="value col-6">{{ trip.tripAssignedTime }}</span>
          </div>

          <div class="row no-gutters">
            <span class="label col-6">Trip Start Time</span>
            <span class="value col-6">{{ trip.tripStartedTime }}</span>
          </div>

          <div class="row no-gutters">
            <span class="label col-6">Trip End Time</span>
            <span class="value col-6" *ngIf="trip.isCompleted; else notFinished">{{ trip.endTime }}</span>
            <ng-template #notFinished>
              <span class="value col-6">IN-PROGRESS</span>
            </ng-template>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-4">
      <mat-card>
        <mat-card-title>Trip Details</mat-card-title>
        <mat-card-content>
          <div class="row no-gutters">
            <span class="label col-6">Trip Status</span>
            <span class="value col-6">{{ trip.state }}</span>
          </div>

          <div class="row no-gutters">
            <span class="label col-6">Store Name</span>
            <span class="value col-6">{{
              trip.driverOrder[0].saleOrder?.storeName | uppercase
              }}</span>
          </div>

          <div class="row no-gutters">
            <span class="label col-6">Total Orders</span>
            <span class="value col-6">{{ trip.driverOrder?.length }}</span>
          </div>

        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-4">
      <mat-card>
        <mat-card-title>Trip Cash Details</mat-card-title>
        <mat-card-content>

          <div class="row no-gutters">
            <span class="label col-6">Cash On Delivery(A)</span>
            <span class="value col-6">{{ trip.cash?.codAmount }}</span>
          </div>

          <div class="row no-gutters">
            <span class="label col-6">Wallet Amount(B)</span>
            <span class="value col-6">{{ trip.cash?.walletAmount }}</span>
          </div>

          <div class="row no-gutters">
            <span class="label col-6">Advance Cash(C)</span>
            <span class="value col-6">{{ trip.cash?.changeAmount }}</span>
          </div>

          <div class="row no-gutters">
            <span class="label col-6">Total Amount(A+B+C)</span>
            <span class="value col-6">{{ trip.cash?.totalAmount }}</span>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <br />

  <div class="col-12 trip-progress">
    <mat-card>
      <mat-card-content>
        <div class="row header">
          <p class="col-6 title" *ngIf="!trip.driver && trip.state == 'Saved Trips';
              else showDriverTrip
            ">
            Not Yet Assigned - ({{ trip.driverOrder?.length }} Orders)
            <mat-chip *ngIf="trip.driverTag" class="driver-tag" (click)="editDummyTripName(trip)">
              {{ trip.driverTag }} 
              <mat-icon class="driver-tag__edit">create</mat-icon>
            </mat-chip>
            <mat-chip *ngIf="trip.isSequence == 0" class="is-sequence">
              Not Sequenced <i class="fa fa-exclamation-circle sequence"></i>
            </mat-chip>
          </p>
          <ng-template #showDriverTrip>
            <p class="col-6 title">
              {{ trip.tripDriverName }} - (
              {{ trip.sequencedOrders?.length }} Pending Orders ) - (
              {{ trip.driverOrder?.length }} Total Orders )

              <mat-chip *ngIf="trip.isSequence == 0" class="is-sequence">
                Not Sequenced <i class="fa fa-exclamation-circle sequence"></i>
              </mat-chip>
            </p>
          </ng-template>

          <p class="col-3">
            <b>TRIP ID: {{ trip.tripId }}</b>
          </p>
        </div>

        <div class="cash-details row">
          <p class="col-2">
            <i class="fa fa-rupee" aria-hidden="true"></i>
            {{ trip.cash?.codAmount }} (COD)(<b>A</b>)
          </p>
          <p class="col-2">
            <i class="fa fa-rupee" aria-hidden="true"></i>
            {{ trip.cash?.walletAmount }} (WALLET)(<b>B</b>)
          </p>
          <p class="col-2">
            <i class="fa fa-rupee" aria-hidden="true"></i>
            {{ trip.cash?.changeAmount }} (ADVANCE CASH)(<b>C</b>)
          </p>
          <p class="col-2">
            <i class="fa fa-rupee" aria-hidden="true"></i>
            {{ trip.cash?.totalAmount }} (TOTAL)(<b>A+B+C</b>)
          </p>
          <p class="col-2" *ngIf="trip.isTripCompleted && trip.cash">
            <i class="fa fa-rupee" aria-hidden="true"></i>
            {{ trip.cash?.shortageAmount }} (Remaining Amount)
          </p>
        </div>
        <mat-divider></mat-divider>

        <div class="trip-time row">
          <p class="col-2">
            <i class="fa fa-clock-o" aria-hidden="true"></i>
            {{ trip.tripStartedTime }}
          </p>
          <p class="col-2" *ngIf="trip.isCompleted; else notYetFinished">
            <i class="fa fa-check-circle-o" aria-hidden="true"></i>
            {{ trip.endTime }} - {{ trip.endDate | date: "MMM d, h:mm a" }}
          </p>
          <ng-template #notYetFinished>
            <p class="col-2">
              <i class="fa fa-check-circle-o" aria-hidden="true"></i>
              IN PROGRESS
            </p>
          </ng-template>
        </div>
        <div class="order-sequence">
          <app-order-id-sequence [trip]="trip"></app-order-id-sequence>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <br />

  <div class="cash-details">
    <div class="row col-12">
      <div class="cash-splitup col-6">
        <app-trip-cash-splitup [tripData]="trip"></app-trip-cash-splitup>
      </div>
      <div class="cash-transactions col-6">
        <app-trip-cash-transactions [trip]="trip"></app-trip-cash-transactions>
      </div>
    </div>
  </div>
  <div class="trip-progress">
    <div class="row col-12">
      <div class="trip-progress col-4">
        <app-trip-status [trip]="trip"> </app-trip-status>
      </div>

      <div class="col-4">
        <mat-card class="checkin-checkout">
          <mat-card-title>Trip Login Logout</mat-card-title>
          <mat-card-content class="row no-gutters checkin-checkout__content">
            <div *ngIf="trip.checkInUrl" class="col-3 checkin-checkout__content__checkin">
              <img class="checkin-checkout__content__checkin__img" (click)="showSpeedoMeterImage(trip.checkInUrl)"
                [src]="trip.checkInUrl" alt="" />
            </div>
            <div class="col-5 checkin-checkout__content__km">
              <span class="row no-gutters checkin-checkout__content__km__checkin-title">
                Check In Value</span>
              <span class="row no-gutters checkin-checkout__content__km__checkin-value">
                <span>{{ trip.checkInValue }} </span>
              </span>
              <span class="row no-gutters checkin-checkout__content__km__checkout-title">
                Check Out Value</span>
              <span class="row no-gutters checkin-checkout__content__km">
                <span>{{ trip.checkOutValue }} </span>
              </span>
            </div>
            <div *ngIf="trip.checkOutUrl" class="col-3 checkin-checkout__content__checkout">
              <img class="checkin-checkout__content__checkout__img" (click)="showSpeedoMeterImage(trip.checkOutUrl)"
                [src]="trip.checkOutUrl" alt="" />
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
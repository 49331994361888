import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import * as selectors from "@store/state";
import { AppState } from "@store/state";
import { ActionGetStores, StoreState } from "@store/store";
import { BookingRequest, RiderProjection, User } from "@tendercuts/models";
import {
  BookingCountService,
  BookingStatusService,
  CancelBookingRequestService,
} from "@tendercuts/providers";
import { Observable } from "rxjs";
import { Filter, FilterGroup, FilterMatch, FilterModel } from "src/models";
import { BookingDetailsDataSource } from "../../components";
import { BasePage } from "../../utils";

/**
 * To display Rider status for the slot
 */
@Component({
  // TODO
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "booking-status",
  templateUrl: "./booking-status.component.html",
  styleUrls: ["./booking-status.component.scss"],
})
export class BookingStatusComponent
  extends BasePage
  implements OnInit, OnDestroy {
  @Input() bookingStatusDetails: BookingRequest[] = [];
  @Input() acceptedCount: RiderProjection[];
  selectedStoreId: any;

  // To display display driverId hyperlink
  isRedirectToCrm: boolean = true;

  /**
   * Search driver name and phone number
   */
  bookingSearchFilterGroup: FilterGroup = new FilterGroup([
    new Filter(
      "Search",
      null,
      ["driverDetails.firstName", "driverDetails.phoneNumber"],
      FilterMatch.CONTAINS,
    ),
  ]);

  // To filter booking status
  bookingStatusFilterGroup: FilterGroup = new FilterGroup([
    new Filter("Pending", "pending", "bookingStatus"),
    new Filter("Rejected", "reject", "bookingStatus"),
    new Filter("Confirmed", "confirm", "bookingStatus"),
    new Filter("Present", "Present", "driverAttendance"),
    new Filter("Absent", "Absent", "driverAttendance"),
    new Filter("First Shift", "First Shift", "shiftStatus"),
    new Filter("Second Shift", "Second Shift", "shiftStatus"),
    new Filter("Full shift", "Full shift", "shiftStatus"),
  ]);

  modelFilter: FilterModel = new FilterModel([
    this.bookingStatusFilterGroup,
    this.bookingSearchFilterGroup,
  ]);

  bookingDataSource: BookingDetailsDataSource = new BookingDetailsDataSource(
    this.modelFilter,
    [],
  );

  stores$: any;
  constructor(
    private title: Title,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public router: Router,
    private bookingCountService: BookingCountService,
    private bookingStatusService: BookingStatusService,
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    this.title.setTitle("Booking Status");
    this.store.dispatch(new ActionGetStores());
    this.stores.subscribe((state: StoreState) => {
      if (this.selectedStoreId != state.store) {
        this.selectedStoreId = state.store;
        this.bookingCount();
        this.bookingStatusDetail();
      }
    });
  }

  /**
   * To get selected store details
   */
  get stores(): Observable<StoreState> {
    return this.store.select(selectors.getStoreState);
  }

  /**
   * sort the table
   */
  applyDriverSearchFilter(searchString: string): void {
    if (searchString === "") {
      this.bookingSearchFilterGroup.filters[0].selected = false;
      this.bookingSearchFilterGroup.filters[0].value = null;
    } else {
      this.bookingSearchFilterGroup.filters[0].selected = true;
      this.bookingSearchFilterGroup.filters[0].value = searchString;
    }
    this.bookingDataSource.filter = "true";
  }

  /**
   * to get booking count overall
   */
  bookingCount(): void {
    const paramsData: {
      store_id: string;
    } = this.bookingCountService.getParams(this.selectedStoreId);
    this.bookingCountService
      .getData(paramsData)
      .subscribe((count: RiderProjection[]) => (this.acceptedCount = count));
  }

  /**
   * Displays complete rider status details
   */
  bookingStatusDetail(): void {
    const params: {
      store_id: number;
    } = this.bookingStatusService.getparams(this.selectedStoreId);
    this.bookingStatusService
      .getData(params)
      .subscribe((data: BookingRequest[]) => {
        this.bookingStatusDetails = data;
        this.bookingDataSource.selection.clear();
        this.bookingDataSource.data = this.bookingStatusDetails;
      });
  }

  // Onclicking over matchip data is filtered
  getFilteredData(filter: Filter): void {
    filter.selected = !filter.selected;
    this.bookingDataSource.filter = "true";
  }

  // unsubcribe the store data
  ngOnDestroy(): void {
    if (this.stores$) {
      this.stores$.unsubscribe();
    }
  }

  /**
   * Get confirm driver count
   */
  get confirmDriverCount(): number {
    return this.bookingStatusDetails.filter(
      (confirmCount: BookingRequest) => confirmCount.bookingStatus == "confirm",
    ).length;
  }

  /**
   * Get First shift count
   */
  get firstShiftCount(): number {
    return this.bookingStatusDetails.filter(
      (confirmCount: BookingRequest) =>
        confirmCount.bookingStatus == "confirm" &&
        confirmCount.shiftStatus == "First Shift",
    ).length;
  }

  /**
   * Get second shift count
   */
  get secondShiftCount(): number {
    return this.bookingStatusDetails.filter(
      (confirmCount: BookingRequest) =>
        confirmCount.bookingStatus == "confirm" &&
        (confirmCount.shiftStatus == "Second Shift" ||
          confirmCount.shiftStatus == "Full shift"),
    ).length;
  }

  /**
   * Get full shift count
   */
  get fullShiftCount(): number {
    return this.bookingStatusDetails.filter(
      (confirmCount: BookingRequest) =>
        confirmCount.bookingStatus == "confirm" &&
        (confirmCount.shiftStatus == "Full shift" ||
          confirmCount.shiftStatus == "Second Shift"),
    ).length;
  }

  /**
   * Get rider present with confirm count
   */
  get driverPresentCount(): number {
    return this.bookingStatusDetails.filter(
      (presentCount: BookingRequest) => presentCount.isShow,
    ).length;
  }

  /**
   * refresh the booking details after emit the edit booking
   * @param $event
   */
  refreshParent($event: any): void {
    this.bookingCount();
    this.bookingStatusDetail();
  }
}

import { Component, Input, OnInit } from "@angular/core";
import { Driver } from "@tendercuts/models";

@Component({
  selector: "app-store-rider",
  templateUrl: "./store-rider.component.html",
  styleUrls: ["./store-rider.component.scss"],
})
export class StoreRiderComponent implements OnInit {
  @Input()
  driver: Driver;

  constructor() {}

  ngOnInit(): void {}

  // Rider status for stores readable format
  get driverStatus(): string {
    switch (this.driver.status) {
      case 0:
      case 6:
        return "available";

      case 1:
      case 2:
      case 3:
        return "ontrip";

      default:
        return "";
    }
  }
}

export class OrderTags {
  public rating: number;
  public id: number;
  public isSeleted: boolean;
  private rating_category: number;
  private tag_name: string;

  deserialize(input) {
    this.tag_name = input.tag_name;
    this.rating = input.threshold;
    this.id = input.id;
    this.rating_category = input.rating_category;

    return this;
  }

  get tagName() {
    return this.tag_name;
  }

  get ratingCategory() {
    return this.rating_category;
  }

  get isDeliveryTag() {
    return this.ratingCategory === 1;
  }

  get isProductTag() {
    return this.ratingCategory === 2;
  }

  get isStoreTag() {
    return this.ratingCategory === 4;
  }

  get isAgentTag() {
    return this.ratingCategory === 5;
  }
}

import { Serializable } from "../base";

export class Notification implements Serializable<Notification> {

  private notified_at: Date;
  private is_read: boolean;
  private notification_type: string;

  public message: string;
  public choices: string;
  public title: string;

  constructor() {}

  deserialize(input) {
    this.message = input.message;
    this.is_read = input.is_read;
    this.choices = input.choices;
    this.notification_type = input.notification_type;
    this.notified_at = input.notified_at;
    this.title = input.title;

    return this;
  }

  get notifiedAt() {
    return this.notified_at;
  }

  get isRead() {
    return this.is_read;
  }

  get notificationType() {
    return this.notification_type
  }
}


import { of, Observable } from "rxjs";
import { BaseDataTransformer } from "../transformer";

import { HttpHeaders } from "@angular/common/http";

/**
 * A base HTTP provider that is built around the data transformation hooks
 * Benefits:
 *  1. Uses the transformation protocol
 *  2. Caching of observalbe & data
 *  3. User authentication in case of an isAuthenticatedEndpoint
 *
 * Subclass SHOULD IMPLEMENT generateBaseObservable
 */
export abstract class BaseHttpProvider<T> extends BaseDataTransformer<T> {
  // Data that gets out of the tranformation pipeline
  protected data: any;

  get cache(): any {
    return this.data;
  }

  // getter to pass normal content type
  get contentType(): string {
    return "application/json";
  }

  // HTTP endpoint to hit and its meta
  abstract get isAuthenticatedEndpoint(): boolean;
  // which service to be used for authProtocol
  abstract get authProtocol(): any;
  // url to hit
  abstract get endpoint(): string;
  // model to parse data back
  abstract get model(): any;

  // if set then we need to tag the primary key along
  // with the url
  get isDetailedEndpoint(): boolean {
    return false;
  }

  // applicable only when you set the url to be a detailed
  // endpoint.
  get key(): string {
    return "";
  }

  // should be cached
  get isCachable(): boolean {
    return true;
  }

  protected generateEndpoint(requestBody: any): string {
    if (!this.isAuthenticatedEndpoint) {
      return this.endpoint;
    }

    // to fetch key value from form data
    if (requestBody instanceof FormData) {
      const data: FormDataEntryValue = requestBody.get(this.key);

      return `${this.endpoint}/${data}/`;
    }

    return `${this.endpoint}/${requestBody[this.key]}/`;
  }

  /**
   * Override for http request where repsonse needs to be converted to
   * HTTP
   */
  protected preProcess(response: any): Array<any> {
    // Prior to using angular httpclient, we had to manually process
    // it to json(), in httpclient the default mode is json, causing the
    // response to be autmatically jsonized. if we need to change it
    // then we need to move to "observe" ing the raw body.
    response = response;

    return super.preProcess(response);
  }

  /**
   * Override for http request where repsonse needs to be saved and cached
   */
  protected postProcess(response: any): Array<any> {
    // store the processed data
    this.data = response;

    return super.postProcess(response);
  }

  /**
   * If data is already fetched then return the data/ the.observable
   */
  private fetchFromCache(): Observable<any> {
    if (this.data) {
      return of(this.data);
    } else if (this.observable) {
      return this.observable;
    }

    return undefined;
  }

  /**
   * Default headers for all endpoint,
   * normal content type is passed as application/json
   */
  protected getHeaders(token: string): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();
    if (this.contentType) {
      headers = headers.set("Content-type", this.contentType);
      headers = headers.set("Accept", "application/json");
    }

    if (this.isAuthenticatedEndpoint) {
      headers = headers.set("Authorization", `Token ${token}`);
    }

    return headers;
  }

  /**
   * getData public endpoint
   *
   * Override the getData method to provide caching
   *
   */
  public getData(...args: any[]): Observable<any> {
    if (this.isCachable) {
      const observable: Observable<any> = this.fetchFromCache();

      if (observable) {
        return observable;
      }
    }

    this.observable = super.getData(...args);

    return this.observable;
  }

  /**
   * Cleans up the cache
   */
  public resetData(): void {
    this.data = null;
    this.observable = null;
  }
}

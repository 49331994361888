import { Component, Input } from "@angular/core";
import { Complaint } from "@tendercuts/models";
import { BaseGridComponent } from "../base-grid/base-grid.component";
import { ColumnAction, DateFormatAction } from "../table-actions";

@Component({
  selector: "app-customer-profile-grid",
  templateUrl: "../../components/base-grid/base-grid.component.html",
  styleUrls: ["../../components/base-grid/base-grid.component.scss"],
})
export class CustomerProfileComplainsComponent extends BaseGridComponent {
  @Input()
  columnsToDisplay: string[] = ["orderId", "source", "comments", "rating"];

  @Input()
  titleToDisplay: string[] = ["Order ID", "Source", "Comments", "Rating"];

  @Input() actions: ColumnAction[] = [
    new DateFormatAction("Date", "created_at", this.getDateFormat.bind(this)),
  ];

  getDateFormat(event: any, complaint: Complaint): string {
    return "mediumDate";
  }
}

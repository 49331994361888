import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";

import { AppState } from "@store/state";

import { InventoryRequest, Product } from "@tendercuts/models";
import { InventoryRequestService } from "@tendercuts/providers";

import { ActionCatalogLoad } from "@store/catalog";
import { Observable } from "rxjs";
import { map, skipWhile } from "rxjs/operators";
import { Filter, FilterGroup, FilterMatch, FilterModel } from "src/models";
import { ButtonAction, ProductDataSource } from "../../components";
import { BasePage } from "../../utils";

@Component({
  selector: "app-stock-dashboard",
  templateUrl: "./stock-dashboard.component.html",
  styleUrls: ["./stock-dashboard.component.scss"],
})
export class StockDashboardComponent extends BasePage implements OnInit {
  buttonActions: ButtonAction[] = [
    new ButtonAction("Out of Stock", "oos", this.submitRequest.bind(this)),
  ];

  searchFilterGroup: FilterGroup = new FilterGroup([
    new Filter("Search", null, ["name", "sku"], FilterMatch.CONTAINS),
  ]);
  modelFilter: FilterModel = new FilterModel([this.searchFilterGroup]);
  products: ProductDataSource = new ProductDataSource(this.modelFilter, []);

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public inventoryRequestService: InventoryRequestService,
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    this.storeState.subscribe((state) => {
      if (!state.store) {
        return;
      }

      this.store.dispatch(new ActionCatalogLoad(state.store.toString()));

      const catalog$: Observable<Product[]> = this.catalogState.pipe(
        skipWhile((catalogState) => catalogState.loading == true),
        map((catalogState) => catalogState.products),
      );

      catalog$.subscribe((products) => {
        if (products) {
          this.products.data = products;
        }
      });
    });
  }

  /**
   * Searches for orderId
   * @param orderId
   */
  applySearchFilter(searchString: string): void {
    if (searchString === "") {
      this.searchFilterGroup.filters[0].selected = false;
      this.searchFilterGroup.filters[0].value = null;
    } else {
      this.searchFilterGroup.filters[0].selected = true;
      this.searchFilterGroup.filters[0].value = searchString;
    }

    this.products.filter = "true";
  }

  /**
   * Attached as a callback to button action.
   * Create a new inventory request model and commit it to server.
   * @param Product product
   */
  submitRequest(product: Product): void {
    this.presentLoader();
    this.selectedStore.subscribe(
      (store) => {
        // Inv request.
        const invRequest: InventoryRequest = new InventoryRequest().deserialize(
          {
            product_id: product.id,
            store_id: store.storeId,
            qty: 0,
          },
        );

        // Trigger request.
        this.inventoryRequestService
          .getData(invRequest)
          .subscribe((status) => this.dismissLoader());
      },
      () => {
        this.dismissLoader();
      },
    );
  }
}

<ng-container *ngIf="profile && customer">
  <div class="customer-container row">
    <mat-card class="customer-container__card">
      <mat-card-title>Customer Information</mat-card-title>
      <mat-card-content>
        <div class="row no-gutters">
          <span class="field col-6">E-Mail</span>
          <span class="value col-6">{{ customer.email }}</span>
        </div>
        <div class="row no-gutters">
          <span class="field col-6">Customer Id</span>
          <span class="value col-6">{{ customer.userId }}</span>
        </div>
        <div class="row no-gutters">
          <span class="field col-6">Phone</span>
          <span class="value col-6">{{ customer.phone }}</span>
        </div>
        <div class="row no-gutters">
          <span class="field col-6">Reward Pts</span>
          <span class="value col-6">{{ customer.reward_points }}</span>
        </div>
        <div class="row no-gutters">
          <span class="field col-6">Store Credit</span>
          <span class="value col-6">{{ customer.store_credit }}</span>
        </div>
        <div class="row no-gutters">
          <span class="field col-6">DND</span>
          <span class="value col-6">
            <mat-checkbox
              #checkBox
              labelPosition="after"
              [checked]="customer.dnd"
              (change)="customerDnd($event)"
            >
            </mat-checkbox
          ></span>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="customer-container__card">
      <mat-card-title>Customer Statistics</mat-card-title>
      <mat-card-content>
        <div class="row no-gutters">
          <span class="field col-6">ATS</span>
          <span class="value col-6">
            {{ profile.ats | currency: "INR":"symbol":"1.0" }}
          </span>
        </div>

        <div class="row no-gutters">
          <span class="field col-6">Lifetime Value</span>
          <span class="value col-6">
            {{ profile.sale_total | currency: "INR":"symbol":"1.0" }}
          </span>
        </div>

        <div class="row no-gutters">
          <span class="field col-6">Lifetime Orders</span>
          <span class="value col-6">
            {{ profile.count_total }}
          </span>
        </div>

        <div class="row no-gutters">
          <span class="field col-6">Customer Since</span>
          <span class="value col-6">
            {{ profile.first_ordered_date | date: mediumDate }}
          </span>
        </div>

        <div class="row no-gutters">
          <span class="field col-6">Last Ordered Date</span>
          <span class="value col-6">
            {{ profile.last_ordered_date | date: mediumDate }}
          </span>
        </div>

        <div class="row no-gutters">
          <span class="field col-6">Avg Orders/Month</span>
          <span class="value col-6">
            {{ profile.ordersPerMonth }}
          </span>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="customer-container__card">
      <mat-card-title>Category Preference</mat-card-title>
      <mat-table #matTable [dataSource]="profile.favoriteCategory" matSort>
        <ng-container matColumnDef="productName">
          <mat-header-cell *matHeaderCellDef> Cat </mat-header-cell>
          <mat-cell *matCellDef="let favoriteCategory">
            <span class="label">{{ favoriteCategory.name }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="orderCount">
          <mat-header-cell *matHeaderCellDef> Orders </mat-header-cell>
          <mat-cell *matCellDef="let favoriteCategory">
            <span class="label">{{ favoriteCategory.count }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="orderValue">
          <mat-header-cell *matHeaderCellDef> Rs.(K) </mat-header-cell>
          <mat-cell *matCellDef="let favoriteCategory">
            <span class="label">{{
              favoriteCategory.v | currency: "INR":"symbol":"1.0"
            }}</span>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columnsToDisplay"></mat-row>
      </mat-table>
    </mat-card>

    <mat-card class="customer-container__card">
      <mat-card-title>Likes</mat-card-title>
      <mat-card-content>
        <div class="row no-gutters">
          <span class="field col-6">Fav. Days</span>
          <span class="value col-6">
            <ng-container *ngFor="let day of profile.favoriteDays">
              {{ day.name }} ({{ day.v }}),
            </ng-container>
          </span>
        </div>

        <div class="row no-gutters">
          <span class="field col-6">Fav. Medium</span>
          <span class="value col-6">
            <ng-container *ngFor="let medium of profile.favoriteMedium">
              {{ medium.name }} ({{ medium.v }}),
            </ng-container>
          </span>
        </div>

        <div class="row no-gutters">
          <span class="field col-6">Replacement Count</span>
          <span class="value col-6">
            {{ profile.replacementOrderCount }}
          </span>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>

<div class="rider" *ngIf="driver && snapDetails">
    <div class="row">
        <div class="rider__photo">
            <img alt="Expert Rider" class="rider__photo__img" src="{{ driver?.profile.thumb }}" />
        </div>
        <div class="rider__details">
            <div class="rider__name">
                {{ driver?.firstName }}
            </div>
            <div class="rider__expertise">
                Rating :
                <span>{{ snapDetails?.ratings | number: "1.0-0" }}</span>
            </div>
            <div class="rider__expertise">
                Orders :
                <span>{{ snapDetails?.orders }}</span>
            </div>
            <div class="rider__expertise">
                Trips :
                <span>{{ snapDetails?.trips }}</span>
            </div>
        </div>
    </div>
</div>
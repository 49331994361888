import { Serializable } from "./base";

export class UserStore implements Serializable<UserStore> {

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private store_id: number;

  deserialize(input: any): UserStore {
    this.store_id = input.store_id;

    return this;
  }

  get storeId(): number {
    return this.store_id;
  }

}

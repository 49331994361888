import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { HyperTrackOrder, SaleOrder, Trips } from "@tendercuts/models";
import { BasePage } from "src/app/utils";
import { HyperTrackOrderService } from "src/providers/hyper-track/hypertrack-order.service";

@Component({
  selector: "app-hyper-track-order",
  templateUrl: "./hyper-track-order.component.html",
  styleUrls: ["./hyper-track-order.component.scss"],
})
export class HyperTrackOrderComponent extends BasePage {
  order: SaleOrder;
  hyperTrackOrder: HyperTrackOrder;
  // to change url to the safe url
  shareurl: SafeResourceUrl;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public dialogRef: MatDialogRef<HyperTrackOrderComponent>,
    protected sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(dialog, snackBar, store);
    if (data) {
      this.order = data.order;
      this.hyperTrackOrder = data.hyperTrackOrder;
      this.shareurl = data.shareUrl;
    }
  }

  dialogClose(): void {
    this.dialogRef.close();
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SaleOrder, Trips } from "@tendercuts/models";

import { HttpParams } from "@angular/common/http";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { DriverTripSearchService } from "../../../../providers";
import { OrderDisplayDialogComponent } from "../../../components/order-display-dialog/order-display-dialog.component";
import { SpeedoMeterImageComponent } from "../../trip-earning-module/speedo-meter-image/speedo-meter-image.component";
import { DummyTripEditComponent } from "../dummy-trip-edit";

@Component({
  selector: "app-trip-detail",
  templateUrl: "./trip-detail.component.html",
  styleUrls: ["./trip-detail.component.scss"],
})
export class TripDetailComponent implements OnInit {
  @Input() trip: Trips;

  @Input() isTripDetailsMode: boolean = false;

  @Output() refreshParentEmiter: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public store: Store<AppState>,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private router: Router,
    private driverTripSearchService: DriverTripSearchService
  ) {}

  ngOnInit(): void {
    this.checkForSaleOrder();
  }

  /** Fn to check for sale order in driver obj if undefined
   * we will fetch from api call
   */
  checkForSaleOrder(): void {
    if (!this.trip.driverOrder.every((order) => order.saleOrder)) {
      this.fetchDriverOrders(this.trip);
    }
  }

  /**
   * @param order Onclicking over Mat-chip Order Details shown
   */
  selectOrder(order: SaleOrder): void {
    this.dialog.open(OrderDisplayDialogComponent, {
      data: {
        order,
        tripDetailsMode: true,
      },
    });
  }

  handleOrderActions(): void {
    this.refreshParentEmiter.emit(1);
  }

  // Function to redirect to rider crm
  redirectToRiderCrm(): void {
    this.dialog.closeAll();
    this.router.navigate([
      "dashboard/driver-crm/" + this.trip.tripDriverPhoneNumber,
    ]);
  }

  // Function to load speedoMeter Image
  showSpeedoMeterImage(source: string): void {
    const dialogRef: MatDialogRef<SpeedoMeterImageComponent, any> =
      this.dialog.open(SpeedoMeterImageComponent, {
        panelClass: "remove-padding",
        data: source,
      });
  }

  /** Fn to fetch sale order details from api if its undefined
   * @param tripId
   * @param tripDriverPhoneNumber is passed
   */
  async fetchDriverOrders(trip: Trips): Promise<void> {
    const params: HttpParams = this.driverTripSearchService.getParams(
      trip.tripId,
      trip.tripDriverPhoneNumber
    );
    this.driverTripSearchService.getData(params).subscribe(
      (trips: Trips[]) => {
        this.trip = trips[0];
      },
      (err) => {}
    );
  }

  /**
   * Fn to open dummy trip name edit component
   * @param trip
   */
  editDummyTripName(trip: Trips): void {
    const editNameDialogRef: MatDialogRef<DummyTripEditComponent, any> =
      this.dialog.open(DummyTripEditComponent, {
        panelClass: "dummy-name-edit",
        data: trip,
        disableClose: true,
        width: "25vw"
      });

    editNameDialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.refreshParentEmiter.emit(1);
      }
    });
  }
}

import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { DriverProfile, GenericServerResponse } from "@tendercuts/models";
import { SuspendDriverService } from "@tendercuts/providers";
import { BasePage } from "src/app/utils";

/*
* Select the option to archive rider
*/
@Component({
  // TODO
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "driver-suspend",
  templateUrl: "./driver-suspend.component.html",
  styleUrls: ["./driver-suspend.component.scss"],
})
export class DriverSuspendComponent extends BasePage implements OnInit {
  driver: DriverProfile;
  options: {
    value: string;
    viewValue: string;
}[] = [
    { value: "Disciplinary", viewValue: "Disciplinary" },
    { value: "Misbehave", viewValue: "Misbehave" },
    { value: "Not_accept", viewValue: "Not accept booking" },
  ];
  // form group controller for all fields
  driverSuspendForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DriverSuspendComponent>,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public suspendDriverService: SuspendDriverService,
    @Inject(MAT_DIALOG_DATA) public data: DriverProfile,
  ) {
    super(dialog, snackBar, store);
    if (data) {
      this.driver = data;
    }
  }

  ngOnInit(): void {
    this.driverSuspendForm = new FormGroup({
      type: new FormControl("", [Validators.required]),
      reason: new FormControl("", [Validators.required]),
    });
  }

  /**
   * update the rider profile in driver crm
   * to block an rider
   */
  suspendRider(): void {
    const params: any = this.suspendDriverService.getParams(
      this.driver.driverId,
      this.driverSuspendForm.value["type"],
      this.driverSuspendForm.value["reason"],
    );

    this.suspendDriverService.getData(params).subscribe(
      (responseData: GenericServerResponse[]) => {
        if (responseData[0].status) {
          this.dialogRef.close(true);
        }

        this.showNotification(
          responseData[0].status ? "top" : "bottom",
          "center",
          responseData[0].status ? "success" : "danger",
          "info-circle",
          responseData[0].message,
        );
      },
      (error) => this.somethingWentWrong(),
    );
  }
}

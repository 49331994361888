<!-- Status Filter Starts -->
<button
  class="status-filter-btn"
  mat-button
  [matMenuTriggerFor]="statusMenu"
  #statusMenuTrigger="matMenuTrigger"
>
  <div class="label">
    <img
      [src]="'assets/' + statusFilterSelectedOption.icon + '.svg'"
      class="status-filter-item-icon"
    />
    <span class="status-filter-item-label ml-2">
      {{ statusFilterSelectedOption?.label }}
    </span>
  </div>
  <mat-icon>arrow_drop_down</mat-icon>
</button>
<mat-menu #statusMenu="matMenu">
  <button
    (click)="onStatusFilterChange(option)"
    *ngFor="let option of statusOptions"
    class="status-filter-item"
    mat-menu-item
    [ngClass]="{
      'item-active': statusFilterSelectedOption.value === option.value
    }"
  >
    <img
      class="status-filter-item-icon"
      [src]="'assets/' + option.icon + '.svg'"
    />
    <span class="status-filter-item-label">
      {{ option.label }}
    </span>
  </button>
</mat-menu>
<!-- Status Filter Ends -->

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BasePostProvider } from "@tendercuts/http";
import { GenerateTripSheetResponse } from "@tendercuts/models";

@Injectable({
  providedIn: "root",
})
export class TripPaymentPhoneNumberService extends BasePostProvider<GenerateTripSheetResponse> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof GenerateTripSheetResponse {
    return GenerateTripSheetResponse;
  }

  get endpoint(): string {
    return this.endpointService.tripSheetPhoneNumber();
  }

  /**
   *
   * @param id
   * @param amount
   */
  getParams(
    phone: number,
  ): {
    mobile: number;
    otp_mode: string;
  } {
    return {
      mobile: phone,
      otp_mode: "DRIVER_PAYMENT",
    };
  }
}

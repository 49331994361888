import { Serializable } from "@tendercuts/models";

export class PosUser implements Serializable<PosUser> {
  public structureId: number;

  public id: number;
  public email: string;
  public phone: string;
  public firstname: string;
  public lastname: string;
  public rewards: number;
  public storeCredit: number;
  public preferredPayment: string = null;
  public rewardsEarningPercentage: number = 0;
  private message: string;
  private groupingId: number;

  constructor() {}

  get entityId(): number {
    return this.structureId;
  }

  set entityId(id: number) {
    this.structureId = id;
  }

  get rewardMessage(): string {
    return this.message;
  }

  get isElite(): boolean {
    return this.groupingId == 4;
  }

  deserialize(input: any): this {
    if (input == undefined) {
      return this;
    }
    this.id = input.id;
    this.structureId = input.entity_id;
    this.email = input.email;
    this.phone = input.phone;
    this.firstname = input.firstname;
    this.lastname = input.lastname;
    this.preferredPayment = input.preferredPayment;
    this.rewards = input.rewards;
    this.rewardsEarningPercentage = 0;
    this.message = input.message;
    this.groupingId = input.group_id;

    return this;
  }

  fireBaseSerialize(): {
    id: number;
    p: string;
    n: string;
  } {
    return {
      id: this.structureId !== undefined ? this.structureId : null,
      p: this.phone !== undefined ? this.phone : null,
      n: this.firstname,
    };
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BaseGetProvider } from "@tendercuts/http";
import { SaleOrder, Serializable } from "@tendercuts/models";

interface OrderHistoryParams {
  from: string;
  to: string;
  status: string;
  city: string;
  page: number;
}

export class OrderHistoryResponse
  implements Serializable<OrderHistoryResponse>
{
  public count: number;
  public previous: string;
  public next: string;
  public results: SaleOrder[];

  deserialize(input) {
    if (!input) {
      return this;
    }
    this.count = input.count;
    this.previous = input.previous;
    this.next = input.next;
    this.results = input.results?.map((order: any) =>
      new SaleOrder().deserialize(order)
    );
    return this;
  }
}

@Injectable({
  providedIn: "root",
})
export class OrderHistoryService extends BaseGetProvider<OrderHistoryService> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices
  ) {
    super(http);
    this.delegate = this;
  }

  postProcessResponse(data: any): OrderHistoryResponse {
    return data?.[0];
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof OrderHistoryResponse {
    return OrderHistoryResponse;
  }

  get endpoint(): string {
    return this.endpointService.orderHistory();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(
    from: string,
    to: string,
    status: string,
    city: string,
    page: number
  ): OrderHistoryParams {
    const params: OrderHistoryParams = {
      from,
      to,
      status,
      city,
      page,
    };

    return params;
  }
}

import { Injectable } from "@angular/core";
import { Store as AppStore } from "@ngrx/store";

import * as fromStore from "@store/state";
import { AppState } from "@store/state";
import { AuthProtocol, BasePostProvider } from "@tendercuts/http";
import { GenericServerResponse, SaleOrder } from "@tendercuts/models";

import { HttpClient } from "@angular/common/http";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";

@Injectable()
export class UpdateAddressService
  extends BasePostProvider<GenericServerResponse>
  implements AuthProtocol {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public store: AppStore<AppState>,
    public loginService: LoginServices,
  ) {
    super(http);
  }

  getToken(): any {
    return this.store.select(fromStore.getUser);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof GenericServerResponse {
    return GenericServerResponse;
  }

  get endpoint(): string {
    return this.endpointService.updateAddress();
  }

  getParams(
    order: SaleOrder,
    place: google.maps.GeocoderResult,
    lat: number,
    lng: number,
    geohash: string,
  ): {
    order_id: string;
    lat: string;
    lng: string;
    street: string;
    geohash: string;
  } {
    const params: {
      order_id: string;
      lat: string;
      lng: string;
      street: string;
      geohash: string;
    } = {
      order_id: order.incrementId,
      lat: lat.toString(),
      lng: lng.toString(),
      street: place.formatted_address,
      geohash,
    };

    // check if we can extract the pincode
    const pincode: google.maps.GeocoderAddressComponent[] = place.address_components.filter(
      (comp) => comp.types.indexOf("postal_code") != -1,
    );

    if (pincode.length > 0) {
      params["pincode"] = pincode[0].short_name;
    }

    return params;
  }
}

import { Serializable } from "../base";

/**
 * Model of referral customers
 */
export class ReferralCustomer implements Serializable<ReferralCustomer> {
  private referral_count: number;
  public username: string;

  constructor() {}

  deserialize(input) {
    this.username = input.username;
    this.referral_count = input.referral_count;

    return this;
  }

  get referralCount() {
    return this.referral_count;
  }
}

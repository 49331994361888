// TODO
/* eslint-disable */
import { Component } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map, skipWhile, take } from "rxjs/operators";

import { CatalogState } from "@store/catalog";
import {
  ActionDriverStoreClear,
  ActionFetchTripConfig,
  ActionGetDrivers,
  ActionGetEveryDrivers,
  ActionGetTrips,
  ActionLoadTripCash,
  ActionStitchTripOrders,
  DriverState,
} from "@store/driver";
import {
  ActionAllOrdersLoaded,
  ActionClearOrders,
  ActionOrderStoreClear,
  ActionRefreshOrders,
  ActionSaveOrders,
  OrderState,
} from "@store/orders";
import * as selectors from "@store/state";
import { AppState } from "@store/state";
import { StoreState } from "@store/store";
import { UserState } from "@store/user";

import { SaleOrder } from "@tendercuts/models";
import { NotificationComponent } from "../notifications/notification/notification.component";
import { ModalComponent } from "./modals/modal.component";
import { SomethingWentWrongComponent } from "./something-went-wrong/something-went-wrong.component";
import { SpinnerComponent } from "./spinner/spinner.component";

@Component({
  template: "",
})
export class BaseComponent {
  constructor(public store: Store<AppState>) {}

  // Shortcuts to AppState

  /**
   * Shortcuts to StoreState
   * @returns {Observable<StoreState>}
   */
  get storeState(): Observable<StoreState> {
    return this.store.select(selectors.getStoreState);
  }

  /**
   * Shortcuts to DrvicerState
   * @returns {Observable<DriverState>}
   */
  get driverState(): Observable<DriverState> {
    selectors.getDriverState.release();

    return this.store.select(selectors.getDriverState);
  }

  /**
   * shortcut to get the selected store from state
   * @returns {Store<Store>}
   */
  get selectedStore(): Observable<any> {
    return this.store.select(selectors.getSelectedStore);
  }

  /**
   * Shortcut to user state
   * @returns {Observable<UserState>}
   */
  get userState(): Observable<UserState> {
    return this.store.select(selectors.getUserState);
  }

  /**
   * Shortcuts to orderState
   * @returns {Observable<OrderState>}
   */
  get orderState(): Observable<OrderState> {
    // we want the selector to retrieve a new copy always
    selectors.getOrderState.release();

    return this.store.select(selectors.getOrderState);
  }

  /**
   * Shortcuts to catalogState
   * @returns {Observable<CatalogStae>}
   */
  get catalogState(): Observable<CatalogState> {
    return this.store.select(selectors.getCatalogState);
  }
}

@Component({
  selector: "alerts-page",
  templateUrl: "./base.component.html",
  styleUrls: ["./styles/base.scss"],
})
export class BasePage extends BaseComponent {
  mySnackBarRef: any;
  loader: MatDialogRef<SpinnerComponent>;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>
  ) {
    super(store);
  }

  basicAlert(): void {
    const dialogRef: MatDialogRef<ModalComponent, any> = this.dialog.open(
      ModalComponent,
      {
        data: {
          title: "Thanks for sharing!",
          button: "DOWNLOAD",
        },
      }
    );
  }

  textAlert(
    title: string,
    text: string,
    buttonText: string = "Done!"
  ): MatDialogRef<ModalComponent, any> {
    const dialogRef: MatDialogRef<ModalComponent, any> = this.dialog.open(
      ModalComponent,
      {
        data: {
          title,
          text,
          button: buttonText,
        },
      }
    );

    return dialogRef;
  }

  iconAlert(
    title: any,
    text: any,
    callback: any,
    icon: string = "check-circle",
    color: string = "success"
  ): void {
    const dialogRef: MatDialogRef<ModalComponent, any> = this.dialog.open(
      ModalComponent,
      {
        data: {
          icon,
          iconColor: color,
          title,
          text,
          button: "OK",
        },
      }
    );

    if (callback) {
      dialogRef.afterClosed().subscribe(callback);
    }
  }

  optionsAlert(title: any, text: any, callback: any): void {
    const dialogRef: MatDialogRef<ModalComponent, any> = this.dialog.open(
      ModalComponent,
      {
        data: {
          icon: "exclamation-circle",
          iconColor: "alert",
          title,
          text,
          options: true,
        },
      }
    );

    if (callback) {
      dialogRef.afterClosed().subscribe(callback);
    }
  }

  cancelledAlert(): void {
    const dialogRef: MatDialogRef<ModalComponent, any> = this.dialog.open(
      ModalComponent,
      {
        data: {
          icon: "exclamation-circle",
          iconColor: "failure",
          title: "Do you want to delete your file?",
          text: "You will not be able to recover it",
          options: true,
        },
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dialog.open(ModalComponent, {
          data: {
            icon: "check-circle",
            iconColor: "success",
            title: "Deleted",
            text: "Your file has been deleted.",
            button: "OK",
          },
        });
      } else {
        this.dialog.open(ModalComponent, {
          data: {
            icon: "times-circle",
            iconColor: "failure",
            title: "Canceled",
            text: "Your file is safe. You can find it on your inbox.",
            button: "OK",
          },
        });
      }
    });
  }

  inputAlert(): void {
    const dialogRef: MatDialogRef<ModalComponent, any> = this.dialog.open(
      ModalComponent,
      {
        data: {
          title: "Please enter your name",
          input: true,
        },
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      this.dialog.open(ModalComponent, {
        data: {
          title: "Your name is",
          text: result,
          button: "OK",
        },
      });
    });
  }

  timedAlert(): void {
    const dialogRef: MatDialogRef<ModalComponent, any> = this.dialog.open(
      ModalComponent,
      {
        data: {
          title: "Auto close alert!",
          text: "I will close in 2 seconds. Bye bye!",
          time: 2000,
        },
      }
    );
  }

  presentLoader(): void {
    this.loader = this.dialog.open(SpinnerComponent, {
      panelClass: "spinner",
      disableClose: true,
    });
  }

  dismissLoader(): void {
    if (this.loader) {
      this.loader.close();
    }
  }

  showNotification(
    vpos: any,
    hpos: any,
    type: any,
    icon: string = "",
    message: string = "",
    duration: number = 3000
  ): void {
    /**
     * for more info about Angular Material snackBar
     * check: https://material.angular.io/components/snack-bar/overview
     */
    this.mySnackBarRef = this.snackBar.openFromComponent(
      NotificationComponent,
      {
        data: {
          message,
          icon,
          type,
          dismissible: true,
          // you can add everything you want here
        },
        duration,
        horizontalPosition: hpos, // 'start' | 'center' | 'end' | 'left' | 'right'
        verticalPosition: vpos, // 'top' | 'bottom'
        panelClass: ["notification-wrapper"],
      }
    );
    // this is to be able to close it from the NotificationComponent
    this.mySnackBarRef.instance.snackBarRef = this.mySnackBarRef;
  }

  somethingWentWrong(): void {
    this.dismissLoader();
    const dialogRef: MatDialogRef<SomethingWentWrongComponent, any> =
      this.dialog.open(SomethingWentWrongComponent, {
        panelClass: "something-went-wrong",
      });
  }

  clearStores(): void {
    this.store.dispatch(new ActionOrderStoreClear());
    this.store.dispatch(new ActionDriverStoreClear());
    this.store.dispatch(new ActionClearOrders());
  }

  refreshStores(): void {
    this.store
      .select(selectors.getStoreState)
      .pipe(take(1))
      .subscribe((storeState) => {
        if (storeState.store) {
          const storeId: string = storeState.store.toString();
          this.store.dispatch(new ActionRefreshOrders(storeId));
          this.store.dispatch(new ActionFetchTripConfig(storeId));
        }
      });
  }

  /**
   * Loads orders,trips,driver and trip cash entries
   * when user clicks the refresh button in top bar
   *
   * If orders already in redux, just update the status
   * of the order and fetch new orders, If orders are not in
   * redux call for get orders
   */
  async refreshOrdersAndTrips(): Promise<void> {
    this.presentLoader();
    const storeState: StoreState = await this.store
      .select(selectors.getStoreState)
      .pipe(
        skipWhile((state) => state.storageLoading == true),
        take(1)
      )
      .toPromise();
    if (!storeState.store) {
      this.dismissLoader();

      return;
    }
    const storeId: string = storeState.store.toString();

    const localOrder: string = localStorage.getItem("liveOrders");
    if (localOrder) {
      let localSaleOrder: SaleOrder[] = JSON.parse(localOrder);
      localSaleOrder = localSaleOrder.map((order) =>
        new SaleOrder().deserialize(order)
      );
      this.store.dispatch(new ActionAllOrdersLoaded(localSaleOrder));
    }

    this.store.dispatch(new ActionFetchTripConfig(storeId));
    this.store.dispatch(new ActionRefreshOrders(storeId));
    this.store.dispatch(new ActionGetTrips(storeId));
    this.store.dispatch(new ActionGetDrivers(storeId));
    this.store.dispatch(new ActionGetEveryDrivers(storeId));

    // We should wait all these three data to stich trip orders
    const orders: SaleOrder[] = await this.store
      .select((state) => state)
      .pipe(
        skipWhile(
          (state) =>
            state.orderState.loading === true ||
            state.driverState.loading === true ||
            state.driverState.trip_loader === true ||
            state.driverState.driver_loader === true
        ),
        take(1),
        map((state) => state.orderState.orders)
      )
      .toPromise();

    this.store.dispatch(new ActionSaveOrders(orders));
    this.store.dispatch(new ActionStitchTripOrders(orders));

    this.store
      .select((state) => state)
      .pipe(
        skipWhile((state) => state.driverState.stichDone === false),
        take(1)
      )
      .toPromise();

    this.dismissLoader();
  }
}

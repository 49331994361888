<div class="driverProfile">
  <h5 class="driverProfile__header">Change Driver Type</h5>
  <div class="row">
    <p class="col-6">Is Weekend Driver</p>
    <span class="col-6">
      <mat-radio-group aria-label="Select an option">
        <mat-radio-button
          (change)="markAsWeekendRider($event)"
          [checked]="driver.isWeekendRider"
          value="true"
          >Yes</mat-radio-button
        >
        <mat-radio-button
          (change)="markAsWeekendRider($event)"
          [checked]="!driver.isWeekendRider"
          value="false"
          >No</mat-radio-button
        >
      </mat-radio-group>
    </span>
  </div>
  <div class="row">
    <p class="col-6">External Driver</p>
    <span class="col-6">
      <mat-radio-group aria-label="Select an option">
        <mat-radio-button
          (change)="markAsExternalRider($event)"
          [checked]="driver.isFreelancer"
          value="true"
          >Yes</mat-radio-button
        >
        <mat-radio-button
          (change)="markAsExternalRider($event)"
          [checked]="!driver.isFreelancer"
          value="false"
          >No</mat-radio-button
        >
      </mat-radio-group>
    </span>
  </div>
  <hr />
</div>
<div class="driverProfile__buttons">
  <h6
    class="driverProfile__paragraph"
    *ngIf="isWeekEndRider !== null || isFreeLancer !== null"
  >
    Are you sure you want to update the rider profile ?
  </h6>
  <div class="row">
    <div class="col-2">
      <button color="primary" mat-raised-button mat-dialog-close>Cancel</button>
    </div>
    <div class="col-2">
      <button
        paragraph
        class="driverProfile__buttons__confirm"
        color="primary"
        [disabled]="!isWeekEndRider && !isFreeLancer"
        mat-raised-button
        (click)="updateRiderProfile()"
      >
        Confirm
      </button>
    </div>
  </div>
</div>

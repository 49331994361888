import { AgmMap } from "@agm/core";
import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { SaleOrder } from "@tendercuts/models";
import { BasePage } from "../../utils";

@Component({
  selector: "app-locate-order-pin",
  templateUrl: "./locate-order-pin.component.html",
  styleUrls: ["./locate-order-pin.component.scss"],
})
export class LocateOrderPinComponent extends BasePage implements OnInit {
  // TODO
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input("map") map: AgmMap | any;
  @Input() locateOrder: SaleOrder;

  constructor(
    public dialogRef: MatDialogRef<LocateOrderPinComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
  ) {
    super(dialog, snackBar, store);
    if (data.order) {
      this.locateOrder = data.order;
    }
  }

  ngOnInit(): void {}

  get lat(): number {
    return this.locateOrder.shippingAddress.Olatitude;
  }

  get lng(): number {
    return this.locateOrder.shippingAddress.Olongitude;
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BasePatchProvider } from "@tendercuts/http";
import { TripEarning } from "@tendercuts/models";

@Injectable({
  providedIn: "root",
})
export class TripEarningUpdateService extends BasePatchProvider<TripEarning> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get key(): string {
    return "id";
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof TripEarning {
    return TripEarning;
  }

  get isCachable(): boolean {
    return false;
  }

  get endpoint(): string {
    return this.endpointService.tripEarningUpdate();
  }

  /**
   * params to pass trip earning confirm
   * @param status
   * @param id
   */
  getConfirmRequest(status: number, id: number): any {
    return {
      id,
      status,
    };
  }

  /**
   * params to pass trip earning approval
   * @param status
   * @param id
   * @param km
   */
  getApproveRequest(status: number, id: number, km: number, comment: string): {
    id: number;
    status: number;
    final_km: number;
    comment: string;
} {
    return {
      id,
      status,
      final_km: km,
      comment,
    };
  }
}

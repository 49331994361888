import { Injectable } from "@angular/core";
import { Actions, createEffect } from "@ngrx/effects";
import { Category, GenericError, Product } from "@tendercuts/models";
import {
  CatalogService,
  FirebaseCatalogService,
  SearchService,
} from "@tendercuts/providers";
import { ofAction } from "ngrx-actions";
import { of, Observable } from "rxjs";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import {
  ActionCatalogLoad,
  ActionCatalogLoadSuccess,
  ActionInventoryLoad,
  ActionLiveTrackLoad,
  ActionLoadFailed,
  ActionNoop,
  ActionSearchComplete,
  ActionSearchProducts,
} from "../catalog/catalog-actions";

@Injectable()
export class CatalogEffects {
  constructor(
    private actions$: Actions,
    private catalogService: CatalogService,
    private searchService: SearchService,
    private firebaseCatalogService: FirebaseCatalogService
  ) {}

  /**
   * Loads the catalog + inventory data & dispatches, returns
   * -> ActionNoop on complete otherwise
   * -> ActionLoadFailed on fail otherwise
   *
   * @type {Observable<any>}
   */
   catalogLoads$: Observable<ActionNoop | ActionLoadFailed> = createEffect(() =>
    this.actions$.pipe(
      ofAction(ActionCatalogLoad),
      mergeMap((action: ActionCatalogLoad) => {
        this.firebaseCatalogService.startSubscription(action.storeId);

        return of(new ActionNoop());
      }),
      catchError((err) => of(new ActionLoadFailed(new GenericError(err))))
    ));

  /**
   * Loads the catalog + inventory data & dispatches
   * -> ActionCatalogLoadSuccess on complete otherwise
   * -> ActionLoadFailed on fail otherwise
   *
   * type {Observable<any>}
   */
   inventoryLoad$: Observable<
    ActionCatalogLoadSuccess | ActionLoadFailed
  > = createEffect(() => this.actions$.pipe(
    ofAction(ActionInventoryLoad),
    switchMap((action: ActionInventoryLoad) => {
      this.catalogService.resetData();

      return this.catalogService
        .getData({
          store_id: action.payload != null ? action.payload.storeId : 1,
        })
        .pipe(
          map((category: Category[]) => new ActionCatalogLoadSuccess(category)),
          catchError((err) => of(new ActionLoadFailed(new GenericError(err))))
        );
    })
  ));

  /***
   * Triggers a search request & dispatches
   * -> ActionSearchComplete
   * type {Observable<ActionSearchComplete>}
   */
  
  searchProducts$: Observable<ActionSearchComplete> = createEffect(() => this.actions$.pipe(
    ofAction(ActionSearchProducts),
    switchMap((action: ActionSearchProducts) =>
      this.searchService
        .searchProducts(action.payload)
        .pipe(map((products: Product[]) => new ActionSearchComplete(products)))
    )
  ));

  /**
   * Loads the liveTrack, returns
   * -> ActionNoop on complete otherwise
   * -> ActionLoadFailed on fail otherwise
   * @type {Observable<any>}
   */
   liveTrackLoads$: Observable<ActionNoop | ActionLoadFailed> = createEffect(() =>
    this.actions$.pipe(
      ofAction(ActionLiveTrackLoad),
      mergeMap((action: ActionLiveTrackLoad) => {
        this.firebaseCatalogService.startSubscription(action.storeId);

        return of(new ActionNoop());
      }),
      catchError((err) => of(new ActionLoadFailed(new GenericError(err))))
    ));
}

<div id="print-container">
  <div *ngFor="let cat of categoriesList">
    <ng-container *ngIf="cat.items?.length > 0">
      <div>
        <table>
          <tbody>
            <tr>
              <td align="center" colspan="10">
                <span *ngIf="driverMode; else normalCopy">
                  <span><b>Trip KOT Copy</b></span>
                  <br />
                  <b>{{ driverDisplayName }}</b>
                  <br />
                  <b>Trip Id:{{ tripId }} </b>
                </span>
                <ng-template #normalCopy>
                  <span><b>Bulk KOT Copy</b></span>
                </ng-template>
              </td>
            </tr>
            <tr>
              <td align="center" colspan="10">
                <span
                  ><b>No of orders : {{ data.length }}</b></span
                >
              </td>
            </tr>
            <tr>
              <td align="center" colspan="10">
                <span
                  ><b> Time of print : {{ time }}</b></span
                >
              </td>
            </tr>
            <tr>
              <td align="center" colspan="10">
                <span
                  ><b>{{ cat.name }}</b>
                </span>
              </td>
            </tr>
            <ng-container *ngIf="cat.nonFreshCut?.length > 0">
              <div *ngFor="let item of cat.nonFreshCut" class="product">
                <tr>
                  <td
                    style="font-size: 0.9rem"
                    colspan="12"
                    class="product__name"
                  >
                    <b>{{ item.name }}</b>
                  </td>
                </tr>
                <tr>
                  <td
                    style="font-size: 0.8rem"
                    colspan="6"
                    class="product__label"
                  >
                    Qty
                  </td>
                  <td
                    style="font-size: 0.8rem"
                    colspan="6"
                    class="product__value"
                  >
                    {{ item.quantity | number: "1.3-3" }}
                  </td>
                </tr>
                <tr>
                  <td
                    style="font-size: 0.8rem"
                    colspan="6"
                    class="product__label"
                  >
                    Weight
                  </td>
                  <td
                    style="font-size: 0.8rem"
                    colspan="6"
                    class="product__value"
                  >
                    {{ productMap[item.productId]?.displayWeight }}
                  </td>
                </tr>
                <tr>
                  <td
                    style="font-size: 0.8rem"
                    colspan="6"
                    class="product__label"
                  >
                    PLU
                  </td>
                  <td
                    style="font-size: 0.8rem"
                    colspan="6"
                    class="product__value"
                  >
                    {{ item.pluCode }}
                  </td>
                </tr>
                <br />
              </div>
            </ng-container>

            <ng-container *ngIf="cat.freshCut?.length > 0">
              <tr>
                <td
                  align="left"
                  colspan="12"
                  style="border-bottom: 1px solid #000"
                >
                  <span>
                    <b>Fresh Cut</b>
                  </span>
                </td>
              </tr>

              <div *ngFor="let item of cat.freshCut" class="product">
                <tr>
                  <td
                    style="font-size: 0.9rem"
                    colspan="12"
                    class="product__name"
                  >
                    <b> {{ item.name }} </b>
                  </td>
                </tr>
                <tr>
                  <td
                    style="font-size: 0.8rem"
                    colspan="6"
                    class="product__label"
                  >
                    Qty
                  </td>
                  <td
                    style="font-size: 0.8rem"
                    colspan="6"
                    class="product__value"
                  >
                    {{ item.quantity | number: "1.3-3" }}
                  </td>
                </tr>
                <tr>
                  <td
                    style="font-size: 0.8rem"
                    colspan="6"
                    class="product__label"
                  >
                    Weight
                  </td>
                  <td
                    style="font-size: 0.8rem"
                    colspan="6"
                    class="product__value"
                  >
                    {{ productMap[item.productId]?.displayWeight }}
                  </td>
                </tr>
                <tr>
                  <td
                    style="font-size: 0.8rem"
                    colspan="6"
                    class="product__label"
                  >
                    PLU
                  </td>
                  <td
                    style="font-size: 0.8rem"
                    colspan="6"
                    class="product__value"
                  >
                    {{ item.pluCode }}
                  </td>
                </tr>
                <br />
              </div>
            </ng-container>
          </tbody>
        </table>
      </div>
      <br />
      <br />
      <br />
    </ng-container>
  </div>
</div>

import { Serializable } from './base';

export class PlutosRequest implements Serializable<PlutosRequest> {
    private TransactionNumber: string;
    private SequenceNumber: number;
    private AllowedPaymentMode: number;
    private Amount: number;
    private MerchantStorePosCode: string;
    public IMEI: string;
    public PlutusTransactionReferenceID: string
    constructor() { }

    get transactionNumber() {
        return this.TransactionNumber;
    }

    get sequenceNumber() {
        return this.SequenceNumber;
    }

    get allowedPaymentMode() {
        return this.AllowedPaymentMode;
    }

    get amount() {
        return this.Amount;
    }

    get merchantStorePosCode() {
        return this.MerchantStorePosCode;
    }

    deserialize(input) {
        this.TransactionNumber = input.TransactionNumber;
        this.SequenceNumber = input.SequenceNumber;
        this.AllowedPaymentMode = input.AllowedPaymentMode;
        this.Amount = input.Amount;
        this.MerchantStorePosCode = input.MerchantStorePosCode;
        this.IMEI = input.IMEI;
        this.PlutusTransactionReferenceID = input.PlutusTransactionReferenceID;
        return this;
    }
}
import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { Trips } from "@tendercuts/models";

@Component({
  selector: "app-map-live-trip",
  templateUrl: "./map-live-trip.component.html",
  styleUrls: ["./map-live-trip.component.scss"],
})
export class MapLiveTripComponent implements OnInit, AfterViewInit {
  @Input() mapRef: any;
  @Input() trip: Trips;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BasePostProvider } from "@tendercuts/http";
import { TripSheet } from "@tendercuts/models";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FetchSpeedoMeterSnapShot extends BasePostProvider<TripSheet> {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof TripSheet {
    return TripSheet;
  }

  get endpoint(): string {
    return this.endpointService.fetchKmSnapshot();
  }

  get isCachable(): boolean {
    return false;
  }

  /**
   * Convert the date into "YYYY-MM-DD" format
   * @param driverId
   * @param selectedDate
   */
  getParams(
    driverId: number,
    selectedDate: Date,
  ): { driver_id: string; date: string } {
    const date: string = selectedDate
      .getDate()
      .toLocaleString("en-US", { minimumIntegerDigits: 2 });
    const month: string = (selectedDate.getMonth() + 1).toLocaleString(
      "en-US",
      {
        minimumIntegerDigits: 2,
      },
    );
    const formattedDate: string =
      selectedDate.getFullYear() +
      "-" +
      month.toString() +
      "-" +
      date.toString();

    const params: {
      driver_id: string;
      date: string;
    } = {
      driver_id: driverId.toString(),
      date: formattedDate,
    };

    return params;
  }
}

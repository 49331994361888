import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BaseGetProvider } from "@tendercuts/http";
import { DriverLoginLogout } from "@tendercuts/models";

@Injectable({
  providedIn: "root",
})
export class FetchStoreDriversService extends BaseGetProvider<DriverLoginLogout> {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http);
    this.delegate = this;
  }

  // override this
  public preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof DriverLoginLogout {
    return DriverLoginLogout;
  }

  get endpoint(): string {
    return this.endpointService.fetchstoreDrivers();
  }

  get isCachable(): boolean {
    return false;
  }

  /**
   * Convert the selected date into "YYYY-MM-DD" format.
   */
  getParams(
    storeId: number,
    selectedDate: Date,
  ): {
    store_id: string;
    date: string;
  } {
    const date: string = selectedDate
      .getDate()
      .toLocaleString("en-US", { minimumIntegerDigits: 2 });
    const month: string = (selectedDate.getMonth() + 1).toLocaleString(
      "en-US",
      {
        minimumIntegerDigits: 2,
      },
    );
    const formattedDate: string =
      selectedDate.getFullYear() +
      "-" +
      month.toString() +
      "-" +
      date.toString();

    const params: {
      store_id: string;
      date: string;
    } = {
      store_id: storeId.toString(),
      date: formattedDate,
    };

    return params;
  }
}

import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";

import { Store } from "@ngrx/store";

import { AppState } from "@store/state";
import * as selectors from "@store/state";
import { ActionLoginClearError, ActionTryLogin, UserState } from "@store/user";
import { User } from "@tendercuts/models";

import { LoginServices } from "@login";
import { skipWhile, take } from "rxjs/operators";
import { BasePage } from "../../utils/pages/base/base.component";

@Component({
  selector: "app-login",
  templateUrl: "login.component.html",
  styleUrls: ["login.component.scss"],
})
export class LoginComponent extends BasePage implements OnInit {
  formLogin: FormGroup;
  redirectUrl: string = null;
  error: boolean;
  constructor(
    public fb: FormBuilder,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    public loginService: LoginServices,
    public store: Store<AppState>
  ) {
    super(dialog, snackBar, store);
    this.route.queryParams.subscribe((params) => {
      this.redirectUrl = params["redirect"];
    });
  }

  ngOnInit(): void {
    this.formLogin = this.fb.group({
      email: new FormControl("", Validators.compose([Validators.required])),

      password: new FormControl(
        "",
        Validators.compose([Validators.required, Validators.minLength(6)])
      ),
    });
  }

  login(): void {
    const user: User = new User();
    user.email = this.formLogin.controls["email"].value;
    user.password = this.formLogin.controls["password"].value;
    this.presentLoader();

    this.store.dispatch(new ActionTryLogin(user));

    this.store
      .select(selectors.getUserState)
      .pipe(
        skipWhile((userState: UserState) => userState.loading == true),
        take(1)
      )
      .subscribe((userState) => {
        this.dismissLoader();
        if (userState.user) {
          const url: string = this.redirectUrl ? this.redirectUrl : "dashboard";
          this.router.navigate([url]);
        } else {
          this.store.dispatch(new ActionLoginClearError());
          this.error = true;
          this.dismissLoader();
        }
      });
  }
}

import { Injectable } from "@angular/core";
@Injectable()
export class GeohashService {
  /**
   * Geohash encode, decode, bounds.
   */
  /* (Geohash-specific) Base32 map */
  base32: string = "0123456789bcdefghjkmnpqrstuvwxyz";

  /**
   * Encodes latitude/longitude to geohash, either to specified precision or to automatically
   * evaluated precision.
   *
   * @param   number lat - Latitude in degrees.
   * @param   number lon - Longitude in degrees.
   * @param   number [precision] - Number of characters in resulting geohash.
   * @returns string Geohash of supplied latitude/longitude.
   * @throws  Invalid geohash.
   *
   * @example
   *     var geohash = Geohash.encode(52.205, 0.119, 7); // geohash: 'u120fxw'
   */
  encode(lat: any, lon: any, precision: any): any {
    // infer precision?
    if (typeof precision == "undefined") {
      // refine geohash until it matches precision of supplied lat/lon
      for (let p: number = 1; p <= 12; p++) {
        const hash: any = this.encode(lat, lon, p);
        const posn: {
          lat: number;
          lon: number;
        } = this.decode(hash);
        if (posn.lat == lat && posn.lon == lon) {
          return hash;
        }
      }
      precision = 12; // set to maximum
    }

    lat = Number(lat);
    lon = Number(lon);
    precision = Number(precision);

    if (isNaN(lat) || isNaN(lon) || isNaN(precision)) {
      throw new Error("Invalid geohash");
    }

    let idx: number = 0; // index into base32 map
    let bit: number = 0; // each char holds 5 bits
    let evenBit: boolean = true;
    let geohash: string = "";

    let latMin: number = -90;
    let latMax: number = 90;
    let lonMin: number = -180;
    let lonMax: number = 180;

    while (geohash.length < precision) {
      if (evenBit) {
        // bisect E-W longitude
        const lonMid: number = (lonMin + lonMax) / 2;
        if (lon >= lonMid) {
          idx = idx * 2 + 1;
          lonMin = lonMid;
        } else {
          idx = idx * 2;
          lonMax = lonMid;
        }
      } else {
        // bisect N-S latitude
        const latMid: number = (latMin + latMax) / 2;
        if (lat >= latMid) {
          idx = idx * 2 + 1;
          latMin = latMid;
        } else {
          idx = idx * 2;
          latMax = latMid;
        }
      }
      evenBit = !evenBit;

      if (++bit == 5) {
        // 5 bits gives us a character: append it and start over
        geohash += this.base32.charAt(idx);
        bit = 0;
        idx = 0;
      }
    }

    return geohash;
  }

  /**
   * Decode geohash to latitude/longitude (location is approximate centre of geohash cell,
   *     to reasonable precision).
   *
   * @param   string geohash - Geohash string to be converted to latitude/longitude.
   * @returns lat:number, lon:number (Center of) geohashed location.
   * @throws  Invalid geohash.
   *
   * @example
   *     var latlon = Geohash.decode('u120fxw'); // latlon: { lat: 52.205, lon: 0.1188 }
   */
  decode(
    geohash: any,
  ): {
    lat: number;
    lon: number;
  } {
    const bounds: {
      sw: {
        lat: number;
        lon: number;
      };
      ne: {
        lat: number;
        lon: number;
      };
    } = this.bounds(geohash); // <-- the hard work
    // now just determine the centre of the cell...

    const latMin: number = bounds.sw.lat;
    const lonMin: number = bounds.sw.lon;
    const latMax: number = bounds.ne.lat;
    const lonMax: number = bounds.ne.lon;

    // cell centre
    let lat: number | string = (latMin + latMax) / 2;
    let lon: number | string = (lonMin + lonMax) / 2;

    // round to close to centre without excessive precision: ⌊2-log10(Δ°)⌋ decimal places
    lat = lat.toFixed(Math.floor(2 - Math.log(latMax - latMin) / Math.LN10));
    lon = lon.toFixed(Math.floor(2 - Math.log(lonMax - lonMin) / Math.LN10));

    return { lat: Number(lat), lon: Number(lon) };
  }

  /**
   * Returns SW/NE latitude/longitude bounds of specified geohash.
   *
   * @param   string geohash - Cell that bounds are required of.
   * @returns sw: lat: number, lon: number, ne: lat: number, lon: number
   * @throws  Invalid geohash.
   */
  bounds(
    geohash: any,
  ): {
    sw: {
      lat: any;
      lon: any;
    };
    ne: {
      lat: any;
      lon: any;
    };
  } {
    if (geohash.length === 0) {
      throw new Error("Invalid geohash");
    }

    geohash = geohash.toLowerCase();

    let evenBit: boolean = true;
    let latMin: number = -90;
    let latMax: number = 90;
    let lonMin: number = -180;
    let lonMax: number = 180;

    for (let i: number = 0; i < geohash.length; i++) {
      const chr: any = geohash.charAt(i);
      const idx: any = this.base32.indexOf(chr);
      if (idx == -1) {
        throw new Error("Invalid geohash");
      }

      for (let n: number = 4; n >= 0; n--) {
        // eslint-disable-next-line no-bitwise
        const bitN: number = (idx >> n) & 1;
        if (evenBit) {
          // longitude
          const lonMid: number = (lonMin + lonMax) / 2;
          if (bitN == 1) {
            lonMin = lonMid;
          } else {
            lonMax = lonMid;
          }
        } else {
          // latitude
          const latMid: number = (latMin + latMax) / 2;
          if (bitN == 1) {
            latMin = latMid;
          } else {
            latMax = latMid;
          }
        }
        evenBit = !evenBit;
      }
    }

    const bounds: {
      sw: {
        lat: any;
        lon: any;
      };
      ne: {
        lat: any;
        lon: any;
      };
    } = { sw: { lat: latMin, lon: lonMin }, ne: { lat: latMax, lon: lonMax } };

    return bounds;
  }
}

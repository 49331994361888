import { SelectionModel } from "@angular/cdk/collections";
import { HttpParams } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import * as selectors from "@store/state";
import { AppState } from "@store/state";
import { StoreSegment } from "@tendercuts/models";
import { OptimalTripService } from "@tendercuts/providers";
import { take } from "rxjs/operators";
import {
  Filter,
  FilterGroup,
  NestedCounterFilter,
  StoreAreaFilter,
} from "src/models";
import { SaleOrderDataSource } from "../../components";
import { BasePage } from "../../utils";

@Component({
  selector: "app-plan-trip-wizard",
  templateUrl: "./plan-trip-wizard.component.html",
  styleUrls: ["./plan-trip-wizard.component.scss"],
})
export class PlanTripWizardComponent extends BasePage implements OnInit {
  public orderCount: number = 12;
  orders: SaleOrderDataSource;

  areaFilterGroup: FilterGroup;
  slotFilterGroup: FilterGroup;

  // Variable to hold the segment that is right clicked.
  public selection: SelectionModel<StoreSegment> = new SelectionModel<StoreSegment>(
    true,
    [],
  );

  mapRawRef: any;

  constructor(
    public dialogRef: MatDialogRef<PlanTripWizardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public optimalTripService: OptimalTripService,
  ) {
    super(dialog, snackBar, store);
    this.orders = data["orders"];
    /**
     * Assumption - data shource has first filter as aread and second as
     * slots
     */
    this.areaFilterGroup = this.orders.filterModel.filterGroups[1];
    this.slotFilterGroup = this.orders.filterModel.filterGroups[2];
  }

  ngOnInit(): void {}

  renderSegment(mapRef: any): void {
    this.mapRawRef = mapRef;
  }

  /**
   * Applies model filters
   */
  applyFilters(filter: Filter | StoreAreaFilter): void {
    filter.selected = !filter.selected;
    this.orders.filter = "true";
    if ("segment" in filter) {
      const seg: StoreSegment = (filter as StoreAreaFilter).segment;
      this.selection.toggle(seg);
    }
  }

  generateTrips(): void {
    this.presentLoader();
    // extract param
    // get store id and query the server for optimal routes
    const appliedFilters: (
      | Filter
      | NestedCounterFilter
    )[] = this.areaFilterGroup.filters.filter((f) => f.selected);
    const segments: number[] = appliedFilters.map(
      (f: StoreAreaFilter) => f.segment.segmentId,
    );
    const slotFilters: (
      | Filter
      | NestedCounterFilter
    )[] = this.slotFilterGroup.filters.filter((f) => f.selected);
    const slots: any[] = slotFilters.map((f: Filter) => f.value);

    this.store
      .select(selectors.getStoreState)
      .pipe(take(1))
      .subscribe(
        (storeState) => {
          const params: HttpParams = this.optimalTripService.getParams(
            storeState.store.toString(),
            this.orderCount,
            segments,
            slots,
          );

          this.optimalTripService.getData(params).subscribe((data) => {
            this.dismissLoader();
            this.dialogRef.close(data);
          });
        },
        (error) => {
          this.dismissLoader();
          this.dialogRef.close([]);
        },
      );
  }
}

<div class="trip-action-btn">
  <button
    mat-raised-button
    color="primary"
    id="view-trip-map"
    (click)="viewTrip()"
  >
    <i class="fa fa-map" aria-hidden="true"></i>
    View Trip
  </button>

  <button
    mat-raised-button
    color="primary"
    id="collect-cash-btn"
    *ngIf="trip.isCashShort || trip.isPendingCash"
    (click)="collectCash()"
  >
    <i class="fa fa-money" aria-hidden="true"></i>
    Collect Cash
  </button>

  <button
    mat-raised-button
    color="primary"
    id="trip-close-btn"
    (click)="closeTrip()"
    *ngIf="trip.status == tripCloseStatus.RETURNINGBACK && isTechOps"
  >
    <i class="fa fa-close" aria-hidden="true">Trip close</i>
  </button>

  <ng-container *ngIf="!trip.isTripCompleted">
    <button
      mat-raised-button
      color="primary"
      id="scan-order-btn"
      *ngIf="trip.unScannedOrdersLength"
      (click)="scanOrders(trip.id.toString())"
    >
      <i class="search-bar-icon fa fa-search"></i>
      Scan Orders
    </button>
    <button
      mat-raised-button
      color="primary"
      id="remove-order"
      (click)="removeOrdersConfirm()"
    >
      <i class="fa fa-adjust" aria-hidden="true"></i>
      Remove Orders
    </button>

    <button
      id="print-one-cc"
      mat-raised-button
      color="primary"
      (click)="printOrders(trip.sequencedOrders, 'customer')"
    >
      <i class="material-icons">print</i>
      Print 1 Customer Copy
    </button>

    <button
      id="print-two-cc"
      mat-raised-button
      color="primary"
      (click)="
        printOrders(trip.sequencedOrders, 'customer', true, trip.driver, true)
      "
    >
      <i class="material-icons">print</i>
      Print 2 Customer Copies & KOT
    </button>

    <button
      id="print-kot"
      mat-raised-button
      color="primary"
      (click)="printOrders(trip.sequencedOrders, 'kot', false, trip.driver)"
    >
      <i class="material-icons">print</i>
      Print KOT
    </button>

    <button
      mat-raised-button
      color="primary"
      id="assign-rider-btn"
      *ngIf="canShowAssignDriverBtn && (canShowAssignRider | async)"
      (click)="assignDriver(trip)"
    >
      <i class="material-icons">motorcycle</i>
      Assign Rider
    </button>

    <button
      mat-raised-button
      color="primary"
      id="change-rider-btn"
      *ngIf="canReAssign"
      (click)="assignDriver(trip, true)"
    >
      <i class="material-icons">motorcycle</i>
      Change Rider
    </button>

    <button
      id="change-order-seq-btn"
      mat-raised-button
      color="primary"
      *ngIf="trip.status == 0"
      (click)="sequencedOrder(trip)"
    >
      <i class="fa fa-bars" aria-hidden="true"></i>
      Change Order Sequence
    </button>

    <button
      id="add-cash-btn"
      mat-raised-button
      color="primary"
      *ngIf="trip.canSubmitAdvanceCash"
      (click)="openAdvanceCashDialog(false)"
    >
      <i class="fa fa-money" aria-hidden="true"></i>
      Add Advance Cash
    </button>

    <button
      id="remove-cash-btn"
      mat-raised-button
      color="primary"
      *ngIf="trip.canSubmitAdvanceCash"
      (click)="openAdvanceCashDialog(true)"
    >
      <i class="fa fa-money" aria-hidden="true"></i>
      Remove Advance Cash
    </button>

    <button
      mat-raised-button
      appFirebaseControlledElement
      color="primary"
      id="edit-trip-btn"
      *ngIf="trip.status <= 1"
      (click)="editTrip()"
    >
      <i class="fa fa-pencil-square" aria-hidden="true"></i>
      Edit Trip
    </button>
  </ng-container>
</div>

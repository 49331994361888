import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";

import { AgmMap } from "@agm/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { Trips } from "@tendercuts/models";
import * as $ from "jquery";
import { BasePage } from "../../../utils";

@Component({
  selector: "app-view-trip-btn",
  templateUrl: "./view-trip-btn.component.html",
  styleUrls: ["./view-trip-btn.component.scss"],
})
export class ViewTripBtnComponent
  extends BasePage
  implements OnInit, AfterViewInit {
  @ViewChild("map") map: AgmMap | any;

  selectedTrip: Trips;

  @Input() set trip(data: any) {
    this.selectedTrip = data;
  }

  mapRawRef: any;

  zoom: number = 13;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.map) {
      this.map.mapReady.subscribe((map) => (this.mapRawRef = map));
    }
  }

  /**
   * Since the marker label can only render text, the styling is quite
   * limited, so we tag a css class to every div and then style it.
   *
   * This method searches the div with content containing Mins and
   * tags it.
   */

  tagLabels(): void {
    // Hack to add class to markers.
    $(document).ready(() => {
      // filtering only the divs that contain 'ID:' text
      const textNodes: any = $(document)
        .find("div:contains('ID:')")
        .contents()
        .filter(function(): boolean {
          // get only text nodes (3)
          return this.nodeType == 3 && this.textContent.indexOf("ID:") > -1;
        });
      textNodes.parent().addClass("markerLabel");
    });
  }

  /**
   * Helper method to convert string to float, mainly for lat & lng
   * @param str
   * @returns number
   */
  parse(str: string): number {
    return parseFloat(str);
  }
}

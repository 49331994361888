import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { InventoryStagingEntry } from "@tendercuts/models";
import { BasePage } from "../../../app/utils";

/**
 * Dialog component to show the inventory released products in a table.
 */
@Component({
  selector: "app-inventory-release-product-dialog",
  templateUrl: "./inventory-release-product-dialog.component.html",
  styleUrls: ["./inventory-release-product-dialog.component.scss"],
})
export class InventoryReleaseProductDialogComponent
  extends BasePage
  implements OnInit {
  @Input() productData: InventoryStagingEntry[];

  columnsToDisplay: string[] = [
    "id",
    "productName",
    "productQuantity",
    "storeName",
  ];

  constructor(
    public dialog: MatDialog,
    public snacker: MatSnackBar,
    public store: Store<AppState>,
    public dialogRef: MatDialogRef<InventoryReleaseProductDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InventoryStagingEntry[],
  ) {
    super(dialog, snacker, store);
  }

  ngOnInit(): void {
    if (this.data) {
      this.productData = this.data;
    }
  }

  // To close mat dialog
  dialogClose(): void {
    this.dialogRef.close();
  }
}

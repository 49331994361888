import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { SharedModule } from "../../shared/shared.module";
import { UtilsModule } from "../../utils/utils.module";
import { InventoryLogGridComponent } from "./inventory-log-tab/inventory-log-display.component";

@NgModule({
    declarations: [InventoryLogGridComponent],
    imports: [SharedModule, UtilsModule, CommonModule, MatDialogModule],
    exports: [InventoryLogGridComponent]
})
export class InventoryLogModule {}

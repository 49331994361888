<div class="reasons" *ngIf="!cancelOrderResponse">
  <p>Choose a reason for cancel :</p>
  <form class="row">
    <mat-form-field class="col-6">
      <mat-label>Select a reason</mat-label>
      <mat-select [(ngModel)]="cancelOrderRequest.selectedReason" name="reason">
        <mat-option
          *ngFor="let reason of cancelOrderRequest.reasons; let i = index"
          id="reason-{{ i }}"
          [value]="reason.value"
        >
          {{ reason.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-radio-group
      class="col-6"
      *ngIf="canAllowForRefund"
      (change)="onSelectionChange($event)"
    >
      <mat-label class="item-radio">Select Mode to refund customer</mat-label>
      <mat-radio-button id="wallet" value="0" [checked]="true"
        >Refund to wallet Tendercuts</mat-radio-button
      >
      <mat-radio-button id="juspay" value="1" *ngIf="isCcManager"
        >Refund to JusPay</mat-radio-button
      >
    </mat-radio-group>
  </form>
  <div class="item-checks">
    <mat-label class="row">Select items to revert inventory:</mat-label>
    <ng-container *ngFor="let item of saleOrder.items">
      <mat-checkbox
        class="row"
        *ngIf="item.isSimple"
        name="item"
        value="{{ item.productId }}"
        (change)="onItemSelect($event, item)"
        >{{ item.name }}</mat-checkbox
      >
    </ng-container>
    <button
      mat-button
      id="cancel-submit"
      [disabled]="!cancelOrderRequest.selectedReason"
      (click)="cancelOrder()"
    >
      Submit
    </button>
  </div>
</div>
<div class="revert-values" *ngIf="cancelOrderResponse">
  <h6 style="text-transform: capitalize">{{ cancelOrderResponse.message }}</h6>
  <!-- <p>{{ cancelOrderResponse.refund_details.products }} products has been reverted.</p> -->
  <p *ngIf="cancelOrderResponse.refundDetails.rewardAmount != null">
    {{ cancelOrderResponse.refundDetails.rewardAmount }} reward points has been
    reverted.
  </p>
  <p *ngIf="cancelOrderResponse.refundDetails.creditAmount != null">
    {{ cancelOrderResponse.refundDetails.creditAmount }} store credit has been
    reverted.
  </p>
  <p *ngIf="cancelOrderResponse.refundDetails.bankAmount">
    {{ cancelOrderResponse.refundDetails.bankAmount }} bank amount has been
    refund.
  </p>
  <button mat-button (click)="closePopup()">Done</button>
</div>

import * as selectors from "@store/state";

import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTabGroup } from "@angular/material/tabs";
import { ActionCatalogLoad, CatalogState } from "@store/catalog";
import {
  ActionGetStores,
  ActionRestrictStores,
  ActionSetStore,
  StoreState,
} from "@store/store";
import { Category, InventoryRequest, Product, User } from "@tendercuts/models";
import { map, skipWhile, take } from "rxjs/operators";
import {
  NumberInputAction,
  ProductDataSource,
  TextBoxAction,
} from "../../components";

import { Title } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { InventoryRequestService } from "@tendercuts/providers";
import { Observable } from "rxjs";
import { Filter, FilterGroup, FilterMatch, FilterModel } from "src/models";
import { InventoryReqReviewDialog } from "../../components/inventory-req-review";
import { BasePage } from "../../utils";
@Component({
  selector: "app-stock-request",
  templateUrl: "./stock-request.component.html",
  styleUrls: ["./stock-request.component.scss"],
})
export class StockRequestComponent
  extends BasePage
  implements OnInit, AfterViewInit {
  public today: Date = new Date();
  public isRequestTriggered: boolean = false;
  public user: User;
  buttonActions: TextBoxAction[] | NumberInputAction[];

  searchFilterGroup: FilterGroup = new FilterGroup([
    new Filter("Search", null, ["name", "sku"], FilterMatch.CONTAINS),
  ]);

  modelFilter: FilterModel = new FilterModel([this.searchFilterGroup]);
  products: ProductDataSource[] = [];
  // Express or scheduled
  inventoryType: number = null;
  selectedStoreId: any;
  requests: InventoryRequest[] = [];
  // flag for New Inventory update changes
  @Input() newInventoryMode: boolean = false;

  @ViewChild("tab") tabView: MatTabGroup;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public inventoryRequestService: InventoryRequestService,
    private title: Title,
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    if (this.newInventoryMode) {
      this.getUser();
      this.buttonActions = [
        new NumberInputAction(
          "Enter Qty (0-5)",
          "packs",
          this.submitRequest.bind(this),
        ),
      ];
      this.title.setTitle("New Inventory Update");
    } else {
      this.buttonActions = [
        new TextBoxAction(
          "Enter Packs",
          "packs",
          this.submitRequest.bind(this),
        ),
      ];
      this.title.setTitle("Old Inventory Update");
    }

    this.store.dispatch(new ActionGetStores());
    this.stores.subscribe((state: StoreState) => {
      this.selectedStoreId = state.store;
    });
  }

  ngAfterViewInit(): void {
    if (this.selectedStoreId) {
      this.store.dispatch(new ActionCatalogLoad(this.selectedStoreId));
      this.refreshData();

      // TODO: HACK needs to be fixed on SERVER SIDE!!!
      const catalogLoading$: Observable<CatalogState> = this.catalogState.pipe(
        skipWhile((catalogState: CatalogState) => catalogState.loading == true),
        take(1),
      );

      catalogLoading$.subscribe(() =>
        this.store.dispatch(new ActionRestrictStores(this.selectedStoreId)),
      );
    }
  }

  get stores(): Observable<StoreState> {
    return this.store.select(selectors.getStoreState);
  }

  changeInventoryType(inventoryType: number): void {
    const currentHour: number = new Date().getHours();
    const currentMinutes: number = new Date().getMinutes();

    if (6 <= currentHour && currentHour <= 21) {
      if (currentHour === 21 && currentMinutes > 30) {
        this.textAlert(
          "Stock Request Timeout",
          "Stock Requesting is available between 6am - 9.30pm only",
        );

        return;
      }
      this.inventoryType = inventoryType;

      return;
    }

    this.textAlert(
      "Stock Request Timeout",
      "Stock Requesting is available between 6am - 9.30pm only",
    );
  }

  async getUser(): Promise<void> {
    this.user = await this.store
      .select((state) => state.userState.user)
      .pipe(
        skipWhile((user) => !user),
        take(1),
      )
      .toPromise();
  }

  refreshData(): void {
    const catalog$: Observable<Category[]> = this.catalogState.pipe(
      skipWhile((state) => state.loading == true),
      map((state) => state.catalog),
    );

    catalog$.pipe(take(1)).subscribe((categories: Category[]) => {
      categories.forEach((category) => {
        // TODO: HACK needs to be fixed on SERVER SIDE!!!
        if (category.name == "Hot Deals") {
          return;
        }
        // try to find existing datasource, or create one
        const sources: ProductDataSource[] = this.products.filter(
          (source) => source.key == category.name,
        );

        let dataSource: ProductDataSource;
        if (!sources || sources.length == 0) {
          dataSource = new ProductDataSource(
            this.modelFilter,
            this.fetchFilterProducts(category),
            category.name,
          );
        } else {
          dataSource = sources[0];
        }

        this.products.push(dataSource);
      });
    });
  }

  /**
   * In New Inventory Mode return only products have parent skus
   * @param category
   */
  fetchFilterProducts(category: Category): Product[] {
    let products: Product[] = [];
    if (!this.newInventoryMode) {
      products = category.products.filter((product) => {
        const hydStores: number[] = [31, 32, 35, 36, 40, 41, 44, 45, 46, 47];
        if (hydStores.indexOf(+this.selectedStoreId) === -1) {
          return product.parentSku == null;
        }

        return product.categoryId == 14 || product.parentSku == null;
      });
    } else {
      products = category.products.filter(
        (product) => product.parentSku !== null,
      );
    }

    return products;
  }

  changeStore(): void {
    this.store.dispatch(new ActionSetStore(this.selectedStoreId));
    this.store.dispatch(new ActionCatalogLoad(this.selectedStoreId.toString()));
    this.refreshData();
  }

  /**
   * Searches for orderId
   * @param orderId
   */
  applySearchFilter(searchString: string): void {
    if (searchString === "") {
      this.searchFilterGroup.filters[0].selected = false;
      this.searchFilterGroup.filters[0].value = null;
    } else {
      this.searchFilterGroup.filters[0].selected = true;
      this.searchFilterGroup.filters[0].value = searchString;
    }

    this.products[this.tabView.selectedIndex].filter = "true";
  }

  /**
   * Attached as a callback to button action.
   * Create a new inventory request model and commit it to server.
   * @param Product product
   */
  submitRequest(event: any, product: Product): void {
    const qty: number = parseFloat(event.target.value);
    const qtyLimit: 5 | 10 = this.newInventoryMode ? 5 : 10;

    if (qty > qtyLimit) {
      this.textAlert(
        `Qty exceeded`,
        `You can only request for <${qtyLimit} packs,` +
          ` for amount > ${qtyLimit} please contact inventory controller.`,
      );

      return;
    }

    const requests: InventoryRequest[] = this.requests.filter(
      (inv) => inv.productId == product.id,
    );

    if (requests.length > 0) {
      // found one already update it
      const invRequest: InventoryRequest = requests[0];

      if (!Number.isNaN(qty)) {
        invRequest.qty = qty;

        return;
      }

      // remove it if it is nan i.e user has cancelled the input
      this.requests = this.requests.filter(
        (req) => req.productId != product.id,
      );

      return;
    }

    this.selectedStore.subscribe((store) => {
      // Inv request.
      const invRequest: InventoryRequest = new InventoryRequest().deserialize({
        product_id: product.id,
        product_name: product.name,
        sku: product.sku,
        store_id: store.storeId,
        store_name: store.code,
        type: this.inventoryType,
        qty,
        gpu: product.gramsPerUnit,
      });
      this.requests.push(invRequest);
    });
  }

  /**
   * Provides a ui for approval
   */
  private verifyRequests(): void {
    this.isRequestTriggered = true;
    const dialogData: {
      requests: InventoryRequest[];
      columns: string[];
    } = {
      requests: this.requests,
      columns: ["productName", "qty"],
    };
    const ref: MatDialogRef<InventoryReqReviewDialog, any> = this.dialog.open(
      InventoryReqReviewDialog,
      {
        data: dialogData,
        disableClose: true,
      },
    );
    ref.afterClosed().subscribe((status) => {
      if (!status) {
        this.isRequestTriggered = false;

        return;
      }

      this.presentLoader();
      this.inventoryRequestService.getData(this.requests).subscribe(
        (requestStatus) => {
          this.dismissLoader();
          this.showNotification(
            "top",
            "center",
            "success",
            "info-circle",
            "Request Successful",
          );
        },
        () => {
          this.showNotification(
            "top",
            "center",
            "success",
            "info-circle",
            "Request failed, contact tech support",
          );
          this.dismissLoader();
        },
        () => {
          this.refreshData();
          this.inventoryType = null;
        },
      );
    });
  }

  get columnsToDisplay(): string[] {
    if (this.newInventoryMode) {
      return ["name"];
    }

    if (this.inventoryType == 0) {
      return ["name", "inventory.today"];
    }

    return ["name", "inventory.future"];
  }
}

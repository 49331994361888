import { Injectable } from "@angular/core";

import { BaseGetProvider, DataTransformationProtocol } from "@tendercuts/http";
import { RewardPointsTransaction } from "@tendercuts/models";

import { HttpClient, HttpParams } from "@angular/common/http";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";

@Injectable()
export class RewardPointsTransactionService
  extends BaseGetProvider<RewardPointsTransaction>
  implements DataTransformationProtocol {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http);
    this.delegate = this;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  /**
   * Hook into the results and pluck out the result attribute
   */
  preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  /**
   * disable caching
   */
  get isCachable(): boolean {
    return false;
  }

  /**
   * The model into which the result are to be converted in process step
   */
  get model(): typeof RewardPointsTransaction {
    return RewardPointsTransaction;
  }

  /**
   * The endpoint for the service
   */
  get endpoint(): string {
    return this.endpointService.rewardPointsTransaction();
  }

  getParams(customerId: string): HttpParams {
    let params: HttpParams = new HttpParams();
    params = params.append("customer_id", customerId.toString());

    return params;
  }
}

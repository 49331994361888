/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/naming-convention */
import { Serializable } from "../base";
import { Location } from "../location";
import { EdcMachine } from "./edc-machine";
import { StoreConfig } from "./store-config";
import { StoreAttribute } from "./store_attribute";

export class Store implements Serializable<Store> {
  public selectedEdcMachine: EdcMachine;
  public storeConfig: StoreConfig[] = [];
  public address: string;
  public name: string;
  public code: string;
  public location: Location;
  public phone: string;
  public distance: number;
  private store_id: number;
  private website_id: number;
  private path_string: string;
  private store_attribute: StoreAttribute[] = [];
  private edc_machines: EdcMachine[] = [];
  private is_open_today: boolean;
  private is_gtg: boolean;

  constructor() {}

  get isGtg(): boolean {
    return this.is_gtg;
  }

  get storeId(): number {
    return this.store_id;
  }

  get websiteId(): number {
    return this.website_id;
  }

  get pathString(): string {
    return this.path_string;
  }

  get storeAttribute(): StoreAttribute {
    const data: StoreAttribute = this.store_attribute[0];
    if (data) {
      return data;
    }
  }

  get edcMachines(): EdcMachine[] {
    return this.edc_machines;
  }

  set edcMachines(machines: EdcMachine[]) {
    this.edc_machines = machines;
  }

  get isOnlineStore(): boolean {
    return this.storeAttribute && this.storeAttribute.storeType == 2;
  }

  get isStoreOpen(): boolean {
    return this.is_open_today;
  }

  /**
   * param ogType
   *    For website 'ogType' is 'website'
   *    For m-site 'ogType' is mobile-site
   */
  ogTagSerializer(
    metaTitle: string,
    metaDesc: string,
    ogType: string
  ): {
    metaTitle: string;
    name: string;
    metaDesc: string;
    type: string;
    image: string;
  } {
    return {
      metaTitle,
      name: this.name,
      metaDesc,
      type: ogType,
      image: this.storeAttribute.logo,
    };
  }

  twitterTagSerializer(
    metaTitle: string,
    metaDescription: string
  ): {
    metaTitle: string;
    name: string;
    metaDescription: string;
    card: string;
    image: string;
  } {
    return {
      metaTitle,
      name: this.name,
      metaDescription,
      card: "summary",
      image: this.storeAttribute.logo,
    };
  }
  deserialize(input: any): this {
    if (input == null || input == undefined) {
      return this;
    }

    this.name = input.name;
    this.code = input.code;
    this.store_id = input.store_id;
    this.is_gtg = input.is_gtg;
    this.website_id = input.website_id;
    this.path_string = input.path_string;
    this.address = input.address;
    this.phone = input.phone;
    this.distance = input.distance;
    // this.flock_group_id = input
    this.selectedEdcMachine = input.selectedEdcMachine;
    this.is_open_today = input.is_open_today;

    if (input.location != null) {
      this.location = new Location().deserialize(input.location);
    }
    if (input.store_attribute) {
      if (Array.isArray(input.store_attribute)) {
        input.store_attribute.forEach((eachAttribute) => {
          this.store_attribute.push(
            new StoreAttribute().deserialize(eachAttribute)
          );
        });
      } else {
        this.store_attribute.push(
          new StoreAttribute().deserialize(input.store_attribute)
        );
      }
    }

    if (input.edc_machines) {
      input.edc_machines.forEach((eachMachine) => {
        this.edc_machines.push(new EdcMachine().deserialize(eachMachine));
      });
    }

    if (input.storeConfig) {
      input.storeConfig.forEach((eachConfig) => {
        this.storeConfig.push(new StoreConfig().deserialize(eachConfig));
      });
    }

    return this;
  }
}

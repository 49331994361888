import { Serializable } from "./base";

export class GramInventory implements Serializable<GramInventory> {
  private store_id: number;
  private expiring_today: number;
  private expiring_tomorrow: number;
  private forecastqty: number;
  public opening: number;
  public qty: number;
  public date: Date;
  public product: number;
  public graminventoryid: number;

  get storeId() {
    return this.store_id;
  }

  get expiringToday() {
    return this.expiring_today;
  }

  get expiringTomorrow() {
    return this.expiring_tomorrow;
  }

  get forecastQty() {
    return this.forecastqty;
  }

  deserialize(input) {
    this.store_id = input.store_id;
    this.expiring_today = input.expiringtoday;
    this.expiring_tomorrow = input.expiring_tomorrow;
    this.forecastqty = input.forecastqty;
    this.opening = input.opening;
    this.qty = input.qty;
    this.date = new Date(input.date);
    this.product = input.product;
    this.graminventoryid = input.graminventoryid;

    return this;
  }
}

<mat-sidenav-container>
  <mat-sidenav class="map-side" mode="over" position="end" #drawer>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="row coverage-header">
      <div class="coverage-header__filter-section col-md-12 col-lg-6">
        <div class="row align-items-center">
          <!-- Date selector section-->
          <mat-form-field
            class="col-md-12 col-lg-4"
            color="accent"
            appearance="fill"
          >
            <mat-label>From date</mat-label>
            <input
              matInput
              [matDatepicker]="datePicker"
              [value]="fromDate"
              (dateChange)="setStatusEmpty()"
              (dateInput)="fromDate = $event.value"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="datePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field
            class="col-md-12 col-lg-4"
            color="accent"
            appearance="fill"
          >
            <mat-label>To date</mat-label>
            <input
              matInput
              [matDatepicker]="toDatePicker"
              (dateChange)="setStatusEmpty()"
              [value]="toDate"
              (dateInput)="toDate = $event.value"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="toDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #toDatePicker></mat-datepicker>
          </mat-form-field>

          <!-- Dropdown section-->
          <div class="row coverage-header__status col-md-12 col-lg-4">
            <div class="col-12">
              <mat-form-field>
                <mat-select
                  placeholder="Select Status"
                  [(ngModel)]="selectedStatus"
                  (selectionChange)="validateDates()"
                >
                  <mat-option
                    *ngFor="let status of statusOptions"
                    [value]="status.value"
                  >
                    {{ status.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="show-geohash-checkbox col-12">
              <mat-checkbox
                #checkBox
                labelPosition="after"
                (change)="toggleGeohashTiles($event)"
              >
                Show Geohash Tiles
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
      <!-- Selected Date section-->
      <div
        class="col-md-12 col-lg-6 coverage-header__date-chip-wrapper dashed-divider"
        *ngIf="searchedRecords.length"
      >
        <div class="coverage-header__date-chip-wrapper">Selected dates</div>
        <mat-chip-list [multiple]="true" [selectable]="true">
          <ng-container
            *ngFor="let searchRecord of searchedRecords; let index = index"
          >
            <mat-chip
              class="coverage-header__date-chip-wrapper__chip"
              (click)="deSelectDateRange(searchRecord)"
              [selected]="searchRecord.selected"
              [ngClass]="{
                'coverage-header__date-chip-wrapper__chip--green': index == 0,
                'coverage-header__date-chip-wrapper__chip--orange': index == 1,
                'coverage-header__date-chip-wrapper__chip--red': index == 2
              }"
            >
              <span>
                {{ searchRecord.date }}
                <br />
                <span class="coverage-header__date-chip-wrapper__status"
                  >{{ searchRecord.statusLabel }}({{
                    searchRecord.searchIndex
                  }})</span
                >
              </span>
              <mat-icon matChipRemove (click)="removeDateRange(searchRecord)"
                >cancel</mat-icon
              >
            </mat-chip>
          </ng-container>
        </mat-chip-list>
      </div>
    </div>

    <div class="map_wrapper">
      <agm-map
        *ngIf="center.length > 0"
        class="map_wrapper__map"
        #map
        [zoom]="mapZoom"
        [latitude]="center[0]"
        [longitude]="center[1]"
        [disableDefaultUI]="false"
        [zoomControl]="true"
        (mapReady)="renderSegment($event)"
      >
        <!-- Render Store segment geohash tiles-->
        <agm-polygon
          *ngFor="let tile of geohashTiles"
          fillColor="#FFA500"
          [paths]="tile"
        ></agm-polygon>

        <app-store-marker
          *ngFor="let store of (storeState | async)?.availableStores"
          [storeObj]="store"
        >
        </app-store-marker>
        <agm-marker-cluster
          *ngIf="selectedRecords?.length"
          [imagePath]="clusterData.imagePath"
          [averageCenter]="true"
          [gridSize]="clusterData.gridSize"
          [maxZoom]="clusterData.maxZoom"
        >
          <div
            *ngFor="let selectedRecord of selectedRecords; let index = index"
          >
            <agm-marker
              *ngFor="let orderLoc of selectedRecord.orders"
              [latitude]="orderLoc.latitude"
              [longitude]="orderLoc.longitude"
              [iconUrl]="markerUrl(index)"
              [clickableIcons]="false"
            >
            </agm-marker>
          </div>
        </agm-marker-cluster>

        <!-- Render store segments -->
        <ng-container *ngIf="mapRawRef">
          <app-store-segment
            *ngFor="let segment of segments"
            [map]="mapRawRef"
            [noHover]="false"
            [storeSegment]="segment"
          ></app-store-segment>
        </ng-container>
      </agm-map>
      <mat-table
        *ngIf="selectedRecords.length"
        [dataSource]="dataSource"
        class="map_wrapper__info mat-elevation-z8"
        matSort
        (matSortChange)="sortTable($event)"
      >
        <ng-container
          [matColumnDef]="column"
          *ngFor="let column of columnsToDisplay"
        >
          <ng-container *ngIf="column === 'segment'">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Segment</mat-header-cell
            >
            <mat-cell
              class="map_wrapper__info__seg_name"
              *matCellDef="let element"
              (click)="centerMap(element[column])"
            >
              {{ element[column] }}
            </mat-cell>
          </ng-container>

          <ng-container *ngIf="column.startsWith('orders')">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Orders <br />
              ({{ column.split("_")[1] }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span *ngIf="element[column]; else showZero">{{
                element[column]
              }}</span>
              <ng-template #showZero>0</ng-template>
            </mat-cell>
            <ng-container></ng-container>
          </ng-container>
          <ng-container *ngIf="column.startsWith('per')">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Per <br />
              ({{ column.split("_")[1] }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span *ngIf="element[column]; else showZeroPer"
                >{{ element[column].toFixed(2) }} %</span
              >
              <ng-template #showZeroPer>0 %</ng-template>
            </mat-cell>
          </ng-container>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columnsToDisplay"></mat-row>
      </mat-table>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

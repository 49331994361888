<div class="main-content-outer" *ngIf="products.length > 0">
  <!--Filter-->
  <div class="row">
    <mat-form-field class="row">
      <input
        matInput
        (keyup)="applySearchFilter($event.target.value)"
        placeholder="Search product"
      />
    </mat-form-field>
  </div>

  <mat-tab-group #tab>
    <mat-tab *ngFor="let category of products" label="{{ category.key }}">
      <table
        mat-table
        [dataSource]="category"
        class="mat-elevation-z8"
        *ngIf="inventory?.length"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let product">{{ product?.name }}</td>
        </ng-container>

        <ng-container matColumnDef="stock">
          <th mat-header-cell *matHeaderCellDef>Stock (On/Off)</th>
          <td mat-cell *matCellDef="let product">
            <mat-slide-toggle
              [checked]="productQuantity(product?.id)"
              (click)="submitRequest(product)"
            >
            </mat-slide-toggle>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </mat-tab>
  </mat-tab-group>
</div>

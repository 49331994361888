import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect } from "@ngrx/effects";
import { Driver, Trips, TripCashEntries, TripConfig } from "@tendercuts/models";
import {
  FetchDriversService,
  FetchEveryDriversService,
  FetchSavedTripsService,
  FetchTripsService,
  TripsCashEntriesService,
  TripConfigService,
} from "@tendercuts/providers";
import { ofAction } from "ngrx-actions";
import { of, Observable } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import {
  ActionAllDriversLoaded,
  ActionAllTripsLoaded,
  ActionEveryDriversLoaded,
  ActionGetDrivers,
  ActionGetEveryDrivers,
  ActionGetTrips,
  ActionLoadFailed,
} from "../driver/actions";
import {
  ActionFetchSavedTrips,
  ActionFetchTripConfig,
  ActionLoadTripCash,
  ActionSaveTripCash,
  ActionSaveTripConfig,
  ActionStoreSavedTrips,
  ActionTripConfigError,
} from "../driver/actions";

@Injectable()
export class DriverEffects {
  constructor(
    private actions$: Actions,
    private driverService: FetchDriversService,
    private everyDriverService: FetchEveryDriversService,
    private tripService: FetchTripsService,
    private fetchSavedTripsService: FetchSavedTripsService,
    private tripsCashEntriesService: TripsCashEntriesService,
    private tripConfigService: TripConfigService,
  ) {}

  /**
   * Trigger the server request to load all driver
   * type {Observable<any>}
   */
  
  getDrivers: Observable<
    ActionAllDriversLoaded | ActionLoadFailed
  > = createEffect(() => this.actions$.pipe(
    ofAction(ActionGetDrivers),
    switchMap((action: ActionGetDrivers) => {
      const params: HttpParams = this.driverService.getParams(action.storeId);

      return this.driverService.getData(params).pipe(
        map((res: Driver[]) => new ActionAllDriversLoaded(res)),
        catchError((err) => of(new ActionLoadFailed(err))),
      );
    }),
  ));

  /**
   * Trigger the server request to load all driver including in-active
   * drivers.
   * type {Observable<any>}
   */
  
  getEveryDrivers: Observable<
    ActionLoadFailed | ActionEveryDriversLoaded
  > = createEffect(() => this.actions$.pipe(
    ofAction(ActionGetEveryDrivers),
    switchMap((action: ActionGetEveryDrivers) => {
      const params: HttpParams = this.everyDriverService.getParams(
        action.storeId,
      );

      return this.everyDriverService.getData(params).pipe(
        map((res: Driver[]) => {
          return new ActionEveryDriversLoaded(res);
        }),
        catchError((err) => of(new ActionLoadFailed(err))),
      );
    }),
  ));

  /**
   * Trigger the server request to load all trips
   * type {Observable<any>}
   */
  
  getTrips: Observable<
    ActionLoadFailed | ActionAllTripsLoaded
  > = createEffect(() => this.actions$.pipe(
    ofAction(ActionGetTrips),
    switchMap((action: ActionGetTrips) => {
      const params: HttpParams = this.tripService.getParams(action.storeId);

      return this.tripService.getData(params).pipe(
        map((res: Trips[]) => {
          return new ActionAllTripsLoaded(res);
        }),
        catchError((err) => of(new ActionLoadFailed(err))),
      );
    }),
  ));

  /**
   * Trigger the server request to load all trips
   * type {Observable<any>}
   */
  
  fetchSavedTrips: Observable<
    ActionLoadFailed | ActionStoreSavedTrips
  > = createEffect(() => this.actions$.pipe(
    ofAction(ActionFetchSavedTrips),
    switchMap((action: ActionFetchSavedTrips) => {
      const params: {
        store_id: number;
      } = this.fetchSavedTripsService.getParams(action.storeId);

      return this.fetchSavedTripsService.getData(params).pipe(
        map((res: Trips[]) => {
          return new ActionStoreSavedTrips(res);
        }),
        catchError((err) => of(new ActionLoadFailed(err))),
      );
    }),
  ));

  /**
   * Loads cash entries of all trips and saves them in driverState
   */
  
  loadTripCash: Observable<
    ActionLoadFailed | ActionSaveTripCash
  > = createEffect(() => this.actions$.pipe(
    ofAction(ActionLoadTripCash),
    switchMap((action: ActionLoadTripCash) => {
      return this.tripsCashEntriesService
        .getData({ store_id: action.payload })
        .pipe(
          map((res: TripCashEntries[]) => new ActionSaveTripCash(res)),
          catchError((err) => of(new ActionLoadFailed(err))),
        );
    }),
  ));

  /**
   * Trigger the server request to save the trip config
   * type {Observable<any>}
   */
  
  saveTripConfig: Observable<
    ActionSaveTripConfig | ActionTripConfigError
  > = createEffect(() => this.actions$.pipe(
    ofAction(ActionFetchTripConfig),
    switchMap((action: ActionFetchTripConfig) => {
      const params: {
        store_id: string;
      } = this.tripConfigService.getParams(action.storeId);

      return this.tripConfigService.getData(params).pipe(
        map((res: TripConfig[]) => new ActionSaveTripConfig(res[0])),
        catchError((err) => of(new ActionTripConfigError(err))),
      );
    }),
  ));
}

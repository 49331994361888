import { Serializable } from "../base";

export class MessageResource implements Serializable<MessageResource> {
  private out_of_stock = "Out of Stock";
  private payment_message = "Pay online for Contactless delivery";

  get oosMessage() {
    return this.out_of_stock;
  }

  get paymentMessage() {
    return this.payment_message;
  }

  constructor() {}

  deserialize(input) {
    if (input.out_of_stock) {
      this.out_of_stock = input.out_of_stock;
    }
    if (input.payment_message) {
      this.payment_message = input.payment_message;
    }

    return this;
  }
}

import { Injectable } from "@angular/core";
import { BaseGetProvider } from "@tendercuts/http";
import { Category } from "@tendercuts/models";

import { HttpClient, HttpParams } from "@angular/common/http";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";

@Injectable()
export class FetchParentProductService extends BaseGetProvider<Category> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
  }

  // override this
  public preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get isCachable(): boolean {
    return false;
  }
  get isAuthenticatedEndpoint(): boolean {
    return true;
  }
  get authProtocol(): LoginServices {
    return this.loginService;
  }
  get model(): typeof Category {
    return Category;
  }
  get endpoint(): string {
    return this.endpointService.parentProducts;
  }

  getParams(storeId: string): HttpParams {
    let params: HttpParams = new HttpParams();
    params = params.append("store_id", storeId);

    return params;
  }
}

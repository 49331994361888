import { DefaultSerializationMixin, Serializable } from "./base";

export class Butcher
  extends DefaultSerializationMixin
  implements Serializable<Butcher> {
  private name: string;
  private id: number;
  private no_of_orders: number;

  public rating: number;
  public url: string;
  public skills: string[] = [];
  public description: string;

  get butcherName() {
    return this.name ? this.name : "Meat Expert";
  }

  get noOfOrders() {
    return this.no_of_orders;
  }

  deserialize(input) {
    this.name = input.name;
    this.url = input.url;
    if (input.skills) {
      this.skills = input.skills;
    }
    this.id = input.id;
    this.description = input.description;
    this.no_of_orders = input.no_of_orders;
    this.rating = input.rating;
    
    return this;
  }
}

export class SaleOrderStatusHistory {
  public created_at: string;
  public status: string;
  public comment: string;
  public isVisibleOnFront: number;

  get userName() {
    let name = this.comment.split("\n");
    if (name.length > 0) {
      return name[1];
    }
    return "";
  }

  get commentDisplay() {
    let comment = this.comment.split("\n");
    if (comment.length > 0) {
      return comment[0];
    }
    return "";
  }

  get createdAt() {
    return this.created_at;
  }

  deserialize(input) {
    this.created_at = input.created_at;
    this.status = input.status;
    this.comment = input.comment;
    this.isVisibleOnFront = input.is_customer_notified;
    return this;
  }
}

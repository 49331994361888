import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BasePostProvider } from "@tendercuts/http";
import { CancelOrderResponse } from "@tendercuts/models";

@Injectable()
export class OrderCancelService extends BasePostProvider<CancelOrderResponse> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof CancelOrderResponse {
    return CancelOrderResponse;
  }

  get endpoint(): string {
    return this.endpointService.orderCancel();
  }
}

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import {
  DriverLoginLogout,
  TripSheet,
  WeeklyTripSheet,
} from "@tendercuts/models";
import {
  ColumnAction,
  IconAction,
  TableRowAction,
  TripSheetDataSource,
} from "src/app/components";
import { BasePage } from "src/app/utils";
import { FilterModel } from "src/models";
import { IncentiveCommentComponent } from "../incentive-comment";
import { VerifyTripSheetWraperComponent } from "../verify-trip-sheet";
import { WeeksTargetComponent } from "../weeks-target";

interface EarningsBreakupDatasourceJson {
  label: string;
  columnA: string | number;
  columnB: string | number;
}

/**
 * component to show weekly trip sheet object.
 */
@Component({
  selector: "app-verify-weekly-trip-sheet",
  templateUrl: "./verify-weekly-trip-sheet.component.html",
  styleUrls: ["./verify-weekly-trip-sheet.component.scss"],
})
export class VerifyWeeklyTripSheetComponent extends BasePage {
  displayedColumns: string[];
  earningsBreakupDatasource: EarningsBreakupDatasourceJson[];

  @Input()
  driver: DriverLoginLogout;

  // weekly tripsheet object
  weeklyTripSheet: WeeklyTripSheet;

  // For Delivery manager actions
  @Input()
  isDeliveryManagerMode: boolean = false;

  @Input() set tripSheet(weeklyTripSheet: WeeklyTripSheet) {
    if (!weeklyTripSheet) {
      return;
    }

    this.weeklyTripSheet = weeklyTripSheet;
    this.tripSheetDataSource.data = weeklyTripSheet.tripSheets.sort((a, b) =>
      a.earningDate < b.earningDate ? -1 : 1
    );
    this.setupEarningsBreakup();
  }

  @Input() columnsToDisplay: string[] = [
    "earningDate",
    "tripDay",
    "orderCount",
    "tripCount",
    "kmEarning",
    "orderEarning",
    "mbgEarning",
    "incentiveEarning",
    "totalEarning",
    "shift",
  ];

  /**
   * Column totals added for tor trip sheet table
   */
  @Input() footerColumnsToDisplay: string[] = [
    "orderCount",
    "tripCount",
    "kmEarning",
    "orderEarning",
    "mbgEarning",
    "totalEarning",
  ];

  @Input() titleToDisplay: string[] = [
    "Date",
    "Day",
    "Order Count",
    "Trip Count",
    "Km Earning",
    "Order Earning",
    "MBG Earning",
    "Incentive Earning",
    "Total Earning",
    "Shift",
  ];

  /**
   * row click action for open tripsheet dialog
   */
  @Input()
  rowClickCallback: TableRowAction = new TableRowAction(
    this.showTripSheet.bind(this)
  );

  tripSheetDataSource: TripSheetDataSource = new TripSheetDataSource(
    new FilterModel([]),
    []
  );

  @Input() actions: ColumnAction[] = [
    new IconAction("On Time", "isOnTime", this.isOnTime.bind(this)),
    new IconAction("MBG Eligible", "mbgApplied", this.isMBGEligible.bind(this)),
  ];

  /**
   * emitter for close drawer
   */
  // TODO
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  // flag to show earning inside dialog
  dialogMode: boolean = true;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>
  ) {
    super(dialog, snackBar, store);
  }

  /**
   * Prepares data to show as rows for earnings breakup table
   * @param label
   * @param columnA
   * @param columnB
   * @returns
   */
  prepareJsonForEarningsBreakup(
    label: string,
    columnA: string | number,
    columnB: string | number
  ): EarningsBreakupDatasourceJson {
    return {
      label,
      columnA,
      columnB,
    };
  }

  /**
   * Fn to prepare the data to be displayed
   * in earnings breakup table
   */
  setupEarningsBreakup(): void {
    this.earningsBreakupDatasource = [
      this.prepareJsonForEarningsBreakup(
        "Order + Km Earnings",
        this.weeklyTripSheet.sheetEarnings,
        this.weeklyTripSheet.sheetEarnings
      ),
      this.prepareJsonForEarningsBreakup(
        "Order Incentive",
        this.weeklyTripSheet.incentiveEarningsSchemeAWeekly,
        0
      ),
      this.prepareJsonForEarningsBreakup(
        "Attendance Incentives",
        0,
        this.weeklyTripSheet.attIncentiveEarned
      ),
      this.prepareJsonForEarningsBreakup(
        "Weekday On Time Incentives",
        0,
        this.weeklyTripSheet.weekdayIncentiveEarned
      ),
      this.prepareJsonForEarningsBreakup(
        "Weekend On Time Incentives",
        0,
        this.weeklyTripSheet.weekendIncentiveEarned
      ),
      this.prepareJsonForEarningsBreakup(
        "MBG Earning Incentives",
        this.weeklyTripSheet.mbgEarningsSchemeAWeekly,
        this.weeklyTripSheet.incentiveForMBGEarning
      ),
      this.prepareJsonForEarningsBreakup(
        "Penalty",
        -1 * this.weeklyTripSheet.dailyPenalty,
        -1 * this.weeklyTripSheet.dailyPenalty
      ),
    ];

    if (this.weeklyTripSheet.isSchemeDaily) {
      this.displayedColumns = ["label", "columnA"];
      this.earningsBreakupDatasource.push(
        this.prepareJsonForEarningsBreakup(
          "Total Earnings",
          this.weeklyTripSheet.dailyTotalEarnings,
          null
        )
      );
    }

    if (this.weeklyTripSheet.isSchemeWeekly || this.weeklyTripSheet.isSchemeHybrid) {
      this.displayedColumns = ["label", "columnB"];
      this.earningsBreakupDatasource.push(
        this.prepareJsonForEarningsBreakup(
          "Total Earnings",
          null,
          this.weeklyTripSheet.weeklyTotalEarnings
        )
      );
    }

    if (this.weeklyTripSheet.isSchemeDual) {
      this.displayedColumns = ["label", "columnA", "columnB"];
      this.earningsBreakupDatasource.push(
        this.prepareJsonForEarningsBreakup(
          "Total Earnings",
          this.weeklyTripSheet.dailyTotalEarnings,
          this.weeklyTripSheet.weeklyTotalEarnings
        ),
        this.prepareJsonForEarningsBreakup(
          "Best of Daily and Weekly",
          null,
          Math.max(
            this.weeklyTripSheet.dailyTotalEarnings,
            this.weeklyTripSheet.weeklyTotalEarnings
          )
        )
      );
    }

    this.earningsBreakupDatasource.push(
      this.prepareJsonForEarningsBreakup(
        "Manual Incentive",
        null,
        this.weeklyTripSheet.manualIncentive
      ),
      this.prepareJsonForEarningsBreakup(
        "Final Earnings",
        null,
        this.weeklyTripSheet.totalEarning
      )
    );
  }

  closeDialog(): void {
    this.onClose.emit(false);
  }

  /**
   * Return the icon based on the on tile flag
   * @param event
   * @param tripSheet
   */
  isOnTime(event: any, tripSheet: TripSheet): string {
    return tripSheet.isOnTime ? "fa fa-check" : "fa fa-times";
  }

  /**
   * Return the icon based on the mbg eligible flag
   * @param event
   * @param tripSheet
   */
  isMBGEligible(event: any, tripSheet: TripSheet): string {
    return tripSheet.mbgApplied ? "fa fa-check" : "fa fa-times";
  }

  /**
   * to show selected trip sheet details
   * @param event
   * @param tripSheet
   */
  showTripSheet(event: any, tripSheet: TripSheet): void {
    const dialog: MatDialogRef<VerifyTripSheetWraperComponent, any> =
      this.dialog.open(VerifyTripSheetWraperComponent, {
        width: "1124px",
        height: "810px",
        panelClass: "earning",
        data: {
          sheet: tripSheet,
          tripSheetDetailMode: false,
          dialogMode: true,
        },
      });
  }

  /**
   * incentive flags based on the status.
   * @param status
   */
  checkEligiblity(status: boolean): string {
    return status ? "done" : "clear";
  }

  /**
   * To show incentive information in a dialog
   */
  openCommentDialog(): void {
    const dialog: MatDialogRef<IncentiveCommentComponent, any> =
      this.dialog.open(IncentiveCommentComponent, {
        data: this.weeklyTripSheet,
        panelClass: "remove-padding",
        width: "500px",
      });
  }

  viewtargets(): void {
    const dialog: MatDialogRef<WeeksTargetComponent, any> = this.dialog.open(
      WeeksTargetComponent,
      {
        data: this.weeklyTripSheet,
        panelClass: "remove-padding",
        width: "500px",
        maxHeight: "90vh",
      }
    );
  }

  /**
   * parent component reload after event emit
   * @param tripsheet
   */
  reloadParent(tripsheet: WeeklyTripSheet): void {
    this.weeklyTripSheet = tripsheet;
    this.setupEarningsBreakup();
    this.onClose.emit(true);
  }

  redirectToCrm(): void {
    window.open(
      window.location.origin +
        "/dashboard/driver-crm/" +
        this.weeklyTripSheet.driver.id,
      "_blank"
    );
  }
}

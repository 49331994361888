import { Serializable } from './base';

export class PageMetaData implements Serializable<PageMetaData> {
    public keywords: string;
    public description: string;
    // tslint:disable-next-line: variable-name
    public product_id: number;
    public title: string;

    constructor() { }

    deserialize(input: any): this {
        this.keywords = input.keywords;
        this.description = input.description;
        this.product_id = input.product_id;
        this.title = input.title;

        return this;
    }
}

import { HttpParams } from "@angular/common/http";
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSidenavContainer } from "@angular/material/sidenav";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { StoreState } from "@store/store/state";
import { UserState } from "@store/user/state";
import {
  BookingRequest,
  DriverLoginLogout,
  DriverProfile,
  DriverProfileDetails,
  DriverSnapshot,
  DriverStatusHistory,
  Trips,
  TripSheet,
  User,
  WeeklyTripSheet,
} from "@tendercuts/models";
import {
  DriverAttendanceDetailsService,
  DriverBookingDetailsService,
  DriverProfileSearchService,
  DriverStatusHistoryService,
  DriverTripSearchService,
  FetchDriverSnapshotService,
  FetchTripSheetService,
  WeekendDriverService,
  WeeklyTripSheetDisplayService,
} from "@tendercuts/providers";
import { Subscription } from "rxjs";
import { skipWhile, take } from "rxjs/operators";
import {
  BookingStatusDataSource,
  ColumnAction,
  DateFormatAction,
  DriverAttendanceDataSource,
  DriverStatusHistoryDataSource,
  TableRowAction,
  TripDataSource,
  TripSheetDataSource,
  WeeklyTripSheetDataSource,
} from "src/app/components";
import { FilterModel } from "src/models";
import { BasePage } from "../../utils";
import { DriverOnBoardingComponent } from "../driver-onboarding";

/**
 * component to load driver profile details
 */
@Component({
  selector: "app-driver-crm",
  templateUrl: "./driver-crm.component.html",
  styleUrls: ["./driver-crm.component.scss"],
})
export class DriverCrmComponent extends BasePage implements OnInit, OnDestroy {
  @ViewChild("drawer") drawer: MatSidenavContainer;
  tcDriver: DriverProfile;
  bookingLastTrip: BookingRequest;
  searchText: string = "";
  driverDetails: DriverProfileDetails;
  selectedTrip: Trips;
  selectedWeekTrip: WeeklyTripSheet;
  availableStores: any;
  index: number = 0;
  selectedTripSheet: TripSheet;
  snapDetails: DriverSnapshot;

  // today's trip sheets(not approved)
  tripSheets: TripSheet[] = [];

  weeklyTripSheets: WeeklyTripSheet[] = [];

  @Input() columnsToDisplay: string[] = [
    "id",
    "earningDate",
    "kmEarning",
    "orderEarning",
    "totalEarning",
    "tripSheetStatus",
  ];

  /**
   * Column to display Booking details
   */
  @Input() columnsToDisplayBooking: string[] = [
    "storeId",
    "storeName",
    "bookedDate",
    "respondDateTime",
    "bookingStatus",
    "driverAttendance",
    "shiftStatus",
    "expireAt",
  ];

  @Input() titleToDisplayBooking: string[] = [
    "Store Id",
    "Store Name",
    "Request Date",
    "Respond Date & Time",
    "Overall Status",
    "Present/Absent",
    "Shift Status",
    "Request Expire At",
  ];

  @Input() titleToDisplay: string[] = [
    "Trip Sheet Id",
    "Created Date",
    "Km Earning",
    "Order Earning",
    "Total Earning",
    "Status",
  ];

  // check for create driver access
  user: User;
  bookingDetails: BookingRequest[] = [];

  @Input()
  rowClickCallback: TableRowAction = new TableRowAction(
    this.showTripSheet.bind(this),
  );

  @Input()
  rowClickCallbackWeeklyTrip: TableRowAction = new TableRowAction(
    this.showWeeklyTripsheet.bind(this),
  );

  modelFilter: FilterModel = new FilterModel([]);

  tripSheetDataSource: TripSheetDataSource = new TripSheetDataSource(
    this.modelFilter,
    [],
  );

  bookingStatusDataSource: BookingStatusDataSource = new BookingStatusDataSource(
    this.modelFilter,
    [],
  );
  tripDataSource: TripDataSource = new TripDataSource(this.modelFilter, []);
  storeSub: Subscription;

  driverAttendanceDataSource: DriverAttendanceDataSource = new DriverAttendanceDataSource(
    this.modelFilter,
    [],
  );

  weeklyTripSheetDataSource: WeeklyTripSheetDataSource = new WeeklyTripSheetDataSource(
    this.modelFilter,
    [],
  );

  driverStatusHistoryDataSource: DriverStatusHistoryDataSource = new DriverStatusHistoryDataSource(
    this.modelFilter,
    [],
  );

  driverStatusHistory: DriverStatusHistory[] = [];

  @Input() driverStatusHistoryColumns: string[] = [
    "id",
    "profile",
    "fieldName",
    "newStatus",
    "oldStatus",
    "comment",
  ];

  @Input() driverStatusHistoryTitle: string[] = [
    "Id",
    "Profile",
    "Field Name",
    "New Status",
    "Old Status",
    "Comment",
  ];

  @Input() actions: ColumnAction[] = [
    new DateFormatAction(
      "Created Date",
      "createdAt",
      this.getDateFormat.bind(this),
    ),
  ];

  eventSubscription: Subscription;
  /**
   * get the format date
   * @param event
   * @param order
   */
  getDateFormat(event: any, history: DriverStatusHistory): string {
    return "mediumDate";
  }

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    private title: Title,
    private driverProfileService: DriverProfileSearchService,
    private router: ActivatedRoute,
    public fetchTripSheet: FetchTripSheetService,
    public weekendDriverService: WeekendDriverService,
    public driverBookingDetailsService: DriverBookingDetailsService,
    public driverTripSearchService: DriverTripSearchService,
    public driverAttendanceDetailsService: DriverAttendanceDetailsService,
    public weeklyTripSheetDisplayService: WeeklyTripSheetDisplayService,
    public driverStatusHistoryService: DriverStatusHistoryService,
    public route: Router,
    public fetchDriverSnapshot: FetchDriverSnapshotService,
  ) {
    super(dialog, snackBar, store);
  }

  /**
   * listen the route params change if the there is any change in the route
   * parameter it will fetch the data according to the activated route value
   */
  ngOnInit(): void {
    // phoneNumber is passed as params to load driver profile details
    this.eventSubscription = this.route.events.subscribe((data) => {
      if (data instanceof NavigationEnd) {
        this.loadDriverDetails();

        return;
      }
    });
    this.loadDriverDetails();
  }

  /*
   * To load driver details
   */
  loadDriverDetails(): void {
    const routeParam: string = this.router.snapshot.params["phoneNumber"];
    if (routeParam) {
      this.searchText = routeParam;
      this.getDriver();
    }
    this.title.setTitle("Driver CRM");
    this.fetchUser();
    this.storeSub = this.storeState.subscribe((state: StoreState) => {
      this.availableStores = state.availableStores;
    });
  }

  /**
   * get the store name
   */
  get storeName(): string {
    if (this.tcDriver.profile.workLocation == null) {
      return "No Store";
    }
    const store: any = this.availableStores.filter(
      (availablestores) =>
        availablestores.storeId == this.tcDriver.profile.workLocation,
    );

    return store.length ? store[0].name : "";
  }

  // Function to check searchText digits
  searchDriver(): void {
    this.route.navigate(["/dashboard/driver-crm/" + this.searchText]);
  }

  getDriver(): void {
    if (this.searchText.toString().length == 0) {
      this.textAlert(
        "Invalid Format",
        "Please enter valid phone number (or) Id",
      );

      return;
    }
    this.loadProfileDetails();
  }

  /**
   * fetch the today's non approved trip sheets
   */
  fetchTripSheets(): void {
    const params: {
      driver_id: number;
  } = this.fetchTripSheet.getDriverTripSheets(
      this.tcDriver.driverId,
    );
    this.fetchTripSheet.getData(params).subscribe((data: TripSheet[]) => {
      this.tripSheets = data.sort((a, b) => {
        if (a.earningDate < b.earningDate) {
          return 1;
        }

        return -1;
      });
      this.tripSheetDataSource.selection.clear();
      this.tripSheetDataSource.data = this.tripSheets;

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      (error) => {
        this.somethingWentWrong();
      };
    });
  }

  /**
   * To show selected trip sheet details
   * @param event
   * @param tripSheet
   */
  showTripSheet(event: any, tripSheet: TripSheet): void {
    this.selectedTripSheet = tripSheet;
    this.drawer.open();
  }

  /**
   * As the name says,loads driver profile details
   * @param searchText ie phoneNumber is passed to get driver profile details
   */
  loadProfileDetails(): void {
    this.tcDriver = null;
    this.presentLoader();
    const params: {
      mobile_number: string;
    } = this.driverProfileService.getParams(this.searchText);
    this.driverProfileService.getData(params).subscribe(
      (response: DriverProfile[]) => {
        if (response.length > 0) {
          this.tcDriver = response[0];
          this.fetchTripSheets();
          this.loadDriverSnapshot();
          this.dismissLoader();
          this.bookingDataDetails();
        } else {
          this.textAlert(
            "Driver Profile doesn't exist",
            "Please enter valid Phone number/ Id",
          );
          this.dismissLoader();
        }
      },
      (error) => this.somethingWentWrong(),
    );
  }

  /**
   * @param driverId of rider is passed, to load this performance details
   */
  loadDriverSnapshot(): void {
    const params: {
      driver: number;
    } = this.fetchDriverSnapshot.getParams(this.tcDriver.driverId);
    this.fetchDriverSnapshot.getData(params).subscribe(
      (data: DriverSnapshot[]) => {
        this.snapDetails = data[0];
      },
      (error) => {
        this.somethingWentWrong();
      },
    );
  }

  createDriver(): void {
    const dialog: MatDialogRef<
      DriverOnBoardingComponent,
      any
    > = this.dialog.open(DriverOnBoardingComponent, {
      width: "750px",
      height: "700px",
      panelClass: "create-driver",
    });
  }

  async fetchUser(): Promise<void> {
    const state$: UserState = await this.userState
      .pipe(
        skipWhile((user) => !user),
        take(1),
      )
      .toPromise();
    this.user = state$.user;
  }

  reloadData(): void {
    this.loadProfileDetails();
  }

  /**
   * Fetch Booking details of an Rider
   */
  bookingDataDetails(): void {
    const params: {
      user_id: string;
    } = this.driverBookingDetailsService.getParams(this.tcDriver.driverId);
    this.driverBookingDetailsService
      .getData(params)
      .subscribe((data: BookingRequest[]) => {
        this.bookingDetails = data;
        this.bookingDetails = data.sort((a, b) => {
          if (a.bookedAt < b.bookedAt) {
            return 1;
          }

          return -1;
        });
        this.bookingLastTrip = this.bookingDetails[0];
        this.bookingStatusDataSource.selection.clear();
        this.bookingStatusDataSource.data = this.bookingDetails;
      });
  }

  /* Fetching trip details through service
   * @param searchText ie phoneNumber is passed to get trip details
   */
  loadTripDetails(): void {
    const params: HttpParams = this.driverTripSearchService.getParams(
      "",
      this.tcDriver.phoneNumber,
    );
    this.driverTripSearchService.getData(params).subscribe(
      (trips: Trips[]) => {
        const driverTrips: Trips[] = trips;
        this.tripDataSource.selection.clear();
        this.tripDataSource.data = driverTrips;
      },
      (err) => {},
    );
  }

  loadDriverAttendance(): void {
    const params: {
      driver_id: number;
    } = this.driverAttendanceDetailsService.getParams(
      this.tcDriver.profile.user,
    );
    this.driverAttendanceDetailsService
      .getData(params)
      .subscribe((attendance: DriverLoginLogout[]) => {
        this.driverAttendanceDataSource.selection.clear();
        this.driverAttendanceDataSource.data = attendance;
      });
  }

  /* Fetching weekly trip details through service
   * @param driver_id is passed to get Weekly trip details
   */
  fetchWeeklyTripSheet(): void {
    const params: {
      driver_id: number;
  } = this.weeklyTripSheetDisplayService.getParams(
      this.tcDriver.profile.user,
    );
    this.weeklyTripSheetDisplayService
      .getData(params)
      .subscribe((weeklyTrip: WeeklyTripSheet[]) => {
        this.weeklyTripSheets = weeklyTrip;
        this.weeklyTripSheetDataSource.data = this.weeklyTripSheets;
      });
  }

  /**
   * @param weekTrip data is passesd as event to selectedWeekTrip
   */
  showWeeklyTripsheet(event: any, weekTrip: WeeklyTripSheet): void {
    this.selectedTrip = undefined;
    this.selectedWeekTrip = weekTrip;
    this.drawer.open();
  }

  /**
   * @param trip data is passesd as event to selectedTrip
   */
  showTrip(trip: Trips): void {
    this.selectedWeekTrip = undefined;
    this.selectedTrip = trip;
    this.drawer.open();
  }

  // To empty trip and trip sheet data on closing dialog
  closeDrawer(): void {
    if (this.selectedTripSheet) {
      this.selectedTripSheet = null;
      this.fetchTripSheets();
    }
    this.selectedTrip = null;
    this.selectedWeekTrip = null;
    this.drawer.close();
  }

  /**
   * load the when the mat tab changed
   */
  loadData(): void {
    switch (this.index) {
      case 1:
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this, this.loadTripDetails();
        break;
      case 2:
        this.bookingDataDetails();
        break;
      case 3:
        this.loadDriverAttendance();
        break;
      case 4:
        this.fetchWeeklyTripSheet();
        break;
      case 5:
        this.fetchStatusHistory();
        break;
      default:
        return;
    }
  }

  /**
   * fetch the driver status history from server
   */
  fetchStatusHistory(): void {
    this.presentLoader();
    const params: {
      user_id: number;
    } = this.driverStatusHistoryService.getParams(this.tcDriver.profile.user);
    this.driverStatusHistoryService.getData(params).subscribe(
      (data: DriverStatusHistory[]) => {
        this.dismissLoader();
        this.driverStatusHistory = data;
        this.driverStatusHistoryDataSource.data = data;
      },
      (err) => {
        this.dismissLoader();
        this.somethingWentWrong();
      },
    );
  }

  /**
   * refresh the booking after edit the booking details
   * @param $event
   */
  refreshBookings($event: any): void {
    this.bookingDataDetails();
  }

  ngOnDestroy(): void {
    if(this.storeSub) {
      this.storeSub.unsubscribe();
    }

    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }
}

/**
 * Model to hold any server error message and push it to fabric
 */
export class GenericError {
  constructor(public message: any, public stack?: any) {
    if (message instanceof Error) {
      this.message = this.message.message;
      this.stack = this.message.stack;
    }
  }
}

export class CouponCodeFailError {
  constructor(public message: any) { }
}
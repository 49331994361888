import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BasePostProvider } from "@tendercuts/http";
import { GenericServerResponse } from "@tendercuts/models";

@Injectable({
  providedIn: "root",
})
export class EnableCODService extends BasePostProvider<GenericServerResponse> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof GenericServerResponse {
    return GenericServerResponse;
  }

  get endpoint(): string {
    return this.endpointService.enableCOD();
  }

  getParms(cusotmerID: number): { customer_id: number } {
    return { customer_id: cusotmerID };
  }
}

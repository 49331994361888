import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  CloseTripService,
  DriverTripSearchService,
  TripsCashEntriesService,
  UpdateTripService,
} from "../providers";
import {
  TodaysCashTripsComponent,
  TodaysTripListComponent,
  TripCashSplitupComponent,
  TripCashSplitupDialogueComponent,
} from "./components";
import {
  OrderIdSequenceComponent,
} from "./components/order-id-sequence/order-id-sequence.component";
import { CustomCommonModule } from "./customCommon.module";
import {
  DriversTripsGridComponent,
  DummyTripEditComponent,
  TripsActionButtonsComponent,
  TripsSearchComponent,
  TripCashTransactionsComponent,
  TripDashboardComponent,
  TripDetailComponent,
  TripGridComponent,
  TripRenderComponent,
  TripStatusComponent,
} from "./modules/trip-module";
import { ViewTripBtnComponent } from "./modules/trip-module/view-trip-btn";
import { ViewTripBtnDialogueComponent } from "./modules/trip-module/view-trip-btn-dialogue";
import { SharedModule } from "./shared/shared.module";
import { UtilsModule } from "./utils/utils.module";

@NgModule({
    declarations: [
        TodaysCashTripsComponent,
        TodaysTripListComponent,
        TripsSearchComponent,
        DriversTripsGridComponent,
        TripDetailComponent,
        TripStatusComponent,
        TripCashSplitupComponent,
        TripCashSplitupDialogueComponent,
        TripsActionButtonsComponent,
        TripCashTransactionsComponent,
        TripDashboardComponent,
        ViewTripBtnComponent,
        ViewTripBtnDialogueComponent,
        TripGridComponent,
        TripRenderComponent,
        DummyTripEditComponent
    ],
    imports: [
        SharedModule,
        CommonModule,
        UtilsModule,
        CustomCommonModule,
        MatDialogModule,
        BrowserModule,
        MatFormFieldModule,
        MatInputModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        TripCashSplitupDialogueComponent,
        TripCashSplitupComponent,
        DriversTripsGridComponent,
        TripDetailComponent,
        TripsActionButtonsComponent
    ],
    providers: [
        UpdateTripService,
        TripsCashEntriesService,
        DriverTripSearchService,
        CloseTripService,
    ]
})
export class TripModule {}

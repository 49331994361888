import { Injectable } from "@angular/core";
import { BaseGetProvider } from "@tendercuts/http";
import { Category } from "@tendercuts/models";

import { HttpClient } from "@angular/common/http";
import { EndPoint } from "@endpoint";

@Injectable()
export class ProductDataService extends BaseGetProvider<Category> {
  constructor(public http: HttpClient, public endpointService: EndPoint) {
    super(http);
  }

  // override this
  public preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get isCachable(): boolean {
    return true;
  }
  get isAuthenticatedEndpoint(): boolean {
    return false;
  }
  get authProtocol(): any {
    return null;
  }
  get model(): typeof Category {
    return Category;
  }
  get endpoint(): string {
    return this.endpointService.productStore;
  }
}

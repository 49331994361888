import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenavContainer } from "@angular/material/sidenav";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { StoreState } from "@store/store/state";
import { WeeklyTripSheet } from "@tendercuts/models";
import { WeeklyTripSheetDisplayService } from "@tendercuts/providers";
import { Subscription } from "rxjs";
import { skipWhile, take } from "rxjs/operators";
import { TableRowAction, WeeklyTripSheetDataSource } from "src/app/components";
import { BasePage } from "src/app/utils";
import { Filter, FilterGroup, FilterMatch, FilterModel } from "src/models";

/**
 * Dashboard for Weekly Trip Sheet Approve(DM).
 */
@Component({
  selector: "app-weekly-tripsheet-update",
  templateUrl: "./weekly-tripsheet-update.component.html",
  styleUrls: ["./weekly-tripsheet-update.component.scss"],
})
export class WeeklyTripsheetUpdateComponent
  extends BasePage
  implements OnInit, OnDestroy {
  // selected week
  selectedWeek: string;

  /**
   * seleted weekly trip sheet in the array list
   */
  selectedWeeklyTripSheet: WeeklyTripSheet;

  weeklyTripSheets: WeeklyTripSheet[] = [];

  @Input()
  rowClickCallback: TableRowAction = new TableRowAction(
    this.showDrawer.bind(this),
  );

  @Input() columnsToDisplay: string[] = [
    "id",
    "week_no",
    "sheetEarnings",
    "totalEarning",
    "weeklySheetStatus",
  ];

  @Input() titleToDisplay: string[] = [
    "Trip Sheet Id",
    "Week Number",
    "Sheet Earnings",
    "Total Earning",
    "Status",
  ];

  tripSheetSearchFilterGroup: FilterGroup = new FilterGroup([
    new Filter("Search", null, ["id", "weekNo"], FilterMatch.CONTAINS),
  ]);

  // To filter trip sheet status
  tripStatusFilterGroup: FilterGroup = new FilterGroup([
    new Filter("Pending", "Pending", "weeklySheetStatus"),
    new Filter("To Approve", "To Approve", "weeklySheetStatus"),
    new Filter("Confirmed", "Confirmed", "weeklySheetStatus"),
  ]);

  modelFilter: FilterModel = new FilterModel([
    this.tripStatusFilterGroup,
    this.tripSheetSearchFilterGroup,
  ]);

  weeklyTripSheetDataSource: WeeklyTripSheetDataSource = new WeeklyTripSheetDataSource(
    this.modelFilter,
    [],
  );

  @ViewChild("drawer") drawer: MatSidenavContainer;
  routerEventSubcriber: Subscription;

  constructor(
    public title: Title,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    private fetchWeeklytripsheetService: WeeklyTripSheetDisplayService,
    public router: Router,
    public route: ActivatedRoute,
  ) {
    super(dialog, snackBar, store);
  }

  /**
   * listen the route params change if the there is any change in the route
   * parameter it will fetch the data according to the activated route value
   */
  ngOnInit(): void {
    this.routerEventSubcriber = this.router.events.subscribe((data) => {
      if (data instanceof NavigationEnd) {
        this.fetchData();

        return;
      }
    });
    this.fetchData();
  }

  /*
   * fetch the data for the selected week which is from the routeparams
   */
  fetchData(): void {
    const routeParam: string = this.route.snapshot.params["selectedWeek"];
    this.title.setTitle("Weekly Trip Sheet");
    if (routeParam != undefined) {
      this.selectedWeek = routeParam;
      this.fetchStoreData();

      return;
    }
    const previousWeek: string | number =
      (+this.getNumberOfWeek - 1).toString().length == 1
        ? "0" + (+this.getNumberOfWeek - 1)
        : +this.getNumberOfWeek - 1;

    this.selectedWeek = new Date().getFullYear() + "-W" + previousWeek;
    this.fetchStoreData();
  }

  /**
   * fetch redux store data
   */
  async fetchStoreData(): Promise<void> {
    const storeState$: StoreState = await this.store
      .select((state) => state.storeState)
      .pipe(
        skipWhile((state) => state.loading == true),
        take(1),
      )
      .toPromise();

    this.fetchWeeklyTripSheets(storeState$.store);
  }

  /**
   * fetch the drivers data from api
   * and assigning to driverdata source
   * @param storeId
   */
  fetchWeeklyTripSheets(storeId: number): void {
    this.presentLoader();

    if (storeId == undefined) {
      this.textAlert("Please select store", "To load Driver's Data");
      this.dismissLoader();
    }

    const weekNo: string = this.selectedWeek.split("W")[1];
    const params: {
      week_no: string;
      store_id: number;
      status: number;
  } = this.fetchWeeklytripsheetService.getWeeklyTripSheets(
      weekNo,
      storeId,
    );
    this.fetchWeeklytripsheetService
      .getData(params)
      .subscribe((data: WeeklyTripSheet[]) => {
        this.dismissLoader();
        this.weeklyTripSheets = data;
        this.weeklyTripSheetDataSource.selection.clear();
        this.weeklyTripSheetDataSource.data = this.weeklyTripSheets;

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (error) => {
          this.dismissLoader();
          this.somethingWentWrong();
        };
      });
  }

  /**
   * search filter based on the search value
   * @param searchString
   */
  applyDriverSearchFilter(searchString: string): void {
    if (searchString === "") {
      this.tripSheetSearchFilterGroup.filters[0].selected = false;
      this.tripSheetSearchFilterGroup.filters[0].value = null;
    } else {
      this.tripSheetSearchFilterGroup.filters[0].selected = true;
      this.tripSheetSearchFilterGroup.filters[0].value = searchString;
    }
    this.weeklyTripSheetDataSource.filter = "true";
  }

  /**
   * trigger's on week change
   * and pass that selected week value // eg:- 2021-W05
   * will be passed into the router param
   * @param $event
   */
  onSelectWeek($event: any): void {
    this.router.navigate([
      "/dashboard/weekly-tripsheet-approve/" + $event.target.value,
    ]);
  }

  /**
   * return the running week no ex: 2020-W36
   */
  get maxWeek(): string {
    return (
      new Date().getFullYear().toString() + "-" + "W" + this.getNumberOfWeek
    );
  }

  /**
   * used to show last 10 week's in week sheet.
   * if year change showing the remaining weeks in last year.
   */
  get minWeek(): string {
    return Math.sign(+this.getNumberOfWeek - 10) == 1
      ? new Date().getFullYear().toString() +
          "-" +
          "W" +
          (+this.getNumberOfWeek - 10)
      : new Date().getFullYear() -
          1 +
          "-" +
          "W" +
          (53 - +(+this.getNumberOfWeek - 10));
  }

  /**
   * getter for get the current week number ex:36 week
   * if week no single digit "0" added in suffix.
   */
  get getNumberOfWeek(): string | number {
    const today: any = new Date();
    const firstDayOfYear: any = new Date(today.getFullYear(), 0, 1);
    const pastDaysOfYear: number = Math.floor(
      (today - firstDayOfYear) / (24 * 60 * 60 * 1000),
    );

    const weekNo: number = Math.ceil(pastDaysOfYear / 7);

    return weekNo.toString().length == 1 ? "0" + weekNo : weekNo;
  }

  /**
   * show the selected weekly trip sheet object in the drawer
   * @param event
   * @param weeklySheet
   */
  showDrawer(event: any, weeklySheet: WeeklyTripSheet): void {
    this.selectedWeeklyTripSheet = weeklySheet;
    this.drawer.open();
  }

  closeDrawer(status: boolean): void {
    if (status) {
      this.fetchStoreData();

      return;
    }

    this.drawer.close();
  }

  /**
   * filter the data source using chips
   * @param filter
   */
  getFilteredData(filter: Filter): void {
    filter.selected = !filter.selected;
    this.weeklyTripSheetDataSource.filter = "true";
  }

  ngOnDestroy(): void {
    if (this.routerEventSubcriber) {
      this.routerEventSubcriber.unsubscribe();
    }
  }
}

import { Serializable } from "./base";

export class InventoryLog implements Serializable<InventoryLog> {
  private inventorylogid: number;
  private sku: string;
  public createdat : Date;
  public relatedto: string;
  private stockupdatedfrom: string;
  private stockupdatedto: string;
  private store_id: string;
  private type_of_qty: string;

  constructor() {}

  get inventoryId() {
    return this.inventorylogid;
  }

  get skuName() {
    return this.sku;
  }

  get relatedTo() {
    return this.relatedto;
  }

  get stockUpdatedFrom() {
    return this.stockupdatedfrom;
  }

  get stockUpdatedTo() {
    return this.stockupdatedto;
  }

  get storeId() {
    return this.store_id;
  }

  get quantityType() {
    return this.type_of_qty;
  }

  get createdTime() {
    return new Date(this.createdat).toLocaleTimeString();
  }

  deserialize(input) {
    this.inventorylogid = input.inventorylogid;
    this.sku = input.sku;
    this.createdat = input.createdat;
    this.relatedto = input.relatedto;
    this.stockupdatedfrom = input.stockupdatedfrom;
    this.stockupdatedto = input.stockupdatedto;
    this.store_id = input.store_id;
    this.type_of_qty = input.type_of_qty;

    return this;
  }
}

<div
  *ngIf="tripObject"
  class="map-header d-flex justify-content-end align-items-center"
>
  <i
    *ngIf="isCloseable"
    (click)="dialogClose.emit(false)"
    class="menu-item-icon fa fa-window-close-o"
  ></i>
  <button
    mat-stroked-button
    color="primary"
    (click)="resetSequence()"
    class="m-2"
    id="reset-sequence-btn"
  >
    Reset Sequence
  </button>
  <button
    mat-stroked-button
    id="update-sequence-btn"
    color="primary"
    class="m-2"
    (click)="updateSequence()"
    [disabled]="sequencedOrder.length != tripObject?.driverOrder.length"
  >
    Update Sequence
  </button>
</div>

<div class="map-side" *ngIf="selectedStore | async">
  <!-- Display all unsequenced orders in the trip -->
  <div class="all-orders" *ngIf="tripObject">
    <div *ngIf="nextNearestOrder != undefined">
      <p class="pane-title">
        <span class="col-6">Next Nearest Order</span>
      </p>
      <div
        class="order-group"
        (mouseover)="clickedOrder = nextNearestOrder"
        (mouseout)="clickedOrder = undefined"
      >
        <h6 class="header">
          <app-order-id-display
            [order]="orderMap[nextNearestOrder.incrementId]"
            [showTime]="true"
          ></app-order-id-display>
        </h6>
        <p class="address">
          {{ orderMap[nextNearestOrder.incrementId].shippingAddress.fax }}, {{
          orderMap[nextNearestOrder.incrementId].shippingAddress.apartment }},
          {{ orderMap[nextNearestOrder.incrementId].shippingAddress.landmark }}
        </p>
      </div>
    </div>
    <p class="pane-title">
      <span class="col-6">Trip Orders</span>
    </p>
    <div
      class="order-group"
      *ngFor="let order of unSequencedOrder"
      (mouseover)="clickedOrder = order"
      (mouseout)="clickedOrder = undefined"
    >
      <h6 class="header">
        <app-order-id-display
          [order]="orderMap[order.incrementId]"
          [showTime]="true"
        ></app-order-id-display>
      </h6>
      <p class="address">
        {{ orderMap[order.incrementId].shippingAddress.fax }}, {{
        orderMap[order.incrementId].shippingAddress.apartment }}, {{
        orderMap[order.incrementId].shippingAddress.landmark }}
      </p>
    </div>
  </div>

  <agm-map
    #map
    (mapReady)="renderSegment($event)"
    [zoom]="zoom"
    [zoomControlOptions]="{ position: 3 }"
    [latitude]="(selectedStore | async).location.lat"
    [longitude]="(selectedStore | async).location.lng"
    [disableDefaultUI]="false"
    [zoomControl]="true"
  >
    <div *ngFor="let order of tripObject?.driverOrder">
      <!--Order markers-->
      <app-address-marker
        [order]="orderMap[order.incrementId]"
        [isSelected]="sequencedOrder.indexOf(order) != -1"
        [sequenceNumber]="getSequenceNumber(order)"
        [displayWindow]="order == clickedOrder"
        [circleRadius]="100"
        [markerIcon]="loadIcon(order)"
        (onClick)="addOrderToSequenceList(order)"
      ></app-address-marker>
    </div>

    <!--Store marker-->
    <app-store-marker
      [storeObj]="selectedStore | async"
      [showMarker]="false"
    ></app-store-marker>

    <!-- Render store segments -->
    <ng-container *ngIf="mapRawRef">
      <app-store-segment
        *ngFor="let segment of segments"
        [map]="mapRawRef"
        [noHover]="true"
        [storeSegment]="segment"
        [highlight]="hoveredSegment == segment"
      ></app-store-segment>
    </ng-container>
  </agm-map>

  <!-- Display the sequenced order list  -->
  <div class="all-orders" *ngIf="tripObject">
    <p class="pane-title">
      <span class="col-6">Trip Order Sequence</span>
    </p>
    <div class="order-group" *ngFor="let number of sequence; let i = index">
      <div *ngFor="let order of getOrdersInSequence(number); let j = index">
        <div
          class="order"
          *ngIf="order.sequence == number"
          (mouseover)="clickedOrder = order"
          (mouseout)="clickedOrder = undefined"
        >
          <h6 class="header d-flex justify-content-around">
            <b>{{ order.sequence }}.{{ order.internalSequenceNumber }}</b>
            <app-order-id-display
              [order]="orderMap[order.incrementId]"
              [showTime]="true"
            ></app-order-id-display>
          </h6>
          <p class="address">
            {{ orderMap[order.incrementId].shippingAddress.fax }}, {{
            orderMap[order.incrementId].shippingAddress.apartment }}, {{
            orderMap[order.incrementId].shippingAddress.landmark }}
          </p>
          <div *ngIf="getOrdersInSequence(number).length > 1">
            <button
              class="inner-swap-button"
              mat-button
              [disabled]="j == 0"
              (click)="moveUpInGroup(number, order.internalSequenceNumber)"
            >
              Up <i class="fa fa-arrow-up" aria-hidden="true"></i>
            </button>
            <button
              class="inner-swap-button"
              mat-button
              [disabled]="j == getOrdersInSequence(number).length - 1"
              (click)="moveDownInGroup(number, order.internalSequenceNumber)"
            >
              Down <i class="fa fa-arrow-down" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="button-container">
        <button
          class="swap-button"
          mat-button
          [disabled]="i == 0"
          (click)="moveUp(number)"
        >
          Up <i class="fa fa-arrow-up" aria-hidden="true"></i>
        </button>
        <button
          class="swap-button"
          mat-button
          [disabled]="i == sequence.length - 1"
          (click)="moveDown(number)"
        >
          Down <i class="fa fa-arrow-down" aria-hidden="true"></i>
        </button>
        <i
          class="fa fa-times ml-1"
          (click)="removeOrder(number)"
          aria-hidden="true"
        ></i>
      </div>
    </div>
  </div>
</div>

import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DriverOrder, SaleOrder, Trips } from "@tendercuts/models";
import {
  FetchOrderLocationService,
  OrderLocationService,
} from "@tendercuts/providers";

import DirectionsResult = google.maps.DirectionsResult;
import DirectionsLeg = google.maps.DirectionsLeg;
import { HttpParams } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { Subscription } from "rxjs";
import { BasePage } from "../../utils";

@Component({
  selector: "app-order-eta-map",
  templateUrl: "./order-eta-map.component.html",
  styleUrls: ["./order-eta-map.component.scss"],
})
export class OrderEtaMapComponent extends BasePage {
  direction: {
    origin: any;
    destination: any;
    waypoints: any[];
  } = { origin: null, destination: null, waypoints: [] };

  customerOrder: SaleOrder;
  pendingOrder: DriverOrder[] = [];
  destinationOrder: any;
  trip: Trips;

  distance: number = 0;
  time: number = 0;

  constructor(
    private tripOrderService: FetchOrderLocationService,
    private driverLocationService: OrderLocationService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
  ) {
    super(dialog, snackBar, store);
  }

  /**
   * mapdata as  input to this component
   * it consist trip object and orderid of holdorder
   */
  @Input() set mapData(data: any) {
    this.destinationOrder = data.order;
    this.trip = data.trip;
    this.computeOriginPoint(this.trip, this.destinationOrder);
  }

  /**
   * used to calculate the orgin point of the trip
   * @param trip
   * @param order
   */
  computeOriginPoint(trip: any, order: SaleOrder): void {
    const orderId: string = order.incrementId;
    const params: HttpParams = this.driverLocationService.getParams(trip.id);
    const location: Subscription = this.driverLocationService
      .getData(params)
      .subscribe(
        (data) => {
          const driverLoc: any = data[0];
          this.direction.origin = {
            lat: driverLoc.lat,
            lng: driverLoc.lng,
          };
          this.computeDirection(orderId, trip);
        },
        (err) => {
          this.textAlert(
            "Alert",
            "Order is out for delivery but no tracking is available",
          );
        },
      );
  }

  /**
   * Computes the direction component
   * when some order is trying to unhold
   * the map direction and destination will be changed
   * according to that holded order
   */
  computeDirection(
    orderId: string,
    trip: Trips,
  ): {
    origin: any;
    destination: any;
    waypoints: any[];
  } {
    const waypoints: any[] = [];
    let orders: DriverOrder[] = trip.driverOrder.filter(
      (order) => order.status != "complete",
    );
    // we need only order that are in processing/delivery
    orders = orders.sort((a, b) => a.sequence - b.sequence);

    let orderReached: boolean = false;
    orders.forEach((order) => {
      if (orderReached) {
        return;
      }
      waypoints.push({
        location: { lat: order.lat, lng: order.lng },
        stopover: true,
      });
      this.pendingOrder.push(order);
      if (order.incrementId == orderId) {
        orderReached = true;
      }
    });
    if (!orders.filter((order) => order.incrementId == orderId)[0]) {
      waypoints.push({
        location: {
          lat: this.destinationOrder.shippingAddress.Olatitude,
          lng: this.destinationOrder.shippingAddress.Olongitude,
        },
        stopover: true,
      });
    }
    if (waypoints.length > 0) {
      const end: any = waypoints.pop();
      this.direction.destination = end.location;
    }

    this.direction.waypoints = waypoints;

    return this.direction;
  }

  onAfterRender(response: DirectionsResult): void {
    // google routes object
    response.routes[0].legs.forEach((leg: DirectionsLeg) => {
      this.distance += leg.distance.value / 1000;
      this.time += leg.duration.value / 60;
    });

    this.distance = Math.round(this.distance);
    this.time = Math.round(this.time);
  }
}

import { HttpParams } from "@angular/common/http";
import { Component, EventEmitter, Output } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import { Constants } from "@app/env";

@Component({
  selector: "app-store-credit-filter",
  templateUrl: "./store-credit-filter.component.html",
  styleUrls: ["./store-credit-filter.component.scss"],
})
export class StoreCreditFilterComponent {
  filterParams: { [key: string]: string } = {
    start_date: "",
    end_date: "",
    amount_type: "",
    reason: "",
  };

  @Output() storeCreditFilter: EventEmitter<HttpParams> =
    new EventEmitter<HttpParams>();

  filters: FormGroup = this.fb.group(
    {
      transactionControl: [],
      reasonControl: [],
      dateGroup: this.fb.group({
        startDateControl: [],
        endDateControl: [],
      }),
    },
    { validators: this.dateGroupValidator() }
  );

  constructor(public constants: Constants, private fb: FormBuilder) { }

  onTransactionSelect(transactionType: string): void {
    this.filterParams.amount_type = transactionType;
  }

  onReasonSelect(reason: string): void {
    this.filterParams.reason = reason.trim();
  }

  get selectedStartDate(): Date {
    return this.filters.get('dateGroup').get('startDateControl').value;
  }

  get selectedEndDate(): Date {
    return this.filters.get('dateGroup').get('endDateControl').value;
  }

  onStartDateSelect(startDate: Date): void {
    this.filterParams.start_date = this.getFormattedDate(startDate);
  }

  onEndDateSelect(endDate: Date): void {
    this.filterParams.end_date = this.getFormattedDate(endDate);
  }

  /**
   * Returns formatted date that is accepted by server
   */
  getFormattedDate(date: Date): string {
    const formattedDate: string =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

    return formattedDate;
  }

  /**
   * Loops through the set of filters
   * and creates a consolidated HttpParams object
   * with the selected ones
   */
  onSubmit(): void {
    let params: HttpParams = new HttpParams();
    Object.entries(this.filterParams).forEach((param) => {
      if (param[1]) {
        params = params.append(param[0], param[1]);
      }
    });
    this.storeCreditFilter.emit(params);
  }

  // reset all filters
  onClearFilters(): void {
    this.filters.reset();
    this.filterParams = {
      start_date: "",
      end_date: "",
      amount_type: "",
      reason: "",
    };
    this.storeCreditFilter.emit(new HttpParams());
  }

  // Checks if start and end dates have been selected
  dateGroupValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      const dateGroup: FormGroup = group.controls["dateGroup"] as FormGroup;
      if (dateGroup.pristine) {
        return null;
      }
      if (
        dateGroup.controls["startDateControl"].pristine ||
        dateGroup.controls["endDateControl"].pristine
      ) {
        return { dateGroupInvalid: true };
      }

      return null;
    };
  }
}

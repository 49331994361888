import { Directive, Input, ViewContainerRef } from "@angular/core";

@Directive({
  // TODO
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[detail]",
})
// TODO
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class GridRowHost {
  // TODO
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input("detail") detail: any;
  constructor(public viewContainerRef: ViewContainerRef) {}
}

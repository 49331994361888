import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BaseGetProvider, DataTransformationProtocol } from "@tendercuts/http";
import { TripCashEntries } from "@tendercuts/models";

@Injectable()
export class TripsCashEntriesService extends BaseGetProvider<TripCashEntries>
  implements DataTransformationProtocol {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http);
    this.delegate = this;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof TripCashEntries {
    return TripCashEntries;
  }

  get endpoint(): string {
    return this.endpointService.tripTodaysCash();
  }

  get isCachable(): boolean {
    return false;
  }
  preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  getParams(storeId: string): HttpParams {
    const params: HttpParams = new HttpParams();
    params.append("store_id", storeId.toString());

    return params;
  }
}

import {Serializable} from "../base";

export class ComplaintTag implements Serializable<ComplaintTag> {
  public tagName: string;

  constructor() {
  }

  deserialize(input) {
    this.tagName = input.tag_name;
    return this;
  }
}

export class Complaint implements Serializable<Complaint> {
  public source: string;
  public comments: string;
  public rating: number;
  public customer: number;
  public tags: ComplaintTag[] = [];

  private store_id: number;
  private order_id: string;
  private created_at: Date;

  get storeId() {
    return this.store_id;
  }

  get orderId() {
    return this.order_id;
  }

  get createdAt() {
    return this.created_at;
  }

  constructor() {
  }

  deserialize(input) {
    this.source = input.source;
    this.comments = input.comments;
    this.rating = input.rating;
    this.customer = input.customer;
    this.store_id = input.store_id;
    this.order_id = input.increment_id;
    this.created_at = input.created_at;

    for (let tag of input.complaint_tag) {
      this.tags.push(new ComplaintTag().deserialize(tag));
    }

    return this;
  }
}

import { Serializable } from "../base";

export class NearestStore implements Serializable<NearestStore> {
  private store_id: number;
  public status: boolean;
  public message: string;
  constructor() {}

  get storeId(): number {
    return this.store_id;
  }

  deserialize(input) {
    this.store_id = input.store_id;
    this.status = input.status;
    this.message = input.message;
    return this;
  }
}

<div>
  <div class="row">
    <span>Order ID :</span>
    <span>{{ order.incrementId }}</span>
  </div>
</div>
<div class="row map">
  <app-order-eta-map [mapData]="data"></app-order-eta-map>
</div>
<br />
<div id="buttons">
  <span
    ><button (click)="returnToStore()" mat-raised-button color="primary">
      Return to Store
    </button></span
  >
  <span
    ><button
      id="trip"
      (click)="returnToTrip()"
      mat-raised-button
      color="primary"
    >
      Return to Trip
    </button></span
  >
</div>

import { StoreSegment } from "@tendercuts/models";
import { Filter, FilterMatch } from "./base";

export class StoreAreaFilter extends Filter {
  constructor(
    public segment: StoreSegment,
    public nameDisplayed: string,
    public value: any,
    public searchField: string | string[],
    public matchType: FilterMatch = FilterMatch.EXACT,
    public selected: boolean = false,
  ) {
    super(nameDisplayed, value, searchField, matchType, selected);
  }

  get displayName(): string {
    return `${this.segment.name} (${this.segment.orderCount} orders)`;
  }
}

import { Injectable } from "@angular/core";

import { BaseGetProvider } from "@tendercuts/http";
import { Location } from "@tendercuts/models";

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";

@Injectable()
export class RealtimeTrackService extends BaseGetProvider<Location> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
  }

  getHeaders(token: string): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Content-type", "application/json");
    headers = headers.append("Accept", "application/json");
    headers = headers.append(
      "Authorization",
      "Basic dHJhY2s6ZUNraCo3dlloRllvUWtxMg==",
    );

    return headers;
  }

  // override this
  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof Location {
    return Location;
  }

  get endpoint(): string {
    return this.endpointService.liveTrackOrder;
  }

  get isCachable(): boolean {
    return false;
  }

  /**
   * Helper function to generate params.
   * @param orderId
   */
  getParams(orderId: any): HttpParams {
    let params: HttpParams = new HttpParams();
    params = params.append("order_id", orderId);

    return params;
  }
}

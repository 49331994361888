import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { Category, Product, SaleItem, SaleOrder } from "@tendercuts/models";
import { skipWhile, take } from "rxjs/operators";

// TODO
// eslint-disable-next-line @typescript-eslint/naming-convention
export interface CategoryCuts {
  name: string;
  items: SaleItem[];
  freshCut: SaleItem[];
  nonFreshCut: SaleItem[];
}

@Injectable()
export class SkuSplitService {
  FRESHCUTSKU: Set<string> = new Set([
    "CHK_BIRI_CUT",
    "CHK_BONELESS",
    "CHK_BR_BONELESS",
    "CHK_THIGH_AND_LEG_BONELESS",
    "CHK_WHL_SKIN_OFF",
    "CHK_WHL_SKIN_ON",
    "CHK_DRM_SKIN_OFF",
    "CHK_LEG_SKIN_OFF",
    "CHK_LOLLI",
    "CHK_MINCE",
    "CHK_WINGS",
    "GT_BIRYANI_CUT",
    "GT_BONELESS",
    "GT_CURRY_CUT",
    "GT_FREE_RANGE_GOAT_SHOULDER_MEAT",
    "GT_MINCE",
    "GT_RIBCHOPS",
  ]);

  productMap: { [id: number]: Product };

  initialCategories: { [id: number]: CategoryCuts } = {};

  constructor(public store: Store<AppState>) {
    this.fetchCategories();
  }

  /**
   * fetch the categories from redux and add MISCELLANEOUS category also
   */
  fetchCategories(): void {
    this.store
      .select((state) => state.catalog)
      .pipe(
        skipWhile((state) => state.loading),
        take(1),
      )
      .subscribe((state) => {
        this.productMap = state.productMap;
        state.catalog.forEach((cat: Category) => {
          this.initialCategories[cat.categoryId] = {
            name: cat.name,
            items: [],
            freshCut: [],
            nonFreshCut: [],
          };
        });
        this.initialCategories[0] = {
          name: "Miscellaneous",
          items: [],
          freshCut: [],
          nonFreshCut: [],
        };
      });
  }

  /**
   * Differentiate SaleItems using categories dictionary
   * returns categories.
   * @param orders
   */

  skuCategorization(orders: SaleOrder[]): any {
    const categories: any = JSON.parse(JSON.stringify(this.initialCategories));
    orders.forEach((order: SaleOrder) => {
      order.items.forEach((orderItem: SaleItem) => {
        if (!orderItem.isSimple) {
          return;
        }
        // fetch the product data from redux and map the category
        const cat: number = this.productMap[orderItem.productId]
          ? this.productMap[orderItem.productId].categoryId
          : 0;
        let catModel: any = categories[cat];
        if (!catModel) {
          catModel = categories[0];
        }

        const itemIndex: any = catModel.items
          .map((item: SaleItem) => item.productId)
          .indexOf(orderItem.productId);
        if (itemIndex !== -1) {
          this.existingItem(itemIndex, catModel, orderItem);
        } else {
          this.addingNewItem(orderItem, catModel);
        }
      });
    });

    return categories;
  }

  /**
   * saleItem exist incrementing the item quantity
   * @param itemIndex
   * @param catModel
   * @param orderItem
   */
  existingItem(itemIndex: number, catModel: any, orderItem: SaleItem): void {
    // eslint-disable-next-line radix
    const itemMapQuantity: number = parseInt(
      "" + catModel.items[itemIndex].quantity
    );
    // eslint-disable-next-line radix
    const orderItemQuantity: number = parseInt("" + orderItem.quantity);
    catModel.items[itemIndex].quantity = itemMapQuantity + orderItemQuantity;
  }

  /**
   * If item not exist push the item into items array and
   * item SKU has exist in freshCut set pushing into a freshcut array
   * else pushing into a nonFreshCut array.
   * @param orderItem
   * @param catModel
   */
  addingNewItem(orderItem: SaleItem, catModel: any): void {
    const product: SaleItem = new SaleItem().deserialize({
      sku: orderItem.SKU,
      qty_ordered: orderItem.quantity,
      product_id: orderItem.productId,
      name: orderItem.name,
      plu_code: orderItem.pluCode,
    });
    catModel.items.push(product);
    if (this.FRESHCUTSKU.has(product.SKU)) {
      catModel.freshCut.push(product);
    } else {
      catModel.nonFreshCut.push(product);
    }
  }
}

import { Action } from "@ngrx/store";
import {
  GenericError,
  Store,
  StoreSegment,
  TcutsCity,
} from "@tendercuts/models";
import { Filter } from "src/models";

/**
 * Action to trigger store loading from server
 */
export class ActionGetStores implements Action {
  readonly type: string = "ACTION_GET_STORES";
}

/**
 * Restricts the selection to certain stores only
 */
export class ActionRestrictStores implements Action {
  readonly type: string = "ACTION_RESTRICT_STORES";

  constructor(public allowedStores: number[]) {}
}

/**
 * Action to indicate that all stores are loaded.
 */
export class ActionAllStoreLoaded implements Action {
  readonly type: string = "ACTION_ALL_STORES_LOADED";

  constructor(public payload: Store[]) {}
}

/**
 * Load the store from storage.
 */
export class ActionLoadStore implements Action {
  readonly type: string = "ACTION_LOAD_STORE";

  constructor() {}
}

/**
 * Load the store segments from storage.
 */
export class ActionLoadStoreSegments implements Action {
  readonly type: string = "ACTION_LOAD_STORE_SEGMENTS";

  constructor(public storeId: number) {}
}

/**
 * Load the store segments from storage.
 */
export class ActionSetStoreSegments implements Action {
  readonly type: string = "ACTION_SET_STORE_SEGMENTS";

  constructor(public segments: StoreSegment[]) {}
}

/**
 * Set the store to redux state
 */
export class ActionSetStore implements Action {
  readonly type: string = "ACTION_SET_STORE";

  constructor(public payload: number) {}
}

/**
 * Error action
 */
export class ActionStoreError implements Action {
  readonly type: string = "ACTION_STORE_ERROR";

  constructor(public payload: GenericError) {}
}

/**
 * ActionLoadDeliverySlots is to fetch delivary slot details
 */
export class ActionLoadDeliverySlots implements Action {
  readonly type: string = "ACTION_LOAD_DELIVERY_SLOTS";

  constructor(public payload: number) {}
}

/**
 * ActionDeliverSlotsLoaded is called when slots are loaded & store delivery slots details
 */
export class ActionDeliverySlotsLoaded implements Action {
  readonly type: string = "ACTION_DELIVERY_SLOTS_LOADED";

  constructor(public payload: { slotFilterGroup: Filter[]; slotMap: any }) {}
}

/**
 * ActionDeliverySlotsLoadFailed is loaded when slots are not loaded
 */
export class ActionDeliverySlotsLoadFailed implements Action {
  readonly type: string = "ACTION_DELIVERY_SLOTS_LOAD_FAILED";

  constructor(public payload: GenericError) {}
}

/**
 * Action trigger from dashboard component itself
 */
export class ActionLoadAllStores implements Action {
  readonly type: string = "ACTION_LOAD_ALL_STORES";
  constructor() {}
}

/**
 * Action trigger on store load success
 */
export class ActionStoresLoadSuccess implements Action {
  readonly type: string = "ACTION_STORES_LOAD_SUCCESS";

  constructor(public stores: Store[]) {}
}

/**
 * ActionLoadCities is triggered to fetch city list
 */
export class ActionLoadCities implements Action {
  readonly type: string = "ACTION_LOAD_CITIES";
}

/**
 * Action to trigger storing fetched cities details
 */
export class ActionSaveCities implements Action {
  readonly type: string = "ACTION_SAVE_CITIES";
  constructor(public payload: TcutsCity[]) {}
}
/**
 * Action triggered when city load fails
 */
export class ActionErrorLoadCities implements Action {
  readonly type: string = "ACTION_ERROR_LOAD_CITIES";
  constructor(public payload: GenericError) {}
}

export class LiveTrack {
  private arrive_at: string;
  public eta: string;
  public message: string;
  public title: string;

  deserialize(input) {
    this.title = input.title;
    this.message = input.message;
    this.arrive_at = input.arrive_at;
    this.eta = input.eta;

    return this;
  }

  get arrivedAt(): string {
    return this.arrive_at;
  }
}

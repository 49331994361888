import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Store } from "@ngrx/store";

import { AppState } from "@store/state";
import { ActionLoadStore } from "@store/store";
import { ActionLoadUser } from "@store/user";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./styles/app.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.store.dispatch(new ActionLoadUser());
    this.store.dispatch(new ActionLoadStore());
  }
}

<div class="row radio-group">
  <div class="col-3 driver-img">
    <img src="assets/dummy-profile.png" alt="" />
  </div>
  <div class="col-8 driver-detail">
    <span [ngClass]="driverStatus" class="row">
      {{ driver.name }} - {{ driver.phone }}
    </span>
    <span *ngIf="driver.id !== -1" class="row driver-status">
      <span>
        {{ driverStatus | titlecase }}
      </span>
    </span>
  </div>
</div>

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-symbol-legend",
  templateUrl: "./symbol-legend.component.html",
  styleUrls: ["./symbol-legend.component.scss"],
})
export class SymbolLegendComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { SaleItem } from "@tendercuts/models";
import { BasePage } from "../../../utils";

/**
 * Quanity dialog model for user to enter the no of quanity of product available
 */
@Component({
  selector: "app-quantity-dialog",
  templateUrl: "./quantity-dialog.html",
  styleUrls: ["./quantity-dialog.scss"],
})
export class QuantityDialogComponent extends BasePage implements OnInit {
  quantityForm: FormGroup;

  // stores ordered quantity count and saleItem array
  productSelectionObj: {
    qty_ordered: number;
    item: SaleItem;
  }[] = [];

  constructor(
    public dialogRef: MatDialogRef<QuantityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialog: MatDialog,
    public matSnackBar: MatSnackBar,
    public store: Store<AppState>,
  ) {
    super(matDialog, matSnackBar, store);
  }

  ngOnInit(): void {
    if (this.data) {
      this.productSelectionObj = this.data;
    }

    this.quantityForm = new FormGroup({
      enteredQuantity: new FormControl("", [
        Validators.required,
        Validators.min(1),
        Validators.max(this.data.qty_ordered),
      ]),
    });
  }

  // Function to validate input field
  validateQuantityForm(): void {
    if (!this.quantityForm.valid) {
      this.iconAlert(
        "Warning",
        "Please enter valid quantity",
        null,
        "exclamation-circle",
        "warning",
      );

      return;
    }
    this.closeDialog();
  }

  // Available Quantity entered is filtered on clicking ok button
  closeDialog(): void {
    this.dialogRef.close({
      quantityAvailable: this.quantityForm.controls["enteredQuantity"].value,
    });
  }
}

import {Filter, FilterGroup} from "./base";

export let slotFilterGroup: FilterGroup = new FilterGroup([
  new Filter("7:30-9:30", 52, "scheduled_slot"),
  new Filter("9:30-11:30", 53, "scheduled_slot"),
  new Filter("10:30-12:30", 51, "scheduled_slot"),
  new Filter("11:30-13:30", 54, "scheduled_slot"),
  new Filter("13:00-16:00", 58, "scheduled_slot"),
  new Filter("14:30-17:00", 55, "scheduled_slot"),
  new Filter("18:30-20:30", 57, "scheduled_slot"),
  new Filter("19:30-21:30", 56, "scheduled_slot"),
  new Filter("Express", 1, "deliverytype"),
  new Filter("Store-Pickup", 998, "scheduled_slot"),
]);

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BasePostProvider } from "@tendercuts/http";
import { GenericServerResponse } from "@tendercuts/models";

@Injectable()
export class UnHoldOrderService extends BasePostProvider<GenericServerResponse> {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof GenericServerResponse {
    return GenericServerResponse;
  }

  get endpoint(): string {
    return this.endpointService.unHoldOrder();
  }

  get isCachable(): boolean {
    return false;
  }

  getparams(
    orderId: string,
    returnToStore: boolean,
  ): {
    increment_id: string;
    can_add: boolean;
  } {
    const params: {
      increment_id: string;
      can_add: boolean;
    } = {
      increment_id: orderId,
      can_add: returnToStore,
    };

    return params;
  }
}

import { Component, Input } from "@angular/core";
import { BaseGridComponent } from "../base-grid/base-grid.component";

@Component({
  selector: "app-weekly-trip-sheets-display-tab",
  templateUrl: "../base-grid/base-grid.component.html",
  styleUrls: ["../base-grid/base-grid.component.scss"],
})
export class WeeklyTripSheetGridComponent extends BaseGridComponent {
  @Input() columnsToDisplay: string[] = [
    "id",
    "week_no",
    "sheetEarnings",
    "totalEarning",
    "weeklySheetStatus",
  ];

  @Input() titleToDisplay: string[] = [
    "Trip Sheet Id",
    "Week Number",
    "Sheet Earnings",
    "Total Earnings",
    "Status",
  ];
}

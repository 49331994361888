import { Serializable } from '../base';

export class DesignateStore implements Serializable<DesignateStore> {
    public value: string;
    public entity: number;
    public value_id: number;
    public attribute: number;
    constructor() { }

    deserialize(input) {
        this.value = input.value;
        this.entity = input.entity;
        this.value_id = input.value_id;
        this.attribute = input.attribute;
        return this;
    }
}

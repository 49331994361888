import { Injectable } from "@angular/core";

import { BasePutProvider } from "@tendercuts/http";
import { GenericServerResponse } from "@tendercuts/models";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";

@Injectable({
  providedIn: "root",
})
export class WeekendDriverService extends BasePutProvider<
  GenericServerResponse
> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
  }

  get key(): string {
    return "id";
  }

  getHeaders(token: string): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();

    if (this.isAuthenticatedEndpoint) {
      headers = headers.set("Authorization", `Token ${token}`);
    }

    return headers;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof GenericServerResponse {
    return GenericServerResponse;
  }

  get endpoint(): string {
    return this.endpointService.weekendRider();
  }

  generateEndpoint(model: any): string {
    if (!this.isAuthenticatedEndpoint) {
      return this.endpoint;
    }

    const id: any = model.get(this.key);

    return `${this.endpoint}/${id}/`;
  }

  get isCachable(): boolean {
    return false;
  }

  /**
   * change driver location params
   * @param driverId
   * @param storeId
   */
  getWorkLocationParams(
    driverId: number,
    driverProfileId: number,
    storeId: number,
  ): FormData {
    const params: FormData = new FormData();
    params.append("user", driverId.toString());
    params.append("id", driverProfileId.toString());
    params.append("work_location", storeId.toString());

    return params;
  }

  /**
   * driver profile update params
   * @param driverId
   * @param driverProfileId
   * @param is_freelancer
   * @param is_weekend_user
   */
  getDriverUpdateProfileParams(
    driverId: number,
    driverProfileId: number,
    isFreelancer: boolean,
    isWeekendUser: boolean,
  ): FormData {
    const params: FormData = new FormData();
    params.append("user", driverId.toString());
    params.append("id", driverProfileId.toString());
    params.append("is_freelancer", isFreelancer.toString());
    params.append("is_weekend_user", isWeekendUser.toString());

    return params;
  }
}

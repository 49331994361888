import { Component, Input } from "@angular/core";
import { BaseGridComponent } from "../../../components/base-grid/base-grid.component";

@Component({
  selector: "app-trip-sheet-grid",
  templateUrl: "../../../components/base-grid/base-grid.component.html",
  styleUrls: ["../../../components/base-grid/base-grid.component.scss"],
})
export class TripSheetGridComponent extends BaseGridComponent {
  @Input() columnsToDisplay: string[] = ["driverId", "driverName"];
  @Input() titleToDisplay: string[] = ["Driver-Id", "Driver-Name"];
  @Input() footerColumnsToDisplay: string[] = [];
}

import { Injectable } from "@angular/core";
import { Store as AppStore } from "@ngrx/store";

import * as fromStore from "@store/state";
import { AppState } from "@store/state";
import { AuthProtocol, BasePostProvider } from "@tendercuts/http";
import { GenericServerResponse, InventoryRequest, User } from "@tendercuts/models";

import { HttpClient } from "@angular/common/http";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { Observable } from "rxjs";

@Injectable()
export class InventoryRequestService
  extends BasePostProvider<GenericServerResponse>
  implements AuthProtocol {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public store: AppStore<AppState>,
    public loginService: LoginServices,
  ) {
    super(http);
  }

  getToken(): Observable<User> {
    return this.store.select(fromStore.getUser);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof GenericServerResponse {
    return GenericServerResponse;
  }

  get endpoint(): string {
    return this.endpointService.inventoryRequest();
  }

  getParams(request: InventoryRequest): string {
    return JSON.stringify(request);
  }
}

import { Serializable } from "../base";

export class TripsHistory implements Serializable<TripsHistory> {
    
    private path_string: string;
    public status: boolean;

    get pathString(): string {
        return this.path_string;
    }

    constructor() {}

    deserialize(input) {
        this.path_string = input.path_string;
        this.status = input.status;
        return this;
    }
}
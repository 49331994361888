import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { UserState } from "@store/user/state";
import {
  BookingRequest,
  DriverProfile,
  GenericServerResponse,
  User,
} from "@tendercuts/models";
import {
  ConvertShiftService,
  DriverLogoutService,
  GenerateBookingRequestService,
  GetDriverOtpService,
  UnBlockDriverService,
} from "@tendercuts/providers";
import { skipWhile, take } from "rxjs/operators";
import { DriverOnBoardingComponent } from "src/app/pages/driver-onboarding";
import { BasePage } from "src/app/utils";
import { DriverProfileUpdateComponent } from "../driver-profile-updates/driver-profile-updates";
import { DriverSuspendComponent } from "../driver-suspend";
import {
  SwitchWorkLocationComponent,
} from "../switch-work-location/switch-work-location.component";

/**
 * component to handle drivers actions
 */
@Component({
  selector: "app-driver-actions",
  templateUrl: "./driver-actions.component.html",
  styleUrls: ["./driver-actions.component.scss"],
})
export class DriverActionsComponent extends BasePage implements OnInit {
  // selected driver details
  @Input() driver: DriverProfile;
  @Input() driverBookings: BookingRequest;

  // check for user permissions
  user: User;
  // an event emitter to affect parent component on actions performed
  @Output()
  refreshParentEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    private getDriverOtpService: GetDriverOtpService,
    private driverLogoutService: DriverLogoutService,
    private unBlockDriverService: UnBlockDriverService,
    private generateBookingRequestService: GenerateBookingRequestService,
    private convertShiftService: ConvertShiftService,
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    this.fetchUser();
  }

  /**
   * fetch logged user details
   */
  async fetchUser(): Promise<void> {
    const state$: UserState = await this.userState
      .pipe(
        skipWhile((user) => !user),
        take(1),
      )
      .toPromise();
    this.user = await state$.user;
  }

  /**
   * fetch the selected driver otp from server
   */
  getOtp(): void {
    const params: {
      mobile_number: number;
    } = this.getDriverOtpService.getParams(this.driver.phoneNumber);
    this.getDriverOtpService.getData(params).subscribe(
      (responseData) => {
        if (responseData[0].status) {
          return this.textAlert(
            "Recieved Otp is",
            "" + responseData[0].message,
          );
        }

        this.showNotification(
          "bottom",
          "center",
          "danger",
          "info-circle",
          responseData[0].message,
        );
      },
      (error) => this.somethingWentWrong(),
    );
  }

  /**
   * logout the selected driver
   */
  driverLogout(): void {
    this.presentLoader();
    const params: {
      user_id: string;
    } = this.driverLogoutService.getParams(this.driver.driverId);
    this.driverLogoutService.getData(params).subscribe(
      (responseData) => {
        this.dismissLoader();
        if (responseData[0].status) {
          return this.textAlert(responseData[0].message, "");
        }

        this.showNotification(
          "bottom",
          "center",
          "danger",
          "info-circle",
          responseData[0].message,
        );
      },
      (error) => {
        this.dismissLoader();
        this.somethingWentWrong();
      },
    );
  }

  /**
   * unblock the selected driver
   */
  unBlockDriver(): void {
    this.presentLoader();
    const params: {
      user_id: string;
    } = this.unBlockDriverService.getParams(this.driver.driverId);
    this.unBlockDriverService.getData(params).subscribe(
      (responseData) => {
        this.dismissLoader();
        if (responseData[0].status) {
          this.showNotification(
            "bottom",
            "center",
            "success",
            "info-circle",
            responseData[0].message,
          );
          this.refreshParentEmitter.emit(true);

          return;
        }

        this.showNotification(
          "bottom",
          "center",
          responseData[0].status ? "success" : "danger",
          "info-circle",
          responseData[0].message,
        );
      },
      (error) => {
        this.dismissLoader();
        this.somethingWentWrong();
      },
    );
  }

  /**
   * Send booking request to driver
   */
  generateBookingRequest(): void {
    const params: {
      user_id: string;
    } = this.generateBookingRequestService.getParams(this.driver.driverId);
    this.presentLoader();
    this.generateBookingRequestService.getData(params).subscribe(
      (responseData: GenericServerResponse[]) => {
        this.dismissLoader();
        if (responseData[0].status) {
          return this.showNotification(
            "top",
            "center",
            "success",
            "info-circle",
            responseData[0].message,
          );
        }

        this.showNotification(
          "bottom",
          "center",
          "danger",
          "info-circle",
          responseData[0].message,
        );
      },
      (error) => {
        this.dismissLoader();
        this.somethingWentWrong();
      },
    );
  }

  /**
   * Convert the driver to full shift
   */
  convertFullShift(): void {
    const params: {
      status: string;
      date: Date;
      shift: string;
    } = this.convertShiftService.getParams(
      this.driverBookings.bookingStatus,
      this.driverBookings.bookedAt,
      "full_shift",
    );
    this.presentLoader();
    this.convertShiftService.getData(params).subscribe(
      (responseData: GenericServerResponse) => {
        this.dismissLoader();
        if (responseData[0].status) {
          return this.showNotification(
            "top",
            "center",
            "success",
            "info-circle",
            responseData[0].message,
          );
        }

        this.showNotification(
          "bottom",
          "center",
          "danger",
          "info-circle",
          responseData[0].message,
        );
      },
      (error) => {
        this.dismissLoader();
        this.somethingWentWrong();
      },
    );
  }

  /*
   * update the rider profile
   */
  showDriverTagDialog(): void {
    const drivertagDialog: MatDialogRef<
      DriverProfileUpdateComponent,
      any
    > = this.dialog.open(DriverProfileUpdateComponent, {
      data: { driver: this.driver },
      width: "500px",
      height: "225px",
    });
    drivertagDialog.afterClosed().subscribe((status) => {
      if (status) {
        this.refreshParentEmitter.emit(true);
      }
    });
  }

  /*
   * Block the rider profile
   */
  suspendDriver(): void {
    const drivertagDialog: MatDialogRef<
      DriverSuspendComponent,
      any
    > = this.dialog.open(DriverSuspendComponent, {
      data: this.driver,
      width: "400px",
      height: "398px",
    });
    drivertagDialog.afterClosed().subscribe((status: boolean) => {
      if (status) {
        this.refreshParentEmitter.emit(true);
      }
    });
  }

  /**
   * switch the store to the rider
   */
  changeDriverWorkLocation(): void {
    const changestoreDialog: MatDialogRef<
      SwitchWorkLocationComponent,
      any
    > = this.dialog.open(SwitchWorkLocationComponent, {
      data: { driver: this.driver },
      width: "500px",
      height: "225px",
    });
    changestoreDialog.afterClosed().subscribe((data) => {
      if (data) {
        this.refreshParentEmitter.emit(true);
      }
    });
  }

  // Fn to edit driver details for now acc no alone
  editDriverDetails(): void {
    const dialogData: {
      editMode: boolean;
      driver: DriverProfile;
    } = {
      editMode: true,
      driver: this.driver,
    };

    const updatePopup: MatDialogRef<
      DriverOnBoardingComponent,
      any
    > = this.dialog.open(DriverOnBoardingComponent, {
      data: dialogData,
      disableClose: false,
      width: "750px",
      height: "400px",
      panelClass: "create-driver"
    });

    updatePopup.afterClosed().subscribe((data) => {
      if (data) {
        this.refreshParentEmitter.emit(true);
      }
    });
  }
}

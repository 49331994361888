<form class="filter-row" [formGroup]="filters" (ngSubmit)="onSubmit()">
  <mat-form-field>
    <mat-select
      formControlName="transactionControl"
      (selectionChange)="onTransactionSelect($event.value)"
    >
      <mat-option value="credit">Credit</mat-option>
      <mat-option value="debit">Debit</mat-option>
    </mat-select>
    <mat-label>Transaction type</mat-label>
  </mat-form-field>
  <mat-form-field>
    <mat-select
      formControlName="reasonControl"
      (selectionChange)="onReasonSelect($event.value)"
    >
      <mat-option *ngFor="let reason of constants.reasons" [value]="reason">
        {{ reason }}
      </mat-option>
    </mat-select>
    <mat-label>Reason</mat-label>
  </mat-form-field>
  <div [formGroup]="filters.get('dateGroup')">
    <mat-form-field class="filter-row__date">
      <input
        matInput
        formControlName="startDateControl"
        [matDatepicker]="start"
        [max]="selectedEndDate"
        placeholder="Start date"
        (dateChange)="onStartDateSelect($event.value)"
      />
      <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
      <mat-datepicker #start></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="filter-row__date">
      <input
        matInput
        formControlName="endDateControl"
        [matDatepicker]="end"
        [min]="selectedStartDate"
        placeholder="End date"
        (dateChange)="onEndDateSelect($event.value)"
      />
      <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
      <mat-datepicker #end></mat-datepicker>
    </mat-form-field>
  </div>
  <button
    [disabled]="filters.pristine && !filters.get('dateGroup').dirty"
    class="btn btn--clear"
    (click)="onClearFilters()"
    mat-flat-button
    type="button"
  >
    Clear
  </button>
  <button
    [disabled]="filters.pristine || !filters.valid"
    class="btn btn--submit"
    mat-flat-button
    type="submit"
  >
    Submit
  </button>
</form>

import { HttpParams } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { FreshdeskTicket, User } from "@tendercuts/models";
import { FreshdeskTicketsService } from "@tendercuts/providers";
import { FilterModel } from "src/models";
import { FreshDeskTicketDataSource } from "../data-source";
import {
  ColumnAction,
  DateFormatAction,
  HyperLinkAction,
} from "../table-actions";

@Component({
  selector: "app-freshdesk-ticket",
  templateUrl: "./freshdesk-ticket.component.html",
  styleUrls: ["./freshdesk-ticket.component.scss"],
})
export class FreshdeskTicketComponent implements OnInit {
  @Input() customerObj: User = null;

  @Input()
  columnsToDisplay: string[] = [
    "subject",
    "voc",
    "disposition",
    "description",
    "status",
  ];

  @Input()
  titleToDisplay: string[] = [
    "Subject",
    "Voc",
    "Disposition",
    "Description",
    "Status",
  ];

  @Input() actions: ColumnAction[] = [
    new DateFormatAction("Date", "created_at", this.getDateFormat.bind(this)),
    new HyperLinkAction("Detail", "id", this.redirectToFreshDeskUrl.bind(this)),
  ];
  tickets: FreshdeskTicket[];

  modelFilter: FilterModel = new FilterModel([]);

  @Input()
  freshDeskTicketDataSource: FreshDeskTicketDataSource = new FreshDeskTicketDataSource(
    this.modelFilter,
    [],
  );

  constructor(private ticketService: FreshdeskTicketsService) {}

  ngOnInit(): void {
    const params: HttpParams = this.ticketService.getParams(this.customerObj.email);
    this.ticketService
      .getData(params)
      .subscribe((tickets: FreshdeskTicket[]) => {
        this.tickets = tickets;
        this.freshDeskTicketDataSource.data = tickets;
      });
  }

  getDateFormat(event: any, complaint: FreshdeskTicket): string {
    return "mediumDate";
  }

  redirectToFreshDeskUrl(event: any, ticket: FreshdeskTicket): void {
    window.open(ticket.url, "_blank");
  }
}

import { Serializable } from "../base";
export class HyperTrack implements Serializable<HyperTrack> {
  private share_url: string;
  public status: string;

  constructor() {}

  get shareUrl(): string {
    return this.share_url;
  }

  deserialize(input) {
    if (!input) {
      return this;
    }
    this.share_url = input.share_url;
    this.status = input.status;

    return this;
  }
}

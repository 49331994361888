import { Component, Input, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { CashTransaction, Trips } from "@tendercuts/models";

@Component({
  selector: "app-trip-cash-transactions",
  templateUrl: "./trip-cash-transactions.component.html",
  styleUrls: ["./trip-cash-transactions.component.scss"],
})
export class TripCashTransactionsComponent implements OnInit {
  @Input() trip: Trips;

  cashTransactions: CashTransaction[];

  cashTransactionsDataSource: MatTableDataSource<CashTransaction>;

  columnsToDisplay: string[] = [
    "cashEntry",
    "cashId",
    "amountCollected",
    "createdAt",
  ];
  constructor() {}

  ngOnInit(): void {
    if (this.trip.cash) {
      this.cashTransactions = this.trip.cash.cashEntries;
      this.cashTransactionsDataSource = new MatTableDataSource(
        this.cashTransactions,
      );
    }
  }
}

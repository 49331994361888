import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BaseGetProvider } from "@tendercuts/http";
import { DriverLoginLogout } from "@tendercuts/models";

@Injectable({
  providedIn: "root",
})
export class FetchWeeklyStoreDriversService extends BaseGetProvider<DriverLoginLogout> {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http);
    this.delegate = this;
  }

  // override this
  public preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof DriverLoginLogout {
    return DriverLoginLogout;
  }

  get endpoint(): string {
    return this.endpointService.fetchStoreWeeklyDrivers();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(
    storeId: number,
    selectedWeek: string,
  ): { store_id: string; week_no: string; year: string } {
    const weekNo: string = selectedWeek.split("W")[1];
    const year: string = selectedWeek.split("-")[0];

    const params: { store_id: string; week_no: string; year: string } = {
      store_id: storeId.toString(),
      week_no: weekNo,
      year,
    };

    return params;
  }
}

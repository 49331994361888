import { Serializable } from "./base";

export class LocationAgreed implements Serializable<LocationAgreed> {
  public isAgreed: boolean;

  deserialize(input) {
    if (!input) return;

    this.isAgreed = input.isAgreed;

    return this;
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { AuthProtocol } from "@tendercuts/http";
import { BasePostProvider, DataTransformationProtocol } from "@tendercuts/http";
import { GenericServerResponse, User } from "@tendercuts/models";
import { PubSubService } from "../pubsub";

@Injectable()
export class SignUpService
  extends BasePostProvider<GenericServerResponse>
  implements AuthProtocol, DataTransformationProtocol {
  KEY: any = "user22";
  public user: User;

  constructor(
    public http: HttpClient,
    public events: PubSubService,
    public endpointService: EndPoint,
  ) {
    super(http);
  }

  getToken(): string {
    return this.user.token;
  }

  get model(): typeof GenericServerResponse {
    return GenericServerResponse;
  }
  get isAuthenticatedEndpoint(): boolean {
    return false;
  }
  get authProtocol(): any {
    return null;
  }
  get endpoint(): string {
    return this.endpointService.signUp;
  }
  postProcessResponse(data: any): User {
    this.user = this.data[0];

    return this.user;
  }
}

import { Serializable } from "../base";

export class StoreBarcode implements Serializable<StoreBarcode> {
  public barcode: string;
  public store: number;
  private created_at: Date;
  private times_used: number;
  private updated_at: string;

  get createdAt() {
    return this.created_at;
  }

  get timesUsed() {
    return this.times_used;
  }

  get updatedAt() {
    return this.updated_at;
  }

  constructor() {}

  deserialize(input) {
    this.store = input.store;
    this.barcode = input.barcode;
    this.created_at = input.created_at;
    this.times_used = input.times_used;
    this.updated_at = input.updated_at;
    return this;
  }
}

export class SalesFlatOrderItemBarcode implements Serializable<SalesFlatOrderItemBarcode> {
  public item: number;
  private created_at: Date;
  public barcode : StoreBarcode;

  get createdAt() {
    return this.created_at;
  }

  constructor() {}

  deserialize(input) {
    this.item = input.item;
    this.created_at = input.created_at;
    this.barcode = new StoreBarcode().deserialize(
      input.barcode
    );

    return this;
  }
}

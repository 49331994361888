import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { ActionLogoutUser } from "@store/user";
import { Observable } from "rxjs";
import { take, tap } from "rxjs/operators";

@Injectable()
export class Http403Interceptor implements HttpInterceptor {
  constructor(public store: Store<AppState>, public router: Router) {}

  /**
   * If api throws 403 status code redirect to login page
   * @param request
   * @param next
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event) => {
          return;
        },
        (err: any) => {
          if (err && err.status === 403) {
            this.store.dispatch(new ActionLogoutUser());
            this.router.navigate(["login"]);
          }
        }
      )
    );
  }
}

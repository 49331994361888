import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SaleOrder } from "@tendercuts/models";

@Component({
  selector: "app-order-display-dialog",
  templateUrl: "./order-display-dialog.component.html",
  styleUrls: ["./order-display-dialog.component.scss"],
})
export class OrderDisplayDialogComponent {
  // Selected order for display
  selectedOrder: SaleOrder;
  orderSource: SaleOrder[];
  isDialogOpen: boolean = false;
  isTripDetailsMode: boolean = false;
  isOrderHistoryMode: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<OrderDisplayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
  ) {
    if (data) {
      this.selectedOrder = data.order;
      this.orderSource = data.orderSource;
      this.isTripDetailsMode = data.tripDetailsMode;
      this.isDialogOpen = true;
      this.isOrderHistoryMode = data?.isOrderHistoryMode;
    }
  }

  closeDialog(): void {
    this.dialogRef.close(true);
  }
}

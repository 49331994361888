import { Serializable } from "./base";
export class Campaign implements Serializable<Campaign> {
  public timestamp: string;
  public name: string;
  public id: string;
  constructor() {}

  get campaignDate() {
    return new Date(this.timestamp);
  }

  serialize() {
    return {
      campaign_id: this.id,
      campaign_name: this.name,
      campaign_timestamp: this.timestamp,
    };
  }

  deserialize(input) {
    if (!input) {
      return this;
    }
    this.timestamp = input.timestamp;
    this.name = input.name;
    this.id = input.id;
    return this;
  }
}

/**
 * RedirectionLink model is used to classify the deeplink
 * received from subscribers like Branch as Product or Category Link etc...
 * Redirection links follow a pattern of [IDENTIFIER + '_' + Key]
 * For product, link will be like "product_chicken_boneless"
 * For category, link will be like "category_marinades"
 *
 * i.e branch deeplink structure
    {
       "data_parsed":{
          "$desktop_url":"http://localhost:8100/",
          "$marketing_title":"Category Redirection 1",
          "$one_time_use":false,
          "+click_timestamp":1601398653,
          "+clicked_branch_link":true,
          "+is_first_session":true,
          "+match_guaranteed":true,
          "c_campaign_id":"1010",
          "c_campaign_name":"test link",
          "c_campaign_timestamp":"NOW",
          "c_redirection_link":"category_marinades",
          "~campaign":"test link",
          "~channel":"WhatApp",
          "~creation_source":1,
          "~feature":"marketing",
          "~id":"839393190729835551",
          "~marketing":true,
          "~referring_link":"https://tcuts.test-app.link/Jajrc0NAaab"
       },
       "developer_identity":null,
       "has_app":false,
       "identity":null,
       "referring_identity":null,
       "referring_link":"https://bnc.lt/c/Jajrc0NAaab?__branch_flow_id=839533678258098264"
    }
 */

export class RedirectionLink implements Serializable<RedirectionLink> {
  public key: string;
  private type: string;
  private redirection_link: string;

  PRODUCT_IDENTIFIER = "product_";
  CATEGORY_IDENTIFIER = "category_";
  RATING_IDENTIFIER = "rating_";
  REFER_IDENTIFIER = "refer_";
  CART_IDENTIFIER = "cart_";
  ELITE_IDENTIFIER = "elite_";
  STORE_IDENTIFIER = "store_";

  get isCategoryLink(): boolean {
    return this.type === "category";
  }

  get isProductLink(): boolean {
    return this.type === "product";
  }

  get isRatingLink(): boolean {
    return this.type === "rating";
  }

  get isReferFiendLink(): boolean {
    return this.type === "refer";
  }

  get isCartLink(): boolean {
    return this.type === "cart";
  }

  get isEliteLink(): boolean {
    return this.type === "elite";
  }

  get isStoreLink(): boolean {
    return this.type === "store";
  }

  deserialize(input: any): this {
    if (!input) {
      return this;
    }
    this.redirection_link = input.redirection_link;
    if (this.redirection_link.startsWith(this.PRODUCT_IDENTIFIER)) {
      this.key = this.redirection_link.split(this.PRODUCT_IDENTIFIER)[1];
      this.type = "product";
    }
    if (this.redirection_link.startsWith(this.CATEGORY_IDENTIFIER)) {
      this.key = this.redirection_link.split(this.CATEGORY_IDENTIFIER)[1];
      this.type = "category";
    }
    if (this.redirection_link.startsWith(this.RATING_IDENTIFIER)) {
      this.key = this.redirection_link.split(this.RATING_IDENTIFIER)[1];
      this.type = "rating";
    }
    if (this.redirection_link.startsWith(this.REFER_IDENTIFIER)) {
      this.key = this.redirection_link.split(this.REFER_IDENTIFIER)[1];
      this.type = "refer";
    }
    if (this.redirection_link.startsWith(this.CART_IDENTIFIER)) {
      this.key = this.redirection_link.split(this.CART_IDENTIFIER)[1];
      this.type = "cart";
    }
    if (this.redirection_link.startsWith(this.ELITE_IDENTIFIER)) {
      this.key = this.redirection_link.split(this.ELITE_IDENTIFIER)[1];
      this.type = "elite";
    }
    if (this.redirection_link.startsWith(this.STORE_IDENTIFIER)) {
      this.key = this.redirection_link.split(this.STORE_IDENTIFIER)[1];
      this.type = "store";
    }

    return this;
  }
}

import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { Store as AppStore } from "@ngrx/store";
import * as selectors from "@store/state";
import { AppState } from "@store/state";

import { AgmMap, LatLng } from "@agm/core";
import { ActionLoadStoreSegments, StoreState } from "@store/store";
import { Location, Store, StoreGeohash, StoreSegment } from "@tendercuts/models";
import { FetchStoreGeohashService, OrdersByDateService } from "@tendercuts/providers";
import Geohash from 'latlon-geohash';
import { skipWhile, take } from "rxjs/operators";
import { BasePage } from "../../utils";

/**
 * Types for Searched Record details
 */
// TODO
// eslint-disable-next-line @typescript-eslint/naming-convention
interface SearchRecord {
  date: string;
  orders: Location[];
  selected: boolean;
  searchIndex: number;
  statusValue: string;
  statusLabel: string;
}
/** Type of Status option */
interface StatusOption {
  value: string;
  label: string;
}
@Component({
  selector: "app-stores-coverage",
  templateUrl: "./stores-coverage.component.html",
  styleUrls: ["./stores-coverage.component.scss"],
})
export class StoresCoverageComponent
  extends BasePage
  implements OnInit, AfterViewInit {
  @ViewChild("map") map: AgmMap;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) matTable: any;
  mapRawRef: any;
  mapZoom: number = 13;
  center: number[] = [];
  geohashTiles: LatLng[][] = [];
  clusterData: {
    gridSize: number;
    maxZoom: number;
    imagePath: string;
  } = {
      gridSize: 100,
      maxZoom: 14,
      imagePath: "assets/marker/cluster/m",
    };

  storeSegment: StoreSegment = new StoreSegment();
  showOlderSegments: boolean = false;
  segments: StoreSegment[] = [];
  statusOptions: StatusOption[] = [
    {
      label: "Completed",
      value: "complete",
    },
    {
      label: "Hold RNR",
      value: "hold_rnr",
    },
    {
      label: "Hold CRC",
      value: "hold_crc",
    },
    {
      label: "Hold Pin",
      value: "hold_pin",
    }
  ];
  selectedStatus: string;

  fromDate: Date;
  toDate: Date;
  currentStore: Store;
  searchedRecords: SearchRecord[] = []; // holds current searched records
  selectedRecords: SearchRecord[] = []; // holds select records from searched records
  searchIndex: number = 0; // index to keep track of the count of searches made
  columnsToDisplay: string[] = [];
  segmentEntries: {
    segment: string;
    // plus more dynamic order and contribution entries
  }[] = [];
  dataSource: MatTableDataSource<unknown> = new MatTableDataSource();

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public ordersByDateService: OrdersByDateService,
    public fetchStoreGeohashService: FetchStoreGeohashService,
    public store: AppStore<AppState>
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    this.loadSegments();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  async loadSegments(): Promise<void> {
    const storeState: StoreState = await this.store
      .select(selectors.getStoreState)
      .pipe(
        skipWhile(
          (state) =>
            state.availableStores.length == 0 ||
            typeof state.store == "undefined"
        ),
        take(1)
      )
      .toPromise();

    if (!storeState.store) {
      this.textAlert("Store Not Selected", "Select a store and try", "Ok");

      return;
    }

    const storeId: string = storeState.store.toString();
    this.storeSegment.store_id = parseInt(storeId);
    this.currentStore = storeState.availableStores.find(
      (store) => store.storeId == storeState.store
    );
    this.center = [
      parseFloat(this.currentStore.location.latitude),
      parseFloat(this.currentStore.location.longitude),
    ];
    this.store.dispatch(new ActionLoadStoreSegments(parseInt(storeId)));

    const newStoreState: StoreState = await this.store
      .select(selectors.getStoreState)
      .pipe(
        skipWhile((state) => state.loading),
        take(1)
      )
      .toPromise();

    this.segments = newStoreState.segments;
    this.segments.forEach((segment) => {
      this.segmentEntries.push({
        segment: segment.name,
      });
    });
    this.segmentEntries.push({
      segment: "Total",
    });
    this.dataSource.data = this.segmentEntries;
  }

  renderSegment(mapRef: any): void {
    this.mapRawRef = mapRef;
  }

  /**
   * Fn to make status dropdown empty
   * if any one of the date is selected
   */
  setStatusEmpty(): void {
    this.selectedStatus = "";
  }

  /**
   * To get the selected status's label
   */
  getStatusLabel(statusValue: string): string {
    const selectedStatus: StatusOption = this.statusOptions.find(
      (status: StatusOption) => status.value === statusValue
    );

    return selectedStatus.label;
  }

  /**
   * fn to reset the filter form
   */
  resetFilterForm(): void {
    this.fromDate = null;
    this.toDate = null;
    this.selectedStatus = "";
  }

  /**
   * 0. Checks whether already 3 searches are made, throws an alert if so.
   * 1. If from date and to date is not selected, then throws an alert.
   * 2. If to date lower than from date, then throws and alert
   * 3. When dates are avaiable formats the date in 'YYYY-MM-DD' and
   *    makes request to server
   * 4. Checks whether the status is selected
   * 5. If the date range and with status selected is already searched, then throws an alert.
   */
  validateDates(): void {
    if (this.searchedRecords.length >= 3) {
      this.textAlert(
        "Only 3 comparisons are allowed",
        "Kindly remove one set and continue ",
        "Ok"
      );

      return;
    }
    if (!this.fromDate || !this.toDate) {
      this.textAlert("Date Not Selected", "Select a date and try", "Ok");

      return;
    }
    if (this.fromDate > this.toDate) {
      this.textAlert(
        "Wrong Date Selection",
        "To Date should be greater than From Date",
        "Ok"
      );

      return;
    }
    if (!this.selectedStatus) {
      this.textAlert(
        "Status is not selected",
        "Please select a status from dropdown",
        "Ok"
      );

      return;
    }
    const fromDate: string = this.getFormattedDate(this.fromDate);
    const toDate: string = this.getFormattedDate(this.toDate);
    const selectedDate: string = fromDate + " to " + toDate;
    const isAlreadySearched: SearchRecord = this.searchedRecords.find(
      (existingSelection) => existingSelection.date === selectedDate &&
        existingSelection.statusValue === this.selectedStatus
    );
    if (isAlreadySearched) {
      this.textAlert("This date range with same status is already searched", "", "Ok");

      return;
    }
    this.searchOrders(fromDate, toDate, selectedDate);
  }

  /**
   * Fetches orders for the particular date range reuqested
   * 0. Throws an alert if no order is returned.
   * 1. Forms segment info if orders are returned.
   * 2. Forms new columns to display for new date range.
   * @param fromDate
   * @param toDate
   * @param selectedDate
   */
  searchOrders(fromDate: string, toDate: string, selectedDate: string): void {
    this.presentLoader();
    const params: {
      store_id: string;
      date: string;
      end_date: string;
      custom_status: string;
    } = this.ordersByDateService.getParams(
      fromDate,
      toDate,
      this.currentStore.storeId,
      this.selectedStatus,
    );
    this.ordersByDateService.getData(params).subscribe(
      (orderLocations: Location[]) => {
        this.dismissLoader();
        if (!orderLocations.length) {
          this.textAlert(
            "No Order Found",
            "Orders not found for selected date range",
            "Ok"
          );

          return;
        }
        const newRecord: SearchRecord = {
          date: selectedDate,
          orders: orderLocations,
          selected: true,
          searchIndex: ++this.searchIndex,
          statusValue: this.selectedStatus,
          statusLabel: this.getStatusLabel(this.selectedStatus),
        };
        this.searchedRecords.push(newRecord);
        this.formSegmentsInfo(newRecord, orderLocations);
        this.formColumnsToDisplay();
        this.resetFilterForm();
      },
      (err) => {
        this.resetFilterForm();
        this.somethingWentWrong();
      }
    );
  }

  /**
   * Allocates orders and contribution to their respective segment for the particular
   * date range selected.
   */
  formSegmentsInfo(
    searchRecord: SearchRecord,
    orderLocations: Location[]
  ): void {
    orderLocations.forEach((orderLocation) => {
      for (const storeSegment of this.segments) {
        if (!storeSegment.containsPoint(orderLocation.lat, orderLocation.lng)) {
          continue;
        }
        const segment: {
          segment: string;
        } = this.segmentEntries.find(
          (segmentEntry) => segmentEntry.segment === storeSegment.name
        );

        if (!segment[`orders_${searchRecord.searchIndex}`]) {
          segment[`orders_${searchRecord.searchIndex}`] = 1;
          segment[`per_${searchRecord.searchIndex}`] =
            1 * (100 / orderLocations.length);
          break;
        }
        segment[`orders_${searchRecord.searchIndex}`] += 1;
        segment[`per_${searchRecord.searchIndex}`] =
          segment[`orders_${searchRecord.searchIndex}`] *
          (100 / orderLocations.length);
        break;
      }
    });
    this.segmentEntries[this.segmentEntries.length - 1][
      `orders_${searchRecord.searchIndex}`
    ] = searchRecord.orders.length;
    this.segmentEntries[this.segmentEntries.length - 1][
      `per_${searchRecord.searchIndex}`
    ] = 100;
  }

  /**
   * Deletes the selected date range chip. And also removes that
   * date range entry in all the segment available.
   */
  removeDateRange(searchedDateRange: SearchRecord): void {
    const index: number = this.searchedRecords.findIndex(
      (dateRange) => dateRange.searchIndex === searchedDateRange.searchIndex
    );
    if (index === -1) {
      return;
    }
    this.searchedRecords.splice(index, 1);
    for (const segment of this.segmentEntries) {
      delete segment[`orders_${searchedDateRange.searchIndex}`];
      delete segment[`per_${searchedDateRange.searchIndex}`];
    }
    this.selectedStatus = "";
    this.formColumnsToDisplay();
  }

  deSelectDateRange(searchRecord: SearchRecord): void {
    searchRecord.selected = !searchRecord.selected;
    this.formColumnsToDisplay();
  }

  /**
   * Forms columns to display array from the selected search records
   */
  formColumnsToDisplay(): void {
    if (!this.searchedRecords.length) {
      this.selectedRecords = [];
      this.columnsToDisplay = [];

      return;
    }
    this.selectedRecords = this.searchedRecords.filter(
      (dateRange) => dateRange.selected
    );
    this.columnsToDisplay = ["segment"];
    this.selectedRecords.forEach((searchRecord) => {
      this.columnsToDisplay.push(`orders_${searchRecord.searchIndex}`);
      this.columnsToDisplay.push(`per_${searchRecord.searchIndex}`);
    });
  }

  /**
   * Our own table sorting logic
   * Sorts segments in ascending or descending order w.r.t its orders / contribution.
   */
  sortTable(event: any): void {
    const direction: any = event.direction;
    if (!direction) {
      return;
    }
    const last: {
      segment: string;
    } = this.segmentEntries.pop();
    const checkGreater: (a?: number, b?: number) => boolean = (a = 0, b = 0) =>
      a > b;
    const checkLesser: (a?: number, b?: number) => boolean = (a = 0, b = 0) =>
      a < b;
    this.segmentEntries.sort((segmentEntryA, segmentEntryB) => {
      const ordersA: any = segmentEntryA[event.active];
      const ordersB: any = segmentEntryB[event.active];
      if (direction == "asc") {
        if (checkGreater(ordersA, ordersB)) {
          return -1;
        }

        return 1;
      }
      if (checkLesser(ordersA, ordersB)) {
        return -1;
      }

      return 1;
    });
    this.segmentEntries.push(last);
    this.matTable.renderRows();
  }

  /**
   * Centers map with selected segment's center
   */
  centerMap(segmentName: string): void {
    const segment: StoreSegment = this.segments.find(
      (segmentData) => segmentData.name === segmentName
    );
    if (!segment || !segment.center) {
      return;
    }
    this.mapRawRef.setCenter(segment.center);
  }

  /**
   * Returns formatted date that is accepted by server
   */
  getFormattedDate(date: Date): string {
    const formattedDate: string =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

    return formattedDate;
  }

  /**
   * Based on the index, we show diff color code the flag
   * param index, returns string
   */
  markerUrl(index: number): string {
    if (index == 1) {
      return "assets/marker/orange.png";
    }
    if (index == 2) {
      return "assets/marker/red.png";
    }

    return "assets/marker/green.png";
  }

  /**
   * Toggles the Store's geohash tiles in map
   * 0. Checkbox unchecked - display nothing
   * 1. Fetch the geohashes based on store id
   * 2. Decode the geohashes to gbounds
   */
  toggleGeohashTiles(event: MatCheckboxChange): void {
    if (!event.checked) {
      this.geohashTiles = [];

      return;
    }

    this.presentLoader();
    const params: {
      store_id: number;
    } = this.fetchStoreGeohashService.getParams(
      this.currentStore.storeId
    );
    // Fetch all geohashes of store
    this.fetchStoreGeohashService.getData(params).subscribe(
      (storeGeohashes: StoreGeohash[]) => {
        storeGeohashes.forEach((storeGeohash: StoreGeohash) => {
          // Decode the geohash to sw, ne bounds
          const gbounds: {
            sw: {
              lat: number,
              lon: number
            },
            ne: {
              lat: number,
              lon: number
            }
          } = Geohash.bounds(storeGeohash.geohash);

          this.geohashTiles.push([
            new google
              .maps
              .LatLng(gbounds.sw.lat, gbounds.sw.lon),
            new google
              .maps
              .LatLng(gbounds.sw.lat, gbounds.ne.lon),
            new google
              .maps
              .LatLng(gbounds.ne.lat, gbounds.ne.lon),
            new google
              .maps
              .LatLng(gbounds.ne.lat, gbounds.sw.lon),
            new google
              .maps
              .LatLng(gbounds.sw.lat, gbounds.sw.lon)
          ]);
        });
        this.dismissLoader();
      },
      (err) => {
        this.somethingWentWrong();
      });
  }
}

<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

<section class="example-section" *ngIf="avlOrders.length > 0; else noOrders">
  <b
    >Note: You can re-assign only orders that are out for delivery
    /processing</b
  >
  <br />
  <hr />
  <div>
    <div class="select-all-section">
      <mat-checkbox
        (change)="toggleAllOrdersSelection($event)"
        [checked]="isAllOrdersSelected"
        [indeterminate]="!!selection.selected.length && !isAllOrdersSelected"
      >
        <b>Select All</b>
      </mat-checkbox>
    </div>
    <ng-container *ngFor="let order of avlOrders">
      <div>
        <mat-checkbox
          id="o-{{ order.incrementId }}"
          class="order-select"
          (change)="selection.toggle(order)"
          [checked]="selection.isSelected(order)"
        >
          {{ order.incrementId }}
        </mat-checkbox>
      </div>
    </ng-container>
  </div>
  <hr />
  <button
    mat-button
    [disabled]="selection.selected.length == 0 || loading"
    (click)="unassignOrders()"
  >
    Un-assign orders
  </button>
</section>

<ng-template #noOrders>
  <b>No orders are eligible for removing from the trip.</b>
</ng-template>

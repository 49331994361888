<mat-form-field class="store" (click)="focusOnSearchBar()">
    <mat-label *ngIf="!selectedStoreId">Select Store</mat-label>
    <mat-select [(ngModel)]="selectedStoreId" panelClass="top-nav-panel">
        <mat-option class="current-store">{{
            selectedStoreName
            }}</mat-option>

        <form class="row search-form">
            <div class="form-inline active-cyan-2">
                <input class="form-control mr-3 ml-4 mb-3" type="text" name="store" placeholder="Enter Store Name"
                    (keyup)="applyStoreSearchFilter($event.target.value)" autocomplete="off" id="searchbar-store"
                    autofocus />
            </div>
        </form>

        <mat-optgroup *ngFor="let city of sortedCity" [label]="city.name" class="label">
            <mat-option *ngFor="let store of city.filteredStores" [value]="store.storeId" (click)="changeStore()">
                {{ store.storeAttribute.displayName }}
            </mat-option>
        </mat-optgroup>
    </mat-select>
</mat-form-field>
import { Component, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { SaleItem, SaleOrder } from "@tendercuts/models";
import { debounceTime, take } from "rxjs/operators";
import { FilterMatch, NestedCounterFilter } from "src/models";
import { BasePage } from "../../utils/pages/base/base.component";
import { QuantityDialogComponent } from "./quantity-dialog";

/**
 * Product selection wizard for user`s to filter orders
 * by selective products
 */
@Component({
  selector: "app-product-selection",
  templateUrl: "./product-selection.component.html",
  styleUrls: ["./product-selection.component.scss"],
})
export class ProductSelectionComponent extends BasePage implements OnInit {
  // catalogMap
  catalogMap: {
    Chicken: {};
    Mutton: {};
    Seafood: {};
    Marinades: {};
    "Cold Cuts": {};
    Spices: {};
    Pickles: {};
  } = {
    Chicken: {},
    Mutton: {},
    Seafood: {},
    Marinades: {},
    "Cold Cuts": {},
    Spices: {},
    Pickles: {},
  };
  productsList: {
    item: SaleItem;
    qty_ordered: number;
  }[] = [];
  selectedProductFilter: NestedCounterFilter = null; // input from sales dashboard
  showProductList: boolean = false; // flag to toggle between tabs and list view
  searchResults: any[] = [];
  searchControl: FormControl = new FormControl();

  objectKeys: (o: object | {}) => string[] = Object.keys;

  // selectedValue,binded with radio button
  selectedValue: string = "";

  constructor(
    public dialogRef: MatDialogRef<ProductSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialog: MatDialog,
    public matSnackBar: MatSnackBar,
    public store: Store<AppState>,
  ) {
    super(matDialog, matSnackBar, store);
    this.selectedProductFilter = data.productFilter ? data.productFilter : null;
  }

  ngOnInit(): void {
    this.buildCatalogMap();
    this.searchControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe((value) => {
        this.searchResults = [];
        if (value === "") {
          this.showProductList = false;

          return;
        }
        this.showProductList = true;
        this.filterProducts(value.toLowerCase());
      });
  }

  /**
   * Builds the catalog by looking up products available
   * in the orders. Maintains a catalog map and productsList arra.
   */
  async buildCatalogMap(): Promise<void> {
    const orders: SaleOrder[] = await this.store
      .select((state) => state.orderState.orders)
      .pipe(take(1))
      .toPromise();

    orders.forEach((saleOrder) => {
      for (const item of saleOrder.items) {
        if (item.SKU.startsWith("CHK")) {
          this.registerProduct("Chicken", item);
          continue;
        }
        if (item.SKU.startsWith("GT") || item.SKU.startsWith("LAMB")) {
          this.registerProduct("Mutton", item);
          continue;
        }
        if (item.SKU.startsWith("SF")) {
          this.registerProduct("Seafood", item);
          continue;
        }
        if (item.SKU.startsWith("MRT")) {
          this.registerProduct("Marinades", item);
          continue;
        }
        if (item.SKU.startsWith("COLD")) {
          this.registerProduct("Cold Cuts", item);
          continue;
        }
        if (item.SKU.startsWith("THILLAIS")) {
          this.registerProduct("Spices", item);
          continue;
        }
        if (item.SKU.startsWith("PKL")) {
          this.registerProduct("Pickles", item);
        }
      }
    });
  }

  /**
   * Adds product to the catalog map and product list array.
   * If a product is already selected, then marks it as checked and
   * also updates it`s available quantity
   */
  registerProduct(category: string, item: SaleItem): void {
    if (this.catalogMap[category][item.SKU]) {
      this.catalogMap[category][item.SKU].qty_ordered += +item.quantity;

      return;
    }

    const skuObj: {
      item: SaleItem;
      qty_ordered: number;
    } = {
      item,
      qty_ordered: +item.quantity,
    };

    this.catalogMap[category][item.SKU] = skuObj;
    this.productsList.push(skuObj);
  }

  /**
   * Whenever the dialog is closed by submitting or closed intentionally
   * returns the selected products.
   */
  checkSelectionAndCloseDialog(): void {
    this.dialogRef.close(this.selectedProductFilter);
  }

  /**
   * Filters matching product by their name.
   */
  filterProducts(searchTerm: string): void {
    this.productsList.forEach(
      (productSelectionObj: { item: SaleItem; qty_ordered: number }) => {
        if (productSelectionObj.item.name.toLowerCase().match(searchTerm)) {
          this.searchResults.push(productSelectionObj);
        }
      },
    );
  }

  openQuantityDialog(productSelectionObj: {
    item: SaleItem;
    qty_ordered: number;
  }): void {
    if (
      this.selectedProductFilter &&
      this.selectedProductFilter.value.some(
        (sku) => sku === productSelectionObj.item.SKU,
      )
    ) {
      this.selectedProductFilter = null;

      return;
    }
    const dialogRef: MatDialogRef<
      QuantityDialogComponent,
      any
    > = this.dialog.open(QuantityDialogComponent, {
      data: productSelectionObj,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        this.selectedProductFilter = null;

        return;
      }
      if (result.quantityAvailable === undefined) {
        this.selectedProductFilter = null;

        return;
      }
      this.selectedProductFilter = new NestedCounterFilter(
        productSelectionObj.item.name,
        [productSelectionObj.item.SKU],
        "items",
        FilterMatch.EXACT,
        true,
        "SKU",
        "quantity",
        result.quantityAvailable,
      );
      this.checkSelectionAndCloseDialog();
    });
  }

  isSelectedProduct(sku: string): boolean {
    if (
      this.selectedProductFilter &&
      this.selectedProductFilter.value.indexOf(sku) > -1
    ) {
      return true;
    }

    return false;
  }

  canDisableProduct(sku: string): boolean {
    if (!this.selectedProductFilter) {
      return false;
    }
    if (this.selectedProductFilter.value.indexOf(sku) > -1) {
      return false;
    }

    return true;
  }

  /**
   * onclicking over clear button selected products are cleared
   */
  clearSelectedProduct(): void {
    this.selectedValue = null;
  }

  /**
   * onclicking over mat chip product is removed
   */
  clearFilter(): void {
    this.selectedProductFilter = null;
  }
}

import {  } from "./driver-dashboard";

import {
  ApproveWeeklyTripsheetComponent,
  ChangeLogsComponent,
  DriverDashboardComponent,
  IncentiveCommentComponent,
  KmViolationPopupComponent,
  SlabProgressBarComponent,
  SpeedoMeterImageComponent,
  SpeedoMeterValidationComponent,
  TargetProgressBarComponent,
  TripEarningConfirmComponent,
  TripEarningSegmentDisplay,
  TripSheetActionComponent,
  TripSheetApproveComponent,
  TripSheetConfirmComponent,
  TripSheetGridComponent,
  TripSheetPaymentOtpComponent,
  TripSheetPayComponent,
  TripSheetPenaltyComponent,
  TripSheetTitleComponent,
  TripSheetUpdateComponent,
  TripSheetWizardComponent,
  VerifyTripSheetComponent,
  VerifyTripSheetWraperComponent,
  VerifyWeeklyTripSheetComponent,
  WeeklyDriversDashboardComponent,
  WeeklyTripsheetUpdateComponent,
  WeeklyTripSheetActionsComponent,
  WeeksTargetComponent,
} from '.';
import {
  FetchDriverEarningsService,
  FetchStoreDriversService,
  TripEarningUpdateService,
  TripSheetGenerateService,
  TripSheetUpdateService,
} from "../../../providers";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDialogModule } from '@angular/material/dialog';
import { CustomCommonModule } from 'src/app/customCommon.module';
import { SharedModule } from "../../shared/shared.module";
import { UtilsModule } from "../../utils/utils.module";
import { TripSheetDetailsComponent } from './tripsheet-details/tripsheet-details.component';

@NgModule({
    declarations: [
        DriverDashboardComponent,
        TripSheetWizardComponent,
        VerifyTripSheetComponent,
        TripSheetGridComponent,
        TripSheetUpdateComponent,
        TripSheetConfirmComponent,
        TripEarningConfirmComponent,
        TripSheetApproveComponent,
        TripSheetPayComponent,
        TripSheetPaymentOtpComponent,
        TripSheetTitleComponent,
        VerifyTripSheetWraperComponent,
        ChangeLogsComponent,
        TripSheetActionComponent,
        TripEarningSegmentDisplay,
        TripSheetPenaltyComponent,
        SpeedoMeterImageComponent,
        KmViolationPopupComponent,
        SpeedoMeterValidationComponent,
        WeeklyDriversDashboardComponent,
        VerifyWeeklyTripSheetComponent,
        WeeksTargetComponent,
        SlabProgressBarComponent,
        TargetProgressBarComponent,
        WeeklyTripsheetUpdateComponent,
        WeeklyTripSheetActionsComponent,
        ApproveWeeklyTripsheetComponent,
        TripSheetDetailsComponent,
        IncentiveCommentComponent,
    ],
    imports: [SharedModule, UtilsModule, CustomCommonModule, CommonModule, MatDialogModule],
    exports: [TripSheetGridComponent, VerifyTripSheetComponent, VerifyWeeklyTripSheetComponent],
    providers: [
        TripSheetUpdateService,
        FetchStoreDriversService,
        FetchDriverEarningsService,
        TripEarningUpdateService,
        TripSheetGenerateService,
    ]
})
export class TripEarningModule {}

import { Serializable } from "./base";

export class Location implements Serializable<Location> {
  public latitude: string;
  public longitude: string;
  public display: string;

  get lat() {
    return parseFloat(this.latitude);
  }

  get lng() {
    return parseFloat(this.longitude);
  }

  get position() {
    return { lat: this.lat, lng: this.lng };
  }

  toString() {
    return `${this.lat},${this.lng}`;
  }

  constructor() {}

  deserialize(input) {
    // hack needs to be removed
    if ("longandlatis" in input) {
      input = input.longandlatis;
    }

    this.latitude = input.latitude;
    this.longitude = input.longitude;
    this.display = input.display;

    return this;
  }
}

export class LocationPing implements Serializable<LocationPing> {
  private _lat: string;
  private _lng: string;
  private odometer: number;
  private trip: number;
  public battery: number;
  public speed: number;

  get lat() {
    return parseFloat(this._lat);
  }

  get lng() {
    return parseFloat(this._lng);
  }

  toString() {
    return `${this.lat},${this.lng}`;
  }

  get odometerValue() {
    return this.odometer;
  }

  get tripId() {
    return this.trip;
  }

  constructor() {}

  deserialize(input) {
    this._lat = input.lat;
    this._lng = input.lon;
    this.battery = input.battery_level;
    this.odometer = input.odometer;
    this.speed = input.speed;
    this.trip = input.trip;

    return this;
  }
}

import { Serializable } from "../base";
import { Store } from "./store";

export class TcutsCity implements Serializable<TcutsCity> {
  private is_active: number;
  private stores: Store[] = [];
  private bounds_x: string;
  private bounds_y: string;
  private location_place_holder: string;
  public name: string;
  public code: string;
  public id: number;
  public gst: string;
  public phone: string;
  public filteredStores: Store[] = [];

  get storesList(): Store[] {
    return this.stores;
  }

  get boundsX(): { lat: number; lng: number } {
    let lat = this.bounds_x.split(",")[0];
    let lng = this.bounds_x.split(",")[1];

    return { lat: +lat, lng: +lng };
  }

  get boundsY(): { lat: number; lng: number } {
    let lat = this.bounds_y.split(",")[0];
    let lng = this.bounds_y.split(",")[1];

    return { lat: +lat, lng: +lng };
  }

  get placeHolder(): string {
    return this.location_place_holder;
  }

  get onlineStoresList(): Store[] {
    return this.stores.filter((store) => store.isOnlineStore);
  }

  // setter to set filtered stores to stores array
  set filteredStoresData(stores: Store[]) {
    this.filteredStores = stores.filter((store) => store.isOnlineStore);
  }

  get isActive(): boolean {
    return this.is_active == 1 ? true : false;
  }

  deserialize(input) {
    if (!input) {
      return this;
    }

    this.name = input.name;
    this.code = input.code;
    this.id = input.id;
    this.is_active = input.is_active;
    this.gst = input.gst;
    this.bounds_x = input.bounds_x;
    this.bounds_y = input.bounds_y;
    this.phone = input.phone;
    this.location_place_holder = input.location_place_holder;

    for (let list of input.stores) {
      this.stores.push(new Store().deserialize(list));
    }

    // remove stores which do not have storeAttribute setup properly
    this.stores = this.stores.filter((store) => store.storeAttribute);

    this.stores.sort((a, b) => {
      if (a.storeAttribute.displayName > b.storeAttribute.displayName) {
        return 1;
      }

      return -1;
    });

    this.filteredStores = this.stores.filter((store) => store.isOnlineStore);

    return this;
  }
}

import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSidenavContainer } from "@angular/material/sidenav";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { ActionCheckNewOrders } from "@store/orders";
import { AppState } from "@store/state";
import * as selectors from "@store/state";
import { StoreState } from "@store/store";
import {
  DeliverySlot,
  GenericServerResponse,
  SaleOrder,
} from "@tendercuts/models";
import { MoveProcessingService } from "@tendercuts/providers";
import { from, interval, of, Observable, Subscription } from "rxjs";
import { map, skipWhile, switchMap, take } from "rxjs/operators";
import { ProductSelectionComponent } from "src/app/components/product-selection";
import {
  Filter,
  FilterGroup,
  FilterMatch,
  FilterModel,
  NestedCounterFilter,
} from "src/models";
import { MoveToHoldService } from "src/providers/move-to-hold";
import { SaleOrderDataSource, TableRowAction } from "../../components";
import { OrderDisplayDialogComponent } from "../../components/order-display-dialog/order-display-dialog.component";
import { PrintInvoiceDialogComponent } from "../../modules/print-module";
import { BasePage } from "../../utils/pages/base/base.component";

@Component({
  selector: "app-sales-dashboard",
  templateUrl: "./sales-dashboard.component.html",
  styleUrls: ["./sales-dashboard.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SalesDashBoardComponent
  extends BasePage
  implements OnDestroy, AfterViewInit, OnInit {
  // zoom level for map
  zoom: number = 13;
  printType: string;
  kotPrint: boolean = false;
  showData: boolean = false;
  selectedStatusTabIndex: number = 0;
  statusTabsFilterGroup: FilterGroup = new FilterGroup([
    new Filter("All", null, null, null, true),
    new Filter("Pending", ["pending", "scheduled_order"], "status"),
    new Filter("Processing", "processing", "status"),
    new Filter("Out For Delivery", "out_delivery", "status"),
    new Filter("Pickup 8 AM - 6 PM", true, "isCustomerPickupOrder"),
    new Filter("Swiggy", true, "isSwiggyOrder"),
  ]);

  searchFilterGroup: FilterGroup = new FilterGroup([
    new Filter(
      "Search",
      null,
      [
        "incrementId",
        "driver_name",
        "driver_number",
        "firstname",
        "shippingDescription",
      ],
      FilterMatch.CONTAINS
    ),
  ]);

  bufferFitler: FilterGroup = new FilterGroup([
    new Filter("<15 Mins", 15, "bufferTime", FilterMatch.LESS_THAN),
    new Filter("<30 Mins", 30, "bufferTime", FilterMatch.LESS_THAN),
    new Filter("<60 Mins", 60, "bufferTime", FilterMatch.LESS_THAN),
    new Filter("<90 Mins", 90, "bufferTime", FilterMatch.LESS_THAN),
    new Filter(">90 Mins", 90, "bufferTime", FilterMatch.GREATER_THAN),
  ]);

  orderFilter: FilterGroup = new FilterGroup([
    new Filter("Is Verified", true, "isVerified", FilterMatch.EXACT),
  ]);

  // Bulk kot filter
  kotFilter: FilterGroup = new FilterGroup([
    new Filter("KOT Not Printed", false, "isKotPrinted", FilterMatch.EXACT),
  ]);

  unAssignedFilter: FilterGroup = new FilterGroup([
    new Filter("Un-assigned", true, "isUnAssignedOrder", FilterMatch.EXACT),
  ]);

  /**
   * Dynamic filter Group
   * We add it`s filter on the go when user selects a product to filter.
   */
  productsFilterGroup: FilterGroup = new FilterGroup([]);

  // Dynamic filter group for slots
  slotFilterGroup: FilterGroup = new FilterGroup([]);

  combinedFilterGroups: FilterGroup[] = [
    this.searchFilterGroup,
    this.slotFilterGroup,
    this.productsFilterGroup,
  ];

  modelFilter: FilterModel = new FilterModel(
    [...this.combinedFilterGroups, this.statusTabsFilterGroup]
  );

  orders: SaleOrderDataSource = new SaleOrderDataSource(this.modelFilter, []);

  INTERVAL: number = 1000 * 120;
  startRefreshSubscription$: Subscription;

  searchTerm: string = "";
  slotMap: { [id: number]: DeliverySlot };

  // action call for row click
  @Input()
  rowClickCallback: TableRowAction = new TableRowAction(
    this.onOrderSelected.bind(this)
  );
  @ViewChild("drawer") drawer: MatSidenavContainer;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public processService: MoveProcessingService,
    public moveToHoldService: MoveToHoldService,
    private title: Title
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    this.title.setTitle("Live Orders");
    this.fetchDeliverySlots();
  }

  ngAfterViewInit(): void {
    this.orderState.subscribe((orderState) => {
      this.orders.selection.clear();
      this.orders.data = orderState.orders;
    });

    this.startRefreshSubscription$ = this.poll().subscribe(() => { });
  }

  /**
   * @param tabIndex
   * Used to Filter the orders based on the
   * selected status tab
   */
  loadSelectedStatusData(tabIndex: number): void {
    this.statusTabsFilterGroup.filters.forEach(
      (filter: Filter, idx: number) => {
        filter.selected = tabIndex === idx;
      }
    );
    this.orders.filter = "true";
  }

  timeRemaining$(order: SaleOrder): number {
    return order.getTimeRemainingSeconds();
  }

  poll(): Observable<SaleOrder[]> {
    return interval(this.INTERVAL).pipe(
      switchMap(() => from(this._refreshOrder()))
    );
  }

  ngOnDestroy(): void {
    // STOP THE POLLING
    if (this.startRefreshSubscription$) {
      this.startRefreshSubscription$.unsubscribe();
    }
  }

  /**
   * Function to fetch delivery slots from redux
   * 1. Get only the required filters for the page
   * (Ignoring Pickup orders slot)
   */
  fetchDeliverySlots(): void {
    this.storeState
      .pipe(
        skipWhile((state) => state.loading === true),
        take(1)
      )
      .subscribe((storeState: StoreState) => {
        const requiredDeliverySlotsFilters: Filter[] =
          storeState.deliverySlotFilters && storeState.deliverySlotFilters.filter(
            (filter: Filter) => filter.nameDisplayed !== 'Pickup 8 AM - 6 PM'
          );
        this.slotFilterGroup.filters = requiredDeliverySlotsFilters;
        this.slotMap = storeState.slotMap;
        this.filterSlots();
      });
  }

  // To filter slots
  filterSlots(): void {
    this.slotFilterGroup.filters.forEach((filter) => {
      filter.searchField = "scheduledSlot";
    });
  }

  /**
   * Triggers a server request to find out if we have any new orders.
   * if we have - we just play a sound and display the new orders received.
   * private
   */
  private async _refreshOrder(): Promise<SaleOrder[]> {
    this.orderState.pipe(take(1)).subscribe((orderState) => {
      const delayPendingOrders: SaleOrder[] = orderState.orders.filter(
        (order) => order.status === "pending" && order.timeRemaining < 110
      );
      if (delayPendingOrders.length > 0) {
        const message: string =
          "Some orders are in pending status for more than 10 minutes";
        this.showNotification(
          "top",
          "right",
          "warning",
          "info-circle",
          message,
          10000
        );
        this.playAudio("alert.mp3");
      }
    });
    const lastOrderTime: string = await this.store
      .select((state) => state.orderState.lastOrderTime)
      .pipe(take(1))
      .toPromise();

    const selectedStore: number = await this.store
      .select((state) => state.storeState.store)
      .pipe(take(1))
      .toPromise();

    const action: ActionCheckNewOrders = new ActionCheckNewOrders(
      // eslint-disable-next-line radix
      parseInt(selectedStore + ""),
      lastOrderTime
    );

    this.store.dispatch(action);

    return await this.store
      .select(selectors.getOrderState)
      .pipe(
        skipWhile((state) => state.loading === true),
        take(1),
        map((state) => {
          if (state.newOrders.length === 0) {
            return [];
          }
          const message: string = `You got ${state.newOrders.length} new orders.`;
          this.showNotification(
            "top",
            "right",
            "warning",
            "info-circle",
            message,
            10000
          );

          const audio: HTMLAudioElement = new Audio();
          audio.src = "assets/notification.mp3";
          audio.load();
          audio.play();
          audio.onended = () => {
            this.playAudio("notification.mp3");
          };

          return state.newOrders;
        })
      )
      .toPromise();
  }

  playAudio(file: string): void {
    const audio: HTMLAudioElement = new Audio();
    audio.src = "assets/" + file;
    audio.load();
    audio.play();
  }

  /**
   * Applies model filters
   */
  applyFilters(filter: Filter): void {
    filter.selected = !filter.selected;
    this.orders.filter = "true";
  }

  /**
   * Searches for orderId
   * @param orderId
   */
  applySearchFilter(): void {
    if (this.searchTerm === "") {
      this.searchFilterGroup.filters[0].selected = false;
      this.searchFilterGroup.filters[0].value = null;
    } else {
      this.searchFilterGroup.filters[0].selected = true;
      this.searchFilterGroup.filters[0].value = this.searchTerm;
    }

    this.orders.filter = "true";
  }

  checkWhetherOrdersAreSelected(): boolean {
    const selectedOrders: SaleOrder[] = this.orders.selection.selected;

    if (selectedOrders.length === 0) {
      return false;
    }

    return true;
  }

  printOrders(): void {
    const dialogData: {
      orders: SaleOrder[];
      printType: string;
      printKot: boolean;
    } = {
      orders: this.orders.selection.selected,
      printType: "kot",
      printKot: this.kotPrint,
    };
    this.dialog.open(PrintInvoiceDialogComponent, {
      width: "80mm",
      data: dialogData,
    });
  }

  /**
   * @returns error message alert, if the orders are not selected,
   * onSuccess, calls move to processing function
   */
  processing(): void {
    if (this.checkWhetherOrdersAreSelected() === false) {
      this.presentCheckboxAlert();

      return;
    }
    const onSuccess: (result: any) => void = (result) => {
      if (result) {
        this.moveToProcessing();
      }
    };

    this.optionsAlert(
      "Confirm",
      "Are you sure you want to move orders to Processing",
      onSuccess
    );
  }

  /**
   * @returns error message alert, if the orders are not selected,
   * onSuccess, calls print orders function
   */
  printBulkKot(): void {
    if (this.checkWhetherOrdersAreSelected() === false) {
      this.presentCheckboxAlert();

      return;
    }

    const onSuccess: (result: any) => void = (result) => {
      if (result) {
        this.printOrders();
      }
    };

    this.optionsAlert(
      "Confirm",
      "Are you sure you want to print bulk kot",
      onSuccess
    );
  }

  /** Fn to present alert */
  presentCheckboxAlert(): void {
    this.iconAlert(
      "Warning",
      "Please select some orders to print.",
      null,
      "exclamation-circle",
      "warning"
    );
  }

  holdOrders(): void {
    if (this.checkWhetherOrdersAreSelected() === false) {
      this.iconAlert(
        "Warning",
        "Please select some orders.",
        null,
        "exclamation-circle",
        "warning"
      );

      return;
    }

    const onSuccess: (result: any) => void = (result) => {
      if (result) {
        this.presentLoader();
        const param: {
          orders: string[];
          move_to_hold: boolean;
        } = {
          orders: this.orders.selection.selected.map(
            (order) => order.incrementId
          ),
          move_to_hold: true,
        };
        this.moveToHoldService.getData(param).subscribe(
          (resultData) => {
            this.refreshStores();
            this.dismissLoader();
            this.iconAlert(
              "Success",
              "Orders held ..",
              null,
              "exclamation-circle",
              "Success"
            );
          },
          (error) => this.somethingWentWrong()
        );
      }
    };

    this.optionsAlert(
      "Confirm",
      "Are you sure you want to hold these orders?",
      onSuccess
    );
  }

  /**
   * @param data - payload for the service
   * @param orders ie list of orders,
   * @param move_processing flag
   */
  moveToProcessing(): void {
    this.presentLoader();
    const param: {
      orders: string[];
      move_processing: boolean;
    } = {
      orders: this.orders.selection.selected.map((order) => order.incrementId),
      move_processing: true,
    };
    this.processService.getData(param).subscribe(
      (result) => {
        this.refreshStores();
        this.dismissLoader();
        if (!result[0].status) {
          this.showMessageAlert("Unsuccessful", result[0]);

          return;
        }
        this.showMessageAlert("Success", result[0]);
      },
      (error) => this.somethingWentWrong()
    );
  }

  showMessageAlert(message: string, response: GenericServerResponse): any {
    this.iconAlert(
      message,
      response.message,
      null,
      "exclamation-circle",
      "Success"
    );
  }

  /**
   * Opens the order display dialog for the selected order.
   * Refetches the orders when the dialog gets closed after holding an order.
   * @param order
   */
  onOrderSelected(event: any, order: SaleOrder): void {
    const dialogRef: MatDialogRef<OrderDisplayDialogComponent, any> =
      this.dialog.open(OrderDisplayDialogComponent, {
        data: { order },
        panelClass: "remove-padding",
      });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.refreshStores();
      }
    });
  }

  /**
   * Opens product selection wizard with already filtered products(if any).
   * Adds all the prodcuts returned from product selection wizard
   * to product filter group.
   * @param filter
   */
  openProductSelectionWizard(): void {
    const dialogRef: MatDialogRef<ProductSelectionComponent, any> =
      this.dialog.open(ProductSelectionComponent, {
        data: { productFilter: this.productsFilterGroup.filters[0] },
        disableClose: true,
        width: "80vw",
        height: "80vh",
        maxHeight: "80vh",
        panelClass: "product-selection-wizard",
      });
    dialogRef.afterClosed().subscribe((result: NestedCounterFilter | any) => {
      if (result === false) {
        // cancelled case
        return;
      }
      this.productsFilterGroup.filters = [];
      if (result === null) {
        // filter removed case
        this.orders.filter = "false";

        return;
      }
      // filter added case
      this.productsFilterGroup.filters.push(result);
      this.orders.filter = "true";
    });
  }

  /**
   * Clears all the selected filters
   */
  clearAllFilter(): void {
    this.combinedFilterGroups.forEach((filterGroup: FilterGroup) => {
      filterGroup.filters.forEach((filter: Filter) => filter.selected = false);
    });
    this.orders.filter = "true";
    this.searchTerm = "";

    // specific only to product filter
    this.productsFilterGroup.filters = [];
  }

  showActiveRiders(): void {
    this.showData = true;
    this.drawer.open();
  }

  // To empty trip data on closing dialog
  closeDrawer(status: boolean): void {
    this.showData = status;
    this.drawer.close();
  }
}

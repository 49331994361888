<div class="comment">
  <mat-label class="row no-gutters">Enter your comment</mat-label>
  <mat-form-field>
    <textarea
      matInput
      type="text"
      class="row"
      placeholder="Enter Comment"
      [formControl]="commentControl"
    ></textarea>
  </mat-form-field>
  <div class="row comment_btn">
    <button
      [disabled]="!commentControl.value"
      id="submit_btn"
      mat-button
      class="col-10"
      (click)="onCommentSend()"
    >
      Submit
    </button>
  </div>
</div>

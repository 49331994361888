
export class VersionControlResponse {
    m_upgrade: string;
    // deprecated fields
    upgraded: string;
    mandatory_upgrade: Boolean;
    deserialize(input) {
        this.upgraded = input.upgraded;
        this.mandatory_upgrade = input.mandatory_upgrade;
        this.m_upgrade = input.m_upgrade;
        return this;
    }
}

import { DefaultSerializationMixin, Serializable } from "./base";

export class BotVersion
  extends DefaultSerializationMixin
  implements Serializable<BotVersion> {
  private bot_version: string;

  get freshBotVersion(): string {
    return this.bot_version;
  }

  deserialize(input) {
    this.bot_version = input.bot_version;

    return this;
  }
}

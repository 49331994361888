import { Component, Input, OnInit } from "@angular/core";

import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { UserState } from "@store/user/state";
import { RewardPointsTransaction, User } from "@tendercuts/models";
import { RewardPointsTransactionService } from "@tendercuts/providers";
import { Observable } from "rxjs";
import { FilterModel } from "src/models";
import { BasePage } from "../../utils/pages/base/base.component";
import { RewardsDataSource } from "../data-source";
import { ColumnAction, DateFormatAction, IconAction } from "../table-actions";

@Component({
  selector: "app-reward-points",
  templateUrl: "./reward-points.component.html",
  styleUrls: ["./reward-points.component.scss"],
})
export class RewardPointsComponent extends BasePage implements OnInit {
  rewards: RewardPointsTransaction[] = [];
  @Input() hidePlaceholder: boolean = false;
  @Input() customerObj: User = null;
  @Input() user: User;
  columnsToDisplay: string[] = ["comment", "updatedBy"];
  titleToDisplay: string[] = ["Comment", "Added By"];
  @Input() actions: ColumnAction[] = [
    new DateFormatAction("Date", "created_at", this.getDateFormat.bind(this)),
    new IconAction("Reward Amount", "amount", this.showAmount.bind(this)),
  ];
  modelFilter: FilterModel = new FilterModel([]);

  @Input()
  rewardsDataSource: RewardsDataSource = new RewardsDataSource(
    this.modelFilter,
    [],
  );

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public rewardService: RewardPointsTransactionService,
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    // parameters
    this.rewardService
      .getData({ customer_id: this.customerObj.userId })
      .subscribe(
        (data: RewardPointsTransaction[]) => {
          this.rewards = data;
          this.rewardsDataSource.data = data;
        },
        (err) => {},
      );
  }

  getDateFormat(event: any, rewards: RewardPointsTransaction): string {
    return "mediumDate";
  }

  showAmount(event: any, rewards: RewardPointsTransaction): string {
    return +rewards.amount > 0
      ? "fa fa-plus-circle fa-lg"
      : "fa fa-minus-circle fa-lg";
  }

  get userState(): Observable<UserState> {
    return this.store.select((state) => state.userState);
  }
}

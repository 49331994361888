import { Injectable } from "@angular/core";

import { BasePostProvider } from "@tendercuts/http";
import { DriverOrder, GenericServerResponse, Trips } from "@tendercuts/models";

import { HttpClient } from "@angular/common/http";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";

@Injectable()
export class UpdateTripService extends BasePostProvider<GenericServerResponse> {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get endpoint(): string {
    return this.endpointService.updateTrip();
  }

  get model(): typeof GenericServerResponse {
    return GenericServerResponse;
  }

  getParams(
    trip: Trips,
    orders: DriverOrder[],
  ): {
    trip_id: number;
    driver_order: any[];
  } {
    const driverOrder: any[] = [];
    orders.forEach((order, index) => {
      driverOrder.push({
        increment_id: order.incrementId,
        sequence: index + 1,
      });
    });

    return {
      trip_id: trip.tripId,
      driver_order: driverOrder,
    };
  }
}

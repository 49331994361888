import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { AnyFn } from "@ngrx/store/src/selector";
import { AppState } from "@store/state";
import { DriverOrder, SaleOrder } from "@tendercuts/models";
import { BaseComponent } from "../../utils";

@Component({
  selector: "app-address-marker",
  templateUrl: "./address-marker.component.html",
  styleUrls: ["./address-marker.component.scss"],
})
export class AddressMarkerComponent extends BaseComponent implements OnInit {
  @Input() driverOrder: DriverOrder;

  @Input() order: SaleOrder;
  @Input() isSelected: boolean;
  @Input() markerIcon: string = null;
  @Input() useDeliveredAddress: boolean = false;
  @Input() sequenceNumber: string = "";
  @Input() draggable: boolean = false;

  /**
   * Config: if the cicle should be displayed
   * type {boolean}
   */
  @Input() showCircle: boolean = true;

  /**
   * Defines the circle radius, not applicable is showCircle config is not defined
   * type {number}
   */
  @Input() circleRadius: number = 400;

  /**
   * Config: If set we render a info windows, otherwise no windows
   * type {boolean}
   */
  @Input() showWindow: boolean = true;

  /**
   * If set displays the info window. Note this works only
   * if showWindow flag is configured
   * type {boolean}
   */
  @Input() displayWindow: boolean = false;

  // TODO
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick: EventEmitter<SaleOrder> = new EventEmitter<SaleOrder>();

  constructor(public store: Store<AppState>) {
    super(store);
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {}

  /**
   * Based on the buffer remaining we color code the flag
   * param SaleOrder order
   * returns string
   */
  markerUrl(order: SaleOrder): string {
    if (this.markerIcon) {
      return this.markerIcon;
    }

    if (this.order.status == "complete") {
      return "assets/marker/green.png";
    }

    if (order.bufferTime < 30) {
      return "assets/marker/red.png";
    }

    if (order.bufferTime < 60) {
      return "assets/marker/orange.png";
    }

    if (order.bufferTime < 75) {
      return "assets/marker/yellow.png";
    }

    return "assets/marker/green.png";
  }

  formatLabels(): string {
    // for completed orders we just show order id as label
    if (this.driverOrder) {
      return (
        "ID: " +
        this.order.incrementId +
        " | " +
        this.order.timeRemaining +
        " Mins " +
        " | " +
        this.driverOrder.sequence
      );
    }
    if (this.order.status == "complete") {
      return "D";
    }
    if (this.sequenceNumber != "") {
      return this.sequenceNumber.toString();
    }

    return this.order.timeRemaining + " Mins";
  }

  onMarkerSelected(): void {
    this.onClick.emit(this.order);
  }

  get latitude(): number {
    if (this.driverOrder) {
      return Number(this.driverOrder.lat);
    }
    if (this.useDeliveredAddress) {
      return this.order.shippingAddress.latitude;
    }

    return this.order.shippingAddress.Olatitude;
  }

  get longitude(): number {
    if (this.driverOrder) {
      return Number(this.driverOrder.lng);
    }
    if (this.useDeliveredAddress) {
      return this.order.shippingAddress.longitude;
    }

    return this.order.shippingAddress.Olongitude;
  }
}

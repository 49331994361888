import { Serializable } from "../base";

/**
 * Model to show delivery package subscription details
 *
 * Model to show elite subscription details
 */

export class CustomerEliteSubscription
  implements Serializable<CustomerEliteSubscription> {
  private id: number;
  private created_at: string;
  private datetime: string;
  private end_time: string;
  private amount_saved: number;
  private free_order_count: number;
  private is_active: boolean;
  private customer: number;
  private days_left: number;
  private message: string;
  public status: string;

  constructor() {}

  deserialize(input) {
    this.id = input.id;
    this.created_at = input.created_at;
    this.datetime = input.start_time;
    this.end_time = input.end_time;
    this.amount_saved = input.amount_saved;
    this.free_order_count = input.free_order_count;
    this.is_active = input.is_active;
    this.customer = input.customer;
    this.days_left = input.days_left;
    this.status = input.status;
    this.message = input.message;

    return this;
  }
  
/**
 * Elite subscription id
 */
  get eliteSubscriptionId() {
    return this.id;
  }

/**
 * Elite subcription purchased at
 */
  get createdAt() {
    return this.created_at;
  }

  get startedDate() {
    return this.datetime;
  }

  get endDate() {
    return this.end_time;
  }

  get amountSaved() {
    return this.amount_saved;
  }

  get freeOrderCount() {
    return this.free_order_count;
  }

  get isActive() {
    return this.is_active;
  }

  get customerId() {
    return this.customer;
  }

  get eliteDaysLeft() {
    if (this.days_left < 0) {
      return 0;
    }
    return this.days_left;
  }

  get isPending(): boolean {
    return this.status === 'pending';
  }

  /**
   * Subscription is expired
   * 1. if it is inactive
   * 2. days left are less than or eqaul to 00
   * 3. end time is not null
   */
  get isExpired(): boolean {
    return this.is_active === false && this.days_left <= 0 && !!this.end_time;
  }

  get subscriptionMsg(): string {
    return this.message;
  }
}

import { Serializable } from "./base";

export class Driver implements Serializable<Driver> {
  private driver_user: string;
  private trip_id: number;
  private is_available: boolean;
  public id: number;
  public phone: string;
  public name: string;
  public status: number;

  constructor() {}

  get displayName() {
    return `${this.name} - ${this.phone}`;
  }

  get driverUserId() {
    return this.driver_user;
  }

  get driverStatus() {
    switch (this.status) {
      case 0:
        return "available";
      case 1:
      case 2:
      case 3:
        return "ontrip";
      case 4:
        return "return";
      case 5:
        return "partial cash collected";
      case 6:
        return "cash collected";

      default:
        return "";
    }
  }

  toString() {
    return `${this.name} - (${this.phone})`;
  }

  get tripId(): number {
    return this.trip_id;
  }

  // when rider is not in a trip this flag is set to true
  get isAvailable(): boolean {
    return this.is_available;
  }

  get trackStatus(): string {
    return this.is_available ? "Available" : "On Trip";
  }

  get isDriverOnTrip(): boolean {
    return this.driverStatus === "ontrip";
  }

  deserialize(input) {
    this.phone = input.phone;
    this.name = input.name;
    this.driver_user = input.driver_user;
    this.id = input.id;
    this.status = input.status;
    this.trip_id = input.trip_id;
    this.is_available = input.is_available;

    return this;
  }
}

import { Injectable } from "@angular/core";
import { Store as AppStore } from "@ngrx/store";

import * as fromStore from "@store/state";
import { AppState } from "@store/state";
import {
  AuthProtocol,
  BaseGetProvider,
  BasePatchProvider,
  DataTransformationProtocol,
} from "@tendercuts/http";
import { GenericServerResponse, InventoryRequest, User } from "@tendercuts/models";

import { HttpClient } from "@angular/common/http";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { Observable } from "rxjs";

@Injectable()
export class StockApprovalService
  extends BasePatchProvider<GenericServerResponse>
  implements AuthProtocol {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public store: AppStore<AppState>,
    public loginService: LoginServices,
  ) {
    super(http);
  }

  getToken(): Observable<User> {
    return this.store.select(fromStore.getUser);
  }

  get isDetailedEndpoint(): boolean {
    return true;
  }

  get key(): string {
    return "id";
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof GenericServerResponse {
    return GenericServerResponse;
  }

  get endpoint(): string {
    return this.endpointService.updateInvRequest();
  }
}

@Injectable()
export class FetchInventoryRequestService
  extends BaseGetProvider<InventoryRequest>
  implements DataTransformationProtocol {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
    this.delegate = this;
  }

  // override this
  preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof InventoryRequest {
    return InventoryRequest;
  }

  get endpoint(): string {
    return this.endpointService.inventoryApprovals();
  }

  get isCachable(): boolean {
    return false;
  }
}

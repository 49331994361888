import { Serializable } from "./base";

export class InventoryStagingEntry
  implements Serializable<InventoryStagingEntry> {
  private id: number;
  public sku: string;
  private storeName: string;
  private qty: number;
  private name: string;
  private po_id: string;

  constructor() {}

  get skuName() {
    return this.sku;
  }

  get poId() {
    return this.po_id;
  }

  get productName() {
    return this.name ? this.name : this.sku;
  }

  /**
   * setting up acceptable weight value by 0.5 % below and above the original qty value,
   * if obtained value is less than 1 ie(0.4 or others)value 1 is returned
   */
  get toleranceValue() {
    let value = +this.qty * 0.05;
    return value < 1 ? 1 : value;
  }

  /**
   * for low range tolerance value is subtracted from original qty
   * for high range tolerance value is added to the original qty
   */
  get toleranceRange() {
    const low = this.qty - this.toleranceValue;
    const high = this.qty + this.toleranceValue;
    return low + " - " + high;
  }

  deserialize(input) {
    this.id = input.id;
    this.sku = input.sku;
    this.storeName = input.store_name;
    this.qty = input.qty;
    this.name = input.name;
    this.po_id = input.po_id;

    return this;
  }
}

import { Serializable } from './base';

export class FreshdeskTicket implements Serializable<FreshdeskTicket> {
  public source: number;
  public status: string;
  public subject: string;
  public id: number;
  public type: string;
  public description: string;
  public voc: string;
  public disposition: string;
  public tags: string[];
  public url: string;

  private created_at: string;

  constructor() {}

  deserialize(input) {
    this.source = input.source;
    this.status = input.status;
    this.subject = input.subject;
    this.id = input.id;
    this.type = input.type;
    this.description = input.description_text;
    this.created_at = input.updated_at;
    this.tags = input.tags;
    this.url = input.url;

    this.voc = input.custom_fields.voc;
    this.disposition = input.custom_fields.cf_disposition;

    return this;
  }
}

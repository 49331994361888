<div>
  <form name="slotform" id="slot" class="col-4" [formGroup]="slotForm">
    <div id="item-checks">
      <ng-container>
        <mat-label>
          <h6><b> Select Day and Slot:</b></h6>
        </mat-label>
        <mat-form-field>
          <mat-label>Select Day</mat-label>
          <mat-select id="day" (selectionChange)="selectedDay($event)">
            <mat-option
              *ngFor="let date of slotDates"
              value="{{ date.name }}"
              >{{ date.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <br />
        <mat-form-field>
          <mat-label>Select Slot</mat-label>
          <mat-select id="time" formControlName="slotTime">
            <mat-option
              *ngFor="let slot of selectedDateSlots"
              [disabled]="slot.isSlotFilled"
              [value]="slot.slot_id"
              >{{ slot.interval }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </ng-container>
    </div>
  </form>
  <button id="cancel" color="primary" mat-raised-button mat-dialog-close>
    Cancel
  </button>

  <button
    id="slot-btn"
    color="primary"
    [disabled]="!slotForm.valid"
    mat-raised-button
    (click)="changeSlot()"
  >
    Change Slot
  </button>
</div>

/* eslint-disable @typescript-eslint/naming-convention */
import { SaleItem } from "./sale-order";

export enum PaymentStep {
    InventoryCheck = 0,
    PlaceOrder,
    Payment,
    ConfirmOrder,
    ThankYou
}

export enum PaymentStepStatus {
    SUCCESS = 0,
    FAILED ,
    PENDING
}

export class PaymentSteps {
    nextStepIndex: number = 0;
    currentState: PaymentStep;
    data: SaleItem[] = [];

    // TODO clean up
    // shitty as f**
    // eslint-disable-next-line @typescript-eslint/typedef
    statusMap = {};
    stateNames: string[] = [
        "Checking Inventory",
        "Placing Order",
        "Processing Payment",
        "Confirming Order",
        "Thank you!"
        ];

    constructor() {
        this.statusMap = {};
        this.statusMap[PaymentStep.InventoryCheck] = PaymentStepStatus.PENDING;
        this.statusMap[PaymentStep.PlaceOrder] = PaymentStepStatus.PENDING;
        this.statusMap[PaymentStep.Payment] = PaymentStepStatus.PENDING;
        this.statusMap[PaymentStep.ConfirmOrder] = PaymentStepStatus.PENDING;
        this.statusMap[PaymentStep.ThankYou] = PaymentStepStatus.PENDING;
    }

    updateState(step: PaymentStep, state: PaymentStepStatus, data: any = undefined): void {
        // Another ugly hack
        this.currentState = step;
        this.nextStepIndex = step + 1;
        this.statusMap[step] = state;
        this.data = data;
    }

    isStepDone(stepName: string): boolean {
        const index: number = this.stateNames.findIndex((val) => stepName == val);

        return this.statusMap[index] == PaymentStepStatus.SUCCESS;
    }

    isStepFailed(stepName: string): boolean {
        const index: number = this.stateNames.findIndex((val) => stepName == val);

        return this.statusMap[index] == PaymentStepStatus.FAILED;
    }

    isStepPending(stepName: string): boolean {
        const index: number = this.stateNames.findIndex((val) => stepName == val);

        return this.statusMap[index] == PaymentStepStatus.PENDING;
    }

    isNextStep(stepName: string): boolean {
        if (this.nextStepIndex < this.stateNames.length) {
            return stepName == this.stateNames[this.nextStepIndex];
        }

        return false;
    }
}

import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import {
  GenericServerResponse,
  RewardPointsTransaction,
  SaleOrder,
  User,
} from "@tendercuts/models";
import { AddRewardPointService } from "@tendercuts/providers";
import { map, skipWhile, take } from "rxjs/operators";
import { ModalComponent } from "src/app/utils";
import { BasePage } from "../../utils/pages/base/base.component";

@Component({
  selector: "app-add-reward-points",
  templateUrl: "./add-reward-points.component.html",
  styleUrls: ["./add-reward-points.component.scss"],
})
export class AddRewardPointsComponent extends BasePage implements OnInit {
  commentControl: FormControl = new FormControl("", [Validators.required]);
  rewardPointsControl: FormControl = new FormControl("", [
    Validators.required,
    Validators.min(-100),
    Validators.max(1000),
  ]);
  orderId: FormControl = new FormControl("", [Validators.required]);
  rewardPoints: number;
  comment: string;
  customer: User;
  rewards: RewardPointsTransaction;
  isCcManager: boolean = false;
  // To add rewards in cases not related to orders
  mockOrderId: string = "0000";

  orders: SaleOrder[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public dialogRef: MatDialogRef<AddRewardPointsComponent>,
    public dialog: MatDialog,
    public addrewardpointservice: AddRewardPointService
  ) {
    super(dialog, snackBar, store);
    if (data) {
      this.customer = data.customer;
      this.orders = data.orders;
    }
    this.rewards = new RewardPointsTransaction();
  }

  ngOnInit(): void {
    this.getCCManager();
  }

  /**
   * check the login state is call center manager
   */
  async getCCManager(): Promise<void> {
    this.isCcManager = await this.store
      .select((state) => state.userState.user)
      .pipe(
        skipWhile((user) => !user),
        take(1),
        map((user: User) => user.isCcManager)
      )
      .toPromise();
  }

  /**
   * Entered reward point is more than 1000 &&
   * comment value is null it will throw error message
   */
  submitTcRewards(): void {
    if (this.rewardPointsControl.valid === false) {
      this.showNotification(
        "top",
        "center",
        "danger",
        "info-circle",
        "Amount credited should be between -100 to 1000"
      );

      return;
    }

    if (!this.commentControl.valid) {
      this.showNotification(
        "top",
        "center",
        "danger",
        "info-circle",
        "Please enter the comment"
      );

      return;
    }

    if (!this.orderId.valid) {
      this.showNotification(
        "top",
        "center",
        "danger",
        "info-circle",
        "Please select the order"
      );

      return;
    }

    this.sendTcRewards();
  }

  /**
   * passing the customer_id, reward points and comment to a service
   * successfully text alert message has been shown
   */
  sendTcRewards(): void {
    const comment: string =
      this.commentControl.value + " " + "Order #o|" + this.orderId.value;
    this.rewards.balance_delta = this.rewardPointsControl.value;
    this.rewards.comment = comment;
    this.rewards.customer_id = this.customer.entity_id;

    this.addrewardpointservice.getData(this.rewards.serialize()).subscribe(
      (data: GenericServerResponse[]) => {
        const textAlert: MatDialogRef<ModalComponent, any> = this.textAlert(
          data[0].message,
          ""
        );
        textAlert.afterClosed().subscribe(() => {
          this.dialogRef.close(true);
        });
      },
      (err) => this.somethingWentWrong()
    );
  }
}

import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { TripSheet } from "@tendercuts/models";
import { BasePage } from "src/app/utils";

/**
 * component to show for delivery manager approval
 */
@Component({
  selector: "app-trip-sheet-approve",
  templateUrl: "./trip-sheet-approve.component.html",
  styleUrls: ["./trip-sheet-approve.component.scss"],
})
export class TripSheetApproveComponent extends BasePage {
  tripSheet: TripSheet;

  // controller for comment box
  commentControl: FormControl = new FormControl();

  // controller for incentive input
  inputController: FormControl = new FormControl();

  // mode diff approve and reject
  isRejectMode: boolean = false;

  // fetch header data from parent component
  header: string = "Reject";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TripSheetApproveComponent>,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
  ) {
    super(dialog, snackBar, store);
    this.tripSheet = data.tripSheet;
    this.isRejectMode = data.isRejectMode;
    this.header = data.header;
  }

  /**
   * pass the params to parent
   */
  tripSheetApprove(): void {
    if (this.inputController.value > 400) {
      this.showNotification(
        "top",
        "center",
        "danger",
        "info-circle",
        "Incentive value should not be more than 400",
      );

      return;
    }

    const incentive: number = this.inputController.value
      ? this.inputController.value
      : 0;
    this.dialogRef.close({
      status: true,
      amount: incentive,
      comment: this.commentControl.value,
    });
  }

  /**
   * pass the params to parent
   */
  tripSheetReject(): void {
    this.dialogRef.close({
      status: true,
      comment: this.commentControl.value,
    });
  }

  /**
   * check for enable submit button
   * 1.if incentive flag is true checking comment field and incentive field
   * 2.if incentive flag is false checking comment field only
   */
  get canActiveSubmit(): boolean {
    return this.tripSheet.requestIncentive && this.tripSheet.isSchemeDaily
      ? this.commentControl.value && this.inputController.value
      : this.commentControl.value;
  }

  closeDialog(): void {
    this.dialogRef.close({ status: false });
  }
}

import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { TripSheet } from "@tendercuts/models";
import { BasePage } from "src/app/utils";

/**
 * component is a wraper of verify-trip-sheet
 */
@Component({
  selector: "app-verify-trip-sheet-wraper",
  templateUrl: "./verify-trip-sheet-wraper.component.html",
  styleUrls: ["./verify-trip-sheet-wraper.component.scss"],
})
export class VerifyTripSheetWraperComponent extends BasePage {
  tripSheet: TripSheet;

  tripSheetDetailModes: boolean;
  // selected store rider

  dialogMode: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<VerifyTripSheetWraperComponent>,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
  ) {
    super(dialog, snackBar, store);
    if (data) {
      this.tripSheet = data.sheet;
      this.tripSheetDetailModes = data.tripSheetDetailMode;
      this.dialogMode = data.dialogMode;
    }

  }
}

import { Component, Inject, Input } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Constants } from "@app/env";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { SaleOrder } from "@tendercuts/models";
import { ChangePaymentModeService } from "@tendercuts/providers";
import { BasePage } from "../../utils/pages/base/base.component";

@Component({
  // TODO
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "app-paymentMode",
  templateUrl: "./payment-mode.component.html",
  styleUrls: ["./payment-mode.component.scss"],
})
export class ChangePaymentModeComponent extends BasePage {
  @Input() order: SaleOrder;

  constructor(
    public dialogRef: MatDialogRef<ChangePaymentModeComponent>,
    public changePaymentmodeService: ChangePaymentModeService,
    public constants: Constants,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: SaleOrder,
  ) {
    super(dialog, snackBar, store);
    if (data) {
      this.order = data;
    }
  }
  /**
   * process to change payment mode card to cod
   */
  proceedToChangeMode(): void {
    const params: string = this.changePaymentmodeService.getParams(
      this.order.incrementId,
    );
    this.dialogRef.close(params);
  }
}

<div id="action_button">
  <button
    mat-raised-button
    *ngIf="!order.isPickup && order.isOutForDelivery"
    color="primary"
    id="live_track"
    (click)="openLiveTrack()"
  >
    Live Track
  </button>
  <button
    mat-raised-button
    id="order_location"
    color="primary"
    (click)="locateOrder()"
  >
    Order Location
  </button>
  <button
    mat-raised-button
    id="print_detail"
    color="primary"
    (click)="printOrder()"
  >
    Print Detail
  </button>
  <button
    mat-raised-button
    id="mark-as-not-delivered"
    color="primary"
    (click)="markAsNotDelivered()"
    *ngIf="canShowMarkAsNotDeliveredBtn"
  >
    Mark as not delivered
  </button>

  <!-- Displayed in order details page,
    shown only to CC Agent, Cluster Manager, Call Center Manager and Tech Ops -->
  <button
    mat-button
    id="action-btn"
    [matMenuTriggerFor]="techOpsMenu"
    *ngIf="canShowActionBtn"
  >
    <b>Action</b><i class="material-icons"> arrow_drop_down </i>
  </button>
  <mat-menu id="techops" class="overflow-hidden" #techOpsMenu="matMenu">
    <!-- 
      1. Close Order button is now visible to -
         * TechOPs-(completed Orders)
         * Call Center Manager-(able to close completed Store Pickup Orders & online orders)
      2. But without Call Center Manager, TechOps not able to perform this closeOrder action
    -->
    <button
      mat-menu-item
      color="primary"
      id="close-order-btn"
      *ngIf="user?.isCcManager && order.isComplete"
      (click)="closeOrder()"
    >
      Close Order
    </button>
    <!-- Displayed in orderdetails page,
      shown only to Tech ops and cluster manager
      but perform only by Cluster Manager
    -->
    <button
      mat-menu-item
      color="primary"
      id="payment-mode-btn"
      *ngIf="canShowChangePaymentButton"
      (click)="paymentMode()"
    >
      Change Payment Mode
    </button>
    <!-- Displayed in order details page,
      shown only 1. If role is Call Center Agent, Call Center Manager and Tech Ops.
      2. Order is in COD method.
    -->
    <button
      mat-menu-item
      color="primary"
      id="payment-link-btn"
      *ngIf="canShowPaymentLinkBtn"
      (click)="sendPaymentLink()"
      appFirebaseControlledElement
    >
      Send Payment Link
    </button>
  </mat-menu>

  <button mat-button [matMenuTriggerFor]="orderStatusMenu">
    <b>Update Order Status</b><i class="material-icons"> arrow_drop_down </i>
  </button>
  <mat-menu
    id="order_status"
    class="overflow-hidden"
    #orderStatusMenu="matMenu"
  >
    <!-- Displayed only in sales dashboard -->
    <button
      mat-menu-item
      color="primary"
      id="button-hold"
      (click)="holdOrder()"
      *ngIf="order.isHoldable && user?.isSm"
    >
      Hold Order
    </button>
    <button
      mat-menu-item
      id="button-scan"
      color="primary"
      (click)="scanOrder()"
      *ngIf="order.isProcessing && user?.isSm"
    >
      Scan Order
    </button>
    <!-- Displayed in customer dashboard and order location page -->
    <button
      mat-menu-item
      id="button-unhold"
      color="primary"
      (click)="unholdOrder()"
      *ngIf="order?.status === 'hold' && user?.isCcAgent"
    >
      UnHold Order
    </button>
    <!-- Displayed in customer dashboard and order location page -->
    <button
      mat-menu-item
      color="primary"
      id="button-cancel"
      *ngIf="order.isCancelable && user?.isCcAgent"
      (click)="openCancelOrderDialog()"
    >
      Cancel Order
    </button>

    <button
      mat-menu-item
      color="primary"
      id="button-complete"
      *ngIf="canShowCompleteButton"
      (click)="completeStorePickedOrder()"
    >
      Complete Order
    </button>
    <!--<button mat-raised-button color="primary" id="cancelbtn" *ngIf="isCCAgent">
      <i class="material-icons">phone</i>
      Call Customer
    </button>-->
    <button
      mat-menu-item
      color="primary"
      id="button-replace"
      *ngIf="canShowReplaceButton"
      (click)="replaceOrder()"
    >
      Replace Order
    </button>
    <!-- change the order slot -->
    <!-- <button
      mat-menu-item
      color="primary"
      id="change-slot"
      (click)="changeSlot()"
    >
      Change Slot
    </button> -->
  </mat-menu>

  <button
    mat-button
    [matMenuTriggerFor]="communiationMenu"
    *ngIf="user?.isCcAgent"
    id="communication-btn"
  >
    <b>Communication</b><i class="material-icons"> arrow_drop_down </i>
  </button>
  <mat-menu
    id="communication"
    class="overflow-hidden"
    #communiationMenu="matMenu"
  >
    <button
      mat-menu-item
      color="primary"
      id="button-add_comment"
      *ngIf="user?.isCcAgent"
      ss
      (click)="openAddCommentDialog()"
    >
      Add Comment
    </button>

    <!-- Add alternate number -->
    <button
      mat-menu-item
      color="primary"
      *ngIf="!order.isCanceled && !order.isComplete"
      (click)="openAddAlternateDialog()"
    >
      Add Alternate number
    </button>

    <!-- Displayed in customer dashboard and OrderDetail page-->
    <button
      mat-menu-item
      id="button-switchdp"
      color="primary"
      *ngIf="order.isSwitchable && user?.isCcAgent"
      (click)="goToGeoHash()"
    >
      Switch DP
    </button>
    <!-- Displayed in OrderDetail page access only to the callcenter -->
    <button
      mat-menu-item
      color="primary"
      id="button-sms_send"
      *ngIf="user?.isCcAgent"
      (click)="openSmsDialog()"
    >
      Send SMS
    </button>

    <button
      mat-menu-item
      color="primary"
      id="button-delivery_instruction"
      *ngIf="!order.isDone"
      (click)="openDeliveryInstructionsDialog()"
    >
      Add Delivery Instructions
    </button>
  </mat-menu>
</div>

import { Component, Inject, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { SaleOrder } from "@tendercuts/models";
import { AddCommentService } from "@tendercuts/providers";
import { BasePage } from "../../utils";

@Component({
  selector: "app-add-comment",
  templateUrl: "./add-comment.component.html",
  styleUrls: ["./add-comment.component.scss"],
})
export class AddCommentComponent extends BasePage {
  @Input()
  order: SaleOrder;
  commentControl: FormControl = new FormControl();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SaleOrder,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public dialogRef: MatDialogRef<AddCommentComponent>,
    public dialog: MatDialog,
    public commentService: AddCommentService,
  ) {
    super(dialog, snackBar, store);
    if (data) {
      this.order = data;
    }
  }

  /**
   * passing comment to the dialog while the dialog close
   */
  onCommentSend(): void {
    const comment: string = this.commentControl.value;
    this.dialogRef.close(comment);
  }
}

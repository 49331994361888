import { Serializable } from "../base";
import { SaleItem } from "./sale-item";
import { ProductSpec } from "./product-spec";

export class ScanItem implements Serializable<ScanItem> {
  public item: SaleItem;
  public spec: ProductSpec;
  public weight: number;
  // for weight tracking
  public noOfTimesScanned: number;
  public noOfTimesToBeScanned: number;
  public barcodes: string[] = [];

  constructor() {}

  get productId() {
    return this.item.product_id;
  }

  deserialize(input) {
    this.item = input.sale_item;
    this.spec = input.product_spec;
    this.weight = 0;
    this.noOfTimesScanned = 0;
    this.noOfTimesToBeScanned = this.item.quantity;
    if (!this.spec.isScannable) {
      let itemQty: any = this.item.quantity;
      this.noOfTimesScanned = itemQty;
      this.weight = Number.parseInt(itemQty);
    }

    return this;
  }

  serialize() {
    return {
      item_id: this.item.itemId,
      barcodes: this.barcodes
    };
  }

  scan(weight: number) {
    this.weight += weight;
    this.noOfTimesScanned++;
  }

  /**
   * push the item barcodes after item scanned
   * @param barcode
   */
  addBarcode(barcode) {
    this.barcodes.push(barcode);
  }

  clearWeights() {
    this.weight = 0;
    this.noOfTimesScanned = 0;
  }

  get isScanned() {
    return this.noOfTimesScanned == this.noOfTimesToBeScanned;
  }
}

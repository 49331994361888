import { Serializable } from "./base";

/**
 * Model for providing driver performance details..
 */

export class DriverSnapshot implements Serializable<DriverSnapshot> {
  public orders: number;
  public ratings: number;
  public trips: number;
  public year: number;
  private five_stars: number;
  private one_stars: number;
  private created_at: string;
  private updated_at: string;
  private week_no: number;
  private primary_store_id: number;
  private driver: number;
  public totalYears : number;

  get fiveStarCount(): number {
    return this.five_stars;
  }

  get oneStarCount(): number {
    return this.one_stars;
  }

  get createdAt(): string {
    return this.created_at;
  }

  get updatedAt(): string {
    return this.updated_at;
  }

  get weekNo(): number {
    return this.week_no;
  }

  get primaryStoreId(): number {
    return this.primary_store_id;
  }

  get driverId(): number {
    return this.driver;
  }

  deserialize(input) {
    if (!input) {
      return;
    }
    this.orders = input.orders;
    this.ratings = input.ratings;
    this.trips = input.trips;
    this.year = input.year;
    this.totalYears = new Date().getFullYear() - this.year;
    this.five_stars = input.five_stars;
    this.one_stars = input.one_stars;
    this.created_at = input.created_at;
    this.week_no = input.week_no;
    this.primary_store_id = input.primary_store_id;
    this.updated_at = input.updated_at;
    this.driver = input.driver;

    return this;
  }
}

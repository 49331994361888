<form class="weekly_tripsheet" [formGroup]="weeklyTripSheetApprove">
  <mat-card class="weekly_tripsheet__card" *ngIf="isRejectMode; else approve">
    <mat-card-title class="weekly_tripsheet__card__title">
      Reject Weekly Trip Sheet
    </mat-card-title>

    <mat-card-content class="weekly_tripsheet__card__content">
      <mat-form-field class="weekly_tripsheet__card__content__form_field">
        <textarea
          matInput
          type="text"
          id="reject-comment"
          class="row"
          placeholder="Enter reason for rejection"
          formControlName="commentControl"
        ></textarea>
      </mat-form-field>

      <div class="weekly_tripsheet__card__content__action_btns no-gutters row">
        <button
          (click)="closeDialog()"
          class="weekly_tripsheet__card__content__action_btns__close"
          color="primary"
          mat-raised-button
        >
          close
        </button>
        <button
          [disabled]="!weeklyTripSheetApprove.value['commentControl']"
          (click)="approveTripSheet()"
          color="primary"
          id="submit-reject"
          mat-raised-button
        >
          Submit
        </button>
      </div>
    </mat-card-content>
  </mat-card>
  <ng-template #approve>
    <mat-card class="weekly_tripsheet__card">
      <mat-card-title class="weekly_tripsheet__card__title">
        Approve Weekly Trip Sheet
      </mat-card-title>

      <mat-card-content class="weekly_tripsheet__card__content">
        <mat-form-field class="weekly_tripsheet__card__content__form_field">
          <mat-label>Remarks</mat-label>
          <textarea
            matInput
            type="text"
            id="approve-remarks"
            class="row"
            placeholder="Enter remarks"
            formControlName="commentControl"
          ></textarea>
        </mat-form-field>

        <mat-form-field class="weekly_tripsheet__card__content__form_field">
          <mat-label>Enter Incentive amount</mat-label>
          <input
            matInput
            type="number"
            id="approve-incentive"
            formControlName="inputController"
            placeholder="Enter incentive"
          />
        </mat-form-field>

        <div
          class="weekly_tripsheet__card__content__action_btns no-gutters row"
        >
          <button
            (click)="closeDialog()"
            class="weekly_tripsheet__card__content__action_btns__close"
            color="primary"
            mat-raised-button
          >
            close
          </button>
          <button
            [disabled]="!weeklyTripSheetApprove.valid"
            (click)="approveTripSheet()"
            color="primary"
            id="submit-approve"
            mat-raised-button
          >
            Submit
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </ng-template>
</form>

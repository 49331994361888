import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BaseGetProvider, DataTransformationProtocol } from "@tendercuts/http";
import { Trips } from "@tendercuts/models";

@Injectable()
export class FetchTripsService
  extends BaseGetProvider<Trips>
  implements DataTransformationProtocol {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
    this.delegate = this;
  }

  // override this
  preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof Trips {
    return Trips;
  }

  get endpoint(): string {
    return this.endpointService.trips();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(storeId: string): HttpParams {
    let params: HttpParams = new HttpParams();
    params = params.append("store_id", storeId);

    return params;
  }
}

<mat-card *ngIf="tripSheet" class="km_violation">
  <mat-card-title class="km_violation__title"> Km Violation </mat-card-title>
  <mat-card-content class="km_violation__content">
    <h6 class="km_violation__content__kmCount">
      Your Speedo meter reading
      <span class="km_violation__content__kmCount__value"
        >{{ tripSheet.odoMeterKM }}
      </span>
      kms
    </h6>
    <p class="km_violation__content__trip">
      You have approved
      <span class="km_violation__content__trip__value">
        {{ tripSheet.tripEarnings.length }}</span
      >
      trips. for
      <span class="km_violation__content__trip__value"
        >{{ tripSheet.totalKms }}
      </span>
      kms
    </p>
    <p class="km_violation__content__eligible">
      you are eligible for only
      <span class="km_violation__content__eligible__value">
        {{ tripSheet.availableKM }}</span
      >
      kms
    </p>
    <div class="km_violation__content__btn row no-gutters">
      <button (click)="closeDialog()" color="primary" mat-raised-button>
        Ok
      </button>
    </div>
  </mat-card-content>
</mat-card>

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { Driver } from "@tendercuts/models";
import { of, Observable } from "rxjs";
import { LocateDriverService } from "./service";

@Injectable()
export class MockLocateDriverService extends LocateDriverService {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http, loginService, endpointService);
  }

  getData(): Observable<Driver[]> {
    return of([
      new Driver().deserialize({ latitude: 80.15, longitude: 13.11 }),
    ]);
  }
}

import { AfterViewInit, Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { Category, Product, SaleItem, SaleOrder } from "@tendercuts/models";
import { SkuSplitService } from "@tendercuts/providers";
import { skipWhile, take } from "rxjs/operators";
@Component({
  selector: "app-print-kot-invoice",
  templateUrl: "./print-kot-invoice.component.html",
  styleUrls: ["./print-kot-invoice.component.scss"],
})
export class PrintKotInvoiceComponent implements OnInit {
  @Input() data: SaleOrder[];

  @Input() driverMode: boolean = false;

  @Input() driverDisplayName: string = null;

  @Input() tripId: number = null;

  time: string;

  categoriesList: any[] = [];

  productMap: { [id: number]: Product };

  constructor(
    public splitService: SkuSplitService,
    public store: Store<AppState>,
  ) {}

  /**
   * using splitService we categorized skus into freshcut and non freshcut
   */
  ngOnInit(): void {
    this.fetchProducts();
    this.time = new Date().toLocaleTimeString();
    const categories: any = this.splitService.skuCategorization(this.data);
    this.categoriesList = Object.keys(categories).map((key: string) => {
      return categories[key];
    });
  }

  fetchProducts(): void {
    this.store
      .select((state) => state.catalog)
      .pipe(
        skipWhile((state) => state.loading),
        take(1),
      )
      .subscribe((state) => {
        this.productMap = state.productMap;
      });
  }
}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule } from "@angular/router";
import {
  devTools,
  firebaseConfig,
  Constants,
  FIREBASE_ANALYTICS_DEBUG_MODE,
} from "@app/env";
import { reducerProvider, reducerToken } from "@store/reducers";
import { AuthEffects, UserStorageEffects } from "@store/user";
import { ReplaceLineBreaks } from "@tendercuts/pipes";
import {
  AddCashToTripService,
  AddCommentService,
  AddRewardPointService,
  AddStoreCreditService,
  AssignDriverService,
  AuthGuardService,
  CallCustomerService,
  CanHoldService,
  CatalogService,
  ChangePaymentModeService,
  CreateDriverService,
  CreateOptimalTrip,
  CustomerComplainsService,
  CustomerDndService,
  CustomerOrdersService,
  CustomerSearchService,
  DeliveryInstructionsService,
  DriverProfileSearchService,
  EditTripService,
  EndPoint,
  FetchDriversService,
  FetchEveryDriversService,
  FetchInventoryRequestService,
  FetchLiteSaleOrder,
  FetchNewOrdersService,
  FetchOrdersDetailsService,
  FetchOrdersService,
  FetchOrderLocationService,
  FetchParentProductService,
  FetchPriorityOrderService,
  FetchSavedTripsService,
  FetchStoreGeohashService,
  FetchTripsHistoryService,
  FetchTripsService,
  FlockAuthService,
  FlockGuardService,
  FreshdeskTicketsService,
  FreshdeskTicketCreateService,
  GeohashService,
  GetCategoryService,
  GoogleMapsService,
  HistoricOrdersService,
  HoldOrderService,
  InventoryApprovalService,
  InventoryRequestService,
  InventoryService,
  ItemWeightService,
  LocateDriverService,
  MoveProcessingService,
  MoveToHoldService,
  OptimalTripService,
  OrdersByDateService,
  OrderCancelService,
  OrderCloseService,
  OrderLocationService,
  ProductDataService,
  ProductPriceService,
  PubSubService,
  RealtimeTrackService,
  ReplaceOrderService,
  RewardPointsTransactionService,
  RoleGuardService,
  SaleOrderProcessor,
  SaveSegmentService,
  SearchService,
  SendSmsService,
  SignUpService,
  SkuSplitService,
  SnapToRoadService,
  StockApprovalService,
  StoreAddressService,
  StoreDetailService,
  StoreSegmentService,
  StoreService,
  SMStorageService,
  TripCashService,
  TripPathService,
  UnassignOrderService,
  UnHoldOrderService,
  UpdateAddressService,
  WalletTransactionService,
} from "@tendercuts/providers";
import {
  AddCommentComponent,
  AddStoreCreditComponent,
  AdvanceCashDialogComponent,
  AlternateNumberComponent,
  BarcodeSpecGrid,
  BookingDisplayTabComponent,
  BookingDisplayTabWrapperComponent,
  BookingEditComponent,
  ButcherComponent,
  CashCollectionDialogComponent,
  CrmCustomerAddressComponent,
  CustomerComplaintTagComponent,
  DriverActionsComponent,
  DriverAttendanceTabComponent,
  DriverComponent,
  DriverProfileUpdateComponent,
  DriverSelectionComponent,
  DriverSuspendComponent,
  DriverTagComponent,
  FreshdeskTicketComponent,
  HyperTrackComponent,
  HyperTrackOrderComponent,
  HyperTrackTripComponent,
  MapLiveTripComponent,
  OptimalTripComponent,
  OrderCancelComponent,
  OrderSequenceDialogComponent,
  OrderTableComponent,
  PlanTripComponent,
  PlanTripOrderSequenceComponent,
  RemoveTripOrdersComponent,
  RewardPointsComponent,
  SaleOrderGridComponent,
  StoreRiderComponent,
  SwitchWorkLocationComponent,
  SymbolLegendComponent,
  TripValidationComponent,
  WalletTransactionComponent,
  WeeklyTripSheetGridComponent,
} from "./components";
import {
  CustomerDashboardComponent,
  DashBoardComponent,
  DriverCrmComponent,
  DriverOnBoardingComponent,
  InventoryBeforeReleaseComponent,
  InventoryLogComponent,
  InventoryReleaseProductDialogComponent,
  LoginComponent,
  NewInventoryReleaseComponent,
  OrdersComponent,
  RoutingDashboardComponent,
  SalesDashBoardComponent,
  StockApprovalComponent,
  StockRequestComponent,
  GTGStockRequestComponent,
} from "./pages";

import { APP_BASE_HREF } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AngularFireModule } from "@angular/fire";
import {
  AngularFirestore,
  AngularFirestoreModule,
} from "@angular/fire/firestore";
import { MatDialogModule } from "@angular/material/dialog";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LoginServices } from "@login";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { CatalogEffects } from "@store/catalog";
import { DriverEffects } from "@store/driver";
import { OrderEffects } from "@store/orders";
import { StoreEffects } from "@store/store";
import { CookieModule } from "ngx-cookie";
import { Http403Interceptor } from "../interceptors/http-403-interceptor";
import { CustomerProfileService } from "../providers/customer-profile";
import { InventoryUpdateService } from "../providers/inventory-update/inventory-update";
import { SignUpEffects } from "../redux-store/backend/user/signup/signup-effects";
import { AppComponent } from "./app.component";
import { rootRoutes } from "./app.routes";
import { BarcodeScanModule } from "./barcode-scan/barcode-scan.module";
import { AddRewardPointsComponent } from "./components/add-reward-points/add-reward-points.component";
import { CanHoldComponent } from "./components/can-hold/can-hold.component";
import { CustomerProfileComplainsComponent } from "./components/customer-profile-complains/customer-profile-complains.component";
import { CustomerProfileCoreComponent } from "./components/customer-profile-core/customer-profile-core.component";
import { DeliveryInstructionsComponent } from "./components/delivery-instructions/delivery-instructions.component";
import { EliteHistoryComponent } from "./components/elite-history";
import { FreshdeskTicketFormComponent } from "./components/freshdesk-ticket-form/freshdesk-ticket-form.component";
import { HoldOrderComponent } from "./components/holdorder/holdorder.component";
import { InventoryReqReviewDialog } from "./components/inventory-req-review";
import { InventoryRequestApprovalGridComponent } from "./components/inventory-request-grid/inventory-request-approval-grid.component";
import { InventoryRequestGridComponent } from "./components/inventory-request-grid/inventory-request-grid.component";
import { LocateOrderPinComponent } from "./components/locate-order-pin/locate-order-pin.component";
import { MapDrawToolsComponent } from "./components/map-draw-tools/map-draw-tools.component";
import { OrderActionsComponent } from "./components/order-actions/order-actions.component";
import { CloseOrderComponent } from "./components/order-close/order-close.component";
import { OrderDetailComponent } from "./components/order-detail/order-detail.component";
import { OrderDisplayDialogComponent } from "./components/order-display-dialog/order-display-dialog.component";
import { OrderDisplayComponent } from "./components/order-display/order-display.component";
import { OrderEtaMapComponent } from "./components/order-eta-map/order-eta-map.component";
import { OrderEtaComponent } from "./components/order-eta/order-eta.component";
import { ReplaceOrderComponent } from "./components/order-replace/order-replace.component";
import { ChangePaymentModeComponent } from "./components/payment-mode/payment-mode.component";
import { PlanTripDialogComponent } from "./components/plan-trip-dialog/plan-trip-dialog.component";
import { PlanTripWizardComponent } from "./components/plan-trip-wizard/plan-trip-wizard.component";
import { ProductDisplayTabComponent } from "./components/product-display-tab/product-display-tab.component";
import { SendSmsComponent } from "./components/send-sms/send-sms.component";
import { SignUpComponent } from "./components/sign-up/sign-up.component";
import { SlotChangeComponent } from "./components/slot-change/slot-change.component";
import { StoreCreditFilterComponent } from "./components/store-credit-filter/store-credit-filter.component";
import { TripOrderTableComponent } from "./components/trip-order-table/trip-order-table.component";
import { CoreModule } from "./core/core.module";
import { CustomCommonModule } from "./customCommon.module";
import { FirebaseControlledElementDirective } from "./directives/firebase-controlled-element/firebase-controlled-element.directive";
import { EliteSubscriptionModule } from "./modules/elite-subscription-module/elite-subscription-module";
import { InventoryLogModule } from "./modules/inventory-module/inventory-module";
import { PrintModule } from "./modules/print-module/print.module";
import { TripEarningModule } from "./modules/trip-earning-module/trip-earning-module";
import { BookingStatusComponent } from "./pages/booking-status/booking-status.component";
import { HistoricSalesComponent } from "./pages/historic-sales/historic-sales.component";
import { InventoryAdjustmentDialogComponent } from "./pages/inventory-adjustment-dialog/inventory-adjustment-dialog.component";
import { InventoryAdjustmentComponent } from "./pages/inventory-adjustment/inventory-adjustment.component";
import { InventoryApprovalComponent } from "./pages/inventory-approval";
import { InventoryUpdateComponent } from "./pages/inventory-update/inventory-update.component";
import { LiveTrackDebugComponent } from "./pages/live-track-debug/live-track-debug.component";
import { OrderLocationComponent } from "./pages/order-location/order-location.component";
import { StockDashboardComponent } from "./pages/stock-dashboard/stock-dashboard.component";
import { StoresCoverageComponent } from "./pages/stores-coverage/stores-coverage.component";
import { TripsHistoryComponent } from "./pages/trips-history/trips-history.component";
import { ProductSelectionModule } from "./product-selection.module";
import { SharedModule } from "./shared/shared.module";
import { TripModule } from "./trip.module";
import { BaseComponent } from "./utils";
import { UtilsModule } from "./utils/utils.module";
import {
  AngularFireAnalyticsModule,
  DEBUG_MODE,
  ScreenTrackingService,
} from "@angular/fire/analytics";
import { AllOrdersComponent } from "./pages/all-orders/all-orders.component";
import { StatusFilterComponent } from "./components/status-filter/status-filter.component";
import { CustomDatePickerComponent } from "./components/custom-date-picker/custom-date-picker.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashBoardComponent,
    SalesDashBoardComponent,
    DriverActionsComponent,
    OrdersComponent,
    ReplaceLineBreaks,
    RoutingDashboardComponent,
    OptimalTripComponent,
    DriverSelectionComponent,
    OrderCancelComponent,
    CustomerDashboardComponent,
    SaleOrderGridComponent,
    FreshdeskTicketComponent,
    OrderDetailComponent,
    BaseComponent,
    StockDashboardComponent,
    ProductDisplayTabComponent,
    StockRequestComponent,
    GTGStockRequestComponent,
    StockApprovalComponent,
    InventoryRequestGridComponent,
    InventoryReqReviewDialog,
    InventoryRequestApprovalGridComponent,
    StoresCoverageComponent,
    SignUpComponent,
    HistoricSalesComponent,
    OrderLocationComponent,
    MapLiveTripComponent,
    WalletTransactionComponent,
    EliteHistoryComponent,
    RewardPointsComponent,
    LiveTrackDebugComponent,
    FreshdeskTicketFormComponent,
    CustomerProfileCoreComponent,
    CustomerProfileComplainsComponent,
    ReplaceOrderComponent,
    MapDrawToolsComponent,
    PlanTripWizardComponent,
    SymbolLegendComponent,
    OrderDisplayComponent,
    OrderEtaComponent,
    OrderActionsComponent,
    OrderDisplayDialogComponent,
    DriverOnBoardingComponent,
    HoldOrderComponent,
    CashCollectionDialogComponent,
    HoldOrderComponent,
    StoreRiderComponent,
    DriverTagComponent,
    AddCommentComponent,
    CrmCustomerAddressComponent,
    InventoryApprovalComponent,
    AddStoreCreditComponent,
    SendSmsComponent,
    CanHoldComponent,
    OrderEtaMapComponent,
    AdvanceCashDialogComponent,
    AlternateNumberComponent,
    LocateOrderPinComponent,
    AddRewardPointsComponent,
    RemoveTripOrdersComponent,
    InventoryUpdateComponent,
    InventoryLogComponent,
    InventoryAdjustmentComponent,
    CloseOrderComponent,
    ChangePaymentModeComponent,
    InventoryAdjustmentDialogComponent,
    RemoveTripOrdersComponent,
    InventoryReleaseProductDialogComponent,
    PlanTripComponent,
    PlanTripDialogComponent,
    PlanTripOrderSequenceComponent,
    TripOrderTableComponent,
    OrderSequenceDialogComponent,
    InventoryBeforeReleaseComponent,
    DriverCrmComponent,
    NewInventoryReleaseComponent,
    SlotChangeComponent,
    BookingStatusComponent,
    BookingDisplayTabComponent,
    WeeklyTripSheetGridComponent,
    DriverProfileUpdateComponent,
    SwitchWorkLocationComponent,
    BarcodeSpecGrid,
    DriverAttendanceTabComponent,
    TripValidationComponent,
    CustomerComplaintTagComponent,
    BookingDisplayTabWrapperComponent,
    BookingEditComponent,
    DriverSuspendComponent,
    ButcherComponent,
    DriverComponent,
    HyperTrackOrderComponent,
    HyperTrackTripComponent,
    StoreCreditFilterComponent,
    FirebaseControlledElementDirective,
    HyperTrackComponent,
    DeliveryInstructionsComponent,
    TripsHistoryComponent,
    AllOrdersComponent,
    OrderTableComponent,
    StatusFilterComponent,
    CustomDatePickerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CookieModule.forRoot(),
    RouterModule.forRoot(rootRoutes, {
      preloadingStrategy: PreloadAllModules,
      useHash: false,
      relativeLinkResolution: "legacy",
    }),
    SharedModule,
    CoreModule,
    BarcodeScanModule,
    UtilsModule,
    // TablesModule,
    StoreModule.forRoot(reducerToken, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    StoreModule.forFeature("appState", reducerToken),
    AngularFireModule.initializeApp(firebaseConfig),
    // AngularFireModule.initializeApp(secondaryAppConfig),
    AngularFirestoreModule.enablePersistence({ synchronizeTabs: true }),
    AngularFireAnalyticsModule,
    EffectsModule.forRoot([
      AuthEffects,
      UserStorageEffects,
      StoreEffects,
      OrderEffects,
      DriverEffects,
      CatalogEffects,
      SignUpEffects,
    ]),
    devTools,
    CustomCommonModule,
    MatDialogModule,
    TripModule,
    PrintModule,
    TripEarningModule,
    ProductSelectionModule,
    EliteSubscriptionModule,
    InventoryLogModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: APP_BASE_HREF, useValue: "/" },
    { provide: HTTP_INTERCEPTORS, useClass: Http403Interceptor, multi: true },
    { provide: DEBUG_MODE, useValue: FIREBASE_ANALYTICS_DEBUG_MODE },
    ScreenTrackingService,
    reducerProvider,
    Constants,
    EndPoint,
    LoginServices,
    SMStorageService,
    AuthGuardService,
    RoleGuardService,
    FetchOrdersService,
    FetchNewOrdersService,
    FetchOrderLocationService,
    PubSubService,
    LocateDriverService,
    FetchDriversService,
    FetchStoreGeohashService,
    FetchEveryDriversService,
    FetchTripsHistoryService,
    FetchTripsService,
    OptimalTripService,
    MoveProcessingService,
    GetCategoryService,
    StoreDetailService,
    GoogleMapsService,
    FetchOrdersDetailsService,
    UpdateAddressService,
    GeohashService,
    CustomerSearchService,
    FreshdeskTicketsService,
    CustomerOrdersService,
    CatalogService,
    SearchService,
    ProductDataService,
    InventoryService,
    ProductPriceService,
    FetchParentProductService,
    StoreService,
    InventoryRequestService,
    FlockAuthService,
    FlockGuardService,
    FetchInventoryRequestService,
    StockApprovalService,
    CreateOptimalTrip,
    SignUpService,
    HistoricOrdersService,
    StoreAddressService,
    CreateOptimalTrip,
    SnapToRoadService,
    UnassignOrderService,
    TripPathService,
    RewardPointsTransactionService,
    WalletTransactionService,
    TripPathService,
    RealtimeTrackService,
    FreshdeskTicketCreateService,
    CustomerProfileService,
    CustomerComplainsService,
    FetchPriorityOrderService,
    SaveSegmentService,
    StoreSegmentService,
    FetchSavedTripsService,
    InventoryApprovalService,
    SkuSplitService,
    ItemWeightService,
    CallCustomerService,
    AddCommentService,
    AddStoreCreditService,
    OrderCancelService,
    AssignDriverService,
    OrderLocationService,
    CreateDriverService,
    TripCashService,
    HoldOrderService,
    ReplaceOrderService,
    UnHoldOrderService,
    EditTripService,
    SendSmsService,
    CanHoldService,
    CustomerDndService,
    AddCashToTripService,
    AddRewardPointService,
    OrderCloseService,
    ChangePaymentModeService,
    InventoryUpdateService,
    DriverProfileSearchService,
    FetchLiteSaleOrder,
    SaleOrderProcessor,
    MoveToHoldService,
    OrdersByDateService,
    AngularFirestore,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BaseGetProvider } from "@tendercuts/http";
import { Store } from "@tendercuts/models";

@Injectable()
export class StoreDetailService extends BaseGetProvider<Store> {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof Store {
    return Store;
  }

  get endpoint(): string {
    return this.endpointService.storeDetailEndpoint();
  }
}

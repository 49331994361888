import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-driver-tag",
  templateUrl: "./driver-tag.component.html",
  styleUrls: ["./driver-tag.component.scss"],
})
export class DriverTagComponent {
  driverLabelControl: FormControl = new FormControl();
  constructor() {}

}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BasePatchProvider } from "@tendercuts/http";
import { TripEarning } from "@tendercuts/models";

@Injectable({
  providedIn: "root",
})
export class TripSheetUpdateService extends BasePatchProvider<TripEarning> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
  }

  get key(): string {
    return "id";
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof TripEarning {
    return TripEarning;
  }

  get isCachable(): boolean {
    return false;
  }

  get endpoint(): string {
    return this.endpointService.tripSheetUpdate();
  }

  /**
   * params to pass trip sheet confirm.
   * @param status
   * @param id
   */
  getConfirmRequest(
    id: number,
    status: number,
  ): {
    id: number;
    status: number;
  } {
    return {
      id,
      status,
    };
  }

  /**
   * params to pass trip sheet approve
   * @param status
   * @param id
   * @param requestMbg
   * @param requestIncentive
   */
  getApproveRequest(
    id: number,
    status: number,
    requestValue: string,
    comment: string,
  ): {
    id: number;
    status: number;
    comment: string;
} {
    const params: {
      id: number;
      status: number;
      comment: string;
  } = {
      id,
      status,
      comment,
    };

    params[requestValue] = true;

    return params;
  }
}

<div class="title">
  Filter Order By Product
  <button mat-raised-button (click)="dialogRef.close(false)">Cancel</button>
</div>

<mat-chip-list class="selected-chips" *ngIf="selectedProductFilter">
  Selected Product:
  <mat-chip selected (click)="clearFilter()"
    >{{ selectedProductFilter.displayName }} -
    {{ selectedProductFilter.counter }} Packs</mat-chip
  >
</mat-chip-list>

<form>
  <mat-form-field>
    <mat-label>Search Product</mat-label>
    <input
      id="search-input"
      matInput
      placeholder="Enter Product To Search"
      [formControl]="searchControl"
    />
    <i
      class="menu-item-icon fa fa-window-close-o"
      (click)="searchControl.setValue('')"
    ></i>
  </mat-form-field>
</form>

<mat-list class="search-list" *ngIf="showProductList; else showTabs">
  <ng-container *ngIf="searchResults.length; else noMatchingProduct">
    <mat-radio-group
      [(ngModel)]="!selectedValue"
      class="radio-group"
      *ngFor="let product of searchResults"
    >
      <mat-radio-button
        class="radio-button"
        [checked]="isSelectedProduct(product.item.SKU)"
        [disabled]="canDisableProduct(product.item.SKU)"
        (change)="openQuantityDialog(product)"
      >
        {{ product.item.name }} - {{ product.qty_ordered }} Packs Ordered
      </mat-radio-button>
    </mat-radio-group>
  </ng-container>
  <ng-template #noMatchingProduct> No product found. </ng-template>
</mat-list>

<ng-template #showTabs>
  <mat-tab-group>
    <ng-container *ngFor="let category of objectKeys(catalogMap)">
      <mat-tab
        label="{{ category }}"
        *ngIf="objectKeys(catalogMap[category]).length"
      >
        <mat-radio-group
          [(ngModel)]="selectedValue"
          class="radio-group"
          *ngFor="let product of objectKeys(catalogMap[category])"
        >
          <mat-radio-button
            class="radio-button"
            (change)="openQuantityDialog(catalogMap[category][product])"
            [value]="product"
          >
            {{ catalogMap[category][product].item.name }} -
            {{ catalogMap[category][product].qty_ordered }} Packs Ordered
          </mat-radio-button>
        </mat-radio-group>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</ng-template>

<div class="actions">
  <button mat-raised-button color="primary" (click)="clearSelectedProduct()">
    Clear Filter
  </button>
</div>

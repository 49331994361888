<mat-card>
  <mat-card-title>
    <div>Trip Penalty</div>
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="tripSheetPenaltyForm">
      <div class="show-fields">
        <div class="row no-gutters">Enter Penalty Amount</div>

        <div class="row no-gutters align-center">
          <mat-form-field>
            <input
              matInput
              type="number"
              formControlName="amount"
              placeholder="Enter the amount"
              autofocus
              autocomplete="off"
            />
          </mat-form-field>
        </div>
        <mat-form-field>
          <textarea
            matInput
            type="text"
            class="row"
            placeholder="Enter the reason"
            formControlName="comment"
          ></textarea>
        </mat-form-field>
      </div>

      <div class="action-btns no-gutters row">
        <button
          (click)="closeDialog()"
          class="close-btn"
          color="primary"
          mat-raised-button
        >
          close
        </button>
        <button
          (click)="addPenalty()"
          id="submit"
          [disabled]="!tripSheetPenaltyForm.valid"
          color="primary"
          mat-raised-button
        >
          Submit
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>

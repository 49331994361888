import { Serializable } from "./base";

export class AddOn implements Serializable<AddOn> {

  private add_ons: number[];

  deserialize(input) {
    this.add_ons = input.add_ons;

    return this;
  }

  get addOns(): number[] {
    return this.add_ons;
  }

}

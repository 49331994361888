// TODO
// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ColumnAction {
  name: string;
  key: string;
  callback: (event: any, model: any) => {};
}
// TODO
// eslint-disable-next-line @typescript-eslint/naming-convention
export interface RowAction {
  callback: (event: any, model: any) => {};
}

export class TableRowAction {
  constructor(public callback: (event: any) => {}) {}
}

export class ButtonAction implements ColumnAction {
  type: any = "button";
  constructor(public name: string, public key: string, public callback: (event: any) => {}) {}
}

export class TextBoxAction implements ColumnAction {
  type: any = "text";
  constructor(public name: string, public key: string, public callback: (event: any) => {}) {}
}

export class NumberInputAction implements ColumnAction {
  type: any = "number";
  constructor(public name: string, public key: string, public callback: (event: any) => {}) {}
}

export class HyperLinkAction implements ColumnAction {
  type: any = "link";
  constructor(public name: string, public key: string, public callback: (event: any) => {}) {}
}

export class MatChipAction implements ColumnAction {
  type: any = "chip";
  constructor(public name: string, public key: string, public callback: (event: any) => {}) {}
}

export class DateFormatAction implements ColumnAction {
  type: any = "date";
  constructor(public name: string, public key: string, public callback: (event: any) => {}) {}
}

/**
 * Action for show icons for selected coloums in the mat table grid
 */
export class IconAction implements ColumnAction {
  type: any = "icon";
  constructor(public name: string, public key: string, public callback: (event: any) => {}) {}
}

/**
 * Action for show currency pipe for selected coloums in the mat table grid
 */
export class CurrencyPipeAction implements ColumnAction {
  type: any = "currency";
  constructor(public name: string, public key: string, public callback: (event: any) => {}) {}
}

/**
 * Action for show currency pipe for selected coloums in the mat table grid
 */
export class TimerPipeAction implements ColumnAction {
  type: any = "timer";
  constructor(public name: string, public key: string, public callback: (event: any) => {}) {}
}

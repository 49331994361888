<div class="hypertrack-order" *ngIf="hyperTrackOrder; else showMessage">
  <div class="hypertrack-order__header row">
    <h6 class="col-6">Order Number: {{ order.incrementId }}</h6>
    <span class="hypertrack-order__header__close col-6"
      ><i
        class="menu-item-icon fa fa-window-close-o"
        (click)="dialogClose()"
      ></i
    ></span>
  </div>
  <div class="hypertrack-order__details">
    <div *ngIf="!order.isDone" class="row">
      <span
        ><p *ngIf="hyperTrackOrder.remainingDistance > 0">
          <mat-chip class="hypertrack-order__details__chip">
            Distance :
            {{
              hyperTrackOrder.remainingDistance | number: "1.2-2"
            }}
            meter</mat-chip
          >
        </p></span
      >
      <span>
        <p *ngIf="hyperTrackOrder.remainingDuration">
          <mat-chip class="hypertrack-order__details__chip"
            >ETA :
            {{
              hyperTrackOrder.remainingDuration | number: "1.2-2"
            }}
            min</mat-chip
          >
        </p>
      </span>
    </div>
    <iframe class="hypertrack-order__iframe" [src]="shareurl"></iframe>
  </div>
</div>
<ng-template #showMessage>
  <h6 class="noHyperTrack">No Hyper track for this order</h6>
</ng-template>
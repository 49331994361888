import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TripEarning, TripSheet } from "@tendercuts/models";
import { KmViolationPopupComponent } from "../km-violation-popup";

/**
 * component to show raise issue content
 */
@Component({
  selector: "app-trip-earning-confirm",
  templateUrl: "./trip-earning-confirm.component.html",
  styleUrls: ["./trip-earning-confirm.component.scss"],
})
export class TripEarningConfirmComponent implements OnInit {
  tripEarning: TripEarning;

  tripSheet: TripSheet;

  isDeliveryManagerMode: boolean = false;

  // form group controller for all fields
  tripEarningConfirmForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      trip: TripEarning;
      tripSheet: TripSheet;
      isDeliveryManagerMode: boolean;
    },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TripEarningConfirmComponent>,
  ) {
    if (data) {
      this.tripEarning = data.trip;
      this.tripSheet = data.tripSheet;
      this.isDeliveryManagerMode = data.isDeliveryManagerMode;
    }
  }

  ngOnInit(): void {
    this.tripEarningConfirmForm = new FormGroup({
      kmValue: new FormControl("", [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ]),
      showKm: new FormControl("", [Validators.required]),
      comment: new FormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(300),
      ]),
    });
  }

  /**
   * if km violation happens throw throw the error
   * else emit the values
   */
  tripEarningUpdate(): void {
    if (!this.isDeliveryManagerMode) {
      try {
        this.tripSheet.addTripEarning(
          this.tripEarning,
          this.tripEarningConfirmForm.value["kmValue"],
        );
      } catch (err) {
        this.showKmViolationError();

        return;
      }
    }

    this.dialogRef.close({
      status: true,
      km: this.tripEarningConfirmForm.value["kmValue"],
      comment: this.tripEarningConfirmForm.value["comment"],
    });
  }

  showKmViolationError(): void {
    this.dialog.open(KmViolationPopupComponent, {
      panelClass: "remove-padding",
      data: this.tripSheet,
    });
  }

  /**
   * show the given trip earning trip details
   */
  showTripDetails(): void {
    window.open(
      window.location.origin +
        "/dashboard/trip-detail/" +
        this.tripEarning.tripId,
      "_blank",
    );
  }

  closeDialog(): void {
    this.dialogRef.close({ status: false });
  }
}

import { Injectable } from "@angular/core";

import { BaseGetProvider } from "@tendercuts/http";
import { ProductPrice } from "@tendercuts/models";

import { HttpClient } from "@angular/common/http";
import { EndPoint } from "@endpoint";

@Injectable()
export class ProductPriceService extends BaseGetProvider<ProductPrice> {
  constructor(public http: HttpClient, public endpointService: EndPoint) {
    super(http);
  }

  get isCachable(): boolean {
    return false;
  }

  get isAuthenticatedEndpoint(): boolean {
    return false;
  }

  get authProtocol(): any {
    return null;
  }

  get model(): typeof ProductPrice {
    return ProductPrice;
  }

  get endpoint(): string {
    return this.endpointService.productPrice;
  }
}

<ng-template class="addTicketPopUp" #mytemplate>
  <app-freshdesk-ticket-form
    [customer]="customer"
    [orders]="orders.data"
  ></app-freshdesk-ticket-form>
</ng-template>

<div class="search-bar row">
  <mat-form-field class="col-9">
    <input
      type="text"
      placeholder="Enter Ph Number / E-Mail / Customer Id"
      aria-label="text"
      matInput
      [(ngModel)]="searchText"
      (keyup.enter)="searchCustomer()"
    />
  </mat-form-field>
  <button class="col-2" mat-stroked-button (click)="searchCustomer()">
    Search Customer
  </button>
</div>

<!--<app-freshdesk-ticket-form></app-freshdesk-ticket-form>-->
<br />
<ng-container *ngIf="customer">
  <mat-card-title>
    <div class="customer-container">
      <span id="name">
        {{ customer.firstname }}
      </span>
      <span *ngIf="profile?.sale_total > 10000">
        <mat-chip
          *ngIf="profile?.sale_total > 10000 && profile?.sale_total < 25000"
          id="total-silver"
          >Silver
        </mat-chip>
        <mat-chip
          *ngIf="profile?.sale_total > 25000 && profile?.sale_total < 100000"
          id="total-gold"
          >Gold
        </mat-chip>
        <mat-chip *ngIf="profile?.sale_total > 100000" id="total-platinum"
          >Platinum
        </mat-chip>
      </span>
      <button
        mat-raised-button
        color="primary"
        class="loginAsCustomer"
        (click)="loginAsCustomer()"
      >
        Login As Customer
      </button>
      <button
        class="addNewTicket"
        (click)="openModal(mytemplate)"
        mat-raised-button
        color="primary"
      >
        Add New Ticket
      </button>
      <button
        class="add-store-credit"
        (click)="openStoreCreditModel()"
        mat-raised-button
        color="primary"
      >
        Add Store Credit
      </button>
      <button
        class="add-store-credit"
        (click)="openTcRewardsdialogue()"
        mat-raised-button
        color="primary"
      >
        Add TC Rewards
      </button>
      <button
        *ngIf="user?.isCcManager"
        class="add-store-credit"
        (click)="enableCOD()"
        mat-raised-button
        color="primary"
      >
        Enable COD   
      </button>
    </div>

    <!-- <mat-card-title> -->
  </mat-card-title>

  <mat-card-content>
    <app-customer-profile-core
      [profile]="profile"
      [customer]="customer"
    ></app-customer-profile-core>

    <div class="row-elite" *ngIf="eliteSubscription?.isActive">
      <app-customer-elite-status
        [eliteSubscription]="eliteSubscription"
      ></app-customer-elite-status>
    </div>
  </mat-card-content>

  <mat-divider></mat-divider>

  <mat-tab-group
    (selectedTabChange)="loadData(index)"
    [(selectedIndex)]="index"
  >
    <mat-tab label="Orders">
      <app-sale-order-grid
        [dataSource]="orders"
        [titleToDisplay]="titleToDisplay"
        [actions]="actions"
        [columnsToDisplay]="columnsToDisplay"
        [rowClickCallback]="rowClickCallback"
      ></app-sale-order-grid>
    </mat-tab>

    <mat-tab label="Customer Address">
      <ng-container *ngIf="index == 1">
        <app-crm-customer-address
          [customerObj]="customer"
        ></app-crm-customer-address>
      </ng-container>
    </mat-tab>

    <mat-tab label="Tickets">
      <ng-container *ngIf="index == 2">
        <app-freshdesk-ticket [customerObj]="customer"> </app-freshdesk-ticket>
      </ng-container>
    </mat-tab>

    <mat-tab label="Reward Points">
      <ng-container *ngIf="index == 3">
        <app-reward-points [customerObj]="customer"></app-reward-points>
      </ng-container>
    </mat-tab>

    <mat-tab label="Store Credit">
      <ng-container *ngIf="index == 4">
        <app-wallet-transaction
          [customerObj]="customer"
        ></app-wallet-transaction>
      </ng-container>
    </mat-tab>

    <mat-tab label="Complains">
      <ng-container *ngIf="index == 5">
        <app-customer-profile-grid
          [dataSource]="complaintsDataSource"
          [detailComponent]="componentToRender"
        ></app-customer-profile-grid>
      </ng-container>
    </mat-tab>

    <mat-tab label="Elite History">
      <ng-container *ngIf="index == 6">
        <app-elite-history [customerObj]="customer"></app-elite-history>
      </ng-container>
    </mat-tab>
  </mat-tab-group>
</ng-container>

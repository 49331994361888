import { Injectable } from "@angular/core";

import { BasePostProvider } from "@tendercuts/http";
import { GenericServerResponse, SaleOrder, Trips } from "@tendercuts/models";

import { HttpClient } from "@angular/common/http";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";

@Injectable()
export class UnassignOrderService extends BasePostProvider<GenericServerResponse> {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get endpoint(): string {
    return this.endpointService.removeOrderFromTrip();
  }

  get model(): typeof GenericServerResponse {
    return GenericServerResponse;
  }

  getParams(
    trip: Trips,
    orders: SaleOrder[],
  ): {
    trip_id: number;
    order_ids: string[];
  } {
    return {
      trip_id: trip.id,
      order_ids: orders.map((order) => order.incrementId),
    };
  }
}

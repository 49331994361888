import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Store } from "@ngrx/store";
import {
  ActionCatalogLoadSuccess,
  ActionLiveTrackLoadSuccess,
  ActionLoadFailed,
} from "@store/catalog";
import { AppState } from "@store/state";
import {
  Category,
  GenericError,
  LiveTrack,
  ProductPrice,
} from "@tendercuts/models";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })

/** Service file to fetch catalog data from firebase database */
export class FirebaseCatalogService {
  catalogSubscription$: Subscription;
  priceSubscription$: Subscription;
  hyperTrackSubscription$: Subscription;
  catalog: Category[] = null;
  prices: ProductPrice[] = null;
  liveTrackDetails: LiveTrack[] = null;

  constructor(private afs: AngularFirestore, private store: Store<AppState>) {}

  /**Fn to fetch catalog from firebase
   * @param storeId is passed
   */
  private startCatalogSubscription(storeId: string): void {
    const catalogStatusDb: any = this.afs.doc("catalog/" + storeId.toString());
    const pipeline$: any = catalogStatusDb.valueChanges().pipe(
      map((data) => {
        return data ? data[storeId] : [];
      }),
      map((data: Category[]) => {
        return data.map((cat) => new Category().deserialize(cat));
      })
    );
    this.catalogSubscription$ = pipeline$.subscribe((categories) => {
      if (!categories.length) {
        this._publishError();

        return;
      }
      this.catalog = categories;
      this.publishCatalog();
    });
  }

  /**Fn to fetch product price  from firebase
   * @param storeId is passed
   */
  private startPriceSubscription(storeId: string): void {
    const priceStatusDb: any = this.afs.doc("price/" + storeId.toString());
    const pipeline$: any = priceStatusDb.valueChanges().pipe(
      map((data) => (data ? data[storeId] : [])),
      map((data: ProductPrice[]) =>
        data.map((price) => new ProductPrice().deserialize(price))
      )
    );
    this.priceSubscription$ = pipeline$.subscribe((prices) => {
      if (!prices.length) {
        this._publishError();

        return;
      }

      this.prices = prices;
      this.publishCatalog();
    });
  }

  /** Fn to publish catalog fetch */
  private publishCatalog(): void {
    if (!this.catalog) {
      return;
    }

    if (this.prices) {
      const priceMap: any = {};

      this.prices.forEach((price) => (priceMap[price.productId] = price));
      this.catalog.forEach((cat) => cat.populatePriceForProducts(priceMap));
    }

    this.store.dispatch(new ActionCatalogLoadSuccess(this.catalog));
  }

  /** Fn to throw error when catalog fetch fails */
  private _publishError(): void {
    this.store.dispatch(
      new ActionLoadFailed(
        new GenericError({ message: "Contact Tech Support" })
      )
    );
  }

  /** Fn to fetch hyper tracks live rider status from firebase
   * @param storeId is passed
   */
  startHyperTrackSubscription(storeId: any): void {
    const hyperTrackDb: any = this.afs.doc("order_status/" + storeId.toString());
    const pipeline$: any = hyperTrackDb.valueChanges().pipe(
      map((data) => {
        return data ? data[storeId] : [];
      }),
      map((data: LiveTrack[]) => {
        return data.map((driverTrack) =>
          new LiveTrack().deserialize(driverTrack)
        );
      })
    );

    this.hyperTrackSubscription$ = pipeline$.subscribe((hyperTrackSub) => {
      if (!hyperTrackSub.length) {
        this._publishError();

        return;
      }

      this.liveTrackDetails = hyperTrackSub;
      if (hyperTrackSub.length) {
        this.store.dispatch(new ActionLiveTrackLoadSuccess(hyperTrackSub));
      }
    });
  }

  startSubscription(storeId: string): void {
    this.startCatalogSubscription(storeId);
    this.startPriceSubscription(storeId);
    this.startHyperTrackSubscription(storeId);
  }

  unSubscription(): void {
    this.priceSubscription$.unsubscribe();
    this.catalogSubscription$.unsubscribe();
    this.hyperTrackSubscription$.unsubscribe();
  }
}

import { Serializable } from "../base";

export class IntroImage implements Serializable<IntroImage> {

  public id: number;
  public title: string;
  public image: string;

  constructor() {}

  deserialize(input: any): this {
    this.id = input.id;
    this.title = input.title;
    this.image = input.image;

    return this;
  }
}

// TODO
/* eslint-disable */
import { Component, ViewEncapsulation } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ModalComponent } from "../base/modals/modal.component";

@Component({
  selector: "file-uploader-page",
  templateUrl: "./file-uploader.component.html",
  styleUrls: ["./styles/file-uploader.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FileUploaderPageComponent {
  placeholder: string = "https://via.placeholder.com/190x190";
  uploaders: {
    avatar: {
      progress: any;
      url: any;
    };
    files: {
      list: any[];
      invalidList: any[];
    };
    image: {
      progress: any;
      url: any;
    };
  } = {
    avatar: {
      progress: undefined,
      url: undefined,
    },
    files: {
      list: [],
      invalidList: [],
    },
    image: {
      progress: undefined,
      url: undefined,
    },
  };

  constructor(public dialog: MatDialog) {}

  onMultipleChange(event: any, uploader: string): void {
    this.onDropzoneMultipleChange(event.target.files, uploader);
  }

  onSingleChange(event: any, uploader: string): void {
    this.onDropzoneSingleChange(event.target.files, uploader);
  }

  onDropzoneMultipleChange(fileList: Array<File>, uploader: string): void {
    for (const file of fileList) {
      const l: number = this.uploaders[uploader].list.push(file);
      this.read(file, this.uploaders[uploader].list[l - 1]);
    }
  }

  onDropzoneSingleChange(fileList: Array<File>, uploader: string): void {
    this.uploaders[uploader] = fileList[0];
    this.read(fileList[0], this.uploaders[uploader]);
  }

  resetUploader(uploader: string): void {
    if (uploader === "files") {
      this.uploaders[uploader] = {
        list: [],
        invalidList: [],
      };
    } else {
      this.uploaders[uploader] = {};
    }
  }

  post(): void {
    const dialogRef: MatDialogRef<ModalComponent, any> = this.dialog.open(
      ModalComponent,
      {
        data: {
          icon: "check-circle",
          iconColor: "success",
          title: "File uploaded corretly",
          text: "Your file has been uploaded",
          button: "DONE",
        },
      },
    );
    dialogRef.afterClosed().subscribe((_) => {
      this.resetUploader("image");
    });
  }

  read(file: File, store: any): void {
    store.total = (file.size / 1024).toFixed(2);
    store.progress = 0;
    store.loaded = 0;
    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      store.url = e.target.result;
    };

    reader.onprogress = (e: ProgressEvent) => {
      if (e.lengthComputable) {
        store.progress = Math.round((e.loaded / e.total) * 100);
        store.loaded = (e.loaded / 1024).toFixed(2);
      }
    };

    reader.readAsDataURL(file);
  }
}

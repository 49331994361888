import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-custom-date-picker",
  templateUrl: "./custom-date-picker.component.html",
  styleUrls: ["./custom-date-picker.component.scss"],
})
export class CustomDatePickerComponent implements OnInit {
  @Output() triggerChange: EventEmitter<any> = new EventEmitter();
  today = new Date();
  dateOptions = [
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "Last 7 Days", value: "last7days" },
    { label: "This Month", value: "thismonth" },
  ];
  range = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });

  dateFilterSelectedOption: {
    value: string;
    label: string;
    fromDate: string;
    toDate: string;
  } = {
    ...this.dateOptions[0],
    fromDate: this.serverDateConverter(this.today),
    toDate: this.serverDateConverter(this.today),
  };

  constructor() {}

  ngOnInit(): void {
    // this.onChange.emit(this.dateFilterSelectedOption);
  }

  serverDateConverter(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const formattedDate = date.toLocaleDateString("en-CA", options);
    return formattedDate;
  }

  clientDateConverter(date: Date): string {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  onDatePickerChange(
    selectedValue: { value: string; label: string } = {
      value: "custom",
      label: "Custom",
    }
  ): void {
    let fromDate: string | null = null;
    let toDate: string | null = null;

    const setDateRange = (from: Date, to: Date = from): void => {
      fromDate = this.serverDateConverter(from);
      toDate = this.serverDateConverter(to);
    };

    const setCustomDateRange = (): void => {
      const { start, end } = this.range?.value || {};
      if (!start && !end) {
        resetToToday();
        return;
      }
      fromDate = start ? this.serverDateConverter(start) : null;
      toDate = end ? this.serverDateConverter(end) : fromDate;
    };

    const resetToToday = (): void => {
      this.dateFilterSelectedOption = {
        value: "today",
        label: "Today",
        fromDate: this.serverDateConverter(this.today),
        toDate: this.serverDateConverter(this.today),
      };
      setDateRange(this.today);
    };

    switch (selectedValue.value) {
      case "today":
        setDateRange(this.today);
        break;
      case "yesterday":
        const yesterday = new Date(this.today);
        yesterday.setDate(this.today.getDate() - 1);
        setDateRange(yesterday);
        break;
      case "last7days":
        const last7Days = new Date(this.today);
        last7Days.setDate(this.today.getDate() - 7);
        setDateRange(last7Days, this.today);
        break;
      case "thismonth":
        const startOfMonth = new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          1
        );
        setDateRange(startOfMonth, this.today);
        break;
      case "custom":
        setCustomDateRange();
        break;
      default:
        resetToToday();
    }

    this.dateFilterSelectedOption =
      selectedValue.value !== "custom"
        ? {
            value: selectedValue.value,
            label: selectedValue.label,
            fromDate: fromDate,
            toDate: toDate,
          }
        : {
            value: selectedValue.value,
            label: `${this.clientDateConverter(
              new Date(fromDate)
            )} - ${this.clientDateConverter(new Date(toDate))}`,
            fromDate: fromDate,
            toDate: toDate,
          };
    this.triggerChange.emit(this.dateFilterSelectedOption);
  }
}

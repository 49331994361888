import { Serializable } from "./base";
import { ChangeLogs } from "./change-logs";
import { TripSheet } from "./trip-sheets";
import { DriverProfile } from "./driver-profile";

enum Schemes {
  Daily = "DAILY",
  Weekly = "WEEKLY",
  Dual = "BEST",
  Hybrid = "HYBRID"
}
export class WeeklyTripSheet implements Serializable<WeeklyTripSheet> {
  private km_earning: number;
  private att_incentive: number;
  private basic_attendance: boolean;
  private basic_full_attendance: boolean;
  private basic_is_peak_day: boolean;
  private basic_weekday_ontime: boolean;
  private basic_weekend_ontime: boolean;
  private incentive_comments: string;
  private inc_mbg_earning: number;
  private inc_attendance: boolean;
  private inc_weekday_ontime: boolean;
  private inc_weekend_ontime: boolean;
  private wd_incentive: number;
  private we_incentive: number;
  private order_earning: number;
  private order_count: number;
  private tripsheet_count: number;
  private penalty_amount: number;
  private incentive_earning: number;
  private total_earning: number;
  private updated_at: Date;
  private store_id: number;
  private week_no: number;
  private is_peak_day: boolean;
  private request_incentive: boolean;
  private is_ontime: boolean;
  private is_attendance: boolean;
  private trip_sheet: TripSheet[] = [];
  private slab_name: string = "";
  private manual_incentive: number;
  private sheet_earnings: number;
  public changes: ChangeLogs[] = [];
  public year: number;
  public status: number;
  public driver: DriverProfile;
  public id: number;
  private earning_scheme: string;
  private alternate_incentive_earning: number = 0;
  private alternate_mbg_earning: number = 0;
  private weekly_total_earning: number = 0;
  private alternate_manual_incentive: number = 0;
  private alternate_total_earning: number;
  private alternate_penalty: number;

  get manualIncentive(): number {
    return this.manual_incentive ? this.manual_incentive : 0;
  }

  get sheetEarnings(): number {
    return this.sheet_earnings ? this.sheet_earnings : 0;
  }

  get orderCount() {
    return this.order_count;
  }

  get tripsheetCount() {
    return this.tripsheet_count;
  }

  get incentiveEarning() {
    return this.incentive_earning;
  }

  get totalEarning(): number {
    return this.total_earning ? this.total_earning : 0;
  }

  get updatedAt() {
    return this.updated_at;
  }

  get storeId() {
    return this.store_id;
  }

  get weekNo() {
    return this.week_no;
  }

  /**
   * Returns the incentive comments
   */
  get incentiveComments() {
    return this.incentive_comments;
  }

  /**
   * return the week as "23 Aug to 29 Aug"
   */
  get week() {
    let day = 1 + this.week_no * 7;
    let date = new Date(new Date().getFullYear(), 0, day);
    let first = date.getDate() - (date.getDay() - 1); // First day is the day of the month - the day of the week
    let last = first + 6; // last day is the first day + 6

    return (
      new Date(date.setDate(first)).toDateString() +
      " - " +
      new Date(date.setDate(last)).toDateString()
    );
  }

  get weekDays() {
    let day = 1 + this.week_no * 7;
    let date = new Date(new Date().getFullYear(), 0, day);
    let first = date.getDate() - (date.getDay() - 1); // First day is the day of the month - the day of the week
    let last = first + 6; // last day is the first day + 6

    return (
      new Date(date.setDate(first)).toDateString().slice(0, 10) + //slice without year
      " - " +
      new Date(date.setDate(last)).toDateString().slice(0, 10)
    );
  }

  get tripSheets() {
    return this.trip_sheet;
  }

  get weeklySheetStatus() {
    switch (this.status) {
      case -1:
        return "Not generated";

      case 0:
        return "Pending";

      case 1:
        return "To Approve";

      case 2:
        return "Confirm";

      default:
        return "Not generated";
    }
  }

  get isTripSheetApproved() {
    return this.status > 0;
  }

  get isPeakDay() {
    return this.is_peak_day;
  }

  get isOnTime() {
    return this.is_ontime;
  }

  get isAttendance() {
    return this.is_attendance;
  }

  get requestIncentive() {
    return this.request_incentive;
  }

  /**
   * Returns order count of the day requested.
   * If day is 'week' then returns total order
   * count of all trip sheets
   * @param day
   */
  dayOrderCount(day: string): number {
    if (day == "week") {
      return this.orderCount;
    }

    if (!this.tripSheets.length) {
      return 0;
    }

    day = day.toLowerCase();
    let tripSheet: TripSheet = this.tripSheets.find(
      (tripSheet) => tripSheet.tripDay == day
    );

    if (!tripSheet) {
      return 0;
    }
    return tripSheet.orderCount;
  }

  get slabName() {
    return this.slab_name ? this.slab_name : "NO_SLAB";
  }

  /**
   * return attendance incentive
   */
  get incentiveForAttendance() {
    return this.inc_attendance;
  }

  get incentiveForMBGEarning(): number {
    return this.inc_mbg_earning ? this.inc_mbg_earning : 0;
  }

  /**
   * return weekday on time incentive
   */
  get incentiveForWeekdayOnTime() {
    return this.inc_weekday_ontime;
  }

  /**
   * return weekend on time incentive
   */
  get incentiveForWeekendOnTime(): boolean {
    return this.inc_weekend_ontime;
  }

  /**
   * return attendance incentive
   */
  get attIncentiveEarned(): number {
    return this.att_incentive ? this.att_incentive : 0;
  }

  /**
   * return weekday incentive
   */
  get weekdayIncentiveEarned(): number {
    return this.wd_incentive ? this.wd_incentive : 0;
  }

  /**
   * return weekend incentive
   */
  get weekendIncentiveEarned(): number {
    return this.we_incentive ? this.we_incentive : 0;
  }

  /**
   * Scheme A - No incentives
   * Scheme B - With Incentives
   * Scheme C - Both Scheme A and B
   *            and the scheme with highest total is paid
   */
  get scheme(): string {
    return this.earning_scheme;
  }

  get schemeDescription(): string {
    switch (this.scheme) {
      case Schemes.Daily:
        return "Daily Earnings";
      case Schemes.Weekly:
        return "Weekly Earnings";
      case Schemes.Hybrid:
        return "Hybrid Earnings";
      default:
        return "Best of daily & weekly Earnings";
    }
  }

  get isSchemeDaily(): boolean {
    return this.earning_scheme === Schemes.Daily;
  }

  get isSchemeWeekly(): boolean {
    return this.earning_scheme === Schemes.Weekly;
  }

  get isSchemeDual(): boolean {
    return this.earning_scheme === Schemes.Dual;
  }

  get isSchemeHybrid(): boolean {
    return this.earning_scheme === Schemes.Hybrid;
  }

  get incentiveEarningsSchemeAWeekly(): number {
    return this.alternate_incentive_earning
      ? this.alternate_incentive_earning
      : 0;
  }

  get mbgEarningsSchemeAWeekly(): number {
    return this.alternate_mbg_earning ? this.alternate_mbg_earning : 0;
  }

  get totalEarningsSchemeAWeekly(): number {
    return this.alternate_total_earning;
  }

  get manualIncentiveSchemeAWeekly(): number {
    return this.alternate_manual_incentive;
  }

  get dailyTotalEarnings(): number {
    return this.alternate_total_earning ? this.alternate_total_earning : 0;
  }

  get dailyPenalty(): number {
    return this.alternate_penalty ? this.alternate_penalty : 0;
  }

  get weeklyTotalEarnings(): number {
    return this.weekly_total_earning ? this.weekly_total_earning : 0;
  }

  deserialize(input) {
    if (!input) {
      return;
    }

    this.id = input.id;
    this.incentive_comments = input.incentive_comments;
    this.att_incentive = input.att_incentive;
    this.inc_mbg_earning = input.inc_mbg_earning;
    this.sheet_earnings = input.sheet_earnings;
    this.wd_incentive = input.wd_incentive;
    this.we_incentive = input.we_incentive;
    this.basic_attendance = input.basic_attendance;
    this.basic_full_attendance = input.basic_full_attendance;
    this.basic_is_peak_day = input.basic_is_peak_day;
    this.basic_weekday_ontime = input.basic_weekday_ontime;
    this.basic_weekend_ontime = input.basic_weekend_ontime;
    this.inc_attendance = input.inc_attendance;
    this.inc_weekday_ontime = input.inc_weekday_ontime;
    this.inc_weekend_ontime = input.inc_weekend_ontime;
    this.km_earning = input.km_earning;
    this.order_earning = input.order_earning;
    this.order_count = input.order_count;
    this.tripsheet_count = input.tripsheet_count;
    this.penalty_amount = input.penalty_amount;
    this.incentive_earning = input.incentive_earning;
    this.total_earning = input.total_earning;
    this.updated_at = input.updated_at;
    this.store_id = input.store_id;
    this.week_no = input.week_no;
    this.request_incentive = input.request_incentive;
    this.year = input.year;
    this.status = input.status;
    this.driver = new DriverProfile().deserialize(input.driver);
    this.is_peak_day = input.is_peak_day;
    this.is_ontime = input.is_ontime;
    this.is_attendance = input.is_attendance;
    this.slab_name = input.slab_name;
    this.manual_incentive = input.manual_incentive;
    this.earning_scheme = input.earning_scheme;
    this.alternate_incentive_earning = input.alternate_incentive_earning;
    this.alternate_mbg_earning = input.alternate_mbg_earning;
    this.weekly_total_earning = input.weekly_total_earning;
    this.alternate_manual_incentive = input.alternate_manual_incentive;
    this.alternate_total_earning = input.alternate_total_earning;
    this.alternate_penalty = input.alternate_penalty;

    if (input.trip_sheet && input.trip_sheet.length) {
      input.trip_sheet.forEach((sheet: TripSheet) => {
        let tripSheet = new TripSheet().deserialize(sheet);
        this.trip_sheet.push(tripSheet);
      });
    }

    if (input.changes && input.changes.length) {
      input.changes.forEach((log) => {
        let logs = new ChangeLogs().deserialize(log);
        this.changes.push(logs);
      });
    }

    return this;
  }
}

import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BaseGetProvider } from "@tendercuts/http";
import { InventoryStagingEntry } from "@tendercuts/models";

@Injectable()
export class InventoryApprovalService extends BaseGetProvider<InventoryStagingEntry> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
    this.delegate = this;
  }

  preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof InventoryStagingEntry {
    return InventoryStagingEntry;
  }

  get endpoint(): string {
    return this.endpointService.inventoryApproval();
  }

  get isCachable(): boolean {
    return false;
  }

  // dry_run is set to 1 to load product list without releasing, 0 will release
  getParams(
    categoryId: string,
    storeId: number,
    poId: string = "",
    dryRun: number = 0,
  ): HttpParams {
    let params: HttpParams = new HttpParams();
    params = params.append("category_id", categoryId.toString());
    params = params.append("store_id", storeId.toString());
    if (poId) {
      params = params.append("po_id", poId.toString());
    }
    params = params.append("dry_run", dryRun.toString());

    return params;
  }
}

<div *ngIf="selectedOrder">
  <app-order-display
    [isTripDetailsMode]="isTripDetailsMode"
    [isOrderHistoryMode]="isOrderHistoryMode"
    [order]="selectedOrder"
    [orderSource]="orderSource"
    [isDialogOpen]="isDialogOpen"
    (refreshParent)="closeDialog()"
  ></app-order-display>
</div>

import { Injectable } from "@angular/core";
import { Store as AppStore } from "@ngrx/store";

import * as fromStore from "@store/state";
import { AppState } from "@store/state";
import { AuthProtocol, BasePostProvider } from "@tendercuts/http";
import { GenericServerResponse, User } from "@tendercuts/models";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { Observable } from "rxjs";

@Injectable()
export class InventoryUpdateService
  extends BasePostProvider<GenericServerResponse>
  implements AuthProtocol {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public store: AppStore<AppState>,
    public loginService: LoginServices,
  ) {
    super(http);
  }

  getToken(): Observable<User> {
    return this.store.select(fromStore.getUser);
  }

  getHeaders(token: string): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();

    if (this.isAuthenticatedEndpoint) {
      headers = headers.set("Authorization", `Token ${token}`);
    }

    return headers;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof GenericServerResponse {
    return GenericServerResponse;
  }

  get endpoint(): string {
    return this.endpointService.inventoryUpdate();
  }

  getParams(sheet: File): FormData {
    const params: FormData = new FormData();
    params.append("csv_file", sheet, sheet.name);

    return params;
  }
}

import { Injectable } from "@angular/core";
import { BasePutProvider } from "@tendercuts/http";

import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";

import { HttpClient } from "@angular/common/http";
import { CustomerEavAttribute } from "@tendercuts/models";

@Injectable()
export class CustomerDndService extends BasePutProvider<CustomerEavAttribute> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
  }

  get key(): string {
    return "customer_id";
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get endpoint(): string {
    return this.endpointService.customerDnd();
  }

  get model(): typeof CustomerEavAttribute {
    return CustomerEavAttribute;
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(
    customerId: any,
    value: any,
  ): {
    customer_id: any;
    value: any;
  } {
    return {
      customer_id: customerId,
      value,
    };
  }
}

import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { map, skipWhile, take } from "rxjs/operators";

import { Store } from "@ngrx/store";
import * as selectors from "@store/state";
import { AppState } from "@store/state";
import { ActionTryFlockLogin } from "@store/user/login/login-actions";
import { UserState } from "@store/user/state";

/**
 * If the user is logged in already, we push him to dashboard
 */
@Injectable()
export class FlockGuardService implements CanActivate {
  constructor(private router: Router, private store: Store<AppState>) {}

  /**
   * ensures components with this auth-guard, is only accesible from flock and not
   * from web, even if the user is auth'd
   * @param ActivatedRouteSnapshot route
   * @param RouterStateSnapshot state
   * @returns Observable<boolean> | Promise<boolean> | boolean
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    let sourceUrl: string = state.url;
    sourceUrl = sourceUrl.split("?")[0];

    this.store.dispatch(new ActionTryFlockLogin(route.queryParams));

    return this.store.select(selectors.getUserState).pipe(
      skipWhile((userState: UserState) => userState.loading == true),
      take(1),
      map((userState) => {
        if (userState.user == null || userState.loginViaFlock == false) {
          return false;
        }

        return true;
      }),
    );
  }
}

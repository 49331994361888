import { DeliveryDate } from "../delivery";
import { SaleItem } from "../sale-item";
import { ShippingLaterGroup, ShippingTodayGroup } from "./shipping-group";

/**
 * A holder for all container types
 */
export class ShippingGroupContainer {
  public groups: any[] = [];
  _areSlotsPopulated = false;

  constructor(items: SaleItem[] = []) {
    this.groups.push(new ShippingTodayGroup());
    this.groups.push(new ShippingLaterGroup());
    if (items.length > 0) {
      items.forEach((item) => this.addProduct(item));
    }
  }

  get todayGroup(): ShippingTodayGroup {
    return this.groups[0];
  }

  get laterGroup(): ShippingLaterGroup {
    return this.groups[1];
  }

  get areSlotsPopulated() {
    return this._areSlotsPopulated;
  }

  /**
   * Add a sale item to one of the group types
   *
   * if no group is specified, we do intelligent distribution by looking at
   * SaleItem, otherwise added explicity
   *
   * @param item to be added
   * @param group (optional) if specfied it will be added to the group
   */
  addProduct(item: SaleItem) {

    /** 
     * For Subscription products we dont need to add 
     * to any group
     */
    if(item.isSubscription){
      return;
    }
    // remove the product if already present, this way we will get rid
    // of the complications during product ADD.
    this.removeProduct(item);

    switch (true) {
      // adds only today's product
      case item.isAvailableToday &&
        this.todayGroup.isDateAvailable &&
        !item.isAvailableLater: {
        this.todayGroup.addItem(item);
        break;
      }

      /**
       * Adds both today and tomo inv products 
       * and only tomo inv products as well
       */
      case item.isAvailableLater: {
        this.laterGroup.addItem(item);
        break;
      }

      /**
       * We will reach here only when
       * 1. We have a qty requested that is available in today and future
       *    combined together.
       *      - This will never happen because in add product, we only let
       *        the customer to add when we can serve the requested qty
       *        in either express or scheduled.
       * 2. A customer has requested a qty that is not present in today's
       *    or futures inv
       *      - This will never happen because we have checks in add product
       *        that will ensure that dont accept such requests
       */
      default:
        break;
    }
  }

  /**
   * Item to remove!
   * @param item Item to remove
   */
  removeProduct(item) {
    
    /** 
     * For Subscription products we dont need to add 
     * to any group
     */
     if(item.isSubscription){
      return;
    }
    this.groups.forEach(group => group.removeItem(item));
  }

  /**
   * At this point we fetch all slots and push into the containers the slots
   * Now some containers might become disabled eg express slots might be disabled
   * after 8pm, so to handle such scenario we do a refresh i.e we redistribute
   * the products again
   *
   * Note: we need to ensure that we do not refresh the products after the
   * user has assigned the group, so we need to ensure it is run only once.
   */
  refreshContainers(dates: DeliveryDate[], products: SaleItem[]) {
    if (this._areSlotsPopulated) return false;

    this._areSlotsPopulated = true;
    this.groups.forEach((group) => group.addDates(dates));

    // now redistribute.
    products.forEach((product) => this.addProduct(product));

    // Filters the dates of groups depending on products selected
    this.groups.forEach((group: ShippingLaterGroup | ShippingTodayGroup) =>
      group.refreshSlots()
    );
    return true;
  }

  /**
   * @override
   * Since we have circular references down the stream, we need to implement
   * our customer serialization function so that the parent: Quote will handle
   * it.
   */
  serialize() {
    var data = {};

    this.groups.forEach((group: ShippingLaterGroup | ShippingTodayGroup) => {
      if (!group.isContainerReady) return;

      if (!("items" in data)) data["items"] = [];

      group.items.forEach((item: SaleItem) => {
        var itemData = item.serialize();
        itemData["scheduled_date"] = group.selectedDate.name;
        itemData["slot_id"] = group.selectedSlot.slotId;
        itemData["slot_id"] = group.selectedSlot.slotId;
        data["items"].push(itemData);
      });
    });

    return data;
  }
}

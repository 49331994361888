import { AfterViewInit, Component, Input, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { of, Observable } from "rxjs";
import { FilterModelDataSource } from "../data-source";
import { ColumnAction, RowAction } from "../table-actions";

@Component({
  selector: "app-base-grid",
  templateUrl: "./base-grid.component.html",
  styleUrls: ["./base-grid.component.scss"],
})
export class BaseGridComponent implements AfterViewInit {
  filterModelDataSource: FilterModelDataSource<any>;
  isMultiRowEnabled: boolean = false;
  baseGridDetailComponent: any = null;

  // region Input params
  // Read only rows from model to display.
  @Input() columnsToDisplay: string[] = [];

  // region Input params
  // Read only rows from model to display.
  @Input() footerColumnsToDisplay: string[] = [];

  // disable checkbox selection based on the columns
  @Input() checkBoxDisableColumns: string[] = [];

  // Special rows that allow user interaction
  @Input() actions: ColumnAction[] = [];

  // handle the table row clicks
  @Input() rowClickCallback: RowAction;

  // alias for the column title, if not specified
  // we fall back the attr name.
  @Input() titleToDisplay: string[] = [];

  // Data source to bind to.
  @Input() set dataSource(dataSource: any) {
    this.filterModelDataSource = dataSource;
    if (dataSource) {
      this.filterModelDataSource.sort = this.sort;
      this.filterModelDataSource.paginator = this.paginator;
    }
  }

  // setter to enable multi row rendering in angular
  @Input() set detailComponent(componentInstance: any) {
    this.isMultiRowEnabled = true;
    this.baseGridDetailComponent = componentInstance;
  }

  @Input()
  enableSelection: boolean = true;

  @Input()
  showPagination: boolean = false;

  // callback if set will be triggered to render the row color
  @Input() rowColorRenderer: (value: any) => string;
  // endregion

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor() { }

  ngAfterViewInit(): void {
    this.filterModelDataSource.sort = this.sort;
    this.filterModelDataSource.paginator = this.paginator;
  }

  triggerCallback(event: any, action: ColumnAction, model: any): {} {
    if (!action.callback) {
      return;
    }

    return action.callback(event, model);
  }

  /**
   * handle the table row clicks
   * @param event
   * @param model
   */
  triggerRowCallback(event: any, model: any): void {
    if (!this.rowClickCallback) {
      return;
    }

    this.rowClickCallback.callback(event, model);
  }

  /**
   * Trigger the row color renderer.
   * @param model
   */
  triggerRowColorRenderer(model: any): string {
    if (!this.rowColorRenderer) {
      return;
    }

    return this.rowColorRenderer(model);
  }

  /**
   * columns from the models to be displayed
   * @returns string[]
   */
  get modelColumns(): string[] {
    return this.columnsToDisplay;
  }

  /**
   * columns from model and actions to be displayed
   * @returns string[]
   */
  get columns(): string[] {
    return this.modelColumns.concat(this.actions.map((action) => action.key));
  }

  getTitle(key: string, index: number): string {
    if (this.titleToDisplay[index] !== void 0) {
      return this.titleToDisplay[index];
    }

    return key;
  }

  /**
   *  Since mat-table cannot access nested values in a object i.e user.address.fax.
   *  We are using our own method to access nested values by searching in each level of an object
   *  and returning the value to be shown.
   */
  getValue(key: string, model: any): any {
    /**
     * Splits the objects.
     * i.e user.address.fax will become parts = ['user','address', 'fax']
     */
    const parts: string[] = key.split(".");

    let part: string = parts.shift();
    let value: any = model;
    /* Loops till the final property */
    while (part) {
      value = value[part];
      part = parts.shift();
    }

    /**
     * Return truthy values as it is.
     * Returns '-' for all falsy values except 0.
     */
    return typeof value === 'boolean' || value === 0 || value ? value : "-";
  }

  /**
   * return the total value of the column
   * if datasource contains the data and footerColumnsToDisplay
   * should contain the key value.
   */
  getColumnTotal(key: string): number {
    if (
      !this.filterModelDataSource.data.length ||
      !this.footerColumnsToDisplay.includes(key)
    ) {
      return;
    }

    return this.filterModelDataSource.data
      .reduce((total: number, model: any) => total + +model[key], 0)
      .toFixed(3);
  }

  /**
   * show check box selection based on the column to display.
   * @param key
   */
  isSelectionColumn(key: string): boolean {
    return this.enableSelection && key == "select";
  }

  /**
   * disable check box based on the colums given.
   * @param key
   * @param model
   */
  isCheckBoxDisable(key: string, model: any): boolean {
    if (!this.checkBoxDisableColumns.length) {
      return false;
    }

    return this.checkBoxDisableColumns.every((col) => model[col]);
  }

  /**
   * return the time subscription
   * @param key
   * @param model
   */
  showTimeValue(key: string, model: any): Observable<number> {
    return of(this.getValue(key, model));
  }

  /**
   * return if the value is booelan value or not
   * 1. if boolean show only icons else show icon and text.
   * @param key
   * @param model
   */
  canShowIconText(key: string, model: any): boolean {
    return typeof (this.getValue(key, model)) === 'boolean';
  }
}

import { Serializable } from "./base";
import { ChangeLogs } from "./change-logs";
import { TripEarning } from "./trip-earnings";
import { TripPayment } from "./trip-sheet-pay";

export class TripSheet implements Serializable<TripSheet> {
  private earning_date: string;
  private incentive_earning: string;
  private is_on_time: boolean;
  private mbg_applied: boolean;
  private login_hours: string = "";
  private is_order_count_eligible: boolean;
  private is_slot_check_eligible: boolean;
  private is_trip_count_eligible: boolean;
  private fr_earning: TripEarning[] = [];
  private km_earning: number = 0;
  private order_earning: number = 0;
  private store_id: number;
  private trip_count: number = 0;
  private order_count: number = 0;
  private total_earning: number = 0;
  private driver_name: string;
  private penalty_amount: number = 0;
  private mbg_earning: number = 0;
  private check_in_url: string;
  private check_out_url: string;
  private check_out_value: number;
  private check_in_value: number;
  private corrected_check_in_value: number;
  private corrected_check_out_value: number;
  private request_mbg: boolean = false;
  private request_incentive: boolean = false;
  private updated_at: string;
  private ride_time: string = "";
  private store_time: string = "";
  private manual_incentive: number;
  private check_in: string;
  private check_out: string;
  public driver: number;
  public id: number;
  public status: number;
  public username: string;
  public changes: ChangeLogs[] = [];
  public payment: TripPayment[] = [];
  public totalPaidAmount: number = 0;
  public balanceAmount: number = 0;
  public shift: string;
  // Return the rider check in time
  public checkInTime: string;
  // Return the rider check out time
  public checkOutTime: string;
  private earning_scheme: string;

  get kmEarning(): number {
    return this.km_earning;
  }

  get driverPhone(): string {
    return this.username;
  }

  get driverName(): string {
    return this.driver_name;
  }

  get isOrderCountEligible(): boolean {
    return this.is_order_count_eligible;
  }

  get incentiveEarning(): string {
    return this.incentive_earning;
  }

  get tripEarnings(): TripEarning[] {
    return this.fr_earning;
  }

  get totalFinalKmEarnings(): number {
    return this.tripEarnings.reduce(
      (total: number, earning: TripEarning) =>
        total + Math.abs(earning.finalKms),
      0,
    );
  }

  get totalOrderCount(): number {
    return this.tripEarnings.reduce(
      (total: number, earning: TripEarning) =>
        total + Math.abs(earning.orderCount),
      0,
    );
  }

  /**
   * total kms travelled should be less than speedo meter travelled value
   */
  get isKmViolation(): boolean {
    return this.totalFinalKmEarnings > this.checkOutValue - this.checkInValue;
  }

  /**
   * @param tripEarning
   * @param finalKm (In Raise issue don't have
   * updated trip earning object. so that passing updated km value).
   * if trip earning final kms more than available kms
   * throw an error
   * else push the trip earning
   */
  addTripEarning(tripEarning: TripEarning, finalKm?: number): void {
    const km: number = finalKm ? finalKm : tripEarning.finalKms;
    if (km > this.availableKM) {
      throw new Error("km violation");
    }

    this.fr_earning.push(tripEarning);
  }

  /**
   * total final kms approved
   */
  get totalKms(): number {
    return this.tripEarnings.reduce(
      (total: number, earning: TripEarning) =>
        total + Math.abs(earning.finalKms),
      0,
    );
  }

  /**
   * if there is not no chackOutValue availble km should be 0
   * else odometer reading - total approved kms
   */
  get availableKM(): number {
    return this.checkOutValue && this.checkOutUrl
      ? this.odoMeterKM - this.totalKms
      : 0;
  }

  // total travelled kms
  get odoMeterKM(): number {
    return this.checkOutValue && this.checkOutUrl
      ? this.checkOutValue - this.checkInValue
      : 0;
  }

  /**
   * if triSheet have corrected values return corrected km
   * else return actual kms
   */
  get correctedOdoMeterKm(): number {
    return this.correctedCheckOutValue && this.correctedCheckInValue
      ? this.correctedCheckOutValue - this.correctedCheckInValue
      : this.odoMeterKM;
  }

  get tripCount(): number {
    return this.trip_count;
  }

  get mbgEarning(): number {
    return this.mbg_earning;
  }

  get orderCount(): number {
    return this.order_count;
  }

  get mbgApplied(): boolean {
    return this.mbg_applied;
  }

  get isOnTime(): boolean {
    return this.is_on_time;
  }

  get isSlotCheckEligible(): boolean {
    return this.is_slot_check_eligible;
  }

  get isTripCountEligible(): boolean {
    return this.is_trip_count_eligible;
  }

  get earningDate(): string {
    return this.earning_date;
  }

  get orderEarning(): number {
    return this.order_earning;
  }

  get totalEarning(): number {
    return this.total_earning;
  }

  get totalEarnings(): number {
    return this.total_earning;
  }

  get storeId(): number {
    return this.store_id;
  }

  get driverId(): number {
    return this.driver;
  }

  get checkOutUrl(): string {
    return this.check_out_url;
  }

  get checkInUrl(): string {
    return this.check_in_url;
  }

  get checkOutValue(): number {
    return this.check_out_value;
  }

  get checkInValue(): number {
    return this.check_in_value;
  }

  get requestMbg(): boolean {
    return this.request_mbg;
  }

  get requestIncentive(): boolean {
    return this.request_incentive;
  }

  get updatedAt(): string {
    return this.updated_at;
  }

  get tripSheetStatus(): string {
    switch (this.status) {
      case 0:
        return "Pending";
      case 1:
        return "To Approve";
      case 2:
        return "Confirmed";
      case 3:
        return "Submited";
      default:
        return "";
    }
  }

  get penaltyAmount(): number {
    return this.penalty_amount;
  }

  get isConfirmed(): boolean {
    return this.status > 1;
  }

  get correctedCheckOutValue(): number {
    return this.corrected_check_out_value;
  }

  get correctedCheckInValue(): number {
    return this.corrected_check_in_value;
  }

  updateCheckOutValue(value: number): void {
    this.corrected_check_out_value = value;
  }

  updateCheckInValue(value: number): void {
    this.corrected_check_in_value = value;
  }

  /**
   * show the login hours
   * split and removed mille seconds
   */
  get loginHours(): string {
    return this.login_hours ? this.login_hours.split(".")[0] : "";
  }

  // to show riders riding time
  get ridingTime(): string {
    return this.ride_time ? this.ride_time.split(".")[0] : "";
  }

  // to show riders time, waiting in store
  get onStoreTime(): string {
    return this.store_time ? this.store_time.split(".")[0] : "";
  }

  /**
   * Return manually added incentive.
   */
  get manualIncentive(): number {
    return this.manual_incentive;
  }

  /**
   * total count of updated trip earning kms
   */
  get tripEarningKm(): number {
    return this.tripEarnings.reduce(
      (total: number, earning: TripEarning) =>
        total + Math.abs(earning.originalKm),
      0,
    );
  }

  /**
   * Returns day of trip in short form
   * i.e fri
   */
  get tripDay(): string {
    if (!this.earningDate) {
      return null;
    }

    const tripDay: string = new Date(this.earningDate)
      .toLocaleDateString("en-US", {
        weekday: "short",
      })
      .toLowerCase();

    return tripDay;
  }

  /**
   * calculating the sum of amount and balance to pay
   */
  calculatePaymentAmount(): void {
    this.payment.forEach((total) => {
      this.totalPaidAmount += +total.amount;
    });
    this.balanceAmount = +this.totalEarnings - +this.totalPaidAmount;
  }

  /**
   * check confirmed state
   */
  get isPayable(): boolean {
    return this.status == 2 && this.totalPaidAmount != this.totalEarnings;
  }

  get earningScheme(): string {
    return this.earning_scheme;
  }

  get isSchemeDaily(): boolean {
    return this.earningScheme === "DAILY";
  }

  deserialize(input: any): this {
    if (!input) {
      return;
    }

    this.earning_date = input.earning_date;
    this.manual_incentive = input.manual_incentive;
    this.check_in = input.check_in;
    this.check_out = input.check_out;
    this.check_in_url = input.check_in_url;
    this.check_out_url = input.check_out_url;
    this.check_in_value = input.check_in_value;
    this.check_out_value = input.check_out_value;
    this.corrected_check_in_value = input.corrected_check_in_value;
    this.corrected_check_out_value = input.corrected_check_out_value;
    this.mbg_earning = input.mbg_earning;
    this.incentive_earning = input.incentive_earning;
    this.is_on_time = input.is_on_time;
    this.mbg_applied = input.mbg_applied;
    this.penalty_amount = input.penalty_amount;
    this.is_order_count_eligible = input.is_order_count_eligible;
    this.is_slot_check_eligible = input.is_slot_check_eligible;
    this.is_trip_count_eligible = input.is_trip_count_eligible;
    this.id = input.id;
    this.trip_count = input.trip_count;
    this.order_count = input.order_count;
    this.km_earning = input.km_earning;
    this.order_earning = input.order_earning;
    this.request_incentive = input.request_incentive;
    this.request_mbg = input.request_mbg;
    this.status = input.status;
    this.driver = input.driver;
    this.driver_name = input.driver_name;
    this.username = input.username;
    this.store_id = input.store_id;
    this.total_earning = input.total_earning;
    this.updated_at = input.updated_at;
    this.login_hours = input.login_hours;
    this.ride_time = input.ride_time;
    this.store_time = input.store_time;
    this.shift = input.shift;
    this.earning_scheme = input.earning_scheme;

    // Removed the seconds
    if (this.check_in) {
      let time: string[] = [];
      time = this.check_in.split(".");
      this.checkInTime = time.length ? time[0] : this.check_in;
    }

    // Removed the seconds
    if (this.check_out) {
      let time: string[] = [];
      time = this.check_out.split(".");
      this.checkOutTime = time.length ? time[0] : this.check_out;
    }

    if (input.fr_earning && input.fr_earning.length) {
      input.fr_earning.forEach((earning) => {
        const tripEarning: TripEarning = new TripEarning().deserialize(earning);
        this.fr_earning.push(tripEarning);
      });
    }

    if (input.changes && input.changes.length) {
      input.changes.forEach((log) => {
        const logs: ChangeLogs = new ChangeLogs().deserialize(log);
        this.changes.push(logs);
      });
    }

    if (input.payment && input.payment.length) {
      input.payment.forEach((amount) => {
        const amounts: TripPayment = new TripPayment().deserialize(amount);
        this.payment.push(amounts);
      });
    }
    this.calculatePaymentAmount();

    return this;
  }
}

import { HttpParams } from "@angular/common/http";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Constants } from "@app/env";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import {
  Complaint,
  CustomerEliteSubscription,
  CustomerProfile,
  GenericServerResponse,
  SaleOrder,
  User,
} from "@tendercuts/models";
import {
  CustomerComplainsService,
  CustomerDndService,
  CustomerOrdersService,
  CustomerProfileService,
  CustomerSearchService,
  EliteCustomerDetailService,
} from "@tendercuts/providers";
import { of, Observable, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { FilterModel } from "src/models";
import { EnableCODService } from "src/providers/enable-cod";
import {
  ColumnAction,
  ComplaintDataSource,
  CurrencyPipeAction,
  CustomerComplaintTagComponent,
  DateFormatAction,
  IconAction,
  SaleOrderDataSource,
  TableRowAction,
} from "../../components";
import { AddRewardPointsComponent } from "../../components/add-reward-points/add-reward-points.component";
import { AddStoreCreditComponent } from "../../components/add-store-credit/add-store-credit.component";
import { FreshdeskTicketFormComponent } from "../../components/freshdesk-ticket-form/freshdesk-ticket-form.component";
import { OrderDisplayDialogComponent } from "../../components/order-display-dialog/order-display-dialog.component";
import { SignUpComponent } from "../../components/sign-up/sign-up.component";
import { BasePage } from "../../utils";
import { skipWhile } from "rxjs/operators";

@Component({
  selector: "app-customer-dashboard",
  templateUrl: "./customer-dashboard.component.html",
  styleUrls: ["./customer-dashboard.component.scss"],
})
export class CustomerDashboardComponent
  extends BasePage
  implements OnInit, OnDestroy
{
  index: number = 0;
  websiteUrl: string;
  user: User;

  searchText: string = "";
  customer: User;
  profile: CustomerProfile;
  eliteSubscription: CustomerEliteSubscription;
  orders: SaleOrderDataSource = new SaleOrderDataSource(null, []);
  @Input() columnsToDisplay: string[] = [
    "incrementId",
    "mode",
    "storeName",
    "shippingDescription",
    "payment_method",
    "idealIssuerTitle",
    "subStatus",
  ];

  @Input() titleToDisplay: string[] = [
    "Order Id",
    "Medium",
    "Store Name",
    "Type",
    "Payment Name",
    "Subpayment Mode",
    "Status",
  ];

  // action call for row click
  @Input()
  rowClickCallback: TableRowAction = new TableRowAction(
    this.onOrderSelected.bind(this)
  );

  @Input() actions: ColumnAction[] = [
    new CurrencyPipeAction(
      "Total",
      "total",
      this.getCurrencyPipeFormat.bind(this)
    ),
    new DateFormatAction(
      "Created Date",
      "createdAt",
      this.getDateFormat.bind(this)
    ),
    new DateFormatAction(
      "Sale Date",
      "saleDate",
      this.getDateFormat.bind(this)
    ),
    new IconAction("Rating", "rating", this.showRating.bind(this)),
  ];

  modelFilter: FilterModel = new FilterModel([]);

  @Input()
  complaintsDataSource: ComplaintDataSource = new ComplaintDataSource(
    this.modelFilter,
    []
  );

  componentToRender: typeof CustomerComplaintTagComponent =
    CustomerComplaintTagComponent;
  eventSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public searchCustomerService: CustomerSearchService,
    public orderService: CustomerOrdersService,
    public profileService: CustomerProfileService,
    public constant: Constants,
    private title: Title,
    public customerDndService: CustomerDndService,
    private eliteSubscriptionService: EliteCustomerDetailService,
    private complainService: CustomerComplainsService,
    public enableCODService: EnableCODService,
    private router: Router
  ) {
    super(dialog, snackBar, store);
    this.websiteUrl = constant.WEB_URL;
  }

  /**
   * listen the route params change if the there is any change in the route
   * parameter it will fetch the data according to the activated route value
   */
  ngOnInit(): void {
    this.getUser();
    this.eventSubscription = this.router.events.subscribe((data) => {
      if (data instanceof NavigationEnd) {
        this.loadCustomer();

        return;
      }
    });
    this.loadCustomer();
  }

  ngOnDestroy(): void {
    this.eventSubscription.unsubscribe();
  }
  /*
   * 1. For ozontol redirection using quearyparams
   * 2. For breadcrumbs using fragments
   * Validating params
   */
  loadCustomer(): void {
    // For ozontol redirection we are using queary params
    const routeParam: string = this.route.snapshot.params["phone"]
      ? this.route.snapshot.params["phone"]
      : this.route.snapshot.queryParams["phone"];

    this.title.setTitle("CRM");
    if (routeParam) {
      this.searchText = routeParam.split("&").length
        ? routeParam.split("&")[0]
        : routeParam;

      if (this.searchText.length > 10) {
        this.searchText = this.searchText.slice(-10);
      }
      this.getCustomer();
    }
  }
  /**
   * As the name suggest, using the input number we search the customer data.
   */
  searchCustomer(): void {
    if (this.searchText.toString().length == 0) {
      this.textAlert("Invalid Format", "Please enter valid phone/mail..");

      return;
    }
    this.router.navigate(["/dashboard/crm/" + this.searchText]);
  }

  /*
   * get customer detail
   */
  getCustomer(): void {
    this.orders.data = [];
    this.customer = null;
    this.profile = null;
    this.presentLoader();
    const params: HttpParams = this.searchCustomerService.getParams(
      this.searchText
    );
    this.searchCustomerService.getData(params).subscribe(
      (user: User[]) => this.loadCustomerMeta(user[0]),
      () => {
        this.dismissLoader();
        this.textAlert("Invalid Data", "User doesn't exist");
        // this.openSignUpDialog();
      },
      () => this.dismissLoader()
    );
  }

  loadCustomerMeta(user: User): void {
    this.customer = user;

    const params: HttpParams = this.orderService.getParams(
      this.customer.entity_id
    );
    this.orderService
      .getData(params)
      .subscribe((orders) => (this.orders.data = orders));

    const profileParams: HttpParams = this.profileService.getParams(
      this.customer.entity_id
    );
    this.profileService
      .getData(profileParams)
      .subscribe((profiles) => (this.profile = profiles[0]));

    this.getEliteSubscriptionDetails(user);
  }

  get customerObj(): Observable<User> {
    return of(this.customer);
  }

  openSignUpDialog(): void {
    const dialogRef: MatDialogRef<SignUpComponent, any> = this.dialog.open(
      SignUpComponent,
      {
        data: {
          mail: this.searchText + "@gmail.com",
          phone: this.searchText,
        },
      }
    );

    dialogRef.afterClosed().subscribe((status: boolean) => {
      if (status) {
        this.showNotification(
          "top",
          "center",
          "success",
          "info-circle",
          "Customer Created"
        );
        this.searchCustomer();
      } else {
        this.showNotification(
          "top",
          "center",
          "danger",
          "info-circle",
          "No Customer Created"
        );
      }
    });
  }

  loginAsCustomer(): void {
    const customertoken: string = this.customer.token;
    this.store
      .select((state) => state.userState)
      .pipe(take(1))
      .subscribe((user) => {
        const agentName: string = user.user.firstname;
        const agentToken: string = user.user.token;
        this.websiteUrl = user?.user?.isGtgUser
          ? this.constant.GTG_WEB_URL
          : this.constant.WEB_URL;
        window.open(
          this.websiteUrl +
            "home?cstk=" +
            customertoken +
            "&agNme=" +
            agentName +
            "&agtk=" +
            agentToken
        );
      });
  }

  /**
   * Opens order display dialog for the order selected.
   * Refetches the same customer data again, if the order was canceled or unheld by cc agent.
   * @param order
   */
  onOrderSelected(event: any, order: SaleOrder): void {
    const dialogRef: MatDialogRef<OrderDisplayDialogComponent, any> =
      this.dialog.open(OrderDisplayDialogComponent, {
        data: { order, orderSource: this.orders.data },
        panelClass: "remove-padding",
      });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.getCustomer();
      }
    });
  }

  openModal(templateRef: any): void {
    const dialogRef: MatDialogRef<FreshdeskTicketFormComponent, any> =
      this.dialog.open(FreshdeskTicketFormComponent, {
        disableClose: true,
        width: "60vw",
        data: {
          orders: this.orders.data,
          customer: this.customer,
        },
      });
  }

  openStoreCreditModel(): void {
    const dialogRef: MatDialogRef<AddStoreCreditComponent, any> =
      this.dialog.open(AddStoreCreditComponent, {
        data: { customer: this.customer, orders: this.orders.data },
        width: "27vw",
      });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.searchCustomer();
      }
    });
  }

  popUpMessage(message: string): void {
    this.showNotification("bottom", "center", "", "info-circle", message);
  }

  openTcRewardsdialogue(): void {
    const dialogRef: MatDialogRef<AddRewardPointsComponent, any> =
      this.dialog.open(AddRewardPointsComponent, {
        data: { customer: this.customer, orders: this.orders.data },
        width: "27vw",
      });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.searchCustomer();
      }
    });
  }

  /**
   * @param user userId is passed to load customer subscription details
   */
  getEliteSubscriptionDetails(user: User): void {
    this.customer = user;
    const params: HttpParams = this.eliteSubscriptionService.getParams(
      this.customer.userId
    );
    this.eliteSubscriptionService.getData(params).subscribe(
      (data: CustomerEliteSubscription) => {
        this.eliteSubscription = data[0];
      },
      (err) => this.somethingWentWrong()
    );
  }

  loadData(index: number): void {
    if (index !== 5) {
      return;
    }

    const params: HttpParams = this.complainService.getParams(
      this.customer.userId
    );
    this.complainService.getData(params).subscribe(
      (data: Complaint[]) => {
        this.complaintsDataSource.data = data;
      },
      (err) => {
        this.somethingWentWrong();
      }
    );
  }

  getDateFormat(event: any, order: SaleOrder): string {
    return "mediumDate";
  }

  getCurrencyPipeFormat(event: any, order: SaleOrder): string {
    return '"INR":symbol:"1.0-0"';
  }

  showRating(event: any, order: SaleOrder): string {
    return order.rating ? "fa fa-star" : "";
  }

  /** Hit an api and enable the COD option for the COD blocked cusotmers */
  enableCOD() {
    this.presentLoader();
    let params = this.enableCODService.getParms(+this.customer.userId);
    this.enableCODService.getData(params).subscribe(
      (response: GenericServerResponse[]) => {
        this.showNotification(
          "top",
          "center",
          response[0].status ? "success" : "danger",
          "info-circle",
          response[0].message
        );
        this.dismissLoader();
      },
      (err) => {
        this.somethingWentWrong();
      }
    );
  }

  /**
   * fetch logged user details
   * By this we can get the logged user's role
   */
  async getUser(): Promise<void> {
    this.user = await this.store
      .select((state) => state.userState.user)
      .pipe(
        skipWhile((user) => !user),
        take(1)
      )
      .toPromise();
  }
}

import { SaleItem } from "../sale-item";
import { DeliveryDate, DeliverySlot } from "../delivery";

/**
 * An abstract class that has barebone method and data for creating
 * a Shipping group
 */
export abstract class ShippingGroupType {
  abstract title: string;
  abstract id: number;

  selectedDate: DeliveryDate;
  selectedSlot: DeliverySlot;
  protected _availableDates: DeliveryDate[] = undefined;
  protected _items: SaleItem[] = [];
  TODAY_TITLE = "Available Today";
  TOMORROW_TITLE = "Available Tomorrow";
  COMMON_TITLE = "Available Today / Tomorrow";

  get availableDates() {
    if (
      !this._availableDates ||
      !this._availableDates.length ||
      !this.items.length
    ) {
      return [];
    }
    return this._availableDates;
  }

  /**
   * Every shipping group needs to be set with a desired delivery slot
   * @param slot DeliverySlot
   */
  setSelectedSlot(slot: DeliverySlot) {
    this.selectedSlot = slot;
  }

  addItem(item: SaleItem) {
    this._items.push(item);
  }

  removeItem(item: SaleItem) {
    if (this._items.indexOf(item) != -1) {
      this._items.splice(this._items.indexOf(item), 1);
    }
    if (this._items.length === 0) {
      this.selectedSlot = null;
    }
  }

  get isGroupValid() {
    if (this.items.length === 0) {
      return true;
    }
    if (this.items.length > 0 && this.selectedSlot) {
      return true;
    }
    return false;
  }

  get isEmpty(): boolean {
    return this._items.length == 0;
  }
  get items(): SaleItem[] {
    return this._items;
  }

  get todayDate() {
    if (!this.availableDates || !this.availableDates.length) {
      return null;
    }
    return this.availableDates.find((date: DeliveryDate) => date.isToday);
  }

  get tomorrowDate() {
    if (!this.availableDates || !this.availableDates.length) {
      return null;
    }
    return this.availableDates.find((date: DeliveryDate) => !date.isToday);
  }

  /** Sets container title depending on delivery dates available
   * for the selected products
   */
  abstract setTitle();

  get isTomoGroup(): boolean {
    return this.id === 1;
  }

  /**
   * Adds delivery dates to the containers
   */
  addDates(dates: DeliveryDate[]): DeliveryDate[] {
    this._availableDates = [];
    dates.forEach((date) => {
      /**
       * making a copy and storing to not run into same reference
       * object errors as both containers will have same slots
       */
      this._availableDates.push(DeliveryDate.makeCopy(date));
    });
    return this._availableDates;
  }

  /**
   * Used to filter out the delivery dates depending upon on
   * products selected and their availability
   */
  refreshSlots() {
    if(!this.items || !this.items.length){
      // ignore refreshing if no product is available in the container
      return;
    }
    this._availableDates.forEach((date) => (date.isAvailable = true));
    for (let item of this.items) {
      if (!item.isAvailableToday) {
        let todaySlot = this._availableDates.find((slot) => slot.isToday);
        if (todaySlot) {
          todaySlot.isAvailable = false;
        }
      }
      if (!item.isAvailableLater) {
        let futureSlot = this._availableDates.find((slot) => !slot.isToday);
        if (futureSlot) {
          futureSlot.isAvailable = false;
        }
      }
    }
    this.setTitle();
  }

  /**
   * If available slots is not set, it means we have not fetched it from
   * server so at this point we assume that all the slots are available.
   */
  get isDateAvailable(): boolean {
    if (!this._availableDates) return true;

    return this._availableDates.length > 0 ? true : false;
  }

  /**
   * Shortcut to check if both the slots & date are set!!
   */
  get isContainerReady(): boolean {
    return this.selectedDate != undefined && this.selectedSlot != undefined;
  }
}

/**
 * A class that represents a shipment group/items that will be delivered today
 */
export class ShippingTodayGroup extends ShippingGroupType {
  public title = "Available Today";
  public id = 0;

  setTitle() {
    this.title = this.TODAY_TITLE + ` [ ${this.todayDate.formattedDate} ]`;
  }
}

/**
 * A class that represents a shipment group/items that will be delivered later
 */
export class ShippingLaterGroup extends ShippingGroupType {
  public title = "Available Today / Tomorrow";
  public id = 1;

  /**
   * Set title as
   * 0. "Available Today / Tomorrow" if all the items are available
   *    for both today & tomorrow.
   * 1. "Available tomorrow" if all the items are available only
   *    for tomorrow
   * 2. "Available today" if all the items are available only for today
   *     This case is technically not possible(wrote for safety) as 
   *     this container will only products that inventory for both today 
   *     & tomorrow or only tomorrow.
   */
  setTitle() {
    let isTodaySlotsAvailable = true;
    let isFutureSlotsAvailable = true;
    this._availableDates.forEach((date) => {
      if (date.isToday && !date.isAvailable) {
        isTodaySlotsAvailable = false;
      }
      if (!date.isToday && !date.isAvailable) {
        isFutureSlotsAvailable = false;
      }
    });
    if (isTodaySlotsAvailable && isFutureSlotsAvailable) {
      this.title = this.COMMON_TITLE;
      return;
    }
    if (isFutureSlotsAvailable) {
      this.title =
        this.TOMORROW_TITLE + ` [ ${this.tomorrowDate.formattedDate} ]`;
      return;
    }
    if (isTodaySlotsAvailable) {
      this.title = this.TODAY_TITLE + ` [ ${this.todayDate.formattedDate} ]`;
    }
  }
}

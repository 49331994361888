import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BaseGetProvider, DataTransformationProtocol } from "@tendercuts/http";
import { HyperTrackOrder } from "@tendercuts/models";

@Injectable({
  providedIn: "root",
})
export class HyperTrackOrderService
  extends BaseGetProvider<HyperTrackOrder>
  implements DataTransformationProtocol {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices
  ) {
    super(http);
    this.delegate = this;
  }

  preProcessResponse(response: any): Array<any> {
    return response.results[0];
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof HyperTrackOrder {
    return HyperTrackOrder;
  }

  get endpoint(): string {
    return this.endpointService.fetchHyperTrackOrderDetail();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(orderId: string): {
    order_id: string;
  } {
    const params: {
      order_id: string;
    } = {
      order_id: orderId,
    };

    return params;
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BaseGetProvider } from "@tendercuts/http";
import { WeeklyTripSheet } from "@tendercuts/models";

@Injectable({
  providedIn: "root",
})
export class WeeklyTripSheetDisplayService extends BaseGetProvider<WeeklyTripSheet> {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http);
    this.delegate = this;
  }

  // override this
  public preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof WeeklyTripSheet {
    return WeeklyTripSheet;
  }

  get endpoint(): string {
    return this.endpointService.generateWeeklyTripsheet();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(driverId: number): {
    driver_id: number;
} {
    const params: {
      driver_id: number;
  } = {
      driver_id: driverId,
    };

    return params;
  }

  getWeeklyTripSheets(weekNo: string, storeId: number): {
    week_no: string;
    store_id: number;
    status: number;
} {
    const params: {
      week_no: string;
      store_id: number;
      status: number;
  } = {
      week_no: weekNo,
      store_id: storeId,
      status: 1,
    };

    return params;
  }
}

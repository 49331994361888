import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { OrderState } from "@store/orders/state";
import { AppState } from "@store/state";
import { StoreState } from "@store/store/state";
import { SaleItem, SaleOrder, TcutsCity } from "@tendercuts/models";
import { Product, Store as TcStore } from "@tendercuts/models";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { BasePage } from "src/app/utils";

// TODO
// eslint-disable-next-line @typescript-eslint/naming-convention
export interface Taxtable {
  taxamount: number;
  taxpercent: number;
  rowTotalWithDiscount: number;
}

@Component({
  selector: "app-invoice",
  templateUrl: "./invoice.component.html",
  styleUrls: ["./invoice.component.scss"],
})
export class InvoiceComponent extends BasePage implements OnInit {
  /**input setter used to set the input,
   * calculate the gst table here
   */
  @Input() set setOrder(order: SaleOrder) {
    this.order = order;
    const newtaxstatus: {
      [taxpercent: number]: Taxtable;
    } = {};
    this.order.simpleItems.forEach((item) => {
      if (!newtaxstatus[item.taxPercent]) {
        newtaxstatus[item.taxPercent] = {
          taxpercent: item.taxPercent,
          taxamount: 0,
          rowTotalWithDiscount: 0,
        };
        this.gstTable.push(newtaxstatus[item.taxPercent]);
      }
      newtaxstatus[item.taxPercent].taxamount +=
        +item.taxAmount + +item.shippingHiddenTaxAmount;
      newtaxstatus[item.taxPercent].rowTotalWithDiscount +=
        +item.rowTotalWithDiscount + +item.shippingAmountWithoutGst;
    });
  }

  @Input() butcherMode: boolean = false;
  @Input() storeAddress: string;
  @Input() index: number;
  ratingTags: string[] = [];
  isPriority: boolean = false;
  gstNumber: string;
  address: string;
  order: SaleOrder;
  gstTable: Taxtable[] = [];
  fssaiCode: string;
  isGtgStore: boolean;

  productMap: { [id: number]: Product };
  cityPhoneNo: string;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    this.fetchData();
  }

  /**
   * Fetch the store address and gst number from redux
   */
  fetchData(): void {
    this.store
      .select((state) => state)
      .pipe(take(1))
      .subscribe((state: AppState) => {
        this.productMap = state.catalog.productMap;
        const selectedStore: TcStore = state.storeState.allStores.find(
          (store) => store.storeId == this.order.storeId
        );
        if (selectedStore) {
          this.address = selectedStore.address;
          this.gstNumber = selectedStore.storeAttribute.gstNumber;
          this.fssaiCode =  selectedStore.storeAttribute.fssaiCode;
          this.isGtgStore = selectedStore.isGtg;
        }
        const selectedCity: TcutsCity = state.storeState.cities.find(
          (city) => city.code == selectedStore.storeAttribute.city
        );
        this.cityPhoneNo = selectedCity.phone;
        this.fetchPriorityOrderData(state.orderState);
      });
  }

  /**
   * Fetch the rating tags and set isPriority flag based on the saleorder
   * @param state
   */
  fetchPriorityOrderData(state: OrderState): void {
    if (!state.priorityOrdertags.priorityOrder) {
      return;
    }

    this.ratingTags =
      state.priorityOrdertags.priorityOrder[this.order.incrementId];
    this.isPriority = this.ratingTags ? true : false;
  }

  /**
   * 1) Showing the gross and net only for online orders and sea food products
   * 2) If no gross and net showing "NA"
   * @param item
   */
  productWeight(item: SaleItem): boolean | string {
    const product: Product = this.productMap[item.productId];
    if (this.order.isPosOrder || !item.isSeafood || !product) {
      return false;
    }

    return product.grossWeight + " | " + product.netWeight;
  }

  get eliteSavingsMessage(): string {
    if (!this.order?.eliteSaving) {
      return ``;
    }

    return `You saved ₹${this.order?.eliteSaving} by using your Elite Subscription.`;
  }

  get brandTitle(): string {
    return this.isGtgStore ? 'GoodToGo' : "Tendercuts";
  }

  get storeState(): Observable<StoreState> {
    return this.store.select((state) => state.storeState);
  }
}

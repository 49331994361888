import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { UserState } from "@store/user/state";
import { BookingRequest } from "@tendercuts/models";
import { skipWhile, take } from "rxjs/operators";
import { ButtonAction, HyperLinkAction } from "../../components/table-actions";
import { BasePage } from "../../utils";
import { BookingEditComponent } from "../booking-edit";
import { BookingDetailsDataSource } from "../data-source";
import { ColumnAction } from "../table-actions";

/**
 * To display booking details for an driver
 */
@Component({
  selector: "app-booking-request-wrapper",
  templateUrl: "./booking-request-wrapper.component.html",
  styleUrls: ["./booking-request-wrapper.component.scss"],
})
export class BookingDisplayTabWrapperComponent extends BasePage implements OnInit {
  // To display display driverId hyperlink
  @Input() isRedirectToCrm: boolean = false;
  // To display colours to row depends up on status
  rowColorRenderer: any = this.statusColourChange.bind(this);

  // grid actions
  @Input() actions: ColumnAction[] = [];

  @Input() columnsToDisplay: string[] = [
    "storeId",
    "driverDetails.firstName",
    "bookedDate",
    "respondDateTime",
    "bookingStatus",
    "driverAttendance",
    "expireAt",
    "shiftStatus",
  ];
  @Input() titleToDisplay: string[] = [
    "Store Id",
    "Driver Name",
    "Request Date",
    "Respond Date & Time",
    "Request Status",
    "Present/Absent",
    "Request Expire At",
    "Shift Status",
  ];
  @Input()
  dataSource: BookingDetailsDataSource;

  // refresh the parent emitter
  @Output()
  refreshParent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public router: Router,
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    this.fetchUser();
    if (this.isRedirectToCrm) {
      this.actions = [
        new HyperLinkAction(
          "Driver Id",
          "driverDetails.driverId",
          this.redirectToDriverCrm.bind(this),
        ),
      ];
    }
  }

  async fetchUser(): Promise<void> {
    const userState: UserState = await this.store
      .select((state) => state.userState)
      .pipe(
        skipWhile((state) => state.loading),
        take(1),
      )
      .toPromise();
    if (userState.user && userState.user.isDeliveryManager) {
      this.actions.push(
        new ButtonAction("Edit", "edit", this.editBookingRequest.bind(this)),
      );
    }
  }

  /**
   * Redirect to Driver crm component
   */
  redirectToDriverCrm(event: any, booking: BookingRequest): void {
    window.open(
      window.location.origin +
        "/dashboard/driver-crm/" +
        booking.driverDetails.phoneNumber,
      "_blank",
    );
  }

  /**
   *
   * @param event
   * @param booking
   */
  editBookingRequest(event: any, booking: BookingRequest): void {
    if (!booking.isTodayBooking && !booking.isTomorrowBooking) {
      this.showNotification(
        "bottom",
        "center",
        "danger",
        "info-circle",
        "Selected Booking is not today's booking",
      );

      return;
    }

    const dialog: MatDialogRef<
    BookingEditComponent, any
    > = this.dialog.open(BookingEditComponent, {
      panelClass: "remove-padding",
      data: booking,
    });

    dialog.afterClosed().subscribe((status) => {
      if (status) {
        this.refreshParent.emit(true);
      }
    });
  }

  /**
   * Give colour for the status
   * @param booking
   */
  statusColourChange(booking: BookingRequest): string {
    switch (booking.bookingStatus) {
      case "reject":
        return "#EB6262";
      case "confirm":
        return "#B6E8B0";
      default:
        return "#fff";
    }
  }
}

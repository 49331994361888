import { HttpParams } from "@angular/common/http";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { LocationPing, Trips } from "@tendercuts/models";
import { TripPathService } from "../../../providers";

@Component({
  selector: "app-map-trip",
  templateUrl: "./map-trip.component.html",
  styleUrls: ["./map-trip.component.scss"],
})
export class MapTripComponent implements OnInit, OnDestroy {
  private polyline: any;
  private pings: LocationPing[];

  @Input() mapRef: any;

  mapTrip: Trips;

  @Input() set trip(trip: Trips) {
    this.mapTrip = trip;

    if (this.polyline) {
      this.polyline.setMap(null);
    }

    const params: HttpParams = this.tripService.getParams(trip.tripId + "");
    this.tripService.getData(params).subscribe((pings: LocationPing[]) => {
      this.pings = pings;
      this.generateRouteLine();
    });
  }

  constructor(private tripService: TripPathService) {}

  private generateRouteLine(): void {
    const lineSymbol: {
      path: google.maps.SymbolPath;
    } = {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    };
    // Extract points in google format.
    const points: any[] = [];
    this.pings.forEach((ping) => {
      const location: {
        lat: number;
        lng: number;
      } = { lat: ping.lat, lng: ping.lng };
      points.push(location);
    });

    // draw the direction line
    this.polyline = new google.maps.Polyline({
      path: points,
      strokeColor: "red",
      strokeWeight: 2,
      strokeOpacity: 0.5,
      geodesic: true,
      icons: [
        {
          icon: lineSymbol,
          // offset: '100%',
          repeat: "50px",
        },
      ],
    });
    this.polyline.setMap(this.mapRef);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.polyline.setMap(null);
  }
}

<mat-card>
  <mat-card-title>Cash Splitup</mat-card-title>
  <mat-table #matTable [dataSource]="cashLineDataSource" matSort>
    <ng-container matColumnDef="cashId">
      <mat-header-cell *matHeaderCellDef> Res-Id </mat-header-cell>
      <mat-cell *matCellDef="let cashLines">
        <span class="label">{{ cashLines.orderId }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cashType">
      <mat-header-cell *matHeaderCellDef> Cash Type </mat-header-cell>
      <mat-cell *matCellDef="let cashLines">
        <span class="label">{{ cashLines.cashType }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cashCollected">
      <mat-header-cell *matHeaderCellDef> Cash Collected </mat-header-cell>
      <mat-cell *matCellDef="let cashLines">
        <span class="label">{{ cashLines.amount }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <mat-header-cell *matHeaderCellDef> Date & Time </mat-header-cell>
      <mat-cell *matCellDef="let cashLines">
        <span class="label">{{ cashLines.createdAt }}</span>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnsToDisplay"></mat-row>
  </mat-table>
</mat-card>

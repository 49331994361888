import { BarcodeSpec } from "@tendercuts/models";

export class BarcodeParser {
  constructor() {}

  /**
   * Get the id and weight in 8 or 17 digit barcode
   * return product_id, weight, date and time
   * 8 digit barcode 19300500
   * (Product Id(3 digits) + Weight(5 digits))
   * 17 digit barcode 30060050019220193
   * (DATE(2digits) + MONTH(2digits) + Product Weight(5digits) + Time(4digits) + Product ID(4digits))
   * @param barcode
   */
  static parse(barcode: string) {
    let weight = Number.parseInt(
      barcode.length == 8 ? barcode.substr(3) : barcode.substr(4, 5)
    );

    let id = barcode.length == 8 ? barcode.substr(0, 3) : barcode.substr(13, 4);

    let date =
      barcode.length == 8 ? new Date().getDate().toString() : barcode.substr(0, 4);

    let time =
      barcode.length == 8 ? new Date().getTime().toString() : barcode.substr(9, 4);

    let barcodeSpec = new BarcodeSpec().deserialize({
      product_id: id,
      weight: weight,
      is_new_format: barcode.length == 17,
      date: this.setDateTime(date, time).toDateString(),
      time: this.setDateTime(date, time).toLocaleTimeString()
    });

    return barcodeSpec;
  }

  /**
   * @function to set date and time values of barcode as Date format
   * @param date 
   * @param time 
   */
  static setDateTime(date: string, time: string) {
    const now = new Date();
    now.setDate(parseInt(date.substring(0, 2)));
    now.setMonth(parseInt(date.substring(2)) - 1);
    now.setHours(parseInt(time.substring(0, 2)));
    now.setMinutes(parseInt(time.substring(2)));

    return now;
  }
}

<div class="row map-header no-gutters">
  <div class="col-10" *ngIf="orders">
    <app-store-segment-filter
      [dataSource]="orders"
      [noTitle]="true"
      [noCount]="true"
      (onFilterApplied)="applyFilters($event)"
    ></app-store-segment-filter>
  </div>
  <div class="col-1" *ngIf="editTripMode; else assignRider">
    <span style="margin-left: 30px">
      <button mat-stroked-button color="warn" (click)="askConfirmation()">
        Edit Trip
      </button>
    </span>
  </div>
  <ng-template #assignRider>
    <div class="col-1">
      <span>
        <button
          mat-stroked-button
          color="warn"
          [disabled]="selectedTrip?.orders.size == 0 || isSelectionInProgress"
          (click)="showDriverSelection(selectedTrip, true)"
        >
          Create Dummy Trip
        </button>
      </span>
    </div>
    <div class="col-1" *ngIf="(canShowAssignRider | async)">
      <span>
        <button
          mat-stroked-button
          color="warn"
          [disabled]="selectedTrip?.orders.size == 0 || isSelectionInProgress"
          (click)="showDriverSelection(selectedTrip)"
        >
          Assign Driver & Sequence
        </button>
      </span>
    </div>
  </ng-template>
</div>

<div class="map-side" *ngIf="(selectedStore | async)">
  <div class="all-orders" *ngIf="selectedTrip">
    <p class="pane-title">
      <span class="col-6">Available Orders</span>
    </p>
    <app-trip-order-table
      (addOrderToTrip)="addOrderToTrip($event)"
      (onOrderClicked)="clickedOrder = $event"
      [availableOrdersMode]="true"
      [orders]="availableOrders"
    >
    </app-trip-order-table>
  </div>

  <agm-map
    #map
    (mapReady)="renderSegment($event)"
    [zoom]="zoom"
    [zoomControlOptions]="{ position: 3 }"
    [latitude]="(selectedStore | async)?.location?.lat"
    [longitude]="(selectedStore | async)?.location?.lng"
    [disableDefaultUI]="false"
    [zoomControl]="true"
    (idle)="tagLabels()"
  >
    <div *ngFor="let order of tripOrders; let i = index">
      <app-address-marker
        [order]="order"
        [sequenceNumber]="i + 1"
        [markerIcon]="waypointMarkerIcon"
        [isSelected]="selectedTrip?.orders.has(order.incrementId)"
        [displayWindow]="order == clickedOrder"
        [showCircle]="false"
        (onClick)="selectOrders($event)"
      >
      </app-address-marker>
    </div>
    <agm-marker-cluster
      [imagePath]="'assets/marker/cluster/m'"
      [averageCenter]="true"
      [gridSize]="10"
      [maxZoom]="14"
    >
      <div *ngFor="let order of orders?.filteredData">
        <!--Order markers-->
        <app-address-marker
          *ngIf="order.isUnAssignedOrder"
          [order]="order"
          [isSelected]="selectedTrip?.orders.has(order.incrementId)"
          [displayWindow]="order == clickedOrder"
          [circleRadius]="100"
          (onClick)="selectOrders($event)"
        ></app-address-marker>
      </div>
    </agm-marker-cluster>

    <!--Store marker-->
    <app-store-marker [storeObj]="selectedStore | async"></app-store-marker>

    <!-- direction & ETA -->
    <div *ngIf="selectedTrip">
      <!--Order locations-->
      <!-- <app-trip-direction
          [trip]="selectedTrip"
          (afterRender)="updateTrip($event)"
        ></app-trip-direction> -->
    </div>

    <!-- Render store segments -->
    <ng-container *ngIf="mapRawRef">
      <app-store-segment
        *ngFor="let segment of segments"
        [map]="mapRawRef"
        [noHover]="true"
        [storeSegment]="segment"
        [highlight]="hoveredSegment == segment"
      ></app-store-segment>
    </ng-container>
  </agm-map>

  <div class="trip-orders" *ngIf="selectedTrip && tripOrders">
    <p class="pane-title">
      <span class="col-6">{{ selectedTrip.titleWithoutKm }}</span>
    </p>
    <app-trip-order-table
      [orders]="tripOrders"
      (onOrderClicked)="clickedOrder = $event"
      (removeOrderFromTrip)="removeOrderFromTrip($event)"
    >
    </app-trip-order-table>
  </div>
</div>

import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  IterableDiffer,
  IterableDiffers,
  OnDestroy,
  Output,
  ViewChild,
} from "@angular/core";
import { Store } from "@ngrx/store";

import { OrderState } from "@store/orders";
import { AppState } from "@store/state";
import { OptimalTrip, Trips } from "@tendercuts/models";

import { BaseComponent } from "../../utils";
import DirectionsResult = google.maps.DirectionsResult;
import DirectionsLeg = google.maps.DirectionsLeg;
import { AgmDirection } from "./agm-direction";

@Component({
  selector: "app-trip-direction",
  templateUrl: "./trip-direction.component.html",
  styleUrls: ["./trip-direction.component.scss"],
})
export class TripDirectionComponent
  extends BaseComponent
  implements DoCheck, OnDestroy {
  // to detect changes within an array
  iterableDiffer: IterableDiffer<string>;
  direction: any = null;
  markerOptions: {
    waypoints: {
      icon: string;
      draggable: boolean;
    };
  } = {
    waypoints: {
      icon:
        "https://www.shareicon.net/data/32x32/2016/04/28/756617_face_512x512.png",
      draggable: false,
    },
  };

  @Input() trip: Trips | OptimalTrip;
  @Output() afterRender: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("directionDisplay")
  directionDisplay: AgmDirection;

  constructor(
    private iterableDiffers: IterableDiffers,
    public store: Store<AppState>,
  ) {
    super(store);
    this.iterableDiffer = this.iterableDiffers.find([]).create();
  }

  ngDoCheck(): void {}

  ngOnDestroy(): void {
    this.direction = {};
  }

  renderRoute(): void {
    if (!this.trip || this.trip.orders.size < 1) {
      this.direction = {};

      return;
    }

    this.selectedStore.subscribe((store) => {
      // store point
      const storePoint: {
        lat: number;
        lng: number;
      } = {
        lat: parseFloat(store.location.latitude),
        lng: parseFloat(store.location.longitude),
      };

      this.orderState.subscribe((state: OrderState) => {
        // Get waypoints
        const waypoints: any[] = [];

        // TODO: Cln up.
        this.trip.orders.forEach((orderId) => {
          // skipping the order if it is not picked by driver
          if (state.orderMap[orderId] != undefined) {
            waypoints.push({
              location: {
                lat: parseFloat(
                  state.orderMap[orderId].shippingAddress.Olatitude,
                ),
                lng: parseFloat(
                  state.orderMap[orderId].shippingAddress.Olongitude,
                ),
              },
              stopover: true,
            });
          }
        });

        // Generate direction
        this.direction = {
          origin: storePoint,
          destination: storePoint,
          waypoints,
          visible: true,
          markerOptions: this.markerOptions,
        };
      });
    });
  }

  onAfterRender(response: DirectionsResult): void {
    let distance: number = 0;
    let time: number = 0;

    // google routes object
    response.routes[0].legs.forEach((leg: DirectionsLeg) => {
      distance += leg.distance.value / 1000;
      time += leg.duration.value / 60;
    });

    distance = Math.round(distance);
    time = Math.round(time);

    this.afterRender.emit([distance, time, response.routes[0].waypoint_order]);
  }
}

import { Component, Input } from "@angular/core";
import { Trips } from "@tendercuts/models";
import { ColumnAction, DateFormatAction } from "src/app/components";
import { BaseGridComponent } from "src/app/components/base-grid/base-grid.component";

@Component({
  selector: "app-trip-grid",
  templateUrl: "../../components/base-grid/base-grid.component.html",
  styleUrls: ["../../components/base-grid/base-grid.component.scss"],
})
export class TripGridComponent extends BaseGridComponent {
  @Input() columnsToDisplay: string[] = [
    "id",
    "tripDriverName",
    "tripTimeInMinutes",
    "state",
    "driverOrderLength",
    "sequencedOrdersLength",
    "scannedOrdersLength",
    "tripActualAmount",
    "tripSubmittedAmount",
  ];

  @Input() titleToDisplay: string[] = [
    "Trip-Id",
    "Driver-Name",
    "Elapsed Time(Mins)",
    "Status",
    "Total Orders",
    "Pending Orders",
    "Scanned Orders",
    "Actual Amount",
    "Submitted Amount",
  ];

  @Input() actions: ColumnAction[] = [
    new DateFormatAction(
      "Date & Time",
      "tripDate",
      this.getDateFormat.bind(this),
    ),
  ];

  getDateFormat(event: any, trip: Trips): string {
    return "MMM d, h:mm a";
  }
}

import { Component, Input } from "@angular/core";
import { BaseGridComponent } from "../base-grid/base-grid.component";

/**
 * To display barcode-spec details
 */
@Component({
  selector: "app-barcode-spec-grid",
  templateUrl: "../base-grid/base-grid.component.html",
  styleUrls: ["../base-grid/base-grid.component.scss"],
})
// TODO
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class BarcodeSpecGrid extends BaseGridComponent {
  @Input() columnsToDisplay: string[] = [
    "product_id",
    "weight",
    "date",
    "time",
  ];
  @Input() titleToDisplay: string[] = [
    "Product-Id",
    "Weight(kg)",
    "Date",
    "Time",
  ];
}

import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { User, WalletTransaction } from "@tendercuts/models";
import { ColumnAction, DateFormatAction, IconAction } from "../table-actions";

import { HttpParams } from "@angular/common/http";
import { Constants } from "@app/env";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { UserState } from "@store/user/state";
import { WalletTransactionService } from "@tendercuts/providers";
import { Observable } from "rxjs";
import { FilterModel } from "src/models";
import { BasePage } from "../../utils/pages/base/base.component";
import { WalletTransactionDataSource } from "../data-source";

@Component({
  selector: "app-wallet-transaction",
  templateUrl: "./wallet-transaction.component.html",
  styleUrls: ["./wallet-transaction.component.scss"],
})
export class WalletTransactionComponent extends BasePage implements OnInit {
  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public walletTransactionService: WalletTransactionService,
    public constants: Constants
  ) {
    super(dialog, snackBar, store);
  }

  get userState(): Observable<UserState> {
    return this.store.select((state) => state.userState);
  }
  transactionRecords: WalletTransaction[];
  @Input() hidePlaceholder: boolean = false;
  @Input() customerObj: User = null;
  @Input() user: User;
  columnsToDisplay: string[] = ["message", "updatedBy", "reason"];
  titleToDisplay: string[] = ["Details", "AddedBy", "Reason"];
  @Input() actions: ColumnAction[] = [
    new DateFormatAction("Date", "created_at", this.getDateFormat.bind(this)),
    new IconAction(
      "Wallet Amount",
      "balance_delta",
      this.showAmount.bind(this)
    ),
  ];

  modelFilter: FilterModel = new FilterModel([]);

  @Input()
  walletTransactionDataSource: WalletTransactionDataSource = new WalletTransactionDataSource(
    this.modelFilter,
    []
  );

  /**
   * Gets store credit data based on the customer_id and selected filters
   * @param filters HttpParams object containing the selected filters
   */
  filterStoreCredit(filters: HttpParams): void {
    this.presentLoader();
    filters = filters.append("customer_id", this.customerObj.userId);
    this.walletTransactionService.getData(filters).subscribe(
      (data: WalletTransaction[]) => {
        this.transactionRecords = data;
        this.walletTransactionDataSource.data = data;
        this.dismissLoader();
      },
      (err) => this.somethingWentWrong()
    );
  }

  ngOnInit(): void {
    this.filterStoreCredit(new HttpParams());
  }

  getDateFormat(event: any, wallet: WalletTransaction): string {
    return "mediumDate";
  }

  showAmount(event: any, wallet: WalletTransaction): string {
    return +wallet.balance_delta > 0
      ? "fa fa-plus-circle fa-lg"
      : "fa fa-minus-circle fa-lg";
  }
}

import { SelectionModel } from "@angular/cdk/collections";
import { Component, Inject, OnInit } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { ActionStitchTripOrders } from "@store/driver";
import { AppState } from "@store/state";
import { GenericServerResponse, SaleOrder, Trips } from "@tendercuts/models";
import { UnassignOrderService } from "@tendercuts/providers";
import { BasePage } from "../../utils";

@Component({
  selector: "app-remove-trip-orders",
  templateUrl: "./remove-trip-orders.component.html",
  styleUrls: ["./remove-trip-orders.component.scss"],
})
export class RemoveTripOrdersComponent extends BasePage implements OnInit {
  selection: SelectionModel<SaleOrder> = new SelectionModel<SaleOrder>(
    true,
    []
  );
  avlOrders: any[] = [];
  loading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<RemoveTripOrdersComponent>,
    @Inject(MAT_DIALOG_DATA) public trip: Trips,
    public unassignOrdersService: UnassignOrderService,
    public store: Store<AppState>,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {
    super(dialog, snackBar, store);
  }

  /**
   * Fetch the available order that can be removed from the trip
   * if its a broadcasted trip only 20% of orders can be removed from the trip
   */
  ngOnInit(): void {
    this.avlOrders = this.trip.sequencedOrders.filter(
      (order) => order.isProcessing || order.isOutForDelivery
    );
  }

  /**
   * To check all orders are selected
   */
  get isAllOrdersSelected(): boolean {
    return this.selection.selected.length === this.avlOrders.length;
  }

  /**
   * Used to toggle all Orders selection
   */
  toggleAllOrdersSelection(event: MatCheckboxChange): void {
    if (!event.checked) {
      this.selection.clear();

      return;
    }
    this.selection.select(...this.avlOrders);
  }

  /**
   * Triggers server request to remove order.
   */
  unassignOrders(): void {
    const orders: SaleOrder[] = this.selection.selected;
    const data: {
      trip_id: number;
      order_ids: string[];
    } = this.unassignOrdersService.getParams(this.trip, orders);
    this.loading = true;
    this.unassignOrdersService.getData(data).subscribe(
      (response: GenericServerResponse[]) => {
        if (!response[0].status) {
          this.showNotification(
            "top",
            "center",
            "danger",
            "info-circle",
            response[0].message
          );
          this.dialogRef.close(2);

          return;
        }

        this.dialogRef.close(1);
        orders.forEach((order) => this.trip.orders.delete(order.incrementId));
        this.orderState.subscribe((state) =>
          this.store.dispatch(new ActionStitchTripOrders(state.orders))
        );
        this.showNotification(
          "top",
          "center",
          "success",
          "info-circle",
          response[0].message
        );
      },
      () => this.dialogRef.close(2),
      () => (this.loading = false)
    );
  }
}

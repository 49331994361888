import { Injectable } from "@angular/core";
import { Constants } from "@app/env";

@Injectable()
export class EndPoint {
  public baseUrl: string;
  public mageSite: string;
  public mageBaseUrl: string;
  public CDN: string;

  constructor(private constants: Constants) {
    this.CDN = constants.CDN;
    this.baseUrl = constants.API_URL;
    this.mageSite = constants.MAGE_URL;
    this.mageBaseUrl = `${this.mageSite}servicelayer/index/`;
  }

  get cdn(): string {
    return this.CDN;
  }

  get login(): string {
    return `${this.baseUrl}store_manager/login/`;
  }

  get signUp(): string {
    return this.mageBaseUrl + "createCustomer";
  }

  getOrders(): string {
    return `${this.baseUrl}store_manager/store_data/`;
  }

  fetchOrders(): string {
    return `${this.baseUrl}store_manager/fetch_order/`;
  }

  liteSaleOrder(): string {
    return `${this.baseUrl}store_manager/order_status/`;
  }

  getNewOrders(): string {
    return `${this.baseUrl}store_manager/new_orders/`;
  }

  orderLocation(): string {
    return `${this.baseUrl}live_track/ping/`;
  }

  getHistoricOrders(): string {
    return `${this.baseUrl}store_manager/order_history/`;
  }

  getOrderDetail(): string {
    return `${this.baseUrl}callcenter/sale_detail/`;
  }

  getOrderLocation(): string {
    return `${this.baseUrl}callcenter/sale_order_location/`;
  }

  getPriorityOrder(): string {
    return `${this.baseUrl}store_manager/priority_orders/`;
  }

  driverLocation(): string {
    return `${this.baseUrl}store_manager/driver_lat_lon/`;
  }

  inventoryApproval(): string {
    return `${this.baseUrl}store_manager/inv_update/`;
  }

  getCategory(): string {
    return ``;
  }

  storeEndpoint(): string {
    return this.baseUrl + "core/store/";
  }

  storeAddressEndpoint(): string {
    return this.baseUrl + "core/store_address/";
  }

  itemWeight(): string {
    return `${this.baseUrl}packing/scan/`;
  }

  callCustomer(): string {
    return ``;
  }

  addComment(): string {
    return `${this.baseUrl}callcenter/comment/sorder/`;
  }

  addAlternateNumber(): string {
    return `${this.baseUrl}callcenter/alternate_number/`;
  }

  addStoreCredit(): string {
    return `${this.baseUrl}callcenter/customer_mcredit/`;
  }

  cancelBookingRequest(): string {
    return `${this.baseUrl}driver_planning/cancel_booking/`;
  }

  bookingEditRequest(): string {
    return `${this.baseUrl}driver_planning/manage`;
  }

  addRewardPoint(): string {
    return `${this.baseUrl}callcenter/customer_reward/add/`;
  }

  storeDetailEndpoint(): string {
    return this.baseUrl + "geohash/store_details/";
  }

  storeSegmentEndpoint(): string {
    return this.baseUrl + "geohash/segment/";
  }

  drivers(): string {
    return `${this.baseUrl}store_manager/drivers/`;
  }

  everyDrivers(): string {
    return `${this.baseUrl}store_manager/all_riders/`;
  }

  trips(): string {
    return `${this.baseUrl}store_manager/trips/`;
  }

  fetchstoreDrivers(): string {
    return `${this.baseUrl}driver_earning/v2/checkin_drivers/`;
  }

  fetchStoreWeeklyDrivers(): string {
    return `${this.baseUrl}driver_earning/weekly/checkin_drivers/`;
  }

  generateWeeklyTripsheet(): string {
    return `${this.baseUrl}driver_earning/weekly/trip_sheet/`;
  }

  updateWeeklyTripsheet(): string {
    return `${this.baseUrl}driver_earning/weekly/trip_sheet`;
  }

  weeklyTripsheetApprove(): string {
    return `${this.baseUrl}driver_earning/weekly/verify_sheet/`;
  }

  fetchDriverEarnings(): string {
    return `${this.baseUrl}driver_earning/trip_earning/`;
  }

  updateTrip(): string {
    return `${this.baseUrl}store_manager/trips/update_sequence/`;
  }

  removeOrderFromTrip(): string {
    return `${this.baseUrl}store_manager/trips/remove_order/`;
  }

  optimalTrip(): string {
    return `${this.baseUrl}store_manager/routing/`;
  }

  orderCancel(): string {
    return `${this.baseUrl}callcenter/cancel/`;
  }

  tripPath(): string {
    return `${this.baseUrl}live_track/path/`;
  }

  moveToProcessing(): string {
    return `${this.baseUrl}store_manager/processing/`;
  }

  updateAddress(): string {
    return `${this.baseUrl}callcenter/update_address/`;
  }

  searchCustomer(): string {
    return `${this.baseUrl}callcenter/search_customer/`;
  }

  customerOrders(): string {
    return `${this.baseUrl}callcenter/customer_orders/`;
  }

  customerComplains(): string {
    return `${this.baseUrl}callcenter/complaints/`;
  }

  freshDeskTickets(): string {
    return `${this.baseUrl}callcenter/freshdesk_tickets/`;
  }

  customerProfile(): string {
    return `${this.baseUrl}callcenter/customer_profile/`;
  }

  unBlockDriver(): string {
    return `${this.baseUrl}driver_profile/manage/unblock`;
  }

  driverStatusHistory(): string {
    return `${this.baseUrl}driver_profile/status_history/`;
  }

  bookingRequest(): string {
    return `${this.baseUrl}driver_planning/send_booking/`;
  }

  bookingDetails(): string {
    return `${this.baseUrl}driver_planning/get_user_bookings/`;
  }

  weekendRider(): string {
    return `${this.baseUrl}store_manager/driver_user_profile`;
  }

  logoutDriver(): string {
    return `${this.baseUrl}driver_profile/manage/logout`;
  }

  freshDeskTicketsCreate(): string {
    return `${this.baseUrl}callcenter/erp_tickets/`;
  }

  flockAuth(): string {
    return `${this.baseUrl}store_manager/flock_auth/`;
  }

  inventoryRequest(): string {
    return `${this.baseUrl}store_manager/inv_request/`;
  }

  inventoryUpdate(): string {
    return `${this.baseUrl}store_manager/inv_staging_create/`;
  }

  inventoryEntry(): string {
    return `${this.baseUrl}inventory/debug/entry/`;
  }

  inventoryPurchase(): string {
    return `${this.baseUrl}inventory/debug/purchase/`;
  }

  inventorySale(): string {
    return `${this.baseUrl}inventory/debug/sale/`;
  }

  rewardPointsTransaction(): string {
    return `${this.baseUrl}callcenter/customer_reward/`;
  }

  walletTransaction(): string {
    return `${this.baseUrl}callcenter/customer_mcredit/`;
  }

  updateInvRequest(): string {
    return `${this.baseUrl}store_manager/pending_inv_request/`;
  }

  inventoryApprovals(): string {
    return `${this.baseUrl}store_manager/pending_inv_request/`;
  }

  get productStore(): string {
    // Again hack remove this when anony browsing comes into place
    // var storeId = 1;
    // if (store != undefined)
    //     storeId = store.storeId;

    return `${this.baseUrl}catalog/product/`;
  }

  get parentProducts(): string {
    return `${this.baseUrl}inventory/parent_products/`;
  }

  get storeInventory(): string {
    return `${this.baseUrl}inventory/store/`;
  }

  get inventory(): string {
    return `${this.baseUrl}inventory/v2/store`;
  }

  get productPrice(): string {
    return `${this.baseUrl}core/price/`;
  }

  get snapToRoads(): string {
    return "https://roads.googleapis.com/v1/snapToRoads";
  }

  get liveTrackOrder(): string {
    return `${this.baseUrl}live_track/ping/`;
  }

  fetchSavedTrips(): string {
    return `${this.baseUrl}store_manager/trips/saved_trips/`;
  }

  assignDriver(): string {
    return `${this.baseUrl}store_manager/trips/assign_rider/`;
  }

  holdOrder(): string {
    return `${this.baseUrl}store_manager/hold`;
  }

  closeOrder(): string {
    return `${this.baseUrl}tech_ops/close_order/`;
  }

  replaceOrder(): string {
    return `${this.baseUrl}callcenter/replace_order/`;
  }

  slotCc(): string {
    return `${this.baseUrl}sale_order/cc_slots/`;
  }

  paymentModeChange(): string {
    return `${this.baseUrl}tech_ops/change_pay_mode/`;
  }

  bookingCount(): string {
    return `${this.baseUrl}driver_planning/get_booking_allocation/`;
  }

  bookingStatus(): string {
    return `${this.baseUrl}driver_planning/get_store_bookings/`;
  }

  createDriver(): string {
    return `${this.baseUrl}store_manager/driver_user_profile/`;
  }

  get updateRiderDetails(): string {
    return `${this.baseUrl}store_manager/driver_user_profile`;
  }

  get tripCashEndPoint(): string {
    return `${this.baseUrl}store_manager/trip_cash`;
  }

  unHoldOrder(): string {
    return `${this.baseUrl}callcenter/unhold`;
  }

  convertShift(): string {
    return `${this.baseUrl}driver_planning/bookings/`;
  }

  editTrip(): string {
    return `${this.baseUrl}store_manager/trips/bulk_edit/`;
  }
  sendSms(): string {
    return `${this.baseUrl}callcenter/sms_template/`;
  }

  sendPaymentLink(): string {
    return `${this.baseUrl}payment/link_pay/`;
  }

  tripTodaysCash(): string {
    return `${this.baseUrl}store_manager/today_cash/`;
  }

  customerDnd(): string {
    return `${this.baseUrl}callcenter/dnd`;
  }

  canHold(): string {
    return `${this.baseUrl}callcenter/unhold_can_add/`;
  }

  get addCashToTrip(): string {
    return `${this.baseUrl}store_manager/trip_cash/add_change_cash/`;
  }

  searchDriverTrip(): string {
    return `${this.baseUrl}store_manager/trip_search`;
  }

  closeTrip(): string {
    return `${this.baseUrl}tech_ops/close_trip/`;
  }

  fetchProductSpec(): string {
    return `${this.baseUrl}sale_order/product_spec/`;
  }

  fetchStoreGeohash(): string {
    return `${this.baseUrl}geohash/geohash_tile/`;
  }

  driverOtp(): string {
    return `${this.baseUrl}tech_ops/get_otp/`;
  }

  autoRoutes(): string {
    return `${this.baseUrl}geohash/generate/auto_trip/`;
  }

  driverProfile(): string {
    return `${this.baseUrl}driver/driver_profile`;
  }

  driverSnapshot(): string {
    return `${this.baseUrl}driver_profile/snapshot/`;
  }

  driverLanguage(): string {
    return `${this.baseUrl}driver_profile/languages`;
  }

  completeStorePickedOrder(): string {
    return `${this.baseUrl}store_manager/complete`;
  }

  changeSlot(): string {
    return "";
  }

  suspendDriver(): string {
    return `${this.baseUrl}driver_profile/manage/block`;
  }

  tripEarningUpdate(): string {
    return `${this.baseUrl}driver_earning/trip_earning`;
  }

  fetchKmSnapshot(): string {
    return `${this.baseUrl}driver_earning/km_snapshot/`;
  }

  tripSheetUpdate(): string {
    return `${this.baseUrl}driver_earning/trip_sheet`;
  }

  tripSheetGenerate(): string {
    return `${this.baseUrl}driver_earning/trip_sheet/`;
  }

  tripSheetApprove(): string {
    return `${this.baseUrl}driver_earning/manage/trip_sheet/verify/`;
  }

  tripSheetPenalty(): string {
    return `${this.baseUrl}driver_earning/manage/trip_sheet/apply_penalty/`;
  }

  tripSheetPay(): string {
    return `${this.baseUrl}driver_earning/make_payment/`;
  }

  tripSheetPhoneNumber(): string {
    return `${this.baseUrl}otp/generate/`;
  }

  moveToHold(): string {
    // TO-DO
    return `${this.baseUrl}store_manager/`;
  }

  eliteSubscriptionEndpoint(): string {
    return `${this.baseUrl}customer_vip/status/`;
  }

  eliteSubscriptionHistoryEndpoint(): string {
    return `${this.baseUrl}customer_vip/history/`;
  }

  barcodeSpecEndpoint(): string {
    return `${this.baseUrl}packing/order/barcode/`;
  }

  driverAttendance(): string {
    return `${this.baseUrl}store_manager/driver/login_records/`;
  }

  fetchSlabs(): string {
    return `${this.baseUrl}driver_earning/slab/`;
  }

  ordersByDate(): string {
    return `${this.baseUrl}geohash/debug/store_orders/`;
  }

  fetchInventoryLog(): string {
    return `${this.baseUrl}inventory/debug/logs/`;
  }

  fetchViolationTickets(): string {
    return `${this.baseUrl}live_track/driver_violations/`;
  }

  changeAddress(): string {
    return `${this.baseUrl}live_track/driver_violations`;
  }

  fetchTripConfig(): string {
    return `${this.baseUrl}/driver/order_per_trip/`;
  }

  fetchCityList(): string {
    return `${this.baseUrl}core/city`;
  }

  liveTrackButcher(): string {
    return `${this.baseUrl}live_track/available_butcher/`;
  }

  liveTrackDriver(): string {
    return `${this.baseUrl}live_track/v2/driver_detail/`;
  }

  addressNoChange(): string {
    return ``;
  }

  fetchHyperTrackOrderDetail(): string {
    return `${this.baseUrl}live_track/hyper_track/order/`;
  }

  fetchHyperTrackTripDetail(): string {
    return `${this.baseUrl}live_track/hyper_track/trip/`;
  }

  editDummyTrip(): string {
    return `${this.baseUrl}store_manager/trips/update_tag/`;
  }

  addDeliveryInstructions(): string {
    return `${this.baseUrl}callcenter/delivery_instruction/`;
  }

  fetchTripsHistory(): string {
    return `${this.baseUrl}live_track/slot/trip_path`;
  }

  markAsNotDelivered(): string {
    return `${this.baseUrl}callcenter/complete_not_delivered`;
  }

  enableCOD(): string {
    return `${this.baseUrl}callcenter_hold/customer/enable_cod/`;
  }

  orderHistory(): string {
    return `${this.baseUrl}store_manager/fetch_all_orders/`;
  }
}

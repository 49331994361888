<mat-drawer-container class="content" id="mat-content">
  <!-- Map drawer for trip planning-->
  <mat-drawer
    class="right-drawer"
    mode="over"
    id="right-drawer"
    position="end"
    disableClose="true"
    #rightDrawer
  >
    <app-plan-trip-dialog
      *ngIf="planTrip"
      [editMode]="planTrip?.editMode"
      [selectedTrip]="planTrip?.selectedTrip"
      [sequenceChangeTrip]="planTrip?.sequenceChangeTrip"
      [isSequencedMode]="planTrip?.isSequencedMode"
      [orders]="planTrip?.dataSource"
      [segments]="planTrip?.segments"
    ></app-plan-trip-dialog>
  </mat-drawer>

  <mat-drawer-content class="wrapper" cdk-scrollable>
    <responsive-breakpoints></responsive-breakpoints>
    <top-navbar-content class="main-navbar-container"></top-navbar-content>

    <div class="main-content-container">
      <mat-sidenav-container #drawerContainer class="">
        <mat-sidenav #sideMenu mode="side" opened="true" class="main-sidenav">
          <side-menu-content
            (autoClose)="collapseSidebar()"
          ></side-menu-content>
        </mat-sidenav>
        <breadcrumb class="bread-crumb"></breadcrumb>
        <div class="container-fluid main-content-outer">
          <router-outlet></router-outlet>
        </div>

        <mat-sidenav
          #sideNotifications
          mode="over"
          opened="false"
          position="end"
          class="notificaitons-sidenav"
        >
          <notifications-menu-content
            [notifications]="notifications"
          ></notifications-menu-content>
        </mat-sidenav>
      </mat-sidenav-container>
    </div>
  </mat-drawer-content>
</mat-drawer-container>

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BasePostProvider } from "@tendercuts/http";
import { GenerateWeeklyTripSheetResponse } from "@tendercuts/models";

@Injectable({
  providedIn: "root",
})
export class WeeklyTripSheetUpdateService extends BasePostProvider<
GenerateWeeklyTripSheetResponse
> {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof GenerateWeeklyTripSheetResponse {
    return GenerateWeeklyTripSheetResponse;
  }

  get endpoint(): string {
    return this.endpointService.weeklyTripsheetApprove();
  }

  get isCachable(): boolean {
    return false;
  }

  getRejectRequest(
    sheetId: number,
    comment: string,
  ): {
    sheet_id: number;
    action: string;
    comment: string;
  } {
    const params: {
      sheet_id: number;
      action: string;
      comment: string;
    } = {
      sheet_id: sheetId,
      action: "reject",
      comment,
    };

    return params;
  }

  /**
   * params to pass trip sheet approve
   * @param status
   * @param id
   * @param requestMbg
   * @param requestIncentive
   */
  getApproveRequest(
    id: number,
    amount: number,
    comment: string,
  ): {
    sheet_id: number;
    action: string;
    amount: number;
    comment: string;
  } {
    const params: {
      sheet_id: number;
      action: string;
      amount: number;
      comment: string;
    } = {
      sheet_id: id,
      action: "approve",
      amount,
      comment,
    };

    return params;
  }
}

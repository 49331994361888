import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { OrderState } from "@store/orders";
import { AppState } from "@store/state";
import {
  BarcodeSpec,
  GenericServerResponse,
  Product,
  SaleOrder,
  ScanItem,
  Trips,
  User,
} from "@tendercuts/models";
import {
  FetchProductSpecService,
  ItemWeightService,
} from "@tendercuts/providers";
import { Subscription } from "rxjs";
import { filter } from "rxjs-compat/operator/filter";
import { map, skipWhile, take } from "rxjs/operators";
import { BarcodeParser } from "tendercuts/barcode";
import { BarcodeValidator } from "../../validators/barcode";
import { BasePage, ModalComponent } from "../utils";

@Component({
  selector: "app-barcode-scan",
  templateUrl: "./barcode-scan.component.html",
  styleUrls: ["./barcode-scan.component.scss"],
})
export class ScanDashboardComponent
  extends BasePage
  implements OnInit, AfterViewInit, OnDestroy {
  searchText: string = "";
  order: SaleOrder;
  tripOrders: SaleOrder[] = [];
  manualBarcodes: {} = {};
  responseMessage: string;
  barcodeText: string = "";
  // Initialize an scanitem model
  scanItems: ScanItem[] = [];
  CANCELBARCODE: string = "111111";
  COMPLETESCANBARCODE: string = "222222";
  orderPacks: number | any;
  barcodeTimeoutListener: any;
  routerEventSubcriber: Subscription;
  isTechOps: boolean = false;
  products: Product[];
  trip: Trips;
  tripId: string = "";
  isPartiallyScanned: boolean = false;
  isStorePickupTrip: boolean = false;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public itemWeightService: ItemWeightService,
    public fetchProductSpecService: FetchProductSpecService,
    public renderer: Renderer2,
    public changeDetectorRef: ChangeDetectorRef,
    private title: Title,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super(dialog, snackBar, store);
  }

  /**
   * listen the route params change if the there is any change in the route
   * parameter it will fetch the data according to the activated route value
   */
  ngOnInit(): void {
    this.title.setTitle("Barcode scan");
    this.routerEventSubcriber = this.router.events.subscribe((data) => {
      if (data instanceof NavigationEnd) {
        this.presentLoader();
        setTimeout(() => {
          this.dismissLoader();
          this.fetchQueryParams();
        }, 300);

        return;
      }
    });
    this.setup();
  }

  /* Refresh orders and trips data before fetching the sale order data */
  async setup(): Promise<void> {
    await this.refreshOrdersAndTrips();
    await this.fetchQueryParams();
    this.isStorePickupTrip = this.trip && this.trip.isStorePickupTrip;
    this.getTechOps();
    this.fetchCatalogState();
  }

  fetchQueryParams(): void {
    const routeParam: string = this.route.snapshot.params["order_id"];
    const tripId: string = this.route.snapshot.params["trip_id"];
    if (tripId || (routeParam && Number.isInteger(+routeParam))) {
      this.tripId = tripId ? tripId : routeParam;
      this.searchText = tripId ? routeParam : "";
      this.getTripOrders();
    } else if (routeParam) {
      this.searchText = routeParam;
      this.getOrder();
    }
  }

  ngAfterViewInit(): void {
    this.refreshStores();
    this.setFocus("#scan-order-ip");
  }

  /** Fn to fetch catalog state from redux */
  async fetchCatalogState(): Promise<void> {
    const catalogProduct: any = await this.catalogState
      .pipe(
        skipWhile((catalogState) => catalogState.loading),
        take(1),
        map((catalogState) => catalogState.products)
      )
      .toPromise();

    if (catalogProduct) {
      this.products = catalogProduct;
    }
  }

  /* Fn to check whether the user is techOps user */
  async getTechOps(): Promise<void> {
    this.isTechOps = await this.store
      .select((state) => state.userState.user)
      .pipe(
        skipWhile((user) => !user),
        take(1),
        map((user: User) => user.isTechOps)
      )
      .toPromise();
  }

  /**
   * Fn to fetch sale order details
   * @param orderNumber
   */
  async fetchOrder(orderNumber: string): Promise<SaleOrder> {
    const order: Promise<SaleOrder> = this.store
      .select((state) => state.orderState)
      .pipe(
        take(1),
        map((orderState: OrderState) => orderState.orderMap[orderNumber])
      )
      .toPromise();

    return order;
  }

  /**
   * 1.orderId is null showing the orderId not found message
   * 2.takeing the based on the the giving orderId from orderState
   * 3.if order not found showing order not found message and return
   * 4.take length of the items in this order into itemsLength
   * 5 set the progress bar length and focus on the element
   */
  async getOrder(): Promise<void> {
    if (this.searchText == "") {
      this.raiseTextAlertAndResetOrder(
        "Order Id Not Found",
        "Please enter order id"
      );

      return;
    }
    this.order = null;
    const order: SaleOrder = await this.fetchOrder(this.searchText);

    if (!order) {
      this.raiseTextAlertAndResetOrder(
        "Order Not Found",
        "Please check the order id"
      );

      return;
    }

    if (
      (order.isPickup && !order.isProcessing) ||
      (!order.isPickup && order.customStatus != "dummy_assigned")
    ) {
      this.raiseTextAlertAndResetOrder(
        "Order should in assigned to a dummy/saved trip before scanning",
        "Ths order #" +
        order.incrementId +
        " is in " +
        order.customStatus +
        " state"
      );

      return;
    }

    this.order = order;
    this.router.navigate(["/dashboard/scan/" + this.searchText]);
    this.setFocus("#scan-item-ip");
    this.getProductsSpec();
  }

  // returns the total ordered products count
  get orderPacksCount(): number {
    this.orderPacks = 0;
    this.scanItems.map((item: ScanItem) => {
      this.orderPacks += +item.noOfTimesToBeScanned;
    });

    return Math.floor(this.orderPacks);
  }

  /**
   * Fetches products spec of the order
   * [Called only in order assigned]
   */
  async getProductsSpec(): Promise<void> {
    const params: {
      increment_id: string;
    } = await this.fetchProductSpecService.getParams(this.order.incrementId);
    const itemSpecs: ScanItem[] = await this.fetchProductSpecService
      .getData(params)
      .toPromise();
    if (!itemSpecs && !itemSpecs.length) {
      this.raiseTextAlertAndResetOrder(
        "Order specification Not Found",
        "Please check"
      );

      return;
    }
    this.scanItems = [];
    // not using maps here since no of products will only be a few.
    this.order.items.forEach((item) => {
      const spec: ScanItem = itemSpecs.find(
        (itemSpecf) => itemSpecf.productId === item.productId
      );
      this.scanItems.push(
        new ScanItem().deserialize({
          sale_item: item,
          product_spec: spec,
        })
      );
    });
  }

  /**
   * set the mouse pointer on the item
   * @param itemToFocus
   */
  setFocus(itemToFocus: string): void {
    this.changeDetectorRef.detectChanges();
    const element: any = this.renderer.selectRootElement(itemToFocus);
    setTimeout(() => element.focus(), 300);
  }

  /**
   * Clears out the barcode field if there is a gap
   * between barcode keystrokes for more than 20ms
   */
  monitorBarcodeInput(): void {
    if (this.isTechOps) {
      return;
    }
    if (this.barcodeTimeoutListener) {
      clearTimeout(this.barcodeTimeoutListener);
    }
    this.barcodeTimeoutListener = setTimeout(() => (this.barcodeText = ""), 20);
  }

  /**
   * @func processBarcode
   * If no barcode is detected then no barcode detected popover is invoked
   * If barcode is valid, then the weight updation takes place
   * If barcode is invalid, Invalid barcode popup is invoked
   */
  processBarcode(): void {
    this.isPartiallyScanned = true;
    this.barcodeText = this.barcodeText.toString();
    if (this.barcodeText == this.COMPLETESCANBARCODE) {
      if (!this.checkIfAllItemsAreScanned()) {
        this.raiseTextAlertAndResetBarcode(
          "Items are not scanned",
          "Please check"
        );

        return;
      }
      this.sendOrderWeights();

      return;
    }

    if (this.barcodeText == this.CANCELBARCODE) {
      this.resetOrder();

      return;
    }

    const barcodeValid: any = BarcodeValidator.validateBarcode(
      this.barcodeText
    );

    if (!barcodeValid.status) {
      this.raiseTextAlertAndResetBarcode(
        barcodeValid.message[0],
        barcodeValid.message[1]
      );

      return;
    }
    if (this.barcodeText.length === 13 || this.barcodeText.length === 12) {
      this.validatePackItem(Number(this.barcodeText));

      return;
    }
    const weightRes: any = BarcodeValidator.validateItem(
      this.barcodeText,
      this.scanItems
    );

    if (!weightRes.status) {
      this.raiseTextAlertAndResetBarcode(
        weightRes.message[0],
        weightRes.message[1]
      );

      return;
    }
    this.addWeightToItem(weightRes.item, weightRes.weight);
  }

  /**
   *
   * @param barcode
   * Products may have multiple barcodes with delimenator ';'
   * Need to split and match with the barcode entered.
   * @returns the product that match the barcode
   */
  fetchProductWRTBarcode(barcode: number): Product {
    return this.products.find((product: Product) => {
      if (!product.barcode) {
        return false;
      }
      const barcodeString: string[] = product.barcode.split(":");
      const productBarcodes: number[] = barcodeString.map((data: string) =>
        Number(data)
      );

      return productBarcodes.indexOf(Number(barcode)) != -1;
    });
  }

  /** Fn to validate 12 or 13 digit barcode text
   * compares entered barcode with the products barcode,
   * checks product id with the scanned items product id,
   * if !product, !item and for already scanned items we throw error message
   */
  async validatePackItem(barcode: number): Promise<void> {
    let productData: Product;
    let item: ScanItem;
    productData = await this.fetchProductWRTBarcode(barcode);

    if (productData) {
      item = this.scanItems.find((data) => data.productId == productData.id);
    }
    if (!item) {
      this.raiseTextAlertAndResetBarcode(
        "Invalid barcode",
        "Please check the barcode"
      );

      return;
    }

    const response: any = BarcodeValidator.isAleardyScannedItem(item);
    if (!response.status) {
      this.raiseTextAlertAndResetBarcode(
        response.message[0],
        response.message[1]
      );

      return;
    }

    item.addBarcode(barcode.toString());
    item.weight = 1;
    item.noOfTimesScanned++;
    this.resetItem();
  }

  /**
   * @func checkIfAllItemsAreScanned
   * Checking if the driver has scanned all the items for the order
   */
  checkIfAllItemsAreScanned(): boolean {
    return this.scanItems.every((item: ScanItem) => item.isScanned);
  }
  /**
   * passing title and subtitle to textAlert function
   * Invalid barcode
   * @param title
   * @param subtitle
   */
  alertMessage(
    title: string,
    subtitle: string
  ): MatDialogRef<ModalComponent, any> {
    return this.textAlert(title, subtitle);
  }
  raiseTextAlertAndResetBarcode(title: string, subtitle: string): void {
    const alertRef: MatDialogRef<ModalComponent, any> = this.alertMessage(
      title,
      subtitle
    );
    alertRef.afterClosed().subscribe(() => {
      this.resetItem();
    });
  }

  /**
   * passing title and subtitle to textAlert function
   * Invalid orderId
   * @param title
   * @param subtitle
   */
  raiseTextAlertAndResetOrder(title: string, subtitle: string): void {
    const alertRef: MatDialogRef<ModalComponent, any> = this.alertMessage(
      title,
      subtitle
    );
    alertRef.afterClosed().subscribe(() => {
      this.resetOrder();
    });
  }

  /**
   * push the each item of serializeDriver details into a assignedItems array
   * hit the itemweightservice and passed orderid and assignedItems
   * if respose true responseMessage text update.
   */
  sendOrderWeights(): void {
    if (this.order.isScanDone) {
      this.alertMessage("Order scanned already", "");

      return;
    }
    this.isPartiallyScanned = false;
    const assignedItems: {
      item_id: number;
      barcodes: string[];
    }[] = this.scanItems.map((item: ScanItem) => item.serialize());

    this.itemWeightService
      .getData(
        this.itemWeightService.getParams(this.order.incrementId, assignedItems)
      )
      .subscribe(
        (response: GenericServerResponse) => {
          if (response) {
            this.raiseTextAlertAndResetOrder(response[0].message, "");
            this.resetScannedItems();
          }
        },
        (err) => this.textAlert("Please take a fresh new barcode and scan", "")
      );
  }

  /**
   * if validateDate is false, just returned alert,
   * if item is already scanned response false return item already scanned
   * else
   * increment item weight
   * increment item noOfTimesScanned
   * reset the barcode text null
   * increment the progress bar length
   * @param item
   * @param weight
   */
  addWeightToItem(item: ScanItem, weight: any): void {
    const barcodeSpec: BarcodeSpec = BarcodeParser.parse(this.barcodeText);
    // When an already scanned item is scanned again
    const response: any = BarcodeValidator.isAleardyScannedItem(item);
    if (!response.status) {
      this.raiseTextAlertAndResetBarcode(
        response.message[0],
        response.message[1]
      );

      return;
    }

    item.addBarcode(this.barcodeText);
    item.weight += barcodeSpec.weight;
    item.noOfTimesScanned++;
    this.resetItem();
  }

  // reset the scanned items
  resetScannedItems(): void {
    this.scanItems.forEach((item: ScanItem) => item.clearWeights());
    this.barcodeText = "";
  }

  // reseting the whole page
  async resetOrder(): Promise<void> {
    this.scanItems.forEach((item: ScanItem) => item.clearWeights());
    if (this.tripOrders.length) {
      await this.refreshOrdersAndTrips();
      this.getTripOrders();
    } else {
      this.clearScanDashboard();
    }
  }

  changeToInteger(value: number): number {
    return Math.floor(value);
  }

  changeArrayType(n: number): any[] {
    return Array(n);
  }

  // reseting the barcode text
  resetItem(): void {
    this.barcodeText = "";
    this.setFocus("#scan-item-ip");
  }

  async fetchTrip(): Promise<Trips> {
    const trip: Promise<Trips> = this.driverState
      .pipe(
        take(1),
        map((driveState) => {
          return driveState.trips.filter(
            (driveStateTrip) => driveStateTrip.id.toString() == this.tripId
          )[0];
        })
      )
      .toPromise();

    return trip;
  }

  /* Fn to fetch sale order detail for all orders in a trip */
  async fetchTripOrders(): Promise<void> {
    this.tripOrders = [];
    const tripOrderIds: any = this.trip.orders;
    for (const orderId of tripOrderIds) {
      const order: SaleOrder = await this.fetchOrder(orderId);
      if (order) {
        this.tripOrders.push(order);
      }
    }
    this.tripOrders.sort((a: SaleOrder, b: SaleOrder) =>
      a.isScanDone === b.isScanDone ? 0 : a.isScanDone ? 1 : -1
    );

    return;
  }

  clearScanDashboard = (): void => {
    this.order = null;
    this.scanItems = [];
    this.tripOrders = [];
    this.tripId = "";
    this.searchText = "";
    this.barcodeText = "";
    this.router.navigate(["/dashboard/scan"]);
    this.setFocus("#scan-order-ip");
  }

  /**
   * 1. Get trip details and then get sale order details for all orders in that trip.
   * 2. If there are orders in the trip and an orderNumber is given
   *      - and the order is unscanned navigate to that order
   *      - and the order is scanned navigatte to next unscanned order
   * 3. If there are no trips or orders found alert the user.
   *      - if it is swiggy trip display 'All orders scanned' alert after final scan.
   */
  async getTripOrders(): Promise<void> {
    this.trip = await this.fetchTrip();
    if (!this.trip) {
      this.clearScanDashboard();
      this.isStorePickupTrip ?
        this.alertMessage("All Orders are scanned", "") :
        this.alertMessage("Trip Not Found", "Please check the trip id");
      this.isStorePickupTrip = false;

      return;
    }
    if (this.trip.state != "Saved Trips") {
      this.clearScanDashboard();
      this.alertMessage(
        "Trip should not be assigned to a rider before scanning orders",
        "This trip #" + this.trip.id + " is in assigned state"
      );

      return;
    }

    await this.fetchTripOrders();
    if (!this.tripOrders.length) {
      this.clearScanDashboard();
      this.alertMessage("No orders to scan", "");

      return;
    }
    if (this.tripOrders.length && this.trip.status === 0) {
      const unScannedTripOrders: SaleOrder[] = this.tripOrders.filter(
        (order) => !order.isScanDone
      );
      const selectedOrder: SaleOrder = unScannedTripOrders.find(
        (order: SaleOrder) => order.incrementId == this.searchText
      );
      this.order = selectedOrder ? selectedOrder : unScannedTripOrders[0];
    }
    if (!this.order) {
      this.clearScanDashboard();
      this.alertMessage("All orders are scanned", "");

      return;
    }
    this.searchText = this.order.incrementId;
    this.setOrderToScan(this.searchText);
    this.getProductsSpec();

    return;
  }

  /**
   * Check if the search text given is a trip id or order number
   * And load the dashboard accordingly
   */
  loadScanDashboard(): void {
    if (this.searchText === "" || !Number.isInteger(+this.searchText)) {
      this.getOrder();
    } else {
      this.tripId = this.searchText;
      this.searchText = "";
      this.getTripOrders();
    }
  }

  /**
   * Alert user when barcode is scanned for an order
   * and then the user tries to change order before completing scan
   * @param orderNumber
   */
  alertOrderPartiallyScanned = (orderNumber: string) => {
    const onSuccess: (result: any) => void = (result) => {
      if (result) {
        this.isPartiallyScanned = false;
        this.setOrderToScan(orderNumber);

        return;
      }
    };
    this.optionsAlert("Are you sure ?", "You want to change order?", onSuccess);
  }

  async setOrderToScan(orderNumber: string): Promise<void> {
    if (this.isPartiallyScanned) {
      this.alertOrderPartiallyScanned(orderNumber);

      return;
    }
    this.router.navigate([`/dashboard/scan/${this.tripId}/${orderNumber}`]);
  }

  ngOnDestroy(): void {
    if (this.routerEventSubcriber) {
      this.routerEventSubcriber.unsubscribe();
    }
  }
}

import {SaleOrder} from "@tendercuts/models";
import { RatingTag } from "tendercuts/models/sale-order/rating-tag";
// TODO
// eslint-disable-next-line @typescript-eslint/naming-convention
export interface OrderState {
  orders: SaleOrder[];
  newOrders: SaleOrder[];
  orderMap: any;
  loading: boolean;
  error: boolean;
  errorMessage: string;
  lastOrderTime: string;
  priorityOrdertags: {[orderId: string]: string[]};
}

export const orderInitialState: OrderState = {
  orders: [],
  newOrders: [],
  orderMap: {},
  loading: false,
  error: false,
  errorMessage: null,
  lastOrderTime: null,
  priorityOrdertags: {},
};

/**
 * @param orderIds - default 1,2,3,4,5
 * orderState.order and orderState.orderMap
 * are populated
 * @return  orderState for the given orders
 */
export function mockOrderSate(
  orderIds: string[] = ['1', '2', '3', '4', '5'],
): OrderState {
  const orderState: OrderState = orderInitialState;
  const saleOrderMap: {} = {};
  const saleOrders: SaleOrder[] = orderIds.map((id) => {
      const saleOrder: SaleOrder = new SaleOrder().deserialize({ increment_id: id });
      saleOrderMap[id] = saleOrder;

      return saleOrder;
  });
  orderState.orders = saleOrders;
  orderState.orderMap = saleOrderMap;

  return orderState;
}

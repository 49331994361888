import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BasePostProvider } from "@tendercuts/http";
import { Trips } from "@tendercuts/models";

@Injectable()
export class FetchSavedTripsService extends BasePostProvider<Trips> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof Trips {
    return Trips;
  }

  get endpoint(): string {
    return this.endpointService.fetchSavedTrips();
  }

  getParams(
    storeId: number,
  ): {
    store_id: number;
  } {
    const params: {
      store_id: number;
    } = { store_id: storeId };

    return params;
  }
}

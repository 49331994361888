import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-delivery-instructions",
  templateUrl: "./delivery-instructions.component.html",
  styleUrls: ["./delivery-instructions.component.scss"],
})
export class DeliveryInstructionsComponent {
  availableDeliveryInstructions: string[] = [
    "Change request",
    "Keep the parcel outside( contact less delivery)",
    "Address change nearby",
    "Flat number change",
    "Before starting ask the rider to call",
    "Not to knock on the doorbell",
  ];
  deliveryInstructions: string;

  constructor(public dialogRef: MatDialogRef<DeliveryInstructionsComponent>) {}

  sendDeliveryInstructions(): void {
    this.dialogRef.close(this.deliveryInstructions);
  }
}

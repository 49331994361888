import { Serializable } from "../base";

export class CashEntryTransaction
  implements Serializable<CashEntryTransaction> {
  public id: number;
  public amount: number;
  public createdAt: string;
  public cashEntryTransaction: number;

  constructor() {}

  deserialize(input) {
    this.id = input.id;
    this.amount = input.amount;
    this.createdAt = input.created_at;
    this.cashEntryTransaction = input.cash_entry;
    return this;
  }
}

export class TripCashEntries implements Serializable<TripCashEntries> {
  public cashEntries = [];
  private id: number;
  private created_at: string;
  private amount: number;
  private driver_no: number;
  private driver_name: string;
  private actual_amount: number;
  private submitted_amount: number;
  public remaining_amount: number;
  private trip: number;

  get cashId() {
    return this.id;
  }

  get pendingAmount() {
    return this.amount;
  }

  get createdTime() {
    return this.created_at;
  }

  get collectedCash() {
    return this.cashEntries;
  }  
  get driverName() {
    return this.driver_name;
  }

  get driverNumber() {
    return this.driver_no;
  }

  get actualAmount() {
    return this.actual_amount;
  }

  get submittedAmount() {
    return this.submitted_amount;
  }

  get remainingAmount() {
    return this.actualAmount - this.submittedAmount;
  }

  get tripId() {
    return this.trip;
  }

  deserialize(input) {
    this.id = input.id;
    this.created_at = input.created_at;
    this.amount = input.amount;

    for (let cashEntryTransaction of input.cash_entries) {
      this.cashEntries.push(
        new CashEntryTransaction().deserialize(cashEntryTransaction)
      );
    }

    this.driver_no = input.driver_no;
    this.driver_name = input.driver_name;
    this.actual_amount = input.actual_amount;
    this.submitted_amount = input.submitted_amount;
    this.remaining_amount = input.remaining_amount;
    this.trip = input.trip;
    return this;
  }
}

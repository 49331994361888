import { Component, Input, OnInit } from "@angular/core";
import { CustomerEliteSubscription } from "@tendercuts/models";

/**
 * component to show Subscription details
 */
@Component({
  selector: "app-customer-elite-status",
  templateUrl: "./customer-elite-status.component.html",
  styleUrls: ["./customer-elite-status.component.scss"],
})
export class CustomerEliteStatusComponent {
  // selector input from customer profile component
  @Input() eliteSubscription: CustomerEliteSubscription;

  constructor() {}
}

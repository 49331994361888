import { Action } from "@ngrx/store";
import {
  GenericError,
  LiteSaleOrder,
  PriorityOrder,
  SaleOrder,
  StoreSegment,
} from "@tendercuts/models";

/**
 * Action to trigger server request to load all orders
 */
export class ActionGetOrders implements Action {
  readonly type: string = "ACTION_GET_ORDERS";

  constructor(public storeId: string) {}
}

/**
 * Sets the loading true and fetchs current order status
 */
export class ActionRefreshOrders implements Action {
  readonly type: string = "ACTION_REFRESH_ORDERS";
  constructor(public storeId: string) {}
}

/**
 * Update the order status and remove the completed/canceld
 * orders from redux store
 */
export class ActionUpdateActiveOrders implements Action {
  readonly type: string = "ACTION_UPDATE_ACTIVE_ORDERS";
  constructor(
    public removeOrders: string[],
    public liteSaleOrder: LiteSaleOrder[],
    public storeId: string,
    public newOrders: string[],
  ) {}
}

/** Set the live sale order in local storage */
export class ActionSaveOrders implements Action {
  readonly type: string = "ACTION_SAVE_ORDERS_IN_LOCAL_STORAGE";

  constructor(public orders: SaleOrder[]) {}
}

/** Fetch the new orders from the server */
export class ActionFetchNewOrders implements Action {
  readonly type: string = "ACTION_FETCH_NEW_ORDERS";

  constructor(public storeId: string, public newOrders: string[]) {}
}

/** Clear local storage live orders */
export class ActionClearOrders implements Action {
  readonly type: string = "ACTION_LOCAL_LIVE_ORDERS_CLEAR";
  constructor() {}
}

export class ActionGetPriorityOrders implements Action {
  readonly type: string = "ACTION_GET_PRIORITY_ORDERS";

  constructor(public storeId: any) {}
}

export class ActionPriorityOrdersLoaded implements Action {
  readonly type: string = "ACTION_PRIORITY_ORDERS_LOADED";

  constructor(public orders: PriorityOrder[]) {}
}

export class ActionLoadSuccess implements Action {
  readonly type: string = "ACTION_LOAD_SUCCESS";

  constructor() {}
}

/**
 * Action to indicate that all orders are loaded.
 */
export class ActionAllOrdersLoaded implements Action {
  readonly type: string = "ACTION_ALL_ORDERS_LOADED";

  constructor(public payload: SaleOrder[]) {}
}

/**
 * Error action
 */
export class ActionOrderError implements Action {
  readonly type: string = "ACTION_ORDER_ERROR";

  constructor(public payload: GenericError) {}
}

/**
 * Clear orders
 */
export class ActionOrderStoreClear implements Action {
  readonly type: string = "ACTION_ORDER_STORE_CLEAR";
  constructor() {}
}

/**
 * Check if new order are available.
 */
export class ActionCheckNewOrders implements Action {
  readonly type: string = "ACTION_CHECK_NEW_ORDERS";

  constructor(public storeId: number, public lastOrderTime: any) {}
}

/**
 * Delta orders loaded
 */
export class ActionSetNewOrders implements Action {
  readonly type: string = "ACTION_SET_NEW_ORDERS";

  constructor(public newOrders: SaleOrder[]) {}
}

/**
 * Sticth segments into sale order
 */
export class ActionStitchSegment implements Action {
  readonly type: string = "ACTION_STITCH_SEGMENT";

  constructor(public segments: StoreSegment[]) {}
}

<div class="butcher" *ngIf="butcher && butcher.url">
  <div class="row">
    <div class="butcher__photo">
      <img alt="Meat Expert" class="butcher__photo__img" src="{{ butcher.url }}" />
    </div>
    <div class="butcher__details">
      <div class="butcher__name">
        {{ butcher.butcherName }}
      </div>
      <div class="butcher__expertise">
        Expertise:
        <span *ngFor="let skill of butcher.skills; let last = last">{{ skill }}<span *ngIf="!last"> | </span></span>
      </div>
      <div *ngIf="order?.processingHistory" class="butcher__time-of-cut">
        Time Of Cut :
        {{
        order?.processingHistory.createdAt | date: "shortTime"
        }}
      </div>
    </div>
  </div>
</div>

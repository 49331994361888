import { Injectable } from "@angular/core";
import { BaseDataTransformer, DataProcessingProtocol } from "@tendercuts/http";
import { Category } from "@tendercuts/models";
import { forkJoin, Observable } from "rxjs";
import { ProductDataService } from "./data";
import { InventoryService } from "./inventory";
import { ProductPriceService } from "./product-price";

import { Inventory } from "@tendercuts/models";
import { share } from "rxjs/operators";

@Injectable()
export class CatalogService
  extends BaseDataTransformer<Category>
  implements DataProcessingProtocol {
  constructor(
    public productDataService: ProductDataService,
    public inventoryService: InventoryService,
    public productPriceService: ProductPriceService,
  ) {
    super();
    // this.delegate = this;
    this.processingDelegate = this;
  }

  get model(): typeof Category {
    return Category;
  }

  get endpoint(): string {
    return "";
  }

  get authProtocol(): any {
    return null;
  }

  processResponse(response: any): Array<any> {
    // response can be an array of two arrays but we wont know which one
    // is which, so we get the category index
    response = [response[0], response[1], response[2]];
    let inventoryRes: any;
    let categoryRes: any;
    let priceRes: any;

    for (const res of response) {
      if (res[0] instanceof Category) {
        categoryRes = res;
      } else {
        if (res[0] instanceof Inventory) {
          inventoryRes = res;
        } else {
          priceRes = res;
        }
      }
    }

    const inventory: {} = {};
    const price: {} = {};
    // the other on is the inv
    inventoryRes.forEach((val, index) => {
      inventory[val.product] = val;
    });

    priceRes.forEach((val, index) => {
      price[val.product_id] = val;
    });

    for (const cat of categoryRes) {
      cat.populateInventoryForProducts(inventory);
      cat.populatePriceForProducts(price);
    }

    return categoryRes;
  }

  /**
   * Override the method
   */
  protected generateBaseObservable(
    urlSearchParams?: any,
  ): Observable<[any, any, any]> {
    const category: Observable<any> = this.productDataService.getData(
      urlSearchParams,
    );
    const inventory: Observable<any> = this.inventoryService.getData(
      urlSearchParams,
    );
    const productPrice: Observable<any> = this.productPriceService.getData(
      urlSearchParams,
    );

    // var mergedObservable = inventory.concat(category)//.flatMap();
    // TODO
    
    const mergedObservable: Observable<[any, any, any]> = forkJoin(
      inventory,
      category,
      productPrice,
    ).pipe(share());

    return mergedObservable;
  }

  public resetData(fullReset: boolean = false): void {
    this.inventoryService.resetData();
    if (fullReset) {
      this.productDataService.resetData();
    }
  }
}

<div class="row">
  <div class="col-sm-6 col-md-4">
    <div class="card card-alert">
      <span class="card-alert-text">
        Basic example
      </span>
      <button class="btn card-alert-button" type="button" (click)="basicAlert()">
        TRY ME
      </button>
    </div>
  </div>
  <div class="col-sm-6 col-md-4">
    <div class="card card-alert">
      <span class="card-alert-text">
        A title with a text under
      </span>
    </div>
  </div>
  <div class="col-sm-6 col-md-4">
    <div class="card card-alert">
      <span class="card-alert-text">
        A success message
      </span>
    </div>
  </div>
  <div class="col-sm-6 col-md-4">
    <div class="card card-alert">
      <span class="card-alert-text">
        A message with a function attached to the OK button
      </span>
    </div>
  </div>
  <div class="col-sm-6 col-md-4">
    <div class="card card-alert">
      <span class="card-alert-text">
        A message with functions attached to both buttons.
      </span>
      <button class="btn card-alert-button" type="button" (click)="cancelledAlert()">
        TRY ME
      </button>
    </div>
  </div>
  <div class="col-sm-6 col-md-4">
    <div class="card card-alert">
      <span class="card-alert-text">
        A message with auto close timer set to 2 seconds
      </span>
      <button class="btn card-alert-button" type="button" (click)="timedAlert()">
        TRY ME
      </button>
    </div>
  </div>
  <div class="col-sm-6 col-md-4">
    <div class="card card-alert">
      <span class="card-alert-text">
        Modal window with input field
      </span>
      <button class="btn card-alert-button" type="button" (click)="inputAlert()">
        TRY ME
      </button>
    </div>
  </div>
</div>

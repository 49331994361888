import { Injectable } from "@angular/core";

import { BaseGetProvider } from "@tendercuts/http";
import { CustomerEliteSubscription } from "@tendercuts/models";

import { HttpClient, HttpParams } from "@angular/common/http";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";

@Injectable({
  providedIn: 'root',
})

export class EliteCustomerDetailService extends BaseGetProvider<
CustomerEliteSubscription
> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get isCachable(): boolean {
    return false;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get endpoint(): string {
    return this.endpointService.eliteSubscriptionEndpoint();
  }

  get model(): typeof CustomerEliteSubscription {
    return CustomerEliteSubscription;
  }

  getParams(customerId: string): HttpParams {
    let params: HttpParams = new HttpParams();
    params = params.append("customer_id", customerId);

    return params;
  }
}

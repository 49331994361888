import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-trip-cash-splitup-dialogue",
  templateUrl: "./trip-cash-splitup-dialogue.component.html",
  styleUrls: ["./trip-cash-splitup-dialogue.component.scss"],
})
export class TripCashSplitupDialogueComponent {
  constructor(
    public dialogRef: MatDialogRef<TripCashSplitupDialogueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}
}

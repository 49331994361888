import { APP_BASE_HREF } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Constants } from "@app/env";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { User } from "@tendercuts/models";
import { Store as TcStore } from "@tendercuts/models";
import { BasePage } from "../../../utils/pages/base/base.component";
import { ResponsiveBreakpointsService } from "../../responsive-breakpoints/responsive-breakpoints.service";
import { SideMenuService } from "../../side-menu/side-menu.service";

// TODO
/* eslint-disable */
import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { map, skipWhile, take } from "rxjs/operators";
@Component({
  selector: "top-navbar-content",
  styleUrls: ["./styles/top-navbar-content.scss"],
  templateUrl: "./top-navbar-content.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class TopNavbarContentComponent
  extends BasePage
  implements OnInit, OnDestroy
{
  @Input("messages") messages: any[] = [];
  @Input("notifications") notifications: any[] = [];

  map: any[] = [];

  sideMenuVisible: boolean = true;
  baseUrl: string = "";
  user: User;

  // only if no stores passed, all stores will be displayed
  accessibleStores: TcStore[] = [];

  storesSub$: Subscription;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private sideMenuService: SideMenuService,
    private responsiveService: ResponsiveBreakpointsService,
    public store: Store<AppState>,
    private route: Router,
    public constants: Constants,
    public title: Title,
    public renderer: Renderer2,
    public changeDetectorRef: ChangeDetectorRef,
    @Inject(APP_BASE_HREF) private baseHref: string
  ) {
    super(dialog, snackBar, store);
    this.baseUrl = baseHref;
    this.keyBoardkeyListener();

    responsiveService.responsiveSubject
      // .filter(breakpoint => breakpoint.screen === 'xs-or-sm')
      .subscribe((breakpoint) => {
        if (breakpoint.active) {
          this.sideMenuService.sidenav.mode = "push";
          this.sideMenuService.sidenav.close().then(
            (val) => {
              // console.log('ok closing');
              this.sideMenuVisible = false;
            },
            (err) => {
              // console.log('error closing');
            },
            () => {
              // console.log('all closing');
            }
          );
        } else {
          this.sideMenuService.sidenav.mode = "side";
        }
      });
  }

  ngOnInit(): void {
    this.getUser();
  }

  /**
   * fetch logged user details
   * By this we can get the logged user's role
   */
  async getUser(): Promise<void> {
    this.user = await this.store
      .select((state) => state.userState.user)
      .pipe(
        skipWhile((user) => !user),
        take(1)
      )
      .toPromise();

    if (
      (this.user.isClusterManager || this.user.isSm) &&
      !this.user.isTechOps
    ) {
      this.fetchAvailableStores();

      return;
    }
  }

  /**
   * If the user is of type sm or cm,
   * we fetch total stores from  available stores list,
   */
  async fetchAvailableStores(): Promise<void> {
    const store: Observable<TcStore[]> = await this.storeState.pipe(
      skipWhile((state) => state.availableStores.length == 0),
      map((state) => state.availableStores),
      take(1)
    );

    this.storesSub$ = store.subscribe((availableStoresResponse: TcStore[]) => {
      if (availableStoresResponse) {
        this.accessibleStores = availableStoresResponse;
      }
    });
  }

  /**
   * Function to bind Page refresh changes using keys
   * 82-> keycode for r
   * 116 -> keycode for f5
   */
  keyBoardkeyListener(): void {
    document.onkeydown = (event: KeyboardEvent) => {
      if (
        event.keyCode === 116 ||
        (event.keyCode === 82 && event.ctrlKey) ||
        (event.keyCode === 82 && event.ctrlKey && event.shiftKey)
      ) {
        this.refreshOrdersAndTrips();
        event.preventDefault();
      }
    };
  }

  toggleSideMenu(): void {
    this.sideMenuService.sidenav.toggle().then(
      (val) => {
        this.sideMenuVisible = !this.sideMenuVisible;
      },
      (err) => {
        // console.log('error toggle');
      },
      () => {
        // console.log('all toggle');
      }
    );
  }

  ngOnDestroy(): void {
    if (this.storesSub$) {
      this.storesSub$.unsubscribe();
    }
  }
}

<div class="top-nav-bar-wrap">
  <div class="branding-col">
    <button
      type="button"
      (click)="toggleSideMenu()"
      class="menu-toggle"
      [ngClass]="{
        'menu-visible': sideMenuVisible,
        'menu-hidden': !sideMenuVisible
      }"
    >
      <i class="fa fa-bars"></i>
    </button>
    <div class="brand-wrapper">
      <!-- <h3 class="brand-name">TenderCuts</h3> -->
      <span class="page-title">{{ title.getTitle() }}</span>
    </div>
  </div>

  <!--<search-bar class="hidden-xs"></search-bar>-->

  <div class="actions-wrapper">
    <ng-container *ngIf="(userState | async)?.user.isSm">
      <button
        mat-raised-button
        class="d-none d-lg-block header-menu-button"
        id="orders"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['/dashboard']"
      >
        <i class="menu-item-icon fa fa-calendar-check-o"></i>
        Live Orders
      </button>
      <button
        mat-raised-button
        class="d-none d-lg-block header-menu-button"
        id="trips"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['/dashboard/live-trips']"
      >
        <i class="menu-item-icon fa fa-flash"></i>
        Live Trips
      </button>
      <button
        mat-raised-button
        class="d-none d-lg-block header-menu-button"
        id="plan"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['/dashboard/routing']"
      >
        <i class="menu-item-icon fa fa-map-pin"></i>
        Plan Trip
      </button>
      <button
        id="scan-orders"
        mat-raised-button
        class="header-menu-button"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['/dashboard/scan']"
      >
        <i class="menu-item-icon fa fa-barcode"></i>
        Scan Orders
      </button>
    </ng-container>
    <ng-container *ngIf="(userState | async)?.user.isCcAgent">
      <button
        id="order-detail"
        mat-raised-button
        class="header-menu-button"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['/dashboard/order-detail']"
      >
        <i class="menu-item-icon fa fa-map-pin"></i>
        Order Details
      </button>
      <button
        id="geohash"
        mat-raised-button
        class="header-menu-button"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['/dashboard/geohash']"
      >
        <i class="menu-item-icon fa fa-map-o"></i>
        Geohash
      </button>
      <button
        id="customer"
        mat-raised-button
        class="header-menu-button"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['/dashboard/crm']"
      >
        <i class="menu-item-icon fa fa-address-book-o"></i>
        CRM
      </button>
    </ng-container>

    <button
      *ngIf="(userState | async)?.user.isSm"
      mat-mini-fab
      (click)="refreshOrdersAndTrips()"
      class="refresh-action"
    >
      <i class="menu-item-icon fa fa-refresh" aria-hidden="true"></i>
    </button>

    <ng-container>
      <app-store-search [accessibleStores]="accessibleStores">
      </app-store-search>
    </ng-container>
  </div>
</div>
<!--Filters and search-->
<mat-progress-bar
  *ngIf="(orderState | async).loading || (driverState | async).loading"
  mode="indeterminate"
>
</mat-progress-bar>

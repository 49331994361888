import { Serializable } from "../base";

/**
 * Model for campaign winners
 */
export class CampaignWinners implements Serializable<CampaignWinners> {
  private referred_count: number;
  private campaign_id: number;
  private winner_id: number;
  public username: string;
  public rank: number;

  constructor() {}

  deserialize(input) {
    this.username = input.username;
    this.referred_count = input.referred_count;
    this.rank = input.rank;
    this.campaign_id = input.campaign_id;
    this.winner_id = input.winner_id;

    return this;
  }

  get referralCount(): number {
    return this.referred_count;
  }

  get campaignId(): number {
    return this.campaign_id;
  }

  get winnerId(): number {
    return this.winner_id;
  }
}

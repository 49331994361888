<mat-card>
  <mat-card-title> Raise Issue </mat-card-title>

  <mat-card-content>
    <mat-radio-group
      [(ngModel)]="isRadioButtonSelected"
      [formControl]="radioButtonController"
    >
      <mat-radio-button
        *ngIf="!weeklyTripSheet"
        [disabled]="tripSheet && tripSheet.mbgApplied"
        id="mbg-radioButton"
        value="request_mbg"
        >Request MBG</mat-radio-button
      >
      <mat-radio-button
        [checked]="weeklyTripSheet"
        id="incentive-radioButton"
        value="request_incentive"
        >Request Incentive</mat-radio-button
      >
    </mat-radio-group>

    <mat-form-field>
      <textarea
        matInput
        type="text"
        class="row"
        placeholder="Enter Comment"
        [formControl]="commentControl"
      ></textarea>
    </mat-form-field>

    <div class="action-btns no-gutters row">
      <button
        (click)="closeDialog()"
        class="close-btn"
        color="primary"
        mat-raised-button
      >
        close
      </button>

      <button
        [disabled]="!canActiveSubmit"
        (click)="tripSheetUpdate()"
        color="primary"
        id="submit"
        mat-raised-button
      >
        Submit
      </button>
    </div>
  </mat-card-content>
</mat-card>

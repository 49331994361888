// TODO
/* eslint-disable */
import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  PLATFORM_ID,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from "@angular/core";

import { ResponsiveBreakpointDirective } from "./responsive-breakpoint.directive";
import { ResponsiveBreakpointsService } from "./responsive-breakpoints.service";

@Component({
  selector: "responsive-breakpoints",
  templateUrl: "./responsive-breakpoints.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class ResponsiveBreakpointsComponent implements AfterViewInit {
  @ViewChildren(ResponsiveBreakpointDirective)
  breakpoints: QueryList<ResponsiveBreakpointDirective>;

  constructor(
    private responsiveService: ResponsiveBreakpointsService,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {}

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      // Client code only
      const breakpointObserverOptions: {
        threshold: number;
    } = {
        threshold: 0.9,
      };

      this.breakpoints.forEach((breakpoint) => {
        const breakpointObserver: IntersectionObserver = new IntersectionObserver(
          (entries, observer) => {
            entries.forEach((entry) => {
              if (entry.target === breakpoint.element.nativeElement) {
                const inView: boolean =
                  entry.intersectionRatio > 0.9 ? true : false;

                this.responsiveService.responsiveSubject.next({
                  screen: breakpoint.screen,
                  active: inView,
                });
              }
            });
          },
          breakpointObserverOptions,
        );

        breakpointObserver.observe(breakpoint.element.nativeElement);
      });
    } else {
      // Server code only
    }
  }
}

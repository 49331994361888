<div *ngIf="changeLogs.length; else noLogs">
  <ng-container *ngFor="let log of changeLogs">
    <div class="content">
      <div class="header">
        Note by <span *ngIf="log.userId"> {{ log.userId }} </span> -
        {{ log.date | date: "MMM d, h:mm a" }}
      </div>
      <ul class="change-data">
        <li>{{ log.fieldName }}: {{ log.oldValue }} -> {{ log.newValue }}</li>
      </ul>
      <div *ngIf="log?.comment" class="comment">Comment: {{ log.comment }}</div>
    </div>
  </ng-container>
</div>
<ng-template #noLogs>
  <div class="no-logs">No logs found</div>
</ng-template>

<div class="row symbol">
  <div class="col-4">
    <span class="legend-title">Verified Address</span>
    <i class="fa fa-check green tick"></i>
  </div>

  <div class="col-4">
    <span class="legend-title">Urgent Order < 75m</span>
    <i class="fa fa-exclamation-circle priority"></i>
  </div>

  <div class="col-4">
    <span class="legend-title">Priority Order </span>
    <i class="fa fa-product-hunt fa-2x priority"></i>
  </div>
</div>

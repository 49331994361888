<div *ngIf="trip">
  <!--Order locations-->
  <div *ngFor="let order of trip.sequencedOrders">
    <app-address-marker
      [order]="order"
      [isSelected]="clickedOrderId === order.incrementId"
      [displayWindow]="clickedOrderId === order.incrementId"
    ></app-address-marker>
  </div>

  <!--&lt;!&ndash;Driver Location: TODO move to a sep component.&ndash;&gt;-->
  <!--<agm-marker [latitude]="lastLocation.latitude"-->
  <!--[longitude]="lastLocation.longitude"-->
  <!--[label]="Driver"-->
  <!--iconUrl="assets/marker/bike.png">-->
  <!--</agm-marker>-->

  <!--<app-trip-direction [trip]="trip"></app-trip-direction>-->
</div>

import { Action } from "@ngrx/store";
import {
  Category,
  ComboSpec,
  GenericError,
  LiveTrack,
  Product,
  Store,
} from "@tendercuts/models";

/**
 * Onfailed carries information to do postmortem
 */
export class ActionLoadFailed implements Action {
  readonly type: string = "ACTION_LOAD_FAILED";

  constructor(public payload: GenericError) {}
}

/**
 * Action trigger on load success
 */
export class ActionCatalogLoadSuccess implements Action {
  readonly type: string = "ACTION_CATALOG_LOAD_SUCCESS";

  constructor(public payload: Category[]) {}
}

/**
 * Action to trigger the server request
 */
export class ActionCatalogLoad implements Action {
  readonly type: string = "ACTION_CATALOG_LOAD";

  /**
   *
   * @param Store Tendercuts store model
   */
  constructor(public storeId: string) {}
}

/**
 * Action to trigger the server request and only refresh inventory
 */
export class ActionInventoryLoad implements Action {
  readonly type: string = "ACTION_INVENTORY_LOAD";

  /**
   *
   * @param Store Tendercuts store model
   */
  constructor(public payload: Store) {}
}

/**
 * Action to trigger the search request
 */
export class ActionSearchProducts implements Action {
  readonly type: string = "ACTION_SEARCH_PRODUCTS";

  /**
   *
   * @param String search string
   */
  constructor(public payload: string) {}
}

/**
 * Action to set the filtered products into the state.
 */
export class ActionSearchComplete implements Action {
  readonly type: string = "ACTION_SEARCH_COMPLETE";

  /**
   *
   * @param String search string
   */
  constructor(public payload: Product[]) {}
}

/**
 * Action to set the filtered products into the state.
 */
export class ActionClearCatalog implements Action {
  readonly type: string = "ACTION_CLEAR_CATALOG";

  /**
   *
   * @param String search string
   */
  constructor() {}
}

/**
 * Action to set the filtered products into the state.
 */
export class ActionLoadComboLinks implements Action {
  readonly type: string = "ACTION_LOAD_COMBO_LINKS";

  /**
   *
   * @param {String} search string
   */
  constructor(public payload: { [id: number]: ComboSpec[] }) {}
}

/**
 * Action to trigger the server request
 */
export class ActionLiveTrackLoad implements Action {
  readonly type: string = "ACTION_LIVE_TRACK_LOAD";

  constructor(public storeId: string) {}
}

/**
 * Action trigger on load success
 */
export class ActionLiveTrackLoadSuccess implements Action {
  readonly type: string = "ACTION_LIVE_TRACK_LOAD_SUCCESS";

  constructor(public payload: LiveTrack[]) {}
}

/**
 * Noop action to use in case when no actions are needed to be triggered
 */
export class ActionNoop implements Action {
  readonly type: string = "ACTION_NOOP";

  constructor() {}
}

<div id="close-button">
  <h5>Are you sure you want to close the order</h5>
  <br />
  <button
    id="close-btn"
    color="primary"
    mat-raised-button
    (click)="proceedToClose()"
  >
    Yes
  </button>
  <button id="cancel" color="primary" mat-raised-button mat-dialog-close>
    No
  </button>
</div>

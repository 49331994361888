import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { DataProcessingProtocol } from "../processor";
import { BaseHttpProvider } from "./base";

export abstract class PutProvider<T> extends BaseHttpProvider<T> {
  constructor(public http: HttpClient) {
    super();
  }

  /**
   * Disable caching for all post calls
   */
  get isCachable(): boolean {
    return false;
  }

  /**
   * Generate a post observable and
   */
  protected generateBaseObservable(
    body: string,
    urlSearchParams?: HttpParams,
  ): Observable<any> {
    const endpoint: string = this.generateEndpoint(body);
    const token: any =
      this.authProtocol != null && this.authProtocol != undefined
        ? this.authProtocol.getToken()
        : null;
    let post: any;
    let userToken: any;

    // redux support
    if (token instanceof Observable) {
      token.pipe(take(1)).subscribe((user) => {
        if (user != null) {
          userToken = user.token;
        } else {
          userToken = null;
        }
        post = this.http.put(endpoint, body, {
          headers: this.getHeaders(userToken),
          params: urlSearchParams,
        });
      });
    } else {
      post = this.http.post(endpoint, body, {
        headers: this.getHeaders(token),
        params: urlSearchParams,
      });
    }

    return post;
  }
}

/**
 * Sample implementations
 */
export abstract class BasePutProvider<T>
  extends PutProvider<T>
  implements DataProcessingProtocol {
  constructor(public http: HttpClient) {
    super(http);
    this.processingDelegate = this;
  }

  processResponse(response: any): Array<any> {
    if (!(response instanceof Array)) {
      response = [response];
    }

    const datas: any[] = [];
    for (const data of response) {
      const modelObj: any = new this.model().deserialize(data);
      datas.push(modelObj);
    }

    return datas;
  }
}

export abstract class BaseEavPutProvider<T>
  extends PutProvider<T>
  implements DataProcessingProtocol {
  constructor(public http: HttpClient) {
    super(http);
    this.processingDelegate = this;
  }

  processResponse(response: any): Array<any> {
    const data: any = new this.model().eavDeserialize(response.attribute);

    return data;
  }
}

<ng-container *ngIf="storeObj.location && showMarker">
  <agm-marker
    [latitude]="parse(storeObj.location.latitude)"
    [longitude]="parse(storeObj.location.longitude)"
    [label]="formatLabel()"
    iconUrl="assets/marker/store.png"
  >
  </agm-marker>
</ng-container>

<ng-container *ngIf="storeObj.pathString && showBoundary">
  <agm-polygon [paths]="polygonPath" [clickable]="false"></agm-polygon>
</ng-container>

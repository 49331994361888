<mat-card class="booking">
  <mat-card-title class="booking__title">
    Booking Confirmation
    <span
      class="booking__driver"
      (click)="redirectToDriverCRM(bookingRequest.driverProfileId)"
      >( {{ bookingRequest.driverProfileId }} )</span
    >
  </mat-card-title>
  <mat-card-content class="booking__content">
    <form [formGroup]="bookingEditForm">
      <div class="row no-gutters">
        <h6>Change Store:</h6>
        <select class="booking__content__stores_list" formControlName="store">
          <option
            *ngFor="let store of availableBookingStores"
            [value]="store.storeId"
          >
            {{ store.storeAttribute?.displayName }}
          </option>
        </select>
      </div>
      <div class="row no-gutters booking__content__shift">
        <h6>Change Shift:</h6>
        <select class="booking__content__stores_list" formControlName="shift">
          <option *ngFor="let shift of DRIVERSHIFTS" [value]="shift.value">
            {{ shift.title }}
          </option>
        </select>
      </div>
      <div class="booking__actions no-gutters row">
        <button
          (click)="closeDialog()"
          class="close-btn"
          color="primary"
          mat-stroked-button
        >
          Close
        </button>
        <button
          (click)="editBooking()"
          id="edit-btn"
          class="booking__actions__submit"
          [disabled]="!bookingEditForm.valid"
          color="primary"
          mat-raised-button
        >
          Edit
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>

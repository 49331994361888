import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { DataTransformationProtocol } from "@tendercuts/http";
import { BaseGetProvider } from "@tendercuts/http";
import { DriverEarningSlab } from "@tendercuts/models";
import { of, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FetchDriverSlabsService
  extends BaseGetProvider<DriverEarningSlab>
  implements DataTransformationProtocol {
  constructor(
    public endPoint: EndPoint,
    public http: HttpClient,
    public loginService: LoginServices,
  ) {
    super(http);
    this.delegate = this;
  }

  preProcessResponse(response: any): Array<any> {
    return response.results;
  }
  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get isCachable(): boolean {
    return false;
  }

  get model(): typeof DriverEarningSlab {
    return DriverEarningSlab;
  }

  get endpoint(): string {
    return this.endPoint.fetchSlabs();
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BaseGetProvider } from "@tendercuts/http";
import { GenerateTripSheetResponse, TripSheet } from "@tendercuts/models";

@Injectable({
  providedIn: "root",
})
export class FetchTripSheetService extends BaseGetProvider<TripSheet> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
    this.delegate = this;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  // override this
  preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof TripSheet {
    return TripSheet;
  }

  get isCachable(): boolean {
    return false;
  }

  get endpoint(): string {
    return this.endpointService.tripSheetGenerate();
  }

  /**
   * Convert the date into "YYYY-MM-DD"
   * param selectedDate
   * param store_id
   */
  getParams(
    selectedDate: Date,
    storeId: number,
  ): {
    earning_date: string;
    store_id: number;
  } {
    const date: string = selectedDate
      .getDate()
      .toLocaleString("en-US", { minimumIntegerDigits: 2 });
    const month: string = (selectedDate.getMonth() + 1).toLocaleString(
      "en-US",
      {
        minimumIntegerDigits: 2,
      },
    );
    const formattedDate: string =
      selectedDate.getFullYear() +
      "-" +
      month.toString() +
      "-" +
      date.toString();

    const params: {
      earning_date: string;
      store_id: number;
    } = {
      earning_date: formattedDate,
      store_id: storeId,
    };

    return params;
  }

  getDriverTripSheets(
    driverId: number,
  ): {
    driver_id: number;
  } {
    const params: {
      driver_id: number;
    } = {
      driver_id: driverId,
    };

    return params;
  }
}

// TODO
/* eslint-disable */
import { Component, ViewEncapsulation } from "@angular/core";
import { AutoCompleterService } from "./services/auto-completer.service";

@Component({
  selector: "auto-completer-page",
  templateUrl: "./auto-completer.component.html",
  styleUrls: ["./styles/auto-completer.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AutoCompleterPageComponent {
  options: {
    simple: any[];
    images: any[];
  } = {
    simple: [],
    images: [],
  };

  constructor(private autoCompleterService: AutoCompleterService) {}

  filter(event: any, type: string): void {
    const input: string = event.target.value;
    if (input === "") {
      this.options[type] = [];
    } else {
      this.autoCompleterService.getData(input).then((res: any) => {
        this.options[type] = res;
      });
    }
  }
}

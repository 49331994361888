import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { GenericServerResponse, SaleOrder, User } from "@tendercuts/models";
import { FreshdeskTicketCreateService } from "@tendercuts/providers";
import { SaleOrderStatusHistory } from "tendercuts/models/sale-order/status-history";
import { BasePage } from "../../utils";

@Component({
  selector: "app-freshdesk-ticket-form",
  templateUrl: "./freshdesk-ticket-form.component.html",
  styleUrls: ["./freshdesk-ticket-form.component.scss"],
})
export class FreshdeskTicketFormComponent extends BasePage implements OnInit {
  orders: SaleOrder[] = [];
  customer: User = null;

  helpDeskFile: File;

  @ViewChild("file") document: ElementRef;

  selectedOrder: SaleOrder = null;
  showError: boolean = false;
  loading: boolean = false;
  ticketForm: FormGroup;
  freshDeskTypes: string[] = [
    "Inbound",
    "Call Outbound",
    "Progressive",
    "Internal Team",
    "Email",
    "Social Media",
    "Chat",
    "Web",
    "Phone",
    "Feedback",
    "Others",
  ];

  dispositions: string[] = [
    "Delivery__Late Delivery",
    "Delivery__Missed Product",
    "Delivery__Wrong Product",
    "Delivery__Rider Feedback",
    "Delivery__SMS received then Delivered",
    "Delivery__Broken eggs",
    /*"Delivery__Extra Time",
    "Delivery__Slot",
    "Delivery__Serviceable area",
    "Delivery__Rider call back",*/
    "Quality__Old Stock",
    "Quality__Bone Meat Ratio",
    "Quality__Hard Meat",
    "Quality__Not Cleaned",
    "Quality__Fat Full",
    "Quality__Bitter Taste",
    "Quality__Cut Issue",
    "Quality__Frozen Meat",
    "Quality__No Taste",
    "Quality__Packing issue",
    "Quality__not deveined",
    "Quantity__Weight issue",
    "Quality__Processing size issue",
    "Quality__Missing Pieces",
    /*"Feedback call__1 star",
    "Feedback call__2 star",
    "Feedback call__Dislike",
    "Technical__App issue",
    "Technical__Website issue",
    "Technical__Magento error",
    "Technical__Tech issue order",
    "Technical__OTP error"*/
  ];

  ticketStatus: string[] = ["OPEN", "CLOSED"];

  complaintStatus: string[] = [
    "Actual Complaint",
    "Customer side issue",
    "Customer assumption",
  ];
  // date for max check in date picker
  todayDate: Date = new Date();
  fileErrorMessage: string = "";

  /*
    slots = [
    "Express",
    "07:30-09:30",
    "09:30-11:30",
    "10:30-12:30",
    "11:30-13:30",
    "15:30-17:30",
    "17:30-19:30",
    "18:30-20:30",
    "19:30-21:30",
    "08:00-10:00",
    "10:00-12:00",
    "11:00-13:00",
    "12:00-02:00",
    "16:00-18:00",
    "18:00-20:00",
    "19:00-21:00",
    "20:00-22:00",
  ];
  */

  constructor(
    public formBuilder: FormBuilder,
    public createTicketService: FreshdeskTicketCreateService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<FreshdeskTicketFormComponent>,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialog, snackBar, store);
    this.customer = data.customer;
    this.orders = data.orders;
  }

  ngOnInit(): void {
    this.ticketForm = this.formBuilder.group({
      status: ["", Validators.required],
      type: ["", Validators.required],
      cf_sku: ["", Validators.required],
      subject: ["", Validators.required],
      description: ["", Validators.required],
      cf_complaint_date: [this.todayDate, Validators.required],
      cf_rca_of_complaint: ["", Validators.required],
      cf_disposition: ["", Validators.required],
      cf_slots: ["", Validators.required],
      cf_delivered_date: [""],
      cf_order: ["", Validators.required],
      cf_stores: ["", Validators.required],
      userId: ["", Validators.required],
    });
  }

  updateOrder($event: MatSelectChange): void {
    this.selectedOrder = $event.value;

    this.ticketForm.controls.userId.setValue(this.customer.userId);
    this.ticketForm.controls.cf_order.setValue(this.selectedOrder.incrementId);
    this.ticketForm.controls.cf_stores.setValue(this.selectedOrder.storeName);
    this.ticketForm.controls.cf_sku.setValue(this.selectedOrder.items[0].SKU);

    this.ticketForm.controls.cf_slots.setValue(
      this.selectedOrder.shippingDescription,
    );

    this.ticketForm.controls.cf_delivered_date.setValue(this.deliveredDate);
  }

  /**
   * Iterates through all the form controls and
   * Generate form data object and pass to server
   * Show the server response message
   */
  saveTicket(): void {
    if (this.ticketForm.invalid) {
      this.showError = true;

      return;
    }

    const ticketData: FormData = new FormData();
    // TODO
    // eslint-disable-next-line guard-for-in
    for (const key in this.ticketForm.value) {
      if (this.ticketForm.get(key).value instanceof Date) {
        ticketData.append(
          key,
          this.getFormattedDate(this.ticketForm.get(key).value)
        );

        continue;
      }
      ticketData.append(key, this.ticketForm.get(key).value);
    }

    if (this.helpDeskFile) {
      ticketData.append("helpdesk_file", this.helpDeskFile, this.helpDeskFile.name);
    }

    this.loading = true;
    this.presentLoader();
    this.createTicketService.getData(ticketData).subscribe(
      (data: GenericServerResponse[]) => {
        this.loading = false;
        this.dismissLoader();
        this.showNotification(
          data[0].status ? "top" : "bottom",
          "center",
          data[0].status ? "success" : "danger",
          "info-circle",
          data[0].message,
        );
        if (data[0].status) {
          this.dialogRef.close();
        }
      },
      (err) => {
        this.dismissLoader();
        this.somethingWentWrong();
        this.loading = false;
      },
    );
  }

  /**
   * From SaleOrderStatusHistory in saleOrder,
   * orders with completed status is filtered and it's created_at is returned
   */
  get deliveredDate(): string {
    const date: SaleOrderStatusHistory[] = this.selectedOrder.progress.filter(
      (order) => order.status == "complete",
    );

    return date[0] ? date[0].created_at : "";
  }

  // To close mat dialog
  dialogClose(): void {
    this.dialogRef.close();
  }

  // To validate empty fields
  validateTicketForm(): void {
    // TODO
    // eslint-disable-next-line guard-for-in
    for (const key in this.ticketForm.controls) {
      const control: AbstractControl = this.ticketForm.controls[key];
      if (!control.valid) {
        this.validationFailed([key]);
      }
    }

    this.saveTicket();
  }

  validationFailed([key]: string[]): void {
    this.showNotification(
      "bottom",
      "center",
      "danger",
      "info-circle",
      [key] + " field is empty",
    );
  }

  /**
   * Returns formatted date that is accepted by server
   */
  getFormattedDate(date: Date): string {
    const formattedDate: string =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

    return formattedDate;
  }

  /**
   * Load the uploaded file and validate the size of the file below 200kb
   * @param event
   */
  uploadFile(event: any): void {
    const fileSize: number = event.target.files[0].size;
    if (fileSize > 200000) {
      this.fileErrorMessage = "File to be uploaded should be less than 200kb";
      this.resetFile();

      return;
    }

    this.fileErrorMessage = "";
    this.helpDeskFile = event.target.files[0];
  }

  /**
   * reset the uploaded file
   */
  resetFile(): void {
    this.document.nativeElement.value = "";
  }
}

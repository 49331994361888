<div *ngIf="canShowSMActions">
  <button
    mat-raised-button
    color="primary"
    id="raise-issue"
    (click)="approveRequest()"
    *ngIf="tripSheet.isSchemeDaily"
  >
    Raise Issue
  </button>
  <button
    mat-raised-button
    color="primary"
    id="confirm"
    (click)="confirmRequest()"
  >
    Confirm
  </button>
</div>
<div *ngIf="tripSheet?.isPayable && !isDeliveryManagerMode">
  <button
    mat-raised-button
    color="primary"
    id="pay"
    [disabled]="!isEnable"
    (click)="tripSheetPayAmount()"
  >
    Pay Now
  </button>
</div>
<div *ngIf="canShowDMActions">
  <button
    mat-raised-button
    color="primary"
    id="approve"
    (click)="tripSheetApprove()"
  >
    Approve
  </button>
  <button
    mat-raised-button
    color="primary"
    id="reject"
    (click)="approveWithOutMbgInc()"
  >
    Approve w/o Mgb/Inc
  </button>
  <button
    mat-raised-button
    color="primary"
    id="tripsheet-reject"
    (click)="tripSheetReject()"
  >
    Reject
  </button>
  <button mat-raised-button color="primary" id="reject" (click)="addPenalty()">
    Add Penalty
  </button>
</div>

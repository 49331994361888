import { Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { TripSheet } from "@tendercuts/models";
import { BasePage } from "src/app/utils";

/**
 * component to show driver name and phone with hyperlinks
 */
@Component({
  selector: "app-trip-sheet-title",
  templateUrl: "./trip-sheet-title.component.html",
  styleUrls: ["./trip-sheet-title.component.scss"],
})
export class TripSheetTitleComponent extends BasePage {
  @Input()
  tripSheet: TripSheet;

  @Input()
  dialogMode: boolean;

  // hide redirect to driver profile
  @Input()
  tripSheetDetailMode: boolean = false;

  @Input()
  showBackButton: boolean = true;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
  ) {
    super(dialog, snackBar, store);
  }

  /**
   * redirect to trip-detail with params
   * @param event
   * @param trip
   */
  redirectToDriverProfile(): void {
    if (this.tripSheetDetailMode) {
      return;
    }

    window.open(
      window.location.origin +
        "/dashboard/driver-crm/" +
        this.tripSheet.driverId,
      "_blank",
    );
  }

  /*
   * Redirect to TripSheet Page
   * To close tripSheet dialog box
   */
  closeTripSheet(): void {
    if (this.dialogMode) {
      this.dialog.closeAll();

      return;
    }
    this.router.navigate([
      "dashboard/generate-trip-sheet/" + this.tripSheet.earningDate,
    ]);
  }
}

import { Serializable } from "../base";

export class DriverTripEarning implements Serializable<DriverTripEarning> {
  private weekly_total_earning: string;
  private weekly_travelled_km: string;
  private weekly_ride_time: string;
  private daily_total_earning: string;
  private daily_travelled_km: string;
  private daily_ride_time: string;

  get weeklyTotalEarning(): string {
    return this.weekly_total_earning;
  }

  get weeklyTravelledKm(): string {
    return this.weekly_travelled_km;
  }

  get weeklyRideTime(): string {
    return (
      this.weekly_ride_time &&
      this.seconds_to_hours_mins(parseInt(this.weekly_ride_time, 10))
    );
  }

  get dailyTotalEarning(): string {
    return this.daily_total_earning;
  }

  get dailyTravelledKm(): string {
    return this.daily_travelled_km;
  }

  get dailyRideTime(): string {
    return (
      this.daily_ride_time &&
      this.seconds_to_hours_mins(parseInt(this.daily_ride_time, 10))
    );
  }

  deserialize(input) {
    if (!input) {
      return this;
    }
    this.weekly_total_earning = input.weekly_total_earning;
    this.weekly_travelled_km = input.weekly_travelled_km;
    this.weekly_ride_time = input.weekly_ride_time;
    this.daily_total_earning = input.daily_total_earning;
    this.daily_travelled_km = input.daily_travelled_km;
    this.daily_ride_time = input.daily_ride_time;
    return this;
  }

  /**
   *
   * @param seconds
   *  convert seconds to hours and minutes
   */
  seconds_to_hours_mins(seconds: number): string {
    var days = Math.floor(seconds / (24 * 60 * 60));
    seconds -= days * (24 * 60 * 60);
    var hours = Math.floor(seconds / (60 * 60));
    seconds -= hours * (60 * 60);
    var minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
    return hours + "h " + minutes + "m";
  }
}

import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { HyperTrackOrder, HyperTrackTrip, Trips } from "@tendercuts/models";
import { BasePage } from "src/app/utils";

@Component({
  selector: "app-hyper-track-trip",
  templateUrl: "./hyper-track-trip.component.html",
  styleUrls: ["./hyper-track-trip.component.scss"],
})
export class HyperTrackTripComponent extends BasePage {
  trip: Trips;
  hyperTrackTrip: HyperTrackTrip;
  embedUrl: SafeResourceUrl;
  nextOrderETA: number;

  /**
   * Returns true if all the orders in the trip are completed
   * and the driver is returning to the store
   */
  get showStoreETA(): boolean {
    return (
      this.hyperTrackTrip.isOrdersCompleted && !this.hyperTrackTrip.isCompleted
    );
  }

  /**
   * Returns true if there is an order which has not been completed or cancelled
   */
  get showNextOrderETA(): boolean {
    return (
      !this.hyperTrackTrip.isOrdersCompleted && !this.hyperTrackTrip.isCompleted
    );
  }

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public dialogRef: MatDialogRef<HyperTrackTripComponent>,
    protected sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(dialog, snackBar, store);
    if (data) {
      this.trip = data.trip;
      this.hyperTrackTrip = new HyperTrackTrip().deserialize(
        data.hyperTrackTrip
      );
      this.embedUrl = data.embedUrl;

      // Searches the list of orders to find an order that has not been completed or cancelled
      const currentOrder: HyperTrackOrder = new HyperTrackOrder().deserialize(
        this.hyperTrackTrip.order.find((tripOrder) => !tripOrder.isCompleted)
      );
      this.nextOrderETA = currentOrder ? currentOrder.remainingDuration : 0;
    }
  }

  dialogClose(): void {
    this.dialogRef.close();
  }
}

import { Component, Inject, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { TripPayment, TripSheet } from "@tendercuts/models";
import { TripPaymentPaidService } from "@tendercuts/providers";
import { TripPaymentDataSource } from "src/app/components";
import { BasePage } from "src/app/utils";
import { FilterModel } from "src/models";
import { TripSheetPaymentOtpComponent } from "../trip-sheet-payment-otp";

/**
 * component to show for delivery manager approval
 */
@Component({
  selector: "app-trip-sheet-pay-dialog",
  templateUrl: "./trip-sheet-pay-dialog.component.html",
  styleUrls: ["./trip-sheet-pay-dialog.component.scss"],
})
export class TripSheetPayComponent extends BasePage implements OnInit {
  tripSheet: TripSheet;

  modelFilter: FilterModel = new FilterModel([]);

  tripPaymentDataSource: TripPaymentDataSource = new TripPaymentDataSource(
    this.modelFilter,
    []
  );

  // controller for pay input amount
  inputController: FormControl = new FormControl();

  @Input() columnsToDisplay: string[] = [
    "referenceNo",
    "paymentDateTime",
    "amount",
  ];
  @Input() titleToDisplay: string[] = [
    "Reference No",
    "Paid on",
    "Paid Amount",
  ];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TripSheetPayComponent>,
    private tripPaymentPaidService: TripPaymentPaidService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>
  ) {
    super(dialog, snackBar, store);
    this.tripSheet = data.tripSheet;
  }

  ngOnInit(): void {
    this.fetchTripPayment();
  }

  /**
   * get the trip data source
   */
  fetchTripPayment(): void {
    const paramsData: {
      sheet_id: number;
    } = this.tripPaymentPaidService.getParams(this.tripSheet.id);
    this.tripPaymentPaidService
      .getData(paramsData)
      .subscribe((data: TripPayment[]) => {
        this.tripPaymentDataSource.data = data;
      });
  }

  /**
   * check the entered value and total paid amount
   *  must be less than the total earning
   *
   */
  tripSheetPay(): void {
    if (
      this.tripSheet.totalPaidAmount + +this.inputController.value <=
      this.tripSheet.totalEarnings
    ) {
      this.tripSheetOpenOtpDialog();

      return;
    }
    this.showNotification(
      "top",
      "center",
      "danger",
      "info-circle",
      "Amount should not be more than " +
        (+this.tripSheet.totalEarnings - +this.tripSheet.totalPaidAmount)
    );
  }

  /**
   * to open a otp page for verification
   */
  tripSheetOpenOtpDialog(): void {
    const data: {
      tripSheet: TripSheet;
      amount: number;
    } = {
      tripSheet: this.tripSheet,
      amount: this.inputController.value,
    };
    const dialogRef: MatDialogRef<TripSheetPaymentOtpComponent, any> =
      this.dialog.open(TripSheetPaymentOtpComponent, {
        width: "70vh",
        panelClass: "remove-padding",
        data,
      });
    dialogRef.afterClosed().subscribe((tripSheetData) => {
      if (!tripSheetData) {
        return;
      }
      if (tripSheetData.status) {
        this.dialogRef.close({
          status: true,
        });
      }
    });
  }

  /**
   * check for enable submit button
   * 1.if the pay amount has a value change the flag to true
   *
   */
  get canActiveSubmit(): boolean {
    if (Number(this.inputController.value) && this.inputController.value >= 1) {
      return this.inputController.value;
    }

    return;
  }

  /**
   * To close the dialogbox
   */
  closeDialog(): void {
    this.dialogRef.close();
  }
}

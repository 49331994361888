import { NgModule } from "@angular/core";

import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";

// Required modules
import { SharedModule } from "../shared/shared.module";
import { Error404PageComponent, Error404PageResolver } from "./404";
import {
  ResponsiveBreakpointsComponent,
  ResponsiveBreakpointsService,
  ResponsiveBreakpointDirective,
} from "./responsive-breakpoints";
import {
  CondenseMenuComponent,
  MenuTopItemsComponent,
  SideMenuContentComponent,
  SideMenuService,
} from "./side-menu";
import {
  MessagesMenuComponent,
  MessagesMenuService,
  NotificationsMenuButtonComponent,
  NotificationsMenuContentComponent,
  NotificationsMenuService,
  SearchBarComponent,
  StoreSearchComponent,
  TopNavbarContentComponent
} from "./top-navbar";

@NgModule({
  declarations: [
    BreadcrumbComponent,
    // Top navbar stuff
    MessagesMenuComponent,
    NotificationsMenuButtonComponent,
    NotificationsMenuContentComponent,
    SearchBarComponent,
    TopNavbarContentComponent,
    // Side menu stuff
    CondenseMenuComponent,
    MenuTopItemsComponent,
    SideMenuContentComponent,
    // 404
    Error404PageComponent,
    // Responsive stuff
    ResponsiveBreakpointDirective,
    ResponsiveBreakpointsComponent,
    StoreSearchComponent
  ],
  imports: [SharedModule],
  providers: [
    MessagesMenuService,
    NotificationsMenuService,
    SideMenuService,
    // 404
    Error404PageResolver,
    // Responsive stuff
    ResponsiveBreakpointsService,
  ],
  exports: [
    BreadcrumbComponent,
    TopNavbarContentComponent,
    SideMenuContentComponent,
    NotificationsMenuContentComponent,
    // 404
    Error404PageComponent,
    // Responsive stuff
    ResponsiveBreakpointsComponent,
    StoreSearchComponent
  ],
})
export class CoreModule {}

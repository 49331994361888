import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { BasePostProvider } from "@tendercuts/http";
import { GenericServerResponse } from "@tendercuts/models";

@Injectable()
export class CreateDriverService extends BasePostProvider<GenericServerResponse> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public store: Store<AppState>,
    public loginService: LoginServices
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  getHeaders(token: string): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();

    if (this.isAuthenticatedEndpoint) {
      headers = headers.set("Authorization", `Token ${token}`);
    }

    return headers;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof GenericServerResponse {
    return GenericServerResponse;
  }

  get endpoint(): string {
    return this.endpointService.createDriver();
  }

  getParams(
    firstName: string,
    phone: number,
    accountNo: number,
    ifscCode: any,
    proofImage: File,
    profileImage: File,
    proofId: any,
    proofType: any,
    isWeekendUser: boolean,
    email: any,
    workLocation: any,
    pan: any,
    preferredLanguage: [],
    earningScheme: string
  ): FormData {
    const params: FormData = new FormData();
    params.append("first_name", firstName.toString());
    params.append("phone", phone.toString());
    params.append("email", email.toString());
    params.append("account_no", accountNo.toString());
    params.append("ifsc_code", ifscCode.toString());
    params.append("profile_image", profileImage, profileImage.name);
    params.append("proof_image", proofImage, proofImage.name);
    params.append("proof_type", proofType.toString());
    params.append("proof_id", proofId.toString());
    params.append("is_weekend_user", isWeekendUser.toString());
    params.append("work_location", workLocation.toString());
    params.append("pan", pan.toString());
    params.append("preferred_language", preferredLanguage.toString());
    params.append("earning_scheme", earningScheme);

    return params;
  }
}

import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-view-trip-btn-dialogue",
  templateUrl: "./view-trip-btn-dialogue.component.html",
  styleUrls: ["./view-trip-btn-dialogue.component.scss"],
})
export class ViewTripBtnDialogueComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ViewTripBtnDialogueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {}
}

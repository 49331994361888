import { Serializable } from "../base";
import { FaqActions } from "./faq-action";

export class FaqDetail implements Serializable<FaqDetail> {
  private sort_order: number;
  private created_by: string;
  private is_active: boolean;
  private created_at: string;
  private agent_group: string;
  public name: string;
  public question: string;
  public answer: string;
  public topics: string;
  public action: FaqActions[] = [];

  constructor() {}

  get sortOrder(): number {
    return this.sort_order;
  }

  get createdBy(): string {
    return this.created_by;
  }

  get isActive(): boolean {
    return this.is_active;
  }

  get createdAt(): string {
    return this.created_at;
  }

  get agentGroup(): string {
    return this.agent_group;
  }

  deserialize(input) {
    this.name = input.name;
    this.question = input.question;
    this.answer = input.answer;
    this.topics = input.topics;
    this.created_at = input.created_at;
    this.is_active = input.is_active;
    this.created_by = input.created_by;
    this.sort_order = input.sort_order;
    this.agent_group = input.agent_group;

    if(input.actions instanceof Array) {
      for (const value of input.actions) {
        this.action.push(new FaqActions().deserialize(value));
      }
    }

    return this;
  }
}

import { Serializable } from "../base";
import { SaleOrder } from "@tendercuts/models";

export class StoreSegment implements Serializable<StoreSegment> {
  private segment_type: number;
  private stroke_color: string;
  private z_index: number;
  private stroke_weight: number;
  public name: string;
  public path_string: string;
  public store_id: number;
  public segment_id: number;
  public id: number;

  // transient
  private orders: Set<any> = new Set<any>();
  public orderCount: number = 0;
  public urgentCount: number = 0;
  private _polygon;
  public center: google.maps.LatLng;

  get storeId() {
    return this.store_id;
  }

  get segmentId() {
    return this.segment_id;
  }

  get pathString() {
    return this.path_string;
  }

  get polygon() {
    if (!this._polygon) {
      let points = this.points.map(
        (pt) => new google.maps.LatLng(pt[0], pt[1])
      );
      this._polygon = new google.maps.Polygon({ paths: points });
    }

    return this._polygon;
  }

  get zIndex(): number {
    return this.z_index;
  }

  get strokeColor(): string {
    return this.stroke_color;
  }

  get strokeWeight(): number {
    return this.stroke_weight;
  }

  get segmentType(): number {
    return this.segment_type;
  }
  /**
   * Helper utility to preapare the path into point for external
   * library consumption like google, jsts etc.
   */
  get points() {
    let components = this.pathString.split(",");
    let points = [];
    while (components.length != 0) {
      points.push([
        parseFloat(components.shift()),
        parseFloat(components.shift()),
      ]);
    }

    return points;
  }

  constructor() {}

  /**
   * Verifies if the point is in the segment and updates params
   * @param lat
   * @param lng
   */
  public containsPoint(lat: number, lng: number) {
    let point = new google.maps.LatLng(lat, lng);
    return google.maps.geometry.poly.containsLocation(point, this.polygon);
  }

  /**
   * Add order to this segment.
   * @param order
   */
  public addOrder(order: SaleOrder) {
    // create links
    order.segmentId = this.segmentId;
    this.orders.add(order.incrementId);

    this.orderCount += 1;
    // Urgent count
    if (order.timeRemaining < 75) {
      this.urgentCount += 1;
    }
  }

  /**
   * Helper method to get the orders
   * that are part of this segment.
   * @param orders list of SaleOrders
   * @return Set of increment ids
   */
  public hasOrders(orders: SaleOrder[]) {
    let intersection = new Set();

    orders.forEach((order) => {
      if (this.orders.has(order.incrementId)) {
        intersection.add(order.incrementId);
      }
    });

    return intersection;
  }

  /**
   * Nuke all counters
   */
  public resetState() {
    this.orders.clear();
    this.orderCount = 0;
    this.urgentCount = 0;
  }

  deserialize(input) {
    this.name = input.name;
    this.path_string = input.path_string;
    this.store_id = input.store_id;
    this.segment_id = input.segment_id;
    this.id = input.id;
    this.segment_type = input.segment_type;
    this.stroke_color = input.stroke_colour;
    this.z_index = input.zindex;
    this.stroke_weight = input.stroke_weight ? input.stroke_weight : 1;

    // this.setCenter();
    return this;
  }
}

<h5>Delivery Instructions</h5>
<form>
  <mat-radio-group
    [(ngModel)]="deliveryInstructions"
    [ngModelOptions]="{standalone: true}"
  >
    <mat-radio-button class="delivery-instruction" *ngFor="let instruction of availableDeliveryInstructions" [value]="instruction">
      {{instruction}}
    </mat-radio-button>
  </mat-radio-group>
  <div class="button-row">
    <button color="primary" mat-raised-button mat-dialog-close>Cancel</button>
    <button
      color="primary"
      mat-raised-button
      (click)="sendDeliveryInstructions()"
      type="submit"
      [disabled]="!deliveryInstructions"
    >
      Send
    </button>
  </div>
</form>

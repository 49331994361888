<table [ngClass]="{ 'order-detail': !invoiceMode }">
  <tr *ngIf="!invoiceMode">
    <th></th>
    <th>Combo Items & Quantity</th>
    <th>Product Id</th>
    <th>Shipped Weight</th>
    <th *ngIf="isTechOps">SKU</th>
    <th>MRP</th>
    <th>Discount</th>
    <th>Item-Total</th>
    <th>GST</th>
  </tr>
  <tr *ngFor="let item of comboChildItems">
    <td>
      <i class="fa fa-arrow-right" aria-hidden="true"></i>
    </td>
    <td>
      <span class="label"
        >{{ item.name }} * {{ item.quantity | number: "1.0-0" }}
      </span>
    </td>
    <td>
      {{ item.productId }}
    </td>
    <td>
      {{ item.displayWeight | number: "1.3-3" }}
    </td>
    <td *ngIf="isTechOps">
      {{ item.sku }}
    </td>
    <td>
      {{ item.basePrice | currency: "INR" }}
    </td>
    <td>
      {{ item.discountPrice | currency: "INR" }}
    </td>
    <td>
      {{ item.rowTotal | currency: "INR" }}
    </td>
    <td>
      {{ item.taxAmount | currency: "INR" }}
    </td>
  </tr>
</table>

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BasePostProvider } from "@tendercuts/http";
import { GenerateTripSheetResponse } from "@tendercuts/models";

@Injectable({
  providedIn: "root",
})
export class TripSheetGenerateService extends BasePostProvider<GenerateTripSheetResponse> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof GenerateTripSheetResponse {
    return GenerateTripSheetResponse;
  }

  get isCachable(): boolean {
    return false;
  }

  get endpoint(): string {
    return this.endpointService.tripSheetGenerate();
  }

  /**
   * Convert the date into "YYYY-MM-DD"
   * @param driverId
   * @param selectedDate
   */
  getParams(driverId: number, selectedDate: Date): {
    driver_id: string;
    earning_date: string;
} {
    const date: string = selectedDate
      .getDate()
      .toLocaleString("en-US", { minimumIntegerDigits: 2 });
    const month: string = (selectedDate.getMonth() + 1).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
    });
    const formattedDate: string =
      selectedDate.getFullYear() +
      "-" +
      month.toString() +
      "-" +
      date.toString();

    const params: {
      driver_id: string;
      earning_date: string;
  } = {
      driver_id: driverId.toString(),
      earning_date: formattedDate,
    };

    return params;
  }

  /**
   * Convert the date into "YYYY-MM-DD"
   * @param driverId
   * @param selectedDate
   * @param correctedCheckIn
   * @param correctedCheckOut
   */
  updateCorrectedValues(
    driverId: number,
    selectedDate: Date,
    correctedCheckIn: number,
    correctedCheckOut: number,
  ): {
    driver_id: string;
    earning_date: string;
    corrected_check_in_value: number;
    corrected_check_out_value: number;
} {
    const date: string = selectedDate
      .getDate()
      .toLocaleString("en-US", { minimumIntegerDigits: 2 });
    const month: string = (selectedDate.getMonth() + 1).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
    });
    const formattedDate: string =
      selectedDate.getFullYear() +
      "-" +
      month.toString() +
      "-" +
      date.toString();

    const params: {
      driver_id: string;
      earning_date: string;
      corrected_check_in_value: number;
      corrected_check_out_value: number;
  } = {
      driver_id: driverId.toString(),
      earning_date: formattedDate,
      corrected_check_in_value: correctedCheckIn,
      corrected_check_out_value: correctedCheckOut,
    };

    return params;
  }
}

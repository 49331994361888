import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BaseGetProvider } from "@tendercuts/http";
import { TripEarning } from "@tendercuts/models";

@Injectable({
  providedIn: "root",
})
export class FetchDriverEarningsService extends BaseGetProvider<TripEarning> {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http);
    this.delegate = this;
  }

  // override this
  public preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof TripEarning {
    return TripEarning;
  }

  get endpoint(): string {
    return this.endpointService.fetchDriverEarnings();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(
    driverId: number,
    selectedDate: Date,
  ): {
    driver_id: string;
    created_date: string;
  } {
    if (selectedDate.toLocaleDateString() !== new Date().toLocaleDateString()) {
      selectedDate.setTime(selectedDate.getTime() + 5.5 * 60 * 60 * 1000);
    }
    const params: {
      driver_id: string;
      created_date: string;
    } = {
      driver_id: driverId.toString(),
      created_date: new Date(selectedDate).toISOString().split("T")[0],
    };

    return params;
  }
}

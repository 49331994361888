<div class="header row">
  <div class="header__icon col-2">
    <i class="menu-icon fa fa-group"></i>
  </div>
  <div class="header__title col-10">
    <div class="header__title__company_name">
      <b>Good To Go Foodworks Pvt. Ltd.</b>
    </div>
    <div class="header__title__name">
      {{ (userState | async).user?.fullName }}
    </div>
  </div>
</div>

<mat-accordion class="grouped-side-menu">
  <condense #condenseMenu [ngClass]="condenseMenu.menuState">
    <div *ngIf="(userState | async)?.user.isOrderHistoryAccessor">
      <a
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['/dashboard/all-orders']"
        class="simple-menu-item"
      >
        <i class="menu-item-icon fa fa-calendar"></i>
        <span class="menu-item-title">Order History</span>
      </a>
    </div>
    <mat-expansion-panel *ngIf="(userState | async)?.user.isInventoryManager">
      <mat-expansion-panel-header>
        <mat-panel-title class="main-menu"> Inventory </mat-panel-title>
      </mat-expansion-panel-header>

      <a
        *ngIf="!(selectedStore | async)?.isGtg"
        id="old-inv-stock"
        class="simple-menu-item"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['/dashboard/stock-request-web']"
      >
        <i class="menu-item-icon fa fa-indent"></i>
        Old Inventory Update
      </a>
      <a
        id="new-inventory-update"
        class="simple-menu-item"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['/dashboard/new-inventory-update']"
      >
        <i class="menu-item-icon fa fa-thumbs-up"></i>
        <span class="menu-item-title">Inventory Update</span>
      </a>

      <a
        *ngIf="!(selectedStore | async)?.isGtg"
        id="inv-update"
        class="simple-menu-item"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['/dashboard/inv-update']"
      >
        <i class="menu-item-icon fa fa-table"></i>
        Inventory - Bulk Upload
      </a>

      <a
        *ngIf="!(selectedStore | async)?.isGtg"
        id="inv-update"
        class="simple-menu-item"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['/dashboard/inv-debug']"
      >
        <i class="menu-item-icon fa fa-table"></i>
        Inventory - Debug
      </a>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title class="main-menu"> Trip </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-container
        *ngIf="
          (userState | async)?.user.isSm || (userState | async)?.user.isCcAgent
        "
      >
        <a
          id="order-locator-dashboard"
          class="simple-menu-item"
          [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLink]="['/dashboard/order-detail']"
        >
          <i class="menu-item-icon fa fa-map-pin"></i>
          <span class="menu-item-title">Order Detail</span>
        </a>

        <a
          *ngIf="
            (userState | async)?.user.isDeliveryManager ||
            (userState | async)?.user.isSm
          "
          id="driver-dashboard"
          class="simple-menu-item"
          [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLink]="['/dashboard/trip-detail']"
        >
          <i class="menu-item-icon fa fa-motorcycle"></i>
          Trip Detail
        </a>
        <a
          *ngIf="
            (userState | async)?.user.isDeliveryManager ||
            (userState | async)?.user.isSm
          "
          id="driver-dashboard"
          class="simple-menu-item"
          [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLink]="['/dashboard/hyper-track']"
        >
          <i class="menu-item-icon fa fa-location-arrow"></i>
          Trip Track
        </a>
      </ng-container>

      <!-- <a *ngIf="(userState | async)?.user.isCcAgent" id="driver-dashboard" class="simple-menu-item"
        [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['/dashboard/geohash/update-location']">
        <i class="menu-item-icon fa fa-map-pin"></i>
        Update Location
      </a> -->

      <ng-container
        *ngIf="
          (userState | async)?.user.isCcAgent && (userState | async)?.user.isSm
        "
      >
        <a
          id="live-track-debug"
          class="simple-menu-item"
          (click)="openLiveTrack()"
        >
          <i class="menu-item-icon fa fa-map-pin"></i>
          <span class="menu-item-title">Live Track</span>
        </a>
      </ng-container>
    </mat-expansion-panel>

    <mat-expansion-panel
      *ngIf="
        (userState | async)?.user.isDeliveryManager ||
        (userState | async)?.user.isSm
      "
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="main-menu"> Driver </mat-panel-title>
      </mat-expansion-panel-header>

      <a
        *ngIf="(userState | async)?.user.isSm"
        id="driver-earnings"
        class="simple-menu-item"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['/dashboard/generate-trip-sheet']"
      >
        <i class="menu-item-icon fa fa-bar-chart"></i>
        <span class="menu-item-title">Trip Sheets</span>
      </a>

      <a
        *ngIf="(userState | async)?.user.isDeliveryManager"
        id="trip-sheet-update"
        class="simple-menu-item"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['/dashboard/trip-sheet-approve']"
      >
        <i class="menu-item-icon fa fa-address-book-o"></i>
        <span class="menu-item-title">Trip Sheet Approve</span>
      </a>

      <a
        *ngIf="(userState | async)?.user.isSm"
        id="weekly-tripsheet"
        class="simple-menu-item"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['/dashboard/weekly-tripsheet']"
      >
        <i class="menu-item-icon fa fa-bar-chart"></i>
        <span class="menu-item-title">Weekly Trip Sheet</span>
      </a>

      <a
        *ngIf="(userState | async)?.user.isDeliveryManager"
        id="weekly-trip-sheet-update"
        class="simple-menu-item"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['/dashboard/weekly-tripsheet-approve']"
      >
        <i class="menu-item-icon fa fa-address-book-o"></i>
        <span class="menu-item-title">Weekly Trip Sheet Approve</span>
      </a>

      <a
        *ngIf="
          (userState | async)?.user.isDeliveryManager ||
          (userState | async)?.user.isSm
        "
        id="search-driver-profile"
        class="simple-menu-item"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['/dashboard/driver-crm']"
      >
        <i class="menu-item-icon fa fa-address-book-o"></i>
        <span class="menu-item-title">Driver CRM</span>
      </a>

      <a
        id="booking-status"
        class="simple-menu-item"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['/dashboard/booking-status']"
      >
        <i class="menu-item-icon fa fa-motorcycle"></i>
        Booking Status
      </a>
    </mat-expansion-panel>
    <div class="logout">
      <a id="logout" class="simple-menu-item" (click)="logout()">
        <i class="menu-item-icon fa fa-sign-out"></i>
        <span class="menu-item-title">Logout</span>
      </a>
    </div>
  </condense>

  <div
    class="condense-toggle-wrapper"
    [ngClass]="{ condensed: condenseMenu.menuState === 'condensed' }"
  >
    <span (click)="condenseMenu.toggleState()" class="condense-toogle"></span>
  </div>
</mat-accordion>

<!-- <a id="stock" *ngIf="!(userState | async)?.user.isSm" class="simple-menu-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/stock-request']">
      <i class="menu-item-icon fa fa-flash"></i>
      <span class="menu-item-title">Stock Requests</span>
    </a> -->

<!-- <a
    *ngIf="(userState | async)?.user.isSm"
    id="inv-adjustment"
    class="simple-menu-item"
    [routerLinkActive]="['active']"
    [routerLinkActiveOptions]="{ exact: true }"
    [routerLink]="['/dashboard/inv-adjustment']"
  >
    <i class="menu-item-icon fa fa-indent"></i>
    Inventory Update
  </a> -->

import { Serializable } from "./base";

export class footerLinks implements Serializable<footerLinks> {
  constructor() {}

  public page_id: number;
  public content: string;
  public meta_description: string;
  public meta_keywords: string;
  public is_active: number;
  public title: string;

  deserialize(input) {
    this.content = input.content;
    this.meta_description = input.meta_description;
    this.meta_keywords = input.meta_keywords;
    this.is_active = input.is_active;
    this.title = input.title;
    this.page_id = input.page_id;
    return this;
  }
}

export class CmsFooter implements Serializable<CmsFooter> {
  constructor() {}

  public id: number;
  public title: string;

  deserialize(input) {
    this.id = input.page_id;
    this.title = input.title;
    return this;
  }
}

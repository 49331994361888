import { Serializable } from "../base";

/**
 * Contains specs of product required for scanning.
 */
export class ProductSpec implements Serializable<ProductSpec> {
  private weight_from: number;
  private weight_to: number;
  private entity_id: number;
  private is_scannable: number;
  private validate_weight: boolean;

  constructor() {}

  deserialize(input): ProductSpec {
    this.entity_id = input.entity_id;
    this.weight_from = input.weight_from;
    this.weight_to = input.weight_to;
    this.is_scannable = input.is_scannable;
    this.validate_weight = input.validate_weight;

    return this;
  }

  get weightFrom(): number {
    return +this.weight_from;
  }

  get weightTo(): number {
    return +this.weight_to;
  }

  get productId(): number {
    return this.entity_id;
  }

  get isScannable(): boolean {
   return this.is_scannable === 1 ||  this.is_scannable === 2;
  }

  get shouldValidateWeight(): boolean {
    return this.is_scannable === 2;
  }
}

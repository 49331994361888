<mat-sidenav-container class="driver-crm">
  <mat-sidenav id="drawer" mode="over" position="end" #drawer>
    <i class="menu-item-icon fa fa-times" (click)="closeDrawer()"></i>
    <div class="trip-detail" *ngIf="selectedTrip">
      <app-trip-detail [trip]="selectedTrip"></app-trip-detail>
    </div>
    <app-verify-weekly-trip-sheet
      [tripSheet]="selectedWeekTrip"
      *ngIf="selectedWeekTrip"
      (onClose)="closeDrawer($event)"
    ></app-verify-weekly-trip-sheet>

    <app-verify-trip-sheet
      *ngIf="selectedTripSheet"
      (reloadParent)="closeDrawer($event)"
      [tripSheet]="selectedTripSheet"
      [tripSheetDetailMode]="true"
    >
    </app-verify-trip-sheet>
  </mat-sidenav>

  <div class="search-bar no-gutters row">
    <mat-form-field class="col-4">
      <input
        matInput
        type="number"
        maxlength="10"
        placeholder="Enter Driver Id/Phone Number"
        aria-label="text"
        [(ngModel)]="searchText"
        (keyup.enter)="searchDriver()"
      />
    </mat-form-field>

    <div class="col-4">
      <button
        class="col-3"
        mat-stroked-button
        id="search-profile"
        (click)="searchDriver()"
      >
        Search
      </button>
    </div>
    <div class="col-4" align="right">
      <button
        *ngIf="user && user.isDeliveryManager"
        mat-raised-button
        color="primary"
        id="create-driver"
        (click)="createDriver()"
      >
        Create Driver
      </button>
    </div>
  </div>
  <ng-container *ngIf="tcDriver">
    <div class="driver-actions">
      <app-driver-actions
        (refreshParentEmitter)="reloadData()"
        [driver]="tcDriver"
        [driverBookings]="bookingLastTrip"
      ></app-driver-actions>
    </div>
    <div class="driver-info row col-12">
      <div class="col-6">
        <mat-card class="driver-info__card">
          <mat-card-title class="driver-info__card__title"
            >Driver Details</mat-card-title
          >
          <mat-card-content class="driver-info__card__content">
            <div class="driver-content row col-12">
              <div class="driver-image col-5">
                <div class="image">
                  <img
                    *ngIf="tcDriver && tcDriver.profile?.thumb; else showIcon"
                    [src]="tcDriver.profile.thumb"
                    alt="Driver"
                    class="image__picture"
                  />
                  <ng-template #showIcon>
                    <mat-icon class="image__icon">account_circle</mat-icon>
                  </ng-template>
                  <div class="driver-profile" id="rider-details">
                    <div
                      align="center"
                      class="driver-profile__name no-gutters row"
                    >
                      <div class="col-12">
                        <h6>
                          <b>{{ tcDriver.firstName }} </b>
                        </h6>
                      </div>
                    </div>
                    <div
                      align="center"
                      class="driver-profile__grade no-gutters row"
                    >
                      <div class="col-12">
                        <mat-chip
                          class="rider-status"
                          [ngClass]="{
                            silver: tcDriver.isSilver,
                            platinium: tcDriver.isPlatinum,
                            gold: tcDriver.isGold
                          }"
                        >
                          {{ tcDriver.profile.driverGrade }}</mat-chip
                        >
                      </div>
                    </div>
                    <div class="driver-profile__phone no-gutters row">
                      <div class="col-12">
                        <h6>
                          {{ tcDriver.phoneNumber }}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-7 details">
                <div class="row no-gutters">
                  <span class="field col-6">Work Location</span>
                  <span class="value col-6">{{ storeName }}</span>
                </div>
                <div class="row no-gutters">
                  <span class="field col-6">Status</span>
                  <span class="value col-6">{{
                    tcDriver.profile?.subStatus
                  }}</span>
                </div>
                <div class="row no-gutters">
                  <span class="field col-6">Is Weekend Driver</span>
                  <span class="value col-6">
                    <mat-checkbox
                      #checkBox
                      labelPosition="after"
                      [checked]="tcDriver.isWeekendRider"
                      disabled
                    >
                    </mat-checkbox
                  ></span>
                </div>
                <div class="row no-gutters">
                  <span class="field col-6">External Driver</span>
                  <span class="value col-6">
                    <mat-checkbox
                      #checkBox
                      labelPosition="after"
                      [checked]="tcDriver.isFreelancer"
                      disabled
                    >
                    </mat-checkbox
                  ></span>
                </div>
                <div class="row no-gutters">
                  <span class="field col-6">Earning scheme</span>
                  <span class="value col-6">{{ tcDriver.profile.schemeDescription }}</span>
                </div>
                <div class="row no-gutters">
                  <span class="field col-6">User Id</span>
                  <span class="value col-6">{{ tcDriver.profile.user }}</span>
                </div>

                <div class="row no-gutters">
                  <span class="field col-6">Email</span>
                  <span class="value col-6">{{ tcDriver.email }}</span>
                </div>
                <div class="row no-gutters">
                  <span class="field col-6">Proof Type</span>
                  <span class="value col-6">{{
                    tcDriver.profile.proofType
                  }}</span>
                </div>
                <div class="row no-gutters">
                  <span class="field col-6">Proof Id Number</span>
                  <span class="value col-6">{{
                    tcDriver.profile.proofId
                  }}</span>
                </div>
                <div class="row no-gutters">
                  <span class="field col-6">Account Number</span>
                  <span class="value col-6">{{
                    tcDriver.profile.accountNumber
                  }}</span>
                </div>

                <div class="row no-gutters">
                  <span class="field col-6">IFSC Code</span>
                  <span class="value col-6">{{
                    tcDriver.profile.ifscCode
                  }}</span>
                </div>

                <div class="row no-gutters" *ngIf="tcDriver.profile.pan">
                  <span class="field col-6">PAN</span>
                  <span class="value col-6">{{ tcDriver.profile.pan }}</span>
                </div>
                <div class="row no-gutters" *ngIf="tcDriver.profile.languageName.length">
                  <span class="field col-6">Preferred Language</span>
                  <span class="value col-6">{{ tcDriver.profile.languageName }}</span>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div *ngIf="tcDriver.trips" class="col-3">
        <mat-card class="driver-info__card">
          <mat-card-title class="driver-info__card__title"
            >Driver Trip Details</mat-card-title
          >
          <mat-card-content class="driver-info__card__content">
            <div class="row no-gutters">
              <span class="field col-6">Total trips</span>
              <span class="value col-6">{{ tcDriver.totTrips }}</span>
            </div>
            <div class="row no-gutters">
              <span class="field col-6">Today's total trips</span>
              <span class="value col-6">{{ tcDriver.totTodaysTrip }}</span>
            </div>
            <div class="row no-gutters">
              <span class="field col-6">Last trip time</span>
              <span class="value col-6">{{ tcDriver.tripTime }}</span>
            </div>
            <div class="row no-gutters">
              <span class="field col-6">Last trip status</span>
              <span class="value col-6">{{ tcDriver.lastTripstatus }}</span>
            </div>
            <div class="row no-gutters">
              <span class="field col-6">Last trip date</span>
              <span class="value col-6">{{ tcDriver.lastTripDate }}</span>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-3" *ngIf="snapDetails">
        <mat-card class="driver-info__card">
          <mat-card-title class="driver-info__card__title"
            >Driver Milestones</mat-card-title
          >
          <mat-card-content class="driver-info__card__content">
            <div class="row no-gutters">
              <span class="field col-6">Total Orders</span>
              <span class="value col-6">{{ snapDetails.orders }}</span>
            </div>
            <div class="row no-gutters">
              <span class="field col-6">Total Trips</span>
              <span class="value col-6">{{ snapDetails.trips }}</span>
            </div>
            <div class="row no-gutters">
              <span class="field col-6">Total Ratings</span>
              <span class="value col-6">{{
                snapDetails.ratings | number: "1.0-0"
              }}</span>
            </div>
            <div class="row no-gutters">
              <span class="field col-6">Five Stars</span>
              <span class="value col-6">{{ snapDetails.fiveStarCount }}</span>
            </div>
            <div class="row no-gutters">
              <span class="field col-6">One Star</span>
              <span class="value col-6">{{ snapDetails.oneStarCount }}</span>
            </div>
            <div class="row no-gutters">
              <span class="field col-6">Year</span>
              <span class="value col-6">{{ snapDetails.year }}</span>
            </div>
            <div class="row no-gutters">
              <span class="field col-6">Week No</span>
              <span class="value col-6">{{ snapDetails.weekNo }}</span>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <mat-tab-group
      class="driver"
      (selectedTabChange)="loadData()"
      [(selectedIndex)]="index"
    >
      <mat-tab label="Trip Sheets">
        <ng-container>
          <div class="row no-gutters">
            <div
              *ngIf="tripSheetDataSource.data.length == 0; else showTripSheets"
              class="row no-gutters no-trip-sheets"
            >
              No trip sheets in this driver account
            </div>
          </div>

          <ng-template #showTripSheets>
            <app-trip-sheet-grid
              [dataSource]="tripSheetDataSource"
              [columnsToDisplay]="columnsToDisplay"
              [titleToDisplay]="titleToDisplay"
              [rowClickCallback]="rowClickCallback"
            >
            </app-trip-sheet-grid>
          </ng-template>
        </ng-container>
      </mat-tab>

      <mat-tab class="trip_detail" label="Trip Detail">
        <ng-container *ngIf="index == 1">
          <div *ngIf="tripDataSource?.data.length">
            <app-drivers-trips-grid
              [tripOrdersDataSource]="tripDataSource"
              class="trip-grid"
              id="search-trip"
              (showTrip)="showTrip($event)"
            ></app-drivers-trips-grid>
          </div>
        </ng-container>
      </mat-tab>
      <mat-tab label="Booking Details" id="booking-details">
        <ng-container *ngIf="index == 2">
          <div class="row no-gutters">
            <div
              *ngIf="
                bookingStatusDataSource.data.length == 0;
                else showBookingStatus
              "
              class="row no-gutters no-trip-sheets"
            >
              No Booking status in this driver account
            </div>
          </div>
          <ng-template #showBookingStatus>
            <app-booking-request-wrapper
              [dataSource]="bookingStatusDataSource"
              [columnsToDisplay]="columnsToDisplayBooking"
              [titleToDisplay]="titleToDisplayBooking"
              class="booking_display"
              (refreshParent)="refreshBookings($event)"
            >
            </app-booking-request-wrapper>
          </ng-template>
        </ng-container>
      </mat-tab>

      <mat-tab label="Attendance">
        <ng-container *ngIf="index == 3">
          <div>
            <app-driver-attendance-display-tab
              [dataSource]="driverAttendanceDataSource"
            >
            </app-driver-attendance-display-tab>
          </div>
        </ng-container>
      </mat-tab>

      <mat-tab label="Weekly Trip Sheets">
        <ng-container *ngIf="index == 4">
          <div>
            <app-weekly-trip-sheets-display-tab
              [dataSource]="weeklyTripSheetDataSource"
              [rowClickCallback]="rowClickCallbackWeeklyTrip"
            >
            </app-weekly-trip-sheets-display-tab>
          </div>
        </ng-container>
      </mat-tab>

      <mat-tab label="Status History">
        <ng-container *ngIf="index == 5">
          <div>
            <app-customer-profile-grid
              [dataSource]="driverStatusHistoryDataSource"
              [columnsToDisplay]="driverStatusHistoryColumns"
              [titleToDisplay]="driverStatusHistoryTitle"
              [actions]="actions"
            >
            </app-customer-profile-grid>
          </div>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</mat-sidenav-container>

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BasePostProvider } from "@tendercuts/http";
import { TripsHistory } from "@tendercuts/models";

@Injectable({
  providedIn: "root",
})
export class FetchTripsHistoryService extends BasePostProvider<TripsHistory> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof TripsHistory {
    return TripsHistory;
  }

  get endpoint(): string {
    return this.endpointService.fetchTripsHistory();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(
    storeId: number,
    date: Date,
    slotId: number[]
  ): string {
    const month: string = (date.getMonth() + 1).toString();
    const day: string = date.getDate().toString();
    const year: string = date.getFullYear().toString();
    const selectedDate: string = `${year}-${month}-${day}`;

    const params: string = JSON.stringify({
      slot_id: slotId,
      store_id: storeId,
      date: selectedDate,
    });

    return params;
  }
}

<mat-card class="slab_card">
  <mat-card-title class="slab_card__header">
    <mat-chip-list>
      Weekly Targets
      <mat-chip class="slab_card__name">{{
        weeklyTripSheet.slabName
      }}</mat-chip>
    </mat-chip-list>
  </mat-card-title>
  <mat-card-content class="slab_card__content">
    <div *ngIf="weeklyTripSheet && availableSlabs.length" class="slab">
      <div class="slab__progress slab-bold">Slab Progress</div>
      <div class="slab__progress-border">
        <app-slab-progress-bar
          [weeklyTripSheet]="weeklyTripSheet"
          [slabs]="availableSlabs"
        ></app-slab-progress-bar>
      </div>
      <div class="slab__week">
        <div class="slab__week__goals slab-bold">Week Goals</div>
        <div class="slag__goal">
          <ng-container *ngFor="let day of alldays">
            <div class="row no-gutters slab__day">
              <span class="slab__text">{{ day }}</span>
              <app-target-progress-bar
                [selectedWeekSheet]="weeklyTripSheet"
                [day]="day"
                class="slab__week__progress"
                [slabs]="availableSlabs"
              ></app-target-progress-bar>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<div class="container-fluid">
  <div class="row">
    <div class="col-xs-12">
      <header class="title">
        <h1>404</h1>
        <h2>We can't find your page</h2>
      </header>
    </div>
  </div>
</div>

import { Injectable } from "@angular/core";

import { BasePostProvider } from "@tendercuts/http";
import { GenericServerResponse, OptimalTrip } from "@tendercuts/models";

import { HttpClient } from "@angular/common/http";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";

@Injectable()
export class EditTripService extends BasePostProvider<GenericServerResponse> {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get endpoint(): string {
    return this.endpointService.editTrip();
  }

  get model(): typeof GenericServerResponse {
    return GenericServerResponse;
  }

  getParams(trip: OptimalTrip): {
    order_ids: string[];
    trip_id: any;
} {
    const params: {
      order_ids: string[];
      trip_id: any;
  } = {
      order_ids: Array.from(trip.orders),
      trip_id: trip.tripId,
    };

    return params;
  }
}

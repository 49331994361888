import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { InventoryStagingEntry } from "@tendercuts/models";
import { BasePage } from "../../utils";

/**
 * component to get conformation before releasing inventory items
 */
@Component({
  selector: "app-inventory-before-release",
  templateUrl: "./inventory-before-release.component.html",
  styleUrls: ["./inventory-before-release.component.scss"],
})
export class InventoryBeforeReleaseComponent extends BasePage {
  selectedProducts: InventoryStagingEntry[] = [];
  @Input()
  productData: InventoryStagingEntry[] = [];

  @Output()
  release: EventEmitter<any> = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    public snacker: MatSnackBar,
    public store: Store<AppState>,
  ) {
    super(dialog, snacker, store);
  }

  /**
   * Data is pushed into selectedProducts array
   * Data lenght and selectedProducts lenght are checked
   */
  onSelectingSku(event: MatCheckboxChange, product: InventoryStagingEntry): void {
    if (event.checked) {
      this.selectedProducts.push(product);
    } else {
      this.selectedProducts.pop();
    }
  }

  // Alert shown after checkbox check status
  releaseProducts(): void {
    const successResponse: (result: any) => void = (result) => {
      if (!result) {
        return;
      }
      this.release.emit(true);
    };

    this.optionsAlert(
      "confirm",
      "Are you sure to release selected products",
      successResponse,
    );
  }
}

import { DefaultSerializationMixin, Serializable } from "./base";
import { Location } from "./location";

export class Address
  extends DefaultSerializationMixin
  implements Serializable<Address>
{
  public street: string = "";
  public city: string = "";
  public postcode: string;
  public phone: string;
  public firstname: string;
  public lastname: string;
  public telephone: string;
  public possible_stores: number[];
  public designated_store: any;
  private country_id: string = "";
  private address_id: string;
  private is_default_address: boolean = false;
  public locality: string = "";

  // driver-app
  public fax: string;
  public latitude: number;
  public longitude: number;
  //driver-app
  public email: string;
  public addressType: string;
  public region: string;
  public geohash: string;

  public eta: number;
  private o_latitude: number;
  private o_longitude: number;
  private entity_id: number;
  is_verified = 0;

  store_id: number;

  get Olatitude() {
    return this.o_latitude;
  }

  get Olongitude() {
    return this.o_longitude;
  }

  get storeId(): number {
    return this.store_id;
  }

  get latLng() {
    const position = {
      lat: this.o_latitude ? this.o_latitude : this.latitude,
      lng: this.o_longitude ? this.o_longitude : this.longitude,
    };
    return position;
  }

  get addressId() {
    return this.address_id;
  }

  get possibleStore(): number[] {
    return this.possible_stores;
  }

  get designatedStore(): any {
    return this.designated_store;
  }

  constructor() {
    super();
  }

  deserialize(input) {
    if (input == null) return this;
    this.address_id = input.address_id;
    this.entity_id = input.entity_id;
    this.city = input.city;
    this.country_id = input.country_id;
    this.fax = input.fax;
    this.firstname = input.firstname;
    this.lastname = input.lastname;
    this.street = input.street;
    this.postcode = input.postcode;
    this.telephone = input.telephone;
    this.possible_stores = input.possible_stores;
    this.designated_store = input.designated_store;
    this.latitude = input.latitude;
    this.longitude = input.longitude;
    //driver-app
    this.email = input.email;
    this.region = input.region;
    this.addressType = input.address_type;
    this.geohash = input.geohash;

    this.o_latitude = parseFloat(input.o_latitude); //parseFloat("12.9" + Math.round(Math.random() * 100000));
    this.o_longitude = parseFloat(input.o_longitude); //parseFloat("80.2" +  Math.round(Math.random() * 100000));
    this.latitude = parseFloat(input.latitude); //parseFloat("80.2" +  Math.round(Math.random() * 100000));
    this.longitude = parseFloat(input.longitude); //parseFloat("80.2" +  Math.round(Math.random() * 100000));
    this.eta = input.eta;
    this.is_verified = input.is_verified;
    this.store_id = input.store_id;
    this.is_default_address = input.is_default_address ? true : false;
    this.locality = input.locality;
    return this;
  }

  /**
   * A neat method to extract apartment from street.
   * @returns {string}
   */
  get apartment() {
    if (!this.street) {
      return "";
    }

    let components = this.street.split("\n");

    if (components.length > 0) {
      return components[0];
    }

    return "";
  }

  get isVerifiedAddress() {
    return this.is_verified == 1 ? true : false;
  }

  /**
   * A neat method to extract google area from street.
   * @returns {string}
   */
  get googleAddress() {
    if (!this.street) {
      return "";
    }

    let components = this.street.split("\n");

    if (components.length > 1) {
      return components[1];
    }

    return "";
  }

  /**
   * A neat method to extract landmark from street.
   * @returns {string}
   */
  get landmark() {
    if (!this.street) {
      return "";
    }

    let components = this.street.split("\n");

    if (components.length > 2) {
      return components[2];
    }

    return "";
  }

  get isDefaultAddress(): boolean {
    return this.is_default_address;
  }

  /**
   * Splits Google Address into parts by comma, checks for
   * CHENNAI part and returns the part before it.
   * Incase of any error, returns fallbackLandmark
   */
  get landmarkFromGoogleAddress(): string {
    const fallbackLandmark = "No Landmark";
    if (this.googleAddress == "" || this.googleAddress == undefined) {
      return fallbackLandmark;
    }
    const addressParts = this.googleAddress.split(",");
    const chennaiIndex = addressParts.findIndex((part) =>
      part.toUpperCase().includes("CHENNAI")
    );
    if (chennaiIndex === -1) {
      return fallbackLandmark;
    }
    if (chennaiIndex === 0) {
      return addressParts[0];
    }
    if (addressParts[chennaiIndex - 1] != undefined) {
      return addressParts[chennaiIndex - 1];
    }
    return fallbackLandmark;
  }

  /**
   * Returns true if designated store and geohash are set
   * to the address
   */
  get isAddressMapped() {
    if (
      this.geohash == null ||
      this.geohash == undefined ||
      this.designated_store == null ||
      this.designated_store == undefined
    ) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Checks whether lat,lng is NAN or undefined or null.
   *
   * @returns True when above condition fails.
   * @returns False when not.
   */
  get canNavigate() {
    if (isNaN(this.o_latitude) || isNaN(this.o_longitude)) {
      return false;
    }
    if (this.o_latitude == undefined || this.o_longitude == undefined) {
      return false;
    }
    return true;
  }

  get countryId() {
    return this.country_id;
  }

  get doorNo() {
    return this.fax;
  }

  get fullAddress() {
    let address = [];
    if (this.doorNo != null) {
      address.push(this.doorNo);
    }

    if (this.street != null) {
      if (this.geohash == null || this.geohash == undefined) {
        address.push(this.street);
      } else {
        address.push(this.street.split("\n")[0]);
        if (this.street.split("\n")[2]) {
          address.push(this.street.split("\n")[2]);
        }
      }
    }

    if (this.locality) {
      address.push(this.locality);
    }

    if (this.postcode != null) {
      address.push(this.postcode);
    }

    return `${address.join(", ")}.`;
  }

  get gAddress() {
    let gAddress = "";
    if (this.street != null) {
      gAddress += `${this.street},`;
    }
    return gAddress;
  }

  private get _designatedStore() {
    if (
      this.designatedStore != undefined &&
      this.designatedStore != null &&
      this.designatedStore != ""
    ) {
      let designatedStore: any[] = [this.designatedStore];
      return designatedStore;
    } else if (this.possibleStore.length > 0) {
      return this.possibleStore;
    }
    return null;
  }

  /**
   * Returns true if current store name and address`
   * designated store is same
   * @param current_store_name
   */
  isStoreServiceAble(storeId, storeName) {
    if (this._designatedStore == null) return true;
    if (
      this._designatedStore.indexOf(storeName) >= 0 ||
      this._designatedStore.indexOf(storeId) >= 0
    )
      return true;
    return false;
  }

  serialize() {
    let address = {
      address_id: this.addressId,
      city: this.city,
      country_id: this.countryId,
      fax: this.fax,
      firstname: this.firstname,
      street: this.street,
      postcode: this.postcode,
      telephone: this.telephone,
      region: this.region,
    };
    return address;
  }
}

// TODO: Remove this class, no longer needed.
export class QuoteAddress extends Address {
  private quote_address_id: number;
  private _address: Address;

  constructor() {
    super();
  }

  deserialize(input) {
    if ("quote_address_id" in input) {
      this.quote_address_id = input.quote_address_id;
      delete input.quote_address_id;
    }

    if (this._address == undefined) this._address = new Address();
    this._address = this._address.deserialize(input);

    return this;
  }

  get quoteAddressId() {
    return this.quote_address_id;
  }

  get address() {
    return this._address;
  }

  get fullAddress() {
    return this._address.fullAddress;
  }

  fromAddress(address: Address) {
    this._address = address;

    return this;
  }

  serialize() {
    let address = Object.assign(this.address);
    address["quote_address_id"] = this.quote_address_id;

    return address;
  }
}

export class CurrentLocation {
  private location: google.maps.GeocoderResult;

  constructor(location: google.maps.GeocoderResult) {
    this.location = location;
  }

  get fullAddress() {
    return this.location.formatted_address;
  }

  get pincode() {
    var components = this.location.address_components;
    var address = components[components.length - 1];

    if (address.types instanceof Array && address.types[0] == "postal_code")
      return address.long_name;
  }
}

// TODO
/* eslint-disable */
import {
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  ViewEncapsulation,
} from "@angular/core";
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";

@Component({
  selector: "notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./styles/notification.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent implements OnInit {
  @Input("message") message: any;
  @Input("text") text: any;
  @Input("icon") icon: any;
  @Input("type") type: boolean = false;
  @Input("dismissible") dismissible: boolean = false;

  baseClass: string = "notification alert";
  classes: string = "";

  /** The instance of the component making up the content of the snack bar. */
  snackBarRef: MatSnackBarRef<NotificationComponent>;

  constructor(@Optional() @Inject(MAT_SNACK_BAR_DATA) public data: any) {
    if (data) {
      this.message = data.message;
      this.icon = data.icon;
      this.type = data.type;
      this.dismissible = data.dismissible;
    }
  }

  /** Dismisses the snack bar. */
  dismiss(): void {
    this.snackBarRef.dismiss();
  }

  ngOnInit(): void {
    this.classes = this.baseClass;
    this.classes += this.dismissible ? " alert-dismissible" : "";
    this.classes += this.type ? " alert-" + this.type : "";
  }
}

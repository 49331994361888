// TODO
/* eslint-disable */
import { Component } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { LoginModalComponent } from "./templates/login/login.component";
import { RegisterModalComponent } from "./templates/register/register.component";

@Component({
  selector: "modals-page",
  templateUrl: "./modals.component.html",
  styleUrls: ["./styles/modals.scss"],
})
export class ModalsPageComponent {
  selectedOption: string;

  constructor(public dialog: MatDialog) {}

  registerModal(): void {
    const dialogRef: MatDialogRef<
      RegisterModalComponent,
      any
    > = this.dialog.open(RegisterModalComponent);
  }

  loginModal(): void {
    const dialogRef: MatDialogRef<LoginModalComponent, any> = this.dialog.open(
      LoginModalComponent,
    );
    dialogRef.afterClosed().subscribe((result) => {
      this.selectedOption = result;
    });
  }
}

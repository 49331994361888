import * as selectors from "@store/state";

import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTabGroup } from "@angular/material/tabs";
import { ActionCatalogLoad, CatalogState } from "@store/catalog";
import {
  ActionGetStores,
  ActionRestrictStores,
  StoreState,
} from "@store/store";
import {
  Category,
  Inventory,
  InventoryRequest,
  Product,
  User,
} from "@tendercuts/models";
import { map, skipWhile, take } from "rxjs/operators";
import { ProductDataSource } from "../../components";

import { Title } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import {
  FetchInventoryService,
  InventoryRequestService,
} from "@tendercuts/providers";
import { Observable } from "rxjs";
import { Filter, FilterGroup, FilterMatch, FilterModel } from "src/models";
import { BasePage } from "../../utils";
@Component({
  selector: "app-gtg-stock-request",
  templateUrl: "./gtg-stock-request.component.html",
  styleUrls: ["./gtg-stock-request.component.scss"],
})
export class GTGStockRequestComponent
  extends BasePage
  implements OnInit, AfterViewInit
{
  public user: User;

  searchFilterGroup: FilterGroup = new FilterGroup([
    new Filter("Search", null, ["name", "sku"], FilterMatch.CONTAINS),
  ]);

  modelFilter: FilterModel = new FilterModel([this.searchFilterGroup]);
  products: ProductDataSource[] = [];
  // Express or scheduled
  selectedStoreId: any;
  inventory: Inventory[];
  // flag for New Inventory update changes
  @Input() newInventoryMode: boolean = false;

  @ViewChild("tab") tabView: MatTabGroup;

  displayedColumns: string[] = ["name", "stock"];

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public inventoryRequestService: InventoryRequestService,
    private title: Title,
    public fetchInventoryService: FetchInventoryService
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    this.getUser();
    this.title.setTitle("New Inventory Update");

    this.store.dispatch(new ActionGetStores());
    this.stores.subscribe((state: StoreState) => {
      this.selectedStoreId = state.store;
    });
  }

  ngAfterViewInit(): void {
    if (this.selectedStoreId) {
      this.store.dispatch(new ActionCatalogLoad(this.selectedStoreId));
      this.refreshData();

      // TODO: HACK needs to be fixed on SERVER SIDE!!!
      const catalogLoading$: Observable<CatalogState> = this.catalogState.pipe(
        skipWhile((catalogState: CatalogState) => catalogState.loading == true),
        take(1)
      );

      catalogLoading$.subscribe(() =>
        this.store.dispatch(new ActionRestrictStores(this.selectedStoreId))
      );
    }
  }

  /*
   * Fetches inventory data for the selected store, handling loading and errors.
   */
  async fetchInventory(): Promise<void> {
    this.presentLoader();
    this.inventory = await this.fetchInventoryService
      .getData({
        store_id: this.selectedStoreId,
      })
      .toPromise()
      .catch((err) => {
        this.somethingWentWrong();
      });
    this.dismissLoader();
  }

  get stores(): Observable<StoreState> {
    return this.store.select(selectors.getStoreState);
  }

  async getUser(): Promise<void> {
    this.user = await this.store
      .select((state) => state.userState.user)
      .pipe(
        skipWhile((user) => !user),
        take(1)
      )
      .toPromise();
  }

  refreshData(): void {
    this.products = [];
    const catalog$: Observable<Category[]> = this.catalogState.pipe(
      skipWhile((state) => state.loading == true),
      map((state) => state.catalog)
    );

    catalog$.pipe(take(1)).subscribe((categories: Category[]) => {
      categories.forEach((category) => {
        // TODO: HACK needs to be fixed on SERVER SIDE!!!
        if (category.name == "Hot Deals") {
          return;
        }
        // try to find existing datasource, or create one
        const sources: ProductDataSource[] = this.products.filter(
          (source) => source.key == category.name
        );

        let dataSource: ProductDataSource;
        if (!sources || sources.length == 0) {
          dataSource = new ProductDataSource(
            this.modelFilter,
            this.fetchFilterProducts(category),
            category.name
          );
        } else {
          dataSource = sources[0];
        }

        this.products.push(dataSource);
      });
    });
    this.fetchInventory();
  }

  /**
   * In New Inventory Mode return only products have parent skus
   * @param category
   */
  fetchFilterProducts(category: Category): Product[] {
    let products: Product[] = [];
    if (!this.newInventoryMode) {
      products = category.products.filter((product) => {
        const hydStores: number[] = [31, 32, 35, 36, 40, 41, 44, 45, 46, 47];
        if (hydStores.indexOf(+this.selectedStoreId) === -1) {
          return product.parentSku == null;
        }

        return product.categoryId == 14 || product.parentSku == null;
      });
    } else {
      products = category.products.filter(
        (product) => product.parentSku !== null
      );
    }

    return products;
  }

  /**
   * Searches for orderId
   * @param orderId
   */
  applySearchFilter(searchString: string): void {
    if (searchString === "") {
      this.searchFilterGroup.filters[0].selected = false;
      this.searchFilterGroup.filters[0].value = null;
    } else {
      this.searchFilterGroup.filters[0].selected = true;
      this.searchFilterGroup.filters[0].value = searchString;
    }

    this.products[this.tabView.selectedIndex].filter = "true";
  }

  /**
   * Create a new inventory request model and commit it to server.
   * @param Product product
   */
  submitRequest(product: Product): void {
    const qty: number = this.productQuantity(product.id) ? 0.1 : 9999;

    this.selectedStore.pipe(take(1)).subscribe((store) => {
      // Inv request.
      const invRequestToday: InventoryRequest =
        new InventoryRequest().deserialize({
          product_id: product.id,
          product_name: product.name,
          sku: product.sku,
          store_id: store.storeId,
          store_name: store.code,
          type: 0,
          qty,
          gpu: product.gramsPerUnit,
        });

      const invRequestTomorrow: InventoryRequest =
        new InventoryRequest().deserialize({
          product_id: product.id,
          product_name: product.name,
          sku: product.sku,
          store_id: store.storeId,
          store_name: store.code,
          type: 1,
          qty,
          gpu: product.gramsPerUnit,
        });

      this.verifyRequests([invRequestToday, invRequestTomorrow]);
    });
  }

  /**
   * Provides a ui for approval
   */
  private verifyRequests(invRequest: InventoryRequest[]): void {
    this.presentLoader();
    this.inventoryRequestService.getData(invRequest).subscribe(
      (requestStatus) => {
        this.dismissLoader();
        this.showNotification(
          "top",
          "center",
          "success",
          "info-circle",
          "Request Successful"
        );
      },
      () => {
        this.showNotification(
          "top",
          "center",
          "success",
          "info-circle",
          "Request failed, contact tech support"
        );
        this.dismissLoader();
      },
      () => {
        //  this.refreshData();
      }
    );
  }

  /**
   * Checks if a product with the given productId is available in the inventory
   *
   * @param productId - The unique identifier of the product to check.
   * @returns {boolean} - Returns true if the product is available with a quantity
   * greater than or equal to 1 in the inventory for today; otherwise, returns false.
   */
  productQuantity(productId: number): boolean {
    const inventoryQty: Inventory = this.inventory.filter(
      (inv) => productId === inv.productId
    )?.[0];
    return inventoryQty?.today + inventoryQty?.tomorrowQty > 0;
  }
}

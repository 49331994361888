import { OrderTags } from "@tendercuts/models";
import { SaleItem } from "../sale-order";
import { RatingCategory } from "./rating-category";

export class RatingSerialiser {
  orderId: string;
  items: SaleItem[] = [];
  ratingCategory: RatingCategory[] = [];
  ratingTags: OrderTags[] = [];
  description: string;

  constructor(orderId: string) {
    this.orderId = orderId;
  }

  /**
   * 
   * @param number - index of the selected star
   * @param ratingCategory - Rating Category that need to updated
   * @returns void
   */
  setRatingStar(number: number, ratingCategory: RatingCategory) {
    if (number == ratingCategory.ratingStar) return;
    ratingCategory.ratingStar = number;
    ratingCategory.selectedTags = [];
    ratingCategory.availableTags = this.ratingTags.filter(
      (tag) => tag.ratingCategory === ratingCategory.id &&
        tag.rating == ratingCategory.ratingStar)
    this.updateRatingCategory(ratingCategory);
  }

  /**
   * Find the index of the rating category 
   * and set at the correct index
   */
  updateRatingCategory(ratingCategory) {
    const index = this.ratingCategory.findIndex((category) => category.id == ratingCategory.id)
    this.ratingCategory[index] = ratingCategory;
  }

  /**
   * 
   * @param orderTag - selected order tag
   * @param ratingCategory - Rating Category that need to updated
   */
  selectedRatingTag(orderTag: OrderTags, ratingCategory: RatingCategory) {
    const index = ratingCategory.selectedTags.findIndex(tag => tag == orderTag);
    if (index == -1) {
      ratingCategory.selectedTags.push(orderTag);
    } else {
      ratingCategory.selectedTags.splice(index, 1)
    }
    this.updateRatingCategory(ratingCategory);
  }

  /**
   * check every rating category is valid or not
   * and 
   * @returns boolean
   */
  get isValid(): boolean {
    return this.ratingCategory.every((category) => category.isValid)
  }

  /**
   * @returns - serialized server request format JSON value
   */
  serialize() {
    let params = { 'increment_id': this.orderId };
    let selectedRatingTagsId: number[] = [];
    this.ratingCategory.forEach((category: RatingCategory) => {
      selectedRatingTagsId = selectedRatingTagsId.concat(...category.selectedTags.map(tag => tag.id));
      params[category.linkedField] = category.ratingStar;
    });
    params['rating_tag'] = selectedRatingTagsId;
    params['items'] = this.items.map((item: SaleItem) => item.productId);
    params['comments'] = this.description;

    return params
  }
}
<h6>Inventory Debug</h6>
<div class="inventory">
  <form [formGroup]="inventoryForm">
    <mat-card class="inventory__card col-12">
      <mat-card-title class="inventory__card__title">
        <div class="col-6 inventory__card__title__search-wrapper">
          <div class="inventory__card__title__search-wrapper__icon">
            <input
              class="inventory__card__title__search-wrapper__icon__search-bar"
              placeholder="Enter the Product Name"
              (keyup)="searchProduct($event.target.value)"
              (focus)="searchIsOpen = true"
              (blur)="searchFieldBlur()"
              formControlName="displayProduct"
            />
            <i _ngcontent-c12="" class="material-icons">search</i>
          </div>

          <div
            class="inventory__card__title__search-wrapper__menu"
            *ngIf="searchIsOpen"
          >
            <div
              class="inventory__card__title__search-wrapper__menu__item"
              *ngFor="let product of searchedProducts"
              (click)="onSelectProduct(product)"
            >
              {{ product.name }}
            </div>
            <div
              class="inventory__card__title__search-wrapper__menu__item"
              *ngIf="!searchedProducts.length"
            >
              No matching products found
            </div>
          </div>
        </div>

        <mat-form-field class="inventory__card__title__date-picker col-3">
          <mat-label>Choose a date</mat-label>
          <input
            matInput
            [max]="todayDate"
            formControlName="selectedDate"
            [matDatepicker]="picker"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <button
          class="col-3"
          mat-stroked-button
          id="search-inventory"
          (click)="searchInventory()"
        >
          Search Inventory
        </button>
      </mat-card-title>
    </mat-card>
    <br />
    <mat-card *ngIf="gramInventory" class="inventory__gram">
      <mat-card-content class="inventory__gram__content">
        <div class="row no-gutters">
          <span class="inventory__gram__header"> Gram Inventory </span>
          <span class="inventory__gram__attr">
            Opening
            <span class="inventory__gram__attr__value">
              {{ gramInventory.opening }}
            </span>
          </span>
          <span class="inventory__gram__attr">
            Qty
            <span class="inventory__gram__attr__value">
              {{ gramInventory.qty }}</span
            >
          </span>
          <span class="inventory__gram__attr">
            Expering Today
            <span class="inventory__gram__attr__value">
              {{ gramInventory.expiringToday }}
            </span>
          </span>
          <span class="inventory__gram__attr">
            Forecast Qty
            <span class="inventory__gram__attr__value">
              {{ gramInventory.forecastQty }}
            </span>
          </span>
        </div>
      </mat-card-content>
    </mat-card>
    <div class="inventory__states row no-gutters">
      <mat-card
        *ngIf="inventoryPurchase.length"
        class="inventory__states__in_ward"
      >
        <mat-card-title class="inventory__states__title">
          Inventory In Ward Sales (weight: {{ totalPurchaseWeight }})
        </mat-card-title>
        <mat-card-content>
          <app-inventory-log-display-tab
            [footerColumnsToDisplay]="footerColumnsToDisplay"
            [columnsToDisplay]="columnsToDisplay"
            [titleToDisplay]="titleToDisplay"
            [dataSource]="inventoryPurchseDataSource"
          >
          </app-inventory-log-display-tab>
        </mat-card-content>
      </mat-card>
      <mat-card
        *ngIf="inventorySale.length"
        class="inventory__states__out_ward"
      >
        <mat-card-title class="inventory__states__title">
          Inventory Out Ward Sales (weight: {{ totalSaleWeight }})
        </mat-card-title>
        <mat-card-content>
          <app-inventory-log-display-tab
            [footerColumnsToDisplay]="saleItmFooterColumnsToDisplay"
            [columnsToDisplay]="saleItemColumnsToDisplay"
            [titleToDisplay]="saleItemTitleToDisplay"
            [dataSource]="inventorySaleDataSource"
          >
          </app-inventory-log-display-tab>
        </mat-card-content>
      </mat-card>
    </div>

    <div *ngIf="inventoryDebugDataSource.data.length; else noInventory">
      <mat-card class="inventory__logs">
        <mat-card-title class="inventory__logs__title">
          Inventory Logs
        </mat-card-title>
        <mat-card-content class="inventory__logs__content">
          <app-inventory-log-display-tab
            [dataSource]="inventoryDebugDataSource"
          >
          </app-inventory-log-display-tab>
        </mat-card-content>
      </mat-card>
    </div>
    <ng-template #noInventory>
      <div class="inventory__no-date">
        No Inventory found in this date. Please try some other date
      </div>
    </ng-template>
  </form>
</div>

import { Serializable } from './base';

export class PaymentDriver implements Serializable<PaymentDriver> {
    public method: string;

    constructor() { }

    deserialize(input) {
        this.method = input.method;
        return this;
    }
}

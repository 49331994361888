<mat-progress-bar *ngIf="loading" mode="indeterminate"> </mat-progress-bar>
<i class="menu-item-icon fa fa-window-close-o" (click)="dialogClose()"></i>
<div class="new-ticket">
  <form [formGroup]="ticketForm">
    <section>
      <p class="form-title">Ticket Details</p>
      <div class="row">
        <mat-form-field class="col-6">
          <mat-select placeholder="Ticket Status" formControlName="status">
            <mat-option *ngFor="let status of ticketStatus" [value]="status">{{
              status
            }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="col-6">
          <mat-select placeholder="Call Type" formControlName="type">
            <mat-option *ngFor="let type of freshDeskTypes" [value]="type">{{
              type
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </section>

    <mat-divider></mat-divider>
    <section>
      <p class="form-title">Order Details</p>
      <div class="row">
        <mat-form-field class="col-6">
          <mat-select
            (selectionChange)="updateOrder($event)"
            placeholder="Order Id"
          >
            <mat-option *ngFor="let order of orders" [value]="order">
              {{ order.incrementId }} -
              {{ order.total | currency: "INR":"symbol":"1.0" }}
              ({{ order.createdAt | date: "d - MMM" }})
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="col-6" *ngIf="selectedOrder?.items">
          <mat-select placeholder="Product Name" formControlName="cf_sku">
            <mat-option
              *ngFor="let item of selectedOrder.items"
              [value]="item.SKU"
            >
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="row" *ngIf="selectedOrder?.items">
        <header class="col-6 block__header">
          Slot
          <p class="block__para">
            {{ this.ticketForm.controls.cf_slots.value }}
          </p>
        </header>

        <header
          class="col-6 block__header"
          *ngIf="selectedOrder?.items && deliveredDate !== ''"
        >
          Delivered Date
          <p class="block__para">
            {{
              ticketForm.controls.cf_delivered_date.value
                | date: "MMM d, y, h:mm:ss a"
            }}
          </p>
        </header>
      </div>
    </section>

    <mat-divider></mat-divider>

    <section>
      <p class="form-title">Ticket Description</p>
      <div class="row">
        <mat-form-field class="col-6">
          <input
            matInput
            placeholder="Subject"
            formControlName="subject"
            autocomplete="off"
          />
        </mat-form-field>

        <mat-form-field class="col-6">
          <textarea
            matInput
            placeholder="Description"
            formControlName="description"
            autocomplete="off"
          ></textarea>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field class="col-6" hidden>
          <textarea
            matInput
            placeholder="VOC"
            autocomplete="off"
          ></textarea>
        </mat-form-field>

        <mat-form-field class="col-6">
          <textarea
            matInput
            placeholder="RCA"
            formControlName="cf_rca_of_complaint"
            autocomplete="off"
          ></textarea>
        </mat-form-field>

        <div class="col-6">
          <label>File Upload</label><br />
          <input
            #file
            type="file"
            id="file"
            (change)="uploadFile($event)"
          /><br />
          <div class="error-message">{{ fileErrorMessage }}</div>
        </div>
      </div>

      <div class="row">
        <mat-form-field class="col-6">
          <input
            matInput
            placeholder="Complaint Date"
            formControlName="cf_complaint_date"
            [matDatepicker]="picker"
            [max]="todayDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field class="col-6" hidden>
          <textarea
            matInput
            placeholder="ButcherName"
            autocomplete="off"
          ></textarea>
        </mat-form-field>

        <mat-form-field class="col-6" hidden>
          <input
            matInput
            placeholder="Age of Stock"
            type="number"
          />
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field class="col-6" hidden>
          <mat-select
            placeholder="Complaint Status"
          >
            <mat-option
              *ngFor="let complaint of complaintStatus"
              [value]="complaint"
              >{{ complaint }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </section>
    <mat-divider></mat-divider>

    <section>
      <div class="form-title">Action Taken</div>
      <div class="row action">
        <mat-form-field class="col-6" hidden>
          <input
            matInput
            placeholder="Cashback Given"
            autocomplete="off"
          />
        </mat-form-field>
        <mat-form-field class="col-6">
          <mat-select placeholder="Disposition" formControlName="cf_disposition">
            <mat-option *ngFor="let dis of dispositions" [value]="dis">{{
              dis
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <!-- <div class="col-6">
          <label>File Upload</label><br>
          <input
            #file
            type="file"
            formControlName="helpdesk_file"
            id="file"
            (change)="uploadFile($event)"
          />
        </div> -->
      </div>
    </section>
    <mat-divider></mat-divider>

    <section class="actions">
      <button
        mat-raised-button
        type="cancel"
        color="primary"
        (click)="dialogClose()"
      >
        Dismiss
      </button>
      <button
        id="create-ticket-submit"
        mat-raised-button
        type="submit"
        color="primary"
        (click)="validateTicketForm()"
      >
        Save
      </button>
    </section>
  </form>
</div>

import { Injectable } from '@angular/core';

import { BaseGetProvider } from '@tendercuts/http';
import { FreshdeskTicket } from '@tendercuts/models';

import { HttpClient, HttpParams } from '@angular/common/http';
import { EndPoint } from '@endpoint';
import { LoginServices } from '@login';

@Injectable()
export class FreshdeskTicketsService extends BaseGetProvider<FreshdeskTicket> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof FreshdeskTicket {
    return FreshdeskTicket;
  }

  get endpoint(): string {
    return this.endpointService.freshDeskTickets();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(email: string): HttpParams {
    let params: HttpParams = new HttpParams();
    params = params.append('email', email);

    return params;
  }
}

import { Action } from "@ngrx/store";
import { GenericError, GenericServerResponse } from "@tendercuts/models";

export class ActionTrySignup implements Action {
  readonly type: string = "ACTION_SIGNUP_TRY";
  constructor(
    public payload: {
      firstname: string;
      email: string;
      mobilenumber: string;
      password: string ;
    },
  ) {}
}

export class ActionSignupFail implements Action {
  readonly type: string = "ACTION_SIGNUP_FAIL";
  constructor(public payload: GenericError) {}
}

export class ActionSignupSuccess implements Action {
  readonly type: string = "ACTION_SIGNUP_SUCCESS";
  constructor(public payload: GenericServerResponse[]) {}
}

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";

@Component({
  selector: "app-map-draw-tools",
  templateUrl: "./map-draw-tools.component.html",
  styleUrls: ["./map-draw-tools.component.scss"],
})
export class MapDrawToolsComponent implements OnInit, AfterViewInit {
  drawingManager: google.maps.drawing.DrawingManager;

  // Map ref
  @Input() map: google.maps.Map;
  // TODO
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onDrawComplete: EventEmitter<any> = new EventEmitter();
  shape: any = null;

  constructor() {}

  ngOnInit(): void {
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      // markerOptions: baseOptions,
      rectangleOptions: {
        strokeWeight: 0,
        fillOpacity: 0.45,
      },
      polygonOptions: {
        strokeColor: "#c11c2d",
        fillColor: "#c11c2d",
      },
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
      },
    });
    // collect all shapes drawn to merge into map
    google.maps.event.addListener(
      this.drawingManager,
      "overlaycomplete",
      (event) => {
        if (this.shape) {
          this.shape.setMap(null);
        }

        this.shape = event.overlay;
        this.shape.type = event.type;
        const pathObj: any = this.shape.getPath();
        let path: string = "";
        for (let i: number = 0; i < pathObj.getLength(); i++) {
          path += pathObj.getAt(i).lat();
          path += ",";
          path += pathObj.getAt(i).lng();
          if (i != pathObj.getLength() - 1) {
            path += ",";
          }
        }

        this.onDrawComplete.emit(path);
      },
    );
  }

  ngAfterViewInit(): void {
    // throw new Error("Method not implemented.");
    this.drawingManager.setMap(this.map);
  }
}

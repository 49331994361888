<div class="create-driver col-12">
  <div class="card">
    <header class="title">
      <div class="col-xs-12">
        <h5 class="title" *ngIf="!isUpdatableMode; else showUpdate">
          Create Driver Form
        </h5>
        <ng-template #showUpdate>
          <h5 class="title">Update Driver Form</h5>
        </ng-template>
      </div>
    </header>
    <br />
    <form class="driveronboarding" [formGroup]="driverOnboardingForm">
      <div *ngIf="!isUpdatableMode">
        <mat-form-field>
          <input
            matInput
            type="text"
            formControlName="userName"
            id="user_name"
            placeholder="Full Name"
            required
          />
        </mat-form-field>
      </div>

      <div *ngIf="!isUpdatableMode">
        <mat-form-field class="field-right">
          <input
            matInput
            type="tel"
            minlength="10"
            maxlength="10"
            formControlName="phoneNumber"
            id="phone_number"
            placeholder="Mobile Number"
            required
          />
        </mat-form-field>
      </div>

      <div *ngIf="!isUpdatableMode">
        <mat-form-field>
          <input
            matInput
            type="text"
            formControlName="email"
            id="email"
            placeholder="E-mail"
            required
          />
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="field-right">
          <input
            matInput
            type="text"
            minlength="6"
            formControlName="accountNumber"
            id="account_number"
            placeholder="Account Number"
            required
          />
        </mat-form-field>
      </div>

      <div>
        <mat-form-field>
          <input
            matInput
            type="text"
            formControlName="ifscCode"
            id="ifsc_code"
            placeholder="IFSC Code"
            required
          />
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="field-right">
          <input
            matInput
            type="text"
            formControlName="pan"
            id="pan"
            placeholder="Enter PAN"
            minlength="10"
            required
          />
        </mat-form-field>
      </div>

      <div *ngIf="!isUpdatableMode" class="account-type">
        <mat-form-field>
          <mat-label>Choose Proof Type *</mat-label>
          <mat-select formControlName="proofType">
            <mat-option value="aadhar">Aadhar</mat-option>
            <mat-option value="voterid">Voter Id</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="!isUpdatableMode">
        <mat-form-field class="field-right">
          <input
            matInput
            type="text"
            formControlName="proofId"
            id="proof_id"
            placeholder="Driver's Proof Id (Aadhar-No)"
            required
          />
        </mat-form-field>
      </div>

      <div class="form-group">
        <mat-form-field>
          <mat-label>Earnings Scheme *</mat-label>
          <mat-select formControlName="earningScheme">
            <mat-option 
              *ngFor="let scheme of availableSchemes" 
              [value]="scheme.value"
            >
              {{scheme.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="isUpdatableMode">
        <mat-form-field class="form-group field-right">
          <mat-label>Preferred Language *</mat-label>
          <mat-select formControlName="preferredLanguage" multiple>
            <mat-option
              *ngFor="let languageData of availableLanguage"
              [value]="languageData.id"
              >{{ languageData.language }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-group proof-image" *ngIf="!isUpdatableMode">
        <label for="proofImage">Driver Proof Image *</label>
        <input
          #myInputProof
          type="file"
          id="proof_image"
          class="form-control"
          (change)="onProofImageSelected($event, true)"
        />
      </div>

      <div class="form-group profile-image"
      [ngClass]="{
        'driver-image': isUpdatableMode
      }">
        <label for="profileImage">Driver Image *</label>
        <input
          #myInputProfile
          type="file"
          id="profile_image"
          class="form-control"
          (change)="onProfileImageSelected($event, true)"
        />
      </div>

      <div *ngIf="!isUpdatableMode">
        <mat-form-field>
          <mat-label>Select Work Location *</mat-label>
          <mat-select formControlName="workLocation">
            <mat-option
              *ngFor="let store of availableStores"
              value="{{ store.storeId }}"
            >
              {{ store.storeAttribute?.displayName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="!isUpdatableMode">
        <mat-form-field class="form-group field-right">
          <mat-label>Preferred Language *</mat-label>
          <mat-select formControlName="preferredLanguage" multiple>
            <mat-option
              *ngFor="let languageData of availableLanguage"
              [value]="languageData.id"
              >{{ languageData.language }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <div class="weekend-rider field-right" *ngIf="!isUpdatableMode">
        <mat-checkbox formControlName="weekendRider">
          Is Weekend Rider
        </mat-checkbox>
      </div>

      <div class="action-btns">
        <button mat-raised-button color="primary" type="reset" id="login-reset">
          Reset Form
        </button>
        <button
          *ngIf="!isUpdatableMode; else showUpdateBtn"
          mat-raised-button
          color="primary"
          type="submit"
          (click)="validateSignUp()"
          id="login-submit"
        >
          Create Driver
        </button>
        <ng-template #showUpdateBtn>
          <button
            mat-raised-button
            color="primary"
            id="update-detail"
            (click)="updateRiderDetail()"
          >
            Update Driver
          </button>
        </ng-template>
      </div>
    </form>
  </div>
</div>

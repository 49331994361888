import { AgmCoreModule, GoogleMapsAPIWrapper } from "@agm/core";
import { AgmJsMarkerClustererModule } from "@agm/js-marker-clusterer";
import { AgmSnazzyInfoWindowModule } from "@agm/snazzy-info-window";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import {
  AddressMarkerComponent,
  DriversStatusComponent,
  DriverStatusGridComponent,
  LocationPingMarkerComponent,
  OrderIdDisplayComponent,
  StoreMarkerComponent,
  TripDirectionComponent,
} from "./components";
import { MapTripComponent } from "./components/map-trip/map-trip.component";
import {
  OrderIdSequenceComponent,
} from "./components/order-id-sequence/order-id-sequence.component";
import {
  StoreSegmentFilterComponent,
} from "./components/store-segment-filter/store-segment-filter.component";
import { StoreSegmentComponent } from "./components/store-segment/store-segment.component";
import { AgmDirection } from "./components/trip-direction/agm-direction";
import { SharedModule } from "./shared/shared.module";
import { UtilsModule } from "./utils/utils.module";

@NgModule({
  declarations: [
    StoreSegmentComponent,
    StoreSegmentFilterComponent,
    OrderIdDisplayComponent,
    OrderIdSequenceComponent,
    TripDirectionComponent,
    AddressMarkerComponent,
    StoreMarkerComponent,
    LocationPingMarkerComponent,
    AgmDirection,
    MapTripComponent,
    DriversStatusComponent,
    DriverStatusGridComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    UtilsModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCA3ckOmpQPiyd8VHMVCieVdD2Brf8nMa0",
    }),
    AgmJsMarkerClustererModule,
    AgmSnazzyInfoWindowModule,
  ],
  providers: [GoogleMapsAPIWrapper],

  exports: [
    StoreSegmentComponent,
    StoreSegmentFilterComponent,
    SharedModule,
    AgmCoreModule,
    AgmJsMarkerClustererModule,
    AgmSnazzyInfoWindowModule,
    OrderIdDisplayComponent,
    StoreMarkerComponent,
    TripDirectionComponent,
    LocationPingMarkerComponent,
    AddressMarkerComponent,
    AgmDirection,
    OrderIdSequenceComponent,
    MapTripComponent,
    DriversStatusComponent,
    DriverStatusGridComponent
  ],
})
export class CustomCommonModule {}

import { Routes } from "@angular/router";
import {
  AuthGuardService,
  DeviceGuardService,
  FlockGuardService,
  RoleGuardService,
} from "@tendercuts/providers";
import { ScanDashboardComponent } from "./barcode-scan/barcode-scan.component";
import { HyperTrackComponent } from "./components/hypertrack-component/hypertrack-component";
import { Error404PageComponent, Error404PageResolver } from "./core";
import {
  DriverDashboardComponent,
  TripSheetUpdateComponent,
  WeeklyDriversDashboardComponent,
  WeeklyTripsheetUpdateComponent,
} from "./modules/trip-earning-module";
import { TripSheetDetailsComponent } from "./modules/trip-earning-module/tripsheet-details";
import {
  TripsSearchComponent,
  TripDashboardComponent,
} from "./modules/trip-module";
import {
  DashBoardComponent,
  InventoryLogComponent,
  LoginComponent,
  NewInventoryReleaseComponent,
  OrderLocationComponent,
  RoutingDashboardComponent,
  SalesDashBoardComponent,
  StockApprovalComponent,
  StockRequestComponent,
} from "./pages";
import { BookingStatusComponent } from "./pages/booking-status";
import { CustomerDashboardComponent } from "./pages/customer-dashboard/customer-dashboard.component";
import { DriverCrmComponent } from "./pages/driver-crm";
import { HistoricSalesComponent } from "./pages/historic-sales/historic-sales.component";
import { InventoryAdjustmentComponent } from "./pages/inventory-adjustment/inventory-adjustment.component";
import { InventoryUpdateComponent } from "./pages/inventory-update/inventory-update.component";
import { LiveTrackDebugComponent } from "./pages/live-track-debug/live-track-debug.component";
import { StockDashboardComponent } from "./pages/stock-dashboard/stock-dashboard.component";
import { StoresCoverageComponent } from "./pages/stores-coverage/stores-coverage.component";
import { TripsHistoryComponent } from "./pages/trips-history/trips-history.component";
import { AllOrdersComponent } from "./pages/all-orders/all-orders.component";

export const rootRoutes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "", redirectTo: "dashboard", pathMatch: "full" },
  {
    path: "dashboard",
    component: DashBoardComponent,
    data: { breadcrumb: "" },
    children: [
      {
        path: "",
        component: SalesDashBoardComponent,
        canActivate: [RoleGuardService],
        data: { breadcrumb: "Live Orders", role: ["Store Manager"] },
      },
      {
        path: "all-orders",
        component: AllOrdersComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { breadcrumb: "Order History", role: ["City Manager"] },
      },
      {
        path: "routing",
        component: RoutingDashboardComponent,
        canActivate: [RoleGuardService],
        data: { breadcrumb: "Plan Trip", role: ["Store Manager"] },
      },
      {
        path: "new-inventory-update",
        component: NewInventoryReleaseComponent,
        canActivate: [RoleGuardService],
        data: {
          breadcrumb: "New Inventory update",
          role: ["Inventory Manager", "Store Manager"],
        },
      },
      {
        path: "geohash",
        loadChildren: () =>
          import(
            "./modules/address-correction-module/address-correction-module"
          ).then((m) => m.AddressCorrectionModule),
        canActivate: [RoleGuardService],
        data: { role: ["Call Center"] },
      },
      {
        path: "generate-trip-sheet",
        component: DriverDashboardComponent,
        canActivate: [RoleGuardService],
        data: { breadcrumb: "Generate Trip Sheet", role: ["Store Manager"] },
      },
      {
        path: "generate-trip-sheet/:date",
        component: DriverDashboardComponent,
        canActivate: [RoleGuardService],
        data: { breadcrumb: "Generate Trip Sheet", role: ["Store Manager"] },
      },
      {
        path: "weekly-tripsheet",
        component: WeeklyDriversDashboardComponent,
        canActivate: [RoleGuardService],
        data: { breadcrumb: "Weekly Trip Sheet", role: ["Store Manager"] },
      },
      {
        path: "weekly-tripsheet/:selectedWeek",
        component: WeeklyDriversDashboardComponent,
        canActivate: [RoleGuardService],
        data: { breadcrumb: "Weekly Trip Sheet", role: ["Store Manager"] },
      },
      {
        path: "trip-sheet-approve",
        component: TripSheetUpdateComponent,
        canActivate: [RoleGuardService],
        data: { breadcrumb: "Trip Sheet Approve", role: ["Delivery Manager"] },
      },
      {
        path: "trip-sheet-approve/:selectedDate",
        component: TripSheetUpdateComponent,
        canActivate: [RoleGuardService],
        data: { breadcrumb: "Trip Sheet Approve", role: ["Delivery Manager"] },
      },
      {
        path: "tripsheet-detail",
        component: TripSheetDetailsComponent,
        canActivate: [RoleGuardService],
        data: { breadcrumb: "Trip Sheet Detail", role: ["Store Manager"] },
      },

      {
        path: "tripsheet-detail/:selectedDate/:selectedRider",
        component: TripSheetDetailsComponent,
        canActivate: [RoleGuardService],
        data: { breadcrumb: "Trip Sheet Detail", role: ["Store Manager"] },
      },
      {
        path: "weekly-tripsheet-approve",
        component: WeeklyTripsheetUpdateComponent,
        canActivate: [RoleGuardService],
        data: {
          breadcrumb: "Weekly Trip Sheet Approve",
          role: ["Delivery Manager"],
        },
      },
      {
        path: "weekly-tripsheet-approve/:selectedWeek",
        component: WeeklyTripsheetUpdateComponent,
        canActivate: [RoleGuardService],
        data: {
          breadcrumb: "Weekly Trip Sheet Approve",
          role: ["Delivery Manager"],
        },
      },
      {
        path: "customer",
        component: CustomerDashboardComponent,
        canActivate: [RoleGuardService],
        data: { breadcrumb: "Customer", role: ["Call Center"] },
      },
      {
        path: "crm/:phone",
        component: CustomerDashboardComponent,
        data: { breadcrumb: "CRM", role: ["Call Center"] },
      },
      {
        path: "crm",
        component: CustomerDashboardComponent,
        data: { breadcrumb: "CRM", role: ["Call Center"] },
      },
      {
        path: "customer/:phone",
        component: CustomerDashboardComponent,
        data: { breadcrumb: "Customer", role: ["Call Center"] },
      },
      {
        path: "stock",
        component: StockDashboardComponent,
        canActivate: [RoleGuardService],
        data: { breadcrumb: "Stock Requests", role: ["Store Manager"] },
      },
      {
        path: "stores-coverage",
        component: StoresCoverageComponent,
        canActivate: [RoleGuardService],
        data: { breadcrumb: "Stores Coverage", role: ["Store Manager"] },
      },
      {
        path: "historic-sales",
        component: HistoricSalesComponent,
        canActivate: [RoleGuardService],
        data: { breadcrumb: "Historic Sales", role: ["Store Manager"] },
      },
      {
        path: "order-detail",
        component: OrderLocationComponent,
        canActivate: [RoleGuardService],
        data: {
          breadcrumb: "Order Detail",
          role: ["Store Manager", "Call Center"],
        },
      },
      {
        path: "order-detail/:order_id",
        component: OrderLocationComponent,
        canActivate: [RoleGuardService],
        data: {
          breadcrumb: "Order Detail",
          role: ["Store Manager", "Call Center"],
        },
      },
      {
        path: "trip-detail",
        component: TripsSearchComponent,
        canActivate: [RoleGuardService],
        data: {
          breadcrumb: "Trip Detail",
          role: ["Store Manager", "Call Center"],
        },
      },
      {
        path: "trip-detail/:tripId",
        component: TripsSearchComponent,
        canActivate: [RoleGuardService],
        data: {
          breadcrumb: "Trip Detail",
          role: ["Store Manager", "Call Center"],
        },
      },
      {
        path: "booking-status",
        component: BookingStatusComponent,
        canActivate: [RoleGuardService],
        data: {
          breadcrumb: "Booking Status",
          role: ["Store Manager"],
        },
      },
      {
        path: "live-trips",
        component: TripDashboardComponent,
        canActivate: [RoleGuardService],
        data: {
          breadcrumb: "Live-Trips",
          role: ["Store Manager", "Call Center"],
        },
      },
      {
        path: "scan",
        component: ScanDashboardComponent,
        canActivate: [RoleGuardService, DeviceGuardService],
        data: { breadcrumb: "Scan Orders", role: ["Store Manager"] },
      },
      {
        path: "scan/:order_id",
        component: ScanDashboardComponent,
        canActivate: [RoleGuardService, DeviceGuardService],
        data: { breadcrumb: "Scan Orders", role: ["Store Manager"] },
      },
      {
        path: "scan/:trip_id/:order_id",
        component: ScanDashboardComponent,
        canActivate: [RoleGuardService, DeviceGuardService],
        data: { breadcrumb: "Scan Orders", role: ["Store Manager"] },
      },
      {
        path: "driver-crm",
        component: DriverCrmComponent,
        data: { breadcrumb: "Driver Crm" },
      },
      {
        path: "driver-crm/:phoneNumber",
        component: DriverCrmComponent,
        data: { breadcrumb: "Driver Crm" },
      },
      {
        path: "inv-adjustment",
        component: InventoryAdjustmentComponent,
        data: { breadcrumb: "Inventory Update", role: ["Store Manager"] },
      },
      {
        path: "inv-update",
        component: InventoryUpdateComponent,
        data: {
          breadcrumb: "Inventory Bulk Update",
          role: ["Inventory Manager"],
        },
      },
      {
        path: "inv-debug",
        component: InventoryLogComponent,
        data: {
          breadcrumb: "Inventory Debug",
          role: ["Inventory Manager"],
        },
      },
      {
        path: "stock-request-web",
        component: StockRequestComponent,
        data: {
          breadcrumb: "Old Inventory Update",
          role: ["Call Center Manager"],
        },
      },
      {
        path: "hyper-track",
        component: HyperTrackComponent,
        canActivate: [AuthGuardService],
        data: { breadcrumb: "Hyper Track" },
      },
      {
        path: "trips-history",
        component: TripsHistoryComponent,
      },
    ],
    canActivate: [AuthGuardService],
  },
  {
    path: "stock-request",
    component: StockRequestComponent,
    canActivate: [FlockGuardService],
    data: { breadcrumb: "Stock Request" },
  },
  {
    path: "stock-approval",
    component: StockApprovalComponent,
    canActivate: [FlockGuardService],
    data: { breadcrumb: "Stock Approval" },
  },
  {
    path: "live-track",
    component: LiveTrackDebugComponent,
    canActivate: [AuthGuardService],
    data: { breadcrumb: "Live Track" },
  },
  {
    path: "404",
    component: Error404PageComponent,
    resolve: { data: Error404PageResolver },
  },
  {
    // There's a bug that's preventing wild card routes to be
    //  lazy loaded (see: https://github.com/angular/angular/issues/13848)
    // That's why the Error page should be eagerly loaded
    path: "**",
    component: Error404PageComponent,
    resolve: { data: Error404PageResolver },
  },
];

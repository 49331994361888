import { Serializable } from './base';

export class DriverStats implements Serializable<DriverStats> {
    public noOfCompletedOrders: number;
    public noOfPendingOrders: number;

    constructor() { }

    deserialize(input) {
        this.noOfCompletedOrders = input.no_of_orders;
        //this.noOfPendingOrders = input.no_of_pending_orders;
        return this;
    }
}
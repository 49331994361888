import { Serializable } from "./base";
import { DriverProfile } from "./driver-profile";

export class DriverLoginLogout implements Serializable<DriverLoginLogout> {
  private check_in: number;
  private check_out: number;
  private store_id: number;
  private trip_sheet_status: number;
  public date: number;
  public driver: DriverProfile;
  private check_in_value: number;
  private check_out_value: number;
  private weekly_sheet_status: number;
  private earning_date: string;

  get checkInValue() {
    return this.check_in_value;
  }

  get checkOutValue() {
    return this.check_out_value;
  }
  
  get phoneNumber() {
    return this.driver.phoneNumber;
  }

  get firstName() {
    return this.driver.firstName;
  }

  get driverId() {
    return this.driver.driverId;
  }

  get checkIn() {
    return new Date(this.date + "T" + this.check_in).toLocaleTimeString();
  }

  get checkOut() {
    return this.check_out
      ? new Date(this.date + "T" + this.check_out).toLocaleTimeString()
      : "Not Yet Logged Out";
  }

  get isRiderLogout() {
    return this.check_out ? true : false;
  }

  get tripSheetStatus() {
    switch (this.trip_sheet_status) {
      case -1:
        return "Not generated";

      case 0:
        return "Pending";

      case 1:
        return "To Approve";

      case 2:
        return "Confirm";

      default:
        return "Not generated";
    }
  }

  get weeklySheetStatus() {
    switch (this.weekly_sheet_status) {
      case -1:
        return "Not generated";

      case 0:
        return "Pending";

      case 1:
        return "To Approve";

      case 2:
        return "Confirm";

      default:
        return "Not generated";
    }
  }

  get earningDate() {
    return this.earning_date;
  }

  get isTripSheetConfirm() {
    return this.trip_sheet_status > 0;
  }

  deserialize(input) {
    this.check_in = input.check_in;
    this.check_out = input.check_out;
    this.date = input.date;
    this.store_id = input.store_id;
    this.trip_sheet_status = input.trip_sheet_status;
    this.check_in_value = input.check_in_value;
    this.check_out_value = input.check_out_value;
    this.weekly_sheet_status = input.weekly_sheet_status;
    this.earning_date = input.earning_date;

    if (input.driver) {
      this.driver = new DriverProfile().deserialize(input.driver);
    }

    return this;
  }
}

import { Injectable } from "@angular/core";
import { Category } from "@tendercuts/models";
import { of, Observable } from "rxjs";
import { ProductDataService } from "./service";

@Injectable()
export class MockProductDataService extends ProductDataService {
  public data: any;
  private prodData: any = [
    {
      category: {
        image: "Chicken_1.png",
        is_active: 1,
        name: "Chicken",
        entity_id: 4,
      },
      products: [
        {
          price: "115.0000",
          special_price: "110.000",
          short_description: "10 -  12  Pieces",
          description: "",
          sku: "CHK_WHL_SKIN_ON",
          small_image: "/w/h/wholechk_skinon.png",
          thumbnail: "/w/h/wholechk_skinon.png",
          weight_description: "500",
          gross_weight_description: "500",
          visibility: 4,
          status: 1,
          uom: 72,
          name: "Chicken Curry Cut (Skin On)",
          entity_id: 192,
        },
        {
          price: "129.0000",
          short_description: "10 - 12  Pieces ",
          description: "",
          sku: "CHK_WHL_SKIN_OFF",
          small_image: "/w/h/wholechk_skinless.png",
          thumbnail: "/w/h/wholechk_skinless.png",
          weight_description: "500",
          gross_weight_description: "500",
          visibility: 4,
          status: 1,
          uom: 72,
          name: "Chicken Curry Cut (Skin Off)",
          entity_id: 193,
        },
      ],
    },
    {
      category: {
        image: "Mutton_1.png",
        is_active: 1,
        name: "Goat",
        entity_id: 5,
      },
      products: [
        {
          price: "374.0000",
          short_description: " 14 – 16 Pieces",
          description: "",
          sku: "GT_FREE_RANGE_GOAT_SHOULDER_MEAT",
          small_image: "/p/r/premium_shoulder-1.png",
          thumbnail: "/p/r/premium_shoulder-1.png",
          weight_description: "500",
          gross_weight_description: "500",
          visibility: 4,
          status: 1,
          uom: 72,
          name: "Free Range Goat Shoulder Meat",
          entity_id: 303,
        },
      ],
    },
  ];

  public get cache(): any {
    return this.data;
  }

  public getData(): Observable<Category[]> {
    const category: Category[] = this.prodData.map((data) => new Category().deserialize(data));

    this.data = category;

    return of(category);
  }
}

import { Injectable } from "@angular/core";
import { Actions, createEffect } from "@ngrx/effects";
import { ofAction } from "ngrx-actions";
import { map, switchMap } from "rxjs/operators";

import { User } from "@tendercuts/models";
import { SMStorageService } from "@tendercuts/providers";

import { of, Observable } from "rxjs";
import {
  ActionLoginClearError,
  ActionLoginSuccess,
  ActionLogoutUser,
} from "../login/login-actions";
import { ActionLoadUser, ActionSetUser } from "../storage/storage-actions";

@Injectable()
export class UserStorageEffects {
  KEY: string = "user22";
  constructor(private actions$: Actions, private storage: SMStorageService) {}

  /**
   * Loads the user form local storage
   * type {Observable<any>}
   */
  
  loadUser: Observable<any> = createEffect(() => this.actions$.pipe(
    ofAction(ActionLoadUser),
    switchMap((data) => {
      return this.storage.getUser("user22").pipe(
        map((user: User) => {
          if (user && user.isValid) {
            return new ActionSetUser(user);
          }

          return new ActionLoginClearError();
        }),
      );
    }),
  ));

  /**
   * Save the user on successfull login
   * type {Observable<any>}
   */
   saveUser: Observable<any> = createEffect(() => this.actions$.pipe(
    ofAction(ActionLoginSuccess),
    switchMap((action: ActionLoginSuccess) => {
      if (action.user && action.user.isValid) {
        this.storage.storeData("user22", action.user);
      }

      return of({});
    }),
  ), { dispatch: false });

   authLogout: Observable<any> = createEffect(() => this.actions$.pipe(
    ofAction(ActionLogoutUser),
    switchMap((action) => {
      this.storage.removeData("user22");
      this.storage.removeData("store22");

      return of({});
    }),
  ), { dispatch: false });
}

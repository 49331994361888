<form [formGroup]="validationForm">
  <div class="speedoMeter">
    <div class="speedoMeter__title">Speedo Meter Validation</div>
    <div class="row no-gutters speedoMeter__content">
      <mat-card *ngIf="tripSheet" class="speedoMeter__content__checkIn col-5">
        <mat-card-title class="speedoMeter__content__checkIn__title"
          >Check In</mat-card-title
        >
        <mat-card-content
          class="row no-gutters speedoMeter__content__checkIn__content"
        >
          <img
            class="row no-gutters no-gutters speedoMeter__content__checkIn__content__img"
            alt=""
            [src]="tripSheet.checkInUrl"
          />
          <div
            class="row no-gutters speedoMeter__content__checkIn__content__value"
          >
            <span
              *ngIf="!editCheckIn; else changeCheckIn"
              class="speedoMeter__content__checkIn__content__value__show"
              >Check In Value:<span
                *ngIf="
                  tripSheet.checkInValue !=
                  validationForm.value['correctedCheckIn']
                "
                class="strike"
              >
                {{ tripSheet.checkInValue }}</span
              >
              {{ validationForm.value["correctedCheckIn"] }}</span
            >
            <ng-template
              class="speedoMeter__content__checkIn__content__value__template"
              #changeCheckIn
            >
              <span
                class="speedoMeter__content__checkIn__content__value__template__edit"
              >
                <mat-form-field
                  class="speedoMeter__content__checkIn__content__value__template__edit__form"
                >
                  <input
                    matInput
                    type="text"
                    formControlName="correctedCheckIn"
                    class="speedoMeter__content__checkIn__content__value__template__edit__form__input"
                    [value]="validationForm.value['correctedCheckIn']"
                    autofocus
                    autocomplete="off"
                  />
                </mat-form-field>
              </span>
            </ng-template>
            <span
              class="row no-gutters speedoMeter__content__checkIn__content__value__image"
              ><i
                [ngClass]="editCheckIn == true ? 'fa fa-check' : 'fa fa-pencil'"
                class="speedoMeter__content__checkIn__content__value__image__icon"
                aria-hidden="true"
                (click)="validateCheckIn()"
              ></i
            ></span>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card *ngIf="tripSheet" class="speedoMeter__content__checkOut col-5">
        <mat-card-title class="speedoMeter__content__checkOut__title"
          >Check Out</mat-card-title
        >
        <mat-card-content
          class="row no-gutters speedoMeter__content__checkOut__content"
        >
          <img
            class="row no-gutters no-gutters speedoMeter__content__checkOut__content__img"
            alt=""
            [src]="tripSheet.checkOutUrl"
          />
          <div
            class="row no-gutters speedoMeter__content__checkOut__content__value"
          >
            <span
              *ngIf="!editCheckOut; else changeCheckOut"
              class="speedoMeter__content__checkOut__content__value__show"
              >Check Out Value:
              <span
                *ngIf="
                  tripSheet.checkOutValue !=
                  validationForm.value['correctedCheckOut']
                "
                class="strike"
              >
                {{ tripSheet.checkOutValue }}</span
              >
              {{ validationForm.value["correctedCheckOut"] }}</span
            >
            <ng-template
              class="speedoMeter__content__checkOut__content__value__template"
              #changeCheckOut
            >
              <span
                class="speedoMeter__content__checkOut__content__value__template__edit"
              >
                <mat-form-field
                  class="speedoMeter__content__checkOut__content__value__template__edit__form"
                >
                  <input
                    matInput
                    type="text"
                    formControlName="correctedCheckOut"
                    class="speedoMeter__content__checkOut__content__value__template__edit__form__input"
                    [value]="validationForm.value['correctedCheckOut']"
                    autofocus
                    autocomplete="off"
                  />
                </mat-form-field>
              </span>
            </ng-template>
            <span
              class="row no-gutters speedoMeter__content__checkOut__content__value__image"
              ><i
                [ngClass]="
                  editCheckOut == true ? 'fa fa-check' : 'fa fa-pencil'
                "
                class="speedoMeter__content__checkOut__content__value__image__icon"
                aria-hidden="true"
                (click)="validateCheckOut()"
              ></i
            ></span>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div *ngIf="tripSheet" class="row no-gutters speedoMeter__footer">
      <div class="col-6 speedoMeter__footer__content">
        <span class="heading">Km Travelled:</span>
        <span
          class="speedoMeter__footer__content-strike"
          *ngIf="
            tripSheet.odoMeterKM !=
            validationForm.value['correctedCheckOut'] -
              validationForm.value['correctedCheckIn']
          "
        >
          {{ tripSheet.odoMeterKM }}
        </span>
        {{
          validationForm.value["correctedCheckOut"] -
            validationForm.value["correctedCheckIn"]
        }}
      </div>
      <div class="col-6 speedoMeter__footer__btns">
        <button
          class="speedoMeter__footer__btns__cancelBtn"
          (click)="closeDialog()"
          mat-raised-button
          color="primary"
        >
          Cancel
        </button>
        <button
          class="speedoMeter__footer__btns__confirmBtn"
          (click)="confirmSpeedoMeter()"
          id="speedo-meter-confirm"
          [disabled]="!validationForm.valid"
          mat-raised-button
          color="primary"
        >
          Generate Trip Sheet
        </button>
      </div>
    </div>
  </div>
</form>

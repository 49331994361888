import { Serializable } from "../base";

export class PosOrderSegment implements Serializable<PosOrderSegment> {
  public status: string;
  public message: string;
  public result: { label: string; status: string; fs_key: string }[];

  deserialize(input) {
    this.status = input.status;
    this.result = input.result;
    this.message = input.message;

    return this;
  }
}

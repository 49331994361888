import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { CustomCommonModule } from "src/app/customCommon.module";
import { SharedModule } from "../../shared/shared.module";
import { UtilsModule } from "../../utils/utils.module";
import { CustomerEliteStatusComponent } from "./customer-elite-status";

@NgModule({
    declarations: [CustomerEliteStatusComponent],
    imports: [
        SharedModule,
        UtilsModule,
        CommonModule,
        CustomCommonModule,
        MatDialogModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [CustomerEliteStatusComponent]
})
export class EliteSubscriptionModule {}

import { Serializable } from "../base";

export class BroadcastTrip implements Serializable<BroadcastTrip> {
  public trip_id: string;
  public total_order: string;
  public total_earning: string;
  public broadcast_expiry_time: string;
  public total_kilometer: string;
  public broadcast_expiry_sec: string;
  public broadcast_time: string;

  deserialize(input) {
    if (!input) {
      return this;
    }
    this.trip_id = input.t;
    this.total_order = input.to;
    this.total_earning = input.te;
    this.broadcast_expiry_time = input.be;
    this.total_kilometer = input.tk;
    this.broadcast_time = input.bt;
    this.broadcast_expiry_sec = input.ex;
    return this;
  }

  /**
   * @Getter isValidClientTime
   * It checks whether the client-side time is in current time or not.
   * if not, it returns false and does not show a broadcast popup
   */
  get isValidClientTime(): boolean {
    return (
      Math.floor(this.broadcastExpiryTime - this.broadcastTime) / 1000 >=
      Math.floor((this.broadcastExpiryTime - new Date().getTime()) / 1000)
    );
  }

  get timeLeft(): number {
    if (!this.isValidClientTime) {
      return 0;
    }
    const seconds: number = Math.floor(
      (this.broadcastExpiryTime - new Date().getTime()) / 1000
    );
    return seconds;
  }

  get broadcastTime(): any {
    return new Date(this.broadcast_time).getTime();
  }

  get broadcastExpirySec(): number {
    return parseInt(this.broadcast_expiry_sec, 10);
  }

  get broadcastExpiryTime(): any {
    return new Date(this.broadcast_expiry_time).getTime();
  }

  get totalOrder(): string {
    return this.total_order;
  }

  get totalEarning(): string {
    return this.total_earning;
  }

  get tripId(): string {
    return this.trip_id;
  }

  get totalKilometer(): string {
    return this.total_kilometer;
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BaseGetProvider } from "@tendercuts/http";
import { DriverViolation } from "@tendercuts/models";
import { of, Observable } from "rxjs";

@Injectable()
export class FetchViolationTicketsService extends BaseGetProvider<DriverViolation> {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint
  ) {
    super(http);
    this.delegate = this;
  }

  // override this
  public preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof DriverViolation {
    return DriverViolation;
  }

  get endpoint(): string {
    return this.endpointService.fetchViolationTickets();
  }

  get isCachable(): boolean {
    return false;
  }

  /**
   * @param selectedDate
   * Convert the date into YYYY-MM-DD
   */
  getParams(selectedDate: Date): { status: number; created_at: string } {
    const date: string = selectedDate
      .getDate()
      .toLocaleString("en-US", { minimumIntegerDigits: 2 });
    const month: string = (selectedDate.getMonth() + 1).toLocaleString(
      "en-US",
      {
        minimumIntegerDigits: 2,
      }
    );
    const formattedDate: string =
      selectedDate.getFullYear() +
      "-" +
      month.toString() +
      "-" +
      date.toString();

    return { status: 0, created_at: formattedDate };
  }

  getData(): Observable<any> {
    return of([
      new DriverViolation().deserialize({
        corrected_at: "2022-02-24T16:20:20.897791+05:30",
        corrected_by: null,
        corrected_lat: null,
        corrected_lng: null,
        expected_lat: 12.950539127215535,
        expected_lng: 80.24324220957031,
        created_at: "2022-02-24T16:20:20.897253+05:30",
        customer_lat: 12.972840401785483,
        customer_lng: 80.22737396038818,
        distance: 291.6551340991127,
        driver_lat: 10.8060661,
        driver_lng: 78.7020844,
        driver_order: {
          created_at: "2022-02-24T16:18:38.489007+05:30",
          driver_id: null,
          driver_user: 953704,
          id: 1070,
          increment_id: "11-507721",
          is_active: true,
          latitude: "12.972840401785483",
          longitude: "80.22737396038818",
          previous_trip_id: null,
          sequence_number: 1,
          status: "complete",
        },
        force_complete: false,
        id: 131411,
        is_verified: false,
        status: 0,
        store_id: 11,
        trip: 462,
      }),
      new DriverViolation().deserialize({
        corrected_at: "2022-02-24T16:29:46.781397+05:30",
        corrected_by: null,
        corrected_lat: null,
        corrected_lng: null,
        expected_lat: 12.950539127215535,
        expected_lng: 80.24324220957031,
        created_at: "2022-02-24T16:29:46.780793+05:30",
        customer_lat: 12.983235741421238,
        customer_lng: 80.23057770847169,
        distance: 292.7966345223151,
        driver_lat: 10.806085,
        driver_lng: 78.7020704,
        driver_order: {
          created_at: "2022-02-24T16:21:14.431311+05:30",
          driver_id: null,
          driver_user: 953704,
          id: 1071,
          increment_id: "11-507711",
          is_active: true,
          latitude: "12.983235741421238",
          longitude: "80.23057770847169",
          previous_trip_id: null,
          sequence_number: 1,
          status: "complete",
        },
        force_complete: false,
        id: 131412,
        is_verified: false,
        status: 0,
        store_id: 11,
        trip: 463,
      }),
      new DriverViolation().deserialize({
        corrected_at: "2022-02-24T16:36:23.963114+05:30",
        corrected_by: null,
        corrected_lat: null,
        corrected_lng: null,
        expected_lat: 12.950539127215535,
        expected_lng: 80.24324220957031,
        created_at: "2022-02-24T16:36:23.962564+05:30",
        customer_lat: 12.983235741421238,
        customer_lng: 80.23057770847169,
        distance: 292.7968419622634,
        driver_lat: 10.8060832,
        driver_lng: 78.7020697,

        driver_order: {
          created_at: "2022-02-24T16:33:31.171704+05:30",
          driver_id: null,
          driver_user: 953704,
          id: 1073,
          increment_id: "11-507717",
          is_active: true,
          latitude: "12.983235741421238",
          longitude: "80.23057770847169",
          previous_trip_id: null,
          sequence_number: 1,
          status: "complete",
        },
        force_complete: false,
        id: 131413,
        is_verified: false,
        status: 0,
        store_id: 11,
        trip: 465,
      }),
      new DriverViolation().deserialize({
        corrected_at: "2022-02-24T16:37:12.981297+05:30",
        corrected_by: null,
        corrected_lat: null,
        corrected_lng: null,
        expected_lat: 12.950539127215535,
        expected_lng: 80.24324220957031,
        created_at: "2022-02-24T16:37:12.980608+05:30",
        customer_lat: 12.975035942000192,
        customer_lng: 80.21098029888915,
        distance: 290.84062186949313,
        driver_lat: 10.806082,
        driver_lng: 78.7020721,
        driver_order: {
          created_at: "2022-02-24T16:33:31.171773+05:30",
          driver_id: null,
          driver_user: 953704,
          id: 1074,
          increment_id: "11-507722",
          is_active: true,
          latitude: "12.975035942000192",
          longitude: "80.21098029888915",
          previous_trip_id: null,
          sequence_number: 2,
          status: "complete",
        },
        force_complete: false,
        id: 131414,
        is_verified: false,
        status: 0,
        store_id: 11,
        trip: 466,
      }),
    ]);
  }
}

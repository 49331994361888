import { GenericError, LiteSaleOrder, SaleOrder } from "@tendercuts/models";
import { createReducer, Action, Store } from "ngrx-actions";
import {
  ActionAllOrdersLoaded,
  ActionCheckNewOrders,
  ActionGetOrders,
  ActionGetPriorityOrders,
  ActionLoadSuccess,
  ActionOrderError,
  ActionOrderStoreClear,
  ActionPriorityOrdersLoaded,
  ActionRefreshOrders,
  ActionSetNewOrders,
  ActionStitchSegment,
  ActionUpdateActiveOrders,
} from "../orders/actions";
import { orderInitialState, OrderState } from "./state";

@Store({ ...orderInitialState })
export class OrderReducers {
  /**
   * Set orders and stop loading
   * @param StoreState state
   * @param ActionSetStore action
   * @returns store: Store; loading: boolean; error: boolean; errorMessage: string
   */
  @Action(ActionGetOrders)
  actionLoadOrders(
    state: OrderState,
    action: ActionGetOrders,
  ): {
    loading: boolean;
    orders: SaleOrder[];
    newOrders: SaleOrder[];
    orderMap: any;
    error: boolean;
    errorMessage: string;
    lastOrderTime: string;
    priorityOrdertags: {};
  } {
    return {
      ...state,
      loading: true,
    };
  }

  /**
   * Set loading as true
   */
  @Action(ActionRefreshOrders)
  actionRefreshOrders(
    state: OrderState,
    action: ActionRefreshOrders,
  ): {
    loading: boolean;
    orders: SaleOrder[];
    newOrders: SaleOrder[];
    orderMap: any;
    error: boolean;
    errorMessage: string;
    lastOrderTime: string;
    priorityOrdertags: {};
  } {
    return {
      ...state,
      loading: true,
    };
  }

  /**
   * Set loading as false
   */
  @Action(ActionLoadSuccess)
  actionLoadSuccess(
    state: OrderState,
    action: ActionLoadSuccess,
  ): {
    loading: boolean;
    orders: SaleOrder[];
    newOrders: SaleOrder[];
    orderMap: any;
    error: boolean;
    errorMessage: string;
    lastOrderTime: string;
    priorityOrdertags: {};
  } {
    return {
      ...state,
      loading: false,
    };
  }

  /**
   * set laoding flag to falsr, and load all orders
   * @param StoreState state
   * @returns store: Store; loading: boolean; error: boolean; errorMessage: string
   */
  @Action(ActionAllOrdersLoaded)
  actionPublishOrders(
    state: OrderState,
    action: ActionAllOrdersLoaded,
  ): {
    loading: boolean;
    orders: SaleOrder[];
    newOrders: SaleOrder[];
    orderMap: any;
    error: boolean;
    errorMessage: string;
    lastOrderTime: string;
    priorityOrdertags: {};
  } {
    const orderMap: any = {};
    let lastCreateOrderTime: any = null;
    action.payload.forEach((order: SaleOrder) => {
      orderMap[order.incrementId.toString()] = order;
      lastCreateOrderTime =
        !lastCreateOrderTime || lastCreateOrderTime < order.createdAt
          ? order.createdAt
          : lastCreateOrderTime;
    });

    return {
      ...state,
      orders: action.payload,
      newOrders: [],
      orderMap,
      lastOrderTime: lastCreateOrderTime,
    };
  }
  /**
   * fetch the priority orders list
   * @param state OrderState
   * @returns loading :true
   */
  @Action(ActionGetPriorityOrders)
  actionGetPriorityOrders(
    state: OrderState,
    action: ActionGetPriorityOrders,
  ): {
    loading: boolean;
    orders: SaleOrder[];
    newOrders: SaleOrder[];
    orderMap: any;
    error: boolean;
    errorMessage: string;
    lastOrderTime: string;
    priorityOrdertags: {};
  } {
    return {
      ...state,
      loading: true,
    };
  }

  /**
   * 1) Remove the completed and cancel orders in redux store
   * 2) Order Map rendered
   * @param state OrderState
   */
  @Action(ActionUpdateActiveOrders)
  actionUpdateActiveOrders(
    state: OrderState,
    action: ActionUpdateActiveOrders,
  ): {
    loading: boolean;
    orders: SaleOrder[];
    newOrders: SaleOrder[];
    orderMap: any;
    error: boolean;
    errorMessage: string;
    lastOrderTime: string;
    priorityOrdertags: {};
  } {
    const oldOrders: SaleOrder[] = [...state.orders];
    const orderMap: any = {};
    const activeOrders: SaleOrder[] = oldOrders.filter(
      (order) => !action.removeOrders.includes(order.incrementId),
    );
    activeOrders.forEach((order: SaleOrder) => {
      orderMap[order.incrementId.toString()] = order;
      const liteSaleOrder: LiteSaleOrder = action.liteSaleOrder.find(
        (orderStatus) => order.incrementId == orderStatus.orderId,
      );
      if (liteSaleOrder) {
        order.orderStatus = liteSaleOrder.status;
        order.customStatus = liteSaleOrder.customStatus;
        order.kotPrinted = liteSaleOrder.isKotPrinted;
        order.driverName = liteSaleOrder.driverName;
        order.driverNumber = liteSaleOrder.driverNumber;
      }
    });

    return {
      ...state,
      orders: [...activeOrders],
      newOrders: [],
      orderMap,
    };
  }

  /**
   * All priority orders with rating tags are mapped
   * @param state
   * @return loading: false, priorityOrdertags : {orderId: Rating tags array}
   */
  @Action(ActionPriorityOrdersLoaded)
  actionPriorityOrdersLoaded(
    state: OrderState,
    action: ActionPriorityOrdersLoaded,
  ): {
    loading: boolean;
    orders: SaleOrder[];
    newOrders: SaleOrder[];
    orderMap: any;
    error: boolean;
    errorMessage: string;
    lastOrderTime: string;
    priorityOrdertags: {};
  } {
    const orderTagMap: any = {};
    action.orders.forEach(
      (order) => (orderTagMap[order.orderId] = order.tagCateogry),
    );

    return {
      ...state,
      loading: false,
      priorityOrdertags: orderTagMap,
    };
  }

  /**
   * Set all loaded stores to redux cart
   * @param StoreState} state
   * @param ActionAllStoreLoaded} action
   * @returns store: Store; loading: boolean; error: boolean;
   *  errorMessage: string; availableStores: []
   */
  @Action(ActionOrderError)
  actoinOrderError(
    state: OrderState,
    action: ActionOrderError,
  ): {
    loading: boolean;
    error: boolean;
    errorMsg: GenericError;
    newOrders: any[];
    orders: SaleOrder[];
    orderMap: any;
    errorMessage: string;
    lastOrderTime: string;
    priorityOrdertags: {};
  } {
    return {
      ...state,
      loading: false,
      error: true,
      errorMsg: action.payload,
      newOrders: [],
    };
  }

  @Action(ActionOrderStoreClear)
  actionOrderStoreClear(
    state: OrderState,
    action: ActionOrderStoreClear,
  ): {
    loading: boolean;
    orders: SaleOrder[];
    newOrders: SaleOrder[];
    orderMap: any;
    error: boolean;
    errorMessage: string;
    lastOrderTime: string;
    priorityOrdertags: {};
  } {
    return {
      ...state,
      loading: false,
      orders: [],
      newOrders: [],
      orderMap: {},
    };
  }

  /**
   * Sets loading to true
   * @param state
   * @param action
   */
  @Action(ActionCheckNewOrders)
  actionCheckNewOrders(
    state: OrderState,
    action: ActionCheckNewOrders,
  ): {
    loading: boolean;
    orders: SaleOrder[];
    newOrders: SaleOrder[];
    orderMap: any;
    error: boolean;
    errorMessage: string;
    lastOrderTime: string;
    priorityOrdertags: {};
  } {
    return {
      ...state,
      newOrders: [],
      loading: true,
    };
  }

  /**
   * Sets loading to true
   * @param state
   * @param action
   */
  @Action(ActionSetNewOrders)
  actionSetNewOrders(
    state: OrderState,
    action: ActionSetNewOrders,
  ): {
    loading: boolean;
    orders: SaleOrder[];
    newOrders: SaleOrder[];
    orderMap: any;
    error: boolean;
    errorMessage: string;
    lastOrderTime: string;
    priorityOrdertags: {};
  } {
    const orderMap: any = {};
    const oldLastCreateOrderTime: string = state.lastOrderTime;
    let lastCreateOrderTime: any = null;
    const newOrders: SaleOrder[] = action.newOrders.filter(
      (order) => !state.orderMap[order.incrementId.toString()],
    );

    newOrders.forEach((order: SaleOrder) => {
      orderMap[order.incrementId.toString()] = order;
      lastCreateOrderTime =
        !lastCreateOrderTime || lastCreateOrderTime < order.createdAt
          ? order.createdAt
          : lastCreateOrderTime;
    });
    // if there is no payload, the go back to the old timestamp.
    if (!lastCreateOrderTime) {
      lastCreateOrderTime = oldLastCreateOrderTime;
    }

    return {
      ...state,
      loading: false,
      orders: [...newOrders, ...state.orders],
      orderMap: { ...orderMap, ...state.orderMap },
      newOrders: action.newOrders,
      lastOrderTime: lastCreateOrderTime,
    };
  }

  /**
   * Set the segment Id for the orders.
   * Generate a polygon from the path string, and check if the order is in that
   * polygon and inject the segment id, if the order is present within the
   * polygon.
   * @param state
   * @param action
   */
  @Action(ActionStitchSegment)
  actionStitchNewOrders(
    state: OrderState,
    action: ActionStitchSegment,
  ): {
    loading: boolean;
    orders: SaleOrder[];
    newOrders: SaleOrder[];
    orderMap: any;
    error: boolean;
    errorMessage: string;
    lastOrderTime: string;
    priorityOrdertags: {};
  } {
    const orderCache: any = {};
    action.segments.forEach((segment) => {
      segment.resetState();
      state.orders.forEach((order) => {
        if (order.incrementId in orderCache || !order.isUnAssignedOrder) {
          return;
        }

        if (order.segment == segment.name) {
          segment.addOrder(order);
          orderCache[order.incrementId] = true;
        }
      });
    }); // End segments.

    // add all remaining orders to other Segment
    action.segments.forEach((segment) => {
      if (segment.segmentId != -1) {
        return;
      }
      state.orders.forEach((order) => {
        if (order.incrementId in orderCache || !order.isUnAssignedOrder) {
          return;
        }
        segment.addOrder(order);
      });
    });

    return {
      ...state,
      orders: [...state.orders],
    };
  }
}

export const orderReducer: (state: any, action: any) => any = createReducer(
  OrderReducers,
);

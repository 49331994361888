import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BasePostProvider } from "@tendercuts/http";
import { GenericServerResponse } from "@tendercuts/models";

@Injectable({
  providedIn: "root",
})
export class GetDriverOtpService extends BasePostProvider<GenericServerResponse> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
  }

  preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof GenericServerResponse {
    return GenericServerResponse;
  }

  get endpoint(): string {
    return this.endpointService.driverOtp();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(
    mobileNumber: any,
  ): {
    mobile_number: any;
  } {
    const params: {
      mobile_number: any;
    } = {
      mobile_number: mobileNumber,
    };

    return params;
  }
}

<ng-container *ngIf="latitude">
  <agm-marker
    [latitude]="latitude"
    [longitude]="longitude"
    [label]="{ color: '#fff', text: formatLabels() }"
    [iconUrl]="markerUrl(order)"
    (markerClick)="onMarkerSelected()"
    (mouseOver)="displayWindow = true"
    (mouseOut)="displayWindow = false"
    [markerDraggable]="draggable"
    [openInfoWindow]="showWindow"
  >
    <agm-snazzy-info-window
      [closeWhenOthersOpen]="true"
      [padding]="'5px'"
      [maxWidth]="250"
      [isOpen]="showWindow && displayWindow"
    >
      <ng-template>
        <span
          ><b>{{ order.incrementId }}({{ order.status }})</b></span
        >
        <p *ngIf="!driverLat">{{ order.shippingAddress.fullAddress }}</p>
      </ng-template>
    </agm-snazzy-info-window>

    <agm-circle
      *ngIf="showCircle"
      [latitude]="latitude"
      [longitude]="longitude"
      fillColor="yellow"
      [radius]="circleRadius"
      [visible]="isSelected"
    >
    </agm-circle>
  </agm-marker>
</ng-container>

import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
@Component({
  selector: "app-order-eta",
  templateUrl: "./order-eta.component.html",
  styleUrls: ["./order-eta.component.scss"],
})
export class OrderEtaComponent {
  constructor(
    public dialogRef: MatDialogRef<OrderEtaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}
}

import { HttpParams } from "@angular/common/http";
import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import DirectionsResult = google.maps.DirectionsResult;
import DirectionsLeg = google.maps.DirectionsLeg;
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { StoreState } from "@store/store";
import { Location, SaleOrder, Store as storeModel } from "@tendercuts/models";
import {
  FetchOrdersDetailsService,
  FetchOrderLocationService,
} from "@tendercuts/providers";
import { Observable, Subscription } from "rxjs";
import { skipWhile, take } from "rxjs/operators";
import { BasePage } from "../../utils";

@Component({
  selector: "app-order-location",
  templateUrl: "./order-location.component.html",
  styleUrls: ["./order-location.component.scss"],
})
export class OrderLocationComponent
  extends BasePage
  implements OnInit, OnDestroy {
  orderNumber: string;
  order: SaleOrder;
  selectedStoreId: number;
  eventSubscription: Subscription;
  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public orderService: FetchOrdersDetailsService,
    public route: ActivatedRoute,
    public router: Router,
    private title: Title
  ) {
    super(dialog, snackBar, store);
  }

  /**
   * listen the route params change if the there is any change in the route
   * parameter it will fetch the data according to the activated route value
   */
  ngOnInit(): void {
    this.eventSubscription = this.router.events.subscribe((data) => {
      if (data instanceof NavigationEnd) {
        this.searchOrder();
      }
    });
    this.searchOrder();
    this.title.setTitle("Order Detail");
  }

  ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }

  /**
   * according to route param value te order detail will be changed
   * if this order number not equal to undefined it will navigate to this page
   * if route param not equal to undefined the getOrder function will changed
   */
  searchOrder(): void {
    const routeParam: string = this.route.snapshot.params["order_id"];
    this.orderNumber = routeParam;
    if (this.orderNumber != undefined) {
      this.router.navigate(["/dashboard/order-detail/" + this.orderNumber]);
    }
    if (routeParam != undefined) {
      this.getStoreId();
    }
  }

  /**
   * Resolves the the order to SaleOrder object.
   * @param increment_id
   */
  resolveOrder(): void {
    if (this.orderNumber != undefined) {
      this.router.navigate(["/dashboard/order-detail/" + this.orderNumber]);
    }
  }

  async getStoreId(): Promise<void> {
    this.selectedStoreId = await this.store
      .select((state) => state.storeState.store)
      .pipe(
        skipWhile((state) => !state),
        take(1)
      )
      .toPromise();
    this.getOrder();
  }

  getOrder(): void {
    if (this.selectedStoreId == undefined) {
      this.textAlert("Please select store", "");

      return;
    }

    const params: {
      order_id: string;
      store_id?: string;
    } = this.orderService.getParams(this.orderNumber, this.selectedStoreId);
    this.presentLoader();
    this.order = null;
    this.orderService.getData(params).subscribe(
      (orders: SaleOrder[]) => {
        this.order = orders[0];
        this.dismissLoader();
      },
      (err) => {
        this.dismissLoader();
        this.somethingWentWrong();
      }
    );
  }
}

import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TripSheet } from "@tendercuts/models";

/**
 * component to show tripsheet penalty details(amount, comments)
 */
@Component({
  selector: "app-trip-sheet-penalty",
  templateUrl: "./trip-sheet-penalty.component.html",
  styleUrls: ["./trip-sheet-penalty.component.scss"],
})
export class TripSheetPenaltyComponent implements OnInit {
  tripSheet: TripSheet;

  // form group controller for all fields
  tripSheetPenaltyForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TripSheet,
    public dialogRef: MatDialogRef<TripSheetPenaltyComponent>,
  ) {
    if (data) {
      this.tripSheet = data;
    }
  }

  ngOnInit(): void {
    this.tripSheetPenaltyForm = new FormGroup({
      amount: new FormControl("", [Validators.required]),
      comment: new FormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(300),
      ]),
    });
  }

  /**
   * pass the params to trip earning update
   */
  addPenalty(): void {
    this.dialogRef.close({
      status: true,
      amount: this.tripSheetPenaltyForm.value["amount"],
      comment: this.tripSheetPenaltyForm.value["comment"],
    });
  }

  closeDialog(): void {
    this.dialogRef.close({ status: false });
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BaseGetProvider } from "@tendercuts/http";
import { TripConfig } from "@tendercuts/models";

/**
 * Service to fetch trip configuration.
 */
@Injectable({
  providedIn: "root",
})
export class TripConfigService extends BaseGetProvider<TripConfig> {
  constructor(
    public http: HttpClient,
    public loginService: LoginServices,
    public endpointService: EndPoint,
  ) {
    super(http);
    this.delegate = this;
  }

  // override this
  preProcessResponse(response: any): Array<any> {
    return response.results;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof TripConfig {
    return TripConfig;
  }

  get endpoint(): string {
    return this.endpointService.fetchTripConfig();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(storeId: string): { store_id: string } {
    const params: { store_id: string } = {
      store_id: storeId,
    };

    return params;
  }
}

import { Serializable } from './base';

export class RewardPointsTransaction implements Serializable<RewardPointsTransaction> {
    private amount_used: string;
    private created_at: Date;
    private transaction_id: string;
    private updated_at: Date;
    private updated_by: string;
    private order_id: string;
    private expires_at: Date;
    public amount: string;
    public code: string;
    public comment: string;
    public customer: string;
    public customer_id: string;
    public balance_delta: number;


    get amountUsed() { return this.amount_used; }
    get createdAt() { return this.created_at; }
    get transactionId() { return this.transaction_id; }
    get updatedAt() { return this.updated_at; }
    get updatedBy() { return this.updated_by; }

    constructor() { }

    get orderId(): string {
      return this.order_id;
    }

    get expiresAt(): Date {
      return this.expires_at;
    }

    serialize() {
        return {
          customer_id: this.customer_id,
          amount: this.balance_delta,
          comment: this.comment
        };
      }

      get isEarned(): boolean {
        return Number(this.amount) > 0 ?  true : false;
      }

      get isSpent(): boolean {
        return (Number(this.amount) < 0 && this.code != 'expired') ? true : false;
      }

      get isExpired(): boolean {
        return (Number(this.amount) < 0 && this.code === 'expired') ? true : false;
      }


    deserialize(input) {
        this.amount = input.amount;
        this.amount_used = input.amount_used;
        this.code = input.code;
        this.comment = input.comment;
        this.created_at = new Date(input.created_at);
        this.customer = input.customer;
        this.transaction_id = input.transaction_id;
        this.updated_at = new Date(input.updated_at);
        this.order_id = input.order_id;
        this.expires_at = new Date(input.expires_at);
        this.updated_by = input.updated_by;
        return this;
    }
}

import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Store as AppStore } from "@ngrx/store";
import { AppState } from "@store/state";
import { StoreState } from "@store/store/state";
import {
  BookingRequest,
  GenericServerResponse,
  Store,
} from "@tendercuts/models";
import { BookingEditService } from "@tendercuts/providers";
import { skipWhile, take } from "rxjs/operators";
import { BasePage } from "src/app/utils";

@Component({
  selector: "app-booking-edit",
  templateUrl: "./booking-edit.component.html",
  styleUrls: ["./booking-edit.component.scss"],
})
export class BookingEditComponent extends BasePage implements OnInit {
  bookingRequest: BookingRequest;

  // stores list based on the booking city
  availableBookingStores: Store[] = [];

  /**
   * showing the available shift of the rider.
   */
  DRIVERSHIFTS: { title: string; key: string; value: string }[] = [
    { title: "First Shift", key: "enable_first_shift", value: "first" },
    { title: "Second Shift", key: "enable_second_shift", value: "second" },
    {
      title: "Full Shift",
      key: "enable_direct_full_shift",
      value: "full_shift",
    },
  ];

  // form group controller for all fields
  bookingEditForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BookingRequest,
    public dialogRef: MatDialogRef<BookingEditComponent>,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: AppStore<AppState>,
    public bookingEditService: BookingEditService,
    public router: Router,
  ) {
    super(dialog, snackBar, store);
    if (data) {
      this.bookingRequest = data;
    }
  }

  ngOnInit(): void {
    this.fetchStores();
    this.bookingEditForm = new FormGroup({
      store: new FormControl("", [Validators.required]),
      shift: new FormControl("", [Validators.required]),
    });
    this.DRIVERSHIFTS = this.DRIVERSHIFTS.filter(
      (shift) => this.bookingRequest[shift.key],
    );
  }

  /**
   * fetch the all stores from redux and filter the stores from
   * city wise(show selected city stores)
   */
  async fetchStores(): Promise<void> {
    this.presentLoader();
    const state$: StoreState = await this.store
      .select((state) => state.storeState)
      .pipe(
        skipWhile((state) => state.loading),
        take(1),
      )
      .toPromise();
    this.dismissLoader();
    const bookingStore: Store = state$.allStores.find(
      (store) => store.storeId == this.bookingRequest.storeId,
    );

    if (!bookingStore) {
      return;
    }

    this.availableBookingStores = state$.allStores.filter(
      (store) =>
        store.storeAttribute &&
        store.storeAttribute.storeType == 2 &&
        store.storeAttribute.city == bookingStore.storeAttribute.city,
    );
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  /**
   * edit the booking object in the server
   */
  editBooking(): void {
    this.presentLoader();
    const selectedStore: Store = this.availableBookingStores.find(
      (store) => store.storeId == +this.bookingEditForm.value["store"],
    );
    this.bookingRequest.setStoreCode(selectedStore.code);
    this.bookingRequest.setStore(+selectedStore.storeId);
    this.bookingRequest.setShift(this.bookingEditForm.value["shift"]);
    this.bookingEditService.getData(this.bookingRequest.serialize()).subscribe(
      (data: GenericServerResponse[]) => {
        this.dismissLoader();

        this.dialogRef.close(true);
        this.showNotification(
          "bottom",
          "center",
          data[0].status ? "success" : "danger",
          "info-circle",
          data[0].message,
        );
      },
      (err) => {
        this.dismissLoader();
        this.somethingWentWrong();
      },
    );
  }

  /**
   * Redirect to Driver crm component
   */
  redirectToDriverCRM(id: number): void {
    window.open(
      window.location.origin + "/dashboard/driver-crm/" + id,
      "_blank",
    );
  }
}

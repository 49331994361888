import { Injectable } from "@angular/core";
import * as firebase from "firebase/app";
import "firebase/analytics";
import { SaleOrder, Trips } from "@tendercuts/models";
import { Store } from "@ngrx/store";
import { StoreState } from "@store/store";
import * as selectors from "@store/state";
import { skipWhile, take } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class FirebaseAnalyticsService {
  analytics: any;

  constructor(public store: Store<StoreState>) {}

  /** Fetch the current selected store and return the store name */
  async getStoreId(): Promise<string> {
    const storeState: StoreState = await this.store
      .select(selectors.getStoreState)
      .pipe(
        skipWhile(
          (state) =>
            state.availableStores.length == 0 ||
            typeof state.store == "undefined"
        ),
        take(1)
      )
      .toPromise();

    return storeState.store.toString();
  }

  /** Post the events to the firebase server */
  logEvent(eventName: string, eventProperties?: { [key: string]: any }) {
    this.analytics = firebase.analytics();
    this.analytics.logEvent(eventName, eventProperties);
  }

  /** AUTO ROUTE EVENTS */
  async autoRouteButtonClicked() {
    let storeId = await this.getStoreId();
    this.logEvent("auto_route_btn_clicked", { store_id: storeId });
  }

  autoRouteSuccess(message: string) {
    this.logEvent("auto_route_success", { message: message });
  }

  autoRouteFailure(errMessage: string) {
    this.logEvent("auto_route_failure");
  }
  /** END OF AUTO ROUTE EVENTS */

  /** TRIP EVENTS STARTS */
  async openTrip(trip: Trips) {
    let storeId = await this.getStoreId();
    this.logEvent("open_trip", {
      trip_id: trip.tripId,
      store_id: storeId,
    });
  }

  /** EDIT TRIP EVENTS */
  async editButtonClicked(tripId: number) {
    let storeId = await this.getStoreId();
    this.logEvent("edit_trip_btn_clicked", {
      trip_id: tripId.toString(),
      store_id: storeId.toString(),
    });
  }

  orderAddedToTrip(tripId: number, saleOrder: SaleOrder) {
    this.logEvent("trip_order_added", {
      trip_id: tripId,
      order_id: saleOrder.incrementId,
      order_desciption: saleOrder?.slotDescription,
      store_name: saleOrder?.storeName,
      segment_name: saleOrder?.segment,
      slot_id: saleOrder?.scheduledSlot,
      sub_status: saleOrder?.subStatus,
      status: saleOrder?.status,
    });
  }

  orderRemovedFromTrip(tripId: number, saleOrder: SaleOrder) {
    this.logEvent("trip_order_removed", {
      trip_id: tripId,
      order_id: saleOrder?.incrementId,
      order_desciption: saleOrder?.slotDescription,
      store_name: saleOrder?.storeName,
      segment_name: saleOrder?.segment,
      slot_id: saleOrder?.scheduledSlot,
      sub_status: saleOrder?.subStatus,
      status: saleOrder?.status,
    });
  }
  
  /** Triggers when the edited trip data posted to the server API call */
  async editTripServerSync(tripId: number) {
    let storeId = await this.getStoreId();
    this.logEvent("edit_trip_server_sync", {
      trip_id: tripId.toString(),
      store_id: storeId.toString(),
    });
  }

  /** Triggers on editTripServerSync API sync success */
  editTripSuccess(tripId: number) {
    this.logEvent("edit_trip_sync_success", { trip_id: tripId });
  }

  /** Triggers on editTripServerSync API sync failure */
  editTripFailure(tripId: number, errMessage: string) {
    this.logEvent("edit_trip_sync_failure", {
      trip_id: tripId,
      error_message: errMessage,
    });
  }
  /** End of EDIT TRIP EVENTS  */
}

import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { InventoryRequest, Product } from "@tendercuts/models";
import { InventoryRequestService } from "@tendercuts/providers";
import { NumberInputAction, ProductDataSource } from "../../components";
import { InventoryReqReviewDialog } from "../../components/inventory-req-review";
import { BasePage } from "../../utils";

@Component({
  selector: "app-inventory-adjustment-dialog",
  templateUrl: "./inventory-adjustment-dialog.component.html",
  styleUrls: ["./inventory-adjustment-dialog.component.scss"],
})
export class InventoryAdjustmentDialogComponent
  extends BasePage
  implements OnInit {
  buttonActions: NumberInputAction[] = [
    new NumberInputAction(
      "Stock On Hand (Kg)",
      "SOH",
      this.handleStockOnHandRequest.bind(this),
    ),
    new NumberInputAction(
      "Stock Expires tomorrow (Kg)",
      "SET",
      this.updateStockExpiresTomorrow.bind(this),
    ),
  ];
  productDataSource: ProductDataSource;
  requests: InventoryRequest[] = [];
  private INV_REQUEST_TYPE: number = 2;
  public isRequestTriggered: boolean = false;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public dialogRef: MatDialogRef<InventoryReqReviewDialog>,
    public inventoryRequestService: InventoryRequestService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialog, snackBar, store);
    this.productDataSource = data.productData;
  }

  ngOnInit(): void {
    // TODO
    // eslint-disable-next-line no-console
    console.log(this.productDataSource);
  }

  /**
   *
   * @param event Event triggered when values entered in stock on hand
   * @param product product for which the event triggered
   * 1) Calls to insert if the product is not in the requests list
   * 2) Calls to update if the product is already in requests list
   */
  handleStockOnHandRequest(event: any, product: Product): void {
    if (event.target.value < 0) {
      event.target.value = null;
      this.textAlert("Negative Value", "Negative value not allowed");

      return;
    }
    const invRequest: InventoryRequest = this.requests.filter(
      (inv) => inv.productId == product.id,
    )[0];
    if (invRequest) {
      this.updateRequest(invRequest, event, product.id);
    } else {
      this.insertRequest(product, event);
    }
  }

  /**
   *
   * @param product - product to be inserted in the requests
   * @param event - Event triggered when values entered in stock on hand
   * Creats a InventoryRequest object with  event value as qty
   * and push inside requests list
   */
  insertRequest(product: Product, event: any): void {
    const stockOnHand: number = parseFloat(event.target.value);
    this.selectedStore.subscribe((store) => {
      // Inv request.
      const invRequest: InventoryRequest = new InventoryRequest().deserialize({
        product_id: product.id,
        product_name: product.name,
        sku: product.sku,
        store_id: store.storeId,
        type: this.INV_REQUEST_TYPE,
        store_name: store.code,
        qty: stockOnHand,
        gpu: product.gramsPerUnit,
      });
      this.requests.push(invRequest);
    });
  }

  /**
   *
   * @param invRequest - requests element for which the update of soh to be done
   * @param event - Event triggered when values entered in stock on hand
   * @param productId - Request element product Id
   * Checks fot he value entered in the event
   * 1) stock on hand must be greated than the stock expires today
   * 2) If entered value is null then remoce the product form the requests
   */
  updateRequest(invRequest: InventoryRequest, event: any, productId: number): void {
    const stockOnHand: number = parseFloat(event.target.value);
    if (invRequest.stockExpiresTomorrow > event.target.value) {
      event.target.value = invRequest.stockOnHand;
      this.textAlert(
        "Error",
        "Stock on hand  cannot be lesser than the stock expires",
      );

      return;
    }
    if (!Number.isNaN(stockOnHand)) {
      invRequest.stockOnHand = stockOnHand;
    } else {
      this.requests = this.requests.filter((req) => req.productId != productId);
    }
  }

  /**
   *
   * @param event - Event triggered when values entered in stock expires tomorrow
   * @param product - product for which the event triggered
   * Adds two checks
   * 1) If already products are in request, we allow to update the stockExpiresTomorrow
   * (Only update to the requests allowed)
   * 2) Stock on hand must be greater than the stock experies tomorrow
   */
  updateStockExpiresTomorrow(event: any, product: Product): void {
    const invRequest: InventoryRequest = this.requests.filter(
      (inventoryRequest) => inventoryRequest.productId == product.id,
    )[0];
    if (event.target.value < 0) {
      event.target.value = null;
      this.textAlert("Negative Value", "Negative value not allowed");

      return;
    }
    if (!invRequest) {
      event.target.value = null;
      this.textAlert(
        "Stock on hand required",
        "Enter stock on hand before entering stock expires tomorrow",
      );

      return;
    }
    if (invRequest.stockOnHand < event.target.value) {
      event.target.value = null;
      this.textAlert(
        "Error",
        "Stock expires cannot be greater than the stock on hand",
      );

      return;
    }
    invRequest.stockExpiresTomorrow = event.target.value;
  }

  /**
   * Provides a ui for approval
   * and on confirm hits the service
   */
  public verifyRequests(): void {
    if (
      this.requests.filter((request) => !request.stockExpiresTomorrow)
        .length !== 0
    ) {
      this.textAlert(
        "All fields must be filled",
        "If there is no stock enter 0",
      );

      return;
    }
    this.isRequestTriggered = true;
    const dialogData: {
      requests: InventoryRequest[];
      columns: string[];
    } = {
      requests: this.requests,
      columns: ["productName", "stockOnHand", "stockExpiresTomorrow"],
    };
    const ref: MatDialogRef<InventoryReqReviewDialog, any> = this.dialog.open(
      InventoryReqReviewDialog,
      {
        data: dialogData,
        disableClose: true,
        panelClass: "inventory-request-dialog",
      },
    );
    ref.afterClosed().subscribe((status) => {
      if (!status) {
        this.isRequestTriggered = false;

        return;
      }

      this.presentLoader();
      this.inventoryRequestService.getData(this.requests).subscribe(
        (response) => {
          this.dismissLoader();
          this.isRequestTriggered = false;
          this.textAlert("success", "Inventroy Request Successful");
        },
        () => {
          this.textAlert(
            "Update Failed",
            "Request failed, Please contact tech support",
          );
          this.isRequestTriggered = false;
          this.dismissLoader();
        },
        () => {
          this.dismiss(true);
        },
      );
    });
  }

  /**
   * Return the columns to display
   */
  get columnsToDisplay(): string[] {
    return ["name"];
  }

  /**
   *
   * @param status
   * Dismiss the dialog
   */
  dismiss(status: boolean): void {
    this.dialogRef.close(status);
  }
}

import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { SaleOrder } from "@tendercuts/models";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";

@Component({
  selector: "app-order-id-display",
  templateUrl: "./order-id-display.component.html",
  styleUrls: ["./order-id-display.component.scss"],
})
export class OrderIdDisplayComponent implements OnInit {
  @Input() order: SaleOrder;
  @Input() showTime: boolean = false;
  @Input() showIndex: boolean = false;
  @Input() index: number = 0;

  constructor(public store: Store<AppState>) {}

  ngOnInit(): void {}
  isPriorityOrder(order: SaleOrder): Observable<boolean> {
    return this.store
      .select((state) => state.orderState.priorityOrdertags)
      .pipe(
        take(1),
        map((priorityOrder) => {
          if (priorityOrder[order.incrementId] == undefined) {
            return false;
          }

          return true;
        }),
      );
  }
}

import { Serializable } from "../base";

export class StoreGeohash implements Serializable<StoreGeohash> {
    private id: number;
    private store_id: number;
    private tile_type: string;
    public geohash: string;
    public segment: number;

    get tileType(): string {
        return this.tile_type;
    }

    get geohashId(): number {
        return this.id;
    }

    get storeId(): number {
        return this.store_id;
    }

    deserialize(input) {
        this.store_id = input.store_id;
        this.geohash = input.geohash;
        this.segment = input.segment;
        this.id = input.id;
        this.tile_type = input.tile_type;

        return this;
    }
}

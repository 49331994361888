import * as selectors from "@store/state";

import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActionSetStore, StoreState } from "@store/store";
import {
  GetCategoryService,
  InventoryApprovalService,
} from "@tendercuts/providers";

import { HttpParams } from "@angular/common/http";
import { Title } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { InventoryStagingEntry } from "@tendercuts/models";
import { Observable } from "rxjs";
import { BasePage } from "../../utils";
import { InventoryReleaseProductDialogComponent } from "../inventory-release-product-dialog";

/**
 * Dashboard to relase category wise inventory.
 * Access only for store manager.
 */
@Component({
  selector: "app-inventory-approval",
  templateUrl: "./inventory-approval.component.html",
  styleUrls: ["./inventory-approval.component.scss"],
})
export class InventoryApprovalComponent extends BasePage implements OnInit {
  categories: any[] = [];
  selectedStoreId: number;
  selectedCategoryId: any;

  productsToRelease: InventoryStagingEntry[] = [];
  /*
   * sotes the array of products with respective po Id as property of object
   */
  releasePoMap: { [poId: string]: InventoryStagingEntry[] } = {};
  /** stores the selected PO Id */
  selectedPoId: string = "";

  constructor(
    public getCategory: GetCategoryService,
    public title: Title,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    public inventoryService: InventoryApprovalService,
  ) {
    super(dialog, snackBar, store);
  }

  ngOnInit(): void {
    this.title.setTitle("Inventory Approval");
    this.getCategory.getData().subscribe((data) => {
      this.categories = data;
    });
    this.stores.subscribe((state: StoreState) => {
      this.selectedStoreId = state.store;
    });

    this.checkStoreSelected();
  }

  get stores(): Observable<StoreState> {
    return this.store.select(selectors.getStoreState);
  }

  // On change store set the selected store Id in redux
  changeStore(): void {
    this.store.dispatch(new ActionSetStore(this.selectedStoreId));
    this.reSetSelectedCateogry();
  }

  // Alert shown when selectedStoreId is null
  checkStoreSelected(): void {
    if (!this.selectedStoreId) {
      this.showNotification(
        "bottom",
        "center",
        "danger",
        "info-circle",
        "Please select store and update",
      );

      return;
    }
  }

  /**
   * Call back form the InventoryBeforeReleaseComponent
   * if true we trigger invetory release service
   * if false left no changes
   */
  releaseInventory(event: any): void {
    if (event) {
      this.inventoryReleaseApproved(
        this.selectedCategoryId,
        this.selectedStoreId,
      );
      delete this.releasePoMap[this.selectedPoId];
    }
    this.productsToRelease = [];
  }

  /**
   * Confirmation alert before inventory release
   * dry run is initially set to 1,
   */
  inventoryRelease(category: any, selectedStoreId: number): void {
    this.selectedCategoryId = category.categoryId;
    const params: HttpParams = this.inventoryService.getParams(
      category.categoryId,
      selectedStoreId,
      this.selectedPoId,
      1,
    );
    this.inventoryService.getData(params).subscribe((response) => {
      if (response.length == 0) {
        this.textAlert(
          "No products are available",
          "under the selected category for release",
        );

        return;
      }
      response.forEach((inv) => {
        this.releasePoMap[inv.poId] = response.filter(
          (product) => product.poId == inv.poId,
        );
      });
    });
  }

  /**
   * @param poId
   * sets the globale selected value to empty string if the po Id is null
   * and sets the products to be release fron the release PO Map
   */
  onPoSelected(poId: any): void {
    this.productsToRelease = this.releasePoMap[poId];
    this.selectedPoId = this.productsToRelease[0].poId;
  }

  /**
   * Reset the releasePoMap object to empty object
   * and also empty the productsToRelease
   */
  reSetSelectedCateogry(): void {
    this.releasePoMap = {};
    this.productsToRelease = [];
    this.selectedPoId = "";
  }

  /**
   * Hit inventory-approval-Service by passing selectedStoreId and categoryId to a service.
   * @param categoryId
   * @param selectedStoreId
   */
  inventoryReleaseApproved(categoryId: any, selectedStoreId: number): void {
    this.presentLoader();
    const params: HttpParams = this.inventoryService.getParams(
      categoryId,
      selectedStoreId,
      this.selectedPoId,
      0,
    );
    this.inventoryService.getData(params).subscribe(
      (response) => {
        if (response.length == 0) {
          this.dismissLoader();
          this.textAlert(
            "No products are available",
            "under the selected category for release",
          );

          return;
        }
        this.dismissLoader();
        this.dialog.open(InventoryReleaseProductDialogComponent, {
          data: response,
          maxHeight: "85vh",
          width: "70vw",
        });
      },
      (err) => {
        this.dismissLoader();
        this.somethingWentWrong();
      },
    );
  }
}

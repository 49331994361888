<div id="payment-button">
  <h6>Are you sure you want to change payment Mode</h6>
  <br />
  <button
    id="payment-btn"
    color="primary"
    mat-raised-button
    (click)="proceedToChangeMode()"
  >
    Yes
  </button>
  <button id="cancel-pay" color="primary" mat-raised-button mat-dialog-close>
    No
  </button>
</div>

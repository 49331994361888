import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class RoleGuardService implements CanActivate {
  constructor(private router: Router, private store: Store<AppState>) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.store
      .select((appState) => appState.userState)
      .pipe(
        map((userState) => {
          if (
            userState.user.groups.some(
              (group) => next.data.role.indexOf(group) !== -1,
            )
          ) {
            return true;
          } else if (userState.user.isCcAgent) {
            this.router.navigate(["/dashboard/crm"]);

            return false;
          }

          // navigate to not found page
          this.router.navigate(["/404"]);

          return false;
        }),
      );
  }
}

<div class="order_display row">
  <div class="order_display__order_id col-9">
    <a
      *ngIf="!isOrderHistoryMode; else normalOrderId"
      id="order"
      (click)="redirectToOrderDetail(order.incrementId)"
    >
      Order#{{ order.incrementId }}&nbsp;
    </a>
    <ng-template #normalOrderId>
      <span> Order#{{ order.incrementId }}&nbsp; </span>
    </ng-template>
    <mat-chip selected color="warn" *ngIf="isGtgOrder"> GTG </mat-chip>
    <button
      mat-button
      class="copybtn"
      (click)="copy(order.incrementId)"
      id="order.incrementId"
      value="click to copy"
    >
      <i class="fa fa-clone" aria-hidden="true"></i>
      Copy
    </button>
  </div>
  <div align="right" class="order_display__index col-2" *ngIf="isCrmPage">
    <p>{{ orderIndex + 1 }}/{{ orderSource.length }}</p>
  </div>
  <div align="right" class="order_display__preview col-1" *ngIf="isCrmPage">
    <strong>
      <a
        id="order"
        class="order_display__preview__previous"
        *ngIf="canShowPrevButton(order)"
        (click)="movePreviousOrder()"
      >
        <
      </a>
      <a
        id="order"
        class="order_display__preview__next"
        *ngIf="canShowNextButton"
        (click)="moveNextOrder()"
      >
        >
      </a>
    </strong>
  </div>
</div>

<div
  class="row header no-gutters"
  [ngClass]="{
    background_color: isDialogOpen,
    'fixed-top': !isDialogOpen
  }"
>
  <div class="order_actions" *ngIf="!isTripDetailsMode && !isOrderHistoryMode">
    <app-order-actions
      [order]="order"
      id="order-action"
      (refreshParentEmitter)="handleOrderActions()"
    ></app-order-actions>
  </div>
</div>

<div class="order-detail" [ngClass]="{ 'order-detail-height': !isDialogOpen }">
  <div class="row separator">
    <div class="col-4">
      <mat-card>
        <mat-card-content>
          <div class="row no-gutters">
            <span class="label col-6">Order Date</span>
            <span class="value col-6">{{
              order.createdAt | date : "medium"
            }}</span>
          </div>

          <div class="row no-gutters">
            <span class="label col-6">Sale Date</span>
            <span class="value col-6">{{
              order.saleDate | date : "medium"
            }}</span>
          </div>

          <div class="row no-gutters">
            <span class="label col-6">Status</span>
            <span class="value col-6">{{ order.status }}</span>
          </div>

          <div class="row no-gutters">
            <span class="label col-6">Sub Status</span>
            <span class="value col-6">{{ order.customStatus }}</span>
          </div>

          <div
            class="row no-gutters"
            id="split_from"
            *ngIf="order.splittedFrom"
          >
            <span class="label col-6">Splitted From</span>
            <span class="value col-6">
              <a
                id="order"
                (click)="redirectToOrderDetail(splitOrder.incrementId)"
                >Order#{{ splitOrder.incrementId }}</a
              >
            </span>
          </div>

          <div class="row no-gutters" id="split_to" *ngIf="order.splittedTo">
            <span class="label col-6">Splitted To</span>
            <span class="value col-6">
              <a
                id="order"
                (click)="redirectToOrderDetail(splitOrder.incrementId)"
                >Order#{{ splitOrder.incrementId }}</a
              >
            </span>
          </div>

          <div class="row no-gutters">
            <span class="label col-6">Medium</span>
            <span class="value col-6">{{ order.mode }}</span>
          </div>

          <div class="row no-gutters" id="elite">
            <span class="label col-6">Customer Name</span>
            <span class="value col-6"
              >{{ order.firstname }}
              <mat-basic-chip class="elite-chip" *ngIf="order.isEliteCustomer"
                >Elite
              </mat-basic-chip></span
            >
          </div>

          <div class="row no-gutters">
            <span class="label col-6">Customer Id</span>
            <span class="value col-6">
              <a class="customer-id" (click)="redirectToCrm()">
                {{ order.customerId }}
              </a>
            </span>
          </div>

          <div class="row no-gutters" *ngIf="order.rating">
            <span class="label col-6">Rating</span>
            <span class="value col-6">{{ order.rating }}</span>
          </div>

          <div *ngIf="user?.isCcAgent" class="phone-no row no-gutters">
            <span class="label col-6">Phone</span>
            <span id="number" class="value col-6">
              {{ order.shippingAddress.telephone }}

              <button
                mat-button
                class="copybtn"
                (click)="copy(order.shippingAddress.telephone)"
                id="order.shippingAddress.telephone"
                value="click to copy"
              >
                <i class="fa fa-clone" aria-hidden="true"></i>
              </button>
            </span>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-4" *ngIf="order.shippingAddress">
      <mat-card>
        <mat-card-title>Shipping Information</mat-card-title>
        <mat-card-content>
          <div class="row no-gutters">
            <span class="label col-4">Store</span>
            <span class="value col-8">{{ order.storeName | uppercase }}</span>
          </div>
          <mat-divider></mat-divider>
          <div class="row no-gutters" *ngIf="!isTripDetailsMode">
            <span class="label col-4">Flat/Door No</span>
            <span class="value col-8">{{ order.shippingAddress.fax }}</span>
            <span class="label col-4">Landmark</span>
            <span class="value col-8">{{
              order.shippingAddress.landmark
            }}</span>
            <span
              class="label col-4"
              *ngIf="order.shippingAddress?.locality && isGtgOrder"
            >
              Locality
            </span>
            <span
              class="value col-8"
              *ngIf="order.shippingAddress?.locality && isGtgOrder"
            >
              {{ order.shippingAddress?.locality }}
            </span>
            <span class="label col-4">Postal Code</span>
            <span class="value col-8">{{
              order.shippingAddress.postcode
            }}</span>
          </div>
          <mat-divider></mat-divider>
          <div class="row no-gutters" *ngIf="user?.isCcAgent">
            <span class="label col-4">Google Address</span>
            <span class="value col-8" *ngIf="!isTripDetailsMode">{{
              order.shippingAddress.googleAddress
            }}</span>
          </div>
          <mat-divider></mat-divider>
          <div class="row no-gutters" *ngIf="order.customerNote">
            <span class="label col-4">Customer Note</span>
            <span class="value col-8">{{ order.customerNote }}</span>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-4">
      <mat-card>
        <mat-card-title>Delivery & Payment</mat-card-title>
        <mat-card-content>
          <div
            class="row no-gutters"
            *ngIf="order.driverName && order.customStatus !== 'dummy_assigned'"
          >
            <span class="label col-6">Driver Name</span>
            <span class="value col-6">
              <span
                id="driver-name"
                *ngIf="!order.driverNumber; else driverDetails"
              >
                {{ order.driverName }}
              </span>
              <ng-template #driverDetails>
                <a
                  id="driver-name-number"
                  class="phone"
                  (click)="redirectToRiderCrm()"
                >
                  {{ order.driverName }} - ({{ order.driverNumber }})
                </a>
              </ng-template>
            </span>
          </div>

          <div class="row no-gutters">
            <span class="label col-6">Delivery Slot</span>
            <span class="value col-6">{{ order.shippingDescription }}</span>
          </div>

          <div class="row no-gutters">
            <span class="label col-6">Delivery Charge</span>
            <span
              class="value col-6"
              *ngIf="!order.isShippingFree; else showFree"
              id="show-amount"
              >{{ order.shipping | currency : "INR" }}</span
            >
            <ng-template #showFree>
              <span class="value col-6">
                <strong id="delivery-charges">Free</strong>
              </span>
            </ng-template>
          </div>

          <div class="row no-gutters">
            <span class="label col-6">Promised Delivery</span>
            <span
              [ngClass]="{
                'time-highlight': highLight
              }"
              class="value col-6"
              >{{ order.promisedDeliveryTime }}</span
            >
          </div>

          <div *ngIf="order?.CCName != null" class="row no-gutters">
            <span class="label col-6">Agent Name</span>
            <span class="value col-6">{{ order.CCName }}</span>
          </div>

          <div *ngIf="order?.platform" class="row no-gutters">
            <span class="label col-6">Platform</span>
            <span class="value col-6">{{ order.platform }}</span>
          </div>

          <div *ngIf="order?.appVersion" class="row no-gutters">
            <span class="label col-6">App Version</span>
            <span class="value col-6">{{ order.appVersion }}</span>
          </div>
        </mat-card-content>
        <mat-card-actions *ngIf="order.paymentMethod">
          <mat-chip-list>
            <mat-chip>
              <i class="material-icons"> attach_money </i>
              {{ order.paymentMethod }}
            </mat-chip>
            <mat-chip *ngIf="order.idealIssuerTitle">
              <i class="material-icons"></i>
              {{ order.idealIssuerTitle }}
            </mat-chip>
          </mat-chip-list>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
  <div class="items row separator">
    <div class="col-8" *ngIf="order.items.length && !isTripDetailsMode">
      <mat-tab-group
        #tabGroup
        [(selectedIndex)]="index"
        (selectedTabChange)="onTabChanged(index)"
      >
        <mat-tab label="Orders Items">
          <mat-card>
            <mat-card-title>
              Order Items - {{ totalItems }} item
            </mat-card-title>
            <mat-card-content>
              <div class="row no-gutters">
                <table
                  mat-table
                  [dataSource]="order.items"
                  multiTemplateDataRows
                >
                  <ng-container matColumnDef="productId">
                    <th mat-header-cell *matHeaderCellDef>Product-Id</th>
                    <td mat-cell *matCellDef="let item">
                      <span
                        class="label"
                        *ngIf="item.isComboItem || item.isPromoItem"
                      >
                        <i
                          *ngIf="
                            !selectedItem || selectedItem != item;
                            else selected
                          "
                          class="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>
                        <ng-template #selected>
                          <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </ng-template>
                      </span>
                      <span class="label">{{ item.productId }}</span>
                    </td>
                    <td mat-footer-cell *matFooterCellDef><b>Sub-Total</b></td>
                  </ng-container>

                  <ng-container matColumnDef="product">
                    <th mat-header-cell *matHeaderCellDef>Product</th>
                    <td mat-cell *matCellDef="let item">
                      <span class="label">{{ item.name }} </span>
                      <span class="oos" *ngIf="item.isOutOfStock">
                        (out of stock)
                      </span>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                  </ng-container>

                  <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef>
                      Product & Quantity
                      <span
                        class="label"
                        *ngIf="order.medium == '4'; else onlineOrders"
                        >(kg)</span
                      >
                      <ng-template #onlineOrders>(packs)</ng-template>
                    </th>
                    <td mat-cell *matCellDef="let item">
                      <span class="label"
                        >{{ item.name }} *
                        {{ item.quantity | number : "1.3-3" }}</span
                      >
                      <span class="oos" *ngIf="item.isOutOfStock">
                        (out of stock)
                      </span>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                      <b>Total Quantity - {{ order.totalQty }}</b>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="display-weight">
                    <th mat-header-cell *matHeaderCellDef>Shipped weight</th>
                    <td *matCellDef="let item">
                      <span class="label">{{ item.displayWeight }}</span>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                  </ng-container>

                  <ng-container matColumnDef="base-price">
                    <th mat-header-cell *matHeaderCellDef>MRP</th>
                    <td mat-cell *matCellDef="let item">
                      <span class="label">{{
                        item.basePrice | currency : "INR"
                      }}</span>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                      <b>{{ order.actualSubtotal | currency : "INR" }}</b>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="discount-price">
                    <th mat-header-cell *matHeaderCellDef>Discount</th>
                    <td mat-cell *matCellDef="let item">
                      <span class="label">{{
                        item.discountPrice | currency : "INR"
                      }}</span>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                      <b>{{ order.specialPriceDiscount | currency : "INR" }}</b>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="row-total">
                    <th mat-header-cell *matHeaderCellDef>Item-Total</th>
                    <td mat-cell *matCellDef="let item">
                      <span class="label">{{
                        item.rowTotal | currency : "INR"
                      }}</span>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                      <b>{{ order.subtotal | currency : "INR" }}</b>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="sku">
                    <th mat-header-cell *matHeaderCellDef>
                      <span *ngIf="isTechOps"> Sku </span>
                    </th>
                    <td mat-cell *matCellDef="let item">
                      <span *ngIf="isTechOps" class="label">
                        {{ item.SKU }}</span
                      >
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                  </ng-container>

                  <ng-container matColumnDef="tax">
                    <th mat-header-cell *matHeaderCellDef>GST</th>
                    <td *matCellDef="let item">
                      <span class="label">{{
                        item.taxAmount | currency : "INR"
                      }}</span>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                      <b> {{ order.taxAmount | currency : "INR" }}</b>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="expandedDetail">
                    <td
                      mat-cell
                      *matCellDef="let item"
                      [attr.colspan]="columnsToDisplay.length"
                    >
                      <div
                        class="item-hide-detail"
                        [@detailExpand]="
                          item == selectedItem ? 'expanded' : 'collapsed'
                        "
                      >
                        <app-combo-items-list
                          [selectedItem]="item"
                          *ngIf="selectedItem == item"
                          [order]="order"
                        ></app-combo-items-list>
                      </div>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                  <tr
                    mat-row
                    [hidden]="item.isSimple && item.parentItemId"
                    *matRowDef="let item; columns: columnsToDisplay"
                    class="order-items"
                    (click)="selectItem(item)"
                    [class.order-item-detail]="selectedItem === item"
                  ></tr>
                  <tr
                    mat-row
                    [hidden]="item.parentItemId"
                    *matRowDef="let item; columns: ['expandedDetail']"
                    class="item-detail-row"
                  ></tr>
                  <tr mat-footer-row *matFooterRowDef="columnsToDisplay"></tr>
                </table>
              </div>
            </mat-card-content>
          </mat-card>
        </mat-tab>

        <mat-tab label="Barcode" id="barcode-tab">
          <mat-card>
            <mat-card-content>
              <span
                *ngIf="!barcodeSpecDatasource.data.length; else showBarcodeGrid"
              >
                <div class="butcher" id="barcode-text">
                  No barcode details available.
                </div></span
              >

              <ng-template #showBarcodeGrid>
                <app-barcode-spec-grid [dataSource]="barcodeSpecDatasource">
                </app-barcode-spec-grid>
              </ng-template>
            </mat-card-content>
          </mat-card>
        </mat-tab>

        <mat-tab label="Live Track" (click)="fetchLiveTrackDetails()">
          <mat-card>
            <mat-card-content>
              <div
                class="butcher"
                *ngIf="!liveTrackButchers.length; else showButcherDetail"
              >
                No butcher details available.
              </div>
              <ng-template #showButcherDetail>
                <div class="butcher">Butcher Detail :</div>
                <app-butcher
                  [butcher]="butcher"
                  [order]="order"
                  *ngFor="let butcher of liveTrackButchers"
                >
                </app-butcher>
              </ng-template>
              <div
                class="butcher"
                *ngIf="!liveTrackDriver?.isActive; else showDriverDetail"
              >
                No driver details available.
              </div>
              <ng-template #showDriverDetail>
                <div class="butcher">Driver Detail :</div>
                <app-driver [driver]="liveTrackDriver" *ngIf="liveTrackDriver">
                </app-driver>
              </ng-template>
            </mat-card-content>
          </mat-card>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div class="col-4">
      <mat-card class="row separator no-gutters">
        <mat-card-title>Order Totals</mat-card-title>
        <mat-card-content>
          <div class="row no-gutters">
            <span class="label col-6">Subtotal</span>
            <span class="value col-6">{{
              order.actualSubtotal | currency : "INR"
            }}</span>
          </div>

          <div class="row no-gutters">
            <span class="label col-6">Shipping</span>
            <span class="value col-6">{{
              order.shipping | currency : "INR"
            }}</span>
          </div>
          <div class="row no-gutters">
            <span class="label col-6">GST</span>
            <span class="value col-6">{{
              order.taxAmount | currency : "INR"
            }}</span>
          </div>
          <div *ngIf="order.specialPriceDiscount > 0" class="row no-gutters">
            <span class="label col-6">Promo Discount</span>
            <span class="value col-6"
              >-{{ order.specialPriceDiscount | currency : "INR" }}</span
            >
          </div>
          <div class="row no-gutters">
            <span class="label col-6">Discount ({{ order.couponCode }})</span>
            <span class="value col-6">{{
              order.discount | currency : "INR"
            }}</span>
          </div>

          <div *ngIf="order.rewardAmount != 0" class="row no-gutters">
            <span class="label col-6">TC Rewards Used</span>
            <span class="value col-6">{{
              order.rewardAmount | currency : "INR"
            }}</span>
          </div>

          <div *ngIf="order.walletAmount != 0" class="row no-gutters">
            <span class="label col-6">TC Wallet Used</span>
            <span class="value col-6"
              >-{{ order.walletAmount | currency : "INR" }}</span
            >
          </div>

          <mat-divider></mat-divider>

          <div class="row no-gutters">
            <span class="label col-6">Total</span>
            <span class="value col-6">{{
              order.total | currency : "INR"
            }}</span>
          </div>

          <div
            class="row no-gutters"
            id="reward-points"
            *ngIf="order.rewardPointsEarned != 0"
          >
            <span class="label col-6">Reward Points Earned </span>
            <span class="value col-6"
              >{{ order.rewardPointsEarned }} Points</span
            >
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="row progress-comments" *ngIf="!isTripDetailsMode">
    <div *ngIf="orderStatusHistory.length" class="col-6">
      <mat-card class="row separator no-gutters">
        <mat-card-title>Order Progress</mat-card-title>
        <mat-card-content>
          <ng-container
            *ngFor="
              let history of orderStatusHistory;
              let i = index;
              let last = last
            "
          >
            <div class="d-flex flex-row py-1 align-items-center status">
              <p class="status-number">
                <b> {{ orderStatusHistoryIndex[i] }}</b>
              </p>
              <p class="m-0">
                <b> {{ history.status }} </b>({{
                  history.created_at | date : "MMM d,h:mm a"
                }}
                )<br />
                <span *ngIf="history.comment"
                  >{{ history.commentDisplay }}
                  <span *ngIf="history.userName"
                    >- {{ history.userName }}</span
                  ></span
                >
              </p>
            </div>
            <div class="line" *ngIf="!last"></div>
          </ng-container>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-6">
      <mat-card
        class="row separator no-gutters"
        *ngIf="userInteractionsHistory.length"
      >
        <mat-card-title>User Interactions</mat-card-title>
        <mat-card-content>
          <ng-container
            *ngFor="
              let history of userInteractionsHistory;
              let i = index;
              let last = last
            "
          >
            <div class="d-flex flex-row py-1 align-items-center status">
              <div class="row">
                <span>
                  <p class="status-number">
                    <b> {{ userInteractionIndex[i] }}</b>
                  </p>
                </span>
                <span>
                  <p class="m-0">
                    <b> {{ history.status }} </b>({{
                      history.created_at | date : "MMM d,h:mm a"
                    }}
                    )<br />
                    <span *ngIf="history.comment"
                      >{{ history.commentDisplay }}
                      <span *ngIf="history.userName"
                        >- {{ history.userName }}</span
                      ></span
                    >
                  </p>
                </span>
              </div>
            </div>
            <div class="line" *ngIf="!last"></div>
          </ng-container>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<div>
  <button mat-raised-button (click)="printOrder()" color="primary">
    <i class="material-icons">print</i>
    Print Order
  </button>
</div>
<div class="row">
  <mat-list class="col-6">
    <h3 mat-subheader>Order Details - {{ order.incrementId }}</h3>
    <mat-list-item *ngIf="ratingTags.length > 0">
      <h4 mat-line>Priority Order</h4>
      <p mat-line *ngFor="let tag of ratingTags">{{ tag }}</p>
    </mat-list-item>
    <mat-list-item>
      <h4 mat-line>Order Date</h4>
      <p mat-line>{{ order.createdAt }}</p>
    </mat-list-item>

    <mat-list-item>
      <h4 mat-line>Order State</h4>
      <p mat-line>{{ order.status }}</p>
    </mat-list-item>
    <mat-list-item *ngIf="order.storeName">
      <h4 mat-line>Store Name</h4>
      <p mat-line>{{ order.storeName }}</p>
    </mat-list-item>
  </mat-list>

  <mat-list class="col-6">
    <h3 mat-subheader>Customer Details</h3>
    <mat-list-item>
      <h4 mat-line>Customer Name</h4>
      <p mat-line>{{ order.firstname }}</p>
    </mat-list-item>

    <mat-list-item>
      <h4 mat-line>Delivery Type</h4>
      <p mat-line>{{ order.deliveryType }}</p>
    </mat-list-item>
  </mat-list>

  <mat-divider></mat-divider>

  <mat-list class="col-12">
    <h3 mat-subheader>Address</h3>
    <mat-list-item>
      <h4 mat-line>Customer Address</h4>
      <p mat-line>
        {{ order.shippingAddress.apartment }},
        {{ order.shippingAddress.landmark }}
      </p>
    </mat-list-item>
  </mat-list>

  <mat-list class="col-6">
    <mat-list-item>
      <h4 mat-line>Apartment/Flat/Street</h4>
      <p mat-line>{{ order.shippingAddress.fax }}</p>
    </mat-list-item>
    <mat-list-item>
      <h4 mat-line>Delivery Charges</h4>
      <p mat-line>{{ order.shipping | currency: "INR":"1.0-0" }}</p>
    </mat-list-item>
  </mat-list>

  <mat-list class="col-6">
    <mat-list-item>
      <h4 mat-line>Pincode</h4>
      <p mat-line>{{ order.shippingAddress.postcode }}</p>
    </mat-list-item>
    <mat-list-item>
      <h4 mat-line>Payment</h4>
      <p mat-line>
        {{ order.total | currency: "INR":"1.0-0" }} ({{ order.paymentMethod }})
      </p>
    </mat-list-item>
  </mat-list>

  <mat-divider></mat-divider>

  <mat-list class="col-6">
    <h3 mat-subheader>Order Items</h3>
    <mat-list-item *ngFor="let item of order.items">
      <h4 mat-line>{{ item.name }}</h4>
      <p mat-line>{{ item.quantity }}</p>
    </mat-list-item>
  </mat-list>

  <mat-list class="col-6">
    <h3 mat-subheader>Totals</h3>
    <mat-list-item>
      <h4 mat-line>Sub-Total</h4>
      <p mat-line>{{ order.subtotal | currency: "INR":"1.0-0" }}</p>
    </mat-list-item>
    <mat-list-item>
      <h4 mat-line>Discount</h4>
      <p mat-line>{{ order.discount | currency: "INR":"1.0-0" }}</p>
    </mat-list-item>
  </mat-list>

  <mat-divider></mat-divider>
</div>

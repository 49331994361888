import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { AppState } from "@store/state";
import { StoreState } from "@store/store/state";
import {
  ALLDAYS,
  DriverEarningSlab,
  WeeklyTripSheet,
} from "@tendercuts/models";
import { FetchDriverSlabsService } from "@tendercuts/providers";
import { skipWhile, take } from "rxjs/operators";
import { BasePage } from "src/app/utils";

/**
 * component to show driver slab progress and week goals
 */
@Component({
  selector: "app-weeks-target",
  templateUrl: "./weeks-target.component.html",
  styleUrls: ["./weeks-target.component.scss"],
})
export class WeeksTargetComponent extends BasePage implements OnInit {
  // driver slabs
  availableSlabs: DriverEarningSlab[] = [];

  // All week days
  alldays: string[] = ["week", ...ALLDAYS];

  weeklyTripSheet: WeeklyTripSheet;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: WeeklyTripSheet,
    public dialogRef: MatDialogRef<WeeksTargetComponent>,
    public fetchDriverSlabsService: FetchDriverSlabsService,
  ) {
    super(dialog, snackBar, store);
    if (data) {
      this.weeklyTripSheet = data;
    }
  }

  ngOnInit(): void {
    this.fetchStoreData();
  }

  /**
   * fetch redux store data
   */
  async fetchStoreData(): Promise<void> {
    const storeState$: StoreState = await this.store
      .select((state) => state.storeState)
      .pipe(
        skipWhile((state) => state.loading == true),
        take(1),
      )
      .toPromise();

    this.getSlabData(storeState$.store);
  }

  /**
   * Fetches all slabs
   */
  async getSlabData(storeId: number): Promise<void> {
    try {
      this.presentLoader();
      this.availableSlabs = await this.fetchDriverSlabsService
        .getData({ store_id: storeId.toString() })
        .toPromise();
      this.dismissLoader();
    } catch (err) {
      this.dismissLoader();
      this.somethingWentWrong();
    }
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { SaleOrder } from "@tendercuts/models";
import { of, Observable } from "rxjs";
import { FetchOrdersService } from "./service";

@Injectable()
export class MockFetchOrdersService extends FetchOrdersService {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http, endpointService, loginService);
    this.delegate = this;
  }

  getData(): Observable<SaleOrder[]> {
    return of([
      new SaleOrder().deserialize({
        entity_id: 36708,
        increment_id: "W800000003",
        customer_id: 8233,
        customer_firstname: "Dharmaraj",
        grand_total: "954.0000",
        updated_at: "2016-09-03T03:12:26Z",
        payment: [{ method: "cashondelivery" }],
        store_id: 8,
        shipping_address: [
          {
            fax: null,
            street:
              "3B-Aishwarya Enclave,H-24,East Avenue, Korattur\npadi\nKorattur",
            region: "Tamil Nadu",
            city: "Chennai",
            postcode: "600080",
            telephone: "09600055641",
            email: "rani_dharmaraj@yahoo.com",
            address_type: "billing",
          },
          {
            fax: null,
            street:
              "3B-Aishwarya Enclave,H-24,East Avenue, Korattur\npadi\nKorattur",
            region: "Tamil Nadu",
            city: "Chennai",
            postcode: "600080",
            telephone: "09600055641",
            email: "rani_dharmaraj@yahoo.com",
            address_type: "shipping",
          },
        ],
        items: [
          {
            item_id: 50593,
            product_id: 192,
            name: "Chicken Curry Cut - Skin on",
            qty_ordered: "0.5000",
            weight: "1.0000",
            row_total: "99.0000",
          },
          {
            item_id: 50594,
            product_id: 198,
            name: "Chicken Lollipop - Skinless",
            qty_ordered: "2.0000",
            weight: "1.0000",
            row_total: "158.0000",
          },
          {
            item_id: 50595,
            product_id: 202,
            name: "Mutton Curry Cut",
            qty_ordered: "0.5000",
            weight: "1.0000",
            row_total: "299.0000",
          },
          {
            item_id: 50596,
            product_id: 215,
            name: "Sankara Big",
            qty_ordered: "1.0000",
            weight: "1.0000",
            row_total: "398.0000",
          },
        ],
        status: "complete",
        order_now: 0,
        promised_delivery_time: "",
        promised_delivery_time_dt: "",
        shipping_amount: "0.0000",
        discount_amount: "0.0000",
        driver_name: "hari1",
        driver_number: 9551071621,
      }),
      new SaleOrder().deserialize({
        entity_id: 36708,
        increment_id: "W800000003",
        customer_id: 8233,
        customer_firstname: "Dharmaraj",
        grand_total: "954.0000",
        updated_at: "2016-09-03T03:12:26Z",
        payment: [{ method: "cashondelivery" }],
        store_id: 8,
        shipping_address: [
          {
            fax: null,
            street:
              "3B-Aishwarya Enclave,H-24,East Avenue, Korattur\npadi\nKorattur",
            region: "Tamil Nadu",
            city: "Chennai",
            postcode: "600080",
            telephone: "09600055641",
            email: "rani_dharmaraj@yahoo.com",
            address_type: "billing",
          },
          {
            fax: null,
            street:
              "3B-Aishwarya Enclave,H-24,East Avenue, Korattur\npadi\nKorattur",
            region: "Tamil Nadu",
            city: "Chennai",
            postcode: "600080",
            telephone: "09600055641",
            email: "rani_dharmaraj@yahoo.com",
            address_type: "shipping",
          },
        ],
        items: [
          {
            item_id: 50593,
            product_id: 192,
            name: "Chicken Curry Cut - Skin on",
            qty_ordered: "0.5000",
            weight: "1.0000",
            row_total: "99.0000",
          },
          {
            item_id: 50594,
            product_id: 198,
            name: "Chicken Lollipop - Skinless",
            qty_ordered: "2.0000",
            weight: "1.0000",
            row_total: "158.0000",
          },
          {
            item_id: 50595,
            product_id: 202,
            name: "Mutton Curry Cut",
            qty_ordered: "0.5000",
            weight: "1.0000",
            row_total: "299.0000",
          },
          {
            item_id: 50596,
            product_id: 215,
            name: "Sankara Big",
            qty_ordered: "1.0000",
            weight: "1.0000",
            row_total: "398.0000",
          },
        ],
        status: "complete",
        order_now: 0,
        promised_delivery_time: "",
        promised_delivery_time_dt: "",
        shipping_amount: "0.0000",
        discount_amount: "0.0000",
        driver_name: "hari1",
        driver_number: 9551071621,
      }),
      new SaleOrder().deserialize({
        entity_id: 36708,
        increment_id: "W800000003",
        customer_id: 8233,
        customer_firstname: "Dharmaraj",
        grand_total: "954.0000",
        updated_at: "2016-09-03T03:12:26Z",
        payment: [{ method: "cashondelivery" }],
        store_id: 8,
        shipping_address: [
          {
            fax: null,
            street:
              "3B-Aishwarya Enclave,H-24,East Avenue, Korattur\npadi\nKorattur",
            region: "Tamil Nadu",
            city: "Chennai",
            postcode: "600080",
            telephone: "09600055641",
            email: "rani_dharmaraj@yahoo.com",
            address_type: "billing",
          },
          {
            fax: null,
            street:
              "3B-Aishwarya Enclave,H-24,East Avenue, Korattur\npadi\nKorattur",
            region: "Tamil Nadu",
            city: "Chennai",
            postcode: "600080",
            telephone: "09600055641",
            email: "rani_dharmaraj@yahoo.com",
            address_type: "shipping",
          },
        ],
        items: [
          {
            item_id: 50593,
            product_id: 192,
            name: "Chicken Curry Cut - Skin on",
            qty_ordered: "0.5000",
            weight: "1.0000",
            row_total: "99.0000",
          },
          {
            item_id: 50594,
            product_id: 198,
            name: "Chicken Lollipop - Skinless",
            qty_ordered: "2.0000",
            weight: "1.0000",
            row_total: "158.0000",
          },
          {
            item_id: 50595,
            product_id: 202,
            name: "Mutton Curry Cut",
            qty_ordered: "0.5000",
            weight: "1.0000",
            row_total: "299.0000",
          },
          {
            item_id: 50596,
            product_id: 215,
            name: "Sankara Big",
            qty_ordered: "1.0000",
            weight: "1.0000",
            row_total: "398.0000",
          },
        ],
        status: "complete",
        order_now: 0,
        promised_delivery_time: "",
        promised_delivery_time_dt: "",
        shipping_amount: "0.0000",
        discount_amount: "0.0000",
        driver_name: "hari1",
        driver_number: 9551071621,
      }),
      new SaleOrder().deserialize({
        entity_id: 36708,
        increment_id: "W800000003",
        customer_id: 8233,
        customer_firstname: "Dharmaraj",
        grand_total: "954.0000",
        updated_at: "2016-09-03T03:12:26Z",
        payment: [{ method: "cashondelivery" }],
        store_id: 8,
        shipping_address: [
          {
            fax: null,
            street:
              "3B-Aishwarya Enclave,H-24,East Avenue, Korattur\npadi\nKorattur",
            region: "Tamil Nadu",
            city: "Chennai",
            postcode: "600080",
            telephone: "09600055641",
            email: "rani_dharmaraj@yahoo.com",
            address_type: "billing",
          },
          {
            fax: null,
            street:
              "3B-Aishwarya Enclave,H-24,East Avenue, Korattur\npadi\nKorattur",
            region: "Tamil Nadu",
            city: "Chennai",
            postcode: "600080",
            telephone: "09600055641",
            email: "rani_dharmaraj@yahoo.com",
            address_type: "shipping",
          },
        ],
        items: [
          {
            item_id: 50593,
            product_id: 192,
            name: "Chicken Curry Cut - Skin on",
            qty_ordered: "0.5000",
            weight: "1.0000",
            row_total: "99.0000",
          },
          {
            item_id: 50594,
            product_id: 198,
            name: "Chicken Lollipop - Skinless",
            qty_ordered: "2.0000",
            weight: "1.0000",
            row_total: "158.0000",
          },
          {
            item_id: 50595,
            product_id: 202,
            name: "Mutton Curry Cut",
            qty_ordered: "0.5000",
            weight: "1.0000",
            row_total: "299.0000",
          },
          {
            item_id: 50596,
            product_id: 215,
            name: "Sankara Big",
            qty_ordered: "1.0000",
            weight: "1.0000",
            row_total: "398.0000",
          },
        ],
        status: "complete",
        order_now: 0,
        promised_delivery_time: "",
        promised_delivery_time_dt: "",
        shipping_amount: "0.0000",
        discount_amount: "0.0000",
        driver_name: "hari2",
        driver_number: 9551071622,
      }),
      new SaleOrder().deserialize({
        entity_id: 36708,
        increment_id: "W800000003",
        customer_id: 8233,
        customer_firstname: "Dharmaraj",
        grand_total: "954.0000",
        updated_at: "2016-09-03T03:12:26Z",
        payment: [{ method: "cashondelivery" }],
        store_id: 8,
        shipping_address: [
          {
            fax: null,
            street:
              "3B-Aishwarya Enclave,H-24,East Avenue, Korattur\npadi\nKorattur",
            region: "Tamil Nadu",
            city: "Chennai",
            postcode: "600080",
            telephone: "09600055641",
            email: "rani_dharmaraj@yahoo.com",
            address_type: "billing",
          },
          {
            fax: null,
            street:
              "3B-Aishwarya Enclave,H-24,East Avenue, Korattur\npadi\nKorattur",
            region: "Tamil Nadu",
            city: "Chennai",
            postcode: "600080",
            telephone: "09600055641",
            email: "rani_dharmaraj@yahoo.com",
            address_type: "shipping",
          },
        ],
        items: [
          {
            item_id: 50593,
            product_id: 192,
            name: "Chicken Curry Cut - Skin on",
            qty_ordered: "0.5000",
            weight: "1.0000",
            row_total: "99.0000",
          },
          {
            item_id: 50594,
            product_id: 198,
            name: "Chicken Lollipop - Skinless",
            qty_ordered: "2.0000",
            weight: "1.0000",
            row_total: "158.0000",
          },
          {
            item_id: 50595,
            product_id: 202,
            name: "Mutton Curry Cut",
            qty_ordered: "0.5000",
            weight: "1.0000",
            row_total: "299.0000",
          },
          {
            item_id: 50596,
            product_id: 215,
            name: "Sankara Big",
            qty_ordered: "1.0000",
            weight: "1.0000",
            row_total: "398.0000",
          },
        ],
        status: "complete",
        order_now: 0,
        promised_delivery_time: "",
        promised_delivery_time_dt: "",
        shipping_amount: "0.0000",
        discount_amount: "0.0000",
        driver_name: "hari2",
        driver_number: 9551071622,
      }),
      new SaleOrder().deserialize({
        entity_id: 36708,
        increment_id: "W800000003",
        customer_id: 8233,
        customer_firstname: "Dharmaraj",
        grand_total: "954.0000",
        updated_at: "2016-09-03T03:12:26Z",
        payment: [{ method: "cashondelivery" }],
        store_id: 8,
        shipping_address: [
          {
            fax: null,
            street:
              "3B-Aishwarya Enclave,H-24,East Avenue, Korattur\npadi\nKorattur",
            region: "Tamil Nadu",
            city: "Chennai",
            postcode: "600080",
            telephone: "09600055641",
            email: "rani_dharmaraj@yahoo.com",
            address_type: "billing",
          },
          {
            fax: null,
            street:
              "3B-Aishwarya Enclave,H-24,East Avenue, Korattur\npadi\nKorattur",
            region: "Tamil Nadu",
            city: "Chennai",
            postcode: "600080",
            telephone: "09600055641",
            email: "rani_dharmaraj@yahoo.com",
            address_type: "shipping",
          },
        ],
        items: [
          {
            item_id: 50593,
            product_id: 192,
            name: "Chicken Curry Cut - Skin on",
            qty_ordered: "0.5000",
            weight: "1.0000",
            row_total: "99.0000",
          },
          {
            item_id: 50594,
            product_id: 198,
            name: "Chicken Lollipop - Skinless",
            qty_ordered: "2.0000",
            weight: "1.0000",
            row_total: "158.0000",
          },
          {
            item_id: 50595,
            product_id: 202,
            name: "Mutton Curry Cut",
            qty_ordered: "0.5000",
            weight: "1.0000",
            row_total: "299.0000",
          },
          {
            item_id: 50596,
            product_id: 215,
            name: "Sankara Big",
            qty_ordered: "1.0000",
            weight: "1.0000",
            row_total: "398.0000",
          },
        ],
        status: "complete",
        order_now: 0,
        promised_delivery_time: "",
        promised_delivery_time_dt: "",
        shipping_amount: "0.0000",
        discount_amount: "0.0000",
        driver_name: "hari2",
        driver_number: 9551071622,
      }),
      new SaleOrder().deserialize({
        entity_id: 36708,
        increment_id: "W800000003",
        customer_id: 8233,
        customer_firstname: "Dharmaraj",
        grand_total: "954.0000",
        updated_at: "2016-09-03T03:12:26Z",
        payment: [{ method: "cashondelivery" }],
        store_id: 8,
        shipping_address: [
          {
            fax: null,
            street:
              "3B-Aishwarya Enclave,H-24,East Avenue, Korattur\npadi\nKorattur",
            region: "Tamil Nadu",
            city: "Chennai",
            postcode: "600080",
            telephone: "09600055641",
            email: "rani_dharmaraj@yahoo.com",
            address_type: "billing",
          },
          {
            fax: null,
            street:
              "3B-Aishwarya Enclave,H-24,East Avenue, Korattur\npadi\nKorattur",
            region: "Tamil Nadu",
            city: "Chennai",
            postcode: "600080",
            telephone: "09600055641",
            email: "rani_dharmaraj@yahoo.com",
            address_type: "shipping",
          },
        ],
        items: [
          {
            item_id: 50593,
            product_id: 192,
            name: "Chicken Curry Cut - Skin on",
            qty_ordered: "0.5000",
            weight: "1.0000",
            row_total: "99.0000",
          },
          {
            item_id: 50594,
            product_id: 198,
            name: "Chicken Lollipop - Skinless",
            qty_ordered: "2.0000",
            weight: "1.0000",
            row_total: "158.0000",
          },
          {
            item_id: 50595,
            product_id: 202,
            name: "Mutton Curry Cut",
            qty_ordered: "0.5000",
            weight: "1.0000",
            row_total: "299.0000",
          },
          {
            item_id: 50596,
            product_id: 215,
            name: "Sankara Big",
            qty_ordered: "1.0000",
            weight: "1.0000",
            row_total: "398.0000",
          },
        ],
        status: "complete",
        order_now: 0,
        promised_delivery_time: "",
        promised_delivery_time_dt: "",
        shipping_amount: "0.0000",
        discount_amount: "0.0000",
        driver_name: "hari3",
        driver_number: 9551071623,
      }),
      new SaleOrder().deserialize({
        entity_id: 36708,
        increment_id: "W800000003",
        customer_id: 8233,
        customer_firstname: "Dharmaraj",
        grand_total: "954.0000",
        updated_at: "2016-09-03T03:12:26Z",
        payment: [{ method: "cashondelivery" }],
        store_id: 8,
        shipping_address: [
          {
            fax: null,
            street:
              "3B-Aishwarya Enclave,H-24,East Avenue, Korattur\npadi\nKorattur",
            region: "Tamil Nadu",
            city: "Chennai",
            postcode: "600080",
            telephone: "09600055641",
            email: "rani_dharmaraj@yahoo.com",
            address_type: "billing",
          },
          {
            fax: null,
            street:
              "3B-Aishwarya Enclave,H-24,East Avenue, Korattur\npadi\nKorattur",
            region: "Tamil Nadu",
            city: "Chennai",
            postcode: "600080",
            telephone: "09600055641",
            email: "rani_dharmaraj@yahoo.com",
            address_type: "shipping",
          },
        ],
        items: [
          {
            item_id: 50593,
            product_id: 192,
            name: "Chicken Curry Cut - Skin on",
            qty_ordered: "0.5000",
            weight: "1.0000",
            row_total: "99.0000",
          },
          {
            item_id: 50594,
            product_id: 198,
            name: "Chicken Lollipop - Skinless",
            qty_ordered: "2.0000",
            weight: "1.0000",
            row_total: "158.0000",
          },
          {
            item_id: 50595,
            product_id: 202,
            name: "Mutton Curry Cut",
            qty_ordered: "0.5000",
            weight: "1.0000",
            row_total: "299.0000",
          },
          {
            item_id: 50596,
            product_id: 215,
            name: "Sankara Big",
            qty_ordered: "1.0000",
            weight: "1.0000",
            row_total: "398.0000",
          },
        ],
        status: "complete",
        order_now: 0,
        promised_delivery_time: "",
        promised_delivery_time_dt: "",
        shipping_amount: "0.0000",
        discount_amount: "0.0000",
        driver_name: "hari3",
        driver_number: 9551071623,
      }),
      new SaleOrder().deserialize({
        entity_id: 36708,
        increment_id: "W800000003",
        customer_id: 8233,
        customer_firstname: "Dharmaraj",
        grand_total: "954.0000",
        updated_at: "2016-09-03T03:12:26Z",
        payment: [{ method: "cashondelivery" }],
        store_id: 8,
        shipping_address: [
          {
            fax: null,
            street:
              "3B-Aishwarya Enclave,H-24,East Avenue, Korattur\npadi\nKorattur",
            region: "Tamil Nadu",
            city: "Chennai",
            postcode: "600080",
            telephone: "09600055641",
            email: "rani_dharmaraj@yahoo.com",
            address_type: "billing",
          },
          {
            fax: null,
            street:
              "3B-Aishwarya Enclave,H-24,East Avenue, Korattur\npadi\nKorattur",
            region: "Tamil Nadu",
            city: "Chennai",
            postcode: "600080",
            telephone: "09600055641",
            email: "rani_dharmaraj@yahoo.com",
            address_type: "shipping",
          },
        ],
        items: [
          {
            item_id: 50593,
            product_id: 192,
            name: "Chicken Curry Cut - Skin on",
            qty_ordered: "0.5000",
            weight: "1.0000",
            row_total: "99.0000",
          },
          {
            item_id: 50594,
            product_id: 198,
            name: "Chicken Lollipop - Skinless",
            qty_ordered: "2.0000",
            weight: "1.0000",
            row_total: "158.0000",
          },
          {
            item_id: 50595,
            product_id: 202,
            name: "Mutton Curry Cut",
            qty_ordered: "0.5000",
            weight: "1.0000",
            row_total: "299.0000",
          },
          {
            item_id: 50596,
            product_id: 215,
            name: "Sankara Big",
            qty_ordered: "1.0000",
            weight: "1.0000",
            row_total: "398.0000",
          },
        ],
        status: "complete",
        order_now: 0,
        promised_delivery_time: "",
        promised_delivery_time_dt: "",
        shipping_amount: "0.0000",
        discount_amount: "0.0000",
        driver_name: "hari3",
        driver_number: 9551071623,
      }),
      new SaleOrder().deserialize({
        entity_id: 36708,
        increment_id: "W800000003",
        customer_id: 8233,
        customer_firstname: "Dharmaraj",
        grand_total: "954.0000",
        updated_at: "2016-09-03T03:12:26Z",
        payment: [{ method: "cashondelivery" }],
        store_id: 8,
        shipping_address: [
          {
            fax: null,
            street:
              "3B-Aishwarya Enclave,H-24,East Avenue, Korattur\npadi\nKorattur",
            region: "Tamil Nadu",
            city: "Chennai",
            postcode: "600080",
            telephone: "09600055641",
            email: "rani_dharmaraj@yahoo.com",
            address_type: "billing",
          },
          {
            fax: null,
            street:
              "3B-Aishwarya Enclave,H-24,East Avenue, Korattur\npadi\nKorattur",
            region: "Tamil Nadu",
            city: "Chennai",
            postcode: "600080",
            telephone: "09600055641",
            email: "rani_dharmaraj@yahoo.com",
            address_type: "shipping",
          },
        ],
        items: [
          {
            item_id: 50593,
            product_id: 192,
            name: "Chicken Curry Cut - Skin on",
            qty_ordered: "0.5000",
            weight: "1.0000",
            row_total: "99.0000",
          },
          {
            item_id: 50594,
            product_id: 198,
            name: "Chicken Lollipop - Skinless",
            qty_ordered: "2.0000",
            weight: "1.0000",
            row_total: "158.0000",
          },
          {
            item_id: 50595,
            product_id: 202,
            name: "Mutton Curry Cut",
            qty_ordered: "0.5000",
            weight: "1.0000",
            row_total: "299.0000",
          },
          {
            item_id: 50596,
            product_id: 215,
            name: "Sankara Big",
            qty_ordered: "1.0000",
            weight: "1.0000",
            row_total: "398.0000",
          },
        ],
        status: "complete",
        order_now: 0,
        promised_delivery_time: "",
        promised_delivery_time_dt: "",
        shipping_amount: "0.0000",
        discount_amount: "0.0000",
        driver_name: "hari1",
        driver_number: 9551071621,
      }),
      new SaleOrder().deserialize({
        entity_id: 36708,
        increment_id: "W800000003",
        customer_id: 8233,
        customer_firstname: "Dharmaraj",
        grand_total: "954.0000",
        updated_at: "2016-09-03T03:12:26Z",
        payment: [{ method: "cashondelivery" }],
        store_id: 8,
        shipping_address: [
          {
            fax: null,
            street:
              "3B-Aishwarya Enclave,H-24,East Avenue, Korattur\npadi\nKorattur",
            region: "Tamil Nadu",
            city: "Chennai",
            postcode: "600080",
            telephone: "09600055641",
            email: "rani_dharmaraj@yahoo.com",
            address_type: "billing",
          },
          {
            fax: null,
            street:
              "3B-Aishwarya Enclave,H-24,East Avenue, Korattur\npadi\nKorattur",
            region: "Tamil Nadu",
            city: "Chennai",
            postcode: "600080",
            telephone: "09600055641",
            email: "rani_dharmaraj@yahoo.com",
            address_type: "shipping",
          },
        ],
        items: [
          {
            item_id: 50593,
            product_id: 192,
            name: "Chicken Curry Cut - Skin on",
            qty_ordered: "0.5000",
            weight: "1.0000",
            row_total: "99.0000",
          },
          {
            item_id: 50594,
            product_id: 198,
            name: "Chicken Lollipop - Skinless",
            qty_ordered: "2.0000",
            weight: "1.0000",
            row_total: "158.0000",
          },
          {
            item_id: 50595,
            product_id: 202,
            name: "Mutton Curry Cut",
            qty_ordered: "0.5000",
            weight: "1.0000",
            row_total: "299.0000",
          },
          {
            item_id: 50596,
            product_id: 215,
            name: "Sankara Big",
            qty_ordered: "1.0000",
            weight: "1.0000",
            row_total: "398.0000",
          },
        ],
        status: "complete",
        order_now: 0,
        promised_delivery_time: "",
        promised_delivery_time_dt: "",
        shipping_amount: "0.0000",
        discount_amount: "0.0000",
        driver_name: "hari1",
        driver_number: null,
      }),
    ]);
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EndPoint } from "@endpoint";
import { LoginServices } from "@login";
import { BaseGetProvider } from "@tendercuts/http";
import { DriverSnapshot } from "@tendercuts/models";

@Injectable({
  providedIn: "root",
})
export class FetchDriverSnapshotService extends BaseGetProvider<DriverSnapshot> {
  constructor(
    public http: HttpClient,
    public endpointService: EndPoint,
    public loginService: LoginServices,
  ) {
    super(http);
    this.delegate = this;
  }

  preProcessResponse(response: any): any {
    return response.results;
  }

  get isAuthenticatedEndpoint(): boolean {
    return true;
  }

  get authProtocol(): LoginServices {
    return this.loginService;
  }

  get model(): typeof DriverSnapshot {
    return DriverSnapshot;
  }

  get endpoint(): string {
    return this.endpointService.driverSnapshot();
  }

  get isCachable(): boolean {
    return false;
  }

  getParams(driverId: number): { driver: number } {
    const params: { driver: number } = {
      driver: driverId,
    };

    return params;
  }
}

import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import {
  FetchProductSpecService,
  ItemWeightService,
} from "@tendercuts/providers";
import { NgxBarcodeModule } from "ngx-barcode";
import { SharedModule } from "../shared/shared.module";
import { ScanDashboardComponent } from "./barcode-scan.component";

@NgModule({
  imports: [CommonModule, SharedModule, NgxBarcodeModule],
  declarations: [ScanDashboardComponent],
  providers: [ItemWeightService, FetchProductSpecService],
  exports: [ScanDashboardComponent],
})
export class BarcodeScanModule {}

import { Serializable } from "./base";

export class DriverStatusHistory implements Serializable<DriverStatusHistory> {
  private old_status: string;
  private new_status: string;
  private created_at: Date;
  private field_name: string;
  public profile: string;
  public comment: number;
  public id: number;


  get oldStatus() {
    return this.old_status;
  }

  get newStatus() {
    return this.new_status;
  }

  get createdAt() {
    return this.created_at;
  }

  get fieldName() {
    return this.field_name;
  }

  constructor() {}

  deserialize(input) {
    this.old_status = input.old_status;
    this.field_name = input.field_name;
    this.new_status = input.new_status;
    this.created_at = input.created_at;
    this.profile = input.profile;
    this.comment = input.comment;
    this.id = input.id;

    return this;
  }
}

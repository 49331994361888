import { Serializable } from './base';

export class DriverOnlineStatus implements Serializable<DriverOnlineStatus> {
    public isOnline: boolean;
    public date: number;
    public store_id: number;
    public status: boolean;

    constructor() { }

    deserialize(input) {
        if (input.isOnline != undefined) {
            this.isOnline = input.isOnline;
        }
        if (input.date != undefined) {
            this.date = input.date;
        }
        this.store_id = input.store_id;
        this.status = input.status;
        return this;
    }
}
